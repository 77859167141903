import { useState, useContext } from 'react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import {
  ReservasDestinoRequest,
  ResponseDefinirReservaDestino,
} from '../types/DefinirReservaDestino.types';
import * as DefinirReservasDestinoApi from '../services/definirReservaDestino.api';

const useDefinirReservaDestino = () => {
  const { toastError } = useToast();
  const [loadingDefinirReservaDestino, setLoading] = useState<boolean>(false);
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const [dataDefinirReservaDestino, setDataDefinirReservaDestino] = useState<
    ResponseDefinirReservaDestino | undefined
  >();
  const definirReservaDestino = async (
    numTransferencia: string | undefined,
    fundosRequest: ReservasDestinoRequest[] | undefined,
  ) => {
    if (numTransferencia && fundosRequest) {
      try {
        setLoading(true);
        const result = await DefinirReservasDestinoApi.definirReservaDestino(
          cpfCnpj,
          numCertificado,
          numTransferencia,
          fundosRequest,
        );
        setDataDefinirReservaDestino(result);
      } catch (requestError: any) {
        toastError(requestError.message);
      } finally {
        setLoading(false);
      }
    }
  };
  return {
    loadingDefinirReservaDestino,
    dataDefinirReservaDestino,
    definirReservaDestino,
  };
};

export default useDefinirReservaDestino;
