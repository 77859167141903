import React, { useEffect } from 'react';
import { Display, Modal, Button, Text } from '@cvp/design-system/react';
import InstrucoesRegerarPdf from 'main/components/InstrucoesRegerarPdf';
import { TextosFeatureProspeccao } from 'main/features/prospeccao/constants/TextosFeatureProspeccao';

type ModalErroConfirmarCadastroProspeccaoProps = {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
};
export const ModalErroConfirmarCadastroProspeccao: React.FC<
  ModalErroConfirmarCadastroProspeccaoProps
> = ({ open, handleClose, handleConfirm }) => {
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  return (
    <Modal
      show={open}
      onClose={() => {
        handleClose();
        handleConfirm();
      }}
    >
      <>
        <Text variant="body02-sm" margin>
          {TextosFeatureProspeccao.ERRO_CADASTRO_PRE_ANALISE}
        </Text>
        <Text variant="body02-sm" margin align="left">
          <InstrucoesRegerarPdf />
        </Text>
        <Display justify="center" style={{ marginTop: 20 }}>
          <Button
            variant="secondary"
            onClick={handleConfirm}
            data-testid="cancelar"
          >
            Ok
          </Button>
        </Display>
      </>
    </Modal>
  );
};
