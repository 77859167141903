import React from 'react';

import { tryGetValueOrDefault } from 'main/utils/conditional';
import Table from 'main/components/Table';
import { COLUNAS_TABELA_CONTAS_BANCARIAS } from 'previdencia/constants/constants';
import * as ALTERACAO_CONTA_TYPES from 'previdencia/features/AlteracaoFormaDadosPagamento/types/AlteracaoFormaDadosPagamento';

const TabelaContasBancarias: React.FC<
  ALTERACAO_CONTA_TYPES.ITabelaContasBancariasProps
> = ({ dados, canal, obterContaCadastradaSelecionada }) => {
  const colunas = COLUNAS_TABELA_CONTAS_BANCARIAS({
    canal,
    obterContaCadastradaSelecionada,
  });

  return (
    <Table
      noHeader
      responsive
      highlightOnHover
      striped
      data={tryGetValueOrDefault([dados], [])}
      columns={colunas}
      noDataComponent="Não há dados para exibir."
      pagination
      paginationPerPage={10}
      paginationComponentOptions={{
        rowsPerPageText: 'Items por página',
        rangeSeparatorText: 'de',
      }}
    />
  );
};

export default TabelaContasBancarias;
