import RenderConditional from 'main/components/RenderConditional';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { ButtonBoleto } from 'seguros/components/HistoricoPagamentos/styles';
import { PRAZO_MAXIMO_PARA_BOLETOS_ATRASADOS } from 'seguros/constants/HistoricoPagamentos';
import { IResponseHistoricoPagamentosTableProps } from 'vida/features/Pagamentos/types/HistoricoPagamentosTypes';

const calculaDiasDeAtraso = (vencimento: string): number => {
  const [dia, mes, ano] = vencimento.split('/').map(Number);
  const data = new Date(ano, mes - 1, dia);

  const diferencaEmMilisegundos = +new Date() - +data;

  return diferencaEmMilisegundos / (1000 * 60 * 60 * 24);
};

const validaImpressaoDeBoleto = ({
  situacao,
  opcaoPagamento,
  vencimento,
}: IResponseHistoricoPagamentosTableProps): boolean => {
  const pagamentoPendente = situacao === 'PENDENTE';
  const pagamentoPorBoleto = opcaoPagamento === 'BOLETO';

  const diasEmAtraso = calculaDiasDeAtraso(vencimento);

  const boletoDentroDoPrazo =
    Math.floor(diasEmAtraso) <= PRAZO_MAXIMO_PARA_BOLETOS_ATRASADOS;

  return checkIfAllItemsAreTrue([
    pagamentoPendente,
    pagamentoPorBoleto,
    boletoDentroDoPrazo,
  ]);
};

export const colunasTabelaHistoricoPagamentosFactory = (
  imprimirBoleto: (pagamento: IResponseHistoricoPagamentosTableProps) => void,
  loading: boolean,
) => [
  {
    name: 'Parcela',
    center: true,
    selector: 'numeroParcela',
  },
  {
    name: 'Situação',
    center: true,
    selector: 'situacao',
    minWidth: '120',
  },
  {
    name: 'Opção de pagamento',
    center: true,
    selector: 'opcaoPagamento',
  },
  {
    name: 'Vencimento',
    center: true,
    selector: 'vencimento',
    minWidth: '120',
  },
  {
    name: 'Valor',
    center: true,
    selector: 'valor',
    minWidth: '120',
  },
  {
    name: 'Ação',
    center: true,
    selector: (row: IResponseHistoricoPagamentosTableProps) => (
      <RenderConditional condition={validaImpressaoDeBoleto(row)}>
        <ButtonBoleto
          data-testid="botao-imprimir-boleto"
          className="hide-print"
          variant="secondary"
          small
          loading={loading}
          onClick={() => imprimirBoleto(row)}
        >
          Gerar Boleto
        </ButtonBoleto>
      </RenderConditional>
    ),
  },
];
