import { obterEndpointListaDadosDevolucaoPVR } from 'reter/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { ResponsePendenteDevolucao } from 'reter/features/retencao/types/ResponsePendenteDevolucao';
import { ResponsePosPVR } from 'reter/features/retencao/types/enum';
import { preencherValorPadrao } from 'reter/features/retencao/utils/preencherValorPadrao';

export const obterListaPendenteDevolucao = async (
  agencia: string,
  UserName: string,
): Promise<ResponsePendenteDevolucao[] | undefined> => {
  const { data } = await api.post<IApiResponse<ResponsePendenteDevolucao[]>>(
    obterEndpointListaDadosDevolucaoPVR(),
    {
      codigoEmpresa: ResponsePosPVR.COD_EMPRESA,
      agencia: preencherValorPadrao('000', agencia),
      UserName,
    },
  );

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw mensagemErro;

  return data?.dados?.entidade;
};
