import styled from 'styled-components/macro';

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const ContainerItens = styled.div({
  position: 'relative',
  width: '100%',
  height: '0',
});

export const StyledUl = styled.ul<{
  show: boolean;
}>(({ show }) => ({
  background: '#FFFFFF',
  boxSizing: 'border-box',
  listStyle: 'none',
  padding: '0',
  margin: '0',
  position: 'absolute',
  left: '0',
  width: '100%',
  top: '0',
  zIndex: '1',
  maxHeight: '200px',
  overflowY: 'auto',
  display: show ? 'block' : 'none',
}));

export const StyledLi = styled.li({
  fontSize: '16px',
  background: '#FFFFFF',
  border: '1px solid #DBDCDE',
  padding: '12px 16px',
  cursor: 'pointer',
  color: '#414042',
  fontWeight: '400',
  lineHeight: '150%',
});
