import { obterEndpointAtualizarEmail } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { RequestAlterarEmail } from '../types/DadosParticipante';

export const atualizarEmail = async (
  cpfCnpj: string,
  numeroCertificado: string,
  request: RequestAlterarEmail | undefined,
): Promise<IApiResponse<undefined> | undefined> => {
  if (request) {
    const { data } = await api.post<IApiResponse<undefined>>(
      obterEndpointAtualizarEmail(),
      {
        cpfCnpj,
        numeroCertificado,
        emailId: request.emailId,
        email: request.email,
        tipoEmail: request.tipoEmail,
      },
    );
    const mensagemErro: string | undefined =
      data?.dados?.mensagens?.[0].descricao;

    if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

    return data;
  }

  return undefined;
};
