import React from 'react';
import Accordion from 'main/components/PEComponents/Accordion';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { TableSemHeader } from 'seguros/components/TabelaApoliceCoberturas/styles';
import * as CONSTS from 'seguros/constants/TabelaApoliceCoberturas';
import * as APOLICE_TYPES from 'seguros/types/ApoliseCobertura';
import DetalhesCobertura from 'seguros/components/TabelaApoliceCoberturas/DetalhesCobertura';

export const TabelaApoliceCoberturas: React.FC<
  APOLICE_TYPES.TabelaApoliceCoberturasProps
> = ({ data, columns }) => {
  const hasMoreDetails = data.some(item => item.descricaoCobertura);

  return (
    <RenderConditional condition={!!data.length}>
      <Accordion open className="hide-print">
        <Accordion.Item title="Coberturas e Assistências" key="coberturas">
          <TableSemHeader
            noHeader
            responsive
            striped
            highlightOnHover
            columns={tryGetValueOrDefault(
              [columns],
              CONSTS.COLUNAS_TABELA_COBERTURAS,
            )}
            data={tryGetValueOrDefault([data], [])}
            expandableRows
            expandableRowDisabled={(
              row: APOLICE_TYPES.ITabelaApoliceCoberturasItens | any,
            ) => !row.descricaoCobertura}
            expandableRowsComponent={<DetalhesCobertura />}
            expandableRowsHideExpander={!hasMoreDetails}
            noDataComponent={CONSTS.SEM_DADOS_COBERTURAS}
            pagination
            paginationPerPage={10}
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de',
            }}
          />
        </Accordion.Item>
      </Accordion>
    </RenderConditional>
  );
};
