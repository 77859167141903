import {
  PRINCIPAL_USERS,
  TODOS_USUARIOS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import { IMenuItem } from 'main/components/Menu/Sidebar/types';

import {
  ROTA_RETENCAO_PRESTAMISTA,
  TITULO_RETENCAO_PRESTAMISTA,
} from 'painelPrestamista/features/reversao/constants/reversaoPrestamista';

export const menuContratosPrestamista: IMenuItem = {
  label: 'Prestamista',
  alt: 'prestamista',
  path: '/prestamista',
  icon: 'extrato',
  roles: [
    ...TODOS_USUARIOS,
    USER_PROFILES.ANALISTA_MANUTENCAO,
    USER_PROFILES.ANALISTA_SAIDA,
    USER_PROFILES.ANALISTA_ENTRADA,
    USER_PROFILES.ANALISTA_CONSULTA,
    USER_PROFILES.ECONOMIARIO,
  ],
  subItems: [
    {
      label: 'Propostas',
      path: '/prestamista/preanalises',
      roles: [
        USER_PROFILES.ANALISTA_PRODUTO,
        USER_PROFILES.OPERADOR,
        USER_PROFILES.MEDICO,
        USER_PROFILES.ADMINISTRADOR,
        USER_PROFILES.ANALISTA_CVP,
        USER_PROFILES.ECONOMIARIO,
        USER_PROFILES.ANALISTA_TI,
        USER_PROFILES.ANALISTA_ENTRADA,
        USER_PROFILES.ANALISTA_CONSULTA,
      ],
      subItems: [
        {
          label: 'Pré-análise de propostas',
          path: '/prestamista/preanalise/cadastro',
          roles: [
            ...TODOS_USUARIOS,
            USER_PROFILES.ANALISTA_ENTRADA,
            USER_PROFILES.ANALISTA_CONSULTA,
          ],
          subItems: [
            {
              label: 'Cadastro de pré-análise',
              path: '/prestamista/preanalise/cadastro',
              roles: PRINCIPAL_USERS,
            },
            {
              label: 'Consultar pré-análise',
              path: '/prestamista/preanalises',
              roles: [
                ...PRINCIPAL_USERS,
                USER_PROFILES.ANALISTA_ENTRADA,
                USER_PROFILES.ANALISTA_CONSULTA,
              ],
            },
            {
              label: 'Registro do parecer técnico',
              path: '/prestamista/preanalises/analise-produto',
              roles: [
                USER_PROFILES.ANALISTA_TI,
                USER_PROFILES.ANALISTA_PRODUTO,
                USER_PROFILES.ANALISTA_ENTRADA,
              ],
            },
            {
              label: 'Registro do parecer médico',
              path: '/prestamista/preanalises/analise-medica',
              roles: [
                USER_PROFILES.ANALISTA_TI,
                USER_PROFILES.MEDICO,
                USER_PROFILES.ANALISTA_ENTRADA,
              ],
            },
            {
              label: 'Visualizar oportunidades avaliadas',
              path: '/prestamista/preanalises/avaliadas',
              roles: [
                USER_PROFILES.ANALISTA_TI,
                USER_PROFILES.OPERADOR,
                USER_PROFILES.ANALISTA_ENTRADA,
              ],
            },
          ],
        },
        {
          label: 'Upload de proposta e DPS(link)',
          path: '/prestamista/upload-proposta-antecipado',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.OPERADOR,
            USER_PROFILES.ANALISTA_ENTRADA,
            USER_PROFILES.ANALISTA_CONSULTA,
          ],
          subItems: [
            {
              label: 'Realizar upload',
              path: '/prestamista/dps/identificacao',
              roles: [
                ...PRINCIPAL_USERS,
                USER_PROFILES.OPERADOR,
                USER_PROFILES.ANALISTA_ENTRADA,
                USER_PROFILES.ANALISTA_CONSULTA,
              ],
            },
            {
              label: 'Consultar upload',
              path: '/prestamista/proposta/upload/consulta',
              roles: [
                ...PRINCIPAL_USERS,
                USER_PROFILES.OPERADOR,
                USER_PROFILES.ANALISTA_ENTRADA,
                USER_PROFILES.ANALISTA_CONSULTA,
              ],
            },
            {
              label: 'Pendências',
              path: '/prestamista/dps/status/consulta',
              roles: [
                ...PRINCIPAL_USERS,
                USER_PROFILES.OPERADOR,
                USER_PROFILES.ANALISTA_ENTRADA,
                USER_PROFILES.ANALISTA_CONSULTA,
              ],
            },
            {
              label: 'Reenvio Link DPS Eletrônica',
              path: '/prestamista/dps/status/reenvio-dps',
              roles: [USER_PROFILES.ANALISTA_TI],
            },
            {
              label: 'Consultar status proposta',
              path: '/prestamista/proposta/status/consulta',
              roles: [USER_PROFILES.ANALISTA_TI],
            },
          ],
        },
      ],
    },
    {
      label: 'Retenção de prestamista',
      path: '/prestamista/cancelamentos',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.OPERADOR,
        USER_PROFILES.ANALISTA_SAIDA,
      ],
    },
    {
      label: 'Formulários (FOPs)',
      path: '/extranet/fop-prestamista',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_MANUTENCAO,
        USER_PROFILES.ANALISTA_SAIDA,
        USER_PROFILES.ANALISTA_ENTRADA,
        USER_PROFILES.ANALISTA_CONSULTA,
      ],
    },
    {
      label: TITULO_RETENCAO_PRESTAMISTA,
      path: ROTA_RETENCAO_PRESTAMISTA,
      roles: [USER_PROFILES.ANALISTA_TI],
    },
    {
      label: 'Relatório Declínio',
      path: '/hierarquia-prestamista-declinio',
      roles: [
        USER_PROFILES.ANALISTA_TI,
        USER_PROFILES.ECONOMIARIO,
        USER_PROFILES.ANALISTA_CONSULTA,
        USER_PROFILES.ANALISTA_ENTRADA,
        USER_PROFILES.ANALISTA_POS_VENDA,
      ],
    },
  ],
};
