import { tryGetValueOrDefault } from 'main/utils/conditional';
import { IStatusSinistroPrevidenciaDados } from 'sinistro/features/statusSinistro/types/IStatusSinistro';
import { ResultadoSinistroProdutoProps } from 'sinistro/features/statusSinistro/types/StatusSinistroComponentProps';
import { TipoProduto } from 'sinistro/features/statusSinistro/types/enum';
import { normalizarFormatoSeparadorData } from 'sinistro/features/statusSinistro/utils/normalizarFormatoSeparadorData';

export function produtosPrevidenciaFactory(
  responseStatusSinistroPrevidencia?: IStatusSinistroPrevidenciaDados,
) {
  const listaPrevidencia = tryGetValueOrDefault(
    [responseStatusSinistroPrevidencia?.andamentosCertificados],
    [],
  );
  return listaPrevidencia.map(produto => {
    return {
      tipoProduto: TipoProduto.PREVIDENCIA,
      descricaoProduto: 'Previdência',
      codigoCertificado: produto.codigoCertificado,
      numeroAvisoSinistro: String(produto.numeroAvisoSinistro),
      dataSinistro: normalizarFormatoSeparadorData(produto.dataSinistro),
      status: produto.status,
    } as ResultadoSinistroProdutoProps;
  });
}
