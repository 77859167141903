import styled from 'styled-components/macro';
import { Display } from '@cvp/design-system/react';

export const ContainerGrafico = styled.div`
  width: 100%;
  border: solid 1px gray;
  justify-content: space-between;
  display: flex;
`;

export const ContainerPrint = styled.div`
  page-break-inside: avoid;
  page-break-before: always;
  @media print {
    padding-top: 20px;
    height: 120vh;
  }
`;

export const DisplayWidth = styled(Display)(() => ({
  width: '100%',
}));
