import { useState } from 'react';

const useMensagemTemporaria = (delay = 10000) => {
  const [mensagemTemporaria, setMensagemTemporaria] = useState<string>('');

  const configurarMensagemTemporaria = (mensagem = ''): void => {
    setMensagemTemporaria(mensagem);

    setTimeout(() => {
      setMensagemTemporaria('');
    }, delay);
  };

  return { configurarMensagemTemporaria, mensagemTemporaria };
};

export default useMensagemTemporaria;
