import guid from 'main/utils/guid';
import React, { useImperativeHandle } from 'react';
import ValidableProps from '../types/ValidableProps';
import { validateRules } from '../utils/validateRules';

type ValidationConfig = {
  skipBlur: boolean;
};

function withValidation<P extends ValidableProps<T>, T>(
  Component: React.FC<ValidableProps<T>>,
  config?: ValidationConfig,
): React.FC<P> {
  const validationId = guid();

  const HOC: React.FC<P> = ({ link, validationRules = [], ...props }) => {
    const validate = () => {
      const { isValid, errorMsg } = validateRules<T>(
        validationRules,
        link.get().value,
      );
      link.set({ value: link.get().value, isValid, errorMsg });
      return { isValid, errorMsg };
    };

    useImperativeHandle(
      link.ref,
      () => ({
        inputElement: document.querySelector(
          `[data-validation='${validationId}'] input`,
        ),
        validate,
        scrollIntoView: () => {
          document
            .querySelector(`[data-validation='${validationId}']`)
            ?.scrollIntoView({
              block: 'center',
              behavior: 'smooth',
            });
        },
      }),
      [validate, validationRules],
    );

    return (
      <Component
        link={link}
        validationRules={validationRules}
        data-validation={validationId}
        onBlur={() => {
          if (!config?.skipBlur) {
            validate();
          }
        }}
        {...props}
      />
    );
  };
  HOC.displayName = 'ValidationHOC';
  return HOC;
}
export default withValidation;
