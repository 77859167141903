import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  background: solid 1px ${props => props.theme.color.neutral['06']};
  padding: 20px;
  width: 100%;
`;

const keyframesGradient = keyframes`
  0% { transform: translateX(0%) }
  50% { transform: translateX(-50%) }
  100% { transform: translateX(0%) }
`;

export const Rect = styled.div`
  width: 100%;
  height: 19px;
  margin-bottom: 22px;
  overflow: hidden;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  &:before {
    background-image: linear-gradient(
      45deg,
      transparent,
      ${props => props.theme.color.line.dark},
      transparent
    );
    animation: ${keyframesGradient} 1.5s 0s infinite;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
  }
`;
