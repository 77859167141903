import styled from 'styled-components/macro';
import { Display as DisplayDS } from '@cvp/design-system/react';

export const ContainerAlert = styled(DisplayDS)(({ theme: { color } }) => ({
  border: `1px solid ${color.brandSecondary.medium}`,
  background: color.feedback.alert,
  color: color.neutral['01'],
  padding: '10px',

  '& p': {
    color: color.neutral['01'],
  },
}));
