import { usePeco } from 'main/hooks/usePeco';
import {
  IResponseObterListaFopsAtivos,
  RequestListarFops,
} from '../types/IFops';
import { PECOS_PAINEL_ADM } from 'main/config/endpoints';

export const usePecoObterListaFopsAtivos = () => {
  return usePeco<RequestListarFops, IResponseObterListaFopsAtivos[]>({
    api: {
      operationPath: PECOS_PAINEL_ADM.ObterListaFopsAtivos,
    },
    autoFetch: true,
  });
};
