import React from 'react';
import { Text } from '@cvp/design-system/react';
import InstrucoesRegerarPdf from 'main/components/InstrucoesRegerarPdf';
import { ERRO_UPLOAD_PROPOSTA } from 'contratosPrestamista/constants';

const TextoErroUploadProposta: React.FC = () => {
  return (
    <>
      <Text variant="body02-lg" margin>
        {ERRO_UPLOAD_PROPOSTA}
      </Text>
      <Text variant="body02-sm" margin align="left">
        <InstrucoesRegerarPdf />
      </Text>
    </>
  );
};

export default TextoErroUploadProposta;
