import React from 'react';
import { Text, Grid, Button } from '@cvp/design-system/react';

import { Display } from 'main/features/Auth/components';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import VoltarDadosPlano from 'main/components/Button/voltarDadosPlano/VoltarDadosPlano';
import masks from 'main/utils/masks';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { obterCodigoOperacaoENumeroConta } from 'previdencia/utils/previdencia';
import { IDadosPagamentoAtualProps } from 'previdencia/features/AlteracaoFormaDadosPagamento/types/AlteracaoFormaDadosPagamento';

const DadosPagamentoAtual: React.FC<IDadosPagamentoAtualProps> = ({
  alterarConta,
  dadosResponsavel,
  valor,
  dadosCertificado,
}) => {
  const operacao: string = tryGetValueOrDefault(
    [
      obterCodigoOperacaoENumeroConta(
        tryGetValueOrDefault([dadosCertificado?.numeroConta], ''),
      ).operacao,
    ],
    '-',
  );

  const conta: string = tryGetValueOrDefault(
    [
      obterCodigoOperacaoENumeroConta(
        tryGetValueOrDefault([dadosCertificado?.numeroConta], ''),
      ).numeroConta,
    ],
    '-',
  );

  return (
    <Display type="display-block">
      <Grid margin>
        <Grid.Item xs={1}>
          <Text variant="body02-md" color="primary">
            Dados do pagamento atual
          </Text>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 4}>
          <Text variant="caption-02" color="text-light">
            CPF do responsável financeiro:
          </Text>
          <Text variant="caption-02" color="text">
            {masks.cpf.mask(dadosResponsavel?.cpf)}
          </Text>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 4}>
          <Text variant="caption-02" color="text-light">
            Nome do responsável financeiro:
          </Text>
          <Text variant="caption-02" color="text">
            {dadosResponsavel?.nome}
          </Text>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 4}>
          <Text variant="caption-02" color="text-light">
            Forma de pagamento:
          </Text>
          <Text variant="caption-02" color="text">
            Débito em conta
          </Text>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 4}>
          <Text variant="caption-02" color="text-light">
            Valor de contribuição:
          </Text>
          <Text variant="caption-02" color="text">
            {tryGetMonetaryValueOrDefault(valor)}
          </Text>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 4}>
          <Text variant="caption-02" color="text-light">
            Dia de vencimento:
          </Text>
          <Text variant="caption-02" color="text">
            {tryGetValueOrDefault([dadosCertificado?.diaPagamento], '-')}
          </Text>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 4}>
          <Text variant="caption-02" color="text-light">
            Operação:
          </Text>
          <Text variant="caption-02" color="text">
            {operacao}
          </Text>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 4}>
          <Text variant="caption-02" color="text-light">
            Banco:
          </Text>
          <Text variant="caption-02" color="text">
            {tryGetValueOrDefault([dadosCertificado?.numeroBanco], '-')}
          </Text>
        </Grid.Item>

        <Grid.Item xs={1} md={1 / 4}>
          <Text variant="caption-02" color="text-light">
            Conta bancária:
          </Text>
          <Text variant="caption-02" color="text">
            {conta}-{dadosCertificado?.digitoConta}
          </Text>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 4}>
          <Text variant="caption-02" color="text-light">
            Agência:
          </Text>
          <Text variant="caption-02" color="text">
            {tryGetValueOrDefault(
              [masks.agency.mask(dadosCertificado?.numeroAgencia)],
              '-',
            )}
          </Text>
        </Grid.Item>
        <Grid.Item xs={1}>
          <Display>
            <VoltarDadosPlano />
            <Button onClick={alterarConta}>Alterar</Button>
          </Display>
        </Grid.Item>
        <br />
      </Grid>
    </Display>
  );
};

export default DadosPagamentoAtual;
