import styled from 'styled-components/macro';
import { TextField as TextFieldDS } from '@cvp/design-system/react';

export const TextField = styled(TextFieldDS)`
  & input {
    height: 45px;
  }

  & label {
    margin-bottom: 0.2rem;
  }
`;

export const WrapperInfo = styled.div(({ theme: { color } }) => ({
  border: `1px solid ${color.neutral['03']}`,
  background: `${color.neutral['03']}0d`,
  padding: 20,
  borderRadius: 8,
  marginTop: 20,

  p: {
    paddingBottom: 7,

    '&:last-child': {
      paddingBottom: 0,
    },
  },
}));

export const WrapperSection = styled.div(() => ({
  marginBottom: 50,

  h6: {
    marginTop: 10,
    fontSize: 12,
  },
}));

export const WrapperOptions = styled.div(() => ({
  margin: '20px 0 30px 0',
  display: 'block',
}));
