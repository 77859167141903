import { PECOS_PRESTAMISTA } from 'contratosPrestamista/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import { useToast } from 'main/hooks/useToast';
import { IApiResponse } from 'main/services';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import { useState } from 'react';
import * as CancelamentoApi from '../services/cancelamento';
import { IRequestConfirmCode } from '../types/IRequestConfirmCode';
import { IRequestSendCode, IResponseSendCode } from '../types/IRequestSendCode';

export const useReversaoCancelamento = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorReponse, setError] = useState<unknown>(false);
  const { toastError } = useToast();

  const enviarCodigoConfirmacao = async (
    data: IRequestSendCode,
  ): Promise<IApiResponse<IResponseSendCode> | null> => {
    try {
      setLoading(true);
      const result = await CancelamentoApi.enviarCodigo(data);
      if (!result.dados.sucesso) toastError();

      return result;
    } catch (error: unknown) {
      toastError();
      setError(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const { loading: loadingConfirmar, fetchData: fetchDataConfirmar } = usePeco<
    IRequestConfirmCode,
    null
  >({
    api: { operationPath: PECOS_PRESTAMISTA.ConfirmarToken },
    autoFetch: false,
    handleResponse: {
      throwToastErrorGI: false,
      throwToastErrorBFF: false,
    },
  });

  return {
    enviarCodigoConfirmacao,
    confirmarReversaoCancelamento: fetchDataConfirmar,
    loading: checkIfSomeItemsAreTrue([loading, loadingConfirmar]),
    errorReponse,
  };
};
