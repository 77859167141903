import * as APOLICE_TYPES from 'seguros/types/ApoliseCobertura';
import * as RESPONSE_TYPES from 'seguros/types/ApoliceAssistenciasResponse';

export const mapeiaAssitenciasServicosParaAssistencias = (
  assistencia: RESPONSE_TYPES.ITabelaApoliceAssitenciasServicosResponse,
) => {
  return {
    descricao: assistencia.descricaoServico,
  } as APOLICE_TYPES.ITabelaApolice;
};

export const removerEspacosTabelaApolice = (
  servico: APOLICE_TYPES.ITabelaApolice,
): APOLICE_TYPES.ITabelaApolice => ({
  ...servico,
  descricao: String(servico.descricao).trim(),
});

export const removerAssistenciasDuplicadas = (
  data: APOLICE_TYPES.ITabelaApolice,
  index: number,
  array: APOLICE_TYPES.ITabelaApolice[],
) =>
  index ===
  array.findIndex(
    t =>
      String(t.descricao).toLocaleUpperCase().trim() ===
      String(data.descricao).toLocaleUpperCase().trim(),
  );

export const filtrarCertificado =
  (numCertificado: string | undefined) =>
  (certificado: RESPONSE_TYPES.ITabelaApoliceAssitenciasResponse) =>
    certificado.numeroContrato.toString() === String(numCertificado);
