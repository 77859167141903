import styled from 'styled-components/macro';
import DataTable from 'react-data-table-component';
import {
  Disclaimer as DisclaimerDS,
  Card as CardDS,
  Text as TextDS,
} from '@cvp/design-system/react';

export const Card = styled(CardDS)`
  min-height: auto;
`;

export const Disclaimer = styled(DisclaimerDS)({
  marginTop: 30,
  padding: '12px 0 12px 16px',
  button: {
    opacity: 0,
  },
  svg: {
    width: '22px',
    height: '22px',
  },
});

export const Table = styled(DataTable)(({ theme: { color, font } }) => ({
  '.rdt_TableCol, .rdt_TableCell': {
    fontSize: font.size.sm,
    fontFamily: font.family.base,
  },
  '.rdt_TableCol': {
    fontWeight: font.weight.lg,
    textAlign: 'center',
    '&:hover': {
      color: color.brandPrimary.light,
    },
  },
}));

export const TableLink = styled(TextDS)(({ theme: { color } }) => ({
  color: color.brandPrimary.light,
  textDecoration: 'underline',
  cursor: 'pointer',
  transition: '250ms ease-in all',

  '&:hover': {
    color: color.neutral['02'],
    transition: '250ms ease-in all',
  },
}));
