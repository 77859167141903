import React from 'react';
import { Redirect } from 'react-router-dom';
import { RouteProfileRedirectConfig } from '../config/routeProfileRedirect';
import { USER_PROFILES } from '../config/userProfiles';
import { useAuth } from '../hooks';

export const RedirectByProfile: React.FC = () => {
  const { userRoles } = useAuth();

  if (userRoles?.includes(USER_PROFILES.MEDICO))
    return <Redirect to={RouteProfileRedirectConfig.medico} />;
  if (userRoles?.includes(USER_PROFILES.ANALISTA_PRODUTO))
    return <Redirect to={RouteProfileRedirectConfig.analistaproduto} />;
  if (userRoles?.includes(USER_PROFILES.OPERADOR))
    return <Redirect to={RouteProfileRedirectConfig.operador} />;
  if (userRoles?.includes(USER_PROFILES.SAC_CAIXA_TERCEIROS))
    return <Redirect to={RouteProfileRedirectConfig.saccaixaterceiros} />;
  return <Redirect to={RouteProfileRedirectConfig.default} />;
};
