import { useContext, useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import masks from 'main/utils/masks';
import * as Api from '../services/graficoRentabilidadeCarteira.api';

export const useGraficoRentabilidadeCarteira = () => {
  const { toastError } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [responseInvestimento, setResponseInvestimento] = useState<string>();
  const [responseRentabilidade, setResponseRentabilidade] = useState<string>();
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);
  const gerarGraficosRentabilidade = async () => {
    try {
      setLoading(true);
      const resultInvestimento = await Api.obterGraficoRentabilidade(
        cpfCnpj,
        'investimento',
      );
      const resultRentabilidade = await Api.obterGraficoRentabilidade(
        cpfCnpj,
        'rentabilidade',
      );
      setResponseInvestimento(resultInvestimento);
      setResponseRentabilidade(resultRentabilidade);
    } catch (requestError: any) {
      toastError(masks.removeAcentos.mask(requestError.message));
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    responseInvestimento,
    responseRentabilidade,
    gerarGraficosRentabilidade,
  };
};
