export const validateEmail = (email?: string): boolean => {
  if (!email) return false;
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  return regex.test(email);
};

export const validateEmailToShowInEmailSender = (
  tipoEmail: string,
  cpfCnpjBeneficiario: string | undefined,
  emailDefault = '',
): string => {
  if (tipoEmail === 'InformeRendimentos' && cpfCnpjBeneficiario !== undefined)
    return '';

  return emailDefault;
};
