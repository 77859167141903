import { Card, Text, Button } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import RenderConditional from 'main/components/RenderConditional';
import { Display } from 'main/features/Auth/components';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { capitalizeExceptPrepositions } from 'main/utils/string';
import * as S from 'previdencia/features/SimulacaoRenda/components/ModalConsultaSimulacao/styles';
import { CERTIFICADO_NAO_ELEGIVEL } from 'previdencia/features/SimulacaoRenda/constants/ModalConsultaSimulacao';
import { useSimulacaoRendaContextData } from 'previdencia/features/SimulacaoRenda/hooks/useSimulacaoRendaContextData';
import usePrevSummary from 'previdencia/hooks/usePrevSummary';

const ModalConsultaSimulacao = () => {
  const { informacaoPorCertificado, isLoading } = usePrevSummary();
  const { featureData, exibirModal } = useSimulacaoRendaContextData();

  const modalidade = tryGetValueOrDefault(
    [informacaoPorCertificado?.produto.modalidade],
    '',
  );

  const regimeTributario = tryGetValueOrDefault(
    [informacaoPorCertificado?.regimeTributario],
    '',
  );

  const modalidadeRegine = `${modalidade} / ${regimeTributario}`;

  return (
    <S.Modal
      show={featureData?.exibirModalConsulta}
      onClose={() => exibirModal(false)}
    >
      <Display alignItems="center" justify="center">
        <Text color="primary-dark" margin>
          {CERTIFICADO_NAO_ELEGIVEL.replace(
            '{regimeTributario}',
            regimeTributario,
          )}
        </Text>

        <RenderConditional condition={!isLoading}>
          <Card>
            <Card.Content padding={[4, 4, 0, 4]}>
              <Display alignItems="center">
                <S.IconContainer>
                  <Icon name="user" />
                </S.IconContainer>
                <span>
                  <Text variant="caption-02">Titular</Text>
                  <S.TextClientName>
                    {capitalizeExceptPrepositions(
                      tryGetValueOrDefault(
                        [informacaoPorCertificado?.pessoa?.pessoaFisica?.nome],
                        '',
                      ),
                    )}
                  </S.TextClientName>
                </span>
              </Display>
              <Display alignItems="center">
                <S.IconContainer>
                  <Icon name="documentPaper" />
                </S.IconContainer>
                <span>
                  <Text variant="caption-02" color="text-light">
                    Certificado
                  </Text>
                  <Text variant="caption-02" color="text">
                    {informacaoPorCertificado?.certificadoNumero}
                  </Text>
                </span>
              </Display>
              <Display alignItems="center">
                <S.IconContainer>
                  <Icon name="documentBack" />
                </S.IconContainer>
                <span>
                  <Text variant="caption-02" color="text-light">
                    Modalidade / Regime Tributário
                  </Text>
                  <Text variant="caption-02" color="text">
                    {modalidadeRegine}
                  </Text>
                </span>
              </Display>
            </Card.Content>
          </Card>
        </RenderConditional>
      </Display>
      <Display alignItems="center" justify="center">
        <Button onClick={() => exibirModal(false)} small>
          Confirmar
        </Button>
      </Display>
    </S.Modal>
  );
};

export default ModalConsultaSimulacao;
