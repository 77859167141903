const guid = (): string => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000) // NOSONAR não é utilizado em um contexto crítico de segurança
      .toString(16)
      .substring(1);
  };
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export default guid;
