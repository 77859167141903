import React from 'react';
import { Grid, Text } from '@cvp/design-system/react';
import { INFORMACOES_REAJUSTE } from '../../constants/constants';
import CardReajusteEReenquadramento from '../CardReajusteEReenquadramento';

const ReajusteAnualPlanoHeader = () => {
  return (
    <div className="hide-print">
      <Grid.Item xs={1}>
        <Text variant="headline-06" color="primary">
          Reajuste Anual do Plano
        </Text>
      </Grid.Item>

      <Grid.Item xs={1}>
        <Text variant="body01-md" color="text-light" margin>
          {INFORMACOES_REAJUSTE.INFO_TITULO}
        </Text>
        <Text variant="body01-md" color="text-light" margin>
          {INFORMACOES_REAJUSTE.INFO_TITULO_COMPLEMENTAR}
        </Text>
      </Grid.Item>

      <CardReajusteEReenquadramento />
    </div>
  );
};

export default ReajusteAnualPlanoHeader;
