import styled from 'styled-components/macro';
import { Select as SelectDS } from '@cvp/design-system/react';
import { TextField as TextFieldDS } from 'main/features/Auth/components';

export const Select = styled(SelectDS)(absolute => ({
  '& div': {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
  },

  '& div:last-child': {
    position: absolute,
  },
}));

export const TextField = styled(TextFieldDS)(() => ({
  margin: '0',

  '& div input': {
    height: '40px',
  },
}));
