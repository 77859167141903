export const riskStatus = (value: number): { id: number; title: string } => {
  const risk = [
    { id: 1, title: 'Conservador' },
    { id: 2, title: 'Moderado' },
    { id: 3, title: 'Arrojado' },
    { id: 0, title: '' },
  ];

  if (!value) {
    return risk[0];
  }

  const [lowRisk, avgRisk, highRisk, none] = risk;
  let str = null;

  switch (true) {
    case value > 0 && value <= 32:
      str = lowRisk;
      break;
    case value >= 33 && value <= 69:
      str = avgRisk;
      break;
    case value >= 70 && value <= 100:
      str = highRisk;
      break;
    default:
      str = none;
      break;
  }

  return str;
};

export const levelRisk = (perfil?: string): number => {
  switch (perfil) {
    case 'Conservador':
      return 10;
    case 'Moderado':
      return 50;
    case 'Arrojado':
      return 70;
    case 'Agressivo':
      return 90;
    default:
      throw new Error();
  }
};
