export const TIPOS_EVOLUCAO = [
  { id: 'diaria', tipoEvolucao: 'Diária' },
  { id: 'mensal', tipoEvolucao: 'Mensal' },
];

export const FILTRO_EVOLUCAO = {
  DIARIA: 'diaria',
  MENSAL: 'mensal',
};

export const TIPOS_MODALIDADE = [
  { id: 'patrimonio', tipoModalidade: 'Patrimônio' },
  { id: 'rentabilidade', tipoModalidade: 'Rentabilidade' },
];

export const FILTRO_MODALIDADE = {
  PATRIMONIO: 'patrimonio',
  RENTABILIDADE: 'rentabilidade',
  LABEL_PATRIMONIO: 'Patrimônio',
  LABEL_RENTABILIDADE: 'Rentabilidade',
};

export const PERIODOS = [
  { label: 'Últimos 30 dias', id: '30' },
  { label: 'Últimos 3 meses', id: '90' },
  { label: 'Últimos 6 meses', id: '180' },
  { label: 'Últimos 9 meses', id: '270' },
  { label: 'Últimos 12 meses', id: '365' },
  { label: 'Ano anterior', id: '-2' },
  { label: 'Ano atual', id: '-3' },
  { label: 'Período Personalizado', id: '-1' },
];

export const FILTRO_PERIODOS = {
  ULTIMO_MES: '30',
  ULTIMOS_3_MESES: '90',
  ULTIMOS_6_MESES: '180',
  ULTIMOS_9_MESES: '270',
  ULTIMOS_12_MESES: '365',
  PERIODO_PERSONALIZADO: '-1',
  ANO_ANTERIOR: '-2',
  ANO_ATUAL: '-3',
};

export const INITIAL_FORMIK_STATE = {
  periodoPersonalizado: false,
  periodo: FILTRO_PERIODOS.ULTIMOS_12_MESES,
  tipoEvolucao: TIPOS_EVOLUCAO[0].id,
  tipoModalidade: TIPOS_MODALIDADE[0].id,
};

export const MENSAGEM_NO_DATA =
  'Não há dados de Evolução Patrimonial para serem exibidos com o filtro selecionado.';

export const DESCRICAO_EVOLUCAO_PATRIMONIAL =
  'Acompanhe a evolução diária ou mensal da reserva acumulada no plano de previdência.';
