import React, { useEffect } from 'react';
import { Display, Text, Button, Skeleton } from '@cvp/design-system/react';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import TabelaMultifundosPJ from 'previdencia/components/TabelaMultifundosPJ/TabelaMultifundosPJ';
import { MultifundoPjData } from 'previdencia/types/MultifundosPJ';
import { ITransferenciaMultifundosPjFeatureData } from 'previdencia/components/TabelaMultifundosPJ/types/ITransferenciaMultifundosPjFeatureData';
import { EnumEtapas } from 'previdencia/types/Fundo.type';
import { PAGE_TEXTS } from '../../constants/texts';
import TabelaResumo from '../TabelaResumo/TabelaResumo';
import useListarFundos from '../../hooks/useListarFundos';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from '../../../../../main/utils/money';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import useDefinirSaida from '../../hooks/useDefinirSaida';
import { formatarFundosPayload } from '../../factories/fundosFactory';
import { ENUM_TIPO_FUNDO } from '../../types/Enum';

const SelecionarFundosOrigem: React.FC = () => {
  const { setFeatureData, featureData } =
    usePrevidenciaContext<ITransferenciaMultifundosPjFeatureData>();
  const { response: responseListarFundos, loading: loadingListarFundos } =
    useListarFundos();
  const {
    response: responseDefinirFundos,
    loading: loadingDefinirFundos,
    fetchData: definirSaida,
  } = useDefinirSaida(
    formatarFundosPayload(
      featureData?.fundosSelecionados,
      ENUM_TIPO_FUNDO.ORIGEM,
    ),
  );

  const desabilitarBotao = () => {
    if (
      featureData?.fundosSelecionados?.some(fundo =>
        fundo.reservas.some(
          reserva =>
            reserva.isParcial &&
            !tryGetValueOrDefault([reserva.valorTransferencia], 0),
        ),
      )
    )
      return true;

    return checkIfSomeItemsAreTrue([
      tryGetValueOrDefault([featureData?.fundosSelecionados], []).length < 1,
      Number(tryGetValueOrDefault([featureData?.dataResumo], [])[4].valor) ===
        0,
    ]);
  };
  useEffect(() => {
    if (responseDefinirFundos?.numeroTransferencia) {
      setFeatureData({
        ...featureData,
        idRequisicao: responseDefinirFundos?.numeroTransferencia,
        fundosDestinoDisponiveis: responseDefinirFundos,
        valorMinimoTransferencia:
          responseDefinirFundos.valorMinimoTransferencia,
        etapa: EnumEtapas.selecionarFundosDestino,
      });
    }
  }, [responseDefinirFundos]);

  return (
    <>
      <Text variant="body02-sm" margin>
        {PAGE_TEXTS.SELECIONE_FUNDO}
      </Text>

      <RenderConditional condition={!loadingListarFundos}>
        <Text variant="body02-sm" margin>
          {PAGE_TEXTS.VALOR_MINIMO_PARCIAL}
          <b>
            {tryGetMonetaryValueOrDefault(
              [responseListarFundos?.valorMinimoTransferencia],
              0,
            )}
          </b>
        </Text>
      </RenderConditional>
      <RenderConditional condition={loadingListarFundos}>
        <Skeleton />
      </RenderConditional>

      <Text variant="body02-md" color="primary">
        Origem
      </Text>
      <TabelaMultifundosPJ
        fundos={responseListarFundos?.fundos}
        valorMinimo={tryGetValueOrDefault(
          [responseListarFundos?.valorMinimoTransferencia],
          0,
        )}
        quantidadeMaximaFundos={tryGetValueOrDefault(
          [responseListarFundos?.quantidadeMaximaFundos],
          0,
        )}
        isLoading={loadingListarFundos}
        setFundosSelecionados={(fundos: MultifundoPjData[]) => {
          setFeatureData({
            ...featureData,
            fundosSelecionados: fundos,
          });
        }}
      />
      <Text variant="body02-sm">
        <b>Importante</b>
      </Text>
      <Text variant="body02-sm" margin>
        {PAGE_TEXTS.IMPORTANTE}
      </Text>
      <TabelaResumo />
      <Display justify="end">
        <Button
          loading={loadingDefinirFundos}
          disabled={desabilitarBotao()}
          onClick={() => definirSaida()}
        >
          Continuar
        </Button>
      </Display>
    </>
  );
};

export default SelecionarFundosOrigem;
