import React, { useContext, useEffect } from 'react';
import { ListaPreAnalise } from 'main/features/prospeccao/components/ListaPreAnalise';
import { IProspeccao } from 'main/features/prospeccao/types/IProspeccao';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { useListarProspeccoes } from 'main/features/prospeccao/hooks/useListarProspeccoes';
import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';
import { ProspeccaoContext } from 'main/features/prospeccao/contexts/ProspeccaoContext';

export const ListaProspeccoes: React.FC = () => {
  const { getDefaultRouteDetails } = useContext(ProspeccaoContext);

  const {
    obterListaProspeccoesPerfilEconomiario,
    prospeccoesPerfilEconomiario,
  } = useListarProspeccoes();

  useEffect(() => {
    obterListaProspeccoesPerfilEconomiario();
  }, []);

  if (prospeccoesPerfilEconomiario.loading) {
    return <SkeletonLoading lines={4} />;
  }

  return (
    <ListaPreAnalise
      profile={(USER_PROFILES.ANALISTA_POS_VENDA, USER_PROFILES.ECONOMIARIO)}
      data={prospeccoesPerfilEconomiario.items ?? []}
      noDataMessage="Não há pré-análises cadastradas"
      onClickDetails={(prospeccao: IProspeccao) =>
        getDefaultRouteDetails(prospeccao)
      }
    />
  );
};
