import React, { useContext, useEffect } from 'react';
import {
  Card,
  Display,
  Grid,
  Hidden,
  Text,
  Modal,
  Button,
} from '@cvp/design-system/react';
import ilustracao from 'assets/img/ilustracoes/prev/milton.svg';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { AppContext } from 'main/contexts/AppContext';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import masks from 'main/utils/masks';
import TagStatusSeguro from 'seguros/components/TagStatusSeguro';
import { useCertificadoSeguroResumo } from 'seguros/hooks/useCertificadoSeguroResumo';
import { InfoGridItem } from 'seguros/components/CardResumoProduto/InfoGridItem/InfoGridItem';
import useVidaNavigation from 'seguros/hooks/useVidaNavigation';
import { TIPO_CERTIFICADO } from 'seguros/constants/Seguros';
import { TEXTOS_CARD_RESUMO } from 'seguros/constants/CardResumoProduto';
import * as S from './styles';
import { CardResumoProdutoImpressao } from 'seguros/components/CardResumoProduto/CardResumoProdutoImpressao/CardResumoProdutoImpressao';

export const CardResumoProduto: React.FC = () => {
  const { goHome } = useVidaNavigation();

  const { clienteVida } = useContext(AppContext);

  const {
    certificadoSeguros: { response, loading, fetchData },
  } = useCertificadoSeguroResumo({
    numeroContrato: tryGetValueOrDefault([clienteVida?.numCertificado], '0'),
    codigoProduto: tryGetValueOrDefault([clienteVida?.codigoProduto], '0'),
  });

  const dadosSeguros = response?.entidade;

  const dadosGerais = dadosSeguros?.dadosGeraisCertificado;

  const tipoContrato = dadosSeguros?.tipoContrato;

  const parametrosIndividual = dadosSeguros?.individual?.proposta?.parametros;

  const status =
    dadosGerais?.statusContrato === undefined
      ? ''
      : dadosGerais?.statusContrato;

  const obterVigenciaValor = (): string => {
    if (tipoContrato === TIPO_CERTIFICADO.INDIVIDUAL) {
      return tryGetValueOrDefault(
        [
          `${parametrosIndividual?.dataInicioVigenciaCertificado} a ${parametrosIndividual?.dataTerminoVigenciaCertificado}`,
        ],
        '',
      );
    }

    if (tipoContrato === TIPO_CERTIFICADO.EMPRESARIAL) {
      return tryGetValueOrDefault(
        [
          dadosSeguros?.empresarial?.dadosContratoEmpresarial?.periodoVigencia?.toLowerCase(),
        ],
        '',
      );
    }

    if (tipoContrato === TIPO_CERTIFICADO.BILHETE) {
      return tryGetValueOrDefault(
        [
          dadosSeguros?.bilhete?.dadosContratoBilhete?.periodoVigencia?.toLowerCase(),
        ],
        '',
      );
    }

    return '';
  };

  const obterVigenciaLabel = (): string => {
    if (tipoContrato === TIPO_CERTIFICADO.EMPRESARIAL) {
      return TEXTOS_CARD_RESUMO.LABEL_VIGENCIA_EMPRESARIAL;
    }

    return TEXTOS_CARD_RESUMO.LABEL_VIGENCIA;
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <SkeletonLoading lines={4} />;
  return (
    <>
      <Display className="hide-print" type="display-block">
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <Grid.Item xs={1}>
                <Display alignItems="center">
                  <Text variant="headline-05" color="primary">
                    {dadosGerais?.nomeProduto}
                  </Text>
                  <TagStatusSeguro status={status} />
                </Display>
              </Grid.Item>
              <Hidden only={['xs', 'sm', 'md', 'lg']}>
                <Grid.Item xl={1 / 10}>
                  <Display
                    style={{ height: '100%' }}
                    alignItems="center"
                    justify="center"
                  >
                    <img src={ilustracao} alt="Ilustração Apólice" />
                  </Display>
                </Grid.Item>
              </Hidden>
              <Grid.Item lg={9 / 10} md={1}>
                <Display alignItems="center">
                  <Grid>
                    <InfoGridItem
                      icon="user"
                      label="Nome do Cliente"
                      value={
                        <S.TextClientName>
                          {tryGetValueOrDefault(
                            [dadosGerais?.nomeSegurado],
                            '',
                          )}
                        </S.TextClientName>
                      }
                    />
                    <InfoGridItem
                      icon="documentFront"
                      label="Documento do Cliente"
                      value={masks.cpfCnpj.mask(
                        tryGetValueOrDefault([dadosGerais?.cpfSegurado], ''),
                      )}
                    />
                    <InfoGridItem
                      icon="documentPaper"
                      label="Número da Apólice"
                      value={tryGetValueOrDefault(
                        [dadosGerais?.numeroApolice],
                        '',
                      )}
                    />
                    <InfoGridItem
                      icon="documentBack"
                      label="Certificado/Bilhete"
                      value={`${tryGetValueOrDefault(
                        [dadosGerais?.numeroCertificado],
                        '',
                      )} (${tryGetValueOrDefault([tipoContrato], '')})`}
                    />
                    <InfoGridItem
                      icon="calendar"
                      label={obterVigenciaLabel()}
                      value={obterVigenciaValor()}
                    />
                    <InfoGridItem
                      icon="information"
                      label="Situação"
                      value={tryGetValueOrDefault(
                        [dadosGerais?.statusContrato],
                        '',
                      )}
                    />
                  </Grid>
                </Display>
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </Display>

      <Modal show={!dadosSeguros} onClose={goHome}>
        <Text variant="body02-sm" margin>
          {TEXTOS_CARD_RESUMO.MODAL_SEM_DADOS}
        </Text>
        <Display justify="center">
          <Button variant="secondary" onClick={goHome}>
            Certo, entendi!
          </Button>
        </Display>
      </Modal>

      <CardResumoProdutoImpressao
        status={status}
        tipoContrato={tipoContrato}
        dadosGerais={dadosGerais}
        obterVigenciaValor={obterVigenciaValor}
        obterVigenciaLabel={obterVigenciaLabel}
      />
    </>
  );
};
