import * as Yup from 'yup';

import * as CONSTS from 'contratosPrestamista/features/reenvioDpsEletronica/constants/constants';

export const FORMIK_VALIDATION = Yup.object().shape({
  nome: Yup.string()
    .required(CONSTS.FORMIK_VALIDATION_MESSAGES.CAMPO_OBRIGATORIO)
    .min(3, 'Informe um nome válido'),
  cpf: Yup.string().required(
    CONSTS.FORMIK_VALIDATION_MESSAGES.CAMPO_OBRIGATORIO,
  ),
  dataNascimento: Yup.string().required(
    CONSTS.FORMIK_VALIDATION_MESSAGES.CAMPO_OBRIGATORIO,
  ),
  telefone: Yup.string().required(
    CONSTS.FORMIK_VALIDATION_MESSAGES.CAMPO_OBRIGATORIO,
  ),
  email: Yup.string()
    .email(CONSTS.FORMIK_VALIDATION_MESSAGES.EMAIL_INVALIDO)
    .nullable(),
});
