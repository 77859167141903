import { useContext } from 'react';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS_VIDA } from 'seguros/config/endpoints';
import * as REQUEST_TYPES from 'seguros/types/SegundaViaCertificadosSeguroPDFRequest';
import * as RESPONSE_TYPES from 'seguros/types/SegundaViaCertificadosSeguroPDFResponse';
import { TIPO_CERTIFICADO } from 'seguros/constants/Seguros';

export const useSegundaViaCertificadosSegurosPdf = () => {
  const { clienteVida } = useContext(AppContext);

  const numeroCertificadoBilhete =
    clienteVida?.tipoCertificado?.toString() === TIPO_CERTIFICADO.BILHETE
      ? tryGetValueOrDefault([clienteVida?.numApolice], '')
      : tryGetValueOrDefault([clienteVida?.numCertificado], '');

  const {
    response: dadosSegundaViaCertificado,
    loading: loadingSegundaViaCertificadoo,
    error,
  } = usePeco<
    REQUEST_TYPES.ISegundaViaCertificadoRequest,
    RESPONSE_TYPES.ISegundaViaCertificadoResponse
  >({
    api: { operationPath: PECOS_VIDA.SegundaViaCertificadosSegurosPdf },
    autoFetch: true,
    payload: {
      numeroCertificadoBilhete,
      tipoCertificado: clienteVida?.tipoCertificado?.toString(),
    },
    handleResponse: { throwToastErrorBFF: false },
  });

  return {
    dadosSegundaViaCertificado,
    loadingSegundaViaCertificadoo,
    error,
  };
};
