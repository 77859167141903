import React from 'react';
import { Text } from '@cvp/design-system/react';
import masks from 'main/utils/masks';
import ButtonsMudarEtapa from 'previdencia/components/ButtonsMudarEtapa';
import LinkedValue from 'main/features/Validation/types/LinkedValue';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import * as S from '../styles';
import useFaixaRendaBeneficioProtecao from '../../hooks/useFaixaRendaBeneficioProtecao';
import { validaFaixaRenda } from '../../utils/beneficioDeProtecao';

type FaixaRendaProps = {
  rendaBrutaLink: LinkedValue<string>;
  proximaEtapa: () => void;
  etapaAnterior: () => void;
};
const FaixaRenda: React.FC<FaixaRendaProps> = ({
  rendaBrutaLink,
  proximaEtapa,
  etapaAnterior,
}) => {
  const { response, loading } = useFaixaRendaBeneficioProtecao();
  const faixaRenda = response?.entidade;
  if (loading) {
    return <SkeletonLoading lines={6} />;
  }
  return (
    <>
      <Text variant="body01-lg" color="text" margin>
        Faixa de Renda
      </Text>
      <Text variant="body02-sm" color="text" margin>
        Informe a renda bruta individual para apresentarmos as opções de
        coberturas disponíveis.
      </Text>
      <Text variant="body02-sm" color="text" margin>
        Faixa de Renda:{' '}
        <b>
          {faixaRenda?.valorMinimo
            ? `${formatarValorPadraoBrasileiro(faixaRenda?.valorMinimo)} a `
            : ' até '}
          {formatarValorPadraoBrasileiro(faixaRenda?.valorMaximo)}
        </b>
      </Text>
      <div>
        <S.StyledInput
          link={rendaBrutaLink}
          placeholder="0,00"
          inputMask={masks.currency}
          label="Renda Bruta Atual: "
        />
      </div>
      <ButtonsMudarEtapa
        etapaAnterior={etapaAnterior}
        proximaEtapa={proximaEtapa}
        disabled={validaFaixaRenda(
          tryGetValueOrDefault([Number(rendaBrutaLink.get().value)], 0),
          tryGetValueOrDefault([faixaRenda?.valorMinimo], 0),
          tryGetValueOrDefault([faixaRenda?.valorMaximo], 0),
        )}
        buttonLabel="Avançar"
      />
    </>
  );
};
export default FaixaRenda;
