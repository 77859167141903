import { api, IApiResponse } from 'main/services';
import { obterEndpointAtualizarDataAposentadoria } from 'previdencia/config/endpoints';
import { ResponseAtualizarDataAposentadoria } from 'previdencia/features/AlteracaoPrazoDiferimento/types/ResponseAtualizarDataAposentadoria';

export const atualizarDataAposentadoria = async (
  cpfCnpj: string,
  NumeroCertificado: string,
  DataAposentadoria: string,
): Promise<IApiResponse<ResponseAtualizarDataAposentadoria> | undefined> => {
  const { data } = await api.post<
    IApiResponse<ResponseAtualizarDataAposentadoria>
  >(obterEndpointAtualizarDataAposentadoria(), {
    cpfCnpj,
    NumeroCertificado,
    DataAposentadoria,
  });
  const messageErro: string | undefined = data?.dados?.mensagens?.[0].descricao;
  if (!data.sucesso || !data.dados.sucesso) throw new Error(messageErro);

  return data;
};
