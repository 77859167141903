import { AppContext } from 'main/contexts/AppContext';
import { obterDadosParticipante } from 'main/services/dadosParticipante.api';
import { useContext, useState } from 'react';
import { IEmailSenderHook } from 'main/types/EmailSender/IEmailSenderHook';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { ParametrosEmissaoExtrato } from '../types/IEnviarExtrato';

interface IEnviarExtratoEmail {
  montarObjetoEmail: (
    numCertificado: string,
    initialDate?: Date | null,
    finalDate?: Date | null,
  ) => any;
}

interface IEnviarExtratoEmailHook
  extends IEnviarExtratoEmail,
    IEmailSenderHook {
  atualizarParametrosExtrato: (parametros: ParametrosEmissaoExtrato) => unknown;
  obterEmailDefault: (numeroCertificado: string) => Promise<string>;
}

export const useEnviarExtratoEmail = (): IEnviarExtratoEmailHook => {
  const { cliente } = useContext(AppContext);

  const [parametrosExtrato, atualizarParametrosExtrato] =
    useState<ParametrosEmissaoExtrato>({
      dataInicial: null,
      dataFinal: null,
      numeroCertificado: '',
      cpfCnpj: cliente.cpfCnpj,
    });

  const obterEmailDefault = async (
    numeroCertificado: string,
  ): Promise<string> => {
    const dadosParticipante = await obterDadosParticipante(
      parametrosExtrato.cpfCnpj,
      numeroCertificado,
    );
    return tryGetValueOrDefault([dadosParticipante?.email], '');
  };

  const montarObjetoEmail = (
    numCertificado: string,
    initialDate?: Date | null,
    finalDate?: Date | null,
  ) => {
    return {
      tipoEmail: tipoEmailConstants.EXTATRO_PREVIDENCIA,
      parametrosEnvio: {
        cpfCnpj: cliente.cpfCnpj,
        numeroCertificado: numCertificado,
        dataInicial: initialDate,
        dataFinal: finalDate,
      },
    };
  };

  return {
    atualizarParametrosExtrato,
    obterEmailDefault,
    montarObjetoEmail,
  };
};
