import { useContext } from 'react';
import { obterDadosParticipante } from 'main/services/dadosParticipante.api';
import { AppContext } from 'main/contexts/AppContext';
import {
  DadosParticipante,
  IDadosParticipanteTelefone,
  TIPO_TELEFONE,
} from 'main/types/Participante';
import { useQuery, UseQueryResult } from 'react-query';
import { reactQueryCacheDuration } from 'config/storage_keys';
import { useToast } from './useToast';
import { tryGetValueOrDefault } from 'main/utils/conditional';

export interface IObterContatosDefaultPrevidencia {
  emailDefault: string;
  numerosTelefone: string;
  numeroDDD: string;
  numeroTelefoneSemDDD: string;
  nome: string;
}

export const useObterContatosDefaultPrevidencia = (
  numCertificado?: string,
): UseQueryResult<IObterContatosDefaultPrevidencia> => {
  const { toastError } = useToast();
  const { cliente } = useContext(AppContext);
  let dadosParticipante: DadosParticipante | undefined;

  const obterTelefonePrincipal = (telefones?: IDadosParticipanteTelefone[]) => {
    const telefoneCelular = telefones?.find(
      (telefone: IDadosParticipanteTelefone) =>
        telefone?.tipoTelefone === TIPO_TELEFONE.CELULAR,
    );

    if (telefoneCelular) {
      return telefoneCelular;
    }

    return telefones?.find(
      (telefone: IDadosParticipanteTelefone) =>
        telefone?.tipoTelefone === TIPO_TELEFONE.OUTRO,
    );
  };

  const obterTelefonePrincipalFormatado = (
    telefonePadrao?: IDadosParticipanteTelefone,
  ) => {
    if (!telefonePadrao) return '';
    return `${telefonePadrao?.codigoArea}${telefonePadrao?.numeroTelefone}`;
  };

  return useQuery(
    ['prev-dadosParticipante', cliente?.cpfCnpj],
    async () => {
      if (cliente.numCertificado || numCertificado) {
        dadosParticipante = await obterDadosParticipante(
          cliente.cpfCnpj,
          tryGetValueOrDefault([cliente.numCertificado, numCertificado], ''),
        );
      }

      const telefonePrincipal = obterTelefonePrincipal(
        dadosParticipante?.telefones,
      );
      if (dadosParticipante?.numCpf) {
        return {
          emailDefault: dadosParticipante?.email ?? '',
          numerosTelefone: obterTelefonePrincipalFormatado(telefonePrincipal),
          numeroDDD: telefonePrincipal?.codigoArea,
          numeroTelefoneSemDDD: telefonePrincipal?.numeroTelefone,
          nome: dadosParticipante?.nome,
        };
      }
      return {} as IObterContatosDefaultPrevidencia;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: reactQueryCacheDuration(),
      retry: false,
      onError: () => toastError(),
    },
  );
};
