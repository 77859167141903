import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { useContext } from 'react';
import { PECOS_VIDA } from 'seguros/config/endpoints';
import { IAtualizarDiaVencimentoSegurosPayload } from 'seguros/types/AtualizarDiaVencimentoSegurosRequest';
import { IAtualizarDiaVencimentoSegurosResponse } from 'seguros/types/AtualizarDiaVencimentoSegurosResponse';
import { IConsultaMetodoPagamentoResponse } from 'seguros/types/ConsultarMetodoPagamentoResponse';

type useAtualizarDiaVencimentoSegurosProps = {
  dadosVencimento: IConsultaMetodoPagamentoResponse | undefined;
  diaNovoVencimento: string;
};

export const useAtualizarDiaVencimentoSeguros = ({
  dadosVencimento,
  diaNovoVencimento,
}: useAtualizarDiaVencimentoSegurosProps) => {
  const {
    clienteVida: { numCertificado },
  } = useContext(AppContext);

  const { loading, response, fetchData } = usePeco<
    IAtualizarDiaVencimentoSegurosPayload,
    IAtualizarDiaVencimentoSegurosResponse
  >({
    api: { operationPath: PECOS_VIDA.AtualizarDataVencimento },
    autoFetch: false,
    handleResponse: { throwToastErrorBFF: false },

    payload: {
      numeroCertificado: numCertificado,
      opcaoPagamento: {
        nome: dadosVencimento?.dadosGerais?.opcaoPagamentoNome,
        codigo: dadosVencimento?.dadosGerais?.opcaoPagamentoCodigo,
      },
      dadosDebito: {
        codigoBanco: dadosVencimento?.dadosGerais?.bancoDebitoCodigo,
        codigoAgencia: dadosVencimento?.dadosGerais?.agenciaDebitoCodigo,
        operacaoConta: dadosVencimento?.dadosGerais?.contaDebitoOperacao,
        numeroConta: dadosVencimento?.dadosGerais?.contaDebitoNumero,
        digitoConta: dadosVencimento?.dadosGerais?.contaDebitoDigito,
        numeroDia: diaNovoVencimento,
      },
    },
  });

  return { loading, response, fetchData };
};
