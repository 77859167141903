import styled from 'styled-components/macro';

export const ListItems = styled.ul`
  background: ${props => props.theme.color.neutral['08']};
  list-style: none;
  user-select: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const Item = styled.li`
  box-shadow: inset 0px 1px 0px 0px ${props => props.theme.color.neutral['06']};
  overflow: hidden;
  transition: 0.3s height;

  &.hide {
    height: 0px !important;
  }
  &.show {
    height: 47px !important;
  }
  &:first-child {
    box-shadow: none;
  }

  .item-button {
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
    text-align: left;
    height: 47px;
    color: ${props => props.theme.color.support['01']};
    padding: 0 20px;
    width: 100%;
    line-height: 1.2;
    position: relative;

    &:hover {
      color: ${props => props.theme.color.support['01']};
      text-decoration: none;
    }
    &.open {
      font-weight: 500;
      background-color: ${props => props.theme.color.support['01']};
      color: ${props => props.theme.color.neutral['08']};
    }
    &.with-subitems {
      padding-right: 60px;

      .icon {
        position: absolute;
        right: 10px;
        top: auto;
      }
    }
  }
  .icon {
    margin-right: 10px;
    font-size: 18px;
    line-height: 0;
    position: relative;
    top: 2px;
  }
`;

export const ListSubItems = styled(ListItems)`
  background-color: ${props => props.theme.color.neutral['08']};
  transition: 0.3s height;

  .item-button {
    box-shadow: 0px -17px 0px -16px ${props => props.theme.color.neutral['06']};
  }
  > li:first-child .item-button {
    box-shadow: none;
  }
`;
