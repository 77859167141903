import React from 'react';
import { Text, Button } from '@cvp/design-system/react';
import {
  ContainerLista,
  ContainerCinza,
  ContainerBranco,
} from 'main/styles/GlobalStyle';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import {
  Participante,
  IResponseResgatarBeneficioProtecao,
} from '../../types/BeneficioProtecaoTypes';
import * as S from '../styles';
import TextoAviso from '../TextoAviso';

type DadosBeneficioProps = {
  proximaEtapa: () => void;
  dataParticipante: Participante;
  dataBeneficios: IResponseResgatarBeneficioProtecao | undefined;
};
const DadosBeneficio: React.FC<DadosBeneficioProps> = ({
  proximaEtapa,
  dataParticipante,
  dataBeneficios,
}) => {
  return (
    <>
      <Text variant="body02-sm" color="text">
        Aqui você encontra as informações do certificado do plano de previdência
        do participante
      </Text>
      <ContainerLista>
        <ContainerCinza>
          <Text variant="body02-sm">Produto :</Text>
          <Text variant="body02-sm">{dataParticipante.produto}</Text>
        </ContainerCinza>
        <ContainerBranco>
          <Text variant="body02-sm">Certificado :</Text>
          <Text variant="body02-sm">{dataParticipante.certificado}</Text>
        </ContainerBranco>
        <ContainerCinza>
          <Text variant="body02-sm">Valor total das contribuições :</Text>
          <Text variant="body02-sm">
            {formatarValorPadraoBrasileiro(dataParticipante.valorTotal)}
          </Text>
        </ContainerCinza>
      </ContainerLista>
      <TextoAviso />
      <S.ContainerButton>
        <Button
          variant="primary"
          disabled={dataBeneficios === undefined}
          onClick={() => proximaEtapa()}
        >
          Avançar
        </Button>
      </S.ContainerButton>
    </>
  );
};
export default DadosBeneficio;
