import { Card, Grid, Display, Button, Text } from '@cvp/design-system/react';

import { useToast } from 'main/hooks/useToast';
import ModalTipoDocuSign from 'main/components/AssinaturaDocuSign/ModalTipoDocuSign';
import ModalTokenDocuSign from 'main/components/AssinaturaDocuSign/ModalTokenDocuSign';
import EmailSenderModal from 'main/components/EmailSenderModal';
import RenderConditional from 'main/components/RenderConditional';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { useObterComprovanteOperacao } from 'previdencia/hooks/useObterComprovanteOperacao';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import { StatusResgate } from 'previdencia/types/StatusResgate';
import useResgateSucesso from 'previdencia/features/SolicitacaoResgate/hooks/useResgateSucesso';
import TabelaResumoSimulacao from 'previdencia/features/SolicitacaoResgate/components/TabelaResumoSimulacao';
import ModalPendenciaSolicitacaoResgate from 'previdencia/features/SolicitacaoResgate/components/ModalPendenciaSolicitacaoResgate';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as S from './styles';

const ResgateSucesso = () => {
  const { navigateTo } = usePrevNavigation();

  const { toastError } = useToast();

  const {
    statusResgate,
    numeroResgate,
    objetoEmail,
    openModalTipo,
    loadingDadosSolicitacaoAssinaturaEletronica,
    dadosCliente,
    openModalDocUsign,
    urlAssinatura,
    exibirBotaoFecharModal,
    openModalMensagemPendencia,
    motivoPendenciaResgate,
    textoInformativoFinalizacaoResgate,
    handleAssinar,
    handleCloseModalTipo,
    handleCloseModal,
    handleCloseModalPendencia,
  } = useResgateSucesso();

  const { gerarComprovante, loading: loadingComprovante } =
    useObterComprovanteOperacao();

  return (
    <Display type="display-block">
      <PrevidenciaResumo />

      <Card style={{ minHeight: 'auto' }}>
        <Card.Content>
          <Grid>
            <Grid.Item xs={1}>
              <Text variant="headline-06" color="primary" margin>
                Solicitação de Resgate
              </Text>

              <RenderConditional
                condition={checkIfSomeItemsAreTrue([
                  statusResgate === StatusResgate.SOLICITADO,
                  statusResgate === StatusResgate.VALIDADO,
                ])}
              >
                <Text variant="body02-md" margin>
                  {textoInformativoFinalizacaoResgate.TEMPO_RESGATE}
                </Text>
                <Text variant="body02-md" margin>
                  {textoInformativoFinalizacaoResgate.IMPRESSAO}
                </Text>
              </RenderConditional>

              <RenderConditional
                condition={statusResgate === StatusResgate.PENDENTE}
              >
                <Text variant="body02-md" margin>
                  {textoInformativoFinalizacaoResgate.CONCLUSAO}
                </Text>
                <Text variant="body02-md" margin>
                  {motivoPendenciaResgate}
                </Text>
              </RenderConditional>

              <RenderConditional
                condition={
                  statusResgate === StatusResgate.AGUARDANDO_ASSINATURA
                }
              >
                <Text variant="body02-md" margin>
                  {textoInformativoFinalizacaoResgate.SUCESSO}
                </Text>
                <Text variant="body02-md" margin>
                  {textoInformativoFinalizacaoResgate.EFETIVACAO}
                </Text>
              </RenderConditional>
            </Grid.Item>
          </Grid>

          <RenderConditional
            condition={checkIfSomeItemsAreTrue([
              statusResgate === StatusResgate.SOLICITADO,
              statusResgate === StatusResgate.VALIDADO,
              statusResgate === StatusResgate.AGUARDANDO_ASSINATURA,
            ])}
          >
            <Grid>
              <Grid.Item xs={1}>
                <Card>
                  <Card.Content padding={[4, 4, 4]}>
                    <div>
                      <TabelaResumoSimulacao />
                    </div>
                  </Card.Content>
                </Card>
              </Grid.Item>
            </Grid>

            <S.ContainerTextoSucessoResgate>
              {CONSTS.INFORMATIVO_FINALIZAR.map(item => (
                <Text variant="body02-md" key={item} margin>
                  {item}
                </Text>
              ))}
            </S.ContainerTextoSucessoResgate>

            <Display>
              <Button
                variant="outlined"
                onClick={() => navigateTo('cancelamento-solicitacao-resgate')}
              >
                Voltar
              </Button>

              <Button
                variant="primary"
                onClick={async () =>
                  gerarComprovante(numeroResgate, CONSTS.TIPO_OPERACAO.RESGATE)
                }
                loading={loadingComprovante}
                disabled={loadingComprovante}
              >
                Imprimir
              </Button>

              <RenderConditional
                condition={checkIfSomeItemsAreTrue([
                  statusResgate === StatusResgate.SOLICITADO,
                  statusResgate === StatusResgate.VALIDADO,
                ])}
              >
                <EmailSenderModal
                  objetoEmail={objetoEmail}
                  onError={() =>
                    toastError('Ocorreu um erro ao enviar o E-mail.')
                  }
                />
              </RenderConditional>
            </Display>
          </RenderConditional>
        </Card.Content>
      </Card>

      <RenderConditional condition={openModalTipo}>
        <ModalTipoDocuSign
          open={openModalTipo}
          executarAssinatura={handleAssinar}
          handleClose={handleCloseModalTipo}
          tituloModal="Solicitação de resgate"
          loading={loadingDadosSolicitacaoAssinaturaEletronica}
          dadosCliente={dadosCliente}
        />
      </RenderConditional>

      <RenderConditional
        condition={checkIfAllItemsAreTrue([openModalDocUsign, !!urlAssinatura])}
      >
        <ModalTokenDocuSign
          open={openModalDocUsign}
          urlTokenPage={urlAssinatura}
          handleClose={handleCloseModal}
          exibirBotaoFecharModal={exibirBotaoFecharModal}
        />
      </RenderConditional>

      <ModalPendenciaSolicitacaoResgate
        open={openModalMensagemPendencia}
        handleClose={handleCloseModalPendencia}
        motivoPendenciaResgate={motivoPendenciaResgate}
      />
    </Display>
  );
};

export default ResgateSucesso;
