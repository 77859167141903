import ConsultarCliente from 'atendimento/features/areaLogada/pages/ConsultarCliente';
import ResetCadastro from 'atendimento/features/areaLogada/pages/ResetCadastro';
import Webchat from 'atendimento/features/Webchat/pages/Webchat';
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import PrivateRoute from 'main/components/Route';
import { IPrivateRoute } from 'main/components/Route/PrivateRoute';
import {
  TODOS_USUARIOS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import React from 'react';
import { Switch } from 'react-router-dom';

const routes: IPrivateRoute[] = [
  {
    path: '/atendimento/webchat',
    exact: true,
    component: Webchat,
    key: 'webchat',
    authenticated: true,
    breadcrumb: 'Webchat',
    requiredRoles: [
      ...TODOS_USUARIOS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
  },
  {
    path: '/atendimento/area-logada',
    exact: true,
    component: ConsultarCliente,
    key: 'areaLogada',
    authenticated: true,
    breadcrumb: 'Área Logada',
    requiredRoles: [USER_PROFILES.ANALISTA_TI],
  },
  {
    path: '/atendimento/area-logada/detalhes',
    exact: true,
    component: ResetCadastro,
    key: 'areaLogada-detalhes',
    authenticated: true,
    breadcrumb: 'Área Logada',
    requiredRoles: [USER_PROFILES.ANALISTA_TI],
  },
];
/**
 * Utilizar caso seja necessário personalizar o nome dos breadcrumbs
 */
export const atendimentoBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
  '/atendimento': null,
};
const RotasAtendimento = (): React.ReactElement => (
  <Switch>
    {routes.map(route => (
      <PrivateRoute
        key={route.key}
        path={route.path}
        exact={route.exact}
        component={route.component}
        authenticated={route.authenticated}
        requiredRoles={route.requiredRoles}
      />
    ))}
  </Switch>
);
export default RotasAtendimento;
