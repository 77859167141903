import React from 'react';
import { Text } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import RenderConditional from 'main/components/RenderConditional';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { CoberturaContratada } from '../../types/CoberturasContratadas.types';
import * as S from './styles';
import { EnumTipoRenda } from '../../types/EnumsStatusAtivacaoSuspensao.types';

type InformacoesAdicionaisCuidadoExtraProps = {
  dados: CoberturaContratada | undefined;
};

const InformacoesAdicionaisCuidadoExtra: React.FC<
  InformacoesAdicionaisCuidadoExtraProps
> = ({ dados }) => {
  const temPrazo = !!checkIfAllItemsAreTrue([
    !!dados?.prazoRecebimento,
    dados?.prazoRecebimento !== '',
  ]);
  return (
    <S.ContainerInformacoesAdicionaisCuidadoExtra prazoGrid={temPrazo}>
      <RenderConditional condition={temPrazo}>
        <S.DescricaoContribuicao>
          <S.TextDescricaoItemContribuicao
            variant="caption-01"
            color="text-light"
          >
            Prazo de Recebimento
          </S.TextDescricaoItemContribuicao>
          <Text variant="body01-sm">{dados?.prazoRecebimento}</Text>
        </S.DescricaoContribuicao>
      </RenderConditional>
      <S.DescricaoContribuicao>
        <S.TextDescricaoItemContribuicao
          variant="caption-01"
          color="text-light"
        >
          Valor da contribuição
        </S.TextDescricaoItemContribuicao>
        <Text variant="body01-sm">
          {formatarValorPadraoBrasileiro(dados?.valor)}
        </Text>
      </S.DescricaoContribuicao>
      <S.DescricaoContribuicao>
        <S.TextDescricaoItemContribuicao
          variant="caption-01"
          color="text-light"
        >
          <RenderConditional
            condition={dados?.tipoRenda === EnumTipoRenda.Mensal}
          >
            Valor da Renda Mensal
          </RenderConditional>
          <RenderConditional
            condition={dados?.tipoRenda !== EnumTipoRenda.Mensal}
          >
            Valor da indenização
          </RenderConditional>
          <br />
        </S.TextDescricaoItemContribuicao>
        <Text variant="body01-sm">
          {formatarValorPadraoBrasileiro(dados?.valorBeneficio)}
        </Text>
      </S.DescricaoContribuicao>
    </S.ContainerInformacoesAdicionaisCuidadoExtra>
  );
};

export default InformacoesAdicionaisCuidadoExtra;
