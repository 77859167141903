import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Accordion,
  Card,
  Grid,
  Text,
  Display,
  Button,
} from '@cvp/design-system/react';
import { TextLabel } from 'main/styles/GlobalStyle';
import masks from 'main/utils/masks';
import { formatarCpf } from 'main/utils/cpf_cnpj';
import RenderConditional from 'main/components/RenderConditional';
import Icon from 'main/components/Icon';
import { DetalheTarefaCardSkeleton } from './DetalheTarefaCardSkeleton';
import { IDadosCardProps } from '../../types/IDadosCardProps';
import {
  extractEmailFromResponse,
  extractPhoneNumberFromReponse,
} from '../../utils/dadosClienteUtils';
import { useValidarRegrasPortabilidade } from '../../hooks';
import * as S from './styles';

const DadosCliente: React.FunctionComponent<IDadosCardProps> = ({
  isLoading,
  dadosPortabilidade,
}) => {
  const history = useHistory();

  const { deveExibirAcoesEdicaoCadastro } =
    useValidarRegrasPortabilidade(dadosPortabilidade);

  const idPortabilidade = dadosPortabilidade?.portabilidade.id;

  const [emailInvalido, setEmailInvalido] = useState<boolean>(false);
  const [telefoneInvalido, setTelefoneInvalido] = useState<boolean>(false);
  const [emailTelefoneInvalido, setEmailTelefoneInvalido] =
    useState<boolean>(false);

  useEffect(() => {
    if (dadosPortabilidade) {
      const telefoneClientePortabilidade =
        extractPhoneNumberFromReponse(dadosPortabilidade);
      setTelefoneInvalido(
        telefoneClientePortabilidade === '' ||
          telefoneClientePortabilidade.length < 2,
      );

      const emailClientePortabilidade =
        extractEmailFromResponse(dadosPortabilidade);
      setEmailInvalido(
        emailClientePortabilidade === '' ||
          emailClientePortabilidade.length < 2,
      );

      setEmailTelefoneInvalido(telefoneInvalido || emailInvalido);
    }
  }, [
    dadosPortabilidade,
    emailInvalido,
    telefoneInvalido,
    emailTelefoneInvalido,
  ]);

  return (
    <>
      <RenderConditional
        condition={isLoading}
        component={<DetalheTarefaCardSkeleton />}
      />
      {/* <RenderConditional
        condition={!isLoading && !dadosPortabilidade}
        component={<CardFallback title="Informações do cliente" />}
      /> */}
      <RenderConditional condition={!isLoading && !!dadosPortabilidade}>
        <Display type="display-block">
          <Card>
            <S.CardContent hasError={emailTelefoneInvalido}>
              <Accordion data-testid="accordionDados">
                <Accordion.Item title="Informações do cliente">
                  <Grid justify="space-between">
                    <Grid.Item xs={1} lg={1 / 2}>
                      <div>
                        <TextLabel variant="body02-sm">Nome</TextLabel>
                        <Text variant="body02-sm" data-testid="nomeCliente">
                          {`${dadosPortabilidade?.cliente.primeiroNome} ${dadosPortabilidade?.cliente.sobrenome}`}
                        </Text>
                      </div>
                    </Grid.Item>
                    <Grid.Item xs={1} lg={1 / 2}>
                      <div>
                        <TextLabel variant="body02-sm">CPF</TextLabel>
                        <Text variant="body02-sm" data-testid="cpfCnpj">
                          {formatarCpf(dadosPortabilidade?.cliente.cpfCnpj)}
                        </Text>
                      </div>
                    </Grid.Item>
                    <Grid.Item xs={1} lg={1 / 2}>
                      <div>
                        <TextLabel variant="body02-sm">
                          E-mail principal
                        </TextLabel>
                        <S.Text
                          data-testid="enderecoEmail"
                          hasError={emailInvalido}
                        >
                          {extractEmailFromResponse(dadosPortabilidade)}
                        </S.Text>
                        {emailInvalido && (
                          <S.TextWarning>
                            <Icon name="warning" /> Informe um e-mail de contato
                            válido.
                          </S.TextWarning>
                        )}
                      </div>
                    </Grid.Item>
                    <Grid.Item xs={1} lg={1 / 2}>
                      <div>
                        <TextLabel variant="body02-sm">
                          Telefone principal
                        </TextLabel>
                        <S.Text
                          data-testid="numeroTelefone"
                          hasError={telefoneInvalido}
                        >
                          {masks.phone.mask(
                            extractPhoneNumberFromReponse(dadosPortabilidade),
                          )}
                        </S.Text>
                        {telefoneInvalido && (
                          <S.TextWarning>
                            <Icon name="warning" /> Informe um número de
                            telefone celular de contato válido.
                          </S.TextWarning>
                        )}
                      </div>
                    </Grid.Item>
                    <RenderConditional
                      condition={deveExibirAcoesEdicaoCadastro()}
                    >
                      <Grid.Item xs={1} lg={1 / 2}>
                        <Button
                          variant="secondary"
                          onClick={() =>
                            history.push({
                              pathname: `/portabilidades/${idPortabilidade}/contatos`,
                              state: { dadosPortabilidade },
                            })
                          }
                        >
                          Editar dados de contato
                        </Button>
                      </Grid.Item>
                    </RenderConditional>
                  </Grid>
                </Accordion.Item>
              </Accordion>
            </S.CardContent>
          </Card>
        </Display>
      </RenderConditional>
    </>
  );
};

export { DadosCliente };
