import { obterEndpointValoresContribuicao } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { ResponseMultiFundo } from 'previdencia/features/AlterarValorContribuicao/types/ResponsesAlterarValorContribuicao';

export const valoresContribuicao = async (
  cpf: string,
  codConta: string,
  valor: string,
): Promise<ResponseMultiFundo | undefined> => {
  const { data } = await api.post<IApiResponse<ResponseMultiFundo>>(
    obterEndpointValoresContribuicao(),
    {
      cpf,
      codConta,
      valor,
    },
  );

  if (data?.dados?.sucesso === false) throw new Error();

  return data?.dados?.entidade;
};
