import React from 'react';
import withValidation from 'main/features/Validation/hocs/withValidation';
import ValidableProps from 'main/features/Validation/types/ValidableProps';
import * as S from './styles';

interface InputSelectProps extends ValidableProps<string> {
  label?: string;
  placeholder?: string;
  error?: boolean;
  dettach?: boolean;
  children?: React.ReactNode;
}

const InputSelect: React.FC<InputSelectProps> = ({
  link,
  label,
  dettach,
  placeholder,
  children,
  ...props
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    link.set({
      value: inputValue,
      isValid: true,
      errorMsg: '',
    });
  };

  return (
    <S.Select
      {...props}
      value={link.get().value}
      label={label}
      placeholder={placeholder}
      onChange={handleChange}
      error={!link.get().isValid}
      errorMessage={link.get().errorMsg}
      dettach={dettach}
    >
      {children}
    </S.Select>
  );
};

InputSelect.defaultProps = {
  error: false,
  label: '',
  placeholder: '',
  dettach: false,
};

export default withValidation<InputSelectProps, string>(InputSelect);
