import React from 'react';
import { Display, Text } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import RenderConditional from 'main/components/RenderConditional';
import { CardDadosBasicoProps } from 'consultaCliente/features/listaCardProduto/types/typesPrestamista';
import * as S from 'consultaCliente/features/listaCardProduto/components/CardsProduto/styles';
import { tryGetValueOrDefault } from 'main/utils/conditional';

const NomeCertificadoCardItem: React.FunctionComponent<
  CardDadosBasicoProps
> = ({ nomeCliente, numCertificado, tipoProduto, classificacaoDoPlano }) => (
  <>
    <RenderConditional condition={numCertificado !== '0'}>
      <Display alignItems="center">
        <S.IconContainer>
          <Icon name="documentPaper" />
        </S.IconContainer>
        <span>
          <Text variant="caption-02">
            <RenderConditional condition={tipoProduto === 'Prestamista'}>
              Contrato
            </RenderConditional>
            <RenderConditional condition={tipoProduto !== 'Prestamista'}>
              Certificado {tryGetValueOrDefault([classificacaoDoPlano], '')}
            </RenderConditional>
          </Text>
          <Text variant="body03-md">{numCertificado}</Text>
        </span>
      </Display>
    </RenderConditional>
    <Display alignItems="center" style={{ flexWrap: 'nowrap' }}>
      <S.IconContainer>
        <Icon name="user" />
      </S.IconContainer>
      <S.TextWrapper>
        <Text variant="caption-02">Nome</Text>
        <S.TextClientName
          statusLength={nomeCliente?.length === 26 ? 8 : 12}
          title={nomeCliente}
        >
          {nomeCliente}
        </S.TextClientName>
      </S.TextWrapper>
    </Display>
  </>
);

export default NomeCertificadoCardItem;
