import { IUser } from 'main/features/Auth/interfaces';
import { Action, Opcoes } from 'main/features/prospeccao/types/IFormProspeccao';
import { unmaskCurrency } from './helpers';
import { MAPPED_FIELDS } from '../../../constants/formProspeccao';
import { ModalProps } from '../types';
import { validators } from './validators';

export const findAction = (
  actioName: string,
  fields: Opcoes[],
): Opcoes | null => {
  let fieldAction: Opcoes = {} as Opcoes;
  fields
    .filter(x => x.atributosView?.actions)
    .forEach(field => {
      const action = field.atributosView?.actions?.find(
        x => x.name === actioName,
      );
      if (action) {
        fieldAction = {
          ...field,
          atributosView: {
            actions: [action],
          },
        };
      }
    });

  return Object.keys(fieldAction).length > 0 ? fieldAction : null;
};

export const setAgenciaCurrentUser = (
  values: Opcoes[],
  user: IUser,
  setFieldValue: (key: string, value: string) => void,
): void => {
  const field = findAction('LOAD_COD_UNIDADE_CURRENT_USER', values);
  if (field) {
    const indexResultField = values.findIndex(
      fieldToUpdate => fieldToUpdate.id === field.id,
    );
    setFieldValue(
      `campos[${indexResultField}].resposta`,
      user?.agenciaVinculada?.toString(),
    );
  }
};

export const setTotalValueIs = (
  action: Action,
  values: Opcoes[],
  setFieldValue: (key: string, value: string) => void,
): void => {
  const firstFieldToSumId = action.event.payload.formFields[0];
  const secondFieldToSumId = action.event.payload.formFields[1];
  const fieldResultId = action.event.payload.formFields[2];

  const firstFieldValue = unmaskCurrency(
    values.find(field => field.id === firstFieldToSumId)?.resposta,
  );
  const secondFieldValue = unmaskCurrency(
    values.find(field => field.id === secondFieldToSumId)?.resposta,
  );

  const sum = firstFieldValue + secondFieldValue;
  const indexResultField = values.findIndex(
    fieldToUpdate => fieldToUpdate.id === fieldResultId,
  );
  setFieldValue(
    `campos[${indexResultField}].resposta`,
    sum.toFixed(2).toString(),
  );
};

export const setImportanciaSegurada = (
  values: Opcoes[],
  valorTotalContratos: number,
  setFieldValue: (key: string, value: string) => void,
): void => {
  const field = findAction('LOAD_IMPORTANCIA_SEGURADA', values);
  if (field) {
    const indexResultField = values.findIndex(
      fieldToUpdate => fieldToUpdate.id === field.id,
    );
    setFieldValue(
      `campos[${indexResultField}].resposta`,
      valorTotalContratos.toString(),
    );
  }
};

type contextType = {
  [index: string]: string | number | boolean;
  isLastStep: boolean;
};

type handleBlurParams = {
  item: Opcoes;
  values: Opcoes[];
  context: contextType;
  setFieldValue: (key: string, value: string) => void;
  setModal: (params: ModalProps) => void;
  setBloqueioEnvio: (bloqueioEnvio: boolean) => void;
};

export const handleOnBlurActions = ({
  item,
  values,
  setFieldValue,
  context,
  setModal,
  setBloqueioEnvio,
}: handleBlurParams): void => {
  const blurAction = item.atributosView?.actions?.filter(
    ac => ac.event.trigger === 'onblur',
  );
  blurAction?.forEach(action => {
    if (action.name === 'VALIDATE_PRAZO_DATA_NASCIMENTO' && item.resposta) {
      validators.validaPrazoIdade({
        dataNascimento: context[MAPPED_FIELDS.dataNascimento],
        prazo: item.resposta,
        setModal,
        setBloqueioEnvio,
      });
    }
    if (action.name === 'SUM_IS') {
      setTotalValueIs(action, values, setFieldValue);
    }
  });
};

export const getButtonSumitLabel = (isLastStep: boolean): string =>
  isLastStep ? 'Enviar' : 'Continuar';
