import React from 'react';
import { Grid, Text, Display, Button } from '@cvp/design-system/react';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import Table from 'main/components/Table';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { TextArea } from 'main/components/form';
import InputFile from 'main/components/form/InputFile';
import {
  filesFormatsAllowed,
  maxFileSizeAllowed,
} from 'main/features/Validation/validations';
import { useRegistrarOcorrenciaContext } from 'registroOcorrenciaASC/features/registrarOcorrencia/contexts/RegistrarOcorrenciaContext';
import CamposDinamicos from 'registroOcorrenciaASC/features/registrarOcorrencia/components/CamposDinamicos';
import BotaoEnviarOcorrencia from 'registroOcorrenciaASC/features/registrarOcorrencia/components/BotaoEnviarOcorrencia';
import ModalDuplicidadeSolicitacao from 'registroOcorrenciaASC/features/registrarOcorrencia/components/ModalDuplicidadeSolicitacao/ModalDuplicidadeSolicitacao';
import ModalComunicadoSinistroPrestamista from 'registroOcorrenciaASC/features/registrarOcorrencia/components/ModalComunicadoSinistroPrestamista';
import { useRenderizarFormOcorrencia } from 'registroOcorrenciaASC/features/registrarOcorrencia/hooks';
import * as CONSTS from 'registroOcorrenciaASC/features/registrarOcorrencia/constants/constants';
import * as ENUM_TYPES from 'registroOcorrenciaASC/types/enum';
import * as OCORRENCIA_UTILS from 'registroOcorrenciaASC/utils/RegistroOcorrenciaASCUtils';
import * as CONSTS_ASC from 'registroOcorrenciaASC/constants/constants';
import * as S from 'registroOcorrenciaASC/features/registrarOcorrencia/components/FormDadosOcorrencia/styles';

const FormDadosOcorrencia = (): React.ReactElement => {
  const { formDadosOcorrencia, formFop } = useRegistrarOcorrenciaContext();

  const {
    formik,
    toggleModalComunicadoSinistroPrestamista,
    toggleModalDuplicidadeSolicitacao,
    limparFormComunicadoSinistroPrestamista,
    desabilitarBotaoFormComunicadoSinistroPrestamista,
    loadingDadosContratos,
    loadingDadosSolicitacoesDuplicadas,
    loadingDadosConsultaCamposDinamicos,
    colunasTabelaContratos,
    dadosConsultaCamposDinamicos,
    salvarMensagemOcorrenciaContexto,
    arquivoOcorrencia,
    validateArquivoOcorrencia,
    salvarCamposComunicadoSinistoPrestamistaContexto,
  } = useRenderizarFormOcorrencia();

  return (
    <Grid>
      <RenderConditional condition={formFop.comunicadoSinistroPrestamista}>
        <Grid.Item xs={1}>
          <S.WrapperWarning>
            <Text variant="body02-sm" margin>
              {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.ALERTA_FORM_TITULO}
            </Text>
            {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.ALERTA_FORM}
          </S.WrapperWarning>

          <Display>
            <Button
              variant="primary"
              onClick={toggleModalComunicadoSinistroPrestamista}
            >
              {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.LINK_FORM_EXTERNO}
            </Button>
          </Display>
        </Grid.Item>
      </RenderConditional>

      <Grid.Item xs={1}>
        <Text variant="body02-sm">
          {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.INDICAR_CONTRATO_USUARIO}
        </Text>
        <Text variant="body02-sm">
          {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.INDICAR_CONTRATO_FRASE}
        </Text>
      </Grid.Item>

      <RenderConditional condition={loadingDadosContratos}>
        <Grid.Item xs={1}>
          <SkeletonLoading blocks={1} />
        </Grid.Item>
      </RenderConditional>

      <RenderConditional condition={!loadingDadosContratos}>
        <Grid.Item xs={1}>
          <Table
            noHeader
            responsive
            striped
            highlightOnHover
            data={tryGetValueOrDefault(
              [formDadosOcorrencia.listaContratos],
              [],
            )}
            columns={colunasTabelaContratos}
            noDataComponent={CONSTS.NAO_HA_DADOS_TABELA}
            progressPending={loadingDadosSolicitacoesDuplicadas}
            progressComponent={
              <Grid.Item xs={1}>
                <SkeletonLoading blocks={1} />
              </Grid.Item>
            }
            pagination
            paginationPerPage={10}
            paginationComponentOptions={{
              rowsPerPageText: 'Items por página',
              rangeSeparatorText: 'de',
            }}
          />
        </Grid.Item>
      </RenderConditional>

      <RenderConditional condition={loadingDadosConsultaCamposDinamicos}>
        <Grid.Item xs={1}>
          <SkeletonLoading blocks={1} />
        </Grid.Item>
      </RenderConditional>

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          !!dadosConsultaCamposDinamicos?.entidade,
          !loadingDadosConsultaCamposDinamicos,
        ])}
      >
        <CamposDinamicos />
      </RenderConditional>

      <Grid.Item xs={1}>
        <Text variant="body02-sm">
          {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.LABEL_MENSAGEM}
        </Text>
        <TextArea
          {...formik.getFieldProps(formik.values.conteudoTextarea)}
          rows={5}
          spellCheck
          maxLength={
            ENUM_TYPES.DefaultValue
              .QTD_MAX_CARACTERES_TEXTAREA_REGISTRAR_OCORRENCIA
          }
          value={formik.values.conteudoTextarea}
          onChange={salvarMensagemOcorrenciaContexto}
          {...OCORRENCIA_UTILS.showErrorInput(
            formik,
            formik.values.conteudoTextarea,
          )}
        />
        <Text variant="body02-sm">
          {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.MSG_CARACTER_RESTANTE}{' '}
          <strong>{formik.values.quantidadeCaracteresTextarea}</strong>
        </Text>
      </Grid.Item>

      <Grid.Item xs={1} md={1 / 3}>
        <Text variant="caption-02">
          {CONSTS_ASC.TEXTOS_UPLOAD.EXTENSOES_PERMITIDAS}
        </Text>
        <Text variant="caption-02" margin>
          Tamanho máximo do arquivo:{' '}
          {ENUM_TYPES.DefaultValue.TAMANHO_ARQUIVO_UPLOAD.toString()}MB
        </Text>
        <InputFile
          maxSizeMb={ENUM_TYPES.DefaultValue.TAMANHO_ARQUIVO_UPLOAD}
          formatsAllowed={CONSTS_ASC.TEXTOS_UPLOAD.FORMATOS_PERMITIDOS_UPLOAD}
          link={arquivoOcorrencia}
          multiple
          validationRules={[
            filesFormatsAllowed(
              () => CONSTS_ASC.TEXTOS_UPLOAD.MSG_FORMATO_NAO_SUPORTADO,
              CONSTS_ASC.TEXTOS_UPLOAD.FORMATOS_PERMITIDOS_UPLOAD,
            ),
            maxFileSizeAllowed(
              () => CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.MSG_TAMANHO_UPLOAD,
              ENUM_TYPES.DefaultValue.TAMANHO_ARQUIVO_UPLOAD,
            ),
          ]}
        />
      </Grid.Item>

      <Grid.Item xs={1}>
        <Text variant="body02-sm" margin>
          <strong>
            {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.CAMPOS_OBRIGATORIOS}
          </strong>
        </Text>
      </Grid.Item>

      <Grid.Item xs={1}>
        <Display justify="center">
          <BotaoEnviarOcorrencia
            validateArquivoOcorrencia={validateArquivoOcorrencia}
            habilitarBotaoFormFopPreenchido={!formik.isValid}
          />
        </Display>
      </Grid.Item>

      <ModalDuplicidadeSolicitacao
        formik={formik}
        toggleModal={toggleModalDuplicidadeSolicitacao}
      />

      <ModalComunicadoSinistroPrestamista
        formik={formik}
        toggleModal={toggleModalComunicadoSinistroPrestamista}
        cleanModal={limparFormComunicadoSinistroPrestamista}
        disableSaveBtnForm={desabilitarBotaoFormComunicadoSinistroPrestamista}
        salvarFormContexto={salvarCamposComunicadoSinistoPrestamistaContexto}
      />
    </Grid>
  );
};

export default FormDadosOcorrencia;
