import React from 'react';
import { Text } from '@cvp/design-system/react';
import { formatarData } from 'main/utils';
import * as CONSTS from 'previdencia/features/Aporte/constants';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';
import * as S from 'previdencia/features/Aporte/styles';

const ComprovanteAporteViaDebito: React.FC<
  APORTE_TYPES.IComprovanteAporteViaDebitoProps
> = ({ agenciaBancaria, contaBancaria, dataSelecionada, operacaoBancaria }) => {
  return (
    <>
      <S.ContainerBrancoAporte>
        <Text variant="body02-sm">Banco:</Text>
        <Text variant="body02-sm">104 - Caixa Econômica Federal</Text>
      </S.ContainerBrancoAporte>
      <S.ContainerCinzaAporte>
        <Text variant="body02-sm">Operação:</Text>
        <Text variant="body02-sm">{operacaoBancaria}</Text>
      </S.ContainerCinzaAporte>
      <S.ContainerBrancoAporte>
        <Text variant="body02-sm">Agência:</Text>
        <Text variant="body02-sm">{agenciaBancaria}</Text>
      </S.ContainerBrancoAporte>
      <S.ContainerCinzaAporte>
        <Text variant="body02-sm">Conta Bancária:</Text>
        <Text variant="body02-sm">{contaBancaria}</Text>
      </S.ContainerCinzaAporte>
      <S.ContainerBrancoAporte>
        <Text variant="body02-sm">Data do Débito:</Text>
        <Text variant="body02-sm">{formatarData(dataSelecionada)}</Text>
      </S.ContainerBrancoAporte>
      <S.WrapperAporteWarning>
        <Text variant="body02-sm" color="warning">
          {CONSTS.COMPROVANTE_APORTE_LABELS.DESCRICAO_CONTA_DEBITO_INDICADA}
        </Text>
      </S.WrapperAporteWarning>
    </>
  );
};
export default ComprovanteAporteViaDebito;
