import { useContext } from 'react';
import { useToast } from 'main/hooks/useToast';
import { useQuery, UseQueryResult } from 'react-query';
import { AppContext } from 'main/contexts/AppContext';
import { ResponseMultiFundo } from 'previdencia/features/AlterarValorContribuicao/types/ResponsesAlterarValorContribuicao';
import * as AlterarValorContribuicaoApi from 'previdencia/features/AlterarValorContribuicao/services/alterarValorContribuicao.api';

const useAlterarValorContribuicao = (
  valor: string,
): UseQueryResult<ResponseMultiFundo | undefined> => {
  const { toastError } = useToast();
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  return useQuery(
    ['prev-alterar-valor-contribuicao', numCertificado],
    () =>
      AlterarValorContribuicaoApi.alterarValorContribuicao(
        cpfCnpj,
        numCertificado,
        valor,
      ),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: () => toastError(),
    },
  );
};

export default useAlterarValorContribuicao;
