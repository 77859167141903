import { Button, Display, Grid, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { obterLinkAtendimentoOnline } from 'previdencia/config/endpoints';
import React, { useEffect, useState } from 'react';
import {
  MENSAGEM_ALTERACAO_COM_SUCESSO,
  MENSAGEM_ALTERACAO_NAO_SE_APLICA,
} from '../../constants/constants';
import useAtualizarDataVencimento from '../../hooks/useAtualizarDataVencimento';

type ConfirmarAlteracaoVencimentoProps = {
  diaAntigo: string | undefined;
  diaNovo: string;
  voltar: () => void;
  refetchDadosCertificado: () => void;
};

const ConfirmarAlteracaoVencimento: React.FC<
  ConfirmarAlteracaoVencimentoProps
> = ({ diaNovo, diaAntigo, voltar, refetchDadosCertificado }) => {
  const [falhaAlteracao, setFalhaAlteracao] = useState(false);
  const [registroAlterado, setRegistroAlterado] = useState(false);
  const [alterado, setAlterado] = useState(false);

  const { response, loading, fetchData } = useAtualizarDataVencimento(diaNovo);

  const handleUltimoValidarParaSalvar = () => {
    if (
      checkIfAllItemsAreTrue([
        !!response?.sucessoBFF,
        !!response?.entidade,
        falhaAlteracao,
      ])
    ) {
      setRegistroAlterado(true);
    }
  };

  useEffect(() => {
    if (response?.sucessoBFF) {
      handleUltimoValidarParaSalvar();
    }
  }, [response]);

  const handleConfirmar = async () => {
    setFalhaAlteracao(true);
    await fetchData();
    setAlterado(true);
  };

  if (checkIfAllItemsAreTrue([falhaAlteracao, alterado])) {
    return (
      <Grid>
        <Grid.Item xs={1}>
          <Display>
            <RenderConditional condition={!registroAlterado}>
              <Text variant="body02-md" color="text">
                {MENSAGEM_ALTERACAO_NAO_SE_APLICA}
                <a
                  href={obterLinkAtendimentoOnline('573Do0000000Aj0')}
                  target="blank"
                >
                  link
                </a>
                .
              </Text>
            </RenderConditional>
            <RenderConditional condition={registroAlterado}>
              <Text variant="body02-md" color="text">
                {MENSAGEM_ALTERACAO_COM_SUCESSO}
              </Text>
            </RenderConditional>
          </Display>
          <Display>
            <Button
              variant="outlined"
              onClick={() => {
                setFalhaAlteracao(false);
                setAlterado(false);
                refetchDadosCertificado();
                voltar();
              }}
            >
              Voltar
            </Button>
          </Display>
        </Grid.Item>
      </Grid>
    );
  }

  return (
    <Grid>
      <Grid.Item xs={1}>
        <Display>
          <Text variant="body01-md" color="text">
            Dia de vencimento antigo:
          </Text>
          <Text
            variant="body01-md"
            color="text-light"
            data-testid="diaVencimentoAtual"
          >
            {diaAntigo}
          </Text>
        </Display>
        <Display>
          <Text variant="body01-md" color="text">
            Novo dia de vencimento:
          </Text>
          <Text
            variant="body01-md"
            color="text-light"
            margin
            data-testid="novoDiaVencimento"
          >
            {diaNovo}
          </Text>
        </Display>
        <br />
        <>
          <Display>
            <Button variant="outlined" onClick={() => voltar()}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={handleConfirmar}
              loading={loading}
              data-testid="confirmarAlteracao"
            >
              Confirmar
            </Button>
          </Display>
        </>
      </Grid.Item>
    </Grid>
  );
};

export default ConfirmarAlteracaoVencimento;
