import React from 'react';
import {
  Accordion,
  Card,
  Grid,
  Text,
  Display,
  Tag,
} from '@cvp/design-system/react';
import { formatarDataHoraAmigavel } from 'main/utils';
import { TextLabel } from 'main/styles/GlobalStyle';
import { DetalheTarefaCardSkeleton } from './DetalheTarefaCardSkeleton';
import CardFallback from './CardFallback';
import { IDadosCardProps } from '../../types/IDadosCardProps';
import { useValidarRegrasPortabilidade } from '../../hooks';

const DadosDaNegociacao: React.FunctionComponent<IDadosCardProps> = ({
  isLoading,
  dadosPortabilidade,
}) => {
  const { obterUltimoStatusPortabilidade } =
    useValidarRegrasPortabilidade(dadosPortabilidade);

  const obterUltimoStatus = () => {
    return obterUltimoStatusPortabilidade()?.situacao || '-';
  };

  if (isLoading) {
    return <DetalheTarefaCardSkeleton />;
  }

  if (!isLoading && dadosPortabilidade) {
    return (
      <Display type="display-block">
        <Card>
          <Card.Content>
            <Accordion data-testid="accordionNegociacao">
              <Accordion.Item title="Dados da negociação">
                <Grid>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <div>
                      <TextLabel variant="body02-sm">Status</TextLabel>
                      <Text variant="body02-sm" data-testid="status">
                        <Display>
                          <Tag variant="error" value={obterUltimoStatus()} />
                        </Display>
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Data de vencimento
                      </TextLabel>
                      <Text variant="body02-sm" data-testid="dataVencimento">
                        <Display>
                          <Tag
                            variant="error"
                            value={formatarDataHoraAmigavel(
                              dadosPortabilidade?.portabilidade.dataVencimento,
                            )}
                          />
                        </Display>
                      </Text>
                    </div>
                  </Grid.Item>

                  <Grid.Item xs={1} lg={1 / 2}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Família de fundos enquadrados
                      </TextLabel>
                      <Display data-testid="fundosEnquadrados">
                        {dadosPortabilidade?.contrato.familiaFundosEnquadrado
                          .split(';')
                          .map(item => (
                            <Tag key={item} variant="primary" value={item} />
                          ))}
                      </Display>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Tipo Enquadramento Cliente
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="tipoEnquadramentoCliente"
                      >
                        {dadosPortabilidade?.contrato.tipoEnquadramento}
                      </Text>
                    </div>
                  </Grid.Item>
                </Grid>
              </Accordion.Item>
            </Accordion>
          </Card.Content>
        </Card>
      </Display>
    );
  }

  return <CardFallback title="Dados da negociação" />;
};

export { DadosDaNegociacao };
