export const regimeTributarioMapper = (
  taxOptionType: string,
): string | undefined => {
  return taxOptionType === 'TP' ? 'Progressivo' : 'Regressivo';
};

export const statusCertificadoMapper = (status?: string): string => {
  switch (status) {
    case 'B':
      return 'Em Beneficio';
    case 'C':
      return 'Cancelado';
    case 'D':
      return 'Desligado';
    case 'E':
      return 'Encerrado';
    case 'P':
      return 'Pendente';
    case 'U':
      return 'Ativo com cobrança suspensa';
    case 'S':
      return 'Sinistro';
    case 'T':
      return 'Em transição';
    default:
      return 'Ativo';
  }
};

export const tipoEnderecoMapper = (tipoEndereco: string): string | undefined =>
  ({
    TR: 'Comercial',
    RE: 'Residencial',
  }[tipoEndereco] ?? 'Outro');

export const tipoPagamentoRendaSimuladaMapper = (
  tipoPagamento: string,
): string | undefined =>
  ({
    '1': 'Vitalícia',
    '2': 'Temporária',
    '3': 'Vitalícia com Prazo Mínimo Garantido',
    '4': 'Vitalícia Reversível ao Beneficiário',
    '12': 'Prazo Certo',
  }[tipoPagamento] ?? 'Não Definido');

export const tipoRendaSimuladaMapper = (
  tipoRenda: string,
): string | undefined =>
  ({
    '1': 'Contratada',
    '2': 'Não Contratada',
  }[tipoRenda] ?? '');
