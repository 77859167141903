import { AppContext } from 'main/contexts/AppContext';
import { useToast } from 'main/hooks/useToast';
import { useContext, useState } from 'react';
import { obterArquivoBoleto } from 'seguros/services/obterArquivoBoleto.api';
import {
  IResponseHistoricoPagamentosTableProps,
  UseImprimirBoletoHistoricoPagamentos,
} from 'vida/features/Pagamentos/types/HistoricoPagamentosTypes';

export const useImprimirBoletoHistoricoPagamentos: UseImprimirBoletoHistoricoPagamentos =
  () => {
    const [loading, setLoading] = useState(false);

    const { clienteVida } = useContext(AppContext);
    const { toastError } = useToast();

    const imprimirBoleto = async (
      pagamento: IResponseHistoricoPagamentosTableProps,
    ) => {
      try {
        setLoading(true);

        const result = await obterArquivoBoleto({
          numeroCertificado: clienteVida?.numCertificado.toString(),
          parcela: pagamento.numeroParcela.toString(),
        });

        const blob = new Blob([result], { type: 'application/pdf' });
        const downloadUrl = window.URL.createObjectURL(blob);
        window.open(downloadUrl, '_blank');
      } catch (requestError) {
        const erro = requestError as Error;
        toastError(erro?.message);
      } finally {
        setLoading(false);
      }
    };

    return { imprimirBoleto, loading };
  };
