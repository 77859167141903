import { ReactNode } from 'react';
import { Display } from '@cvp/design-system/react';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';

type BeneficiarioRootProps = {
  children: ReactNode;
};

export const BeneficiarioRoot = ({ children }: BeneficiarioRootProps) => {
  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredRoles={[...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO]}
        requiredPermissions={[
          PREV_PERMISSIONS.INFORMACOES_DOS_BENEFICIARIOS_CONSULTA,
          PREV_PERMISSIONS.BENEFICIARIOS_CADASTRADOS_EDITAR,
        ]}
      >
        {children}
      </FeatureAuthorizer>
    </Display>
  );
};
