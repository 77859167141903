import { Card, Text, Display } from '@cvp/design-system/react';

const StatusDPS = () => {
  return (
    <>
      <Display type="display-block">
        <Card>
          <Card.Content>
            <Display type="flex">
              <Text variant="headline-04" color="primary">
                Relatório DPS
              </Text>
            </Display>
          </Card.Content>
        </Card>
      </Display>
    </>
  );
};

export default StatusDPS;
