import { ChangeEvent } from 'react';
import * as Yup from 'yup';
import { Radio, Text } from '@cvp/design-system/react';
import * as REGISTRO_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrencias';
import * as ENUM_TYPES from 'registroOcorrenciaASC/types/enum';

export const COLUNAS_TABELA_DADOS_OCORRENCIA = ({
  salvarContratoSelecionadoContexto,
  formDadosOcorrencia,
}: REGISTRO_OCORRENCIA_TYPES.IColunasTabelaDadosOcorrencia) => [
  {
    name: '',
    selector: 'selecao',
    center: true,
    width: '100px',
    cell: (row: REGISTRO_OCORRENCIA_TYPES.IFormDadosOcorrenciaContrato) => (
      <Radio
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          salvarContratoSelecionadoContexto(e)
        }
        checked={row.id === formDadosOcorrencia.contratoSelecionado.id}
        value={row.id}
        name="contratosAberturaOcorrencia"
      />
    ),
  },
  {
    name: 'Produto',
    selector: 'produto',
    wrap: true,
    center: true,
    minWidth: '200px',
  },
  {
    name: 'Contrato',
    selector: 'contrato',
    center: true,
    minWidth: '150px',
  },
  {
    name: 'Vigência',
    selector: 'vigencia',
    wrap: true,
    center: true,
    minWidth: '200px',
  },
];

export const NAO_HA_DADOS_TABELA = 'Não há dados para serem exibidos.';

export const LISTA_PRODUTO = [
  { id: 'PREV', produto: 'Previdência' },
  { id: 'PREST', produto: 'Prestamista' },
  { id: 'VIDA', produto: 'Vida' },
];

export const LISTA_GRAU_PARENTESCO = [
  { id: 'parentesco1', grauParentesco: 'Cônjuge/Companheiro(a)' },
  { id: 'parentesco2', grauParentesco: 'Irmão(a)' },
  { id: 'parentesco3', grauParentesco: 'Filho(a)' },
  { id: 'parentesco4', grauParentesco: 'Pai' },
  { id: 'parentesco5', grauParentesco: 'Mãe' },
  { id: 'parentesco6', grauParentesco: 'Titular' },
  { id: 'parentesco7', grauParentesco: 'Procurador' },
  { id: 'parentesco8', grauParentesco: 'Outros' },
];

export const LISTA_COBERTURA_PLEITEADA = [
  { id: 'cobertura1', coberturaPleiteada: 'Morte Natural' },
  { id: 'cobertura2', coberturaPleiteada: 'Morte Acidental' },
  { id: 'cobertura3', coberturaPleiteada: 'Invalidez por Acidente' },
  { id: 'cobertura4', coberturaPleiteada: 'Invalidez por Doença' },
];

export const OPCAO_PADRAO_TABELA_DADOS_OCORRENCIA: REGISTRO_OCORRENCIA_TYPES.IFormDadosOcorrenciaContrato =
  {
    id: '',
    produto: 'Abrir solicitação sem contrato',
    contrato: '',
    vigencia: '',
  };

export const INITIAL_FORMIK_STATE_CONSULTA_CPFCNPJ: REGISTRO_OCORRENCIA_TYPES.IFormikValuesConsultaCpfCnpj =
  {
    inputconsultaCpfCnpj: '',
  };

export const INITIAL_FORMIK_STATE_FORM_COMUNICADO_CAMPOS_OBRIGATORIOS = {
  nomeDadosSegurado: '',
  cpfDadosSegurado: '',
  dataNascDadosSegurado: '',
  nomeDadosReclamante: '',
  cpfDadosReclamante: '',
  grauParentescoDadosReclamante: '',
  enderecoDadosReclamante: '',
  cidadeDadosReclamante: '',
  bairroDadosReclamante: '',
  coberturaPleiteadaDadosSinistro: '',
  dataOcorrenciaDadosSinistro: '',
  historicoDadosSinistro: '',
};

export const INITIAL_FORMIK_STATE_FORM_COMUNICADO: REGISTRO_OCORRENCIA_TYPES.IFormikValuesDadosOcorrenciaFormComunicadoSinistro =
  {
    sexoDadosSegurado: '',
    email1DadosReclamante: '',
    email2DadosReclamante: '',
    uFDadosReclamante: '',
    dDDTelResidencialDadosReclamante: '',
    telResidencialDadosReclamante: '',
    dDDTelComercialDadosReclamante: '',
    telComercialDadosReclamante: '',
    dDDTelCelularDadosReclamante: '',
    telCelularDadosReclamante: '',
    ...INITIAL_FORMIK_STATE_FORM_COMUNICADO_CAMPOS_OBRIGATORIOS,
  };

export const INITIAL_FORMIK_STATE_DADOS_OCORRENCIA: REGISTRO_OCORRENCIA_TYPES.IFormikValuesDadosOcorrencia =
  {
    conteudoTextarea: '',
    quantidadeCaracteresTextarea:
      ENUM_TYPES.DefaultValue.QTD_MAX_CARACTERES_TEXTAREA_REGISTRAR_OCORRENCIA,
    toggleModalMsgDuplicidadeSolicitacao: false,
    toggleModalComunicadoSinistroPrestamista: false,
    ...INITIAL_FORMIK_STATE_FORM_COMUNICADO,
  };

export const VALIDATION_DEFAULT_MESSAGE_SCHEMA = {
  CAMPO_OBRIGATORIO: 'Campo obrigatório.',
  EMAIL_INVALIDO: 'E-mail inválido',
  TELEFONE_INVALIDO: 'Telefone inválido',
};

export const TEXTOS_FORM_DADOS_CLIENTE = {
  VERIFICAR_DADOS: 'Verifique os dados abaixo e atualize, se necessário:',
  INFO_DADOS_REPRESENTANTE: `Ao registrar uma ocorrência para Pessoa Jurídica é necessário
  informar os dados do Representante Legal da Empresa.`,
  CAMPOS_OBRIGATORIOS: 'Campos Obrigatórios*',
};

export const TEXTOS_FORM_DADOS_OCORRENCIA = {
  EXTENSOES_PERMITIDAS: `Extensões permitidas: pdf, mp3, wma, aac, ogg, wav, bmp, gif, jpg,
  png, doc, docx, xls, xlsx, ppt, pptx.`,
  LINK_FORM_EXTERNO: 'Preencher o formulário*',
  INDICAR_CONTRATO_USUARIO: 'Olá, parceiro!',
  INDICAR_CONTRATO_FRASE:
    'Fizemos algumas melhorias, agora é necessário indicar o contrato que deseja registrar a solicitação.',
  LABEL_MENSAGEM: 'Mensagem*',
  MSG_CARACTER_RESTANTE: 'Caracteres restantes:',
  MSG_TAMANHO_UPLOAD: 'Tamanho máximo do arquivo excedido.',
  CAMPOS_OBRIGATORIOS: 'Campos Obrigatórios*',
  SEXO_MASCULINO: 'M',
  SEXO_FEMININO: 'F',
  MODAL_MSG_DUPLICIDADE:
    'Já existe ocorrência em andamento com esse assunto para o certificado selecionado.',
  ALERTA_FORM_TITULO: 'Atenção!',
  ALERTA_FORM: (
    <Text variant="body02-sm" margin>
      É necessário abrir apenas 01 ocorrência de comunicado de sinistro
      Prestamista por CPF, mesmo que o cliente tenha outros certificados. A área
      de Sinistros irá atuar em todos os certificados{' '}
      <span style={{ textDecoration: 'underline' }}>Prestamista</span> vinculado
      ao CPF informado.
    </Text>
  ),
  TITULO_FOP: `FOP 364 02 / Comunicado de Sinistro Prestamista`,
  SUBTITULO_SEGURADO: `1- Dados do Segurado`,
  SUBTITULO_RECLAMANTE: `2- Dados do Reclamante`,
  SUBTITULO_RECLAMANTE_INFO: `(Preencher, preferencialmente, com dados do familiar)`,
  SUBTITULO_SINISTRO: `3- Dados do Sinistro`,
  SUBTITULO_SINISTRO_INFO: `(Preenchimento da Seguradora (Corretora))`,
  LABEL_HISTORICO_SINISTRO: `Histórico do Sinistro (Breve Relato)*`,
};

const CAMPO_OBRIGATORIO_YUP = Yup.string().required(
  VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO,
);

export const FORM_COMUNICADO = {
  SINISTRO_PRESTAMISTA: {
    PRODUTO: 'PREST',
    MACROASSUNTO: '242',
    ASSUNTO: '10-603-11',
  },
};

export const MSG_TOAST_FOP_364_02 =
  'Dados do FOP 364 02 / Comunicado de Sinistro Prestamista salvos com sucesso.';

export const DADOS_OCORRENCIA_CAMPOS_OBRIGATORIOS: REGISTRO_OCORRENCIA_TYPES.IValidacaoCamposYupGeneric =
  {
    conteudoTextarea: CAMPO_OBRIGATORIO_YUP,
  };

export const DADOS_OCORRENCIA_FORM_FOP_OBRIGATORIOS: REGISTRO_OCORRENCIA_TYPES.IValidacaoCamposYupGeneric =
  {
    nomeDadosSegurado: CAMPO_OBRIGATORIO_YUP,
    cpfDadosSegurado: CAMPO_OBRIGATORIO_YUP,
    dataNascDadosSegurado: CAMPO_OBRIGATORIO_YUP,
    nomeDadosReclamante: CAMPO_OBRIGATORIO_YUP,
    cpfDadosReclamante: CAMPO_OBRIGATORIO_YUP,
    grauParentescoDadosReclamante: CAMPO_OBRIGATORIO_YUP,
    enderecoDadosReclamante: CAMPO_OBRIGATORIO_YUP,
    cidadeDadosReclamante: CAMPO_OBRIGATORIO_YUP,
    bairroDadosReclamante: CAMPO_OBRIGATORIO_YUP,
    coberturaPleiteadaDadosSinistro: CAMPO_OBRIGATORIO_YUP,
    dataOcorrenciaDadosSinistro: CAMPO_OBRIGATORIO_YUP,
    historicoDadosSinistro: CAMPO_OBRIGATORIO_YUP,
  };

export const initialRegistrarOcorrenciaDataState: REGISTRO_OCORRENCIA_TYPES.RegistrarOcorrenciaData =
  {
    cpfCnpjCliente: {
      cpf: false,
      cnpj: false,
      valor: '',
    },
    formDadosCliente: {
      novoCliente: true,
      tipoCliente: '',
      razaoSocialOuNome: '',
      emailCorporativoOuEmail: '',
      cep: '',
      endereco: '',
      bairro: '',
      cidade: '',
      uf: '',
      telefonePrincipal: '',
      telefoneSecundario: '',
      cpfRepresentante: '',
      nomeRepresentante: '',
      emailRepresentante: '',
      telefoneRepresentante: '',
      camposObrigatorios: {
        pessoaFisica: false,
        pessoaJuridica: false,
      },
    },
    formDadosOcorrencia: {
      produto: '',
      macroassunto: '',
      assunto: '',
      mensagem: '',
      listaContratos: [OPCAO_PADRAO_TABELA_DADOS_OCORRENCIA],
      contratoSelecionado: {
        id: '',
        produto: '',
        contrato: '',
        vigencia: '',
      },
      listaCamposDinamicos: [],
      camposDinamicosPreenchidos: [],
      arquivoAnexo: {} as FileList,
      botaoRegistroOcorrenciaHabilitado: true,
    },
    aberturaOcorrencia: {
      ocorrenciaAberta: false,
      numeroProtocolo: '',
      status: '',
      dataVencimento: '',
      codigoSolicitacao: '',
    },
    formFop: {
      comunicadoSinistroPrestamista: false,
      camposComunicadoSinistoPrestamista: {
        NomeSegurado: '',
        CPFSegurado: '',
        DthNascimento: '',
        Sexo: '',
        NomeReclamante: '',
        CPFReclamante: '',
        GrauParentesco: '',
        Email1: '',
        Email2: '',
        Endereco: '',
        Cidade: '',
        Bairro: '',
        Uf: '',
        DDDResidencial: '',
        TelefoneResidencial: '',
        DDDComercial: '',
        TelefoneComercial: '',
        DDDCelular: '',
        TelefoneCelular: '',
        Cobertura: '',
        DthSinistro: '',
        HistoricoSinistro: '',
      },
    },
  };
