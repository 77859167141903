import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import { ResponseHistoricoSimulacaoRenda } from 'previdencia/features/SimulacaoRenda/types/historicoSimulacaoRenda';
import * as ConsultarHistoricoSimulacaoRendaApi from 'previdencia/features/SimulacaoRenda/services/consultarHistoricoSimulacaoRenda.api';

const useConsultarHistoricoSimulacaoRenda = (): UseQueryResult<
  ResponseHistoricoSimulacaoRenda | undefined
> => {
  const { toastError } = useToast();
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  return useQuery(
    ['prev-consultar-historico-simulacao-renda', numCertificado],
    () =>
      ConsultarHistoricoSimulacaoRendaApi.consultarHistoricoSimulacaoRenda(
        cpfCnpj,
        numCertificado,
      ),
    {
      cacheTime: reactQueryCacheDuration(),
      refetchOnWindowFocus: false,
      retry: false,
      onError: (erro: Error) => toastError(erro.message),
    },
  );
};

export default useConsultarHistoricoSimulacaoRenda;
