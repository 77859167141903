/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any  */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import * as REQUEST_TYPES from '../types/RelatorioResgateRequest';
import * as RESPONSE_TYPES from '../types/RelatorioResgateResponse';

export const useRelatorioResgate = () => {
  const { response, loading, fetchData } = usePeco<
    REQUEST_TYPES.IRelatorioResgatePayload,
    RESPONSE_TYPES.IRelatorioResgateResponse[]
  >({
    api: { operationPath: PECOS.RelatorioResgate },
  });

  return {
    loading,
    response,
    fetchData,
  };
};
