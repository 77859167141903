import styled from 'styled-components/macro';

export const UploadButton = styled.label({
  transition: '0.1s',
  background: 'transparent',
  display: 'inline-block',
  border: '1px dotted #005FC8',
  width: '100%',
  cursor: 'pointer',
  outline: 'none',
  padding: '12px 24px',
  fontFamily: 'CAIXA Std',
  fontSize: '14px',
  textAlign: 'center',
  textDecoration: 'none',
  position: 'relative',
  pointerEvents: 'auto',
  minWidth: '148px',
  color: '#005FC8',
});

export const Input = styled.input.attrs({ type: 'file' })(() => ({
  display: 'none',
}));

export const InputFileContainer = styled.div`
  input[type='file'] {
    display: none;
  }
`;
