import React from 'react';
import * as ST from './styles';

export const HorizontalProgressBar: React.FC<{
  width: string | number;
}> = ({ width }) => {
  return (
    <ST.ProgressStatusVendasContainer>
      <ST.ProgressStatusVendas width={width} />
      <ST.ProgressStatusVendasValue>{width}%</ST.ProgressStatusVendasValue>
    </ST.ProgressStatusVendasContainer>
  );
};
