import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';
import {
  RequestParametrosRegimeTributario,
  ResponseParametrosRegimeTributario,
} from 'previdencia/features/SimulacaoRenda/types/simularBeneficios';

export const useParametrosRegimeTributario = () => {
  const {
    cliente: { numCertificado },
  } = useContext(AppContext);

  const { response, loading } = usePeco<
    RequestParametrosRegimeTributario,
    ResponseParametrosRegimeTributario
  >({
    api: { operationPath: PECOS.ConsultarParametrosRegimeTributario },
    autoFetch: true,
    payload: {
      numeroConta: numCertificado,
      codigoEmpresa: ENUM_TYPES.DefaultValue.CODIGO_EMPRESA,
    },
    handleResponse: {
      throwToastErrorGI: true,
      throwToastErrorBFF: false,
    },
  });

  return {
    loading,
    response,
  };
};
