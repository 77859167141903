import { FC, ReactElement, useContext } from 'react';
import { Display, Grid, Text } from '@cvp/design-system/react';
import { AppContext } from 'main/contexts/AppContext';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { IQuitacaoAnualDebitosDadosProps } from 'seguros/types/EmitirDeclaracaoQuitacaoAnualTypes';
import { formataDataQuitacao } from 'seguros/utils/quitacaoAnualDebitos';

export const QuitacaoAnualDebitosDados: FC<IQuitacaoAnualDebitosDadosProps> = ({
  dados,
}): ReactElement => {
  const { clienteVida } = useContext(AppContext);
  return (
    <Grid>
      <Grid.Item lg={9 / 10} md={1}>
        <Display alignItems="center">
          <Grid>
            <Grid.Item xs={1}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Período de Referência
                  </Text>
                  <Text variant="caption-02" color="text">
                    {dados?.anoBase}
                  </Text>
                </div>
              </Display>
            </Grid.Item>
            <Grid.Item xs={1}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Nome do Cliente
                  </Text>
                  <Text variant="caption-02" color="text">
                    {dados?.nomeCliente}
                  </Text>
                </div>
              </Display>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} lg={1 / 2} xl={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Certificado/Bilhete
                  </Text>
                  <Text variant="caption-02" color="text">
                    {tryGetValueOrDefault(
                      [
                        dados?.numeroCertificadoBilhete,
                        clienteVida?.numCertificado,
                      ],
                      '',
                    )}
                  </Text>
                </div>
              </Display>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} lg={1 / 2} xl={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Número da Apólice
                  </Text>
                  <Text variant="caption-02" color="text">
                    {dados?.numeroApolice}
                  </Text>
                </div>
              </Display>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} lg={1 / 2} xl={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Produto
                  </Text>
                  <Text variant="caption-02" color="text">
                    {dados?.nomeProduto}
                  </Text>
                </div>
              </Display>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} lg={1 / 2} xl={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Data de Início de Vigência do Certificado
                  </Text>
                  <Text variant="caption-02" color="text">
                    {formataDataQuitacao(dados?.dataInicioVigencia)}
                  </Text>
                </div>
              </Display>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} lg={1 / 2} xl={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Situação Atual do Seguro
                  </Text>
                  <Text variant="caption-02" color="text">
                    {dados?.situacaoSeguro}
                  </Text>
                </div>
              </Display>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} lg={1 / 2} xl={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Data de Emissão da Declaração
                  </Text>
                  <Text variant="caption-02" color="text">
                    {formataDataQuitacao(dados?.dataEmissaoDeclaracao)}
                  </Text>
                </div>
              </Display>
            </Grid.Item>
          </Grid>
        </Display>
      </Grid.Item>
    </Grid>
  );
};
