import LinkedValue from 'main/features/Validation/types/LinkedValue';
import { DadosParticipante } from 'main/types/Participante';

export type DadosCEP = {
  cep: string;
  endereco: string;
  complemento: string;
  bairro: string;
  cidade: string;
  idCidade: string;
  estado: string;
  idEstado: string;
  numero: string;
};

export type DadosAlteracao = {
  dadosEndereco: RequestAlterarEndereco | undefined;
  dadosTelefone: RequestAlterarTelefone[] | undefined;
  dadosEmail: RequestAlterarEmail | undefined;
};

export type RequestAlterarEndereco = {
  idEndereco: string;
  endereco: string;
  numero: string;
  tipoEndereco: string;
  complemento: string;
  bairro: string;
  cidade: string;
  idCidade: string;
  Estado: string;
  cep: string;
  Pais: string;
  tipoRua: string;
  enderecoPadrao: string;
};

export type RequestAlterarTelefone = {
  telefoneId: string | null;
  numeroTelefone: string | undefined;
  codigoArea: string | undefined;
  tipoTelefone: string | undefined;
  localTelefone: string | undefined;
  aceitarSms: string | undefined;
  telefonePrincipal: string | null;
  extensaoNumero: string | null;
};

export type RequestAlterarEmail = {
  emailId: string | null;
  email: string;
  tipoEmail: string;
};

export type RequestAtualizarConsentimento = {
  sistema: {
    codigo: string;
  };
  unidadeNegocio: {
    codigo: string;
  };
  processoNegocio: {
    subProcesso: {
      codigo: string;
    };
    macroProcesso: {
      codigo: string;
    };
  };
  evidencia: {
    codigo: string;
  };
  consentimento: {
    tipo: string;
    numConsentimentoNegocio: string;
  };
  cliente: {
    codigo: string;
  };
};

export type ResponseConsultarConsentimento = {
  consentimentos: [
    {
      consentimento: {
        data: string;
        descricao: string;
        tipo: number;
      };
      subProcessoNegocio: {
        codigo: number;
        descricao: string;
      };
    },
  ];
};

export type TipoTelefone = {
  tipoUm: string;
  tipoDois: string;
};

export enum CODIGO_PAIS {
  brasil = '105',
}

export enum CODIGO_CONSENTIMENTO {
  parceiros = 1,
  caixa = 2,
}

export enum ESTADO_CIVIL {
  SOLTEIRO = 'S',
  CASADO = 'C',
  VIUVO = 'V',
}

export enum ESTADO_CIVIL_LABEL {
  SOLTEIRO = 'Solteiro(a)',
  CASADO = 'Casado(a)',
  VIUVO = 'Viúvo(a)',
}

export type FormAtualizarDadosParticipanteProps = {
  dadosParticipante: DadosParticipante | undefined;
  dadosCep: DadosCEP | undefined;
  dadosConsentimentos: ResponseConsultarConsentimento | undefined;
  onAtualizar: (dados: DadosAlteracao) => void;
  onCancelar: () => void;
  fetchingDados: boolean;
  cepLink: LinkedValue<string>;
};

export type EditarParticipanteProps = {
  dadosParticipante: DadosParticipante | undefined;
  onCancelar: () => void;
};

export type VerParticipanteProps = {
  edit: () => void;
  data: DadosParticipante | undefined;
};

export type CheckBoxConsentimentosProps = {
  statusDadosPessoaisOutrosProdutos: boolean;
  setStatusDadosPessoaisOutrosProdutos: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  statusDadosPessoaisParceiros: boolean;
  setStatusDadosPessoaisParceiros: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

export type ValidaCamposObrigatoriosProps = {
  cepLink: LinkedValue<string>;
  numeroLink: LinkedValue<string>;
  enderecoLink: LinkedValue<string>;
  bairroLink: LinkedValue<string>;
  telefoneComercialLink: LinkedValue<string>;
  emailLink: LinkedValue<string>;
};
