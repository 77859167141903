import React, { useState } from 'react';
import { Grid } from '@cvp/design-system/react';
import { required } from 'main/features/Validation/validations';
import { Input } from 'main/components/form';
import masks from 'main/utils/masks';
import ModalNotificacao from 'previdencia/features/Aporte/components/ModalAporte';
import DadosBancariosOperacao from 'previdencia/features/Aporte/components/DadosBancarios/DadosBancariosOperacao';
import * as CONSTS from 'previdencia/features/Aporte/constants';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';

const DadosBancariosEditar: React.FC<
  APORTE_TYPES.IDadosBancariosEditarProps
> = ({ numeroAgencia, operacao, numeroConta, digitoConta }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Grid style={{ marginTop: 20 }}>
        <Grid.Item xs={1} lg={2 / 3} xl={3 / 10}>
          <DadosBancariosOperacao operacaoLink={operacao} />
        </Grid.Item>
        <Grid.Item xs={1} lg={1 / 3} xl={3 / 10}>
          <Input
            label="Agência:"
            inputMask={masks.numberOnly}
            link={numeroAgencia}
            validationRules={[required()]}
            maxLength="4"
          />
        </Grid.Item>
        <Grid.Item xs={1} md={2 / 3} xl={3 / 10}>
          <Input
            label="Conta Bancária:"
            inputMask={masks.numberOnly}
            link={numeroConta}
            validationRules={[required()]}
            maxLength="9"
          />
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 3} xl={1 / 10}>
          <Input
            label="Dígito: "
            inputMask={masks.numberOnly}
            maxLength="1"
            link={digitoConta}
          />
        </Grid.Item>
      </Grid>

      <ModalNotificacao
        tipo={CONSTS.enumTipoMensagemAporte.CONTA_BANCARIA_NOVA}
        titulo="Nova Conta Bancária Inválida"
        onClose={() => setOpenModal(false)}
        open={openModal}
      />
    </>
  );
};

export default DadosBancariosEditar;
