import React, { useState } from 'react';
import { useLocation } from 'react-router';
import Icon from 'main/components/Icon';
import { Accordion, Card, Grid, Text, Display } from '@cvp/design-system/react';
import { TextLabel } from 'main/styles/GlobalStyle';
import { maskCpfCnpj } from 'contratosPrestamista/utils/maskCpfCnpj';
import FabButton, { Action } from 'portabilidade/components/FabButton';
import { IResponseListaSegurosCancelados } from '../types/IResponseListaSegurosCancelados';
import ModalSendAndValidateCode from '../components/ModalSendAndValidateCode';

type LocationState = {
  proposta: IResponseListaSegurosCancelados;
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
const DetalhesCancelamento = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const {
    state: { proposta },
  } = useLocation<LocationState>();

  return (
    <Display type="display-block">
      <Card>
        <Card.Content>
          <Accordion open>
            <Accordion.Item title="Dados da Proposta">
              <Grid>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">Nome do Segurado</TextLabel>
                    <Text variant="body02-sm" proposta-testid="nomeSegurado">
                      {proposta?.nomeSegurado}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">
                      CPF/CNPJ do Segurado
                    </TextLabel>
                    <Text variant="body02-sm" proposta-testid="cpfCnpjSegurado">
                      {maskCpfCnpj(
                        proposta?.cpfCnpjSegurado ?? proposta.cpfCnpj,
                      )}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">Nº do Contrato</TextLabel>
                    <Text variant="body02-sm" proposta-testid="numeroProposta">
                      {proposta?.numContrato}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">Agência</TextLabel>
                    <Text
                      variant="body02-sm"
                      proposta-testid="codigoAgenciaVenda"
                    >
                      {proposta?.codigoAgenciaVenda}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">
                      Data de Emissão da Proposta
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      proposta-testid="propostaInicioVigencia"
                    >
                      {proposta?.dataHoraEmissaoDaProposta ?? ''}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">Valor Prêmio</TextLabel>
                    <Text
                      variant="body02-sm"
                      proposta-testid="valorPremioLiquido"
                    >
                      {proposta?.valorPremio}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">
                      Valor Prêmio Líquido
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      proposta-testid="valorPremioLiquido"
                    >
                      {proposta?.valorPremioLiquido}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">
                      Valor da Importância Segurada
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      proposta-testid="valorImportanciaSegurada"
                    >
                      {proposta?.valorImportanciaSegurada}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} lg={1 / 3}>
                  <div>
                    <TextLabel variant="body02-sm">
                      Status da Retenção
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      proposta-testid="descricaoPeriodicidadeCobranca"
                    >
                      {proposta?.statusReversao}
                    </Text>
                  </div>
                </Grid.Item>
              </Grid>
            </Accordion.Item>
          </Accordion>
        </Card.Content>
        <FabButton iconName="floatMenu">
          <Action
            text="Retenção de prestamista"
            onClick={() => setOpenModal(true)}
            disabled={!proposta.reversaoPermitida}
          >
            <Icon name="edit" />
          </Action>
        </FabButton>
      </Card>
      <ModalSendAndValidateCode
        open={openModal}
        handleClose={() => setOpenModal(false)}
        numContrato={proposta.numContrato}
      />
    </Display>
  );
};

export default DetalhesCancelamento;
