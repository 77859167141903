import styled from 'styled-components/macro';
import { Breadcrumb as BreadcrumbDS } from '@cvp/design-system/react';

export const Breadcrumb = styled(BreadcrumbDS)(({ ...rest }) => ({
  ...rest,
}));

Breadcrumb.Item = styled(BreadcrumbDS.Item)(({ ...rest }) => ({
  ...rest,
}));

export default Breadcrumb;
