import { forwardRef, ForwardRefRenderFunction } from 'react';
import * as S from 'previdencia/components/ButtonsValidaDadosTabelaFundos/styles';

export type ButtonsValidaDadosTabelaFundosProps = {
  onClick: () => void;
  labelButtonSubmit: string;
  loading?: boolean;
} & Partial<HTMLInputElement>;

const ButtonsValidaDadosTabelaFundos: ForwardRefRenderFunction<
  HTMLInputElement,
  ButtonsValidaDadosTabelaFundosProps
> = ({ onClick, labelButtonSubmit, disabled, loading }, ref) => {
  return (
    <S.Container>
      <S.AlterarStyler
        onClick={onClick}
        ref={ref}
        variant="primary"
        type="submit"
        disabled={disabled}
        loading={loading}
      >
        {labelButtonSubmit}
      </S.AlterarStyler>
    </S.Container>
  );
};

export default forwardRef(ButtonsValidaDadosTabelaFundos);
