import React from 'react';
import { Card, Button, Grid, Display } from '@cvp/design-system/react';
import { validDateRange } from 'main/features/Validation/validations';
import useFieldLink from 'main/hooks/useFieldLink';

import { validate } from 'main/features/Validation/utils/validateRules';
import Calendar from 'main/components/form/Calendar';
import { CalendarData } from 'main/components/form/Calendar/Calendar.type';
import { useHistory } from 'react-router';
import { useToast } from 'main/hooks/useToast';

const ConsultarStatusPropostaDps: React.FC = () => {
  const history = useHistory();
  const { toastError } = useToast();
  const [cpfLink] = useFieldLink<string>('');
  const [numeroContratoLink] = useFieldLink<string>('');
  const [dateLink, validateDateLink] = useFieldLink<CalendarData>({
    initialDate: null,
    finalDate: null,
  });
  const redirect = () => {
    history.push('/prestamista/dps/status/consulta/resultado', {
      cpfCnpj: cpfLink.get().value,
      dataInicial: dateLink.get().value.initialDate?.toISOString(),
      dataFinal: dateLink.get().value.finalDate?.toISOString(),
      numeroContrato: numeroContratoLink.get().value,
    });
  };

  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    const dataValida = validate([validateDateLink]);

    if (!dataValida) {
      toastError('Datas inválidas!');
      return;
    }

    redirect();
  };

  return (
    <Card style={{ minHeight: 'auto' }}>
      <Card.Content data-testid="tabelaListaPropostas">
        <form onSubmit={handleSubmit}>
          <Grid>
            <Grid.Item xs={1 / 3}>
              <Calendar
                link={dateLink}
                requiredFinalDate
                requiredInitialDate
                validationRules={[validDateRange()]}
                range
                placeholder="Selecione a data"
                data-testid="data-propostas"
              />
            </Grid.Item>
          </Grid>
          <Display>
            <Button type="submit" variant="primary">
              Consultar
            </Button>
          </Display>
        </form>
      </Card.Content>
    </Card>
  );
};

export default ConsultarStatusPropostaDps;
