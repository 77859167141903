import { Text, Grid, TextField } from '@cvp/design-system/react';
import { showErrorInput } from 'main/utils/formik';
import masks from 'main/utils/masks';
import * as CONSTS from 'prestamista/features/dadosSegurado/constants/constants';
import * as DADOS_SEGURADO_TYPES from 'prestamista/features/dadosSegurado/types/DadosSegurado';
import * as S from 'prestamista/features/dadosSegurado/pages/AtualizacaoDadosSegurado/styles';

const FormAtualizacaoDadosSegurado = ({
  formik,
  handleCepChange,
}: DADOS_SEGURADO_TYPES.IFormAtualizacaoDadosSeguradoProps) => {
  return (
    <>
      <S.GridContainerForm>
        <Grid.Item xs={1} lg={2 / 10}>
          <TextField
            {...formik.getFieldProps(formik.values.cep)}
            maxLength={9}
            label="CEP*"
            data-testid="inputDadosSeguradoCep"
            name="cep"
            value={masks.cep.mask(formik.values.cep)}
            onChange={handleCepChange}
            {...showErrorInput(formik, 'cep')}
          />
        </Grid.Item>
      </S.GridContainerForm>
      <S.GridContainerForm marginTop={20}>
        <Grid.Item xs={1} lg={5 / 10}>
          <TextField
            {...formik.getFieldProps(formik.values.endereco)}
            label="Endereço*"
            data-testid="inputDadosSeguradoEndereco"
            name="endereco"
            value={formik.values.endereco}
            {...showErrorInput(formik, 'endereco')}
          />
        </Grid.Item>
        <Grid.Item xs={1} lg={1 / 10}>
          <TextField
            {...formik.getFieldProps(formik.values.numeroEndereco)}
            label="Número*"
            data-testid="inputDadosSeguradoNumeroEndereco"
            name="numeroEndereco"
            value={formik.values.numeroEndereco}
            {...showErrorInput(formik, 'numeroEndereco')}
          />
        </Grid.Item>
        <Grid.Item xs={1} lg={4 / 10}>
          <TextField
            {...formik.getFieldProps(formik.values.complementoEndereco)}
            label="Complemento"
            data-testid="inputDadosSeguradoComplementoEndereco"
            name="complementoEndereco"
            value={formik.values.complementoEndereco}
            {...showErrorInput(formik, 'complementoEndereco')}
          />
        </Grid.Item>
      </S.GridContainerForm>
      <S.GridContainerForm>
        <Grid.Item xs={1} md={8 / 10} lg={5 / 10}>
          <TextField
            {...formik.getFieldProps(formik.values.bairro)}
            label="Bairro*"
            data-testid="inputDadosSeguradoBairro"
            name="bairro"
            value={formik.values.bairro}
            {...showErrorInput(formik, 'bairro')}
          />
        </Grid.Item>
        <Grid.Item xs={1} md={2 / 10} lg={1 / 10}>
          <TextField
            {...formik.getFieldProps(formik.values.uf)}
            label="UF"
            value={formik.values.uf}
            disabled
            name="uf"
          />
        </Grid.Item>
        <Grid.Item xs={1} lg={4 / 10}>
          <TextField
            {...formik.getFieldProps(formik.values.cidade)}
            label="Cidade"
            value={formik.values.cidade}
            disabled
            name="cidade"
          />
        </Grid.Item>
      </S.GridContainerForm>

      <S.GridContainerForm>
        <Grid.Item xs={3 / 10} lg={2 / 10} xl={1 / 10}>
          <TextField
            {...formik.getFieldProps(formik.values.ddd1)}
            maxLength={2}
            label="DDD 1"
            data-testid="inputDadosSeguradoDDD1"
            name="ddd1"
            value={formik.values.ddd1}
            {...showErrorInput(formik, 'ddd1')}
          />
        </Grid.Item>
        <Grid.Item xs={7 / 10} lg={3 / 10} xl={2 / 10}>
          <TextField
            {...formik.getFieldProps(formik.values.telefone1)}
            maxLength={13}
            label="Telefone Celular"
            data-testid="inputDadosSeguradoTelefoneCelular"
            name="telefone1"
            value={masks.phoneWithoutDDD.mask(formik.values.telefone1)}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue(
                'telefone1',
                masks.phoneWithoutDDD.unmask(value),
              );
            }}
            {...showErrorInput(formik, 'telefone1')}
          />
        </Grid.Item>
        <Grid.Item xs={3 / 10} lg={2 / 10} xl={1 / 10}>
          <TextField
            {...formik.getFieldProps(formik.values.ddd2)}
            maxLength={2}
            label="DDD 2"
            data-testid="inputDadosSeguradoDDD2"
            name="ddd2"
            value={formik.values.ddd2}
            {...showErrorInput(formik, 'ddd2')}
          />
        </Grid.Item>
        <Grid.Item xs={7 / 10} lg={3 / 10} xl={2 / 10}>
          <TextField
            {...formik.getFieldProps(formik.values.telefone2)}
            maxLength={13}
            label="Telefone Residencial"
            data-testid="inputDadosSeguradoTelefoneResidencial"
            name="telefone2"
            value={masks.phoneWithoutDDD.mask(formik.values.telefone2)}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue(
                'telefone2',
                masks.phoneWithoutDDD.unmask(value),
              );
            }}
            {...showErrorInput(formik, 'telefone2')}
          />
        </Grid.Item>
        <Grid.Item xs={1} xl={4 / 10}>
          <TextField
            {...formik.getFieldProps(formik.values.email)}
            label="E-mail"
            data-testid="inputDadosSeguradoEmail"
            name="email"
            value={formik.values.email}
            {...showErrorInput(formik, 'email')}
          />
        </Grid.Item>
      </S.GridContainerForm>
      <S.GridContainerForm marginTop={20}>
        <Grid.Item xs={1}>
          <Text
            variant="caption-02"
            color="text"
            data-testid="infoDadosCorretos"
          >
            {CONSTS.ATUALIZACAO_DADOS_SEGURADO.INFO_CONFIRMACAO}
          </Text>
          <Text variant="body02-sm" data-testid="infoCamposObrigatorios">
            <strong>
              {CONSTS.ATUALIZACAO_DADOS_SEGURADO.INFO_OBRIGATORIO}
            </strong>
          </Text>
        </Grid.Item>
      </S.GridContainerForm>
    </>
  );
};

export default FormAtualizacaoDadosSegurado;
