import React from 'react';
import { Text } from '@cvp/design-system/react';
import {
  INCLUSAO_ALTERACAO,
  TEXTO_ATENCAO,
} from 'previdencia/features/AlteracaoBeneficiarios/constants/constants';

const TextoAlteracaoBeneficiario: React.FC = () => {
  return (
    <>
      <Text variant="body02-sm" className="hide-print" margin>
        {INCLUSAO_ALTERACAO}
      </Text>
      <Text variant="body03-md" margin>
        <strong>Atenção:</strong> {TEXTO_ATENCAO.DADOS_PESSOAIS}
      </Text>
    </>
  );
};

export default TextoAlteracaoBeneficiario;
