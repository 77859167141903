import React from 'react';
import { Button, Display, Text } from '@cvp/design-system/react';
import * as CONSTS from 'seguros/constants/AlteracaoValorCapital';
import { AlteracaoValorCapitalTipoSolicitacaoProps } from 'seguros/types/AlteracaoValorCapital';

const AlteracaoValorCapitalTipoSolicitacao = ({
  formik,
}: AlteracaoValorCapitalTipoSolicitacaoProps) => {
  return (
    <>
      <Text variant="body02-md" margin>
        {CONSTS.TEXTOS_ALTERACAO_VALOR_CAPITAL.TITULO_SOLICITACAO}
      </Text>

      <Display>
        <Button
          variant="primary"
          onClick={() => {
            formik.setValues({
              ...formik.values,
              isReducao: true,
              isAumento: false,
            });
          }}
          disabled={formik.values.isReducao}
          data-testid="tipoReducao"
        >
          Redução
        </Button>

        <Button
          variant="primary"
          onClick={() => {
            formik.setValues({
              ...formik.values,
              isAumento: true,
              isReducao: false,
            });
          }}
          disabled={formik.values.isAumento}
          data-testid="tipoAumento"
        >
          Aumento
        </Button>
      </Display>
    </>
  );
};

export default AlteracaoValorCapitalTipoSolicitacao;
