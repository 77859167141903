import React from 'react';
import { Text, Button } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { ContainerButton, DisplayCards } from '../styles';
import CardCobertura from '../CardCobertura';
import {
  COBERTURA_ALTERAR_VALOR,
  COBERTURA_VALOR_ULTIMA_PARCELA,
  TEXTO_COBERTURA,
  COBERTURA_REATIVAR,
} from '../../constants';
import { validarReativacao } from '../../utils/beneficioDeProtecao';
import {
  EnumCobertura,
  EnumPeriodicidade,
  EnumPeriodicidadeAbreviada,
  EnumSelectPeriodicidade,
} from '../../types/enum';
import { AdquirirCoberturaProps } from '../../types/AdquirirCoberturaProps';

const AdquirirCobertura: React.FC<AdquirirCoberturaProps> = ({
  response,
  cobertura,
  beneficios,
  setPersonalizar,
  setPlano,
  etapaAnterior,
  proximaEtapa,
}) => {
  const goToAdquirir = (
    plano: string,
    contribuicao: number | undefined,
    beneficio: number | undefined,
  ) => {
    setPlano(plano.toLocaleLowerCase(), contribuicao, beneficio);
    proximaEtapa();
  };

  const reativacao = validarReativacao(beneficios, cobertura);

  const coberturaExistente =
    cobertura === EnumCobertura.pensao
      ? beneficios?.pensao
      : beneficios?.peculio;

  const periodicidade =
    coberturaExistente?.periodicidade === EnumPeriodicidadeAbreviada.anual
      ? EnumPeriodicidade.anual
      : EnumPeriodicidade.mensal;

  return (
    <>
      <Text variant="body01-lg" color="text" margin>
        Cobertura Ideal
      </Text>
      <Text variant="body02-sm" color="text">
        {TEXTO_COBERTURA}
      </Text>
      <DisplayCards>
        <RenderConditional condition={!reativacao}>
          <CardCobertura
            title="Mensal"
            valorContribuicao={
              cobertura === EnumCobertura.pensao
                ? response?.valoresMensal.vlrSugeridoContribPensao
                : response?.valoresMensal.vlrSugeridoContribPeculio
            }
            formaContribuicao="todo mês"
            cobertura={cobertura}
            valorBeneficio={
              cobertura === EnumCobertura.pensao
                ? response?.valoresMensal.vlrSugeridoPensao
                : response?.valoresMensal.vlrSugeridoPeculio
            }
            onClick={goToAdquirir}
          />
          <CardCobertura
            title="Anual"
            valorContribuicao={
              cobertura === EnumCobertura.pensao
                ? response?.valoresAnual.vlrSugeridoContribPensao
                : response?.valoresAnual.vlrSugeridoContribPeculio
            }
            formaContribuicao="por ano"
            cobertura={cobertura}
            valorBeneficio={
              cobertura === EnumCobertura.pensao
                ? response?.valoresAnual.vlrSugeridoPensao
                : response?.valoresAnual.vlrSugeridoPeculio
            }
            onClick={goToAdquirir}
          />
        </RenderConditional>
        <RenderConditional condition={reativacao}>
          <CardCobertura
            title={
              coberturaExistente?.periodicidade ===
              EnumPeriodicidadeAbreviada.anual
                ? EnumPeriodicidade.anual
                : EnumPeriodicidade.mensal
            }
            valorContribuicao={tryGetValueOrDefault(
              [coberturaExistente?.valorContribuicao],
              0,
            )}
            formaContribuicao={
              periodicidade === EnumPeriodicidade.mensal
                ? EnumSelectPeriodicidade.mensal
                : EnumSelectPeriodicidade.anual
            }
            cobertura={cobertura}
            valorBeneficio={tryGetValueOrDefault(
              [coberturaExistente?.valorBeneficio],
              0,
            )}
            onClick={goToAdquirir}
          />
        </RenderConditional>
      </DisplayCards>
      <RenderConditional condition={reativacao}>
        <Text variant="body02-sm" color="text">
          <strong>{COBERTURA_REATIVAR}</strong>
        </Text>
        <Text variant="body02-sm" color="text">
          <strong>{COBERTURA_VALOR_ULTIMA_PARCELA}</strong>
        </Text>
        <Text variant="body02-sm" color="text" margin>
          <strong>{COBERTURA_ALTERAR_VALOR}</strong>
        </Text>
      </RenderConditional>
      <RenderConditional condition={!reativacao}>
        <ContainerButton justifyContent="center">
          <Button
            variant="primary"
            data-testid="personalizar"
            onClick={() => setPersonalizar(true)}
          >
            Personalizar
          </Button>
        </ContainerButton>
      </RenderConditional>
      <Button
        variant="outlined"
        data-testid="voltar"
        onClick={() => etapaAnterior()}
      >
        Voltar
      </Button>
    </>
  );
};
export default AdquirirCobertura;
