import React from 'react';
import { Text } from '@cvp/design-system/react';
import { FlexContainer } from 'main/styles/GlobalStyle';
import Icon from 'main/components/Icon';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import * as S from './styles';
import { definirNomeIcone } from '../../utils/operacoesBeneficiarios';

type InformeReservaData = {
  valorBeneficio: string;
  descricaoCobertura: string;
  nomTipoPagamento: string;
  tipoBeneficio: string;
};

type InformReservaProps = {
  data: InformeReservaData;
  count: number;
  saldoTotal: string | undefined;
};

const InformeReserva: React.FC<InformReservaProps> = ({
  data,
  count,
  saldoTotal,
}) => {
  return (
    <S.Container>
      <FlexContainer alignItems="center" justifyContent="space-between">
        <S.Wrapper>
          <S.WrapperIcon margin={20}>
            <Icon name={definirNomeIcone(data.descricaoCobertura || '')} />
          </S.WrapperIcon>
          <S.Description>{data.nomTipoPagamento}</S.Description>
        </S.Wrapper>

        <S.Wrapper>
          <S.WrapperColumn>
            <S.WrapperIcon>
              <Icon name="user" />
              <Text variant="body02-md" color="primary">
                {count}
              </Text>
            </S.WrapperIcon>
          </S.WrapperColumn>
          <S.WrapperColumn>
            {(data.tipoBeneficio === 'PR' && (
              <>
                <Text variant="body02-sm" color="primary">
                  Reserva disponível
                </Text>
                <Text variant="body02-md">
                  {formatarValorPadraoBrasileiro(saldoTotal)}
                </Text>
              </>
            )) || (
              <>
                <Text variant="body02-sm" color="primary">
                  Indenização
                </Text>
                <Text variant="body02-md">
                  {formatarValorPadraoBrasileiro(data.valorBeneficio)}
                </Text>
              </>
            )}
          </S.WrapperColumn>
        </S.Wrapper>
      </FlexContainer>
    </S.Container>
  );
};

export default InformeReserva;
