import React from 'react';
import { Accordion, Card, Grid, Text, Display } from '@cvp/design-system/react';
import { TextLabel } from 'main/styles/GlobalStyle';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { DetalheTarefaCardSkeleton } from './DetalheTarefaCardSkeleton';
import CardFallback from './CardFallback';
import { IDadosCardProps } from '../../types/IDadosCardProps';

const DadosDoCertificado: React.FunctionComponent<IDadosCardProps> = ({
  isLoading,
  dadosPortabilidade,
}) => {
  if (isLoading) {
    return <DetalheTarefaCardSkeleton />;
  }
  if (!isLoading && dadosPortabilidade) {
    return (
      <Display type="display-block">
        <Card>
          <Card.Content>
            <Accordion data-testid="accordionDadosCertificado">
              <Accordion.Item title="Dados do certificado">
                <Grid>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">Certificado</TextLabel>
                      <Text variant="body02-sm" data-testid="contrato">
                        {dadosPortabilidade?.contrato.numeroContrato}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Modalidade do Plano
                      </TextLabel>
                      <Text variant="body02-sm" data-testid="modalidadeDoPlano">
                        {dadosPortabilidade?.contrato.modalidadePlano}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Valor do carregamento
                      </TextLabel>
                      <Text variant="body02-sm" data-testid="valorCarregamento">
                        {formatarValorPadraoBrasileiro(
                          dadosPortabilidade?.contrato.valorCarregamento,
                        )}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">Agência Venda</TextLabel>
                      <Text variant="body02-sm" data-testid="agenciaVenda">
                        {dadosPortabilidade?.portabilidade.numeroAgenciaOrigem}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">Agência Digital</TextLabel>
                      <Text variant="body02-sm" data-testid="agenciaDigital">
                        {dadosPortabilidade?.portabilidade.agenciaDigital
                          ? 'Sim'
                          : 'Não'}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Saldo total do certificado
                      </TextLabel>
                      <Text variant="body02-sm" data-testid="saldoAtual">
                        {formatarValorPadraoBrasileiro(
                          dadosPortabilidade?.contrato.valorSaldoAtualizado,
                        )}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Saldo em prev por cpf
                      </TextLabel>
                      <Text variant="body02-sm" data-testid="saldoCliente">
                        {formatarValorPadraoBrasileiro(
                          dadosPortabilidade?.contrato.valorSaldoCliente,
                        )}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Taxa de administração financeira
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="taxaAdministracaoFinanceira"
                      >
                        {
                          dadosPortabilidade?.contrato
                            .taxaAdministrativaFinanceira
                        }
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Código do Produto
                      </TextLabel>
                      <Text variant="body02-sm" data-testid="codigoDoProduto">
                        {dadosPortabilidade?.contrato.codigoProduto}
                      </Text>
                    </div>
                  </Grid.Item>
                </Grid>
              </Accordion.Item>
            </Accordion>
          </Card.Content>
        </Card>
      </Display>
    );
  }

  return <CardFallback title="Dados da solicitação de portabilidade" />;
};

export { DadosDoCertificado };
