import { getUserName } from 'main/features/Auth/utils/auth';
import { usePeco } from 'main/hooks/usePeco';
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { useState } from 'react';
import { PECOS_SINISTRO } from 'sinistro/config/endpoints';
import { obterStatusSinistroVida } from 'sinistro/features/statusSinistro/services/statusSinistroVida.api';
import {
  IStatusSinistroVidaPayload,
  IStatusSinistroVidaResponse,
  IProdutoSinistroVida,
} from 'sinistro/features/statusSinistro/types/IStatusSinistro';

async function carregaTimeline(
  produtosVida: IProdutoSinistroVida[],
): Promise<IProdutoSinistroVida[]> {
  const resultado: IProdutoSinistroVida[] = [];
  await Promise.all(
    produtosVida.map(async produto => {
      resultado.push({
        ...produto,
        timeline: await obterStatusSinistroVida({
          cpfCnpj: produto.cpfCnpjPessoa.toString().padStart(11, '0'),
          numeroCertificado: produto.numeroCertificado.toString(),
          numeroProtocolo: produto.numeroProtocoloSinistro.toString(),
        }),
      });
    }),
  );

  return resultado;
}

function flatSinistrosVida(
  consultaTimelineResult?: IHandleReponseResult<IStatusSinistroVidaResponse>,
): IProdutoSinistroVida[] {
  const listaInformeSinistro = tryGetValueOrDefault(
    [consultaTimelineResult?.entidade?.dados],
    [],
  );

  return listaInformeSinistro.flatMap(item => item.informeSinistro);
}

export const useConsultarSinistroCpfCnpjVida = () => {
  const { fetchData } = usePeco<
    IStatusSinistroVidaPayload,
    IStatusSinistroVidaResponse
  >({
    api: { operationPath: PECOS_SINISTRO.ConsultarTimelineSinistroCpfCnpj },
    handleResponse: {
      throwToastErrorGI: false,
      throwToastErrorBFF: false,
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<IProdutoSinistroVida[]>();

  const obterDados = async (params: IStatusSinistroVidaPayload) => {
    setLoading(true);

    const produtosVida = flatSinistrosVida(
      await fetchData({
        ...params,
        usuario: getUserName(),
      }),
    );

    try {
      const produtosVidaComTimeline = await carregaTimeline(produtosVida);
      setResponse(produtosVidaComTimeline);
    } catch (requestError: unknown) {
      setResponse(produtosVida);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    response,
    setResponse,
    obterStatusSinistroVida: obterDados,
  };
};
