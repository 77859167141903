import { PECOS_PRESTAMISTA } from 'contratosPrestamista/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import {
  ISolicitarDpsRequest,
  ISolicitarDpsResponse,
} from 'contratosPrestamista/features/dpseletronica/types';

export const usePecoSolicitarDps = () => {
  const { fetchData: fecthSolicitarDps, loading: loadingSolicitarDps } =
    usePeco<ISolicitarDpsRequest, ISolicitarDpsResponse>({
      api: {
        operationPath: PECOS_PRESTAMISTA.SolicitarDps,
      },
      handleResponse: { throwToastErrorBFF: false },
    });

  return {
    loadingSolicitarDps,
    fecthSolicitarDps,
  };
};
