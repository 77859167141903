import { Modal as ModalBase, Text as TextBase } from '@cvp/design-system/react';
import styled from 'styled-components/macro';

export const Modal = styled(ModalBase)({
  maxWidth: '500px',
  width: '100%',
});

export const Text = styled(TextBase)({
  fontSize: '24px',
});

export const IconContainer = styled.div`
  margin: 0 !important;
  svg {
    height: 20px;
  }
`;

export const TextClientName = styled.div(() => ({
  maxWidth: '75%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  color: '#414042',
}));
