import React from 'react';
import { Text } from '@cvp/design-system/react';
import { TextoRendaSimuladaProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';
import * as S from 'previdencia/features/SimulacaoRenda/components/TextoRendaSimulada/styles';

const TextoRendaSimulada: React.FC<TextoRendaSimuladaProps> = ({ dados }) => {
  return (
    <S.WrapperWarning>
      <Text variant="body02-sm" margin>
        O valor da renda simulada está abaixo do previsto no regulamento do seu
        plano:
      </Text>
      <Text variant="body02-sm">{dados?.descMensagemError}</Text>
    </S.WrapperWarning>
  );
};

export default TextoRendaSimulada;
