import React, { useEffect, useRef, useState } from 'react';
import { Text, Button } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import Modal from 'main/components/Modal';
import SkeletonLoading from 'main/components/SkeletonLoading';
import TabelaFundos from 'previdencia/components/TabelaFundos';
import { TabelaFundosHandles } from 'previdencia/components/TabelaFundos/TabelaFundos';
import { useTransferenciaFundosContext } from '../../contexts/ContextTransferenciaFundos';
import useDefinirFundoContribuicaoRegular from '../../hooks/useDefinirFundoContribuicaoRegular';
import { RequestDefinirFundoContribuicaoRegular } from '../../types/ContribuicaoRegular.types';
import * as S from './styles';
import useConfirmarTransferencia from '../../hooks/useConfirmarTransferencia';
import {
  converterFundoParaRequestDefinirFundoContribuicaoRegular,
  converterResponseFundosContribParaFundo,
} from '../../factories/transferenciaFundoInvestimentoFactory';
import { direcionarTelaFinalizacaoTransferencia } from '../../utils/transferenciaFundosInvestimento';

const RedistribuirContribuicaoRegular: React.FC = () => {
  const tabelaFundosRef = useRef<TabelaFundosHandles>(null);
  const [openModal, setOpenModal] = useState(false);
  const [mensagemModal, setMensagemModal] = useState<string | undefined>('');
  const [requestService, setRequestService] = useState<
    RequestDefinirFundoContribuicaoRegular | undefined
  >(undefined);
  const {
    dadosContribuicaoRegular,
    responseRedistribuirFundos,
    numTransferencia,
    direcionarNovaTela,
    permissaoAssinatura,
  } = useTransferenciaFundosContext();

  const { isLoading, definirFundoContribuicaoRegular } =
    useDefinirFundoContribuicaoRegular();

  const {
    confirmarTransferenciaEntreFundos: confirmarTransferencia,
    loadingConfirmarTransferenciaEntreFundos: loadingConfirmarTransferencia,
    obterConfirmarTransferencia,
  } = useConfirmarTransferencia();

  useEffect(() => {
    if (requestService) {
      definirFundoContribuicaoRegular(
        responseRedistribuirFundos?.numTransferencia,
        requestService,
      );
    }
  }, [requestService]);

  useEffect(() => {
    if (numTransferencia) {
      obterConfirmarTransferencia(numTransferencia);
    }
  }, [numTransferencia]);

  useEffect(() => {
    direcionarTelaFinalizacaoTransferencia(
      confirmarTransferencia,
      permissaoAssinatura,
      direcionarNovaTela,
    );
  }, [confirmarTransferencia]);

  const realizarRedistribuicao = () => {
    const validacao = tabelaFundosRef.current?.validateFundos();
    if (validacao?.tabelaAlterada) {
      const requestData =
        converterFundoParaRequestDefinirFundoContribuicaoRegular(
          validacao.tabelaAlterada,
        );
      setRequestService(requestData);
    }
    if (validacao?.valoresDiferentes) {
      setMensagemModal(validacao.mensagemValoresDiferentes);
      setOpenModal(true);
      return undefined;
    }

    return undefined;
  };

  if (isLoading || loadingConfirmarTransferencia) {
    return <SkeletonLoading blocks={1} />;
  }

  return (
    <>
      <Text color="primary">Redistribuir Contribuição Regular</Text>
      <Text variant="body01-sm" margin>
        Como foi feita transferência total. É necessário redistribuir o valor de
        contribuição pertencente ao fundo selecionado.
      </Text>
      <Text variant="body01-sm" margin>
        Você possui{' '}
        <strong>
          {formatarValorPadraoBrasileiro(
            dadosContribuicaoRegular?.vlrContribRegular,
          )}
        </strong>{' '}
        que deve ser distribuidos entre os seguintes fundos:
      </Text>
      <TabelaFundos
        fundos={
          converterResponseFundosContribParaFundo(
            dadosContribuicaoRegular?.fundosContrib,
          ) || []
        }
        quantidadeMaximaFundos={1}
        valorTotal={dadosContribuicaoRegular?.vlrContribRegular}
        ref={tabelaFundosRef}
        isLoading={false}
        tipoResgate={undefined}
        resgate={undefined}
      />
      <S.ContainerButtons>
        <Button variant="primary" onClick={() => realizarRedistribuicao()}>
          Confirmar
        </Button>
      </S.ContainerButtons>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        {mensagemModal}
      </Modal>
    </>
  );
};

export default RedistribuirContribuicaoRegular;
