import React, { useState } from 'react';
import { Radio, Text } from '@cvp/design-system/react';
import useFieldLink from 'main/hooks/useFieldLink';
import Table from 'main/components/Table';
import RenderConditional from 'main/components/RenderConditional';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { useValidarConta } from 'main/features/ValidacaoContaCaixa/hooks/useValidarConta';
import { formatarNumeroContaPadraoKiprev } from 'main/utils/contaBancaria';
import { WrapperSlideFadeContent } from 'main/components/Wrappers/styled/WrapperSlideFadeContent';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ResponseValidarConta } from 'previdencia/types/DadosContaBancaria';
import ModalNotificacao from 'previdencia/features/Aporte/components/ModalAporte';
import DadosBancariosEditar from 'previdencia/features/Aporte/components/DadosBancarios/DadosBancariosEditar';
import ButtonsEtapasAporte from 'previdencia/features/Aporte/components/ButtonsEtapasAporte';
import { usePecoDadosBancarios } from 'previdencia/features/Aporte/hooks/usePecoAporte';
import * as FACTORY from 'previdencia/features/Aporte/factories/aporteFactory';
import * as CONSTS from 'previdencia/features/Aporte/constants';
import * as UTILS from 'previdencia/features/Aporte/utils/aporteUtils';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';
import * as S from 'previdencia/features/Aporte/styles';

const DadosBancarios: React.FC<APORTE_TYPES.IDadosBancariosProps> = ({
  aporteDadosBancarios,
}) => {
  const { dadosDatasBancarios, loadingDadosDatasBancarios } =
    usePecoDadosBancarios();

  const { featureData, cpfCnpj } =
    usePrevidenciaContext<APORTE_TYPES.IAporteContextData>();

  const [exibeEditarNovaConta, setExibeEditarNovaConta] =
    useState<boolean>(false);
  const [agenciaBancaria] = useFieldLink('');
  const [operacao] = useFieldLink('');
  const [conta] = useFieldLink('');
  const [digito] = useFieldLink('');
  const [tipoContaSelecionadaDigitada, setTipoContaSelecionadaDigitada] =
    useState(CONSTS.enumTipoMensagemAporte.CONTA_BANCARIA_EXISTENTE);
  const [tituloMensagemModal, setTituloMensagemModal] = useState(
    CONSTS.enumTituloMensagemContaBancaria.CONTA_SELECIONADA,
  );

  const resultConta = FACTORY.converterResponseParaListaDadosBancariosAporte(
    dadosDatasBancarios?.entidade,
  );

  const [validarDados, setValidarDados] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { obterValidacaoConta, loading: loadingValidacaoConta } =
    useValidarConta();

  const preenchimentoInvalido = (valido: boolean): void => {
    if (valido) {
      setOpenModal(true);
      setValidarDados(false);
    }
  };

  const validacaoRetornoBaseCaixa = (
    respostaValidacao: ResponseValidarConta | undefined,
  ): boolean => {
    const respostaEhValida =
      respostaValidacao?.codigoRetorno !==
      CONSTS.enumValidacaoContaBancaria.CONTA_BANCARIA_CADASTRADA_VALIDADA;

    if (respostaEhValida) {
      setOpenModal(true);
      setValidarDados(false);
    }

    return respostaEhValida;
  };

  let habilitarBotaoNovaConta: boolean = checkIfAllItemsAreTrue([
    !!conta.get().value,
    !!agenciaBancaria.get().value,
    !!operacao.get().value,
    !!digito.get().value,
  ]);

  const setContaDefaultValues = (): void => {
    conta.set({ value: '', isValid: false });
    agenciaBancaria.set({ value: '', isValid: false });
    operacao.set({ value: '', isValid: false });
    digito.set({ value: '', isValid: false });
    habilitarBotaoNovaConta = false;
  };

  const handleSelecionarConta = async (canalId: string): Promise<void> => {
    setExibeEditarNovaConta(false);
    setValidarDados(true);
    setContaDefaultValues();
    setTituloMensagemModal(
      CONSTS.enumTituloMensagemContaBancaria.CONTA_SELECIONADA,
    );

    const itemSelecionado = UTILS.retornoItemSelecionado(resultConta, canalId);

    aporteDadosBancarios.set({
      value: {
        tipoPagamentoId: tryGetValueOrDefault(
          [itemSelecionado?.tipoPagamentoId],
          '',
        ),
        descricaoPagamento: tryGetValueOrDefault(
          [itemSelecionado?.descricaoPagamento],
          '',
        ),
        canalId: tryGetValueOrDefault([itemSelecionado?.canalId], ''),
        dataPagamento: tryGetValueOrDefault(
          [itemSelecionado?.dataPagamento],
          '',
        ),
        metodoPagamento: tryGetValueOrDefault(
          [itemSelecionado?.metodoPagamento],
          '',
        ),
        tipoContaBanco: tryGetValueOrDefault(
          [itemSelecionado?.tipoContaBanco],
          '',
        ),
        numeroBanco: tryGetValueOrDefault([itemSelecionado?.numeroBanco], ''),
        nomeBanco: tryGetValueOrDefault([itemSelecionado?.nomeBanco], ''),
        numeroAgencia: tryGetValueOrDefault(
          [itemSelecionado?.numeroAgencia],
          '',
        ),
        digitoAgencia: tryGetValueOrDefault(
          [itemSelecionado?.digitoAgencia],
          '',
        ),
        numeroConta: formatarNumeroContaPadraoKiprev(
          tryGetValueOrDefault([itemSelecionado?.numeroConta], '0'),
        ),
        digitoConta: tryGetValueOrDefault([itemSelecionado?.digitoConta], ''),
        operacao: tryGetValueOrDefault([itemSelecionado?.operacao], ''),
        banco: tryGetValueOrDefault([itemSelecionado?.banco], ''),
        conta: formatarNumeroContaPadraoKiprev(
          tryGetValueOrDefault([itemSelecionado?.conta], '0'),
        ),
        agencia: tryGetValueOrDefault([itemSelecionado?.agencia], ''),
      },
      isValid: true,
      errorMsg: '',
    });

    const contaSelecionadaPossuiPreenchimentoValido: boolean =
      checkIfAllItemsAreTrue([
        !!itemSelecionado?.conta,
        !!itemSelecionado?.agencia,
        !!itemSelecionado?.operacao,
        !!itemSelecionado?.digitoConta,
      ]);

    if (contaSelecionadaPossuiPreenchimentoValido) {
      const respostaValidacao = await obterValidacaoConta({
        agencia: tryGetValueOrDefault([itemSelecionado?.agencia], ''),
        codigoInstituicao: CONSTS.NumeroInstituicaoCaixa.CONTA_CAIXA,
        conta: UTILS.recuperaNumeroConta(
          tryGetValueOrDefault([itemSelecionado?.numeroConta], ''),
        ),
        digito: tryGetValueOrDefault([itemSelecionado?.digitoConta], ''),
        operacao: UTILS.recuperaNumeroOperacao(
          tryGetValueOrDefault([itemSelecionado?.numeroConta], ''),
        ),
        cpf: cpfCnpj,
      });

      validacaoRetornoBaseCaixa(respostaValidacao);
    }

    preenchimentoInvalido(!contaSelecionadaPossuiPreenchimentoValido);

    setTipoContaSelecionadaDigitada(
      CONSTS.enumTipoMensagemAporte.CONTA_BANCARIA_EXISTENTE,
    );
  };

  const setAporteInitialValue = (): void => {
    aporteDadosBancarios.set({
      value: {
        tipoPagamentoId: '',
        descricaoPagamento: '',
        canalId: '',
        dataPagamento: '',
        metodoPagamento: '',
        nomeBanco: '',
        tipoContaBanco: '',
        numeroBanco: CONSTS.NumeroInstituicaoCaixa.CONTA_CAIXA,
        numeroAgencia: agenciaBancaria.get().value,
        digitoAgencia: CONSTS.DIGITO_AGENCIA_DEFAULT,
        numeroConta: formatarNumeroContaPadraoKiprev(conta.get().value),
        digitoConta: digito.get().value,
        operacao: operacao.get().value,
        banco: CONSTS.NumeroInstituicaoCaixa.CONTA_CAIXA,
        conta: `${conta.get().value}-${digito.get().value}`,
        agencia: agenciaBancaria.get().value,
      },
      isValid: true,
      errorMsg: '',
    });
  };

  const handleNovaConta = async (): Promise<void> => {
    if (habilitarBotaoNovaConta) {
      setAporteInitialValue();

      const respostaValidacaoNovaConta = await obterValidacaoConta({
        agencia: agenciaBancaria.get().value,
        codigoInstituicao: CONSTS.NumeroInstituicaoCaixa.CONTA_CAIXA,
        conta: formatarNumeroContaPadraoKiprev(conta.get().value),
        digito: digito.get().value,
        operacao: operacao.get().value,
        cpf: cpfCnpj,
      });

      validacaoRetornoBaseCaixa(respostaValidacaoNovaConta);

      setTituloMensagemModal(
        CONSTS.enumTituloMensagemContaBancaria.CONTA_DIGITADA,
      );

      setTipoContaSelecionadaDigitada(
        CONSTS.enumTipoMensagemAporte.CONTA_BANCARIA_NOVA,
      );

      if (
        respostaValidacaoNovaConta?.codigoRetorno ===
        CONSTS.enumValidacaoContaBancaria.CONTA_BANCARIA_CADASTRADA_VALIDADA
      ) {
        featureData?.proximaEtapa();
      }
    }
  };

  const handleSeguirProximaEtapa = (dadosContaDigitada: boolean): void => {
    if (dadosContaDigitada) {
      handleNovaConta();
    } else {
      featureData?.proximaEtapa();
    }
  };

  const handleIndicarNovaConta = (): void => {
    setValidarDados(false);
    setContaDefaultValues();
    setExibeEditarNovaConta(true);
    setAporteInitialValue();
    setContaDefaultValues();
  };

  const resultDados = resultConta.map(({ canalId, ...rest }) => ({
    contaSelecionada: (
      <Radio
        data-testid="contaSelecionada"
        onChange={() => handleSelecionarConta(canalId)}
        value="1"
        name="contaSelecionada"
        checked={aporteDadosBancarios.get().value.canalId === canalId}
      />
    ),
    ...rest,
  }));

  if (loadingDadosDatasBancarios) {
    return <SkeletonLoading blocks={1} lines={2} />;
  }

  return (
    <>
      <Text variant="body02-md" color="primary" margin>
        Selecione uma das contas a seguir:
      </Text>

      <Table
        columns={CONSTS.COLUNAS_TABELA_CONTAS_BANCARIAS_APORTE}
        data={tryGetValueOrDefault([resultDados], [])}
        noHeader
        striped
        highlightOnHover
        pagination
        paginationPerPage={10}
        paginationComponentOptions={{
          rowsPerPageText: 'Items por página',
          rangeSeparatorText: 'de',
        }}
        noDataComponent={CONSTS.DADOS_BANCARIOS_NAO_ENCONTADOS}
      />

      <RenderConditional condition={!!resultDados?.length}>
        <S.WrapperRadioAporte>
          <Radio
            data-testid="contaSelecionada"
            value="1"
            name="contaSelecionada"
            onChange={handleIndicarNovaConta}
            checked={!!exibeEditarNovaConta}
          />
          <S.TextRadioAporte>
            Clique aqui para indicar outra conta
          </S.TextRadioAporte>
        </S.WrapperRadioAporte>
      </RenderConditional>

      <RenderConditional condition={!!exibeEditarNovaConta}>
        <WrapperSlideFadeContent>
          <DadosBancariosEditar
            numeroAgencia={agenciaBancaria}
            numeroConta={conta}
            digitoConta={digito}
            operacao={operacao}
          />
        </WrapperSlideFadeContent>
      </RenderConditional>

      <ButtonsEtapasAporte
        proximaEtapa={() => {
          handleSeguirProximaEtapa(exibeEditarNovaConta);
        }}
        disabled={checkIfSomeItemsAreTrue([
          checkIfAllItemsAreTrue([!validarDados, !habilitarBotaoNovaConta]),
          loadingValidacaoConta,
        ])}
        loading={loadingValidacaoConta}
        buttonLabel="Avançar"
      />
      <ModalNotificacao
        tipo={tipoContaSelecionadaDigitada}
        titulo={tituloMensagemModal}
        onClose={() => setOpenModal(false)}
        open={openModal}
      />
    </>
  );
};

export default DadosBancarios;
