import React from 'react';
import { Button, Grid, Text } from '@cvp/design-system/react';
import EmailSenderModal from 'main/components/EmailSenderModal';
import RenderConditional from 'main/components/RenderConditional';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { lastYears } from 'previdencia/features/ServicosIR/utils/lastYears';
import { hasIR } from 'previdencia/features/ServicosIR/utils/hasIR';
import { ConsultaImpostoRendaPorAnoProps } from 'previdencia/features/ServicosIR/types/ConsultaImpostoRendaPorAnoProps';
import * as S from 'previdencia/features/ServicosIR/components/styles';
import { useEnviarIREmail } from '../hooks/useEnviarIREmail';

const ConsultaIRPorAno: React.FC<ConsultaImpostoRendaPorAnoProps> = ({
  anoSelecionado,
  setAnoSelecionado,
  exerciciosIR,
  messageError,
  obterIR,
  loading,
  certificadoSelecionado,
  recuperacaoEmailExecutada,
}) => {
  const { goDadosPlano } = usePrevNavigation();
  const { montarObjetoEmail } = useEnviarIREmail();
  const objetoEmail = montarObjetoEmail(anoSelecionado);

  return (
    <>
      <RenderConditional
        condition={Boolean(hasIR(exerciciosIR))}
        component={
          <Grid.Item xs={1}>
            {lastYears(
              tryGetValueOrDefault([exerciciosIR?.anosDisponiveis], ['']),
            ).map(item => (
              <S.ButtonAnoConsultaIR
                key={item}
                variant={item === anoSelecionado ? 'secondary' : 'outlined'}
                onClick={() => setAnoSelecionado(item)}
                data-testid="ButtonAnoConsultaIR"
              >
                {item}
              </S.ButtonAnoConsultaIR>
            ))}
            <S.ButtonContainer>
              <Button variant="outlined" onClick={goDadosPlano}>
                Voltar
              </Button>
              <Button
                loading={loading}
                disabled={!anoSelecionado}
                onClick={() => {
                  if (anoSelecionado) {
                    obterIR();
                  }
                }}
              >
                Emitir IR
              </Button>
              <EmailSenderModal
                objetoEmail={objetoEmail}
                disabled={
                  !(
                    certificadoSelecionado &&
                    anoSelecionado &&
                    recuperacaoEmailExecutada
                  )
                }
              />
            </S.ButtonContainer>
            <RenderConditional
              condition={messageError}
              component={
                <Text variant="body01-md" color="primary" margin>
                  Não existem contribuições e resgates efetuados neste
                  certificado no ano de {anoSelecionado}.
                </Text>
              }
            />
          </Grid.Item>
        }
      />
      <RenderConditional
        condition={Boolean(!hasIR(exerciciosIR))}
        component={
          <Grid.Item xs={1}>
            <Text
              variant="body01-md"
              color="primary"
              margin
              data-testid="msgNaoExisteIRCPF"
            >
              Não existem informes de rendimento para este CPF.
            </Text>
          </Grid.Item>
        }
      />
    </>
  );
};

export default ConsultaIRPorAno;
