import { useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import * as CancelamentoPrevidenciaApi from '../services/cancelamentoPrevidenciaApi';
import {
  IResponseSolicitacaoCancelamento,
  SolicitacaoCancelamentoPayload,
} from '../types/IResponseCancelamento';

const useSolicitarCancelamentoPrevidencia = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { toastError } = useToast();
  const [response, setResponse] = useState<string | undefined>();
  const cancelarPrevidencia = async (
    payload: SolicitacaoCancelamentoPayload,
  ): Promise<IResponseSolicitacaoCancelamento | undefined> => {
    try {
      setLoading(true);
      const result =
        await CancelamentoPrevidenciaApi.solicitaCancelamentoPrevidencia(
          payload,
        );
      setResponse(result?.url);
      return result;
    } catch (requestError: any) {
      toastError(requestError.message);
      return undefined;
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    response,
    cancelarPrevidencia,
  };
};

export default useSolicitarCancelamentoPrevidencia;
