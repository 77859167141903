import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { PECOS_VIDA } from 'seguros/config/endpoints';
import {
  IRequestObterHistoricoPagamentos,
  IResponseHistoricoPagamentos,
} from 'vida/features/Pagamentos/types/HistoricoPagamentosTypes';

export const useHistoricoPagamentos = () => {
  const { clienteVida } = useContext(AppContext);

  const getHistoricoPagamentos = usePeco<
    IRequestObterHistoricoPagamentos,
    IResponseHistoricoPagamentos[]
  >({
    api: { operationPath: PECOS_VIDA.ObterInformacoesPagamentoSeguroVida },
    autoFetch: true,
    payload: {
      numeroCertificadoBilhete: tryGetValueOrDefault(
        [clienteVida?.numCertificado],
        '',
      ),
    },
    handleResponse: {
      throwToastErrorGI: false,
      throwToastErrorBFF: false,
    },
  });

  return { getHistoricoPagamentos };
};
