import RiskBar from 'previdencia/components/RiskBar';
import styled from 'styled-components/macro';
import { Display } from '@cvp/design-system/react';

export const RiskBarAporteStyleAporte = styled(RiskBar)(() => ({
  minHeight: '60px',
  minWidth: '100px',
}));

export const ContainerAporte = styled.div(({ theme }) => ({
  padding: '20px',
  justifyContent: 'end',
  gap: '50px',

  [theme.breakpoint.md()]: {
    display: 'flex',
  },
}));

export const ContainerModalAporte = styled.div(({ theme }) => ({
  padding: '20px',
  justifyContent: 'center',
  gap: '50px',
  [theme.breakpoint.md()]: {
    display: 'flex',
  },
}));

export const ActionsAporte = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  button: {
    width: '100%',
    marginBottom: '10px',
  },

  [theme.breakpoint.xs() && theme.breakpoint.sm()]: {
    flexDirection: 'row',
    button: {
      width: '100%',
      marginRight: '10px',
    },
  },
}));

export const RadioContainerAporte = styled.div({
  marginTop: '7px',
  padding: '0 30px',
});

export const ContainerBrancoAporte = styled.div(({ theme: { color } }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  padding: '5px',
  borderBottom: `1px solid ${color.neutral['04']}`,
  background: '#fff',
}));

export const ContainerCinzaAporte = styled.div(({ theme: { color } }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  padding: '5px',
  background: `${color.neutral['07']}`,
  borderBottom: `1px solid ${color.neutral['04']}`,
}));

export const ContainerListaAporte = styled.div({
  padding: '15px 0',
});

export const WrapperRadioAporte = styled.div(() => ({
  paddingLeft: 16,
  marginTop: 20,
  display: 'flex',
  alignItems: 'center',
}));

export const TextRadioAporte = styled.h4(() => ({
  fontSize: 18,
  color: '#414042',
  marginTop: 0,
  marginLeft: 80,
  fontWeight: '500',
  lineHeight: '160%',
}));

export const WraperTotalFundosAporte = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const WrapperAporteWarning = styled.div(({ theme: { color } }) => ({
  border: `1px solid ${color.brandSecondary.light}`,
  background: `${color.brandSecondary.light}0d`,
  padding: 20,
  borderRadius: 8,
  marginTop: 20,
  marginBottom: 20,

  '& p:first-child': {
    color: color.brandSecondary.dark,
    fontWeight: 'bold',
  },
}));

export const ContainerExcecaoAporte = styled(Display)(
  ({ theme: { color } }) => ({
    border: `1px solid ${color.brandSecondary.medium}`,
    background: color.feedback.alert,
    color: color.feedback.error,
    padding: '10px',

    '& p': {
      color: color.feedback.error,
    },
  }),
);
