import React, { ChangeEvent } from 'react';
import { Switch } from '@cvp/design-system/react';
import masks from 'main/utils/masks';
import { formatarData } from 'main/utils';
import Table from 'main/components/Table/Table';
import {
  BeneficiariosPersonData,
  BeneficiorioData,
} from 'previdencia/features/AlteracaoBeneficiarios/types/Beneficiarios';
import { TabelaReservaProps } from 'previdencia/features/AlteracaoBeneficiarios/types/AlteracaoBeneficiariosProps';
import { COLUNAS_TABELA_RESERVA } from 'previdencia/constants/constants';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { verificarBeneficiarioAtual } from '../../utils/operacoesBeneficiarios';
import * as S from './styles';
import {
  definirColunasTabelaReserva,
  definirOverflow,
} from '../../utils/definirColunas';
import { HERDEIROS_LEGAIS } from '../../constants/constants';

const TabelaReserva: React.FC<TabelaReservaProps> = ({
  print,
  beneficiarios,
  atualizaBeneficiarios,
  TipoParentesco,
}) => {
  function TipoParentescoSelecionado(id: string) {
    return TipoParentesco.filter(item => {
      return item.valor === id;
    }).map(kinship => kinship.label);
  }

  const percentualDistribuicaoTotal = beneficiarios.reduce((prev, curr) => {
    if (!curr?.isRemove) {
      return prev + Number(curr.percentualDistribuicao);
    }
    return prev;
  }, 0);

  const BeneficiarioTotal: BeneficiorioData = {
    descricaoGrauParentesco: '',
    grauParentescoId: '',
    percentualDistribuicao: `${percentualDistribuicaoTotal}`,
    pessoaFisica: {
      pessoaFisicaId: '',
      genero: '',
      dataNascimento: new Date('1900-00-00T00:00:00-00:00'),
      nome: 'TOTAL (%)',
      cpfCnpj: '',
    },
    situacao: '',
    total: true,
  };

  const beneficiariosComTotal = [...beneficiarios];
  beneficiariosComTotal.push(BeneficiarioTotal);

  const atualizaPorcentagem = (
    pessoaFisica: BeneficiariosPersonData,
    value: string,
  ) => {
    if (!atualizaBeneficiarios) {
      return;
    }
    atualizaBeneficiarios(
      beneficiarios.map(beneficiario => {
        if (
          verificarBeneficiarioAtual(
            beneficiario.pessoaFisica.pessoaFisicaId,
            pessoaFisica.pessoaFisicaId,
            pessoaFisica?.cpfCnpj,
            beneficiario.pessoaFisica.cpfCnpj,
          )
        ) {
          return {
            ...beneficiario,
            percentualDistribuicao: masks.percentage.mask(value),
            isUpdate: true,
          };
        }
        return beneficiario;
      }),
    );
  };
  const atualizaRemover = (pessoaFisica: BeneficiariosPersonData) => {
    if (!atualizaBeneficiarios) {
      return;
    }
    atualizaBeneficiarios(old =>
      old.map(beneficiario => {
        if (
          verificarBeneficiarioAtual(
            beneficiario.pessoaFisica.pessoaFisicaId,
            pessoaFisica.pessoaFisicaId,
            pessoaFisica.cpfCnpj,
            beneficiario.pessoaFisica.cpfCnpj,
          )
        ) {
          return {
            ...beneficiario,
            isRemove: !beneficiario.isRemove,
          };
        }
        return beneficiario;
      }),
    );
  };
  const montaArray = () => {
    return beneficiariosComTotal.map(
      ({
        percentualDistribuicao,
        pessoaFisica,
        grauParentescoId,
        isRemove,
        total,
      }) => ({
        beneficiario: (
          <S.TableBodyWrapper isRemoved={!isRemove}>
            {pessoaFisica.nome}
          </S.TableBodyWrapper>
        ),
        parentesco: (
          <S.TableBodyWrapper isRemoved={!isRemove}>
            {TipoParentescoSelecionado(grauParentescoId)}
          </S.TableBodyWrapper>
        ),
        dataNascimento: (
          <S.TableBodyWrapper isRemoved={!isRemove}>
            {!total && formatarData(pessoaFisica.dataNascimento)}
          </S.TableBodyWrapper>
        ),
        percentual: (
          <>
            <S.InputPorcent
              totalBeneficiario={total}
              value={masks.percentage.mask(
                percentualDistribuicao.split('.')[0],
              )}
              disabled={isRemove || total}
              autoFocus
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                atualizaPorcentagem(pessoaFisica, e.target.value)
              }
            />
            %
          </>
        ),
        remover: !total && (
          <S.WrapperSwitch>
            <Switch
              controlled
              onChange={() => {
                atualizaRemover(pessoaFisica);
              }}
              checked={isRemove}
            />
          </S.WrapperSwitch>
        ),
      }),
    );
  };
  return (
    <>
      {(beneficiarios.length === 0 && (
        <>
          <Table
            data={[
              {
                beneficiario: HERDEIROS_LEGAIS,
                parentesco: '',
                dataNascimento: '',
                percentual: '',
                remover: '',
              },
            ]}
            responsive
            columns={COLUNAS_TABELA_RESERVA}
            noDataComponent="Não há dados para exibir."
            style={{ marginBottom: 40 }}
          />{' '}
        </>
      )) || (
        <>
          <Table
            data={tryGetValueOrDefault([montaArray()], [])}
            responsive
            columns={definirColunasTabelaReserva(print)}
            noDataComponent="Não há dados para exibir."
            style={{ marginBottom: 40, overflow: definirOverflow(print) }}
          />
        </>
      )}
    </>
  );
};

export default TabelaReserva;
