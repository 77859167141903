import * as Yup from 'yup';
import * as CONSTS from 'registroOcorrenciaASC/features/consultarOcorrencia/constants/constants';
import * as CONSULTA_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/consultarOcorrencia/types/ConsultarOcorrencia';

/**
 * Limita o tamanho de um texto, adicionando três pontos (...) no final, se necessário.
 *
 * @param {string} texto - O texto original que será limitado.
 * @param {number} [limite=20] - O tamanho máximo do texto permitido (incluindo os três pontos).
 * @returns {string} - O texto limitado, com os três pontos adicionados se necessário.
 */
export const charSizeLimit = (texto: string, limite = 20): string => {
  if (texto.length <= limite) return texto;

  const extensao = texto.substring(texto.lastIndexOf('.') + 1);
  const nomeArquivo = texto.substring(0, texto.lastIndexOf('.'));
  const tamanhoNomeArquivo = limite - extensao.length - 3; // Considerando os três pontos (...)
  const nomeArquivoLimitado = nomeArquivo.substring(0, tamanhoNomeArquivo);

  return `${nomeArquivoLimitado}...${extensao}`;
};

export const FormikDetalhesOcorrenciaSchema = Yup.object({
  conteudoTextarea: Yup.string().required(
    CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO,
  ),
});

/**
 * Retorna uma representação formatada do tamanho de um arquivo em bytes, kilobytes (KB),
 * megabytes (MB), gigabytes (GB) ou terabytes (TB).
 *
 * @param {number} [fileSize] - O tamanho do arquivo em bytes.
 * @returns {string} - Uma string formatada representando o tamanho do arquivo com a unidade de medida apropriada.
 *                    Por exemplo: "1.23 KB", "456 MB", "789 GB", etc.
 *                    Retorna "Valor desconhecido" se o tamanho do arquivo não for informado (undefined).
 *                    Retorna "0 bytes" se o tamanho do arquivo for 0.
 */
export const fileSizeMask = (fileSize?: number): string => {
  if (fileSize === undefined) return 'Valor desconhecido';

  if (fileSize === 0) return '0 bytes';

  const medidas = ['bytes', 'KB', 'MB', 'GB', 'TB'];

  // Calcula o índice da unidade de medida apropriada com base no tamanho do arquivo.
  const index = Math.floor(Math.log2(fileSize) / 10);

  // Calcula o tamanho do arquivo na unidade de medida apropriada e arredonda para 2 casas decimais.
  const tamanho = (fileSize / 1024 ** index).toFixed(2);

  return `${tamanho} ${medidas[index]}`;
};

/**
 * Edita uma data fornecida com novos valores para ano, mês, dia, hora, minuto e segundo.
 * @param {Object} params - Parâmetros para edição da data.
 * @param {string} params.dataOriginal - A data original no formato de string.
 * @param {number} [params.novoAno] - Novo valor para o ano.
 * @param {number} [params.novoMes] - Novo valor para o mês (1 a 12).
 * @param {number} [params.novoDia] - Novo valor para o dia (1 a 31).
 * @param {number} [params.novaHora] - Nova hora do dia (0 a 23).
 * @param {number} [params.novoMinuto] - Novo minuto da hora (0 a 59).
 * @param {number} [params.novoSegundo] - Novo segundo do minuto (0 a 59).
 * @returns {string} - A data formatada no formato de string (YYYY-MM-DD HH:mm:ss.SSS).
 */
export const editarData = ({
  dataOriginal,
  novoAno,
  novoMes,
  novoDia,
  novaHora = 0,
  novoMinuto = 0,
  novoSegundo = 0,
}: CONSULTA_OCORRENCIA_TYPES.IEditarData): string => {
  const data = new Date(dataOriginal);

  if (novoAno !== undefined) data.setUTCFullYear(novoAno);
  if (novoMes !== undefined) data.setUTCMonth(novoMes - 1);
  if (novoDia !== undefined) data.setUTCDate(novoDia);

  data.setUTCHours(novaHora, novoMinuto, novoSegundo);

  return data.toISOString().replace('T', ' ').replace('Z', '');
};
