/* eslint-disable react/jsx-no-constructed-context-values */

import React, {
  createContext,
  useLayoutEffect,
  useContext,
  PropsWithChildren,
} from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { AuthContext } from 'main/features/Auth/contexts/AuthContext';
import {
  VIDA_PERMISSIONS,
  VIDA_PERMISSIONS_STRING,
} from 'main/features/Auth/config/userProfiles';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { useFeatureData } from 'main/hooks/useFeatureData';
import { obterChaveArmazenamentoDadosFuncionalidades } from 'config/storage_keys';
import { removeSessionItem } from 'main/utils/storage';
import {
  getPermissionsInSession,
  setPermissionsInSession,
} from 'main/features/Auth/utils/auth';

interface ISegurosContext<T = unknown> {
  certificado: string;
  cpfCnpj: string;
  numApolice: string;
  codigoCliente?: string;
  nomeSegurado?: string;
  tipoCertificado?: string;
  procedureReferencia?: string;
  featureData?: T | null;
  setFeatureData: (data: T | null) => void;
  clearFeatureData: () => void;
}

export function createSegurosContext<T = unknown>() {
  return createContext<ISegurosContext<T> | null>(null);
}

const SegurosContext = createSegurosContext<unknown>();

SegurosContext.displayName = 'SegurosContextFeatureData';

export function useSegurosContext<T>() {
  const context = useContext(SegurosContext);
  if (!context) {
    throw new Error('useSegurosContext deve ser usado com o SegurosContext');
  }
  return context as ISegurosContext<T>;
}

const SegurosContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    clienteVida,
    setContextualMenu,
    contextualMenu,
    restoreDefaultMenuVida,
  } = useContext(AppContext);
  const { setUserPermissions } = useContext(AuthContext);
  const { clearFeatureData, setFeatureData, featureData } = useFeatureData();
  const featureDataKey = obterChaveArmazenamentoDadosFuncionalidades();
  const converterTipoCertificado = (tipoCertificado?: string) => {
    switch (tipoCertificado) {
      case VIDA_PERMISSIONS_STRING.CERTIFICADO_PF:
        return VIDA_PERMISSIONS.CERTIFICADO_PF;
      case VIDA_PERMISSIONS_STRING.CERTIFICADO_PJ:
        return VIDA_PERMISSIONS.CERTIFICADO_PJ;
      case VIDA_PERMISSIONS_STRING.BILHETE_PF:
        return VIDA_PERMISSIONS.BILHETE_PF;
      default:
        return '';
    }
  };

  const armazenarPermissoesUsuario = (tipoCertificado?: string) => {
    setPermissionsInSession([converterTipoCertificado(tipoCertificado)]);
  };

  useLayoutEffect(() => {
    if (
      checkIfAllItemsAreTrue([
        !!clienteVida?.cpfCnpj,
        !!clienteVida?.numApolice,
        contextualMenu === 'default',
      ])
    ) {
      setContextualMenu('seguros');
    }
    if (clienteVida.tipoCertificado) {
      armazenarPermissoesUsuario(clienteVida.tipoCertificado);
      setUserPermissions(getPermissionsInSession());
    }
    return () => {
      if (contextualMenu === 'seguros') {
        restoreDefaultMenuVida(clienteVida?.cpfCnpj);
        removeSessionItem(featureDataKey);
      }
    };
  }, [
    clienteVida?.cpfCnpj,
    clienteVida?.numApolice,
    clienteVida?.tipoCertificado,
  ]);

  return (
    <SegurosContext.Provider
      value={{
        certificado: tryGetValueOrDefault([clienteVida?.numCertificado], ''),
        numApolice: tryGetValueOrDefault([clienteVida?.numApolice], ''),
        cpfCnpj: tryGetValueOrDefault([clienteVida?.cpfCnpj], ''),
        codigoCliente: tryGetValueOrDefault([clienteVida?.codigoCliente], ''),
        nomeSegurado: tryGetValueOrDefault([clienteVida?.nomeSegurado], ''),
        tipoCertificado: tryGetValueOrDefault(
          [clienteVida?.tipoCertificado],
          '',
        ),
        procedureReferencia: tryGetValueOrDefault(
          [clienteVida?.procedureReferencia],
          '',
        ),
        featureData,
        setFeatureData,
        clearFeatureData,
      }}
    >
      {children}
    </SegurosContext.Provider>
  );
};

export default SegurosContextProvider;
