import { PREV_PERMISSIONS } from 'main/features/Auth/config/userProfiles';

export const MENU_PERMISSIONS = {
  CONSULTAS: [
    PREV_PERMISSIONS.BENEFICIARIOS_CADASTRADOS_EDITAR,
    PREV_PERMISSIONS.INFORMACOES_DO_PLANO,
    PREV_PERMISSIONS.ENDERECO_DE_CORRESPONDENCIA__CONSULTA,
    PREV_PERMISSIONS.ENDERECO_DE_CORRESPONDENCIA__EDITAR,
    PREV_PERMISSIONS.CONTRIBUICAO_ADICIONAL,
    PREV_PERMISSIONS.CONTRIBUICOES_EM_ABERTO,
    PREV_PERMISSIONS.CONTRIBUICOES_PAGAS,
    PREV_PERMISSIONS.HISTORICO_DE_SOLICITACOES,
    PREV_PERMISSIONS.REAJUSTE_E_REENQUADRAMENTO,
    PREV_PERMISSIONS.SIMULADOR_DE_RENDA,
  ],
  ALTERACOES: [
    PREV_PERMISSIONS.BENEFICIARIOS_CADASTRADOS_EDITAR,
    PREV_PERMISSIONS.DIA_DE_VENCIMENTO_DO_PLANO,
    PREV_PERMISSIONS.ALTERAR_DADOS_DE_PAGAMENTO,
    PREV_PERMISSIONS.PRAZO_DE_TERMINO_DO_PLANO,
    PREV_PERMISSIONS.ALTERAR_VALOR_DA_CONTRIBUICAO,
    PREV_PERMISSIONS.ALTERAR_REGIME_TRIBUTARIO,
  ],
  EMISSOES: [
    PREV_PERMISSIONS.EXTRATO_DETALHADO,
    PREV_PERMISSIONS.RENTABILIDADE_E_COTAS,
    PREV_PERMISSIONS.QUITACAO_ANUAL,
    PREV_PERMISSIONS.INFORMACOES_DOS_BENEFICIARIOS_CONSULTA,
    PREV_PERMISSIONS.IMPOSTO_DE_RENDA,
    PREV_PERMISSIONS.EMITIR_CERTIFICADO,
  ],
  SERVICOS: [
    PREV_PERMISSIONS.CONTRIBUICAO_ADICIONAL,
    PREV_PERMISSIONS.ATIVACAO_E_SUSPENSAO_DE_CONTRIBUICOES,
    PREV_PERMISSIONS.SOLICITACAO_DE_RESGATE,
    PREV_PERMISSIONS.HISTORICO_E_CANCELAMENTO_DE_RESGATE,
    PREV_PERMISSIONS.TRANSFERENCIA_DE_FUNDOS,
    PREV_PERMISSIONS.INCLUIR_PROTECAO,
    PREV_PERMISSIONS.CANCELAR_CERTIFICADO,
  ],
};
