import { usePeco } from 'main/hooks/usePeco';
import { toastError, toastSuccess } from 'main/hooks/useToast';
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import { PECOS_RETENCAO_PORTABILIDADE } from 'portabilidade/config/endpoints';
import { getAtualizacaoPortabilidadePayload } from 'portabilidade/features/retencao/factories/payloadAtualizacaoPortabilidade';
import { IRequestDefinirRetencao } from 'portabilidade/features/retencao/types/IRequestDefinirRetencao';
import { IResponseDefinirRetencao } from 'portabilidade/features/retencao/types/IResponseDefinirRetencao';

export const useDefinirAcaoRetencao = () => {
  const { fetchData, loading, error } = usePeco<
    IRequestDefinirRetencao,
    IResponseDefinirRetencao
  >({
    api: {
      operationPath: PECOS_RETENCAO_PORTABILIDADE.registrarPortabilidade,
    },
  });

  const defirnirAcaoRetencao = async (idRetencao: string, idAcao: string) => {
    try {
      const result = await fetchData({
        ...getAtualizacaoPortabilidadePayload(),
        numPortabilidade: idRetencao,
        acao: idAcao,
      });
      const { mensagens, sucessoBFF } =
        result ?? ({} as IHandleReponseResult<IResponseDefinirRetencao>);
      const mensagemServico = (mensagens ?? [])[0].descricao;

      if (sucessoBFF) {
        toastSuccess(mensagemServico);
        return result;
      }
      toastError(mensagemServico);
    } catch (requestError: any) {
      toastError(requestError);
    }
    return {} as IHandleReponseResult<IResponseDefinirRetencao>;
  };

  return {
    defirnirAcaoRetencao,
    loading,
    error,
  };
};
