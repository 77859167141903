import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import PrivateRoute from 'main/components/Route';
import { IPrivateRoute } from 'main/components/Route/PrivateRoute';
import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';
import React from 'react';
import { Switch } from 'react-router-dom';
import ConsultaResultado from '../features/consultarValorIS/pages/ConsultaResultado';

const routes: IPrivateRoute[] = [
  {
    path: '/ferramentas/consultar-valor-is',
    exact: true,
    component: ConsultaResultado,
    key: 'consultar-valor-is',
    authenticated: true,
    breadcrumb: 'Consultar valor IS',
    requiredRoles: [
      USER_PROFILES.ANALISTA_TI,
      USER_PROFILES.ANALISTA_MANUTENCAO,
    ],
  },
];

/**
 * Utilizar caso seja necessário personalizar o nome dos breadcrumbs
 */
export const ferramentasBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
  '/ferramentas': null,
};

const RotasFerramentas = (): React.ReactElement => (
  <Switch>
    {routes.map(route => (
      <PrivateRoute
        key={route.key}
        path={route.path}
        exact={route.exact}
        component={route.component}
        authenticated={route.authenticated}
        requiredRoles={route.requiredRoles}
      />
    ))}
  </Switch>
);

export default RotasFerramentas;
