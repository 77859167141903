/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import { usePeco } from 'main/hooks/usePeco';
import { useToast } from 'main/hooks/useToast';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { models, service } from 'powerbi-client';
import { useEffect, useState } from 'react';
import { PECOS_RELATORIO } from 'relatorios/config/endpoints';
import {
  IRelatorioConfig,
  IRelatorioConfigPayload,
} from 'relatorios/features/powerbi/types/IRelatorioConfig';
import { IRelatorioEmbed } from 'relatorios/features/powerbi/types/IRelatorioEmbed';

export const usePowerBI = (relatorioConfig: IRelatorioConfig) => {
  const { toastError } = useToast();
  const [rendered, setRendered] = useState<boolean>(false);
  const [reportSettingsLoaded, setReportSettingsLoaded] =
    useState<boolean>(false);
  const [loadedReport, setLoadedReport] = useState<boolean>(false);

  const [reportConfig, setReportConfig] =
    useState<models.IReportEmbedConfiguration>({
      type: 'report',
      embedUrl: '',
      tokenType: models.TokenType.Embed,
      accessToken: '',
      settings: undefined,
    });

  const { loading, response } = usePeco<
    IRelatorioConfigPayload,
    IRelatorioEmbed
  >({
    api: { operationPath: PECOS_RELATORIO.ObterRelatorioEmbed },
    autoFetch: true,
    payload: {
      groupId: relatorioConfig.GROUP_ID,
      reportId: relatorioConfig.RELATORIO_ID,
    },
  });

  useEffect(() => {
    if (
      checkIfAllItemsAreTrue([
        !!response?.sucessoBFF,
        !!response?.sucessoGI,
        !!response?.entidade,
      ])
    ) {
      setReportConfig(prevState => ({
        ...prevState,
        embedUrl: response?.entidade?.embedUrl,
        accessToken: response?.entidade?.embedToken,
      }));

      setReportSettingsLoaded(true);
    }
  }, [response?.sucessoBFF, response?.sucessoGI, response?.entidade]);

  const eventHandlersMap = new Map([
    [
      'loaded',
      function () {
        setLoadedReport(true);
      },
    ],
    [
      'rendered',
      function () {
        setRendered(true);
      },
    ],
    [
      'error',
      function (event?: service.ICustomEvent<any>) {
        if (event) {
          toastError(event.detail);
          setRendered(true);
        }
      },
    ],
  ]);

  return {
    loadedReport,
    reportConfig,
    eventHandlersMap,
    loading,
    rendered,
    reportSettingsLoaded,
  };
};
