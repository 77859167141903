import React from 'react';
import { Text } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { formatarCpf } from 'main/utils/cpf_cnpj';
import RenderConditional from 'main/components/RenderConditional';
import * as S from 'main/styles/GlobalStyle';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { CoberturaContratada } from '../../types/CoberturasContratadas.types';

type ListaInformacoesPlanoCuidadoExtraProps = {
  dados: CoberturaContratada | undefined;
};
const ListaInformacoesPlanoCuidadoExtra: React.FC<
  ListaInformacoesPlanoCuidadoExtraProps
> = ({ dados }) => {
  return (
    <S.ContainerLista>
      <Text variant="body02-md">
        <b>Dados do certificado</b>
      </Text>
      <S.ContainerBranco>
        <Text variant="body02-sm">CPF :</Text>
        <Text variant="body02-sm">
          {formatarCpf(dados?.cpfPessoaCertificado)}
        </Text>
      </S.ContainerBranco>
      <S.ContainerCinza>
        <Text variant="body02-sm">Produto :</Text>
        <Text variant="body02-sm">{dados?.descricaoProduto}</Text>
      </S.ContainerCinza>
      <S.ContainerBranco>
        <Text variant="body02-sm">Certificado :</Text>
        <Text variant="body02-sm">{dados?.certificado}</Text>
      </S.ContainerBranco>
      <br />
      <Text variant="body02-md">
        <b>Cuidado Extra</b>
      </Text>

      <S.ContainerCinza>
        <Text variant="body02-sm">Tipo :</Text>
        <Text variant="body02-sm">{dados?.nome}</Text>
      </S.ContainerCinza>

      <S.ContainerBranco>
        <Text variant="body02-sm">Valor da Contribuição :</Text>
        <Text variant="body02-sm">
          {formatarValorPadraoBrasileiro(dados?.valor)}
        </Text>
      </S.ContainerBranco>

      <S.ContainerCinza>
        <Text variant="body02-sm">Dia de Vencimento :</Text>
        <Text variant="body02-sm">{dados?.diaVenciamento}</Text>
      </S.ContainerCinza>

      <S.ContainerBranco>
        <Text variant="body02-sm">Indenização :</Text>
        <Text variant="body02-sm">
          {formatarValorPadraoBrasileiro(dados?.valorBeneficio)}
        </Text>
      </S.ContainerBranco>
      <RenderConditional
        condition={
          !!checkIfAllItemsAreTrue([
            !!dados?.prazoRecebimento,
            dados?.prazoRecebimento !== '',
          ])
        }
      >
        <S.ContainerCinza>
          <Text variant="body02-sm">
            Duração do Recebimento do Cuidado Extra :
          </Text>
          <Text variant="body02-sm">{dados?.prazoRecebimento}</Text>
        </S.ContainerCinza>
      </RenderConditional>
    </S.ContainerLista>
  );
};

export default ListaInformacoesPlanoCuidadoExtra;
