import React from 'react';
import { Text, Grid } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import RenderConditional from 'main/components/RenderConditional';
import { formatarData } from 'main/utils';
import { InformacoesProdutoProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';
import { EnumTipoRendaCodigo } from 'previdencia/features/SimulacaoRenda/types/enum';
import * as S from 'previdencia/features/SimulacaoRenda/components/InformacoesProduto/styles';

const InformacoesProduto: React.FC<InformacoesProdutoProps> = ({ dados }) => {
  const exibirPrazoContratado = (): boolean => {
    return (
      dados?.codTipoPagamento === EnumTipoRendaCodigo.Temporaria ||
      dados?.codTipoPagamento === EnumTipoRendaCodigo.PrazoCerto
    );
  };

  const dataAtual: string = formatarData(new Date());
  const dataContratacao: string = formatarData(dados?.dthEntrada);
  const saldo: string = formatarValorPadraoBrasileiro(dados?.vlrReserva);

  return (
    <S.Container>
      <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
        <Text variant="caption-02">Data de Contratação</Text>
        <Text variant="lead" color="text">
          <strong>{dataContratacao}</strong>
        </Text>
      </Grid.Item>

      <RenderConditional condition={exibirPrazoContratado()}>
        <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
          <Text variant="caption-02">Prazo Contratado</Text>
          <Text variant="lead" color="text">
            <strong>{dados?.qtdPrazoAnos} anos</strong>
          </Text>
        </Grid.Item>
      </RenderConditional>

      <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
        <Text variant="caption-02">Saldo em: {dataAtual}</Text>
        <Text variant="lead" color="text">
          <strong>{saldo}</strong>
        </Text>
      </Grid.Item>

      <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
        <Text variant="caption-02">Produto</Text>
        <Text variant="lead" color="text">
          <strong>{dados?.nomProduto}</strong>
        </Text>
      </Grid.Item>

      <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
        <Text variant="caption-02">Modelo Tributário</Text>
        <Text variant="lead" color="text">
          <strong>{dados?.nomRegimeTributario}</strong>
        </Text>
      </Grid.Item>
    </S.Container>
  );
};

export default InformacoesProduto;
