import { obterEndpointAtualizarTelefone } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { RequestAlterarTelefone } from '../types/DadosParticipante';

export const atualizarTelefone = async (
  cpfCnpj: string,
  numeroCertificado: string,
  listaDadosTelefoneParticipante: RequestAlterarTelefone[] | undefined,
): Promise<IApiResponse<undefined> | undefined> => {
  if (listaDadosTelefoneParticipante) {
    const { data } = await api.post<IApiResponse<undefined>>(
      obterEndpointAtualizarTelefone(),
      {
        cpfCnpj,
        numeroCertificado,
        listaDadosTelefoneParticipante,
      },
    );
    const mensagemErro: string | undefined =
      data?.dados?.mensagens?.[0].descricao;

    if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

    return data;
  }

  return undefined;
};
