import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';
import { AuthContext } from 'main/features/Auth/contexts/AuthContext';
import { useToast } from 'main/hooks/useToast';
import masks from 'main/utils/masks';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { STATUS_PROSPECCAO } from 'main/features/prospeccao/constants/statusProspeccao';
import { IAtualizacaoStatusRequest } from 'main/features/prospeccao/types/IAtualizacaoStatusRequest';
import { useContext, useState } from 'react';
import * as ApiStatusProspeccao from '../../../services/api';

export const useAtualizarStatusProspeccao = () => {
  const { userRoles } = useContext(AuthContext);
  const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] =
    useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const { toastError, toastSuccess } = useToast();

  const updateStatus = async (payload: IAtualizacaoStatusRequest) => {
    try {
      setIsLoadingUpdateStatus(true);
      const { dados } = await ApiStatusProspeccao.atualizarStatusProspeccao({
        ...payload,
        cpfCnpjCliente: masks.numberOnly.unmask(payload.cpfCnpjCliente),
      });
      if (!dados) {
        toastError();
        return false;
      }
      const { sucesso, mensagens } = dados;

      if (!sucesso && mensagens && mensagens?.length > 0) {
        const [errorDetails] = mensagens;
        toastError(errorDetails.descricao);
        return false;
      }
      toastSuccess('Pré-Análise atualizada com sucesso!');
      return sucesso;
    } catch (exception: unknown) {
      setError(exception);
    } finally {
      setIsLoadingUpdateStatus(false);
    }
    return false;
  };

  const verificarPermissao = (
    codigoTipoSituacaoVigente: number | undefined,
    perfilPermitidos: Array<(typeof USER_PROFILES)[keyof typeof USER_PROFILES]>,
    statusPermitidos?: Array<
      (typeof STATUS_PROSPECCAO)[keyof typeof STATUS_PROSPECCAO]
    >,
  ): boolean => {
    const codigoTipoSituacao = tryGetValueOrDefault(
      [codigoTipoSituacaoVigente],
      -1,
    );

    if (codigoTipoSituacao === -1) return false;

    const isStatusPermitido =
      statusPermitidos?.includes(codigoTipoSituacao) ?? true;
    const hasRolePermitido = userRoles.some(role =>
      perfilPermitidos.includes(role),
    );
    return isStatusPermitido && hasRolePermitido;
  };

  const devePermitirParecerMedico = (codigoTipoSituacaoVigente?: number) => {
    return verificarPermissao(
      codigoTipoSituacaoVigente,
      [USER_PROFILES.MEDICO, USER_PROFILES.ANALISTA_ENTRADA],
      [STATUS_PROSPECCAO.AGUARDANDO_ANALISE_MEDICA],
    );
  };

  const devePermitirParecerTecnico = (codigoTipoSituacaoVigente: number) => {
    const statusPermitidos = [
      STATUS_PROSPECCAO.AVALIACAO_MEDICA_FINALIZADA_APROVADA,
      STATUS_PROSPECCAO.AVALIACAO_MEDICA_FINALIZADA_REPROVADA,
    ];

    return verificarPermissao(
      codigoTipoSituacaoVigente,
      [USER_PROFILES.ANALISTA_ENTRADA, USER_PROFILES.ANALISTA_PRODUTO],
      statusPermitidos,
    );
  };

  return {
    updateStatus,
    devePermitirParecerMedico,
    devePermitirParecerTecnico,
    isLoadingUpdateStatus,
    error,
  };
};
