import styled from 'styled-components/macro';

import DataTable from 'main/components/Table';

export const ContainerTituloAliquota = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  padding: '20px',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  background: theme.color.brandPrimary.gradient,

  p: {
    marginLeft: '10px',
  },

  'button[role=radio]': {
    borderColor: 'white',

    '& span': {
      backgroundColor: 'white',
    },
  },
}));

export const Table = styled(DataTable)`
  .rdt_TableCol {
    text-align: center;
  }
`;
