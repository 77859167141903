import { Option } from 'previdencia/types/Forms';

export const filtroPeriodos: Option[] = [
  { label: 'Último mês', value: '30' },
  { label: 'Últimos 3 meses', value: '90' },
  { label: 'Últimos 6 meses', value: '180' },
  { label: 'Últimos 9 meses', value: '270' },
  { label: 'Últimos 12 meses', value: '365' },
  { label: 'Período Personalizado', value: '-1' },
];
