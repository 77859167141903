import { tryGetValueOrDefault } from 'main/utils/conditional';
import { FORMATA_CASAS_DECIMAIS } from './regex';

export const formatarValorPadraoBrasileiro = (
  value?: string | number,
): string => {
  if (value) {
    if (typeof value === 'string') {
      const numberWithoutFormat = Number(value.replace(/[^0-9.-]+/g, ''));
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(numberWithoutFormat);
    }

    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }
  return '';
};

export const formatarValorPadraoDigitoBrasileiro = (
  value?: string | number,
  digit = 2,
  removeFormatCurrent = false,
): string => {
  if (value) {
    if (typeof value === 'string') {
      const numberWithoutFormat = Number(value.replace(/[^0-9.-]+/g, ''));
      const resultInfo = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: digit,
      }).format(numberWithoutFormat);
      return removeFormatCurrent
        ? `${resultInfo}`.replace(/[R]\$/g, '')
        : resultInfo;
    }

    const resultData = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: digit,
    }).format(value);

    return removeFormatCurrent
      ? `${resultData}`.replace(/[R]\$/g, '')
      : resultData;
  }
  return '-';
};

/**
 * Valida e formata um valor separando casas decimais por ponto.
 *
 * @template T
 * @param {T} value - O valor a ser validado e formatado.
 * @param {string} [defaultValue=''] - O valor a ser usado caso o value seja null, undefined, ou uma string vazia.
 * @returns {string} O valor formatado separando casas decimais.
 * @example: input: 6529 ->  output: 6.529
 */

export const formatDecimalValue = (
  value: string | number | null | undefined,
) => {
  if (value == null) {
    return '';
  }

  const stringValue = typeof value === 'number' ? value.toString() : value;
  const parts = stringValue.split('.');

  if (parts.length === 1) {
    parts[0] = parts[0].replace(FORMATA_CASAS_DECIMAIS, '.');
  } else if (parts.length === 2) {
    parts[0] = parts[0].replace(FORMATA_CASAS_DECIMAIS, '.');
  } else {
    return '';
  }

  return parts.join('.');
};

export const removerMascaraValorMonetario = (value: string | number | null) => {
  const valueParsed = tryGetValueOrDefault([value?.toString()], '');
  return parseFloat(
    valueParsed
      .toString()
      .replace('R$', '')
      .replace(/\./g, '')
      .replace(',', '.'),
  );
};

export const removerMascaraValorMonetarioOrdenaTable = (value: string) => {
  const numericValue = parseFloat(
    value
      .replace('R$', '') // Remove a string 'R$'
      .replace(/\./g, '') // Remove todos os pontos
      .replace(',', '.'), // Substitui a vírgula por ponto para permitir o parseFloat
  );

  return Number.isNaN(numericValue) ? 0 : numericValue;
};

/**
 * Valida um valor monetário e o retorna no formato padrão brasileiro.
 *
 * @template T
 * @param {T} value - O valor monetário a ser validado e formatado.
 * @param {string|number} [defaultValue='0' || 0] - O valor a ser usado caso o value seja null, undefined, ou uma string vazia.
 * @returns {string} O valor monetário formatado no padrão brasileiro.
 * @author Ramon Gomes
 */
export function tryGetMonetaryValueOrDefault<T>(
  value: T,
  defaultValue: string | number = '0' || 0,
): string {
  return formatarValorPadraoBrasileiro(
    tryGetValueOrDefault([`${value}`], defaultValue),
  );
}

/**
 * Converte o valor para número, limita a 100, e retorna um valor inteiro.
 * @param {string | number} input - O valor percentual a ser formatado, como número ou string.
 * @returns {string} - Valor formatado como string, limitado a 100 e sem casas decimais.
 * @example formatarPorcentagem("10000") // Retorna "100"
 * @example formatarPorcentagem("100,00") // Retorna "100"
 * @example formatarPorcentagem(85) // Retorna "85"
 * @example formatarPorcentagem("99,99") // Retorna "99"
 * @example formatarPorcentagem(101) // Retorna "100"
 * @example formatarPorcentagem("120") // Retorna "100"
 * @example formatarPorcentagem("45") // Retorna "45"
 */
export const formatarPorcentagem = (input: string | number) => {
  let value =
    parseFloat(
      String(input)
        .replace(',', '.')
        .replace(/[^0-9.]/g, ''),
    ) || 0;
  value = Math.min(Math.floor(value), 100); // Limita a 100 e remove decimais
  return String(value); // Retorna como string
};
