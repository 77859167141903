import styled from 'styled-components/macro';
import { QuickAccess as QuickAccessDS } from '@cvp/design-system/react';

export const QuickAccess = styled(QuickAccessDS)(({ ...rest }) => ({
  ...rest,
}));

QuickAccess.Item = styled(QuickAccessDS.Item)(({ ...rest }) => ({
  ...rest,
}));

export default QuickAccess;
