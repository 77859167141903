import styled from 'styled-components/macro';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

export const Linked = styled(Link)(
  ({ theme: { breakpoint, color, font } }) => ({
    '&&': {
      cursor: 'pointer',
      color: color.brandPrimary.dark,
      fontSize: font.size.md,
      [breakpoint.lg()]: {
        maxWidth: '150px',
      },
    },
  }),
);

export const LinkedTable = styled(Link)(({ theme: { color, font } }) => ({
  '&&': {
    cursor: 'pointer',
    color: color.brandPrimary.dark,
    fontSize: font.size.md,
  },
}));

export const Table = styled(DataTable)(({ theme: { color, font } }) => ({
  '.rdt_TableCol, .rdt_TableCell': {
    fontSize: font.size.sm,
    fontFamily: font.family.base,
  },
  '.rdt_TableCol': {
    fontWeight: font.weight.lg,
    textAlign: 'center',
    '&:hover': {
      color: color.brandPrimary.light,
    },
  },
}));
