import React, { useEffect, useState } from 'react';
import { Button, Display, Radio, Text } from '@cvp/design-system/react';
import { MensagemContatosInvalidos } from 'main/components/AssinaturaDocuSign/MensagemContatosInvalidos';
import * as S from 'main/components/AssinaturaDocuSign/styles';
import RenderConditional from 'main/components/RenderConditional';
import { useAuth } from 'main/features/Auth/hooks';
import { TipoAssinatura } from 'main/types/TipoAssinaturaDocuSign';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import { aplicarMascaraTelefone } from 'main/utils/phone';
import { NAO_TEM_CADASTRADO } from 'main/components/AssinaturaDocuSign/constants/contatosInvalidos';
import {
  MENSAGEM_CORPO_MODAL,
  MENSAGEM_TITULO_MODAL,
} from 'main/components/AssinaturaDocuSign/constants/mensagens';

export type DadosClienteModalTipoDocuSingProps = {
  email?: string;
  mobileNumber?: string;
  cpfCnpj: string;
  numeroCertificado?: string;
  tipoProduto?: string;
  nome?: string;
};

type ModalTipoDocuSingProps = {
  open: boolean;
  executarAssinatura(): void;
  handleClose(close: boolean): void;
  dadosCliente: DadosClienteModalTipoDocuSingProps;
  mensagemAlerta?: string;
  tituloModal: string;
  metodoAssinaturaEmail?: boolean;
  loading?: boolean;
  showCloseButton?: boolean;
};

enum MetodoAssinaturaDocuSign {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export const ModalTipoDocuSing: React.FunctionComponent<
  ModalTipoDocuSingProps
> = ({
  executarAssinatura,
  handleClose,
  open,
  showCloseButton,
  dadosCliente = {
    email: '',
    mobileNumber: '',
  },
  mensagemAlerta = 'O processo de assinatura utilizará os contatos abaixo:',
  tituloModal,
  metodoAssinaturaEmail = false,
  loading = false,
}) => {
  const [selectedMethodDocuSing, setSelectedMethodDocuSing] =
    useState<TipoAssinatura | null>({ tipo: MetodoAssinaturaDocuSign.SMS });
  const [disableButtonDocuSing, setDisableButtonDocuSing] =
    useState<boolean>(false);
  const { sessionId } = useAuth();

  const closeModalDocuSing = () => {
    setSelectedMethodDocuSing({ tipo: MetodoAssinaturaDocuSign.SMS });
    handleClose(false);
  };

  const checkMobileNumber = Boolean(
    dadosCliente?.mobileNumber?.replace(/\D/gi, ''),
  );

  const checkEmail = Boolean(dadosCliente?.email);

  const checkDisableButtonDocuSing = () => {
    return checkIfSomeItemsAreTrue([!checkEmail, !checkMobileNumber]);
  };

  useEffect(() => {
    setDisableButtonDocuSing(checkDisableButtonDocuSing());
  }, [selectedMethodDocuSing, dadosCliente]);

  return (
    <S.MoldaConfirmarContatos
      show={open}
      onClose={closeModalDocuSing}
      style={{ minWidth: '800' }}
      showCloseButton={showCloseButton}
    >
      <RenderConditional
        condition={!!tituloModal}
        component={
          <S.Text margin fWeight="bold">
            {tituloModal}
          </S.Text>
        }
      />
      <RenderConditional
        condition={checkIfAllItemsAreTrue([!!mensagemAlerta, !loading])}
      >
        <S.Text variant="body-medium3" color="primary" fWeight="bold">
          {MENSAGEM_TITULO_MODAL}
        </S.Text>
        <S.Text variant="body-medium3" color="primary">
          {MENSAGEM_CORPO_MODAL}
        </S.Text>
        <S.Text variant="body-medium3" mt="20px">
          {mensagemAlerta}
        </S.Text>
      </RenderConditional>

      <RenderConditional condition={loading}>
        <Button variant="text" loading />
      </RenderConditional>

      <RenderConditional condition={!loading}>
        <Display justify="center">
          <RenderConditional condition={metodoAssinaturaEmail}>
            <div>
              <Display type="inline-block">
                <Radio
                  data-testid="btnEmail"
                  onChange={() =>
                    setSelectedMethodDocuSing({
                      tipo: MetodoAssinaturaDocuSign.EMAIL,
                    })
                  }
                  value="EMAIL"
                  name="selectedMethod"
                />
                E-mail
              </Display>
            </div>
          </RenderConditional>
          <RenderConditional condition={metodoAssinaturaEmail}>
            <div>
              <Display type="inline-block">
                <Radio
                  data-testid="btnSms"
                  onChange={() =>
                    setSelectedMethodDocuSing({
                      tipo: MetodoAssinaturaDocuSign.SMS,
                    })
                  }
                  value="SMS"
                  name="selectedMethod"
                  checked
                />
                SMS
              </Display>
            </div>
          </RenderConditional>
        </Display>
      </RenderConditional>

      <S.ContainerContatos>
        <RenderConditional condition={!loading}>
          <Display justify="center">
            <div>
              <RenderConditional condition={checkEmail}>
                <span>
                  <strong> E-mail:</strong>
                </span>
                <Text variant="body-medium3">
                  {dadosCliente?.email?.toLowerCase()}
                </Text>
              </RenderConditional>
              <RenderConditional condition={!checkEmail}>
                <span>
                  <S.LabelTipoDocusignError> E-mail:</S.LabelTipoDocusignError>
                </span>
                <Text variant="body-medium3" color="error">
                  {NAO_TEM_CADASTRADO}
                </Text>
              </RenderConditional>
            </div>
          </Display>
        </RenderConditional>
        <RenderConditional
          condition={checkIfAllItemsAreTrue([
            selectedMethodDocuSing?.tipo === MetodoAssinaturaDocuSign.SMS,
            !loading,
          ])}
        >
          <RenderConditional condition={checkMobileNumber}>
            <Display justify="center">
              <div>
                <span>
                  <strong> Telefone:</strong>
                </span>
                <Text variant="body-medium3">
                  {aplicarMascaraTelefone(dadosCliente?.mobileNumber)}
                </Text>
              </div>
            </Display>
          </RenderConditional>
          <RenderConditional condition={!checkMobileNumber}>
            <Display justify="center">
              <div>
                <span>
                  <S.LabelTipoDocusignError>Telefone:</S.LabelTipoDocusignError>
                </span>
                <Text variant="body-medium3" color="error">
                  {NAO_TEM_CADASTRADO}
                </Text>
              </div>
            </Display>
          </RenderConditional>
        </RenderConditional>
      </S.ContainerContatos>
      <RenderConditional condition={disableButtonDocuSing}>
        <MensagemContatosInvalidos />
      </RenderConditional>
      <RenderConditional condition={!!selectedMethodDocuSing?.tipo}>
        <RenderConditional condition={!loading}>
          <S.ContainerButton justify="center">
            <Button
              variant="primary"
              onClick={executarAssinatura}
              data-testid="submit"
              disabled={disableButtonDocuSing}
              loading={loading}
            >
              Iniciar Assinatura
            </Button>
          </S.ContainerButton>
        </RenderConditional>
      </RenderConditional>
      <S.Text variant="caption-02">
        <span>Sessão:</span> {sessionId}
      </S.Text>
    </S.MoldaConfirmarContatos>
  );
};

export default ModalTipoDocuSing;
