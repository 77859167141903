import { useEffect, useState } from 'react';
import { FormikProps, useFormik } from 'formik';

import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { verificarContaValida } from 'previdencia/features/AlteracaoFormaDadosPagamento/utils/alteracaoFormaDadosPagamento';
import { usePecoValidarConta } from 'previdencia/hooks/usePecoValidarConta';
import * as FACTORY from 'previdencia/features/AlteracaoFormaDadosPagamento/factories/AlteracaoFormaDadosPagamentoFactory';
import * as CONSTS from 'previdencia/features/AlteracaoFormaDadosPagamento/constants/constants';
import * as ALTERACAO_CONTA_TYPES from 'previdencia/features/AlteracaoFormaDadosPagamento/types/AlteracaoFormaDadosPagamento';

const useAlteracaoContaDebito = (
  params: Partial<ALTERACAO_CONTA_TYPES.AlteracaoContaDebitoProps>,
) => {
  const formik: FormikProps<ALTERACAO_CONTA_TYPES.IFormikValuesAlteracaoContaDebito> =
    useFormik<ALTERACAO_CONTA_TYPES.IFormikValuesAlteracaoContaDebito>({
      enableReinitialize: true,
      initialValues: CONSTS.INITIAL_FORMIK_STATE,
      validationSchema: FACTORY.FORMIK_VALIDATION,
      validateOnMount: true,
      onSubmit: () => undefined,
    });

  const [exibirFormConta, setExibirFormConta] = useState(false);
  const [validarDados, setValidarDados] = useState(false);
  const [chamadaAlterarDadosNovaConta, setChamadaAlterarDadosNovaConta] =
    useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [canal, setCanal] = useState<ALTERACAO_CONTA_TYPES.CanalDados>({
    canal: '',
    isDisabled: false,
  });
  const [backupcanal, setBackupCanal] = useState('');
  const [dadosBancoSelecionado, setDadosBancoSelecionado] =
    useState<ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias>();

  const { dadosValidacaoConta, loadingDadosValidacaoConta, validarConta } =
    usePecoValidarConta({
      codigoAgencia: formik.values.agencia,
      codigoOperacao: formik.values.operacao,
      digitoVerificador: formik.values.digitoBanco,
      numeroBanco: CONSTS.NUMERO_BANCO_CAIXA,
      numeroConta: formik.values.conta,
    });

  const toggleFormNovaConta = (): void => {
    setExibirFormConta(prevState => !prevState);
    formik.resetForm();
  };

  const alterarFormaDadosPagamento = (): void => {
    setChamadaAlterarDadosNovaConta(prevState => !prevState);

    if (canal.isDisabled) {
      return setValidarDados(true);
    }

    return params?.alterar?.(
      canal.canal,
      FACTORY.converterDadosBasicoContaParaDadosPagamento(
        formik,
        dadosBancoSelecionado,
      ),
    );
  };

  const desabilitarAlteracaoFormaDadosPagamento = checkIfAllItemsAreTrue([
    !canal.canal,
    !canal.isDisabled,
  ]);

  const obterContaCadastradaSelecionada = (
    row: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias,
  ): void => {
    setCanal({ canal: row.idCanalRecuperado, isDisabled: false });
    setDadosBancoSelecionado(row);
  };

  const saveCanal = (): void => {
    if (checkIfAllItemsAreTrue([exibirFormConta, !canal.isDisabled])) {
      setBackupCanal(canal.canal);
      setCanal({
        canal: '',
        isDisabled: true,
      });
    }
  };

  const revertCanal = (): void => {
    if (checkIfAllItemsAreTrue([!exibirFormConta, canal.isDisabled])) {
      setCanal({
        canal: backupcanal,
        isDisabled: false,
      });
    }
  };

  const validarErroNovaConta = (): void => {
    if (verificarContaValida(dadosValidacaoConta?.codigoRetorno)) {
      setValidarDados(false);
      setOpenModal(true);
    }
  };

  const enviarDadosNovaConta = (): void => {
    if (
      checkIfAllItemsAreTrue([
        !!dadosValidacaoConta,
        !verificarContaValida(dadosValidacaoConta?.codigoRetorno),
      ])
    ) {
      params?.alterar?.(
        canal.canal,
        FACTORY.converterDadosBasicoContaParaDadosPagamento(
          formik,
          dadosBancoSelecionado,
        ),
      );
    }
  };

  useEffect(() => {
    if (loadingDadosValidacaoConta) {
      return;
    }

    validarErroNovaConta();

    enviarDadosNovaConta();
  }, [
    dadosValidacaoConta,
    loadingDadosValidacaoConta,
    formik.values,
    canal.canal,
  ]);

  useEffect(() => {
    saveCanal();

    revertCanal();
  }, [canal.canal, canal.isDisabled, backupcanal, exibirFormConta]);

  useEffect(() => {
    if (validarDados) {
      validarConta();
      setValidarDados(true);
    }
  }, [validarDados, chamadaAlterarDadosNovaConta]);

  return {
    formik,
    canal,
    obterContaCadastradaSelecionada,
    exibirFormConta,
    toggleFormNovaConta,
    alterarFormaDadosPagamento,
    loadingDadosValidacaoConta,
    desabilitarAlteracaoFormaDadosPagamento,
    openModal,
    setOpenModal,
  };
};

export default useAlteracaoContaDebito;
