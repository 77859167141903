import { Grid, Disclaimer } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const DisclaimerMsgErroResgate = ({
  mensagem,
}: SOLICITACAO_RESGATE_TYPES.IDisclaimerMsgErroResgateProps) => {
  return (
    <RenderConditional condition={!!mensagem}>
      <Grid.Item xs={1}>
        <Disclaimer variant="error">
          <Disclaimer.Content text={mensagem} />
        </Disclaimer>
      </Grid.Item>
    </RenderConditional>
  );
};

export default DisclaimerMsgErroResgate;
