import { tryGetValueOrDefault } from 'main/utils/conditional';
import {
  AndamentoSinistroPrevidencia,
  IStatusSinistroPrevidenciaDados,
} from 'sinistro/features/statusSinistro/types/IStatusSinistro';
import { normalizarFormatoSeparadorData } from 'sinistro/features/statusSinistro/utils/normalizarFormatoSeparadorData';
import { ultimoItemArray } from 'sinistro/features/statusSinistro/utils/ultimoItemArray';

function converteDataBrasileiraParaData(dateString: string): Date {
  const [day, month, year] = dateString.split('/').map(Number);
  return new Date(year, month - 1, day);
}

export const ordenarAndamentosPorData = (
  lista: AndamentoSinistroPrevidencia[] | undefined,
): AndamentoSinistroPrevidencia[] => {
  if (!lista) {
    return [];
  }
  const novaLista = lista.map(item => {
    return {
      ...item,
      data: normalizarFormatoSeparadorData(item.data),
    };
  });

  return novaLista.sort(
    (a: AndamentoSinistroPrevidencia, b: AndamentoSinistroPrevidencia) => {
      const dateA = +converteDataBrasileiraParaData(a.data);
      const dateB = +converteDataBrasileiraParaData(b.data);

      return dateA - dateB;
    },
  );
};

export const formatarStatusSinistroPrevidenciaDados = (
  dados?: IStatusSinistroPrevidenciaDados,
): IStatusSinistroPrevidenciaDados => {
  return {
    ...dados,
    andamentosCertificados: dados?.andamentosCertificados.map(certificado => {
      const andamentosOrdenados = ordenarAndamentosPorData(
        certificado.andamentos,
      );

      const utimoStatus = tryGetValueOrDefault(
        [ultimoItemArray(andamentosOrdenados)?.status],
        '',
      );

      return {
        ...certificado,
        andamentos: andamentosOrdenados,
        status: utimoStatus,
      };
    }),
  } as IStatusSinistroPrevidenciaDados;
};
