import { checkIfAllItemsAreTrue, tryGetValueOrDefault } from './conditional';

export const CODIGO_BANCO_CAIXA = '104';

type FormatarContaCaixaProps = {
  codigoBanco: string;
  operacao: string;
  numeroConta: string;
  agencia: string;
};
/**
 * Formata número da conta ex: operacao 3700 e conta: 900999997
 * @param operacao
 * @param numeroConta
 * @returns ex: 3700000900999997
 */
export const formatarContaNSGD = ({
  numeroConta,
  operacao,
}: FormatarContaCaixaProps) => {
  let numeroContaFormatado = numeroConta;
  if (numeroConta.length < 12) {
    numeroContaFormatado = numeroConta.padStart(12, '0');

    return `${operacao}${numeroContaFormatado}`;
  }
  return operacao + numeroConta;
};

/**
 * Formata número da conta ex: operacao 001 e conta: 180960
 * @param operacao
 * @param numeroConta
 * @returns ex: 00100180960
 */
export const formatarContaSIDEC = ({
  numeroConta,
  operacao,
}: FormatarContaCaixaProps) => {
  let numeroContaFormatado = numeroConta;
  if (numeroConta.length < 8) {
    numeroContaFormatado = numeroConta.padStart(8, '0');

    return `${operacao}${numeroContaFormatado}`;
  }
  return operacao + numeroConta;
};

/**
 * Se o banco for Caixa retorna uma conta formatada do tipo SIDEC ou NSGD, se não o numero da conta passado
 * @param operacao
 * @param agencia
 * @param numeroConta
 * @returns Ex: 00100180960 ou 3700000900999997
 */
export const formatarContaSeBancoCaixa = ({
  numeroConta,
  operacao,
  agencia,
  codigoBanco,
}: FormatarContaCaixaProps) => {
  if (codigoBanco !== CODIGO_BANCO_CAIXA) return numeroConta;
  const validateParams = checkIfAllItemsAreTrue([
    operacao?.length > 0,
    agencia?.length > 0,
    numeroConta?.length > 0,
  ]);
  if (!validateParams) return '';
  if (operacao.length === 4)
    return formatarContaNSGD({
      operacao,
      numeroConta,
      agencia: '',
      codigoBanco: '',
    });

  return formatarContaSIDEC({
    operacao,
    numeroConta,
    agencia: '',
    codigoBanco: '',
  });
};

/**
 * Completa uma string à esquerda com um valor específico até atingir um comprimento total.
 * @param {string | undefined | null} value - A string original que será completada à esquerda.
 * @param {number} totalLength - O comprimento total desejado da string resultante.
 * @param {string} [fillValue='0'] - O valor que será usado para preencher a string à esquerda. Valor padrão é '0'.
 * @param {string} [defaultValue=''] - O valor padrão a ser retornado se o `value` for `undefined` ou `null`. Valor padrão é uma string vazia ('').
 * @returns {string} - A string completada à esquerda com o valor especificado ou o valor padrão.
 */
export const completarDadosConta = (
  value: string | undefined | null,
  totalLength: number,
  fillValue = '0',
  defaultValue = '',
): string => {
  const valorString = tryGetValueOrDefault([value], defaultValue) as string;
  return valorString.padStart(totalLength, fillValue);
};
