import { AppContext } from 'main/contexts/AppContext';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { useContext, useEffect } from 'react';
import { useCertificadoSeguroResumo } from 'seguros/hooks/useCertificadoSeguroResumo';
import * as PECO_TYPES from 'seguros/types/ApoliseCoberturaUsePeco';
import { IAviso } from 'seguros/types/IResponseCertificadosResumo';

export const useApoliceDadosAvisosVida =
  (): PECO_TYPES.IUsePecoTabelaApolice<IAviso> => {
    const { clienteVida } = useContext(AppContext);
    const {
      certificadoSeguros: { fetchData, response, loading },
    } = useCertificadoSeguroResumo({
      numeroContrato: tryGetValueOrDefault([clienteVida?.numCertificado], '0'),
      codigoProduto: tryGetValueOrDefault([clienteVida?.codigoProduto], '0'),
    });
    useEffect(() => {
      fetchData();
    }, []);
    const entidade = response?.entidade;

    const dados = tryGetValueOrDefault([entidade?.individual?.avisos], []);

    return {
      dados,
      loading,
    };
  };
