import { Disclaimer as DisclaimerDS } from '@cvp/design-system/react';
import styled from 'styled-components';

export const DisclaimerSmall = styled(DisclaimerDS)({
  padding: '12px 0 12px 16px',
  button: {
    opacity: 0,
  },
  svg: {
    width: '22px',
    height: '22px',
  },
});

export const ContainerExpandedInfo = styled.div(({ theme: { color } }) => ({
  borderBottom: `1px solid ${color.neutral['03']}`,
  background: `${color.neutral['02']}14`,
  padding: 20,
  marginBottom: 20,

  '& p:first-child': {
    color: `${color.neutral['02']}`,
  },
}));

export const ContainerExpandedInfoImpressao = styled.div(
  ({ theme: { color } }) => ({
    borderBottom: `1px solid ${color.neutral['03']}`,
    background: `${color.neutral['02']}14`,
    padding: 10,
    marginBottom: 0,

    '& p:first-child': {
      color: `${color.neutral['02']}`,
    },
  }),
);

export const ContainerImportantInfo = styled.div(({ theme: { color } }) => ({
  border: `1px solid ${color.brandSecondary.light}`,
  background: `${color.brandSecondary.light}0d`,
  padding: 20,
  borderRadius: 8,
  marginTop: 20,
  marginBottom: 20,

  '& p:first-child': {
    color: color.brandSecondary.dark,
  },

  '& h6': {
    fontSize: 12,
  },
}));

export const ContainerImportantInfoImpressao = styled.div(
  ({ theme: { color } }) => ({
    border: `1px solid ${color.brandSecondary.light}`,
    background: `${color.brandSecondary.light}0d`,
    padding: 20,
    borderRadius: 8,
    marginTop: 15,
    marginBottom: 15,

    '& p:first-child': {
      color: color.brandSecondary.dark,
    },

    '& h6': {
      fontSize: 10,
    },
  }),
);
