import React from 'react';
import { Text } from '@cvp/design-system/react';
import * as CONSTS from 'seguros/constants/AlteracaoValorCapital';

const AlterarValorCapitalHeader = () => {
  return (
    <>
      <Text variant="headline-05" color="primary" margin>
        {CONSTS.TEXTOS_ALTERACAO_VALOR_CAPITAL.TITULO}
      </Text>

      <Text variant="body02-sm" margin>
        {CONSTS.TEXTOS_ALTERACAO_VALOR_CAPITAL.CABECALHO.PARAGRAFO_01}
      </Text>
      <Text variant="body02-sm" margin>
        {CONSTS.TEXTOS_ALTERACAO_VALOR_CAPITAL.CABECALHO.PARAGRAFO_02}
      </Text>
      <Text variant="body02-sm" margin>
        {CONSTS.TEXTOS_ALTERACAO_VALOR_CAPITAL.CABECALHO.PARAGRAFO_03}
      </Text>
    </>
  );
};

export default AlterarValorCapitalHeader;
