import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute, {
  IPrivateRoute,
} from 'main/components/Route/PrivateRoute';
import PropostasPendentes from 'contratosPrestamista/features/proposta/pages/PropostasPendentes';
import DetalheProposta from 'contratosPrestamista/features/proposta/pages/DetalheProposta';
import DetalhesCancelamento from 'contratosPrestamista/features/cancelamento/pages/DetalhesCancelamento';
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import ListagemSegurosCancelados from '../features/cancelamento/pages/ListagemSegurosCancelados';
import ConsultaUpload from '../features/uploadproposta/pages/ConsultaUpload';
import ConsultaStatusProposta from '../features/uploadproposta/pages/ConsultaStatusProposta';
import ListagemStatusProposta from '../features/uploadproposta/pages/ListagemStatusProposta';
import Identificacao from 'contratosPrestamista/features/dpseletronica/pages/Identificacao';
import ContratosPrestamistaProvider from 'contratosPrestamista/contexts/ContratosPrestamistaContext';
import FluxoPF from 'contratosPrestamista/features/dpseletronica/pages/FluxoPF';
import FluxoPJ from 'contratosPrestamista/features/dpseletronica/pages/FluxoPJ';
import ListaStatusPropostaDps from 'contratosPrestamista/features/dpsEletronicaPostecipado/pages/ListaStatusPropostaDps';
import DetalhesStatusPropostaDps from 'contratosPrestamista/features/dpsEletronicaPostecipado/pages/DetalhesStatusPropostaDps';
import ConsultarStatusPropostaDps from 'contratosPrestamista/features/dpsEletronicaPostecipado/pages/ConsultarStatusPropostaDps';
import ConsultarDps from 'contratosPrestamista/features/reenvioDpsEletronica/pages/ConsultarDps';

const routes: IPrivateRoute[] = [
  {
    path: '/prestamista/propostas/pendentes',
    exact: true,
    component: PropostasPendentes,
    key: 'lista-propostas',
    authenticated: true,
    breadcrumb: 'Propostas',
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.OPERADOR],
  },
  {
    path: '/prestamista/propostas/pendentes/:id/detalhes',
    exact: true,
    component: DetalheProposta,
    key: 'detalhe-proposta',
    authenticated: true,
    breadcrumb: 'Detalhes',
    requiredRoles: PRINCIPAL_USERS,
  },
  {
    path: '/prestamista/cancelamentos',
    exact: true,
    component: ListagemSegurosCancelados,
    key: 'lista-cancelamentos',
    authenticated: true,
    breadcrumb: 'Seguros Cancelados',
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.OPERADOR,
      USER_PROFILES.ANALISTA_SAIDA,
    ],
  },
  {
    path: '/prestamista/cancelamentos/:id/detalhes',
    exact: true,
    component: DetalhesCancelamento,
    key: 'detalhe-cancelamento',
    authenticated: true,
    breadcrumb: 'Detalhes',
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
  },
  {
    path: '/prestamista/dps/identificacao',
    exact: true,
    component: Identificacao,
    key: 'identificacao-dps',
    authenticated: true,
    breadcrumb: 'Identificação Proposta',
    requiredRoles: [
      USER_PROFILES.ADMINISTRADOR,
      USER_PROFILES.ECONOMIARIO,
      USER_PROFILES.OPERADOR,
      USER_PROFILES.ANALISTA_CVP,
      USER_PROFILES.ANALISTA_TI,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_POS_VENDA,
    ],
  },
  {
    path: '/prestamista/dps/pf/upload',
    exact: true,
    component: FluxoPF,
    key: 'pf-upload-proposta-dps',
    authenticated: true,
    breadcrumb: 'Upload Proposta/DPS',
    requiredRoles: [
      USER_PROFILES.ADMINISTRADOR,
      USER_PROFILES.ECONOMIARIO,
      USER_PROFILES.OPERADOR,
      USER_PROFILES.ANALISTA_CVP,
      USER_PROFILES.ANALISTA_TI,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_POS_VENDA,
    ],
  },
  {
    path: '/prestamista/dps/pj/upload',
    exact: true,
    component: FluxoPJ,
    key: 'pj-upload-proposta-dps',
    authenticated: true,
    breadcrumb: 'Upload Proposta/DPS',
    requiredRoles: [
      USER_PROFILES.ADMINISTRADOR,
      USER_PROFILES.ECONOMIARIO,
      USER_PROFILES.OPERADOR,
      USER_PROFILES.ANALISTA_CVP,
      USER_PROFILES.ANALISTA_TI,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_POS_VENDA,
    ],
  },
  {
    path: '/prestamista/dps/status/consulta',
    exact: true,
    component: ConsultarStatusPropostaDps,
    key: 'consulta-status-dps',
    authenticated: true,
    breadcrumb: 'Consultar Propostas',
    requiredRoles: [
      USER_PROFILES.ADMINISTRADOR,
      USER_PROFILES.ECONOMIARIO,
      USER_PROFILES.ANALISTA_CVP,
      USER_PROFILES.ANALISTA_TI,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.OPERADOR,
      USER_PROFILES.ANALISTA_POS_VENDA,
    ],
  },
  {
    path: '/prestamista/dps/status/reenvio-dps',
    exact: true,
    component: ConsultarDps,
    key: 'reenvio-dps',
    authenticated: true,
    breadcrumb: 'Reenvio Link DPS Eletrônica',
    requiredRoles: [USER_PROFILES.ANALISTA_TI],
  },
  {
    path: '/prestamista/dps/status/consulta/resultado',
    exact: true,
    component: ListaStatusPropostaDps,
    key: 'resultado-consulta-status-dps',
    authenticated: true,
    breadcrumb: 'Propostas',
    requiredRoles: [
      USER_PROFILES.ADMINISTRADOR,
      USER_PROFILES.ECONOMIARIO,
      USER_PROFILES.ANALISTA_CVP,
      USER_PROFILES.ANALISTA_TI,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.OPERADOR,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_POS_VENDA,
    ],
  },
  {
    path: '/prestamista/dps/status/consulta/resultado/detalhes',
    exact: true,
    component: DetalhesStatusPropostaDps,
    key: 'detalhes-consulta-status-dps',
    authenticated: true,
    breadcrumb: 'Detalhes',
    requiredRoles: [
      USER_PROFILES.ADMINISTRADOR,
      USER_PROFILES.ECONOMIARIO,
      USER_PROFILES.ANALISTA_CVP,
      USER_PROFILES.ANALISTA_TI,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.OPERADOR,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_POS_VENDA,
    ],
  },
  {
    path: '/prestamista/proposta/upload/consulta',
    exact: true,
    component: ConsultaUpload,
    key: 'upload-status',
    authenticated: true,
    breadcrumb: 'Status Upload',
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.OPERADOR,
      USER_PROFILES.ANALISTA_ENTRADA,
      USER_PROFILES.ANALISTA_CONSULTA,
    ],
  },
  {
    path: '/prestamista/proposta/status/consulta',
    exact: true,
    component: ConsultaStatusProposta,
    key: 'consulta-status-proposta',
    authenticated: true,
    breadcrumb: 'Consulta Status Proposta',
    requiredRoles: [USER_PROFILES.ANALISTA_TI, USER_PROFILES.ANALISTA_CONSULTA],
  },
  {
    path: '/prestamista/proposta/status/consulta/resultado',
    exact: true,
    component: ListagemStatusProposta,
    key: 'consulta-resultado-status-proposta',
    authenticated: true,
    breadcrumb: 'Status de Propostas',
    requiredRoles: [USER_PROFILES.ANALISTA_TI],
  },
];

export const prestamistaBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
  '/prestamista': null,
  '/prestamista/propostas': null,
  '/prestamista/propostas/:id': null,
  '/prestamista/propostas/pendentes/:id': null,
  '/prestamista/cancelamentos/:id': null,
  '/prestamista/proposta': null,
  '/prestamista/proposta/status': null,
  '/prestamista/dps': null,
  '/prestamista/dps/pf': null,
  '/prestamista/dps/pj': null,
  '/prestamista/proposta/upload': null,
  '/prestamista/dps/status': null,
  '/prestamista/dps/proposta': null,
};

const RotasPrestamista = (): React.ReactElement => (
  <Switch>
    <ContratosPrestamistaProvider>
      {routes.map(route => (
        <PrivateRoute
          key={route.key}
          path={route.path}
          exact={route.exact}
          component={route.component}
          authenticated={route.authenticated}
          requiredRoles={route.requiredRoles}
        />
      ))}
    </ContratosPrestamistaProvider>
  </Switch>
);

export default RotasPrestamista;
