/* eslint-disable react/jsx-no-constructed-context-values */

import React, {
  createContext,
  useLayoutEffect,
  useContext,
  PropsWithChildren,
} from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { AuthContext } from 'main/features/Auth/contexts/AuthContext';
import { getPermissionsInSession } from 'main/features/Auth/utils/auth';
import { obterMatrizPeloCertificado } from 'previdencia/services/matrizAcesso.api';
import { useToast } from 'main/hooks/useToast';
import { removeSessionItem } from 'main/utils/storage';
import { obterChaveArmazenamentoDadosFuncionalidades } from 'config/storage_keys';
import { useFeatureData } from 'main/hooks/useFeatureData';
import { IModuleContext } from 'main/types/IModuleContext';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';

export function createPrevidenciaContext<T = unknown>() {
  return createContext<IModuleContext<T> | null>(null);
}

const PrevidenciaContext = createPrevidenciaContext<unknown>();

PrevidenciaContext.displayName = 'PrevidenciaContextFeatureData';

export function usePrevidenciaContext<T>() {
  const context = useContext(PrevidenciaContext);
  if (!context) {
    throw new Error(
      'usePrevidenciaContext deve ser usado com o PrevidenciaContext',
    );
  }
  return context as IModuleContext<T>;
}

const featureDataKey = obterChaveArmazenamentoDadosFuncionalidades();

const PrevidenciaContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { cliente, setContextualMenu, contextualMenu, restoreDefaultMenu } =
    useContext(AppContext);
  const { setUserPermissions } = useContext(AuthContext);
  const { toastError } = useToast();
  const { clearFeatureData, setFeatureData, featureData } = useFeatureData();

  useLayoutEffect(() => {
    if (
      checkIfAllItemsAreTrue([
        cliente?.cpfCnpj !== '',
        cliente?.numCertificado !== '',
        contextualMenu === 'default',
      ])
    ) {
      const matriz = obterMatrizPeloCertificado(cliente?.numCertificado);
      if (!matriz) toastError('Ocorreu um erro ao carregar os menus!');
      setUserPermissions(getPermissionsInSession());
      setContextualMenu('previdencia');
    }
    return () => {
      if (contextualMenu === 'previdencia') {
        restoreDefaultMenu(cliente?.cpfCnpj);
        removeSessionItem(featureDataKey);
      }
    };
  }, [cliente.cpfCnpj, cliente.numCertificado]);

  return (
    <PrevidenciaContext.Provider
      value={{
        certificado: tryGetValueOrDefault([cliente?.numCertificado], ''),
        cpfCnpj: tryGetValueOrDefault([cliente?.cpfCnpj], ''),
        featureData,
        setFeatureData,
        clearFeatureData,
      }}
    >
      {children}
    </PrevidenciaContext.Provider>
  );
};

export default PrevidenciaContextProvider;
