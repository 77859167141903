import { api, IApiResponse } from 'main/services';
import { SendEmailResult } from 'main/types/EmailSender/IEmailSender';
import { obterEndpointEnviarEmail } from 'previdencia/config/endpoints';
import { obterEndpointEnviarEmailVida } from 'vida/config/endpoints';

export async function enviarEmail<SendEmailRequest>(
  objetoEnvio: SendEmailRequest,
  seguro?: boolean,
): Promise<SendEmailResult> {
  const definirEndpoint = seguro
    ? obterEndpointEnviarEmailVida()
    : obterEndpointEnviarEmail();
  try {
    const { data } = await api.post<IApiResponse<unknown>>(definirEndpoint, {
      ...objetoEnvio,
    });

    const result: SendEmailResult = {
      sucess: false,
      resultCode: 'ERGFRT001',
      resultMessage: 'Erro ao enviar e-mail!',
    };

    if (
      data?.dados?.mensagens &&
      data.dados.mensagens.length > 0 &&
      data.dados.mensagens[0]
    ) {
      const mensagem = data.dados.mensagens[0];
      if (mensagem) {
        result.sucess = data.dados.sucesso ?? false;
        result.resultCode = mensagem.codigo ?? '';
        result.resultMessage = mensagem.descricao ?? '';
      }
    }

    return result;
  } catch (error: unknown) {
    let mensagemErro = 'Erro inesperado ao enviar e-mail!';
    if (error instanceof Error) mensagemErro = error.message;
    else if (error instanceof String) mensagemErro = error.toString();
    return {
      sucess: false,
      resultCode: 'ERFR001',
      resultMessage: mensagemErro,
    };
  }
}
