import { Card, Display, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { WrapperWarning } from 'main/components/WrapperWarning';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import masks from 'main/utils/masks';
import { useState } from 'react';
import FormSinistro from 'sinistro/features/statusSinistro/components/FormSinistro';
import LinhaTempoSinistro from 'sinistro/features/statusSinistro/components/LinhaTempoSinistro';
import ModalObservacoesAndamento from 'sinistro/features/statusSinistro/components/ModalObservacoesAndamento';
import ResultadoSinistro from 'sinistro/features/statusSinistro/components/ResultadoSinistro';
import ResultadoSinistroProdutos from 'sinistro/features/statusSinistro/components/ResultadoSinistroProdutos';
import { initialStateModalMotivo } from 'sinistro/features/statusSinistro/factories/initialStateModalMotivoFactory';
import { resultadoSinistroPropsFactory } from 'sinistro/features/statusSinistro/factories/resultadoSinistroPropsFactory';
import { sinistroProdutosPropsFactory } from 'sinistro/features/statusSinistro/factories/sinistroProdutosPropsFactory';
import { sinistroTimelineFactory } from 'sinistro/features/statusSinistro/factories/sinistroTimelineFactory';
import { useConsultarSinistroCpfCnpjVida } from 'sinistro/features/statusSinistro/hooks/useConsultarSinistroCpfCnpjVida';
import { useStatusSinistroPrestamista } from 'sinistro/features/statusSinistro/hooks/useObterStatusSinistroPrestamista';
import { useStatusSinistro } from 'sinistro/features/statusSinistro/hooks/useObterStatusSinistroPrevidencia';
import { IFormStatusSinistroSubmit } from 'sinistro/features/statusSinistro/types/FormularioSinistroParams';
import { ICertificadoProdutoSelecionado } from 'sinistro/features/statusSinistro/types/IStatusSinistro';
import { IModalObservacoes } from 'sinistro/features/statusSinistro/types/ModalObservacoes';
import ResultadoSinistroProdutosSkeleton from 'sinistro/features/statusSinistro/components/ResultadoSinistroProdutosSkeleton';
import ResultadoSinistroSkeleton from 'sinistro/features/statusSinistro/components/ResultadoSinistroSkeleton';
import {
  NENHUM_RESULTADO,
  TITULO_NENHUM_RESULTADO,
} from 'sinistro/features/statusSinistro/constants/texts';

const StatusSinistro = () => {
  const [produtoSelecionado, setProdutoSelecionado] = useState<
    ICertificadoProdutoSelecionado | undefined
  >();
  const [flagMostrarResultado, setFlagMostrarResultado] =
    useState<boolean>(false);

  const [openModalMotivo, setOpenModalMotivo] = useState<IModalObservacoes>(
    initialStateModalMotivo,
  );

  const {
    response: responseStatusSinistroPrevidencia,
    setResponse: setResponseStatusSinistroPrevidencia,
    loading: loadingStatusSinistroPrevidencia,
    obterStatusSinistroPrevidencia,
  } = useStatusSinistro();

  const {
    response: responseStatusSinistroPrestamista,
    setResponse: setResponseStatusSinistroPrestamista,
    loading: loadingStatusSinistroPrestamista,
    obterStatusSinistroPrestamista,
  } = useStatusSinistroPrestamista();

  const {
    response: responseStatusSinistroVida,
    setResponse: setResponseStatusSinistroVida,
    loading: loadingStatusSinistroVida,
    obterStatusSinistroVida,
  } = useConsultarSinistroCpfCnpjVida();

  const limparResultadoFormulario = (): void => {
    setResponseStatusSinistroVida(undefined);
    setResponseStatusSinistroPrevidencia(undefined);
    setResponseStatusSinistroPrestamista(undefined);
    setFlagMostrarResultado(false);
  };

  const handleFormSubmit: IFormStatusSinistroSubmit = cpf => {
    limparResultadoFormulario();

    obterStatusSinistroPrevidencia({
      cpfTitular: masks.cpf.unmask(cpf),
    });

    obterStatusSinistroVida({
      cpfCnpj: masks.cpf.unmask(cpf),
      usuario: undefined,
    });

    obterStatusSinistroPrestamista({
      cpfCnpj: masks.cpf.unmask(cpf),
      numeroSinistro: 0,
    });

    setFlagMostrarResultado(true);
  };

  const dadosDoCliente = resultadoSinistroPropsFactory({
    statusSinistroPrevidencia: responseStatusSinistroPrevidencia,
    listaStatusSinistroPrestamista: responseStatusSinistroPrestamista,
    responseStatusSinistroVida,
  });

  const exibirSkeleton = (): boolean => {
    return checkIfSomeItemsAreTrue([
      loadingStatusSinistroPrevidencia,
      loadingStatusSinistroVida,
      loadingStatusSinistroPrestamista,
    ]);
  };
  const exibirMensagemDisclaimer = (): boolean => {
    return checkIfAllItemsAreTrue([
      dadosDoCliente.nomeTitular === '-',
      flagMostrarResultado,
      !exibirSkeleton(),
    ]);
  };

  const exibirResultado = checkIfAllItemsAreTrue([
    !exibirMensagemDisclaimer(),
    !exibirSkeleton(),
    flagMostrarResultado,
  ]);

  return (
    <Display type="display-block">
      <Card>
        <Card.Content>
          <FormSinistro
            loading={exibirSkeleton()}
            handleFormSubmit={handleFormSubmit}
            limparResultadoFormulario={limparResultadoFormulario}
          />

          <RenderConditional
            condition={exibirSkeleton()}
            component={
              <>
                <ResultadoSinistroSkeleton />
                <ResultadoSinistroProdutosSkeleton />
              </>
            }
          />

          <RenderConditional
            condition={exibirMensagemDisclaimer()}
            component={
              <WrapperWarning>
                <Text variant="body02-md" color="text-light" margin>
                  {TITULO_NENHUM_RESULTADO}
                </Text>

                <Text variant="body02-md" color="text-light">
                  {NENHUM_RESULTADO}
                </Text>
              </WrapperWarning>
            }
          />

          <RenderConditional condition={exibirResultado}>
            <ResultadoSinistro dados={dadosDoCliente} />
            <ResultadoSinistroProdutos
              produtoSelecionado={produtoSelecionado}
              setProdutoSelecionado={setProdutoSelecionado}
              produtos={sinistroProdutosPropsFactory({
                sinistroPrevidencia: responseStatusSinistroPrevidencia,
                sinistroPrestamista: responseStatusSinistroPrestamista,
                sinistroVida: responseStatusSinistroVida,
              })}
            />
            <LinhaTempoSinistro
              certificadoSelecionado={produtoSelecionado}
              timeline={sinistroTimelineFactory({
                produtoSelecionado,
                sinistroPrevidencia: responseStatusSinistroPrevidencia,
                sinistroPrestamista: responseStatusSinistroPrestamista,
                sinistroVida: responseStatusSinistroVida,
              })}
              setOpenModalMotivo={setOpenModalMotivo}
            />
          </RenderConditional>

          <ModalObservacoesAndamento
            exibirBotaoFecharModal={false}
            data={openModalMotivo}
            handleClose={() => setOpenModalMotivo(initialStateModalMotivo)}
          />
        </Card.Content>
      </Card>
    </Display>
  );
};

export default StatusSinistro;
