export const ultimosCincoAnos = (): string[] => {
  const anoAtual = new Date();
  anoAtual.setFullYear(anoAtual.getFullYear() - 1);
  const cincoAnos: string[] = [];

  for (let i = 0; i < 5; i += 1) {
    cincoAnos.push(String(anoAtual.getFullYear() - i));
  }

  return cincoAnos;
};
