import React, { useContext, useState } from 'react';
import { Text, Button, Card } from '@cvp/design-system/react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import ButtonsEtapasAporte from 'previdencia/features/Aporte/components/ButtonsEtapasAporte';
import GerarAporteDadosDistribuicao from 'previdencia/features/Aporte/components/GerarAporte/GerarAporteDadosDistribuicao';
import { usePecoEfetuarAporte } from 'previdencia/features/Aporte/hooks/usePecoAporte';
import * as FACTORY from 'previdencia/features/Aporte/factories/aporteFactory';
import * as CONST from 'previdencia/features/Aporte/constants';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';

import usePrevSummary from 'previdencia/hooks/usePrevSummary';
import { Display } from 'main/features/Auth/components';
import Grid from 'main/components/PEComponents/Grid';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import ModalBlur from 'main/components/ModalBlur/ModalBlur';
import RenderConditional from 'main/components/RenderConditional';
import { InfoGridItemInline } from 'previdencia/components/PrevidenciaResumo/InfoGridItemInline/InfoGridItemInline';

const GerarAporte: React.FC<APORTE_TYPES.IGerarAporteProps> = ({
  fundosDistribuicao,
  aporteFormaPagamento,
  aporteDadosBancarios,
  aporteOrigemRecursos,
}) => {
  const { toastSuccess } = useToast();

  const { setFeatureData, featureData } =
    usePrevidenciaContext<Partial<APORTE_TYPES.IAporteContextData>>();
  const [confirmarAporte, setConfirmarAporte] = useState(false);
  const { informacaoPorCertificado } = usePrevSummary();

  const { efetuarAporteAdicional, loadingDadosAporteAdicional } =
    usePecoEfetuarAporte();

  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const requestAporte: APORTE_TYPES.RequestEfetuarAporte =
    FACTORY.requisicaoEfetuarAporte({
      cpfCnpj,
      numCertificado,
      aporteFormaPagamento: aporteFormaPagamento.get().value,
      aporteDadosBancarios: aporteDadosBancarios.get().value,
      fundosDistribuicao: fundosDistribuicao.get().value,
      pepPositivo: featureData?.pepPositivo,
      aporteOrigemRecursos: aporteOrigemRecursos.get().value,
    });

  const concluirSolicitacaoAporte = async (): Promise<void> => {
    const result = await efetuarAporteAdicional(requestAporte);

    if (result?.entidade) {
      setFeatureData({
        ...featureData,
        dadosAporteAdicional: result,
        isFetching: {
          loadingDadosAporteAdicional,
        },
      });
      featureData?.proximaEtapa?.();
      toastSuccess(CONST.COMPROVANTE_APORTE_LABELS.OPERACAO_REALIZADA);
    } else {
      featureData?.proximaEtapa?.();
    }
  };

  const dadosPagamento = FACTORY.dadosGerarAporte(
    numCertificado,
    aporteFormaPagamento.get().value,
    aporteDadosBancarios.get().value,
  );

  return (
    <>
      <RenderConditional condition={confirmarAporte}>
        <ModalBlur
          open={confirmarAporte}
          onClose={() => setConfirmarAporte(false)}
        >
          <>
            <Display>
              <Text variant="headline-06" color="primary">
                Deseja realizar o aporte?
              </Text>
            </Display>

            <Card>
              <Card.Content>
                <Display type="display-block">
                  <InfoGridItemInline
                    icon="user"
                    label="Titular"
                    value={tryGetValueOrDefault(
                      [informacaoPorCertificado?.pessoa?.pessoaFisica?.nome],
                      '',
                    )}
                  />
                  <InfoGridItemInline
                    icon="documentPaper"
                    label="Certificado"
                    value={tryGetValueOrDefault(
                      [informacaoPorCertificado?.certificadoNumero],
                      '',
                    )}
                  />
                  <InfoGridItemInline
                    icon="documentBack"
                    label="Modalidade / Regime Tributário"
                    value={`${informacaoPorCertificado?.produto?.modalidade} / ${informacaoPorCertificado?.regimeTributario} `}
                  />
                  <InfoGridItemInline
                    icon="financialWallet"
                    label="Forma de Pagamento"
                    value={tryGetValueOrDefault(
                      [requestAporte?.Pagamento?.DescricaoPagamento],
                      '',
                    )}
                  />
                  <InfoGridItemInline
                    icon="financialMoneyBag"
                    label="Valor"
                    value={tryGetValueOrDefault(
                      [dadosPagamento[0].valorAporte],
                      '',
                    )}
                  />
                </Display>
              </Card.Content>
            </Card>
            <Grid>
              <Grid.Item xs={1}>
                <ButtonsEtapasAporte
                  proximaEtapa={concluirSolicitacaoAporte}
                  buttonLabel="Confirmar Aporte"
                  loading={loadingDadosAporteAdicional}
                  disabled={loadingDadosAporteAdicional}
                />
              </Grid.Item>
            </Grid>
          </>
        </ModalBlur>
      </RenderConditional>

      <Text variant="body02-md" color="primary" style={{ marginTop: 20 }}>
        Distribuição:
      </Text>

      <GerarAporteDadosDistribuicao fundosDistribuicao={fundosDistribuicao} />

      <Button variant="primary" onClick={() => setConfirmarAporte(true)}>
        Confirmar Aporte
      </Button>
    </>
  );
};

export default GerarAporte;
