import React, { useContext } from 'react';
import SidebarBase from 'main/components/Menu/Sidebar';
import { AppContext } from 'main/contexts/AppContext';

const Sidebar: React.FC = () => {
  const { menu } = useContext(AppContext);
  return <SidebarBase menu={menu} />;
};

export default Sidebar;
