export const QUITACAO_ANUAL = {
  CONFORMIDADE_LEI:
    'A Declaração de Quitação Anual de Débitos é um documento em conformidade com a Lei 12.007 de 29 de julho de 2009.',
  DECLARACAO_SUBSTITUI:
    'A Declaração substitui, para comprovação do cumprimento das obrigações do consumidor, as quitações dos débitos de parcelas do seguro por período de referência (ano). Podem ser consultadas as declarações dos 4 anos anteriores (janeiro a dezembro) a partir de 15 de janeiro do ano subsequente.',
  CERTIFICADOS_CANCELADOS:
    'A Declaração de Quitação Anual de Débitos não será emitida para certificados/apólices cancelados ou com a vigência encerrada.',
  PENDENCIA_PAGAMENTO:
    'Se houver pendência de pagamento de parcela no período consultado, a Declaração de Quitação Anual de Débitos não será emitida até que a (s) parcela (s) seja (m) regularizada (s) - paga (s).',
};
