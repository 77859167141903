import { IFilterableEntity } from 'main/types/IFilterableEntity';
import { dateWithoutTime } from 'main/utils';
import { ITagFilterOption, ISearchTerms } from './IFilterOption';

const filterByTextPartial = (
  text: string,
  filterColumn: string,
  dataToFilter: IFilterableEntity[],
  hasUnmask?: (value: string) => string,
) => {
  return dataToFilter?.filter(item => {
    const itemFilterColumn = item[filterColumn]
      ?.toString()
      .trim()
      .toLowerCase();
    const textToFilter = text.trim().toLowerCase();

    if (item[filterColumn]) {
      if (hasUnmask) {
        const unmaskedData = hasUnmask(itemFilterColumn).toLowerCase();
        const unmaskedSearchText = hasUnmask(textToFilter).toLowerCase();
        return unmaskedData.includes(unmaskedSearchText);
      }

      return itemFilterColumn.includes(textToFilter);
    }
    return null;
  });
};

export const filterByDate = (
  search: ISearchTerms,
  dataToFilter: IFilterableEntity[],
) => {
  const filterColumn = search.filter;
  return dataToFilter?.filter(item => {
    if (item[filterColumn] && search.initialDate && search.endDate) {
      return (
        dateWithoutTime(new Date(item[filterColumn]).toISOString()) >=
          dateWithoutTime(search.initialDate.toISOString()) &&
        dateWithoutTime(new Date(item[filterColumn]).toISOString()) <=
          dateWithoutTime(search.endDate.toISOString())
      );
    }
    return null;
  });
};

export const filterByText = (
  text: string,
  filterColumn: string,
  dataToFilter: IFilterableEntity[],
  hasUnmask?: (value: string) => string,
  partial = false,
) => {
  if (partial)
    return filterByTextPartial(text, filterColumn, dataToFilter, hasUnmask);

  return dataToFilter?.filter(item => {
    const itemFilterColumn = item[filterColumn]?.toString().trim();
    const textToFilter = text.trim();
    if (itemFilterColumn) {
      if (
        hasUnmask &&
        hasUnmask(itemFilterColumn) === hasUnmask(textToFilter)
      ) {
        return item;
      }
      return itemFilterColumn === textToFilter;
    }
    return null;
  });
};

const filterByIdOrDescription = (
  dataToFilter: IFilterableEntity[],
  item: ITagFilterOption,
) => {
  const filter = dataToFilter.filter(x => x[item.column ?? ''] === item.id);
  const filterByDescription = dataToFilter.filter(
    x =>
      x[item.column ?? '']?.toString().trim().toLowerCase() ===
      item.description.trim().toLowerCase(),
  );

  return filter.length > 0 ? filter : filterByDescription;
};

export const filterByTags = (
  dataToFilter: IFilterableEntity[],
  filterColumn?: string,
  options?: ITagFilterOption[],
) => {
  if (filterColumn && options && options?.length > 0) {
    const filteredItemsBySingleColumn: IFilterableEntity[] = [];
    // items filtrados com mais de uma coluna
    const filteredItemsByMultipleColumns: IFilterableEntity[] = [];

    const [firstOptionToFilter] = options;

    let lastColumnFiltered = firstOptionToFilter.column;

    options.forEach(item => {
      if (lastColumnFiltered !== item.column) {
        const filterResult = filterByIdOrDescription(
          filteredItemsBySingleColumn,
          item,
        );
        filteredItemsByMultipleColumns.push(...filterResult);
      } else {
        const filterResult = filterByIdOrDescription(dataToFilter, item);
        filteredItemsBySingleColumn.push(...filterResult);
      }
      lastColumnFiltered = item.column ?? '';
    });

    return filteredItemsByMultipleColumns?.length > 0
      ? filteredItemsByMultipleColumns
      : filteredItemsBySingleColumn;
  }
  return dataToFilter;
};
