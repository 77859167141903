import React from 'react';
import { Text } from '@cvp/design-system/react';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import masks from 'main/utils/masks';
import RenderConditional from 'main/components/RenderConditional';
import Icon from 'main/components/Icon';
import * as TypesDPS from 'contratosPrestamista/features/dpseletronica/types';

const EnvioDps: React.FC<TypesDPS.IEnvioDpsProps> = ({ listaEnvioDps }) => {
  const qtdSucessoNoEnvio = listaEnvioDps?.filter(
    dado => !!dado.isDPSReqSuccessfully,
  ).length;

  const totalEnviado = listaEnvioDps?.length;

  return (
    <>
      <Text variant="caption-02" margin align="center" color="success">
        <Icon name="successCheck" /> DPS enviada com sucesso!
      </Text>
      <Text variant="caption-02" margin align="center">
        ({`${qtdSucessoNoEnvio}/${totalEnviado}`}) DPS enviada
        {tryGetValueOrDefault([qtdSucessoNoEnvio], 0) > 1 && 's'}
      </Text>
      {listaEnvioDps?.map(dados => {
        return (
          <>
            <RenderConditional condition={!!dados.isDPSReqSuccessfully}>
              <Text variant="caption-02" margin align="center" color="success">
                <Icon name="successCheck" /> {masks.cpfCnpj.mask(dados.cpf)}
              </Text>
            </RenderConditional>
            <RenderConditional condition={!dados.isDPSReqSuccessfully}>
              <Text variant="caption-02" margin align="center" color="error">
                <Icon name="close" /> {masks.cpfCnpj.mask(dados.cpf)}
              </Text>
            </RenderConditional>
          </>
        );
      })}
    </>
  );
};

export default EnvioDps;
