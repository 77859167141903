export enum PREV_REDIRECTS_SCREENS {
  HISTORICO_SOLICITACOES = 'HISTORICO_SOLICITACOES',
  HISTORICO_CANCELAMENTO_RESGATE = 'HISTORICO_CANCELAMENTO_RESGATE',
}

export const PrevRedirectConfig: Record<PREV_REDIRECTS_SCREENS, string> = {
  [PREV_REDIRECTS_SCREENS.HISTORICO_SOLICITACOES]:
    '/cliente/produtos/previdencia/historico-solicitacoes',
  [PREV_REDIRECTS_SCREENS.HISTORICO_CANCELAMENTO_RESGATE]:
    '/cliente/produtos/previdencia/cancelamento-solicitacao-resgate',
};
