import { Text } from '@cvp/design-system/react';
import { ContainerExpandedInfoImpressao } from 'vida/features/DadosApolice/pages/styles';
import * as APOLICE_TYPES from 'seguros/types/ApoliseCobertura';

const DetalhesCoberturaImpressao = ({
  data,
}: APOLICE_TYPES.ITabelaApoliceCoberturasItens | any) => {
  return (
    <ContainerExpandedInfoImpressao>
      <Text variant="body02-md">{data?.descricaoCobertura}</Text>
    </ContainerExpandedInfoImpressao>
  );
};

export default DetalhesCoberturaImpressao;
