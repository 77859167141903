import styled from 'styled-components/macro';
import { Input as InputDS } from 'main/components/form';

export const ContainerInputGroup = styled.div`
  width: 100%;
  margin: 0;
  margin-bottom: 25px;
`;

export const Input = styled(InputDS)(({ theme: { color } }) => ({
  '& input::placeholder': {
    color: color.neutral['03'],
  },
}));
