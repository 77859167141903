import React from 'react';
import { Grid, Text } from '@cvp/design-system/react';
import {
  CONTRIBUICAO_PLANO_APLICACAO_FUNDOS,
  CONVERSAO_CONVERTE_FUNDO_ESCOLHIDO,
  COTAS,
  FUNDOS_INVESTIMENTOS,
  FUNDOS_INVESTIMENTOS_COTAS_PREVIDENCIA,
  PREVIDENCIA_PRIVADA_APLICACAO_DINHEIRO,
} from '../../constants';

const InvestimentoInfo: React.FC = () => {
  return (
    <Grid>
      <Grid.Item>
        <Text variant="body02-sm" color="text-light" margin>
          {CONTRIBUICAO_PLANO_APLICACAO_FUNDOS}
        </Text>

        <Text variant="body02-md" color="primary" margin>
          {FUNDOS_INVESTIMENTOS_COTAS_PREVIDENCIA}
        </Text>

        <Text variant="body01-lg" color="primary" margin>
          {FUNDOS_INVESTIMENTOS}
        </Text>

        <Text variant="body02-sm" color="text-light" margin>
          {PREVIDENCIA_PRIVADA_APLICACAO_DINHEIRO}
        </Text>

        <Text variant="body01-lg" color="primary" margin>
          {COTAS}
        </Text>

        <Text variant="body02-sm" color="text-light" margin>
          {CONVERSAO_CONVERTE_FUNDO_ESCOLHIDO}
        </Text>
      </Grid.Item>
    </Grid>
  );
};

export default InvestimentoInfo;
