import React from 'react';
import { Grid } from '@cvp/design-system/react';
import CardSkeleton from './CardSkeleton';

type GridCardSkeletonProps = {
  quantidadeColunas?: number;
};

const GridCardSkeleton: React.FC<GridCardSkeletonProps> = ({
  quantidadeColunas = 3,
}) => (
  <Grid>
    {Array.from(new Array(quantidadeColunas).keys()).map(i => (
      <Grid.Item xs={1} lg={1 / quantidadeColunas} key={i}>
        <CardSkeleton />
      </Grid.Item>
    ))}
  </Grid>
);

export default GridCardSkeleton;
