import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { PECOS } from 'previdencia/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import {
  IFundosOrigem,
  IPayloadDefinirDestino,
  IResponseDefinirSaida,
} from '../types/IFundos';

const useDefinirDestino = (
  fundoDestino: IFundosOrigem[] | undefined,
  numeroTransferencia?: string,
) => {
  const {
    cliente: { numCertificado },
  } = useContext(AppContext);
  const { response, loading, fetchData } = usePeco<
    IPayloadDefinirDestino,
    IResponseDefinirSaida
  >({
    api: {
      operationPath: PECOS.DefinirDestinoMultifundos,
    },
    autoFetch: false,
    payload: {
      numeroTransferencia,
      certificado: numCertificado,
      fundoDestino,
    },
  });
  return { response: response?.entidade, loading, fetchData };
};

export default useDefinirDestino;
