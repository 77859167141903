import { Button, Display, Grid, TextField } from '@cvp/design-system/react';
import { Field, FieldProps, Formik } from 'formik';
import { isCpf } from 'main/utils/cpf_cnpj';
import masks from 'main/utils/masks';
import React from 'react';
import { initialFormState } from 'sinistro/features/statusSinistro/factories/initialFormStateFactory';
import { textFieldFormSinistroPropsFactory } from 'sinistro/features/statusSinistro/factories/textFieldFormSinistroPropsFactory';
import * as S from 'sinistro/features/statusSinistro/pages/styles';
import { FormSinistroProps } from 'sinistro/features/statusSinistro/types/StatusSinistroComponentProps';
import { validarCpf } from 'sinistro/features/statusSinistro/utils/validarCpf';
import { PESQUISA_CPF, PESQUISA_CPF_LABEL } from '../constants/texts';

const FormSinistro: React.FC<FormSinistroProps> = ({
  loading,
  handleFormSubmit,
  limparResultadoFormulario,
}) => {
  return (
    <Formik
      initialValues={initialFormState}
      onSubmit={values => handleFormSubmit(values.cpf)}
    >
      {({ handleSubmit, handleBlur, values, setFieldValue, resetForm }) => (
        <form onSubmit={handleSubmit}>
          <Grid>
            <Grid.Item xs={1}>
              <S.TextAttention variant="body-medium3">
                {PESQUISA_CPF}
              </S.TextAttention>
            </Grid.Item>
          </Grid>
          <Grid>
            <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
              <Field name="cpf" validate={validarCpf}>
                {({ field, meta }: FieldProps) => {
                  return (
                    <TextField
                      {...field}
                      {...textFieldFormSinistroPropsFactory(
                        meta,
                        PESQUISA_CPF_LABEL,
                        setFieldValue,
                        'cpf',
                        values.cpf,
                        handleBlur,
                      )}
                      value={masks.cpf.mask(values.cpf)}
                      data-testid="inputCpf"
                    />
                  );
                }}
              </Field>
            </Grid.Item>
          </Grid>

          <Grid>
            <Grid.Item xs={1} md={1 / 2} xl={1 / 3}>
              <Display>
                <Button
                  variant="outlined"
                  loading={loading}
                  type="reset"
                  data-testid="btnLimpar"
                  onClick={() => {
                    resetForm();
                    limparResultadoFormulario();
                  }}
                >
                  Limpar
                </Button>
                <Button
                  data-testid="btnConsultar"
                  disabled={!isCpf(values.cpf)}
                  loading={loading}
                >
                  Consultar
                </Button>
              </Display>
            </Grid.Item>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default FormSinistro;
