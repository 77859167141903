import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'main/components/Route';
import { IPrivateRoute } from 'main/components/Route/PrivateRoute';
import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import ListagemFop from 'painelAdministracao/pages/ListagemFop';
import AdmContextProvider from 'painelAdministracao/context/AdministracaoContext';
import For from 'main/components/For';

const routes: IPrivateRoute[] = [
  {
    path: '/administracao/gestao-fop',
    component: ListagemFop,
    key: 'fop-prestamista',
    breadcrumb: 'Gestão de FOPs',
    authenticated: true,
    withSidebar: true,
    exact: true,
    requiredRoles: [
      USER_PROFILES.ANALISTA_TI,
      USER_PROFILES.ANALISTA_POS_VENDA,
    ],
  },
];

export const administracaoBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
  '/administracao': null,
};

const RotasAdministracao = (): React.ReactElement => (
  <Switch>
    <AdmContextProvider>
      <For each={routes}>
        {route => (
          <PrivateRoute
            key={route.key}
            path={route.path}
            exact={route.exact}
            component={route.component}
            authenticated={route.authenticated}
            requiredRoles={route.requiredRoles}
          />
        )}
      </For>
    </AdmContextProvider>
  </Switch>
);

export default RotasAdministracao;
