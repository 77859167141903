import { IAlicotasAgrupadasFundosAliquota } from 'previdencia/types/AlicotasAgrupadas';

export interface IModaisRegimeTributario {
  modalTipo: boolean;
  modalDocusign: boolean;
  modalAlertaContatos: boolean;
  modalAlertaAlteracaoExpirada: boolean;
}

export enum ResponsePerfilTributario {
  AGENCIA = 'agencia',
}

export interface IModalAlertaAlteracaoExpiradaProps {
  open: boolean;
  onClose: () => void;
  mensagem: string;
}

export interface IDadosTabela {
  fundosAliquotaRegressivo: Partial<IAlicotasAgrupadasFundosAliquota>[];
  fundosAliquotaProgressivo: Partial<IAlicotasAgrupadasFundosAliquota>[];
}

export interface ITabelasAliquotasProps {
  opcoesTributacaoIrrfDisponiveis: string[] | undefined;
  loadingDadosAliquota: boolean;
  opcaoRegimeTributario: string;
  dadosTabela: IDadosTabela | undefined;
  selecionarOpcaoRegimeTributario: (tipoAliquota: string) => void;
}

export interface IMensagemSemPermissaoAlteracaoProps {
  opcaoTributacaoIrrfAtual: string | undefined;
}

export interface IMensagemDeclaracaoRegimeTributarioProps {
  checarDeclaracao: () => void;
}

export interface IUseTabelasAliquotas {
  dadosTabela: IDadosTabela | undefined;
  loadingDadosAliquota: boolean;
  opcaoRegimeTributario: string;
}
