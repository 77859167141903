import styled from 'styled-components/macro';

export const Container = styled.div({
  paddingRight: 10,
  paddingLeft: 10,
});

export const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Description = styled.div(({ theme: { breakpoint } }) => ({
  [breakpoint.md()]: {
    fonrSize: 13,
  },
}));

export const WrapperColumn = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
});

export const WrapperIcon = styled.div<{ margin?: number }>(({ margin }) => ({
  marginRight: margin || 56,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));
