import { formatarData } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { IStatusSinistroPrestamista } from 'sinistro/features/statusSinistro/types/IStatusSinistro';
import { ResultadoSinistroProdutoProps } from 'sinistro/features/statusSinistro/types/StatusSinistroComponentProps';
import { TipoProduto } from 'sinistro/features/statusSinistro/types/enum';

export function produtosPrestamistaFactory(
  responseStatusSinistroPrestamista: IStatusSinistroPrestamista[] | undefined,
) {
  const listaPrestamista = tryGetValueOrDefault(
    [responseStatusSinistroPrestamista],
    [],
  );
  return listaPrestamista.map(produto => {
    return {
      descricaoProduto: 'Prestamista',
      tipoProduto: TipoProduto.PRESTAMISTA,
      codigoCertificado: String(produto.numeroContratoTerceiro),
      numeroAvisoSinistro: String(produto.sinistro),
      dataSinistro: formatarData(produto.dataOcorrencia),
      status: tryGetValueOrDefault([produto.timeline?.status], '-'),
    } as ResultadoSinistroProdutoProps;
  });
}
