export const PESQUISA_CPF = (
  <>
    Faça a pesquisa pelo
    <strong>
      {' '}
      CPF do(a) titular do contrato de seguro / plano de previdência
    </strong>{' '}
    e obtenha as informações dos sinistros relacionados a ele:
  </>
);

export const TITULO_NENHUM_RESULTADO = 'ATENÇÃO!';

export const NENHUM_RESULTADO = (
  <>
    Verifique se os dados foram inseridos corretamente. <br /> Caso não
    apresente informações para o CPF consultado, realize a abertura de
    ocorrência com assunto Andamento de Sinistro vinculado ao produto
    contratado.
  </>
);

export const PESQUISA_CPF_LABEL = 'CPF do(a) titular do produto';
