import * as RESPONSE_TYPES from 'seguros/types/ApoliceAssistenciasResponse';
import * as APOLICE_TYPES from 'seguros/types/ApoliseCobertura';
import {
  removerAssistenciasDuplicadas,
  removerEspacosTabelaApolice,
  mapeiaAssitenciasServicosParaAssistencias,
  filtrarCertificado,
} from 'seguros/utils/assistencias';

export function listaCoberturasAssistenciasFactory(
  numCertificado: string | undefined,
  coberturas: APOLICE_TYPES.ITabelaApolice[],
  contratos: RESPONSE_TYPES.ITabelaApoliceAssitenciasResponse[],
) {
  const assistencias = contratos
    .filter(filtrarCertificado(numCertificado))
    .flatMap(certificado => certificado.servicos)
    .filter(servico => Boolean(servico.descricaoServico))
    .map(mapeiaAssitenciasServicosParaAssistencias);

  return coberturas
    .concat(assistencias)
    .map(removerEspacosTabelaApolice)
    .filter(removerAssistenciasDuplicadas);
}
