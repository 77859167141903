import { tab } from 'consultaCliente/features/listaCardProduto/consts/constsListaCardProduto';
import { ICardProdutoPrestamista } from 'consultaCliente/features/listaCardProduto/interfaces/ICardProdutoPrestamista';
import { ICardProdutoVida } from 'consultaCliente/features/listaCardProduto/interfaces/ICardProdutoVida';
import { IDadosBasicosCertificadoPrevidencia } from 'consultaCliente/features/listaCardProduto/interfaces/IDadosBasicosCertificadoPrevidencia';
import {
  checkPrestAtivo,
  checkPrevAtivo,
  checkVidaAtivo,
} from 'consultaCliente/features/listaCardProduto/utils/tabProduto';
import { useMemo } from 'react';

export function useActiveTab(
  loading: boolean,
  dadosCardsPrevidencia: IDadosBasicosCertificadoPrevidencia[] | undefined,
  dadosCardsVida: ICardProdutoVida[] | undefined,
  dadosCardsPrestamista: ICardProdutoPrestamista[] | undefined,
): number | null {
  return useMemo(() => {
    if (loading) return null;

    if (checkPrevAtivo(dadosCardsPrevidencia)) return tab.PREVIDENCIA;

    if (checkVidaAtivo(dadosCardsVida)) return tab.VIDA;

    if (checkPrestAtivo(dadosCardsPrestamista)) return tab.PRESTAMISTA;

    return tab.PREVIDENCIA;
  }, [dadosCardsPrestamista, dadosCardsPrevidencia, dadosCardsVida, loading]);
}
