import styled from 'styled-components/macro';
import { Modal as ModalBase } from '@cvp/design-system/react';

export const ModalConfirmacaoAlteracaoValorContribuicaoBeneficioProtecao =
  styled(ModalBase)(
    ({
      theme: { breakpoint },
      showCloseButton,
    }: {
      theme: any;
      showCloseButton: boolean;
    }) => ({
      '&&': {
        [breakpoint.lg()]: {
          minWidth: '800px',
        },
        '& > button': {
          display: showCloseButton ? 'inline-block' : 'none',
        },
      },
    }),
  );

export const ContentModalValorContribuicao = styled.div({
  display: 'flex',
  gap: '7px',
  marginBottom: '1rem',
});
