import React from 'react';
import { Skeleton, Card, Display, Divider } from '@cvp/design-system/react';

const CardSkeleton = (): React.ReactElement => {
  return (
    <Card>
      <Card.Content padding={[1, 4, 0]}>
        <Display alignItems="center">
          <Skeleton lines={1} />
        </Display>
      </Card.Content>
      <Divider />
      <Card.Content padding={[1, 4, 0]}>
        <Display alignItems="center">
          <Skeleton lines={4} />
        </Display>
      </Card.Content>
      <Divider />
      <Card.Content padding={[1, 4, 0]}>
        <Display justify="space-between">
          <Skeleton lines={1} />
        </Display>
      </Card.Content>
    </Card>
  );
};

export default CardSkeleton;
