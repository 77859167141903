import { Input } from 'main/components/form';
import styled from 'styled-components/macro';

export const ContainerButton = styled.div<{
  justifyContent?: string;
}>(({ justifyContent }) => ({
  display: 'flex',
  justifyContent: `${justifyContent || 'flex-end'}`,
  padding: '20px',
}));

export const StyledInput = styled(Input)({
  width: 300,
});

export const SelectContainer = styled.div({
  width: 300,
  marginBottom: '20px',
});

export const DisplayCards = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '40px',
  padding: '40px',
});

export const Divider = styled.div({
  marginBottom: '100px',
});
