import { isCpf } from 'main/utils/cpf_cnpj';

export const validarCpf = (value: string): string | undefined => {
  return isCpf(value) ? undefined : 'CPF inválido!';
};

export const validarCampo = (value: string): string | undefined => {
  return value ? undefined : 'Campo obrigatório';
};

export const validarSelect = (value: string): string | undefined => {
  return value ? undefined : 'Escolha uma opção';
};
