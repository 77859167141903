import { api, IApiResponse } from 'main/services';
import {
  obterEndpointAtualizarDadosBancarios,
  obterEndpointListarDadosBancarios,
} from 'previdencia/config/endpoints';
import {
  AtualizacaoDadosBancariosPayload,
  ListarDadosBancariosPayload,
  ResponseListaDadosBancarios,
} from 'previdencia/features/CancelamentoPrevidencia/types/IResponseAtualizacaoDadosBancarios';

export const atualizarDadosBancarios = async (
  payload: AtualizacaoDadosBancariosPayload,
): Promise<string | undefined> => {
  const { data } = await api.post<IApiResponse<string>>(
    obterEndpointAtualizarDadosBancarios(),
    payload,
  );

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data?.dados?.mensagens?.[0].descricao;
};

export const listarDadosBancarios = async (
  payload: ListarDadosBancariosPayload,
): Promise<ResponseListaDadosBancarios | undefined> => {
  const { data } = await api.post<IApiResponse<ResponseListaDadosBancarios>>(
    obterEndpointListarDadosBancarios(),
    payload,
  );

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data?.dados?.entidade;
};
