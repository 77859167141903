import { Display, Grid, Text } from '@cvp/design-system/react';
import { FC, ReactElement } from 'react';
import * as S from 'seguros/components/QuitacaoAnualDebitos/styles';

export const QuitacaoAnualDebitosSkeleton: FC = (): ReactElement => {
  return (
    <Grid>
      <Grid.Item lg={9 / 10} md={1}>
        <Display alignItems="center">
          <Grid>
            <Grid.Item xs={1}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Período de Referência
                  </Text>
                  <S.Skeleton />
                </div>
              </Display>
            </Grid.Item>
            <Grid.Item xs={1}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Nome do Cliente
                  </Text>

                  <S.Skeleton />
                </div>
              </Display>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} lg={1 / 2} xl={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Certificado/Bilhete
                  </Text>

                  <S.Skeleton />
                </div>
              </Display>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} lg={1 / 2} xl={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Número da Apólice
                  </Text>

                  <S.Skeleton />
                </div>
              </Display>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} lg={1 / 2} xl={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Produto
                  </Text>

                  <S.Skeleton />
                </div>
              </Display>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} lg={1 / 2} xl={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Data de Início de Vigência do Certificado
                  </Text>

                  <S.Skeleton />
                </div>
              </Display>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} lg={1 / 2} xl={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Situação Atual do Seguro
                  </Text>

                  <S.Skeleton />
                </div>
              </Display>
            </Grid.Item>
            <Grid.Item xs={1} md={1 / 2} lg={1 / 2} xl={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Data de Emissão da Declaração
                  </Text>

                  <S.Skeleton />
                </div>
              </Display>
            </Grid.Item>
          </Grid>
        </Display>
      </Grid.Item>
    </Grid>
  );
};
