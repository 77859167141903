import { Card, Grid, Text } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import * as UTILS from 'previdencia/features/SolicitacaoResgate/utils/SolicitacaoResgate';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const CardResumoDistribuicaoFundosResgate = ({
  formik,
}: SOLICITACAO_RESGATE_TYPES.ICardResumoDistribuicaoFundosResgateProps) => {
  return (
    <Grid.Item xs={1} lg={2 / 5}>
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <RenderConditional
            condition={UTILS.calcularValorRestante(formik).calculoRestante >= 0}
          >
            <Text variant="body02-md">
              Restante:{' '}
              <strong>
                {tryGetMonetaryValueOrDefault(
                  UTILS.calcularValorRestante(formik).calculoRestante,
                )}
              </strong>
            </Text>
          </RenderConditional>
          <RenderConditional
            condition={UTILS.calcularValorRestante(formik).calculoRestante < 0}
          >
            <Text variant="body02-md">
              O valor preenchido é maior que o solicitado.
            </Text>
          </RenderConditional>
          <Text variant="body02-md">
            Total:{' '}
            <strong>
              {tryGetMonetaryValueOrDefault(
                UTILS.calcularValorRestante(formik).calculoTotal,
              )}
            </strong>
          </Text>
        </Card.Content>
      </Card>
    </Grid.Item>
  );
};

export default CardResumoDistribuicaoFundosResgate;
