import React, { useContext, memo } from 'react';
import { Button } from '@cvp/design-system/react';
import { AppContext } from 'main/contexts/AppContext';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import {
  IDadosClientePrev,
  IClientePrevidencia,
} from 'previdencia/types/IClientePrevidencia';

const VoltarDadosPlano = (): React.ReactElement => {
  const { navigateTo } = usePrevNavigation();
  const { cliente }: { cliente: IClientePrevidencia<IDadosClientePrev> } =
    useContext(AppContext);

  const voltarDadosPlano = (): void => {
    navigateTo('dados-plano', {
      cerficadosDetalhado: cliente.dados?.cerficadosDetalhado,
      diaVencimento: cliente.dados?.diaVencimento,
      duracao: cliente.dados?.duracao,
      formaPagamento: cliente.dados?.formaPagamento,
    });
  };

  return (
    <Button variant="outlined" onClick={voltarDadosPlano}>
      Voltar
    </Button>
  );
};

export default memo(VoltarDadosPlano);
