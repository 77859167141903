import React from 'react';
import { Switch } from 'react-router-dom';
import EvolucaoPatrimonial from 'evolucaoPatrimonial/pages/EvolucaoPatrimonial';
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import PrivateRoute from 'main/components/Route';
import { IPrivateRoute } from 'main/components/Route/PrivateRoute';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import PrevidenciaContextProvider from 'previdencia/contexts/PrevidenciaContextProvider';
import AlteracaoBeneficiarios from 'previdencia/features/AlteracaoBeneficiarios/pages/AlteracaoBeneficiarios';
import AlteracaoDiaVencimento from 'previdencia/features/AlteracaoDiaVencimento/pages/AlteracaoDiaVencimento';
import AlteracaoFormaDadosPagamento from 'previdencia/features/AlteracaoFormaDadosPagamento/pages/AlteracaoFormaDadosPagamento';
import AlteracaoPrazoDiferimento from 'previdencia/features/AlteracaoPrazoDiferimento/pages/AlteracaoPrazoDiferimento';
import AlteracaoRegimeTributario from 'previdencia/features/AlteracaoRegimeTributario/pages/AlteracaoRegimeTributario';
import ComprovanteConfirmacaoValorContribuicao from 'previdencia/features/AlterarValorContribuicao/components/SolicitarAlteracaoContribuicaoRenda/SolicitarAlteracaoContribuicaoRenda';
import AlterarValorContribuicao from 'previdencia/features/AlterarValorContribuicao/pages/AlterarValorContribuicao';
import Aporte from 'previdencia/features/Aporte/pages/Aporte';
import BeneficioDeProtecao from 'previdencia/features/BeneficioDeProtecao/pages/BeneficioDeProtecao';
import DadosCancelamento from 'previdencia/features/CancelamentoPrevidencia/pages/DadosCancelamento';
import CancelamentoSolicitacaoResgate from 'previdencia/features/CancelamentoSolicitacaoResgate/pages/CancelamentoSolicitacaoResgate';
import Contribuicoes from 'previdencia/features/Contribuicoes/pages/Contribuicoes';
import DadosParticipante from 'previdencia/features/DadosParticipante/pages/DadosParticipante';
import DadosPlano from 'previdencia/features/DadosPlano/pages/DadosPlano';
import HomePage from 'previdencia/features/Dashboard/pages/Home';
import DeclaracaoQuitacaoAnual from 'previdencia/features/DeclaracaoQuitacaoAnual/pages/DeclaracaoQuitacaoAnual';
import EmissaoCertificado from 'previdencia/features/EmissaoCertificado/pages/EmissaoCertificado';
import Extrato from 'previdencia/features/Extrato/pages/Extrato';
import GuiaPreenchimento from 'previdencia/features/GuiaPreenchimento/pages/GuiaPreenchimento';
import HistoricoSolicitacoes from 'previdencia/features/HistoricoSolicitacoes/pages/HistoricoSolicitacoes';
import InformesBeneficiarios from 'previdencia/features/InformesBeneficiarios/pages/InformesBeneficiarios';
import PosicaoConsolidada from 'previdencia/features/PosicaoConsolidada/pages/PosicaoConsolidada';
import InformacaoReajusteAnualPlano from 'previdencia/features/ReajusteAnualPlano/pages/InformacaoReajusteAnualPlano';
import ReajusteAnualPlano from 'previdencia/features/ReajusteAnualPlano/pages/ReajusteAnualPlano';
import RentabilidadeCotas from 'previdencia/features/RentabilidadeCotas/pages/RentabilidadeCotas';
import Resgates from 'previdencia/features/Resgate/pages/Resgates';
import ServicosIR from 'previdencia/features/ServicosIR/pages/ServicosIR';
import SimulacaoRenda from 'previdencia/features/SimulacaoRenda/pages/SimulacaoRenda';
import EfetuarResgate from 'previdencia/features/SolicitacaoResgate/pages/EfetuarResgate';
import ConfirmarValoresSimulacao from 'previdencia/features/SolicitacaoResgate/pages/ConfirmarValoresSimulacao';
import SolicitacaoResgate from 'previdencia/features/SolicitacaoResgate/pages/SolicitacaoResgate';
import DefinirContribuicao from 'previdencia/features/SolicitacaoResgate/pages/DefinirContribuicao';
import SuspensaoContribuicoes from 'previdencia/features/SuspensaoContribuicoes/pages/SuspensaoContribuicoes';
import TransferenciaFundoInvestimento from 'previdencia/features/TransferenciaFundoInvestimento/pages/TransferenciaFundoInvestimento';
import TransferenciaInternaEmissaoConjugado from 'previdencia/features/TransferenciaInternaEmissaoConjugado/pages/TransferenciaInternaEmissaoConjugado';
import TransferenciaMultifundos from 'previdencia/features/TransferenciaMultifundos/pages/TransferenciaMultifundos';
import ResgateSucesso from 'previdencia/features/SolicitacaoResgate/pages/ResgateSucesso';

export const routes: IPrivateRoute[] = [
  {
    path: '/cliente/produtos/previdencia/dados-participante',
    component: DadosParticipante,
    key: 'previdencia-dados-participante',
    authenticated: true,
    breadcrumb: 'Dados do Participante',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_CONSULTA,
    ],
    requiredPermissions: [PREV_PERMISSIONS.INFORMACOES_DO_PARTICIPANTE],
  },
  {
    path: '/cliente/produtos/previdencia',
    component: HomePage,
    key: 'previdencia-home',
    authenticated: true,
    breadcrumb: 'Painel',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
    requiredPermissions: [],
  },
  {
    path: '/cliente/produtos/previdencia/dados-plano',
    component: DadosPlano,
    key: 'previdencia-dados-do-plano',
    authenticated: true,
    breadcrumb: 'Dados do Plano',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
    requiredPermissions: [PREV_PERMISSIONS.INFORMACOES_DO_PARTICIPANTE],
  },
  {
    path: '/cliente/produtos/previdencia/extrato',
    component: Extrato,
    key: 'previdencia-extrato',
    authenticated: true,
    breadcrumb: 'Extrato',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
    requiredPermissions: [PREV_PERMISSIONS.EXTRATO_DETALHADO],
  },
  {
    path: '/cliente/produtos/previdencia/extrato-rentabilidade-cotas',
    component: RentabilidadeCotas,
    key: 'previdencia-rentabilidadecotas',
    authenticated: true,
    breadcrumb: () => 'Extrato de Rentabilidade e Cotas',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
    requiredPermissions: [PREV_PERMISSIONS.RENTABILIDADE_E_COTAS],
  },
  {
    path: '/cliente/produtos/previdencia/contribuicoes',
    component: Contribuicoes,
    key: 'previdencia-contribuicoes',
    authenticated: true,
    breadcrumb: 'contribuicoes',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
    requiredPermissions: [
      PREV_PERMISSIONS.ATIVACAO_E_SUSPENSAO_DE_CONTRIBUICOES,
      PREV_PERMISSIONS.CONTRIBUICAO_ADICIONAL,
      PREV_PERMISSIONS.CONTRIBUICOES_EM_ABERTO,
      PREV_PERMISSIONS.CONTRIBUICOES_PAGAS,
    ],
  },
  {
    path: '/cliente/produtos/previdencia/servicos-ir',
    component: ServicosIR,
    key: 'previdencia-servicos-ir',
    authenticated: true,
    breadcrumb: 'Informe de Rendimentos',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
    requiredPermissions: [PREV_PERMISSIONS.IMPOSTO_DE_RENDA],
  },
  {
    path: '/cliente/produtos/previdencia/emissao-certificado',
    component: EmissaoCertificado,
    key: 'previdencia-emissaocertificado',
    authenticated: true,
    breadcrumb: 'Emissao de Certificado',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
    requiredPermissions: [PREV_PERMISSIONS.EMITIR_CERTIFICADO],
  },
  {
    path: '/cliente/produtos/previdencia/beneficiarios',
    component: AlteracaoBeneficiarios,
    key: 'previdencia-alteracaobeneficiarios',
    authenticated: true,
    breadcrumb: 'Alteracao Beneficiario',
    exact: true,
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO],
    requiredPermissions: [
      PREV_PERMISSIONS.INFORMACOES_DOS_BENEFICIARIOS_CONSULTA,
    ],
  },
  {
    path: '/cliente/produtos/previdencia/alterar-valor-contribuicao',
    component: AlterarValorContribuicao,
    key: 'previdencia-dados-contribuicoes',
    authenticated: true,
    breadcrumb: 'Alterar Valor de Contribuição',
    exact: true,
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO],
    requiredPermissions: [PREV_PERMISSIONS.ALTERAR_VALOR_DA_CONTRIBUICAO],
  },
  {
    path: '/cliente/produtos/previdencia/comprovante-alteracao-valor-contribuicao',
    component: ComprovanteConfirmacaoValorContribuicao,
    key: 'previdencia-comprovante-alteracao-valor-contribuicao',
    authenticated: true,
    breadcrumb: 'Comprovante Alteração Valor Fundo',
    exact: true,
    requiredRoles: PRINCIPAL_USERS,
    requiredPermissions: [PREV_PERMISSIONS.ALTERAR_VALOR_DA_CONTRIBUICAO],
  },
  {
    path: '/cliente/produtos/previdencia/declaracao-quitacao-anual',
    component: DeclaracaoQuitacaoAnual,
    key: 'previdencia-declaracao-quitacao-anual',
    authenticated: true,
    breadcrumb: 'Declaracao de Quitacao Anual',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
    requiredPermissions: [PREV_PERMISSIONS.QUITACAO_ANUAL],
  },
  {
    path: '/cliente/produtos/previdencia/cancelamento-solicitacao-resgate',
    component: CancelamentoSolicitacaoResgate,
    key: 'previdencia-cancelamentoSolicitacaoResgate',
    authenticated: true,
    breadcrumb: 'Cancelamento de solicitações',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
    requiredPermissions: [PREV_PERMISSIONS.HISTORICO_E_CANCELAMENTO_DE_RESGATE],
  },
  {
    path: '/cliente/produtos/previdencia/posicao-consolidada',
    component: PosicaoConsolidada,
    key: 'previdencia-carteiraInvestimento',
    authenticated: true,
    breadcrumb: 'Posição Consolidada do Dia',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
    ],
  },
  {
    path: '/cliente/produtos/previdencia/evolucao-patrimonial',
    component: EvolucaoPatrimonial,
    key: 'previdencia-evolucao-patrimonial',
    authenticated: true,
    breadcrumb: 'Evolução Patrimonial',
    exact: true,
    requiredRoles: [
      USER_PROFILES.ANALISTA_TI,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ECONOMIARIO,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_POS_VENDA,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
  },
  {
    path: '/cliente/produtos/previdencia/guia-preenchimento',
    component: GuiaPreenchimento,
    key: 'previdencia-guiaPreenchimento',
    authenticated: true,
    breadcrumb: 'Guia de Preenchimento',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
    requiredPermissions: [PREV_PERMISSIONS.INFORMACOES_DO_PARTICIPANTE],
  },
  {
    path: '/cliente/produtos/previdencia/transferencia-fundo-investimento',
    component: TransferenciaFundoInvestimento,
    key: 'previdencia-transferencia-fundo-investimento',
    authenticated: true,
    breadcrumb: 'Transferência de Fundos de Investimento',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_CONSULTA,
    ],
    requiredPermissions: [PREV_PERMISSIONS.TRANSFERENCIA_DE_FUNDOS],
  },
  {
    path: '/cliente/produtos/previdencia/transferencia-multifundos',
    component: TransferenciaMultifundos,
    key: 'previdencia-transferencia-multifundos',
    authenticated: true,
    breadcrumb: 'Transferência Multifundos PJ',
    exact: true,
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO],
    requiredPermissions: [PREV_PERMISSIONS.TRANSFERENCIA_DE_FUNDOS],
  },
  {
    path: '/cliente/produtos/previdencia/transferencia-interna-emissao-conjugado',
    component: TransferenciaInternaEmissaoConjugado,
    key: 'previdencia-transferencia-interna-emissao-conjugado',
    authenticated: true,
    breadcrumb: 'Transferência Interna com Emissão de Conjugado',
    exact: true,
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO],
    requiredPermissions: [PREV_PERMISSIONS.TRANSFERENCIA_DE_FUNDOS],
  },
  {
    path: '/cliente/produtos/previdencia/solicitacao-resgate',
    component: SolicitacaoResgate,
    key: 'previdencia-solicitacao-resgate',
    authenticated: true,
    breadcrumb: 'Solicitacao de Resgate',
    exact: true,
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
    requiredPermissions: [PREV_PERMISSIONS.SOLICITACAO_DE_RESGATE],
  },
  {
    path: '/cliente/produtos/previdencia/confirmar-valores-simulacao',
    component: ConfirmarValoresSimulacao,
    key: 'previdencia-confirmar-valores-simulacao',
    authenticated: true,
    breadcrumb: 'Confirmar Valores Simulação',
    exact: true,
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
    requiredPermissions: [PREV_PERMISSIONS.SOLICITACAO_DE_RESGATE],
  },
  {
    path: '/cliente/produtos/previdencia/efetuar-resgate',
    component: EfetuarResgate,
    key: 'previdencia-efetuar-resgate',
    authenticated: true,
    breadcrumb: 'Efetuar Resgate',
    exact: true,
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
    requiredPermissions: [PREV_PERMISSIONS.SOLICITACAO_DE_RESGATE],
  },
  {
    path: '/cliente/produtos/previdencia/efetuar-resgate/sucesso',
    component: ResgateSucesso,
    key: 'resgates-sucesso',
    authenticated: true,
    breadcrumb: 'Resultado da Solicitação de Resgate',
    exact: true,
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
    requiredPermissions: [PREV_PERMISSIONS.INFORMACOES_DO_PARTICIPANTE],
  },
  {
    path: '/cliente/produtos/previdencia/definir-contribuicao',
    component: DefinirContribuicao,
    key: 'previdencia-definir-contribuicao',
    authenticated: true,
    breadcrumb: 'Definir Contribuição',
    exact: true,
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
    requiredPermissions: [PREV_PERMISSIONS.SOLICITACAO_DE_RESGATE],
  },
  {
    path: '/cliente/produtos/previdencia/reajuste-anual-do-plano',
    component: ReajusteAnualPlano,
    key: 'previdencia-reajuste-anual-plano',
    authenticated: true,
    breadcrumb: 'Reajuste Anual do Plano',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_CONSULTA,
    ],
    requiredPermissions: [PREV_PERMISSIONS.REAJUSTE_E_REENQUADRAMENTO],
  },
  {
    path: '/cliente/produtos/previdencia/informacao-reajuste-anual-do-plano',
    component: InformacaoReajusteAnualPlano,
    key: 'previdencia-informacao-reajuste-anual-do-plano',
    authenticated: true,
    breadcrumb: 'Reajuste Anual do Plano',
    exact: true,
    withSidebar: false,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_CONSULTA,
    ],
    requiredPermissions: [PREV_PERMISSIONS.REAJUSTE_E_REENQUADRAMENTO],
  },
  {
    path: '/cliente/produtos/previdencia/ativacao-suspensao-contribuicoes',
    component: SuspensaoContribuicoes,
    key: 'previdencia-ativacao-suspensao-contribuicoes',
    authenticated: true,
    breadcrumb: 'Ativação/Suspensão de Contribuições',
    exact: true,
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO],
    requiredPermissions: [
      PREV_PERMISSIONS.ATIVACAO_E_SUSPENSAO_DE_CONTRIBUICOES,
    ],
  },
  {
    path: '/cliente/produtos/previdencia/alteracao-prazo-diferimento',
    component: AlteracaoPrazoDiferimento,
    key: 'previdencia-alteracao-prazo-diferimento',
    authenticated: true,
    breadcrumb: 'Alteração do Prazo de Diferimento',
    exact: true,
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO],
    requiredPermissions: [PREV_PERMISSIONS.PRAZO_DE_TERMINO_DO_PLANO],
  },
  {
    path: '/previdencia/resgates',
    component: Resgates,
    key: 'pesquisa-resgates-periodo',
    breadcrumb: 'Resgates',
    authenticated: true,
    exact: true,
    requiredRoles: PRINCIPAL_USERS,
    requiredPermissions: [PREV_PERMISSIONS.SOLICITACAO_DE_RESGATE],
  },
  {
    path: '/cliente/produtos/previdencia/simulacao-renda',
    component: SimulacaoRenda,
    key: 'previdencia-simulacao-renda',
    authenticated: true,
    breadcrumb: 'Simulação de Renda',
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
    requiredPermissions: [PREV_PERMISSIONS.SIMULADOR_DE_RENDA],
  },
  {
    path: '/cliente/produtos/previdencia/historico-solicitacoes',
    component: HistoricoSolicitacoes,
    key: 'previdencia-historico-solicitacoes',
    authenticated: true,
    breadcrumb: 'Histórico de Solicitações',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
    requiredPermissions: [PREV_PERMISSIONS.HISTORICO_DE_SOLICITACOES],
  },
  {
    path: '/cliente/produtos/previdencia/alteracao-dia-vencimento',
    component: AlteracaoDiaVencimento,
    key: 'previdencia-alteracao-dia-vencimento',
    authenticated: true,
    breadcrumb: 'Alteração de Dia de Vencimento',
    exact: true,
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO],
    requiredPermissions: [PREV_PERMISSIONS.DIA_DE_VENCIMENTO_DO_PLANO],
  },
  {
    path: '/cliente/produtos/previdencia/informes-beneficiarios',
    component: InformesBeneficiarios,
    key: 'previdencia-informes-beneficiarios',
    authenticated: true,
    breadcrumb: 'Informe de Rendimento(s) de Beneficiário(s)',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
    requiredPermissions: [
      PREV_PERMISSIONS.INFORMACOES_DOS_BENEFICIARIOS_CONSULTA,
    ],
  },
  {
    path: '/cliente/produtos/previdencia/alterar-forma-dados-pagamento',
    component: AlteracaoFormaDadosPagamento,
    key: 'previdencia-alterar-forma-dados-pagamento',
    authenticated: true,
    breadcrumb: 'Alterar Forma e Dados de Pagamento',
    exact: true,
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO],
    requiredPermissions: [PREV_PERMISSIONS.ALTERAR_DADOS_DE_PAGAMENTO],
  },
  {
    path: '/cliente/produtos/previdencia/aporte',
    component: Aporte,
    key: 'previdencia-aporte',
    authenticated: true,
    breadcrumb: 'Aporte Adicional',
    exact: true,
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO],
    requiredPermissions: [PREV_PERMISSIONS.ALTERAR_DADOS_DE_PAGAMENTO],
  },
  {
    path: '/cliente/produtos/previdencia/cancelamento',
    component: DadosCancelamento,
    key: 'cancelamento',
    authenticated: true,
    breadcrumb: 'Cancelamento',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
    ],
    requiredPermissions: [PREV_PERMISSIONS.ALTERAR_DADOS_DE_PAGAMENTO],
  },
  {
    path: '/cliente/produtos/previdencia/alteracao-regime-tributario',
    component: AlteracaoRegimeTributario,
    key: 'previdencia-alteracao-regime-tributario',
    authenticated: true,
    breadcrumb: 'Alteração de Regime Tributário',
    exact: true,
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO],
    requiredPermissions: [PREV_PERMISSIONS.ALTERAR_DADOS_DE_PAGAMENTO],
  },
  {
    path: '/cliente/produtos/previdencia/incluir-beneficio-de-protecao',
    component: BeneficioDeProtecao,
    key: 'incluir-beneficio-de-protecao',
    authenticated: true,
    breadcrumb: 'Incluir Benefício de Proteção',
    exact: true,
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO],
  },
];

export const previdenciaBreadcrumbs = {
  '/cliente/produtos/previdencia': null,
  '/previdencia': null,
  '/previdencia/resgates': null,
  '/previdencia/resgates/detalhes': null,
  ...mapBreadCrumbsFromRoutes(routes),
};

const RotasPrevidencia = (): React.ReactElement => (
  <Switch>
    <PrevidenciaContextProvider>
      {routes.map(route => (
        <PrivateRoute
          key={route.key}
          path={route.path}
          exact={route.exact}
          component={route.component}
          authenticated={route.authenticated}
          requiredRoles={route.requiredRoles}
        />
      ))}
    </PrevidenciaContextProvider>
  </Switch>
);

export default RotasPrevidencia;
