import React from 'react';
import { Card, Divider, Switch, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { CoberturaContratada } from '../../types/CoberturasContratadas.types';
import { verificarDadoAtivo } from '../../utils/suspensaoContribuicoes';
import InformacoesAdicionaisReserva from '../InformacoesAdicionaisReserva';
import InformacoesAdicionaisCuidadoExtra from '../InformacoesAdicionaisCuidadoExtra';
import * as S from './styles';
import CardStatusCancelado from '../CardStatusCancelado';

type CardStatusContribuicaoProps = {
  dados: CoberturaContratada | undefined;
  tipo: TipoContribuicao;
  mudarEtapa: (dado: CoberturaContratada | undefined) => void;
};

type TipoContribuicao = 'reserva' | 'cuidadoExtra';

const statusPossiveis = ['Suspenso', 'Ativo'];

const CardStatusContribuicao: React.FC<CardStatusContribuicaoProps> = ({
  dados,
  tipo,
  mudarEtapa,
}) => {
  const status = statusPossiveis[verificarDadoAtivo(dados?.status)];

  if (dados?.status === 'C') {
    return (
      <S.CardStyleCancelado>
        <CardStatusCancelado dados={dados} />
      </S.CardStyleCancelado>
    );
  }

  return (
    <S.CardStyle>
      <Card.Content>
        <S.ContainerSwitch>
          <Switch
            onClick={() => mudarEtapa(dados)}
            checked={verificarDadoAtivo(dados?.status)}
          />
          <S.TextSwitch>{status}</S.TextSwitch>
        </S.ContainerSwitch>
        <S.ContainerTipoContribuicao>
          <Text variant="body01-sm">
            <strong>{dados?.nome}</strong>
          </Text>
          <RenderConditional condition={tipo === 'cuidadoExtra'}>
            <Text variant="body01-sm">Cuidado Extra</Text>
          </RenderConditional>
          <RenderConditional condition={tipo !== 'cuidadoExtra'}>
            <Text variant="body01-sm">Reserva</Text>
          </RenderConditional>
        </S.ContainerTipoContribuicao>
        <Divider />
        <RenderConditional condition={tipo === 'reserva'}>
          <InformacoesAdicionaisReserva dados={dados} />
        </RenderConditional>
        <RenderConditional condition={tipo === 'cuidadoExtra'}>
          <InformacoesAdicionaisCuidadoExtra dados={dados} />
        </RenderConditional>
      </Card.Content>
    </S.CardStyle>
  );
};

export default CardStatusContribuicao;
