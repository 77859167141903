const createEntry = (label: string, valor: string) => {
  return {
    tpDominio: 'KinshipType',
    label,
    valor,
  };
};

export const TipoParentesco = [
  createEntry('Cônjuge', '1'),
  createEntry('Filho(a)', '2'),
  createEntry('Pais', '3'),
  createEntry('Mãe', '4'),
  createEntry('Companheiro (a)', '5'),
  createEntry('Irmão (ã)', '6'),
  createEntry('Sobrinho (a)', '7'),
  createEntry('Tio (a)', '8'),
  createEntry('Primo (a)', '9'),
  createEntry('Avô (ó)', '10'),
  createEntry('Outros', '11'),
  createEntry('Enteado (a)', '12'),
  createEntry('Neto (a)', '13'),
  createEntry('Bisneto (a)', '14'),
  createEntry('Bisavô (ó)', '15'),
  createEntry('Menor', '16'),
];
