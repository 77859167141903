import { useContext, useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import { ProspeccaoContext } from 'main/features/prospeccao/contexts/ProspeccaoContext';
import { AuthContext } from 'main/features/Auth/contexts/AuthContext';
import {
  IArquivoDownload,
  IDetalhesProspeccao,
} from 'main/features/prospeccao/types/IDetalhesProspeccao';
import * as Api from '../services/api';
import { STATUS_PROSPECCAO } from '../constants/statusProspeccao';

export const useProspeccao = () => {
  const { userRoles } = useContext(AuthContext);
  const { toastError, toastSuccess } = useToast();
  const [prospeccao, setProspeccao] = useState<IDetalhesProspeccao>(
    {} as IDetalhesProspeccao,
  );
  const [loadingProspeccao, setLoadingProspeccao] = useState<boolean>(true);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
  const [loadingUploadProspeccao, setLoadingUploadProspeccao] =
    useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const { setPropeccao } = useContext(ProspeccaoContext);

  const obterDetalhesDaProspeccao = async (
    id: string,
    cpfCnpjCliente: string,
  ) => {
    try {
      setLoadingProspeccao(true);

      const { dados, sucesso } = await Api.obterDetalhesDaProspeccao(
        id,
        cpfCnpjCliente,
      );

      if (sucesso && dados && dados.entidade) {
        setProspeccao(dados.entidade);
        setPropeccao(dados.entidade);
      }
    } catch (exception: unknown) {
      setError(exception);
    } finally {
      setLoadingProspeccao(false);
    }
  };

  const obterArquivoProspeccao = async (
    idArquivoProsposta: number,
    cpfCnpjCliente: string,
  ) => {
    try {
      setLoadingDownload(true);
      const { dados } = await Api.obterArquivoEmBase64(
        idArquivoProsposta,
        cpfCnpjCliente,
      );
      if (!dados)
        toastError('Ocorreu um erro ao buscar o arquivo da prospeccção.');
      const { entidade } = dados;
      return entidade;
    } catch (exception: unknown) {
      setError(exception);
    } finally {
      setLoadingDownload(false);
    }
    return {} as IArquivoDownload;
  };
  const deveExibirBotaoReenvioDPS = (
    detalhesProspeccao: IDetalhesProspeccao,
  ) => {
    return (
      detalhesProspeccao?.codigoTipoSituacaoVigente ===
      STATUS_PROSPECCAO.PRE_CADASTRADA
    );
  };

  const realizarUploadDps = async (
    file: FileList,
    idProspeccao: number,
    cpfCnpjCliente: string,
  ) => {
    try {
      setLoadingUploadProspeccao(true);
      const {
        dados: { sucesso },
      } = await Api.realizarUploadArquivoProspeccao(
        file,
        idProspeccao.toString(),
      );

      if (!sucesso) {
        toastError();
        return false;
      }
      if (sucesso) {
        const {
          dados: { sucesso: atualizacaoSucesso },
        } = await Api.atualizarStatusProspeccao({
          idProspeccao,
          tipoSituacaoProspeccao: STATUS_PROSPECCAO.AGUARDANDO_ANALISE_MEDICA,
          justificativa: '',
          cpfCnpjCliente,
          perfil: userRoles[0],
        });

        if (atualizacaoSucesso) {
          toastSuccess();
          return true;
        }
      }
    } catch (exception: unknown) {
      setError(exception);
      toastError();
    } finally {
      setLoadingUploadProspeccao(false);
    }

    return false;
  };

  return {
    prospeccao,
    loadingProspeccao,
    loadingDownload,
    loadingUploadProspeccao,
    error,
    obterDetalhesDaProspeccao,
    obterArquivoProspeccao,
    deveExibirBotaoReenvioDPS,
    realizarUploadDps,
  };
};
