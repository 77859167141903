import { FormState } from 'reter/features/retencao/types/FormState';

export const initialFormStateFactory: FormState = {
  cpf: '',
  nome: '',
  codigoInstituicao: '',
  nomeInstituicao: '',
  agencia: '',
  digitoAgencia: '',
  operacao: '',
  conta: '',
  digito: '',
};
