import React from 'react';
import { Text, Grid, Divider, Button, Display } from '@cvp/design-system/react';

import { formatarData } from 'main/utils';
import * as ALTERACAO_CONTA_TYPES from 'previdencia/features/AlteracaoFormaDadosPagamento/types/AlteracaoFormaDadosPagamento';

const DadosSolicitacao: React.FC<
  ALTERACAO_CONTA_TYPES.IDadosSolicitacaoProps
> = ({ voltar }) => {
  return (
    <>
      <Divider />
      <Grid.Item xs={1}>
        <Text variant="caption-02" color="text">
          Solicitado em {formatarData(new Date())}, por Agência.
        </Text>
      </Grid.Item>
      <Grid.Item xs={1}>
        <Display>
          <Button variant="outlined" onClick={voltar}>
            Voltar
          </Button>
        </Display>
      </Grid.Item>
    </>
  );
};

export default DadosSolicitacao;
