import {
  Button,
  Card,
  Display,
  Divider,
  Text,
  Tooltip,
} from '@cvp/design-system/react';
import TagStatusCertificado from 'consultaCliente/features/listaCardProduto/components/CardsProduto/CardPrevidencia/TagStatusCertificado';
import {
  ICardProdutoPrevidenciaProps,
  Modalidade,
} from 'consultaCliente/features/listaCardProduto/components/CardsProduto/CardPrevidencia/types/CardPrevidencia.types';
import NomeCertificadoCardItem from 'consultaCliente/features/listaCardProduto/components/CardsProduto/NomeCertificadoCardItem';
import * as S from 'consultaCliente/features/listaCardProduto/components/CardsProduto/styles';
import { MODALIDADES } from 'consultaCliente/features/listaCardProduto/consts/constDescricaoModalidades';
import Icon from 'main/components/Icon';
import { formatarData } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import React from 'react';

export const CardPrevidencia: React.FunctionComponent<
  ICardProdutoPrevidenciaProps
> = ({
  onClick,
  produto,
  pessoa,
  certificadoNumero,
  situacao = '',
  regimeTributario,
  emissao,
  aposentadoria,
}) => {
  const getInfoPorModalidade = (modalidade: Modalidade) => {
    const infoModalidade = MODALIDADES[modalidade];
    if (infoModalidade) {
      return tryGetValueOrDefault([infoModalidade.info], '');
    }
    return '';
  };

  const getCorPorModalidade = (modalidade: Modalidade) => {
    const infoModalidade = MODALIDADES[modalidade];
    if (infoModalidade) {
      return tryGetValueOrDefault([infoModalidade.cor], '');
    }
    return '';
  };
  return (
    <Card>
      <S.Container>
        <Card.Content padding={[1, 2, 0]}>
          <Display alignItems="center">
            <S.Text
              statusLength={situacao?.length === 26 ? 8 : 12}
              title={produto.descricaoProduto}
              status={situacao}
            >
              {produto.descricaoProduto}
            </S.Text>
          </Display>
          <Display justify="end">
            <TagStatusCertificado status={situacao} />
          </Display>
        </Card.Content>
        <Divider />
        <Card.Content padding={[1, 2, 0]}>
          <NomeCertificadoCardItem
            nomeCliente={pessoa.pessoaFisica.nome}
            numCertificado={certificadoNumero}
            classificacaoDoPlano={produto.classificacaoDoPlano}
          />

          <Display
            alignItems="center"
            style={{
              borderRadius: '16px',
              background: getCorPorModalidade(produto.modalidade as Modalidade),
            }}
          >
            <S.IconContainer>
              <Icon name="documentBack" />
            </S.IconContainer>
            <Tooltip
              text={getInfoPorModalidade(produto.modalidade as Modalidade)}
              position="top"
              variant="gray"
            >
              <span style={{ textAlign: 'left', marginLeft: '-6px' }}>
                <Text variant="caption-02">Modalidade / Regime Tributário</Text>

                <Text variant="body03-md">
                  {produto.modalidade} / {regimeTributario} &nbsp;
                  <Icon name="warning" />
                </Text>
              </span>
            </Tooltip>
          </Display>

          <Display alignItems="center">
            <S.IconContainer>
              <Icon name="calendar" />
            </S.IconContainer>
            <span>
              <Text variant="caption-02">Vigência</Text>
              <Text variant="body03-md">
                {formatarData(emissao)} - {formatarData(aposentadoria)}
              </Text>
            </span>
          </Display>
        </Card.Content>
        <Card.Content padding={[1, 4, 0]}>
          <Display justify="space-between">
            <Button onClick={onClick} fullWidth small>
              Detalhes
            </Button>
          </Display>
        </Card.Content>
      </S.Container>
    </Card>
  );
};
