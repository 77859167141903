import styled, { keyframes } from 'styled-components/macro';

const wave = keyframes`
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
`;

const marginBefore = (theme: { spacing: { inline: { [x: string]: any } } }) =>
  `-${theme.spacing.inline['6xs']} -${theme.spacing.inline['2xs']}`;
const margin = (theme: { spacing: { inline: { [x: string]: any } } }) =>
  `-${theme.spacing.inline['6xs']} -${theme.spacing.inline['6xs']}`;
const marginAfter = (theme: { spacing: { inline: { [x: string]: any } } }) =>
  `-${theme.spacing.inline['6xs']} ${theme.spacing.inline['4xs']}`;

export const LoadingDots = styled.span`
  span,
  span:before,
  span:after {
    background: #544b4e;
    border-radius: 50%;
    width: ${({ theme }) => theme.spacing.inline['5xs']};
    height: ${({ theme }) => theme.spacing.inline['5xs']};
    position: absolute;
    top: 50%;
    left: 50%;
  }
  span:before {
    content: '';
    margin: ${({ theme }) => marginBefore(theme)};
    animation: ${wave} 0.5s 0s linear infinite alternate-reverse;
  }
  span {
    margin: ${({ theme }) => margin(theme)};
    animation: ${wave} 0.5s 0.2s linear infinite alternate-reverse;
  }
  span:after {
    content: '';
    margin: ${({ theme }) => marginAfter(theme)};
    animation: ${wave} 0.5s 0.4s linear infinite alternate-reverse;
  }
`;
