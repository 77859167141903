import { CardResumoProduto } from 'seguros/components';
import { Display } from '@cvp/design-system/react';
import ConsultaSinistro from 'seguros/components/ConsultaSinistro/ConsultaSinistro';

const ConsultaDeSinistro = () => {
  return (
    <Display type="display-block">
      <CardResumoProduto />
      <ConsultaSinistro />
    </Display>
  );
};

export default ConsultaDeSinistro;
