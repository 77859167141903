import React from 'react';
import { Text } from '@cvp/design-system/react';
import { Display } from 'main/components/Layout/AppFooter/styles';
import { FooterProps } from 'main/components/Layout/AppFooter/FooterTypes';
import { TEXTOS } from 'main/components/Layout/AppFooter/constants';

const Footer: React.FunctionComponent<FooterProps> = ({ isPublic }) => {
  return (
    <Display className="hide-print">
      <div>{TEXTOS.CANAIS_ATENDIMENTO}</div>
      <div>
        <Text variant="caption-02" align="left">
          {TEXTOS.CENTRAL_SERVICO}
        </Text>
        <Text role="presentation" variant="caption-02" align="left">
          {TEXTOS.APOIO_REDE}
        </Text>
      </div>
      <div>
        <Text variant="caption-02" align="left">
          <b>{TEXTOS.ATENDIMENTO_WEBCHAT}</b>
        </Text>
        <Text role="presentation" variant="caption-02" align="left">
          {TEXTOS.WEBCHAT}
          {isPublic ? TEXTOS.WEBCHAT_PUBLICO : TEXTOS.WEBCHAT_PRIVADO}
        </Text>
      </div>
      <div>
        <Text variant="caption-02" align="left">
          <b>{TEXTOS.MESA_APOIO}</b>
        </Text>
        <Text role="presentation" variant="caption-02" align="left">
          {TEXTOS.ATENDIMENTO_GESTORES}
        </Text>
      </div>
    </Display>
  );
};
export default Footer;
