import { useHistory } from 'react-router';
import { Card, Text, Grid, Button, Display } from '@cvp/design-system/react';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import { CardResumoPrestamista } from 'prestamista/components/CardResumoPrestamista';
import { useAtualizacaoDadosSeguradoPrestamista } from 'prestamista/features/dadosSegurado/hooks/useAtualizacaoDadosSeguradoPrestamista';
import FormAtualizacaoDadosSegurado from 'prestamista/features/dadosSegurado/components/FormAtualizacaoDadosSegurado';
import * as CONSTS from 'prestamista/features/dadosSegurado/constants/constants';
import * as S from 'prestamista/features/dadosSegurado/pages/AtualizacaoDadosSegurado/styles';

const AtualizacaoDadosSegurado = () => {
  const history = useHistory();

  const { formik, handleCepChange, loadingDadosSeguradoAtualizados } =
    useAtualizacaoDadosSeguradoPrestamista();

  return (
    <>
      <CardResumoPrestamista />

      <Display type="display-block">
        <Card>
          <Card.Content padding={[6, 8, 6, 8]}>
            <Text variant="headline-05" color="primary" margin>
              {CONSTS.ATUALIZACAO_DADOS_SEGURADO.TITULO}
            </Text>

            <Text variant="body02-md" margin>
              {CONSTS.ATUALIZACAO_DADOS_SEGURADO.DESCRICAO}
            </Text>

            <FormAtualizacaoDadosSegurado
              formik={formik}
              handleCepChange={handleCepChange}
            />

            <S.GridContainerForm marginTop={20}>
              <Grid.Item xs={1}>
                <Display>
                  <Button
                    data-testid="btnVoltarDadosSegurado"
                    variant="outlined"
                    onClick={() => {
                      history.push({
                        pathname: `/cliente/produtos/prestamista/dados-segurado`,
                        state: {
                          msgAtualizacaoDados: '',
                        },
                      });
                    }}
                  >
                    Voltar
                  </Button>
                  <Button
                    data-testid="btnAtualizarDadosSegurado"
                    onClick={formik.submitForm}
                    loading={loadingDadosSeguradoAtualizados}
                    disabled={checkIfSomeItemsAreTrue([
                      !formik.isValid,
                      loadingDadosSeguradoAtualizados,
                    ])}
                  >
                    Confirmar
                  </Button>
                </Display>
              </Grid.Item>
            </S.GridContainerForm>
          </Card.Content>
        </Card>
      </Display>
    </>
  );
};

export default AtualizacaoDadosSegurado;
