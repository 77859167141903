import React from 'react';
import { Text } from '@cvp/design-system/react';

const TextoInformativoSemProdutos: React.FunctionComponent = () => (
  <>
    <Text>Nenhum produto ativo localizado para este cliente.</Text>
    <Text>
      Informamos que se o produto foi adquirido recentemente ele será
      disponibilizado em breve.
    </Text>
  </>
);

export default TextoInformativoSemProdutos;
