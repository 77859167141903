import { useEffect, useState } from 'react';
import { Button, Card, Display, Text } from '@cvp/design-system/react';

import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import Modal from 'main/components/Modal';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import TabelaSolicitacoesResgate from 'previdencia/features/CancelamentoSolicitacaoResgate/components/TabelaSolicitacoesResgate';
import useCancelamentoSolicitacaoResgate from 'previdencia/features/CancelamentoSolicitacaoResgate/hooks/useCancelamentoSolicitacaoResgate';
import useCancelamentoHistoricoResgate from 'previdencia/features/CancelamentoSolicitacaoResgate/hooks/useCancelamentoHistoricoResgate';
import { IResponseCancelamentoResgate } from 'previdencia/features/CancelamentoSolicitacaoResgate/types/ResponseCancelamentoSolicitacaoResgate';
import * as CONSTS from 'previdencia/features/CancelamentoSolicitacaoResgate/constants/constants';

const CancelamentoSolicitacaoResgate = (): React.ReactElement => {
  const { navigateTo } = usePrevNavigation();

  const { clearFeatureData } = usePrevidenciaContext();

  const [openModal, setOpenModal] = useState(false);

  const { response, fetchData, loading } = useCancelamentoSolicitacaoResgate();

  const { aviso, indicadorCancelado } = tryGetValueOrDefault(
    [response?.entidade?.[0]],
    {} as IResponseCancelamentoResgate,
  );

  const {
    response: listaHistoricoResgate,
    loading: recuperandoHistoricoResgate,
    fetchData: recuperarHistoricoSolicitacaoResgate,
  } = useCancelamentoHistoricoResgate();

  const handleCloseModalAvisoCancelamento = (): void => setOpenModal(false);

  const handleOpenModalAvisoCancelamento = (): void => {
    if (indicadorCancelado) {
      setOpenModal(true);
      recuperarHistoricoSolicitacaoResgate();
    }
  };

  useEffect(handleOpenModalAvisoCancelamento, [response]);

  useEffect(() => {
    clearFeatureData();
  }, []);

  if (recuperandoHistoricoResgate) {
    return <SkeletonLoading blocks={1} />;
  }

  return (
    <Display type="display-block">
      <PrevidenciaResumo />

      <FeatureAuthorizer
        requiredPermissions={[
          PREV_PERMISSIONS.HISTORICO_E_CANCELAMENTO_DE_RESGATE,
        ]}
        requiredRoles={[
          ...PRINCIPAL_USERS,
          USER_PROFILES.ANALISTA_CONSULTA,
          USER_PROFILES.ANALISTA_SAIDA,
          USER_PROFILES.ANALISTA_ENTRADA,
        ]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Text variant="headline-05" color="primary" margin>
              {CONSTS.TEXTOS_CANCELAMENTO.TITULO}
            </Text>
            <Text variant="body02-sm" color="text" margin>
              {CONSTS.TEXTOS_CANCELAMENTO.DESCRICAO}
            </Text>

            <TabelaSolicitacoesResgate
              data={listaHistoricoResgate?.entidade?.listaDadosHistorico}
              onClick={(numeroResgate: string): void => {
                fetchData({ numeroResgate });
              }}
              loading={loading}
            />

            <Button
              variant="outlined"
              onClick={() => navigateTo('dados-plano')}
            >
              Voltar
            </Button>
          </Card.Content>
        </Card>
      </FeatureAuthorizer>

      <Modal open={openModal} onClose={handleCloseModalAvisoCancelamento}>
        <Text variant="body02-md">{aviso}</Text>
      </Modal>
    </Display>
  );
};

export default CancelamentoSolicitacaoResgate;
