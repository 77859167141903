import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import {
  FieldArray,
  Form,
  Field,
  FieldProps,
  FieldArrayRenderProps,
  useFormikContext,
} from 'formik';
import {
  Display,
  Disclaimer,
  Grid,
  TextField,
  Button,
  Divider,
  Switch,
  Hidden,
  Text,
  Select,
} from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import masks from 'main/utils/masks';
import { Email, Telefone } from '../types/Cliente';
import * as S from './DetalheTarefaCards/styles';
import { useEdicaoContatos } from '../hooks/useEdicaoContatos';
import { DetalhesPortabilidade } from '../types/DetalhesPortabilidade';
import { disableAllContacts } from '../utils/edicaoContatosUtils';

type FormValues = {
  emails: Email[];
  telefones: Telefone[];
};

type FormEdicaoContatosProps = {
  dadosPortabilidade: DetalhesPortabilidade;
};

const FormEdicaoContatos: React.FC<FormEdicaoContatosProps> = ({
  dadosPortabilidade,
}) => {
  const [phoneRemoved, setPhoneRemoved] = useState<boolean>(false);
  const [emailRemoved, setEmailRemoved] = useState<boolean>(false);
  const { validateEmail, validatePhone, validateTipoTelefone } =
    useEdicaoContatos(dadosPortabilidade);
  const formik = useFormikContext<FormValues>();
  const history = useHistory();

  const { values, setFieldValue, handleChange, setValues, handleSubmit } =
    formik;

  const handleSwitchTelefone = () => {
    const newValues = disableAllContacts(values.telefones);
    setValues({ telefones: newValues, emails: values.emails });
  };

  const handleSwitchEmail = () => {
    const newEmails = disableAllContacts(values.emails);
    setValues({ telefones: values.telefones, emails: newEmails });
  };

  const removeTelefone = (
    arrayHelpers: FieldArrayRenderProps,
    index: number,
  ) => {
    arrayHelpers.replace(index, {
      ...values.telefones[index],
      ativo: false,
      principal: false,
    });
    setPhoneRemoved(true);
  };

  const removeEmail = (arrayHelpers: FieldArrayRenderProps, index: number) => {
    arrayHelpers.replace(index, {
      ...values.emails[index],
      ativo: false,
      principal: false,
    });
    setEmailRemoved(true);
  };

  const existeTelefonePrincipal = () => {
    return values.telefones.find(x => x.principal);
  };

  const existeEmailPrincipal = () => {
    return values.emails.find(x => x.principal);
  };

  const habilitarTelefoneComoPrincipal = () => {
    if (
      values.telefones.filter(x => x.ativo).length === 1 &&
      !existeTelefonePrincipal()
    ) {
      const newValues = values;
      const index = newValues.telefones.findIndex(x => x.ativo);
      newValues.telefones[index].principal = true;

      setValues(newValues);
      setPhoneRemoved(false);
    }
  };

  const habilitarEmailComoPrincipal = () => {
    if (
      values.emails.filter(x => x.ativo).length === 1 &&
      !existeEmailPrincipal()
    ) {
      const newValues = values;
      const index = newValues.emails.findIndex(x => x.ativo);
      newValues.emails[index].principal = true;

      setValues(newValues);
      setEmailRemoved(false);
    }
  };
  useEffect(() => {
    if (phoneRemoved) habilitarTelefoneComoPrincipal();
    if (emailRemoved) habilitarEmailComoPrincipal();
  }, [phoneRemoved, emailRemoved]);

  const callSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (!existeEmailPrincipal() || !existeTelefonePrincipal()) return;

    handleSubmit(e);
  };

  return (
    <Form data-testid="alteracao-email" onSubmit={callSubmit}>
      <div>
        <Text>Telefones</Text>
        <Divider />
      </div>
      <S.Content id="section-telefones">
        <FieldArray
          name="telefones"
          render={arrayHelpers => (
            <>
              {values.telefones &&
                values.telefones.map((item, index) => {
                  return (
                    <div>
                      {item.ativo && (
                        <Grid>
                          <Grid.Item xs={1} lg={1 / 5}>
                            <Field
                              name={`telefones[${index}].numeroTelefone`}
                              validate={validatePhone}
                            >
                              {({ field, meta }: FieldProps) => {
                                return (
                                  <TextField
                                    {...field}
                                    label="Nº do Telefone"
                                    error={meta.touched && meta.error}
                                    errorMessage={meta.touched && meta.error}
                                    onChange={({
                                      target: { value },
                                    }: React.ChangeEvent<HTMLInputElement>) => {
                                      setFieldValue(
                                        `telefones[${index}].numeroTelefone`,
                                        masks.phone.unmask(value),
                                      );
                                    }}
                                    value={masks.phone.mask(
                                      item.numeroTelefone,
                                    )}
                                  />
                                );
                              }}
                            </Field>
                          </Grid.Item>

                          <Grid.Item xs={1} lg={1 / 5}>
                            <Field
                              name={`telefones[${index}].tipoTelefone`}
                              validate={validateTipoTelefone}
                            >
                              {({ field, meta }: FieldProps) => {
                                return (
                                  <Select
                                    {...field}
                                    label="Tipo"
                                    error={meta.touched && meta.error}
                                    errorMessage={meta.touched && meta.error}
                                    placeholder="Escolha uma opção"
                                    onChange={({
                                      target: { value },
                                    }: React.ChangeEvent<HTMLInputElement>) => {
                                      setFieldValue(
                                        `telefones[${index}].tipoTelefone`,
                                        value,
                                      );
                                    }}
                                  >
                                    <Select.Item
                                      value="Celular"
                                      text="Celular"
                                      selected={
                                        values.telefones[index].tipoTelefone ===
                                        'Celular'
                                      }
                                    />
                                    <Select.Item
                                      value="Comercial"
                                      text="Comercial"
                                      selected={
                                        values.telefones[index].tipoTelefone ===
                                        'Comercial'
                                      }
                                    />
                                    <Select.Item
                                      value="Residencial"
                                      text="Residencial"
                                      selected={
                                        values.telefones[index].tipoTelefone ===
                                        'Residencial'
                                      }
                                    />
                                  </Select>
                                );
                              }}
                            </Field>
                          </Grid.Item>
                          <Grid.Item xs={1 / 2} lg={1 / 5}>
                            <p>Principal</p>

                            <Field name={`telefones[${index}].principal`}>
                              {() => (
                                <S.ActionsButton>
                                  <Switch
                                    controlled
                                    onChange={() => {
                                      handleSwitchTelefone();
                                      setFieldValue(
                                        `telefones[${index}].principal`,
                                        !values.telefones[index].principal,
                                      );
                                    }}
                                    checked={values.telefones[index].principal}
                                  />
                                </S.ActionsButton>
                              )}
                            </Field>
                          </Grid.Item>
                          {values.telefones.filter(x => x.ativo).length > 1 && (
                            <Grid.Item xs={1 / 2} lg={1 / 6}>
                              <p>Remover</p>
                              <Field name={`telefones[${index}].ativo`}>
                                {() => {
                                  return (
                                    <S.ActionsButton>
                                      <S.IconButton
                                        type="button"
                                        small
                                        variant="outlined"
                                        onClick={() =>
                                          removeTelefone(arrayHelpers, index)
                                        }
                                      >
                                        <Icon name="trash" />
                                      </S.IconButton>
                                    </S.ActionsButton>
                                  );
                                }}
                              </Field>
                            </Grid.Item>
                          )}
                        </Grid>
                      )}

                      <Hidden only={['md', 'lg', 'xl']}>
                        <Divider />
                      </Hidden>
                    </div>
                  );
                })}
              <Display>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    arrayHelpers.push({
                      ativo: true,
                      tipoTelefone: '',
                      principal: false,
                      dataHoraAtualizacao: '',
                      numeroTelefone: '',
                    });
                  }}
                >
                  Novo telefone
                </Button>
              </Display>
              {!existeTelefonePrincipal() && (
                <S.Disclaimer variant="error" color="error">
                  <Disclaimer.Content
                    icon={<Icon name="information" />}
                    text=" Selecione um telefone como principal!"
                  />
                </S.Disclaimer>
              )}
            </>
          )}
        />
      </S.Content>
      <div>
        <Text>E-mails</Text>
        <Divider />
      </div>
      <S.Content id="section-emails">
        <FieldArray
          name="emails"
          render={arrayHelpers => (
            <>
              {values.emails &&
                values.emails.map((item, index) => {
                  return (
                    <div>
                      {item.ativo && (
                        <Grid>
                          <Grid.Item xs={1} lg={2 / 5}>
                            <Field
                              name={`emails[${index}].enderecoEmail`}
                              validate={validateEmail}
                            >
                              {({ field, meta }: FieldProps) => {
                                return (
                                  <TextField
                                    {...field}
                                    label="E-mail"
                                    disabled={!values.emails[index].ativo}
                                    error={meta.touched && meta.error}
                                    errorMessage={meta.touched && meta.error}
                                    onChange={handleChange}
                                    value={item.enderecoEmail}
                                  />
                                );
                              }}
                            </Field>
                          </Grid.Item>

                          <Grid.Item xs={1 / 2} lg={1 / 5}>
                            <p>Principal</p>

                            <Field name={`emails[${index}].principal`}>
                              {() => (
                                <S.ActionsButton>
                                  <Switch
                                    controlled
                                    onChange={() => {
                                      handleSwitchEmail();
                                      setFieldValue(
                                        `emails[${index}].principal`,
                                        !values.emails[index].principal,
                                      );
                                    }}
                                    checked={values.emails[index].principal}
                                  />
                                </S.ActionsButton>
                              )}
                            </Field>
                          </Grid.Item>
                          {values.emails.filter(x => x.ativo).length > 1 && (
                            <Grid.Item xs={1 / 2} lg={1 / 6}>
                              <p>Remover</p>
                              <Field name={`emails[${index}].ativo`}>
                                {() => (
                                  <S.ActionsButton>
                                    <S.IconButton
                                      small
                                      variant="outlined"
                                      type="button"
                                      onClick={() =>
                                        removeEmail(arrayHelpers, index)
                                      }
                                    >
                                      <Icon name="trash" />
                                    </S.IconButton>
                                  </S.ActionsButton>
                                )}
                              </Field>
                            </Grid.Item>
                          )}
                        </Grid>
                      )}

                      <Hidden only={['md', 'lg', 'xl']}>
                        <Divider />
                      </Hidden>
                    </div>
                  );
                })}
              <Display>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    arrayHelpers.push({
                      ativo: true,
                      enderecoEmail: '',
                      principal: false,
                      dataHoraAtualizacao: '',
                    });
                  }}
                >
                  Novo e-mail
                </Button>
              </Display>
              {!existeEmailPrincipal() && (
                <S.Disclaimer variant="error" color="error">
                  <Disclaimer.Content
                    icon={<Icon name="information" />}
                    text=" Selecione um e-mail como principal!"
                  />
                </S.Disclaimer>
              )}
            </>
          )}
        />
      </S.Content>
      <Display>
        <Divider />
        <Button variant="primary" type="submit">
          Salvar alterações
        </Button>
        <Button
          variant="outlined"
          type="button"
          onClick={() => history.goBack()}
        >
          Cancelar
        </Button>
      </Display>
    </Form>
  );
};

export default FormEdicaoContatos;
