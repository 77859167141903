import React from 'react';
import RegistrarOcorrenciaProvider from 'registroOcorrenciaASC/features/registrarOcorrencia/contexts/RegistrarOcorrenciaContext';
import CardsRegistroOcorrencia from 'registroOcorrenciaASC/features/registrarOcorrencia/components/CardsRegistroOcorrencia';

const RegistrarOcorrencia: React.FC = () => {
  return (
    <RegistrarOcorrenciaProvider>
      <CardsRegistroOcorrencia />
    </RegistrarOcorrenciaProvider>
  );
};

export default RegistrarOcorrencia;
