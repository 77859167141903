import React, { useState, useEffect, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import BottomNavLoader from 'main/components/Menu/BottomNav/BottomNavLoader';
import * as S from './styles';
import { IMenuItem, IMenuSubItem } from '../../Sidebar/types';

const itemStatusClass = (current: string, activeSubItems: string) => {
  if (activeSubItems && activeSubItems !== current) return 'hide';
  if (activeSubItems === '') return 'show';
  return '';
};

type ItemProps = {
  activeSubItems: string;
  setActiveSubItems(item: string): void;
  item: IMenuItem;
};

const Item: React.FunctionComponent<ItemProps> = ({
  item,
  activeSubItems,
  setActiveSubItems,
}) => {
  const itemType = (() => {
    if (item.subItems) return 'withSubitems';
    if (item.outside) return 'withPathOutside';
    return 'withPathInside';
  })();
  const showSubItems = activeSubItems === item.label;
  const itemHeight = 47;
  const openHeight = item.subItems
    ? item.subItems.length * itemHeight + itemHeight
    : itemHeight;

  return (
    <S.Item
      key={item.label}
      className={itemStatusClass(item.label, activeSubItems)}
      style={{ height: openHeight }}
    >
      {itemType === 'withSubitems' && (
        <>
          <button
            className={`${
              showSubItems ? 'open' : 'close'
            } item-button with-subitems`}
            type="button"
            onClick={() =>
              setActiveSubItems(activeSubItems === item.label ? '' : item.label)
            }
          >
            {item.label}
            <i
              className={`icon ${
                showSubItems ? 'icon-menos' : 'icon-adicionar'
              }`}
            />
          </button>
          <S.ListSubItems>
            {(item.subItems ?? []).map((subitem: IMenuSubItem) => (
              <li key={subitem.label}>
                <Link className="item-button make-click" to={subitem.path}>
                  <>
                    {subitem.icon && <i className={`icon ${subitem.icon}`} />}
                    {subitem.label}
                  </>
                </Link>
              </li>
            ))}
          </S.ListSubItems>
        </>
      )}
      {itemType === 'withPathInside' && (
        <Link className="item-button make-click" to={item.path ?? '#'}>
          {item.label}
        </Link>
      )}
      {itemType === 'withPathOutside' && (
        <a
          className="item-button make-click"
          href={item.path}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.label}
        </a>
      )}
    </S.Item>
  );
};

type BottomNavMenuProps = {
  loading?: boolean;
  items: IMenuItem[];
  type: string;
  visibility?: boolean;
  firstItem?: ReactNode;
};

const BottomNavMenu: React.FunctionComponent<BottomNavMenuProps> = ({
  loading,
  items,
  type,
  visibility,
  firstItem,
}) => {
  const [activeSubItems, setActiveSubItems] = useState('');

  // Reseta menu ao fechar
  useEffect(() => {
    // Fecha subitem aberto se houver, após fechar o menu
    if (activeSubItems !== '' && !visibility) {
      /* Espera a transição de slide terminar
       * para resetar */
      setTimeout(() => setActiveSubItems(''), 300);
    }
  }, [visibility]);

  return (
    <S.ListItems className="make-section">
      {firstItem && (
        <S.Item className={itemStatusClass('Back Button', activeSubItems)}>
          {firstItem}
        </S.Item>
      )}
      {loading ? (
        <BottomNavLoader />
      ) : (
        (items ?? []).map((item: IMenuItem) => (
          <Item
            key={item.label}
            item={item}
            activeSubItems={activeSubItems}
            setActiveSubItems={setActiveSubItems}
          />
        ))
      )}
    </S.ListItems>
  );
};

BottomNavMenu.defaultProps = {
  loading: false,
  visibility: false,
  firstItem: <></>,
};

export default BottomNavMenu;
