import styled from 'styled-components/macro';
import {
  Card as CardDS,
  CardImage as CardImageDS,
} from '@cvp/design-system/react';

export const Card = styled(CardDS)(({ ...rest }) => ({
  ...rest,
}));

Card.Content = styled(CardDS.Content)(({ ...rest }) => ({
  ...rest,
}));

Card.Actions = styled(CardDS.Actions)(({ ...rest }) => ({
  ...rest,
}));

Card.Button = styled(CardDS.Button)(({ ...rest }) => ({
  ...rest,
}));

Card.Image = styled(CardImageDS)(({ ...rest }) => ({
  ...rest,
}));

export default Card;
