import { basePath } from 'config/httpRequest';

export const obterUrlComBasePAth = (nomePeco: string): string => {
  return `${basePath}${nomePeco}`;
};

export const obterEndpointSegurosVida = (): string =>
  obterUrlComBasePAth('PECO_ConsultarSegurosPorCpf');

export const obterEndpointEnviarEmailVida = (): string =>
  '/PortalEconomiario/PECO_EnviarEmailVida';
