import { usePeco } from 'main/hooks/usePeco';
import { toastError, toastSuccess } from 'main/hooks/useToast';
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { PECOS_RETENCAO_PORTABILIDADE } from 'portabilidade/config/endpoints';
import { getAtualizacaoPortabilidadePayload } from '../factories/payloadAtualizacaoPortabilidade';
import { AtualizacaoPortabilidade } from '../types/AtualizacaoPortabilidade';

const obterPayloadAtualizado = (dados: AtualizacaoPortabilidade) => {
  const payload = getAtualizacaoPortabilidadePayload();
  payload.dadosCliente.emails =
    tryGetValueOrDefault([dados.dadosCliente.emails], []).length > 0
      ? dados.dadosCliente.emails
      : payload.dadosCliente.emails;
  payload.dadosCliente.telefones =
    (dados.dadosCliente.telefones || []).length > 0
      ? dados.dadosCliente.telefones
      : payload.dadosCliente.telefones;
  payload.numPortabilidade = dados.numPortabilidade;
  payload.acao = dados.acao;
  return payload;
};
export const useAtualizarDadosRetencao = () => {
  const { fetchData, loading, error, invalidateCache } = usePeco<
    AtualizacaoPortabilidade,
    boolean
  >({
    api: {
      operationPath: PECOS_RETENCAO_PORTABILIDADE.registrarPortabilidade,
    },
  });
  const atualizarDados = async (
    dados: AtualizacaoPortabilidade,
  ): Promise<boolean> => {
    try {
      const payload = obterPayloadAtualizado(dados);

      const response = await fetchData(payload);

      const { sucessoBFF, mensagens } =
        response ?? ({} as IHandleReponseResult<boolean>);
      if (sucessoBFF) {
        const mensagemServico = (mensagens ?? [])[0].descricao;
        await invalidateCache(
          PECOS_RETENCAO_PORTABILIDADE.detalhesPortabilidade,
        );
        toastSuccess(mensagemServico);
        return sucessoBFF;
      }

      toastError();
    } catch (requestError: any) {
      toastError();
    }
    return false;
  };
  return {
    atualizarDados,
    loading,
    error,
  };
};
