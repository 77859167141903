import { api, IApiResponse } from 'main/services';
import { obterEndpointDadosBeneficioProtecao } from 'previdencia/config/endpoints';
import { ResponseDadosBeneficioProtecaoCompleto } from 'previdencia/features/AlterarValorContribuicao/types/ResponsesAlterarValorContribuicao';
import { DefaultValue } from 'previdencia/features/AlterarValorContribuicao/types/enum';

export const obterDadosBeneficioProtecao = async (
  numeroCertificado: string,
): Promise<any | undefined> => {
  const { data } = await api.post<
    IApiResponse<ResponseDadosBeneficioProtecaoCompleto>
  >(obterEndpointDadosBeneficioProtecao(), {
    codigoEmpresa: DefaultValue.CODIGO_EMPRESA,
    codigoCertificado: numeroCertificado,
  });

  return data.dados;
};
