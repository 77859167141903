import { api, IApiResponse } from 'main/services';
import {
  obterUrlListarPorCpfCnpj,
  obterUrlListarProdutos,
} from 'main/features/prospeccao/config/endpoints';
import { IProspeccao } from 'main/features/prospeccao/types/IProspeccao';
import { IProduto } from 'main/features/prospeccao/types/Produto';
import { mapListProspeccao } from 'main/features/prospeccao/utils/mapListProspeccaoResult';

export const obterProdutos = async () => {
  const { data } = await api.post<IApiResponse<IProduto[]>>(
    obterUrlListarProdutos(),
  );

  return data;
};

export const obterListaPorCpfCpnj = async (cpfCnpj: string) => {
  const { data } = await api.post<IApiResponse<IProspeccao[]>>(
    obterUrlListarPorCpfCnpj(),
    {
      cpfCnpjCliente: cpfCnpj,
    },
  );

  return mapListProspeccao(data);
};
