import styled from 'styled-components/macro';
import { Button } from '@cvp/design-system/react';

export const AlterarStyler = styled(Button)(({ theme }) => ({
  width: '100%',
  [theme.breakpoint.md()]: {
    width: '46px',
    height: '46px',
  },
}));

export const Container = styled.div(({ theme }) => ({
  padding: '20px',
  justifyContent: 'end',
  gap: '50px',

  [theme.breakpoint.md()]: {
    display: 'flex',
  },
}));
