export const textosAtivarSuspender = {
  ativacaoInforme: 'Você  ativou apenas a contribuição de renda.',
  suspensaoInforme: 'Você suspendeu a contribuição de renda.',
  confirmacaoSuspensao: 'Veja abaixo a confirmação da suspensão.',
  cancelouCuidadoExtra: 'Você cancelou o Cuidado Extra',
  confirmacaoCancelamento: 'Veja abaixo a confirmacao do cancelamento',
  comprovanteAtivacao: 'MREACONTRD',
  comprovanteSuspensao: 'MSUSCONTRD',
  comprovanteCancelamentoRI: 'MSUSCONTRS',
};
