import React, { useEffect } from 'react';
import useFieldLink from 'main/hooks/useFieldLink';
import * as VGBL_MASKS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/utils/TransferenciaVgblMasks';
import * as CONSTS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/constants/constants';
import * as PROPS_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblComponentProps';
import * as TRANSF_VGBL_STYLES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/styles';

const InputDistribuicaoValorAplicacao: React.FC<
  PROPS_TYPES.InputDistribuicaoValorAplicacaoProps
> = ({ inputItem, handleInput, somaPercentualAplicacaoFundos }) => {
  const [valorLink] = useFieldLink('');

  const valorInput = valorLink.get().value;

  const limparInputSeFundoNaoSelecionado = (): void => {
    if (!inputItem.selecionado) {
      valorLink.set({
        value: '',
        isValid: true,
      });
    }
  };

  const exibirErroAplicacaoAcimaDoPermitido = (): void => {
    if (somaPercentualAplicacaoFundos > CONSTS.VALOR_PORCENTAGEM.MAXIMO) {
      valorLink.set({
        value: valorLink.get().value,
        isValid: false,
        errorMsg: CONSTS.MSG_ERRO_MAXIMO_APLICACAO,
      });

      limparInputSeFundoNaoSelecionado();
    }
  };

  useEffect(() => {
    handleInput({ codigoFundo: inputItem.codigoFundo, valorInput });

    limparInputSeFundoNaoSelecionado();

    exibirErroAplicacaoAcimaDoPermitido();
  }, [valorInput, inputItem.selecionado, inputItem.percentualDistribuicao]);

  return (
    <TRANSF_VGBL_STYLES.InputTableTransferenciaInterna
      link={valorLink}
      inputMask={VGBL_MASKS.percentageDecimalInput}
      disabled={!inputItem.selecionado}
      maxLength="6"
      placeholder="0,00"
    />
  );
};

export default React.memo(InputDistribuicaoValorAplicacao);
