import { useState } from 'react';
import { usePeco } from 'main/hooks/usePeco';
import { parseDateStringToDate } from 'main/utils';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { PECOS_PRESTAMISTA } from 'contratosPrestamista/config/endpoints';
import { useContratosPrestamistaContext } from 'contratosPrestamista/contexts/ContratosPrestamistaContext';
import { IRequestListarStatusProposta } from '../types/IRequestListarStatusProposta';
import {
  IResponseListarStatusPropostaDps,
  IStatusPropostaDpsAgrupado,
} from '../types/IResponseListarStatusPropostaDps';
import { agruparPorCnpj } from '../utils/agruparRetornoPorCnpj';

export const usePecoListarStatusProposta = () => {
  const { featureData } =
    useContratosPrestamistaContext<IRequestListarStatusProposta>();

  const [loadingListarStatusProposta, setLoadingListarStatusProposta] =
    useState(false);
  const [resultadoAgrupado, setResultadoAgrupado] =
    useState<IStatusPropostaDpsAgrupado[]>();
  const { fetchData: fetchDataListarStatusProposta } = usePeco<
    IRequestListarStatusProposta,
    IResponseListarStatusPropostaDps[]
  >({
    api: {
      operationPath: PECOS_PRESTAMISTA.ListarStatusPropostaDps,
    },
  });

  const obterDadosETransformarRetorno = async (
    entrada: IRequestListarStatusProposta,
  ) => {
    setLoadingListarStatusProposta(true);
    const result = await fetchDataListarStatusProposta({
      dataInicial: parseDateStringToDate(
        tryGetValueOrDefault(
          [entrada?.dataInicial, featureData?.dataInicial],
          new Date('2000-01-01').toISOString(),
        ),
      ),
      dataFinal: parseDateStringToDate(
        tryGetValueOrDefault(
          [entrada?.dataFinal, featureData?.dataFinal],
          new Date().toISOString(),
        ),
      ),
      cpfCnpj: tryGetValueOrDefault(
        [entrada?.cpfCnpj, featureData?.cpfCnpj],
        '',
      ),
      numeroContrato: tryGetValueOrDefault(
        [entrada?.numeroContrato, featureData?.numeroContrato],
        '',
      ),
    });
    if (
      checkIfAllItemsAreTrue([
        !!result?.sucessoGI,
        !!result?.sucessoBFF,
        tryGetValueOrDefault([result?.entidade], []).length > 0,
      ])
    ) {
      const resultParsed = agruparPorCnpj(
        tryGetValueOrDefault([result?.entidade], []),
      );
      setResultadoAgrupado(resultParsed);
      setLoadingListarStatusProposta(false);
      return resultParsed;
    }
    setLoadingListarStatusProposta(false);
    return [] as IStatusPropostaDpsAgrupado[];
  };
  return {
    loadingListarStatusProposta,
    resultadoAgrupado,
    obterDadosETransformarRetorno,
  };
};
