/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Display, Text, Card, StepBar } from '@cvp/design-system/react';
import { useToast } from 'main/hooks/useToast';
import { ISecaoItem } from 'main/features/prospeccao/types/IFormProspeccao';
import { ProspeccaoContext } from 'main/features/prospeccao/contexts/ProspeccaoContext';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { useHistory } from 'react-router';
import { validate } from 'main/features/Validation/utils/validateRules';
import { MAPPED_FIELDS } from 'main/features/prospeccao/constants/formProspeccao';
import RenderConditional from 'main/components/RenderConditional';
import { useObterProdutos } from 'main/features/prospeccao/features/consulta/hooks/useConsultaCadastro';
import { IProduto } from 'main/features/prospeccao/types/Produto';
import { useProspeccao } from 'main/features/prospeccao/hooks/useProspeccao';
import { createProspeccaoByIdCpfCnpj } from 'main/features/prospeccao/factories/prospeccaoFactory';
import { ModalErroConfirmarCadastroProspeccao } from 'main/features/prospeccao/features/questionario/components/ModalErroConfirmarCadastroProspeccao';
import { useFormularioProspeccao } from 'main/features/prospeccao/features/questionario/hooks/useFormularioProspeccao';
import { FormikFormContext } from 'main/features/prospeccao/features/questionario/components/DynamicForm/FormikFormContext';
import { ModalConfirmarCadastroProspeccao } from 'main/features/prospeccao/features/questionario/components/ModalConfirmarCadastroProspeccao';
import FormularioEscolhaProduto from 'main/features/prospeccao/features/questionario/components/FormularioEscolhaProduto';

export const FormularioProspeccao: React.FC = () => {
  const history = useHistory();
  const { toastError } = useToast();
  const { produtos, loading: loadingProdutos } = useObterProdutos();
  const {
    setLastStep,
    arquivoDps,
    validateDps,
    formProspeccao,
    getRouteListaPreAnalise,
    getDefaultRouteDetails,
  } = useContext(ProspeccaoContext);
  const [currentStepPage, setCurrentStepPage] = useState(0);
  const [currentStepFormulario, setCurrentStepFormulario] = useState(0);
  const [stepsBar, setStepsBar] = useState<string[]>([]);
  const {
    formulario,
    getFormulario,
    loading: loadingFormulario,
    loadingCadastroProspeccao,
    cadastrarProspeccao,
  } = useFormularioProspeccao();
  const { loadingUploadProspeccao, realizarUploadDps } = useProspeccao();
  const [openModalConfirmacao, setOpenModalConfirmacao] =
    useState<boolean>(false);
  const [openModalErro, setOpenModalErro] = useState<boolean>(false);
  const [prospeccaoId, setProspeccaoId] = useState<number>(0);
  const cpfCnpj = formProspeccao[MAPPED_FIELDS.cpfCnpj];

  const formRespondido = formulario;

  const handleCadastrarProspeccao = async () => {
    const arquivo = arquivoDps.get().value;
    const idProspeccaoCadastrada = await cadastrarProspeccao(formRespondido);
    setProspeccaoId(idProspeccaoCadastrada);
    if (idProspeccaoCadastrada > 0) {
      const sucesso = await realizarUploadDps(
        arquivo,
        idProspeccaoCadastrada,
        cpfCnpj.toString(),
      );

      if (sucesso) {
        history.push({
          pathname: getRouteListaPreAnalise(),
          state: { cpfCnpj },
        });
      } else setOpenModalErro(!openModalErro);

      arquivoDps.set({ value: {} as FileList, isValid: false });
    }
  };

  const isLastStep = () => {
    if (!formRespondido?.modeloFormulario?.secoes[currentStepFormulario + 1])
      return true;

    return false;
  };

  const onUpdate = async (values: ISecaoItem, sectionIndex: number) => {
    if (!isLastStep()) {
      formRespondido.modeloFormulario.secoes[sectionIndex] = values;
      setCurrentStepFormulario(prevState => prevState + 1);
    }

    if (isLastStep()) {
      if (validate([validateDps])) {
        formRespondido.modeloFormulario.secoes[sectionIndex] = values;
        setOpenModalConfirmacao(true);
      } else {
        toastError('Por favor, anexe o arquivo DPS');
      }
    }
  };

  const closeModalLastState = () => {
    arquivoDps.set({ value: {} as FileList, isValid: false });

    history.goBack();
  };

  useEffect(() => {
    if (formulario?.modeloFormulario?.secoes?.length > 0) {
      const steps = formulario.modeloFormulario.secoes.map(item => item.titulo);
      setStepsBar(prevState => [...prevState, ...steps]);
    }
    return () => {
      setStepsBar([]);
    };
  }, [formulario]);

  const goBackForm = () => {
    if (currentStepPage > 0 && currentStepFormulario > 0) {
      setCurrentStepFormulario(prevtState => prevtState - 1);
    } else if (currentStepPage === 1 && currentStepFormulario === 0) {
      setCurrentStepPage(0);
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    if (formulario?.modeloFormulario?.secoes?.length > 0) {
      const lastStep = isLastStep();
      setLastStep(lastStep);
    }
  }, [currentStepPage, currentStepFormulario, formulario]);

  const handleEscolhaProduto = (produto?: IProduto) => {
    if (produto?.codigo) {
      setCurrentStepPage(prevState => prevState + 1);
      getFormulario(produto.codigo.toString());
    }
  };

  return (
    <Display type="display-block">
      <Card>
        <RenderConditional
          condition={
            loadingFormulario || loadingCadastroProspeccao || loadingProdutos
          }
          component={<SkeletonLoading lines={4} />}
        />

        <RenderConditional
          condition={
            !loadingProdutos &&
            !loadingFormulario &&
            !loadingCadastroProspeccao &&
            !!formulario
          }
        >
          <Card.Content>
            <RenderConditional condition={currentStepPage === 0}>
              <FormularioEscolhaProduto
                produtos={produtos}
                handleConfirm={handleEscolhaProduto}
                handleClose={() =>
                  history.push('/prestamista/preanalise/cadastro')
                }
              />
            </RenderConditional>

            {formulario?.modeloFormulario?.secoes.map((item, index) => (
              <RenderConditional
                condition={
                  currentStepPage > 0 && currentStepFormulario === index
                }
              >
                <Display>
                  <StepBar
                    disableClickEvent
                    steps={stepsBar}
                    step={currentStepFormulario + 1}
                  />
                </Display>
                <RenderConditional
                  condition={!!formulario?.modeloFormulario?.descricao}
                >
                  <Text variant="headline-05" color="primary" margin>
                    {formulario?.modeloFormulario?.descricao}
                  </Text>
                </RenderConditional>
                <br />
                <FormikFormContext
                  key={item.id}
                  initialValues={formulario?.modeloFormulario?.secoes[index]}
                  sectionIndex={index}
                  onUpdate={onUpdate}
                  goBackForm={goBackForm}
                />
              </RenderConditional>
            ))}
          </Card.Content>
        </RenderConditional>
      </Card>
      {openModalConfirmacao && (
        <ModalConfirmarCadastroProspeccao
          open={openModalConfirmacao}
          handleClose={() => {
            setOpenModalConfirmacao(!openModalConfirmacao);
            closeModalLastState();
          }}
          handleConfirm={handleCadastrarProspeccao}
          loadingCadastroProspeccao={loadingCadastroProspeccao}
          loadingUploadProspeccao={loadingUploadProspeccao}
        />
      )}
      {openModalErro && (
        <ModalErroConfirmarCadastroProspeccao
          open={openModalErro}
          handleClose={() => setOpenModalErro(!openModalErro)}
          handleConfirm={() =>
            getDefaultRouteDetails(
              createProspeccaoByIdCpfCnpj(prospeccaoId, cpfCnpj.toString()),
            )
          }
        />
      )}
    </Display>
  );
};
