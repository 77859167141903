import { useState } from 'react';
import { useQuery } from 'react-query';

import { useToast } from 'main/hooks/useToast';
import * as contasBancariasApi from 'main/hooks/contasBancarias.api';
import { DadosInstituicaoBancaria } from 'main/types/Bancos/DadosInstituicaoBancaria';
import { TipoContaBancaria } from 'main/types/Bancos/TipoContaBancaria';
import { tipoContasBancariasCefMain } from 'main/constants/tipoContasBancariasMain';
import { CODIGO_BANCO_CAIXA } from 'main/utils/formatarNumeroContaCaixa';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';

type useObterContasBancariasReturn = {
  instituicoesBancarias: DadosInstituicaoBancaria[] | [];
  obterTiposContasBancariasMain: (numeroBanco: string) => TipoContaBancaria[];
  alternarExibicaoFormularioDadosBancariosMain: (showForm: boolean) => void;
  exibeFormularioDadosBancariosMain: boolean;
};

const useInstituicoesBancarias = (
  cpf: string,
): useObterContasBancariasReturn => {
  const [
    exibeFormularioDadosBancariosMain,
    setExibeFormularioDadosBancariosMain,
  ] = useState(false);
  const alternarExibicaoFormularioDadosBancariosMain = (showForm: boolean) =>
    setExibeFormularioDadosBancariosMain(showForm);

  const { toastError } = useToast();

  const queryResultInstituicoesBancariasMain = useQuery(
    ['-prev-contas-bancarias', cpf],
    () => contasBancariasApi.obterListaBancos(cpf),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      onError: (error: Error) => toastError(error.message),
    },
  );

  const obterTiposContasBancariasNaoCefMain = (): TipoContaBancaria[] => {
    return [
      {
        codigo: '001',
        descricao: 'Conta Corrente',
      },
      {
        codigo: '013',
        descricao: 'Conta Poupança',
      },
    ];
  };

  const obterTiposContasBancariasCefMain = (): TipoContaBancaria[] => {
    return tipoContasBancariasCefMain;
  };

  const obterTiposContasBancariasMain = (
    numeroBanco: string,
  ): TipoContaBancaria[] => {
    if (numeroBanco === CODIGO_BANCO_CAIXA)
      return obterTiposContasBancariasCefMain();
    return obterTiposContasBancariasNaoCefMain();
  };

  return {
    instituicoesBancarias: queryResultInstituicoesBancariasMain?.data ?? [],
    obterTiposContasBancariasMain,
    alternarExibicaoFormularioDadosBancariosMain,
    exibeFormularioDadosBancariosMain,
  };
};

export default useInstituicoesBancarias;
