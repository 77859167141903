import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Button, Card, Display, Divider, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { QuitacaoAnualDebitosDados } from 'seguros/components/QuitacaoAnualDebitos/QuitacaoAnualDebitosDados';
import { QuitacaoAnualDebitosSkeleton } from 'seguros/components/QuitacaoAnualDebitos/QuitacaoAnualDebitosSkeleton';
import * as S from 'seguros/components/QuitacaoAnualDebitos/styles';
import { QUITACAO_ANUAL } from 'seguros/constants/QuitacaoAnual';
import { useQuitacaoAnualDebitos } from 'seguros/hooks/useQuitacaoAnualDebitos';
import EmailSenderModal from 'main/components/EmailSenderModal';
import { AppContext } from 'main/contexts/AppContext';
import { tipoEmailConstants } from 'main/constants/tipoEmail';

export const QuitacaoAnualDebitos: React.FC = (): React.ReactElement => {
  const history = useHistory();
  const {
    anoConsulta,
    setAnoConsulta,
    listaAnos,
    entidade,
    exibirLoading,
    exibirErro,
    sucesso,
    emitir,
  } = useQuitacaoAnualDebitos();

  const { clienteVida } = useContext(AppContext);
  const objetoEmail = {
    tipoEmail: tipoEmailConstants.DECLARACAO_QUITACAO_ANUAL,
    parametrosEnvio: {
      periodoReferencia: anoConsulta,
      numeroCertificadoBilhete: clienteVida?.numCertificado,
      codigoProduto: clienteVida?.codigoProduto,
    },
  };

  return (
    <Card>
      <Card.Content>
        <Display>
          <Text variant="headline-05" color="primary" margin>
            Declaração de Quitação Anual de Débitos
          </Text>
        </Display>
        <Text variant="caption-02" color="text" margin>
          {QUITACAO_ANUAL.CONFORMIDADE_LEI}
        </Text>
        <Text variant="caption-02" color="text" margin>
          {QUITACAO_ANUAL.DECLARACAO_SUBSTITUI}
        </Text>
        <Text variant="caption-02" color="text" margin>
          {QUITACAO_ANUAL.CERTIFICADOS_CANCELADOS}
        </Text>
        <Text variant="caption-02" color="text" margin>
          {QUITACAO_ANUAL.PENDENCIA_PAGAMENTO}
        </Text>
        <Text variant="caption-02" color="text" margin>
          Para informações ou dúvidas, por favor, acesse o{' '}
          <strong> “Atendimento Online” </strong>
          Clique Aqui ou entre em contato com a nossa Central de Serviços e
          Relacionamento <strong>0800 702 4000</strong>.
        </Text>
        <Display>
          {listaAnos.map(ano => {
            return (
              <S.ButtonAnoConsulta
                key={ano}
                variant={ano === anoConsulta ? 'secondary' : 'outlined'}
                disabled={exibirLoading && ano !== anoConsulta}
                loading={exibirLoading && ano === anoConsulta}
                onClick={() => setAnoConsulta(ano)}
              >
                {ano}
              </S.ButtonAnoConsulta>
            );
          })}
        </Display>
        <RenderConditional condition={exibirErro}>
          <Text align="center" variant="body02-sm" color="text">
            Não há dados para o ano selecionado.
          </Text>
        </RenderConditional>

        <RenderConditional condition={exibirLoading}>
          <QuitacaoAnualDebitosSkeleton />
        </RenderConditional>

        <RenderConditional condition={sucesso}>
          <QuitacaoAnualDebitosDados dados={entidade} />
        </RenderConditional>
        <Divider />
        <br />
        <Display>
          <Button variant="outlined" onClick={history.goBack}>
            Voltar
          </Button>
          <EmailSenderModal
            objetoEmail={objetoEmail}
            disabled={!sucesso}
            seguro
          />
          <Button variant="secondary" onClick={emitir} disabled={!sucesso}>
            Emitir
          </Button>
        </Display>
      </Card.Content>
    </Card>
  );
};
