import styled from 'styled-components/macro';

export const WrapperQuest = styled.div(({ theme: { breakpoint } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  [breakpoint.lg()]: {
    flexDirection: 'row',
  },
}));

export const TextWrapperQuest = styled.div(({ theme: { breakpoint } }) => ({
  maxWidth: 'unset',
  textAlign: 'left',
  [breakpoint.lg()]: {
    textAlign: 'left',
    maxWidth: '480PX',
  },
}));
