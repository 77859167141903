import styled from 'styled-components/macro';

export const Content = styled.div({
  marginTop: 20,
});

export const ContainerForm = styled.div({
  width: '40%',
  marginBottom: 30,
});

export const WraperLabel = styled.label({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginBottom: 16,
});

export const Label = styled.div({
  fontSize: 16,
  color: '#414042',
  marginBottom: 8,
});

export const WrapperInputPorcent = styled.div({
  paddingTop: 12,
  width: 70,
  display: 'flex',
  marginBottom: 12,
  alignItems: 'center',
});

export const InputPorcent = styled.input(({ theme }) => ({
  height: 35,
  width: '100%',
  padding: 10,
  border: `1px solid ${theme.color.neutral['02']}`,
  borderRadius: 3,
  marginRight: 5,
  outline: 'none',

  '&:focus': {
    borderColor: theme.color.brandPrimary.light,
  },
}));

export const WrapperSelectBeneficiario = styled.div({
  marginBottom: 30,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
});

export const ContainerButtonsForm = styled.div({
  display: 'flex',
  gap: '15px',
});
