import React from 'react';
import { Display, Card, Text, Grid, Divider } from '@cvp/design-system/react';
import { LinkFop } from 'main/styles/GlobalStyle';
import {
  obterUrlArquivoCarteiraDiaria,
  obterUrlArquivoPortifolioFundosInvestimentoPF,
  obterUrlArquivoPortifolioFundosInvestimentoPJ,
  obterUrlDoArquivoDesempenhoFundosInvestimento,
  obterUrlImagemGraficoComposicaoCarteiras,
} from 'extranet/config/endpoints';
import InvestidorPF from '../../../img/perfil_investidorPF.png';
import InvestidorPJ from '../../../img/perfil_investidorPJ.png';

export const ListagemFundos: React.FC = () => (
  <Display type="display-block">
    <Card>
      <Card.Content padding={[4, 4, 4]}>
        <Grid>
          <Grid.Item xs={1}>
            <Text
              variant="headline-05"
              color="primary"
              key="fundos-investimentos"
            >
              Fundos de Investimento
            </Text>
          </Grid.Item>
        </Grid>
        <Text variant="body01-sm" margin>
          As Novas Previdências da CAIXA oferecem diversos fundos de
          investimento. Tudo para o seu cliente investir no futuro com mais
          vantagens no presente. Para negociar os fundos com condições
          diferenciadas, não é preciso entrar em contato com a matriz para
          solicitar autorização, o sistema de vendas vai direcionar de forma
          automática o cliente ao fundo mais adequado ao seu perfil. Conheça a
          nova estrutura de fundos de investimento.
          <br />
        </Text>
        <Text variant="body01-sm" color="primary" margin>
          <LinkFop
            variant="text"
            href={obterUrlDoArquivoDesempenhoFundosInvestimento()}
            target="_blank"
          >
            <b>Relatório Mensal de Desempenho dos fundos de investimentos </b>
          </LinkFop>
        </Text>
        <Text variant="body01-sm" color="primary" margin>
          <LinkFop
            variant="text"
            href={obterUrlArquivoCarteiraDiaria()}
            target="_blank"
            rel="noreferrer"
          >
            <b> Carteira diária CVP </b>
          </LinkFop>
        </Text>
        <Text variant="body01-sm" margin>
          Clique nos itens das tabelas para obter a lâmina e regulamentos de
          cada fundo.
        </Text>
        <a
          href={obterUrlArquivoPortifolioFundosInvestimentoPF()}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={InvestidorPF}
            data-testid="InvestidorPF-cvp"
            alt="InvestidorPF"
            width="400"
            style={{ padding: 10 }}
          />
        </a>
        <a
          href={obterUrlArquivoPortifolioFundosInvestimentoPJ()}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={InvestidorPJ}
            data-testid="InvestidorPJ-cvp"
            alt="InvestidorPJ"
            width="400"
            style={{ padding: 10 }}
          />
        </a>
        <Divider />
        <br />
        <img
          src={obterUrlImagemGraficoComposicaoCarteiras()}
          data-testid="Grafico-cvp"
          alt="Grafico Compossição Carteira"
          width="1000"
          style={{ padding: 10 }}
        />
      </Card.Content>
    </Card>
  </Display>
);
