import {
  DataModalConfirmacao,
  OpcoesProtecaoBeneficio,
} from 'previdencia/features/AlterarValorContribuicao/types/InitialStateTypes';

export const initialStateDataModalConfirmacao: DataModalConfirmacao = {
  tipoBeneficio: '',
  descricaoTipoBeneficio: '',
  novoValor: 0,
  periodicidade: '',
  valorCustomizacao: 0,
  prazo: 0,
};

export const initialStateOpcoesProtecaoBeneficio: OpcoesProtecaoBeneficio = {
  peculio: false,
  pensao: false,
};
