import React from 'react';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { IResponseAcaoCoberturas } from '../../types/AcaoCoberturas.types';
import { EnumTipoBeneficios } from '../../types/EnumsStatusAtivacaoSuspensao.types';
import ListaComprovanteCuidadoExtra from '../ListaComprovanteCuidadoExtra';
import ListaComprovanteReserva from '../ListaComprovanteReserva';

type ListaInformacoesComprovanteProps = {
  dadosComprovante: IResponseAcaoCoberturas | undefined;
  status: string;
};

const ListaInformacoesComprovante: React.FC<
  ListaInformacoesComprovanteProps
> = ({ dadosComprovante, status }) => {
  return (
    <>
      <RenderConditional
        condition={
          dadosComprovante?.dadosBeneficio?.tipoBeneficio ===
          EnumTipoBeneficios.RISCO
        }
      >
        <ListaComprovanteCuidadoExtra
          dadosComprovante={dadosComprovante}
          status={status}
        />
      </RenderConditional>
      <RenderConditional
        condition={
          dadosComprovante?.dadosBeneficio?.tipoBeneficio ===
          EnumTipoBeneficios.RISCO
        }
      >
        <ListaComprovanteReserva
          dadosComprovante={dadosComprovante}
          status={status}
        />
      </RenderConditional>
    </>
  );
};

export default ListaInformacoesComprovante;
