import React from 'react';
import { Grid, Text, Display, Button } from '@cvp/design-system/react';
import Calendar from 'main/components/form/Calendar';
import { required } from 'main/features/Validation/validations';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import masks from 'main/utils/masks';
import * as API from 'registroOcorrenciaASC/features/consultarOcorrencia/hooks';
import * as UTILS from 'registroOcorrenciaASC/features/consultarOcorrencia/utils/ConsultarOcorrenciaUtils';
import * as CONSULTA_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/consultarOcorrencia/types/ConsultarOcorrencia';
import * as S from 'registroOcorrenciaASC/features/consultarOcorrencia/components/FormConsultaOcorrencia/styles';

const FormConsultaOcorrencia: React.FC<
  CONSULTA_OCORRENCIA_TYPES.FormConsultaOcorrenciaProps
> = ({
  obterHistoricoSolicitacao,
  setListaHistoricoSolicitacao,
  loadingDadosHistoricoSolicitacao,
}) => {
  const {
    camposFormConsultaPreenchidos,
    limparForm,
    cpfCnpjLink,
    inputDateLink,
  } = API.useRenderizarFormConsultaOcorrencia(setListaHistoricoSolicitacao);

  return (
    <>
      <Grid>
        <Grid.Item xs={1}>
          <Text
            variant="body02-md"
            color="text"
            data-testid="subtituloPeriodoConsulta"
          >
            Informe um período e o CPF/CNPJ do cliente para realizar a consulta:
          </Text>
        </Grid.Item>
        <Grid.Item xs={1} lg={1 / 3}>
          <S.ContainerInputGroup>
            <Calendar
              data-testid="inputCalendarioConsulta"
              placeholder="Selecione uma data"
              range
              link={inputDateLink}
            />
          </S.ContainerInputGroup>
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item xs={1} lg={1 / 3}>
          <S.ContainerInputGroup>
            <S.Input
              data-testid="inputCpfCnpjCliente"
              placeholder="Digite o CPF/CNPJ do cliente"
              label="CPF/CNPJ"
              link={cpfCnpjLink}
              validationRules={[required()]}
              inputMask={{
                mask: value => masks.cpfCnpj.mask(value),
                unmask: value => masks.cpfCnpj.unmask(value),
              }}
            />
          </S.ContainerInputGroup>
        </Grid.Item>
      </Grid>
      <Display>
        <Button
          data-testid="botaoLimparFormularioConsulta"
          variant="outlined"
          onClick={limparForm}
        >
          Limpar
        </Button>
        <Button
          data-testid="botaoConsultarOcorrencia"
          variant="primary"
          disabled={checkIfSomeItemsAreTrue([
            !camposFormConsultaPreenchidos,
            loadingDadosHistoricoSolicitacao,
          ])}
          loading={loadingDadosHistoricoSolicitacao}
          onClick={() => {
            obterHistoricoSolicitacao({
              dataInicio: UTILS.editarData({
                dataOriginal: inputDateLink.get().value.initialDate as Date,
                novaHora: 0,
              }),
              dataFim: UTILS.editarData({
                dataOriginal: inputDateLink.get().value.finalDate as Date,
                novaHora: 23,
                novoMinuto: 59,
                novoSegundo: 59,
              }),
              numeroCpfCnpj: cpfCnpjLink.get().value,
            });
          }}
        >
          Consultar
        </Button>
      </Display>
    </>
  );
};

export default FormConsultaOcorrencia;
