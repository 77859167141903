import { tryGetValueOrDefault } from 'main/utils/conditional';

/**
 * Detecta o tipo MIME de um arquivo com base na assinatura de seus bytes.
 * @param byteArray - Array de bytes que representa o conteúdo do arquivo.
 * @returns O tipo MIME detectado do arquivo.
 */

export const detectMimeType = (byteArray: number[]): string => {
  const signatures: Record<string, number[]> = {
    'image/jpg': [0xff, 0xd8, 0xff],
    'image/jpeg': [0xff, 0xd8, 0xff],
    'image/png': [0x89, 0x50, 0x4e, 0x47],
    'audio/mpeg': [0x49, 0x44, 0x33],
    'audio/x-ms-wma': [
      0x30, 0x26, 0xb2, 0x75, 0x8e, 0x66, 0xcf, 0x11, 0xa6, 0xd9, 0x0, 0xaa,
      0x0, 0x62, 0xce, 0x6c,
    ],
    'audio/aac': [0xff, 0xf1],
    'audio/ogg': [0x4f, 0x67, 0x67, 0x53],
    'video/ogg': [0x4f, 0x67, 0x67, 0x53],
    'application/ogg': [0x4f, 0x67, 0x67, 0x53],
    'audio/wav': [0x52, 0x49, 0x46, 0x46],
    'image/bmp': [0x42, 0x4d],
    'image/gif': [0x47, 0x49, 0x46, 0x38, 0x39, 0x61],
    'application/msword': [0xd0, 0xcf, 0x11, 0xe0],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      0x50, 0x4b, 0x03, 0x04, 0x14, 0x00, 0x06, 0x00,
    ],
    'application/vnd.ms-excel': [0xd0, 0xcf, 0x11, 0xe0],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      0x50, 0x4b, 0x03, 0x04, 0x14, 0x00, 0x06, 0x00,
    ],
    'application/vnd.ms-powerpoint': [0xd0, 0xcf, 0x11, 0xe0],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      [0x50, 0x4b, 0x03, 0x04, 0x14, 0x00, 0x06, 0x00],
    'application/pdf': [0x25, 0x50, 0x44, 0x46],
  };

  const mimeTypeKeys = Object.keys(signatures);

  const matchingMimeType = mimeTypeKeys.find(mimeType =>
    byteArray.every((byte, index) => byte === signatures[mimeType][index]),
  );

  if (matchingMimeType) {
    return matchingMimeType;
  }

  return 'application/pdf';
};

/**
 * Converte uma string em formato base64 para um Blob.
 * @param {string | undefined} data - String em formato base64 a ser convertida.
 * @param {string} mimeType - Tipo MIME do Blob resultante.
 * @returns {Blob} - Um Blob contendo os dados convertidos.
 */
export const converterBase64 = (
  data: string | undefined,
  defaultMimeType = 'application/pdf',
) => {
  const byteCharacters = atob(tryGetValueOrDefault([data], ''));
  const byteArrays = [];
  const sliceSize = 1024;

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const mimeType = detectMimeType(Array.from(byteArrays[0]));

  return new Blob(byteArrays, {
    type: tryGetValueOrDefault([mimeType], defaultMimeType),
  });
};

type DownloadBase64FileParams = {
  blob: Blob;
  fileName: string;
};

/**
 * Realiza o download de um arquivo a partir de um Blob em base64.
 * @param {Object} params - Parâmetros para o download.
 * @param {Blob} params.blob - Blob contendo os dados do arquivo.
 * @param {string} params.fileName - Nome do arquivo para download.
 * @returns {void}
 */
export const downloadBase64File = ({
  blob,
  fileName,
}: DownloadBase64FileParams): void => {
  const blobUrl = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;
  link.style.display = 'none';
  document.body.appendChild(link);

  link.click();

  URL.revokeObjectURL(blobUrl);
};

type OpenBase64FileBrowserParams = {
  blob: Blob;
};

/**
 * Abre um navegador com a visualização de um arquivo em base64.
 * @param {Object} params - Parâmetros para abrir o navegador.
 * @param {Blob} params.blob - Blob contendo os dados do arquivo.
 * @returns {void}
 */
export const openBase64FileBrowser = ({
  blob,
}: OpenBase64FileBrowserParams): void => {
  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl);
};
