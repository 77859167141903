import React, { PropsWithChildren } from 'react';
import { Hidden, Container } from '@cvp/design-system/react';
import Header from 'main/components/Header';
import { Wrapper, Middle, Main } from 'main/components/Menu/Sidebar';
import BottomNav from 'main/components/Layout/AppMenu/Mobile/CustomBottomNav';
import Menu from './AppMenu/Desktop/Sidebar';
import Footer from './AppFooter';

const AppPrivateLayout: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => (
  <Wrapper>
    <Header />
    <Middle>
      <Hidden only={['sm', 'xs']}>
        <Menu />
      </Hidden>
      <Hidden only={['md', 'lg', 'xl']}>
        <BottomNav />
      </Hidden>
      <Main>
        <Container>{children}</Container>
      </Main>
    </Middle>
    <Footer />
  </Wrapper>
);

export default AppPrivateLayout;
