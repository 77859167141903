import React from 'react';
import { Button, Display, Text } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import AvisoConclusaoAssinatura from 'main/components/AssinaturaDocuSign/AvisoConclusaoAssinatura';
import { ModalWithoutCloseButton } from 'main/components/AssinaturaDocuSign/styles';
import RenderConditional from 'main/components/RenderConditional/index';
import { MensagemContatosInvalidos } from 'main/components/AssinaturaDocuSign/MensagemContatosInvalidos';
import { ModalAvisoType } from 'main/components/AssinaturaDocuSign/types/ModalAvisoType';

const ModalAviso: React.FC<ModalAvisoType> = ({
  children,
  titulo,
  textoSolicitacao,
  openModal,
  setOpenModal,
  setModalAssinatura,
  disableButtonDocuSing = false,
}) => {
  return (
    <ModalWithoutCloseButton
      showCloseButton
      show={openModal}
      onClose={() => setOpenModal(false)}
    >
      <Text margin>{titulo}</Text>
      <Display type="display-block">
        <RenderConditional condition={!!textoSolicitacao}>
          <Text variant="caption-02" margin color="success" align="left">
            <Icon name="successCheck" /> {textoSolicitacao}
          </Text>
        </RenderConditional>

        <Text variant="caption-02" margin align="left" color="secondary">
          <Icon name="waiting" /> Para concluir realize a assinatura.
        </Text>
        <AvisoConclusaoAssinatura>{children}</AvisoConclusaoAssinatura>

        <RenderConditional condition={disableButtonDocuSing}>
          <MensagemContatosInvalidos />
        </RenderConditional>

        <Button
          disabled={disableButtonDocuSing}
          variant="primary"
          onClick={() => {
            setOpenModal(false);
            setModalAssinatura(true);
          }}
        >
          Continuar
        </Button>
      </Display>
    </ModalWithoutCloseButton>
  );
};

export default ModalAviso;
