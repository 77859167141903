import React, { useContext } from 'react';
import { Card, Display, Grid, Text, Button } from '@cvp/design-system/react';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import EmailSenderModal from 'main/components/EmailSenderModal';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { AppContext } from 'main/contexts/AppContext';

const GuiaPreenchimento: React.FC = () => {
  const anoImportoRenda = new Date().getFullYear();
  const { goDadosPlano } = usePrevNavigation();
  const { cliente } = useContext(AppContext);

  const objetoEmail = {
    tipoEmail: tipoEmailConstants.GUIA_PREENCHIMENTO_IR,
    parametrosEnvio: {
      numeroCertificado: cliente.numCertificado,
      CpfCnpj: cliente.cpfCnpj,
    },
  };
  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Grid>
            <Grid.Item xs={1}>
              <Text variant="headline-05" color="primary">
                Guia de Preenchimento - Imposto de Renda{'  '}
                {anoImportoRenda}
              </Text>
            </Grid.Item>
            <Grid.Item xs={1}>
              <Text variant="body01-lg" color="primary" margin>
                Quem deve declarar?
              </Text>
              <Text variant="body02-md" color="text" margin>
                Previdência
              </Text>
              <Text variant="body02-sm" color="text">
                1 - Quem tem qualquer produto de previdência e fez regaste em{' '}
                {anoImportoRenda - 1}.
              </Text>
              <Text variant="body02-sm" color="text">
                2 - Quem tem uma previdência PGBL e fez contribuições em{' '}
                {anoImportoRenda - 1} pode declarar esses valores e contar com o
                benefício fiscal.
              </Text>
            </Grid.Item>

            <Grid.Item xs={1}>
              <Text variant="body02-sm" color="text" margin>
                Acesse o
                <a
                  href="https://statics.caixavidaeprevidencia.com.br/areaaberta/Guias/Previdencia/Guia-Informe-Rendimentos-Previdencia.pdf?_gl=1*fr7chy*_ga*MjEzNDQ4MTgzNC4xNjA5Mjc2NDA1*_ga_29H51MJ2DR*MTY0MTgzNzIzOS4xNjMuMS4xNjQxODM3NzU2LjU3#_ga=2.19709374.76255107.1641837240-2134481834.1609276405"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {' '}
                  Guia de Preenchimento{' '}
                </a>
                que a gente preparou para você declarar sua Previdência. Ou
                clique em &apos;Enviar E-mail&apos; para receber o guia por
                E-mail.
              </Text>
            </Grid.Item>
          </Grid>
          <Display>
            <EmailSenderModal objetoEmail={objetoEmail} />
            <Button variant="outlined" onClick={goDadosPlano}>
              Voltar
            </Button>
          </Display>
        </Card.Content>
      </Card>
    </Display>
  );
};

export default GuiaPreenchimento;
