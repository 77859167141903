import styled from 'styled-components/macro';
import {
  Text as TextDS,
  Display as DisplayDS,
  TextField as TextFieldDS,
  Grid,
} from '@cvp/design-system/react';

export const Text = styled(TextDS)`
  margin: 0.8rem 0;
`;

export const TextField = styled(TextFieldDS)`
  & input {
    height: 45px;
  }

  & label {
    margin-bottom: 0.2rem;
  }
`;

export const ContainerConsultarCep = styled(DisplayDS)`
  flex-wrap: wrap;
  align-items: flex-end;
  column-gap: 10px;

  & div:first-child {
    flex: 1;
    margin: 0 0 0 8px;
  }

  & div input {
    height: 45px;
  }

  & button {
    margin: 0;
    height: 45px;
  }
`;

export const GridItem = styled(Grid.Item)`
  padding: 5px 12px 5px 12px;
`;
