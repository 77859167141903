import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Grid, Card, Display, Text, Button } from '@cvp/design-system/react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import RenderConditional from 'main/components/RenderConditional';
import ModalAlertaContatos from 'main/components/AssinaturaDocuSign/ModalAlertaContatos';
import { useObterContatosDefaultPrevidencia } from 'main/hooks/useObterContatosDefaultPrevidencia';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { PREV_REDIRECTS_SCREENS } from 'previdencia/config/redirects';
import { IRedirecionamentoHistoricoSolicitacaoLocation } from 'previdencia/types/IRedirecionamentoHistoricoSolicitacaoLocation';
import { useTransferenciaInternaContext } from 'previdencia/features/TransferenciaInternaEmissaoConjugado/contexts/TransferenciaInternaContext';
import usePlanosTransferenciaInterna from 'previdencia/features/TransferenciaInternaEmissaoConjugado/hooks/usePlanosTransferenciaInterna';
import ResumoTransferenciaInterna from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/ResumoTransferenciaInterna';
import ModalConsentimentoTransferencia from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/Modal/ModalConsentimentoTransferencia';
import ModalInformeDadosOrigem from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/Modal/ModalInformeDadosOrigem';
import { initialModalState } from 'previdencia/features/TransferenciaInternaEmissaoConjugado/factories/initialStateFactory';
import * as CALC_UTILS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/utils/TransferenciaVgblCalculos';
import * as VGBL_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgbl';
import * as CONSTS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/constants/constants';
import * as TRANSF_VGBL_STYLES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/styles';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';

const PlanosTransferenciaInterna: React.FC = () => {
  const history = useHistory();

  const { state } =
    useLocation<IRedirecionamentoHistoricoSolicitacaoLocation>();

  const { goDadosPlano } = usePrevNavigation();

  const [open, setOpen] =
    useState<VGBL_TYPES.ModalPlanosTransferenciaInterna>(initialModalState);

  const {
    salvarDadosOrigem,
    salvarSaldoTotalFundosAtuais,
    definirContribuicaoRendaMensal,
    confirmarAquisicao,
    salvarNumeroSolicitacao,
  } = useTransferenciaInternaContext();

  const {
    data: responseObterContatosDefault,
    isLoading: loadingObterContatos,
    isFetched: recuperacaoEmailExecutada,
  } = useObterContatosDefaultPrevidencia();

  const { dadosOrigem, loadingDadosOrigem, obterDadosOrigem } =
    usePlanosTransferenciaInterna();

  const handleModalConsentimento = (): void => {
    setOpen(prevState => ({
      ...prevState,
      modalConsentimentoTransferencia:
        !prevState.modalConsentimentoTransferencia,
    }));
  };

  const transformarDadosTabelaDadosOrigem = () => {
    return dadosOrigem?.entidade?.cuidadosExtras?.map(item => ({
      ...item,
      valorContribuicao: tryGetMonetaryValueOrDefault(item.valorContribuicao),
      valorBeneficio: tryGetMonetaryValueOrDefault(item.valorBeneficio),
    }));
  };

  const prosseguirContribuicaoRendaMensal = (): void => {
    handleModalConsentimento();
    definirContribuicaoRendaMensal();
  };

  const salvarDadosOrigemNoContexto = (): void => {
    if (dadosOrigem) {
      salvarDadosOrigem(dadosOrigem?.entidade);
      salvarSaldoTotalFundosAtuais(
        CALC_UTILS.somarSaldoFundosAtuais(dadosOrigem),
      );
    }
  };

  const redirecionarParaAquisicaoVindaHistoricoSolicitacoes = (): void => {
    if (
      checkIfAllItemsAreTrue([
        state?.from === PREV_REDIRECTS_SCREENS.HISTORICO_SOLICITACOES,
        !!state?.data?.idRequisicao,
      ])
    ) {
      salvarNumeroSolicitacao(state?.data?.idRequisicao);
      confirmarAquisicao();
    }
  };

  const abrirModalAtualizacaoContatos = (): void => {
    if (
      checkIfAllItemsAreTrue([!loadingObterContatos, recuperacaoEmailExecutada])
    ) {
      setOpen(prevState => ({
        ...prevState,
        modalAlertaContatos: !prevState.modalAlertaContatos,
      }));
    }
  };

  const carregarAPIDadosOrigem = (): void => {
    if (
      !checkIfAllItemsAreTrue([
        state?.from === PREV_REDIRECTS_SCREENS.HISTORICO_SOLICITACOES,
        !!state?.data?.idRequisicao,
      ])
    ) {
      obterDadosOrigem();
    }
  };

  useEffect(carregarAPIDadosOrigem, []);

  useEffect(salvarDadosOrigemNoContexto, [dadosOrigem]);

  useEffect(abrirModalAtualizacaoContatos, [
    loadingObterContatos,
    recuperacaoEmailExecutada,
  ]);

  useEffect(redirecionarParaAquisicaoVindaHistoricoSolicitacoes, [
    state?.from,
    state?.data?.idRequisicao,
  ]);

  return (
    <>
      <Display type="display-block" data-testid="PlanosTransferenciaInterna">
        <ResumoTransferenciaInterna loadingDadosOrigem={loadingDadosOrigem} />

        <FeatureAuthorizer
          requiredRoles={[
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
          ]}
        >
          <Card>
            <Card.Content padding={[6, 8, 6, 8]}>
              <Grid>
                <Grid.Item xs={1}>
                  <Text
                    variant="headline-05"
                    color="primary"
                    margin
                    data-testid="subTituloEtapaCuidadoExtra"
                  >
                    Cuidado Extra:
                  </Text>
                  <RenderConditional condition={loadingDadosOrigem}>
                    <SkeletonLoading
                      blocks={1}
                      data-testid="skeletonExibirDadosOrigem"
                    />
                  </RenderConditional>

                  <RenderConditional condition={!loadingDadosOrigem}>
                    <TRANSF_VGBL_STYLES.TabelaTransferenciaInterna
                      columns={CONSTS.COLUNAS_TABELA_CUIDADO_EXTRA}
                      noHeader
                      highlightOnHover
                      striped
                      responsive
                      pagination
                      paginationPerPage={10}
                      paginationComponentOptions={{
                        rowsPerPageText: 'Items por página',
                        rangeSeparatorText: 'de',
                      }}
                      title="Cuidado Extra"
                      data={tryGetValueOrDefault(
                        [transformarDadosTabelaDadosOrigem()],
                        [],
                      )}
                      noDataComponent={CONSTS.NAO_HA_DADOS_TABELA}
                      data-testid="tabelaExibirDadosOrigem"
                    />
                  </RenderConditional>
                </Grid.Item>
                <Grid.Item xs={1}>
                  <Display>
                    <Button
                      variant="outlined"
                      onClick={goDadosPlano}
                      data-testid="botaoVoltarDadosPlano"
                    >
                      Voltar
                    </Button>
                    <Button
                      data-testid="botaoContinuarEtapa"
                      onClick={() =>
                        setOpen(prevState => ({
                          ...prevState,
                          modalConsentimentoTransferencia: true,
                        }))
                      }
                      loading={loadingDadosOrigem}
                      disabled={checkIfSomeItemsAreTrue([
                        loadingDadosOrigem,
                        !dadosOrigem?.sucessoBFF,
                      ])}
                    >
                      Continuar
                    </Button>
                  </Display>
                </Grid.Item>
              </Grid>
            </Card.Content>
          </Card>
        </FeatureAuthorizer>
      </Display>

      <ModalAlertaContatos
        open={checkIfAllItemsAreTrue([
          open.modalAlertaContatos,
          !!dadosOrigem?.sucessoBFF,
        ])}
        onClose={() =>
          setOpen(prevState => ({
            ...prevState,
            modalAlertaContatos: !prevState.modalAlertaContatos,
          }))
        }
        redirect={() =>
          history.push('/cliente/produtos/previdencia/dados-participante')
        }
        dados={{
          email: responseObterContatosDefault?.emailDefault,
          telefone: responseObterContatosDefault?.numerosTelefone,
        }}
        data-testid="modalAlertaContatos"
      />

      <ModalConsentimentoTransferencia
        handleModal={handleModalConsentimento}
        toggleModal={open.modalConsentimentoTransferencia}
        avancarEtapa={prosseguirContribuicaoRendaMensal}
      />

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          !dadosOrigem?.sucessoBFF,
          !loadingDadosOrigem,
          !loadingObterContatos,
        ])}
      >
        <ModalInformeDadosOrigem dadosOrigem={dadosOrigem} />
      </RenderConditional>
    </>
  );
};

export default PlanosTransferenciaInterna;
