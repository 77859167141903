import { IResponseObterListaFopsAtivos } from 'main/features/Administracao/types/IFops';
import * as Yup from 'yup';

const validationMessage = 'Campo obrigatório.';

const FormFopsValidationSchema = Yup.object({
  nomeDaEmpresa: Yup.string().required(validationMessage),
  atividadePrincipalEmpresa: Yup.string().required(validationMessage),
  cnpjEmpresa: Yup.string().required(validationMessage),
  nomeCompletoResponsavel: Yup.string().required(validationMessage),
  emailResponsavel: Yup.string().required(validationMessage),
});

export const validarArquivoDownload = (
  dataLocation: IResponseObterListaFopsAtivos[],
) => {
  return dataLocation[0]?.dadosArquivos
    ? dataLocation[0]?.dadosArquivos[0]?.codigoIdentificadorUnico
    : '00000000';
};

export default FormFopsValidationSchema;
