import {
  dadosCoberturasFactory,
  dadosFinanciamentoFactory,
} from 'prestamista/features/dadosSeguro/factories';
import { usePecoCertificadoPrestamista } from 'prestamista/hooks/usePecoCertificadoPrestamista';
import { IUseDadosSeguroPrestamista } from 'prestamista/features/dadosSeguro/types/IUseDadosSeguroPrestamista';
import { useEffect } from 'react';

export const useDadosSeguroPrestamista = (): IUseDadosSeguroPrestamista => {
  const { certificadoPrestamista } = usePecoCertificadoPrestamista();

  const dadosFinanciamento = dadosFinanciamentoFactory(
    certificadoPrestamista.response?.entidade,
  );

  const dadosCoberturas = dadosCoberturasFactory(
    certificadoPrestamista.response?.entidade,
  );

  useEffect(() => {
    certificadoPrestamista.fetchData();
  }, []);

  return {
    dadosFinanciamento,
    dadosCoberturas,
    loading: certificadoPrestamista.loading,
  };
};
