import {
  Button,
  Display,
  Switch,
  Text,
  TextField,
} from '@cvp/design-system/react';
import Calendar from 'main/components/Calendar/Calendar';
import { InputFileFormik } from 'main/components/form/InputFile/InputFileFormik';
import RenderConditional from 'main/components/RenderConditional';
import { formatarData } from 'main/utils';
import { endOfDay } from 'date-fns';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  getTernaryResult,
} from 'main/utils/conditional';
import masks from 'main/utils/masks';
import { Modal } from 'painelAdministracao/components/ModalEditar/styles';
import { MODAL } from 'painelAdministracao/constants/constants';
import { IModalEditarFormularioProps } from 'painelAdministracao/types/IEditarFop';

export const ModalEditarFormulario = ({
  onClose,
  fopEditar,
  formik,
  handleChangeDataVersao,
  handleChangeAtivo,
  handleAtualizar,
  loading,
}: IModalEditarFormularioProps) => {
  const maxDate = endOfDay(new Date());
  const textoBotao = getTernaryResult(
    !!fopEditar?.nome,
    getTernaryResult(
      formik.values.ativo,
      MODAL.BOTAO_ATUALIZAR,
      MODAL.BOTAO_REMOVER,
    ),
    MODAL.BOTAO_CADASTRAR,
  );

  return (
    <Modal show onClose={onClose}>
      <Text variant="body02-lg" margin>
        <b>
          {getTernaryResult(
            !!fopEditar?.nome,
            MODAL.TITULO_EDITAR,
            MODAL.TITULO_NOVO,
          )}
        </b>
      </Text>
      <Text variant="body02-md" align="left" margin>
        <b>Código: </b>
        <RenderConditional condition={!!fopEditar?.codigo}>
          {fopEditar?.codigo}
        </RenderConditional>
        <RenderConditional condition={!fopEditar?.codigo}>
          <TextField
            name="codigo"
            data-testid="inputCodigo"
            maxLength="10"
            value={masks.numberOnly.mask(formik.values.codigo)}
            onChange={formik.handleChange}
          />
        </RenderConditional>
      </Text>
      <Text variant="body02-md" align="left" margin>
        <b>Nome: </b>
        <RenderConditional condition={!!fopEditar?.nome}>
          {fopEditar?.nome}
        </RenderConditional>
        <RenderConditional condition={!fopEditar?.nome}>
          <TextField
            name="nome"
            disabled={!formik.values.ativo}
            data-testid="inputNome"
            value={formik.values.nome}
            onChange={formik.handleChange}
          />
        </RenderConditional>
      </Text>
      <Text variant="body02-md" align="left" margin>
        <b>Versão: </b>
        <TextField
          disabled={!formik.values.ativo}
          name="versao"
          data-testid="inputVersao"
          value={formik.values.versao}
          onChange={formik.handleChange}
          maxLength="2"
        />
      </Text>

      <Text variant="body02-md" align="left" margin>
        <b>Data Versão: </b>
        <RenderConditional condition={formik.values.ativo}>
          <Calendar
            placeholder="Selecione a data da versão"
            data-testid="inputDataVersao"
            maxDate={maxDate}
            id="dataVersao"
            value={formik.values.dataVersao}
            onChange={date => {
              handleChangeDataVersao(date);
            }}
          />
        </RenderConditional>
        <RenderConditional condition={!formik.values.ativo}>
          <TextField
            placeholder="Selecione a data da versão"
            id="dataVersao"
            disabled
            data-testid="inputDataVersao"
            value={formatarData(formik.values.dataVersao?.toISOString())}
          />
        </RenderConditional>
      </Text>
      <RenderConditional condition={!!fopEditar?.nome}>
        <Display alignItems="center">
          <Text variant="body02-md" align="left" margin>
            <b>Deseja remover FOP?: </b>
          </Text>
          <div>
            <Display type="inline-block">
              <Switch
                onClick={() => handleChangeAtivo(!formik.values.ativo)}
                checked={!formik.values.ativo}
              />
            </Display>
          </div>
        </Display>
      </RenderConditional>

      <RenderConditional condition={formik.values.ativo}>
        <InputFileFormik
          onChange={event => {
            if (event?.currentTarget?.files)
              formik.setFieldValue(
                'novoArquivo',
                event?.currentTarget?.files[0],
              );
          }}
        />
      </RenderConditional>

      <Display justify="center">
        <Button
          onClick={() => handleAtualizar()}
          loading={loading}
          disabled={checkIfSomeItemsAreTrue([
            loading,
            checkIfAllItemsAreTrue([
              formik.values.ativo,
              !formik?.values?.novoArquivo?.size,
            ]),
          ])}
        >
          {textoBotao}
        </Button>
      </Display>
    </Modal>
  );
};
