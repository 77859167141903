import React from 'react';
import Table from 'main/components/Table';

import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import {
  removerMascaraValorMonetario,
  tryGetMonetaryValueOrDefault,
} from 'main/utils/money';
import * as S from 'previdencia/components/TabelaMultifundosPJ/styles';
import { InputValor } from 'previdencia/components/TabelaMultifundosPJ/InputValor';
import { COLUNAS_DESTINO_EXPANSIVEL_MULTIFUNDOS_PJ } from 'previdencia/components/TabelaMultifundosPJ/constants/constants';
import { LinhaExpansivelProps } from 'previdencia/components/TabelaMultifundosPJ/types/TabelaMultifundos.types';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ITransferenciaMultifundosPjFeatureData } from 'previdencia/components/TabelaMultifundosPJ/types/ITransferenciaMultifundosPjFeatureData';

export const LinhaExpansivelDestino: React.FunctionComponent<
  LinhaExpansivelProps
> = ({ data, handleAlterar }) => {
  const { featureData } =
    usePrevidenciaContext<ITransferenciaMultifundosPjFeatureData>();

  const definirValorMaximo = (
    descricaoReserva?: string,
    valorTransferencia?: number,
  ) => {
    const dataResumoValor = featureData?.dataResumo?.find(
      item =>
        item.resumo?.toLocaleLowerCase() ===
        descricaoReserva?.toLocaleLowerCase(),
    )?.valor;
    const resumoDestino = featureData?.resumoDestino?.find(
      item =>
        item.resumo?.toLocaleLowerCase() ===
        descricaoReserva?.toLocaleLowerCase(),
    );
    if (
      removerMascaraValorMonetario(
        tryGetValueOrDefault([resumoDestino?.valor], '0'),
      ) === valorTransferencia
    ) {
      return Number(dataResumoValor);
    }

    const restante = tryGetValueOrDefault(
      [removerMascaraValorMonetario(resumoDestino?.restante)],
      0,
    );

    return Number.isNaN(restante) ? 0 : Number(restante);
  };

  const definirExibicaoLinhaExpansivel = (descricaoReserva?: string) => {
    return (
      Number(
        featureData?.dataResumo?.find(
          e =>
            e.resumo.toLocaleLowerCase() ===
            descricaoReserva?.toLocaleLowerCase(),
        )?.valor,
      ) !== 0
    );
  };

  const definirExibicao = (
    descricaoReserva?: string,
    valorTransferencia?: number,
  ) => {
    const possuiSaldo =
      Number(
        featureData?.dataResumo?.find(
          e =>
            e.resumo.toLocaleLowerCase() ===
            descricaoReserva?.toLocaleLowerCase(),
        )?.valor,
      ) !== 0;

    const valorMaximo = definirValorMaximo(
      descricaoReserva,
      valorTransferencia,
    );

    const rangePositivo =
      valorMaximo >=
      tryGetValueOrDefault([featureData?.valorMinimoTransferencia], 0);

    return checkIfAllItemsAreTrue([possuiSaldo, rangePositivo]);
  };

  const table = data?.reservas
    .filter(({ descricaoReserva }) =>
      definirExibicaoLinhaExpansivel(descricaoReserva),
    )
    .map(
      ({ descricaoReserva, saldo, valorTransferencia, tipoTransferencia }) => ({
        descricaoReserva,
        valor: tryGetMonetaryValueOrDefault([saldo], 0),
        saldo: tryGetMonetaryValueOrDefault([saldo], 0),
        valor_input: (
          <InputValor
            tipoTransferencia={tipoTransferencia}
            show={definirExibicao(descricaoReserva, valorTransferencia)}
            valorTransferencia={valorTransferencia}
            valorMinimo={featureData?.valorMinimoTransferencia}
            valorMaximo={definirValorMaximo(
              descricaoReserva,
              valorTransferencia,
            )}
            onBlur={(value: number) =>
              handleAlterar({
                id: tryGetValueOrDefault([data?.codigoFundo], ''),
                reserva: descricaoReserva,
                saldo: Number(saldo),
                valorTransferencia: value,
              })
            }
          />
        ),
      }),
    );

  return (
    <S.ContainerLinhaExpansivel>
      <Table
        responsive
        data={tryGetValueOrDefault([table], [])}
        columns={tryGetValueOrDefault(
          [COLUNAS_DESTINO_EXPANSIVEL_MULTIFUNDOS_PJ],
          [],
        )}
        noDataComponent="Não há dados para exibir."
      />
    </S.ContainerLinhaExpansivel>
  );
};
