import { obterEndpointTransferirEntreFundos } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import {
  RequestTransferenciaEntreFundos,
  ResponseTransferenciaEntreFundos,
} from '../types/TransferenciaEntreFundos.type';

export const fundosDestino = async (
  cpf: string,
  codContaOrigem: string,
  reservasOrigem: RequestTransferenciaEntreFundos[] | undefined,
): Promise<ResponseTransferenciaEntreFundos | undefined> => {
  if (reservasOrigem) {
    const { data } = await api.post<
      IApiResponse<ResponseTransferenciaEntreFundos>
    >(obterEndpointTransferirEntreFundos(), {
      cpf,
      codContaOrigem,
      reservasOrigem,
    });

    if (data?.dados?.sucesso === false)
      throw new Error(
        data?.dados?.mensagens && data?.dados?.mensagens[0]?.descricao,
      );

    return data.dados.entidade;
  }

  return undefined;
};
