import { Modal, Text } from '@cvp/design-system/react';
import React from 'react';
import { TextAlign } from '../styles';

type ModalIndisponibilidadeProps = {
  open: boolean;
  handleClose: () => void;
};
const ModalIndisponibilidade: React.FC<ModalIndisponibilidadeProps> = ({
  open,
  handleClose,
}) => (
  <Modal show={open} onClose={handleClose}>
    <Text variant="headline-04" margin>
      IMPORTANTE!
    </Text>
    <TextAlign variant="body03-sm">Prezado(a) colega CAIXA,</TextAlign>
    <TextAlign variant="body03-sm">
      Informamos que a consulta de Status de Sinistro está{' '}
      <strong>temporariamente indisponível</strong> neste canal.
    </TextAlign>
    <TextAlign variant="body03-sm">
      A previsão de normalização é em 19/07/2023.
    </TextAlign>
    <TextAlign variant="body03-sm">
      Caso não seja possível aguardar, abra uma ocorrência no ASC ou oriente seu
      cliente a ligar na Central de Sinistros: 0800 722 2492.
    </TextAlign>
    <TextAlign variant="body03-sm">
      <i>Contamos com a sua compreensão.</i>
    </TextAlign>
  </Modal>
);

export default ModalIndisponibilidade;
