export const MENSAGEM_FILTRO_SEM_DADOS =
  'Não há registros que correspondam com o filtro selecionado.';

export const PROPOSTA_SEM_DADOS_RETORNO_ENDPOINT =
  'Não há propostas ativas para realizar upload vinculadas à agência.';

export const STATUS_PROPOSTA_ENDPOINT =
  'Não foram encontradas propostas com os parâmetros informados.';

export const ALERTA_FINALIZACAO_UPLOAD_PROPOSTA =
  'Atenção: Após a finalização do processo de upload desta proposta informamos que ela não constará mais na listagem de propostas pendentes.';
export const ERRO_UPLOAD_PROPOSTA = 'ERRO NO UPLOAD DA PROPOSTA';
