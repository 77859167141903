import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'main/components/Route';
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import PrestamistaContextProvider from 'prestamista/contexts/PrestamistaContextProvider';
import { IPrivateRoute } from 'main/components/Route/PrivateRoute';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import DadosSeguro from 'prestamista/features/dadosSeguro/pages/DadosSeguro';
import DadosSegurado from 'prestamista/features/dadosSegurado/pages/DadosSegurado';
import AtualizacaoDadosSegurado from 'prestamista/features/dadosSegurado/pages/AtualizacaoDadosSegurado';
import EmissaoCertificado from 'prestamista/features/emissaoCertificado/pages/EmissaoCertificado';
import HomePagePrestamista from 'prestamista/features/apresentacaoPrestamista/pages/HomePagePrestamista';

const routes: IPrivateRoute[] = [
  {
    path: '/cliente/produtos/prestamista',
    component: HomePagePrestamista,
    key: 'prestamista-home',
    authenticated: true,
    breadcrumb: 'Painel',
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
  },
  {
    path: '/cliente/produtos/prestamista/dados-segurado',
    component: DadosSegurado,
    key: 'cliente-consulta-prestamista',
    breadcrumb: 'Dados Segurado',
    authenticated: true,
    withSidebar: true,
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
  },
  {
    path: '/cliente/produtos/prestamista/dados-seguro',
    component: DadosSeguro,
    key: 'cliente-produtos-prestamista',
    breadcrumb: 'Dados Seguro',
    exact: true,
    authenticated: true,
    withSidebar: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
  },
  {
    path: '/cliente/produtos/prestamista/dados-segurado/atualizacao-dados-segurado',
    component: AtualizacaoDadosSegurado,
    key: 'cliente-produtos-prestamista-atualizacao',
    breadcrumb: 'Atualização Dados do Segurado',
    authenticated: true,
    withSidebar: true,
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
  },
  {
    path: '/cliente/produtos/prestamista/emissao-certificado',
    component: EmissaoCertificado,
    key: 'cliente-produtos-certificado',
    breadcrumb: 'Emissão Certificado',
    authenticated: true,
    withSidebar: true,
    exact: true,
    requiredRoles: [
      ...PRINCIPAL_USERS,
      USER_PROFILES.ANALISTA_CONSULTA,
      USER_PROFILES.ANALISTA_MANUTENCAO,
      USER_PROFILES.ANALISTA_SAIDA,
      USER_PROFILES.ANALISTA_ENTRADA,
    ],
  },
];

/**
 * Utilizar caso seja necessário personalizar o nome dos breadcrumbs
 */
export const prestamistaBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
};

const RotasPrestamista = (): React.ReactElement => (
  <Switch>
    <PrestamistaContextProvider>
      {routes.map(route => (
        <PrivateRoute
          key={route.key}
          path={route.path}
          exact={route.exact}
          component={route.component}
          authenticated={route.authenticated}
          requiredRoles={route.requiredRoles}
        />
      ))}
    </PrestamistaContextProvider>
  </Switch>
);

export default RotasPrestamista;
