import { AppContext } from 'main/contexts/AppContext';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { useContext, useEffect } from 'react';
import * as PECO_TYPES from 'seguros/types/ApoliseCoberturaUsePeco';
import { ICarencia } from '../types/IResponseCertificadosResumo';
import { useCertificadoSeguroResumo } from './useCertificadoSeguroResumo';

export const useApoliceDadosCarenciaVida =
  (): PECO_TYPES.IUsePecoTabelaApolice<ICarencia> => {
    const { clienteVida } = useContext(AppContext);
    const {
      certificadoSeguros: { fetchData, response, loading },
    } = useCertificadoSeguroResumo({
      numeroContrato: tryGetValueOrDefault([clienteVida?.numCertificado], '0'),
      codigoProduto: tryGetValueOrDefault([clienteVida?.codigoProduto], '0'),
    });
    useEffect(() => {
      fetchData();
    }, []);

    const entidade = response?.entidade;

    const carenciasIndividuais: ICarencia[] | undefined =
      entidade?.individual?.carencias?.map(carencia => ({
        descricao: carencia,
      }));

    const dados = tryGetValueOrDefault(
      [
        entidade?.dadosComunsBilheteEmpresarial?.carencias,
        carenciasIndividuais,
      ],
      [],
    );

    return {
      dados,
      loading,
    };
  };
