export const MAPPED_FIELDS = {
  prazo: 'A3D49BE7-F18E-45A0-9CEF-002A8FC5B480',
  agendamento: '7D5CB9CC-BAAF-4C81-87F1-A3A232DF99D3',
  importanciaSegurada: '471B505A-5688-4A0A-9C1A-71707ABB6A57',
  acumuloIs: '7E055209-6548-4BC3-A533-B4ADE38F89E9',
  dataNascimento: '772137EF-B0F4-4651-A3F0-D76500336866',
  valorProspeccao: 'B944127A-88D0-4500-A450-C660E25B5916',
  cpfCnpj: 'FE3DB2B7-925F-429A-8B5E-98C023287080',
  telefoneComercial: '53F7307A-9CA0-452C-B937-DF32F469DADF',
};
