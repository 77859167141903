import {
  CoberturaContratada,
  IResponseStatusCoberturasContratadas,
} from '../types/CoberturasContratadas.types';

export function converterResponseCoberturasContratadasParaCoberturaContratada(
  data: IResponseStatusCoberturasContratadas | undefined,
  tipo: string,
): CoberturaContratada[] | undefined {
  let contribuicaoAtual: CoberturaContratada | undefined;
  const contribuicaoConvertido: CoberturaContratada[] | undefined = [];

  data?.beneficioContribuicaoCertificado.forEach(e => {
    if (e.tipoBeneficio === tipo) {
      contribuicaoAtual = {
        nome: e.descricaoBeneficio,
        certificado: data.contaId,
        descricaoProduto: data.descricaoProduto,
        status: e.statusBeneficio,
        tipo: e.tipoBeneficio,
        valor: e.valorContribuicaoEsperado,
        valorAcumulado: data.valorTotalSaldo,
        valorIndenizacao: e.valorBeneficioEsperado,
        numeroBeneficio: e.beneficioId,
        numeroPlano: e.planoId,
        tipoContribuicao: e.tipoContribuicao,
        diaVenciamento: e.diaPagamento,
        prazoRecebimento: e?.tipoCalculo,
        cpfPessoaCertificado: data.cpfPessoaCertificado,
        tipoRenda: e.tipoRenda,
        valorBeneficio: e.valorBeneficio,
      };

      contribuicaoConvertido.push(contribuicaoAtual);
    }
  });

  return contribuicaoConvertido;
}
