import { obterEndpointRevalidarTransferencia } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { ResponseRevalidarTransferencia } from '../types/RevalidarTransferencia.types';

export const revalidarTransferencia = async (
  cpf: string,
  numTransferencia: string | undefined,
): Promise<ResponseRevalidarTransferencia | undefined> => {
  if (numTransferencia) {
    const { data } = await api.post<
      IApiResponse<ResponseRevalidarTransferencia>
    >(obterEndpointRevalidarTransferencia(), {
      cpf,
      numTransferencia,
    });

    if (data?.dados?.sucesso === false || typeof data?.dados === 'string')
      throw new Error(
        (data?.dados?.mensagens && data?.dados?.mensagens[0]?.descricao) ||
          'Ocorreu um erro ao realizar a transferencia',
      );

    return data.dados.entidade;
  }

  return undefined;
};
