/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
import { Button, Display } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { IFilterOption } from 'main/components/Table/TableFilter/IFilterOption';
import TableFilter from 'main/components/Table/TableFilter/TableFilter';
import * as S from 'main/components/Table/customStyles';
import { formatarData } from 'main/utils';
import { formatarCpf } from 'main/utils/cpf_cnpj';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { TableSkeleton } from 'portabilidade/components/Table';
import * as CONST from 'previdencia/features/Resgate/constants/constants';
import useRelatorioResgateFilterOptions from 'previdencia/features/Resgate/hooks/useRelatorioResgateFilterOptions';
import * as RESPONSE_TYPES from 'previdencia/features/Resgate/types/RelatorioResgateResponse';
import React, { useCallback } from 'react';
import { Container } from './styles';

interface IListagemResgatesProps {
  listaResgates?: RESPONSE_TYPES.IRelatorioResgateResponse[];
  loading: boolean;
  voltar: () => void;
  exibirDetalhe: (data: RESPONSE_TYPES.IRelatorioResgateResponse) => void;
}

const ListaResgates: React.FC<IListagemResgatesProps> = ({
  listaResgates,
  loading,
  voltar,
  exibirDetalhe,
}) => {
  const filterOptions: IFilterOption[] =
    useRelatorioResgateFilterOptions(listaResgates);

  const imprimir = useCallback(() => {
    window.print();
  }, []);

  const colunas = [
    {
      name: 'Cód. Certificado',
      selector: 'codigoCertificado',
      sortable: true,
      cell: (data: any) => (
        <Button
          data-testid="btnCodigoCertificado"
          style={{ padding: 0, textAlign: 'left' }}
          variant="text"
          onClick={() => exibirDetalhe(data)}
        >
          {data.codigoCertificado}
        </Button>
      ),
    },
    {
      name: 'Agência',
      selector: 'codigoAgencia',
      sortable: true,
    },
    {
      name: 'CPF/CNPJ',
      selector: 'cpfCnpjCliente',
      sortable: true,
      cell: ({ cpfCnpjCliente }: any) => (
        <span>{formatarCpf(cpfCnpjCliente)}</span>
      ),
    },
    {
      name: 'Cliente',
      selector: 'nomeCliente',
      sortable: true,
    },
    {
      name: 'Gerado em',
      selector: 'dataGeracao',
      sortable: true,
      cell: ({ dataGeracao }: any) => <span>{formatarData(dataGeracao)}</span>,
    },
    {
      name: 'Tipo de Resgate',
      selector: 'tipoResgate',
      sortable: true,
    },
    {
      name: 'Valor do Resgate',
      selector: 'valorResgate',
      sortable: true,
      cell: ({ valorResgate }: any) => (
        <span>{formatarValorPadraoBrasileiro(valorResgate)}</span>
      ),
    },
  ];

  return (
    <Container>
      <RenderConditional condition={loading}>
        <TableSkeleton colunas={colunas} />
      </RenderConditional>
      <RenderConditional condition={!loading}>
        <TableFilter
          filterOptions={filterOptions}
          dataToFilter={listaResgates}
          filterTextPartial
          tagsFilterDescription={CONST.TAGS_FILTER_DESCRIPTION}
          customButton={
            <Button
              type="button"
              onClick={voltar}
              variant="outlined"
              data-testid="voltar"
            >
              Voltar
            </Button>
          }
        >
          {dataFiltered => (
            <>
              <div className="hide-print">
                <S.Table
                  noHeader
                  responsive
                  data={dataFiltered}
                  columns={colunas}
                  noDataComponent="Não há dados para exibir."
                  pagination
                  paginationPerPage={10}
                  paginationComponentOptions={{
                    rowsPerPageText: 'Items por página',
                    rangeSeparatorText: 'de',
                  }}
                />
              </div>

              <S.Table
                className="show-print"
                noHeader
                responsive
                data={dataFiltered}
                columns={colunas}
                noDataComponent="Não há dados para exibir."
              />

              <RenderConditional condition={dataFiltered.length > 0}>
                <Display>
                  <Button
                    className="hide-print"
                    variant="primary"
                    onClick={imprimir}
                  >
                    Imprimir
                  </Button>
                </Display>
              </RenderConditional>
            </>
          )}
        </TableFilter>
      </RenderConditional>
    </Container>
  );
};

export default ListaResgates;
