import styled from 'styled-components/macro';
import { Card, Display, Button, Text } from '@cvp/design-system/react';

export const CardContainer = styled(Card)`
  min-height: auto;
  position: relative;
`;

export const DuvidasContainer = styled(Display)`
  position: absolute;
  right: 20px;
`;

export const BotaoVisualizar = styled(Button)`
  padding: 0;
  text-align: left;
`;

export const TextoUppercase = styled(Text)`
  text-transform: uppercase;
`;

export const TextoDuvidas = styled(Text)`
  margin-right: 10px;
`;
