import React, { useEffect } from 'react';
import { Display, Text, Button } from '@cvp/design-system/react';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import TabelaMultifundosPJ from 'previdencia/components/TabelaMultifundosPJ/TabelaMultifundosPJ';
import { MultifundoPjData } from 'previdencia/types/MultifundosPJ';
import { ITransferenciaMultifundosPjFeatureData } from 'previdencia/components/TabelaMultifundosPJ/types/ITransferenciaMultifundosPjFeatureData';
import { EnumEtapas } from 'previdencia/types/Fundo.type';
import { PAGE_TEXTS, TROCA_FUNDO } from '../../constants/texts';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import useDefinirContribuicaoRegular from '../../hooks/useDefinirContribuicaoRegular';
import {
  converterContribuicaoParaFundo,
  formatarFundosNovos,
} from '../../factories/fundosFactory';
import useConfirmarTransferencia from '../../hooks/useConfirmarTransferencia';
import { confirmarAssinaturaEletronica } from '../../utils/TransferenciaMultifundos';

const Redistribuir: React.FC = () => {
  const { setFeatureData, featureData } =
    usePrevidenciaContext<ITransferenciaMultifundosPjFeatureData>();
  const contribuicaoRegular = featureData?.contribuicaoRegular;

  const {
    response: responseDefinir,
    loading: loadingDefinir,
    fetchData: definirRegular,
  } = useDefinirContribuicaoRegular(
    featureData?.idRequisicao,
    formatarFundosNovos(featureData?.fundosNovosSelecionados),
  );

  const {
    response: responseConfirmar,
    loading: loadingConfirmar,
    fetchData: confirmarTransferencia,
  } = useConfirmarTransferencia(featureData?.idRequisicao);

  useEffect(() => {
    if (responseDefinir) {
      confirmarTransferencia();
    }
  }, [responseDefinir]);

  useEffect(() => {
    if (confirmarAssinaturaEletronica(responseConfirmar)) {
      setFeatureData({
        etapa: EnumEtapas.assinatura,
      });
    }
  }, [responseConfirmar]);

  return (
    <>
      <Text variant="body02-sm" margin>
        {TROCA_FUNDO.FUNDO_REMOVIDO_INICIO}
        <b>
          {tryGetMonetaryValueOrDefault(
            [contribuicaoRegular?.valorContribuicaoRegular],
            0,
          )}
        </b>
        {TROCA_FUNDO.FUNDO_REMOVIDO_FIM}
      </Text>
      <Text variant="body02-sm" margin>
        {TROCA_FUNDO.REDEFINIR}
      </Text>
      <Text variant="body02-sm" margin>
        {TROCA_FUNDO.VALOR_MINIMO}
        <b>
          {tryGetMonetaryValueOrDefault(
            [contribuicaoRegular?.valorMinimoDiversificacaoRegular],
            0,
          )}
        </b>{' '}
        por fundo.
      </Text>
      <TabelaMultifundosPJ
        fundos={converterContribuicaoParaFundo(
          contribuicaoRegular?.fundosContribuicao,
        )}
        quantidadeMaximaFundos={1}
        isLoading={checkIfSomeItemsAreTrue([loadingDefinir, loadingConfirmar])}
        valorMinimo={tryGetValueOrDefault(
          [contribuicaoRegular?.valorMinimoContribuicao],
          200,
        )}
        valorMaximo={tryGetValueOrDefault(
          [contribuicaoRegular?.valorContribuicaoRegular],
          200,
        )}
        setFundosSelecionados={(fundos: MultifundoPjData[]) => {
          setFeatureData({
            ...featureData,
            fundosNovosSelecionados: fundos,
          });
        }}
      />
      <Text variant="body02-sm">
        <b>Importante</b>
      </Text>
      <Text variant="body02-sm" margin>
        {PAGE_TEXTS.IMPORTANTE}
      </Text>
      <Display justify="space-between">
        <Button
          variant="outlined"
          onClick={() =>
            setFeatureData({
              ...featureData,
              etapa: EnumEtapas.confirmarTransferencia,
            })
          }
        >
          Voltar
        </Button>
        <Button
          loading={checkIfSomeItemsAreTrue([loadingDefinir, loadingConfirmar])}
          disabled={checkIfSomeItemsAreTrue([loadingDefinir, loadingConfirmar])}
          onClick={() => definirRegular()}
        >
          Continuar
        </Button>
      </Display>
    </>
  );
};

export default Redistribuir;
