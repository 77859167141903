import { Card, Display, Grid, Text } from '@cvp/design-system/react';

import * as CONSTS from 'previdencia/features/AlteracaoRegimeTributario/constants/constants';
import * as REGIME_TRIBUTARIO_TYPES from 'previdencia/features/AlteracaoRegimeTributario/types/AlteracaoRegimeTributario';
import * as S from './styles';

const MensagemDeclaracaoRegimeTributario = ({
  checarDeclaracao,
}: REGIME_TRIBUTARIO_TYPES.IMensagemDeclaracaoRegimeTributarioProps) => {
  return (
    <Grid.Item xs={1}>
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Text variant="body01-lg" color="primary" role="heading">
            {CONSTS.TEXTOS_REGIME_TRIBUTARIO.TITULO_DECLARACAO}
          </Text>
          <Display>
            <Text variant="body02-md" color="text">
              <S.CheckboxDeclaracao
                onClick={checarDeclaracao}
                role="checkbox"
              />
              <span aria-label="texto-consentimento">
                {CONSTS.TEXTOS_REGIME_TRIBUTARIO.INFO_CHECKBOX}
              </span>
            </Text>
          </Display>
        </Card.Content>
      </Card>
    </Grid.Item>
  );
};

export default MensagemDeclaracaoRegimeTributario;
