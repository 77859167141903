import React from 'react';
import { Text } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { CoberturaContratada } from '../../types/CoberturasContratadas.types';
import * as S from './styles';

type InformacoesAdicionaisReservaProps = {
  dados: CoberturaContratada | undefined;
};

const InformacoesAdicionaisReserva: React.FC<
  InformacoesAdicionaisReservaProps
> = ({ dados }) => {
  return (
    <S.ContainerInformacoesAdicionaisReserva>
      <S.PrimeiroElementoDescricaoContribuicaoReserva>
        <S.TextDescricaoItemContribuicaoReserva
          variant="caption-01"
          color="text-light"
        >
          Valor da Contribuição
        </S.TextDescricaoItemContribuicaoReserva>
        <Text variant="body01-sm">
          {formatarValorPadraoBrasileiro(dados?.valor)}
        </Text>
      </S.PrimeiroElementoDescricaoContribuicaoReserva>
      <S.DescricaoContribuicaoReserva>
        <S.TextDescricaoItemContribuicaoReserva
          variant="caption-01"
          color="text-light"
        >
          Saldo Acumulado
        </S.TextDescricaoItemContribuicaoReserva>
        <Text variant="body01-sm">
          {formatarValorPadraoBrasileiro(dados?.valorAcumulado)}
        </Text>
      </S.DescricaoContribuicaoReserva>
    </S.ContainerInformacoesAdicionaisReserva>
  );
};

export default InformacoesAdicionaisReserva;
