import { usePeco } from 'main/hooks/usePeco';
import { PECOS_RETENCAO_PORTABILIDADE } from 'portabilidade/config/endpoints';
import { IResponsePortabilidadeEntrada } from '../types/IResponsePortabilidadeEntrada';

export const useListarPortabilidadesEntrada = () => {
  const { loading, response } = usePeco<unknown, IResponsePortabilidadeEntrada>(
    {
      api: {
        operationPath: PECOS_RETENCAO_PORTABILIDADE.listarPortabilidadesEntrada,
      },
      autoFetch: true,
      cache: true,
    },
  );

  return { loading, response: response?.entidade };
};
