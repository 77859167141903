export function tryGetValueOrDefault<Type>(
  valor: Array<Type | undefined>,
  valorDefault: Type,
): Type {
  return (
    valor?.reduce<Type | undefined>(
      (a: Type | undefined, b: Type | undefined): Type | undefined => a || b,
      valor[0],
    ) || valorDefault
  );
}

/**
 * Testa se todos os items passados são true.
 * Equivalente: a && b
 * @param values
 * @returns boolean
 */

export function checkIfAllItemsAreTrue(values: Array<boolean>): boolean {
  return values?.every(x => x);
}

/**
 * Testa se pelo menos um dos items passados é true.
 * Equivalente: a || b
 * @param values
 * @returns boolean
 */
export function checkIfSomeItemsAreTrue(values: Array<boolean>): boolean {
  return values?.some(x => x);
}

/**
 * Helper para abstrair uma operação ternária
 * Equivalente: state ? condition : otherwise
 * @param state variável boolean a ser verificada
 * @param condition condição no caso true
 * @param otherwise condição alternativa no caso false
 * @example getTernaryResult(true, 'N', 'D')
 */
export function getTernaryResult<T = string>(
  state: boolean,
  condition: T,
  otherwise: T,
): T {
  return state ? condition : otherwise;
}
