import { useEffect, useState } from 'react';

import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { usePecoCalcularResgate } from 'previdencia/hooks/usePecoCalcularResgate';
import { usePecoConsultarListaFundosParaResgate } from 'previdencia/hooks/usePecoConsultarListaFundosParaResgate';
import { usePecoAlicotasAgrupadas } from 'previdencia/hooks/usePecoAlicotasAgrupadas';
import { ALIQUOTA } from 'previdencia/constants/constants';
import { TAlicotasAgrupadasFundosAliquotaFactory } from 'previdencia/types/AlicotasAgrupadas';
import { usePecoConsultarParametrosRegimeTributario } from 'previdencia/features/AlteracaoRegimeTributario/hooks/usePecoAlteracaoRegimeTributario';
import * as CONSTS from 'previdencia/features/AlteracaoRegimeTributario/constants/constants';
import * as REGIME_TRIBUTARIO_TYPES from 'previdencia/features/AlteracaoRegimeTributario/types/AlteracaoRegimeTributario';

const useHandleDadosAliquota = () => {
  const [dadosTabela, setDadosTabelaAliquota] =
    useState<REGIME_TRIBUTARIO_TYPES.IDadosTabela>();

  const [opcaoRegimeTributario, setOpcaoRegimeTributario] =
    useState<string>('');

  const selecionarOpcaoRegimeTributario = (tipoAliquota: string) => {
    setOpcaoRegimeTributario(tipoAliquota);
  };

  const { listarFundosParaResgate, loadingDadosListarFundosParaResgate } =
    usePecoConsultarListaFundosParaResgate();

  const { calcularResgate, loadingDadosCalcularResgate } =
    usePecoCalcularResgate();

  const { obterAlicotasAgrupadas, loadingDadosAlicotasAgrupadas } =
    usePecoAlicotasAgrupadas();

  const {
    dadosConsultaParametrosRegimeTributario,
    loadingDadosConsultaParametrosRegimeTributario,
  } = usePecoConsultarParametrosRegimeTributario();

  const loadingDadosAliquota: boolean = checkIfSomeItemsAreTrue([
    loadingDadosListarFundosParaResgate,
    loadingDadosCalcularResgate,
    loadingDadosAlicotasAgrupadas,
    loadingDadosConsultaParametrosRegimeTributario,
  ]);

  const isPermitidoAlterarRegimeTributario: string = tryGetValueOrDefault(
    [
      dadosConsultaParametrosRegimeTributario?.dados
        .podeAlterarRegimeTributario,
    ],
    '',
  );

  const isHandleDadosAliquota: boolean =
    isPermitidoAlterarRegimeTributario ===
    CONSTS.ALTERACAO_REGIME_TRIBUTARIO.PODE_ALTERAR;

  const handleDadosTabelaAliquota = async (): Promise<void> => {
    const listaFundosParaResgate = await listarFundosParaResgate();

    const dadosCalculoRegressivo = await calcularResgate({
      valorResgateTotal:
        listaFundosParaResgate?.entidade?.saldo?.saldoDisponivelParaResgate,
      TipoRegimeTributario: ALIQUOTA.TIPO_REGIME_REGRESSIVO,
      TipoResgate: ALIQUOTA.TIPO_RESGATE_TOTAL,
    });

    const dadosCalculoProgressivo = await calcularResgate({
      valorResgateTotal:
        listaFundosParaResgate?.entidade?.saldo?.saldoDisponivelParaResgate,
      TipoRegimeTributario: ALIQUOTA.TIPO_REGIME_PROGRESSIVO,
      TipoResgate: ALIQUOTA.TIPO_RESGATE_TOTAL,
    });

    const listaAliquotaAgrupadaRegressiva = await obterAlicotasAgrupadas({
      numeroResgate:
        dadosCalculoRegressivo?.entidade?.dadosEncargo.numeroResgate,
    });

    const listaAliquotaAgrupadaProgressiva = await obterAlicotasAgrupadas({
      numeroResgate:
        dadosCalculoProgressivo?.entidade?.dadosEncargo.numeroResgate,
    });

    const obterListaConsolidadaRegressiva = tryGetValueOrDefault(
      [
        listaAliquotaAgrupadaRegressiva?.entidade?.flatMap(
          item => item.fundosAliquota,
        ),
      ],
      [],
    );

    const listaConsolidadaRegressiva = obterListaConsolidadaRegressiva.flatMap(
      fundoRegressivo => {
        return fundoRegressivo.valoresAliquotas.map(valorAliquota => ({
          descricaoFundo: fundoRegressivo.descricaoFundo,
          ...valorAliquota,
        }));
      },
    );

    const obterListaConsolidadaProgressiva = tryGetValueOrDefault(
      [
        listaAliquotaAgrupadaProgressiva?.entidade?.flatMap(
          item => item.fundosAliquota,
        ),
      ],
      [],
    );

    const listaConsolidadaProgressiva =
      obterListaConsolidadaProgressiva.flatMap(fundoProgressivo => {
        return fundoProgressivo.valoresAliquotas.map(valorAliquota => ({
          descricaoFundo: fundoProgressivo.descricaoFundo,
          ...valorAliquota,
        }));
      });

    const fundosRegressiva: Partial<TAlicotasAgrupadasFundosAliquotaFactory>[] =
      [
        ...listaConsolidadaRegressiva,
        {
          descricaoFundo: CONSTS.TIPOS_RESGATE.TOTAL,
          saldoTotal: tryGetValueOrDefault(
            [listaAliquotaAgrupadaRegressiva?.entidade?.[0]?.saldoTotal],
            '',
          ),
        },
      ];

    const fundosProgressiva: Partial<TAlicotasAgrupadasFundosAliquotaFactory>[] =
      [
        ...listaConsolidadaProgressiva,
        {
          descricaoFundo: CONSTS.TIPOS_RESGATE.TOTAL,
          saldoTotal: tryGetValueOrDefault(
            [listaAliquotaAgrupadaProgressiva?.entidade?.[0]?.saldoTotal],
            '',
          ),
        },
      ];

    const ordenarLista = (
      lista: Partial<TAlicotasAgrupadasFundosAliquotaFactory>[],
    ) => {
      return lista.sort((a, b) => Number(b.aliquota) - Number(a.aliquota));
    };

    setDadosTabelaAliquota({
      fundosAliquotaRegressivo: ordenarLista(fundosRegressiva),
      fundosAliquotaProgressivo: ordenarLista(fundosProgressiva),
    });
  };

  useEffect(() => {
    if (isHandleDadosAliquota) {
      handleDadosTabelaAliquota();
    }
  }, [isPermitidoAlterarRegimeTributario]);

  return {
    loadingDadosAliquota,
    dadosConsultaParametrosRegimeTributario:
      dadosConsultaParametrosRegimeTributario?.dados,
    opcaoRegimeTributario,
    dadosTabela,
    selecionarOpcaoRegimeTributario,
  };
};

export default useHandleDadosAliquota;
