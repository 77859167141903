import { FormikProps } from 'formik';
import * as Yup from 'yup';

import {
  tipoContasBancariasCefMain,
  tipoContasBancariasNaoCefMain,
} from 'main/constants/tipoContasBancariasMain';
import {
  checkIfSomeItemsAreTrue,
  getTernaryResult,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import {
  formatarData,
  formatarDataHora,
  parseDateStringToDate,
} from 'main/utils';
import {
  CODIGO_BANCO_CAIXA,
  formatarContaSeBancoCaixa,
} from 'main/utils/formatarNumeroContaCaixa';
import { ALIQUOTA } from 'previdencia/constants/constants';
import {
  IListarFundosParaResgateAliquota,
  IListarFundosParaResgateFundosDisponiveis,
} from 'previdencia/types/ConsultaListaFundosParaResgate';
import { TAlicotasAgrupadasFundosAliquotaFactory } from 'previdencia/types/AlicotasAgrupadas';
import LISTA_BANCOS_BASE_PREV from 'previdencia/features/SolicitacaoResgate/static/ListaBancosBasePrev.json';
import * as UTILS from 'previdencia/features/SolicitacaoResgate/utils/SolicitacaoResgate';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as RESPONSE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgateResponse';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

export const mensagensCertificadoFactory = ({
  indicadorPrazoDeDiferimentoExpirado,
  mensagensCertificado,
  redirectPrazoDiferimento,
}: SOLICITACAO_RESGATE_TYPES.IMensagensCertificadoFactory) => {
  const mensagensCustom = [
    CONSTS.MENSAGEM_CERTIFICADO_PRAZO_DIFERIMENTO_CUSTOM(
      redirectPrazoDiferimento,
    ),
  ];

  if (indicadorPrazoDeDiferimentoExpirado) {
    return mensagensCertificado?.concat(mensagensCustom);
  }

  return tryGetValueOrDefault([mensagensCertificado], []);
};

export const modificarFundoSelecionadoFactory = ({
  fundo,
  codigoFundo,
}: SOLICITACAO_RESGATE_TYPES.IModificarFundoSelecionadoFactoryParams) => {
  return getTernaryResult(
    fundo.codigoFundo === codigoFundo,
    {
      ...fundo,
      selecionado: !fundo.selecionado,
      tipoResgate: fundo.tipoResgate,
      valorRetirar: fundo.valorRetirar,
    },
    { ...fundo },
  );
};

export const selecionarTipoResgateFactory = ({
  fundo,
  codigoFundo,
  tipoResgate,
}: SOLICITACAO_RESGATE_TYPES.ISelecionarTipoResgateFactoryParams) => {
  return getTernaryResult(
    fundo.codigoFundo !== codigoFundo,
    { ...fundo },
    {
      ...fundo,
      tipoResgate,
      valorRetirar: getTernaryResult(
        tipoResgate === CONSTS.ID_SIGLA_PARCIAL,
        '',
        fundo.saldoTotal.toString(),
      ),
    },
  );
};

export const distribuirValorAplicacaoFactory = ({
  fundo,
  codigoFundo,
  valorRetirar,
}: SOLICITACAO_RESGATE_TYPES.IDistribuirValorAplicacaoFactoryParams) => {
  return getTernaryResult(
    fundo.codigoFundo !== codigoFundo,
    { ...fundo },
    { ...fundo, valorRetirar },
  );
};

export const formikSimularResgateFactory = (
  listaFundosParaResgate: IListarFundosParaResgateFundosDisponiveis[],
) => ({
  tipoResgate: '',
  valorSolicitado: '',
  listaFundosParaResgate,
});

export const formikSimulacaoResgateSchemaFactory = (
  valorMinimoResgate: number,
  valorMaximoResgate: number,
  valorMinimoPermanencia: number,
) => {
  return Yup.object().shape({
    tipoResgate: Yup.string().required(CONSTS.MSG_VALIDACAO.CAMPO_OBRIGATORIO),
    valorSolicitado: Yup.string()
      .required(CONSTS.MSG_VALIDACAO.CAMPO_OBRIGATORIO)
      .test(
        CONSTS.MSG_VALIDACAO.VALOR_MINIMO_NOME,
        `Valor Mínimo ${tryGetMonetaryValueOrDefault(valorMinimoResgate)}`,
        value => UTILS.validarValorMinimoSolicitado(value, valorMinimoResgate),
      )
      .test(
        CONSTS.MSG_VALIDACAO.VALOR_MAXIMO_NOME,
        `Valor Máximo ${tryGetMonetaryValueOrDefault(
          valorMaximoResgate - valorMinimoPermanencia,
        )}`,
        (value, context) => {
          const { tipoResgate } = context.parent;

          return UTILS.validarValorMaximoSolicitado(
            value,
            getTernaryResult(
              tipoResgate === CONSTS.ID_PARCIAL,
              valorMaximoResgate - valorMinimoPermanencia,
              valorMaximoResgate,
            ),
          );
        },
      ),
  });
};

const obterDetalhesFundosFactory = (
  fundosParaResgate: IListarFundosParaResgateFundosDisponiveis[],
) => {
  return fundosParaResgate
    .map(fundo => ({
      codigoFundo: fundo.codigoFundo,
      codigoReserva: fundo.codigoReserva,
      valorResgate: fundo.valorRetirar,
    }))
    .filter(fundoComValor => !!fundoComValor.valorResgate);
};

export const payloadCalcularResgateFactory = (
  formik: FormikProps<SOLICITACAO_RESGATE_TYPES.IFormikValuesSimulacaoResgate>,
  aliquota: string,
) => ({
  tipoRegimeTributario: aliquota,
  tipoResgate: getTernaryResult(
    formik.values.tipoResgate === CONSTS.ID_PARCIAL,
    CONSTS.TIPOS_RESGATE.PARCIAL.id,
    CONSTS.TIPOS_RESGATE.TOTAL.id,
  ),
  detalhesFundos: getTernaryResult(
    formik.values.tipoResgate === CONSTS.ID_PARCIAL,
    obterDetalhesFundosFactory(formik.values.listaFundosParaResgate),
    [] as unknown,
  ),
  valorResgateTotal: formik.values.valorSolicitado,
});

const dadosPorAliquotaFactory = (
  isAliquotaProgressiva: boolean,
  dadosSelecaoAliquota:
    | SOLICITACAO_RESGATE_TYPES.IDadosEtapaSelecaoAliquota
    | undefined,
) => {
  return {
    calculo: getTernaryResult(
      isAliquotaProgressiva,
      dadosSelecaoAliquota?.calculoAliquotaProgressiva,
      dadosSelecaoAliquota?.calculoAliquotaRegressiva,
    ),
    detalhamento: getTernaryResult(
      isAliquotaProgressiva,
      dadosSelecaoAliquota?.detalhamentoAliquotaProgressiva,
      dadosSelecaoAliquota?.detalhamentoAliquotaRegressiva,
    ),
    resumo: getTernaryResult(
      isAliquotaProgressiva,
      dadosSelecaoAliquota?.resumoAliquotaProgressiva,
      dadosSelecaoAliquota?.resumoAliquotaRegressiva,
    ),
  };
};

export const obterDadosTabelaDetalheCalculoFactory: SOLICITACAO_RESGATE_TYPES.TObterDadosTabelaDetalheCalculoFactory =
  (featureData, tipoAliquota) => {
    const isAliquotaProgressiva =
      tipoAliquota === ALIQUOTA.TIPO_REGIME_PROGRESSIVO;

    const dadosSelecaoAliquota = featureData?.dadosEtapaSelecaoAliquota;

    const dadosPorAliquota = dadosPorAliquotaFactory(
      isAliquotaProgressiva,
      dadosSelecaoAliquota,
    );

    return [
      ...tryGetValueOrDefault([dadosPorAliquota?.detalhamento?.detalhes], []),
      {
        data: CONSTS.VALOR_TOTAL,
        rendimento: dadosPorAliquota?.detalhamento?.totalRendimento,
        carregamentoSaida:
          dadosPorAliquota?.detalhamento?.totalCarregamentoSaida,
        baseIrrf: dadosPorAliquota?.detalhamento?.totalBaseIrrf,
        valorIrrf: dadosPorAliquota?.detalhamento?.totalValorIrrf,
        taxaSaida: dadosPorAliquota?.detalhamento?.totalTaxaSaida,
        aliquotaIrrf: dadosPorAliquota?.detalhamento?.totalAliquotaIrrf,
        codigoConta: '',
        codigoFundo: '',
        descricaoFundo: '',
        numeroResgate: dadosPorAliquota?.calculo?.numeroResgate,
        saldoAporte: dadosPorAliquota?.detalhamento?.totalValorSolicitado,
        saldoPrincipal: dadosPorAliquota?.detalhamento?.totalSaldoPrincipal,
        rendimentos: dadosPorAliquota?.detalhamento?.totalRendimentosAporte,
        valorCorrecao: dadosPorAliquota?.detalhamento?.totalValorCorrecao,
        valorLiquido: dadosPorAliquota?.detalhamento?.totalValorLiquido,
        valorPrincipal:
          dadosPorAliquota?.detalhamento?.totalValorPrincipalAporte,
        valorSolicitado: dadosPorAliquota?.detalhamento?.totalValorSolicitado,
        id: '',
        codigoEmpresa: '',
      },
    ];
  };

export const obterResumoAliquotaFactory: SOLICITACAO_RESGATE_TYPES.TObterResumoAliquotaFactory =
  (featureData, tipoAliquota) => {
    const isAliquotaProgressiva =
      tipoAliquota === ALIQUOTA.TIPO_REGIME_PROGRESSIVO;

    const dadosSelecaoAliquota = featureData?.dadosEtapaSelecaoAliquota;

    const dadosPorAliquota = dadosPorAliquotaFactory(
      isAliquotaProgressiva,
      dadosSelecaoAliquota,
    );

    return [
      {
        label: `Saldo em ${formatarData(new Date())}:`,
        value:
          featureData?.dadosListarFundosParaResgate?.saldo?.saldoTotal?.toString(),
        mask: 'money',
      },
      {
        label: 'Produto e Modalidade',
        value: `${dadosPorAliquota?.resumo?.descricaoProduto}, ${dadosPorAliquota?.resumo?.tipoProduto}`,
      },
      {
        label: 'Valor simulado:',
        value: dadosPorAliquota?.detalhamento?.totalValorSolicitado,
        mask: 'money',
      },
      {
        label: 'Valor nominal estimado:',
        value: dadosPorAliquota?.detalhamento?.totalSaldoPrincipal,
        mask: 'money',
      },
      {
        label: 'Valor de IR estimado:',
        value: dadosPorAliquota?.detalhamento?.totalValorIrrf,
        mask: 'money',
      },
      {
        label: 'Taxa de carregamento na saída estimada:',
        value: dadosPorAliquota?.detalhamento?.totalCarregamentoSaida,
        mask: 'money',
      },
      {
        label: 'Valor líquido desejado:',
        value: dadosPorAliquota?.detalhamento?.totalValorLiquido,
        mask: 'money',
      },
    ];
  };

export const obterDetalhadoAliquotaFactory: SOLICITACAO_RESGATE_TYPES.TObterDetalhadoAliquotaFactory =
  (featureData, tipoAliquota) => {
    const isAliquotaProgressiva =
      tipoAliquota === ALIQUOTA.TIPO_REGIME_PROGRESSIVO;

    const dadosSelecaoAliquota = featureData?.dadosEtapaSelecaoAliquota;

    const dadosPorAliquota = dadosPorAliquotaFactory(
      isAliquotaProgressiva,
      dadosSelecaoAliquota,
    );

    return [
      {
        label: 'Nome:',
        value: dadosPorAliquota?.resumo?.nomeCliente,
      },
      {
        label: 'Certificado:',
        value: dadosPorAliquota?.resumo?.codigoCertificado,
      },
      {
        label: 'Produto e Modalidade:',
        value: `${dadosPorAliquota?.resumo?.descricaoProduto}, ${dadosPorAliquota?.resumo?.tipoProduto}`,
      },
      {
        label: 'Data e hora da simulação:',
        value: formatarDataHora(String(new Date())),
      },
      {
        label: `Saldo em ${formatarData(new Date())}:`,
        value:
          featureData?.dadosListarFundosParaResgate?.saldo?.saldoTotal.toString(),
        mask: 'money',
      },
      {
        label: 'Valor nominal estimado:',
        value: dadosPorAliquota?.detalhamento?.totalSaldoPrincipal,
        mask: 'money',
      },
      {
        label: 'Valor simulado:',
        value: dadosPorAliquota?.detalhamento?.totalValorSolicitado,
        mask: 'money',
      },
      {
        label: 'Valor do IR estimado:',
        value: dadosPorAliquota?.detalhamento?.totalValorIrrf,
        mask: 'money',
      },
      {
        label: 'Valor líquido estimado:',
        value: dadosPorAliquota?.detalhamento?.totalValorLiquido,
        mask: 'money',
      },
      {
        label: 'Taxa de carregamento na saída estimada:',
        value: dadosPorAliquota?.detalhamento?.totalCarregamentoSaida,
        mask: 'money',
      },
      { label: 'Valor máximo permitido:', value: '0', mask: 'money' },
      {
        label: 'Valor bloqueado:',
        value:
          featureData?.dadosListarFundosParaResgate?.saldo.saldoTotalBloqueado.toString(),
        mask: 'money',
      },
    ];
  };

export const definirTiposContaFactory: SOLICITACAO_RESGATE_TYPES.TDefinirTiposContaFactory =
  formik => {
    if (formik.values.novaConta.banco.value === CODIGO_BANCO_CAIXA)
      return tipoContasBancariasCefMain;

    return tipoContasBancariasNaoCefMain;
  };

export const formikDefinicaoContribuicaoRegularFactory = (
  listaFundosParaContribuicaoRegular: RESPONSE_TYPES.IConsultarContribuicaoRegularFundos[],
) => ({
  listaFundosParaContribuicaoRegular,
});

export const modificarFundoParaContribuicaoRegularSelecionadoFactory = ({
  fundo,
  codigoFundo,
  valorContribuicaoRegularlAtual,
}: SOLICITACAO_RESGATE_TYPES.IModificarFundoParaContribuicaoRegularSelecionadoFactoryParams) => {
  return getTernaryResult(
    fundo.codigoFundo === codigoFundo,
    {
      ...fundo,
      selecionado: !fundo.selecionado,
      valorContribuicao: valorContribuicaoRegularlAtual,
    },
    { ...fundo, selecionado: false, valorContribuicao: 0 },
  );
};

export const distribuirContribuicaoRegularFactory = ({
  fundo,
  codigoFundo,
  valorContribuicao,
}: SOLICITACAO_RESGATE_TYPES.IDistribuirValorContribuicaoRegularFactoryParams) => {
  return getTernaryResult(
    fundo.codigoFundo !== codigoFundo,
    { ...fundo },
    { ...fundo, valorContribuicao },
  );
};

export const criarPayloadParaContribuicaoRegular = (
  listaFundos: RESPONSE_TYPES.IConsultarContribuicaoRegularFundos[],
  numeroResgate: string | undefined,
) => ({
  fundos: listaFundos
    .map(fundo => ({
      codigoFundo: fundo.codigoFundo,
      codigoReserva: fundo.codigoReserva,
      valorContribuicao: tryGetValueOrDefault([fundo.valorContribuicao], 0),
    }))
    .filter(fundoComValor => !!fundoComValor.valorContribuicao),
  numeroResgate: tryGetValueOrDefault([numeroResgate], ''),
});

export const obterDadosRetornoConfirmacaoResgateFactory = (
  dadosConfirmacaoResgate: RESPONSE_TYPES.IConfirmarResgateResponse | undefined,
  numeroResgate: string | undefined,
) => ({
  solicitarAssinaturaEletronica:
    dadosConfirmacaoResgate?.status ===
    CONSTS.STATUS_RESGATE.AGUARDANDO_ASSINATURA,
  statusResgate: tryGetValueOrDefault([dadosConfirmacaoResgate?.status], ''),
  motivoPendenciaResgate: tryGetValueOrDefault(
    [dadosConfirmacaoResgate?.mensagem],
    '',
  ),
  numeroResgate: tryGetValueOrDefault([numeroResgate], ''),
});

export const criarPayloadDadosPagamentoFactory = (
  featureData:
    | SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData
    | null
    | undefined,
  formik: FormikProps<SOLICITACAO_RESGATE_TYPES.IFormikValuesEfetuarResgate>,
) => {
  if (formik.values.isNovaConta) {
    return {
      codigoBanco: formik.values.novaConta.banco.value,
      dataProgramada: parseDateStringToDate(new Date()),
      digitoAgencia: CONSTS.CONTA_BANCARIA.DIGITO_AGENCIA_PADRAO,
      digitoConta: formik.values.novaConta.digitoConta,
      numeroAgencia: formik.values.novaConta.agencia,
      numeroConta: formatarContaSeBancoCaixa({
        codigoBanco: formik.values.novaConta.banco.value,
        agencia: formik.values.novaConta.agencia,
        numeroConta: formik.values.novaConta.conta,
        operacao: formik.values.novaConta.tipoConta.codigo,
      }),
      numeroResgate: Number(featureData?.numeroResgateConsolidado),
      tipoConta: CONSTS.CONTA_BANCARIA.SIGLA_CONTA_CORRENTE,
      tipoPagamento: CONSTS.TIPOS_CANAL_PAGAMENTO.RESGATE_TRANSFERENCIA,
    };
  }

  if (!formik.values.isNovaConta) {
    return {
      codigoBanco: formik.values.contaExistente.codigoBanco,
      dataProgramada: parseDateStringToDate(new Date()),
      digitoAgencia: CONSTS.CONTA_BANCARIA.DIGITO_AGENCIA_PADRAO,
      digitoConta: formik.values.contaExistente.digitoConta,
      numeroAgencia: formik.values.contaExistente.numeroAgencia,
      numeroConta: formik.values.contaExistente.codigoContaBancaria,
      numeroResgate: Number(featureData?.numeroResgateConsolidado),
      tipoConta: CONSTS.CONTA_BANCARIA.SIGLA_CONTA_CORRENTE,
      tipoPagamento: CONSTS.TIPOS_CANAL_PAGAMENTO.RESGATE_TRANSFERENCIA,
    };
  }

  return {};
};

export const obterDetalhamentoAliquotaFactory = async ({
  formik,
  aliquotaAtual,
  calcularResgate,
  consultarResumoAliquota,
  consultarDetalheCalculo,
}: SOLICITACAO_RESGATE_TYPES.IObterDetalhamentoAliquotaFactory) => {
  const retornoCalculo = await calcularResgate(
    payloadCalcularResgateFactory(formik, aliquotaAtual),
  );

  const retornoResumo = await consultarResumoAliquota({
    numeroResgate: retornoCalculo?.entidade?.dadosEncargo.numeroResgate,
  });

  const retornoDetalhes = await consultarDetalheCalculo({
    numeroResgate: retornoCalculo?.entidade?.dadosEncargo.numeroResgate,
  });

  return {
    calculo: retornoCalculo?.entidade?.dadosEncargo,
    resumo: retornoResumo?.entidade,
    detalhado: retornoDetalhes?.entidade,
  };
};

export const criarContextoSelecaoAliquotaFactory = async (
  aliquota: IListarFundosParaResgateAliquota,
  defaultFactoryParams: SOLICITACAO_RESGATE_TYPES.IDefaultFactoryConfirmarSimulacaoParams,
) => {
  let dadosAliquotaPorRegimeProgressivo =
    {} as SOLICITACAO_RESGATE_TYPES.IDadosAliquotaPorRegime;

  let dadosAliquotaPorRegimeRegressivo =
    {} as SOLICITACAO_RESGATE_TYPES.IDadosAliquotaPorRegime;

  if (
    checkIfSomeItemsAreTrue([
      aliquota.aliquotaAtual === ALIQUOTA.TIPO_REGIME_PROGRESSIVO,
      aliquota.indicadorPermiteEditarAliquota,
    ])
  ) {
    dadosAliquotaPorRegimeProgressivo = await obterDetalhamentoAliquotaFactory({
      aliquotaAtual: ALIQUOTA.TIPO_REGIME_PROGRESSIVO,
      ...defaultFactoryParams,
    });
  }

  if (
    checkIfSomeItemsAreTrue([
      aliquota.aliquotaAtual === ALIQUOTA.TIPO_REGIME_REGRESSIVO,
      aliquota.indicadorPermiteEditarAliquota,
    ])
  ) {
    dadosAliquotaPorRegimeRegressivo = await obterDetalhamentoAliquotaFactory({
      aliquotaAtual: ALIQUOTA.TIPO_REGIME_REGRESSIVO,
      ...defaultFactoryParams,
    });
  }

  return {
    calculoAliquotaProgressiva: dadosAliquotaPorRegimeProgressivo.calculo,
    calculoAliquotaRegressiva: dadosAliquotaPorRegimeRegressivo.calculo,
    resumoAliquotaProgressiva: dadosAliquotaPorRegimeProgressivo.resumo,
    resumoAliquotaRegressiva: dadosAliquotaPorRegimeRegressivo.resumo,
    detalhamentoAliquotaProgressiva:
      dadosAliquotaPorRegimeProgressivo.detalhado,
    detalhamentoAliquotaRegressiva: dadosAliquotaPorRegimeRegressivo.detalhado,
  };
};

export const payloadValidarNovaContaFactory = (
  novaConta: SOLICITACAO_RESGATE_TYPES.IFormikValuesEfetuarResgateNovaConta,
) => {
  return {
    codigoAgencia: novaConta.agencia,
    codigoOperacao: novaConta.tipoConta.codigo,
    digitoVerificador: novaConta.digitoConta,
    numeroBanco: CODIGO_BANCO_CAIXA,
    numeroConta: novaConta.conta,
  };
};

export const obterCalculoRegimeFactory = async ({
  tipoRegimeTributario,
  calcularResgate,
  obterAlicotasAgrupadas,
  dadosFundosParaResgate,
}: SOLICITACAO_RESGATE_TYPES.IObterCalculoRegimeFactory) => {
  const dadosCalculo = await calcularResgate({
    valorResgateTotal:
      dadosFundosParaResgate?.saldo?.saldoDisponivelParaResgate,
    TipoRegimeTributario: tipoRegimeTributario,
    TipoResgate: ALIQUOTA.TIPO_RESGATE_TOTAL,
  });

  const listaAliquotaAgrupada = await obterAlicotasAgrupadas({
    numeroResgate: dadosCalculo?.entidade?.dadosEncargo.numeroResgate,
  });

  const lista = listaAliquotaAgrupada?.entidade;

  const fundosAliquota = tryGetValueOrDefault(
    [lista?.flatMap(item => item.fundosAliquota)],
    [],
  );

  const listaFundosAliquotaConsolidada = fundosAliquota.flatMap(fundo => {
    return fundo.valoresAliquotas.map(valorAliquota => ({
      descricaoFundo: fundo.descricaoFundo,
      ...valorAliquota,
    }));
  });

  const fundos: Partial<TAlicotasAgrupadasFundosAliquotaFactory>[] = [
    ...listaFundosAliquotaConsolidada,
    {
      descricaoFundo: CONSTS.VALOR_TOTAL,
      saldoTotal: tryGetValueOrDefault([lista?.[0]?.saldoTotal], ''),
    },
  ];

  return {
    fundos,
    calculo: dadosCalculo?.entidade?.dadosEncargo,
  };
};

export const criarContextoDetalhesAliquotaFactory = async (
  aliquotaAtual: string,
  indicadorPermiteEditarAliquota: boolean,
  defaultFactoryParams: SOLICITACAO_RESGATE_TYPES.IDefaultFactoryDetalhesAliquotaParams,
) => {
  const resultadoAliquotaRegressivo =
    {} as SOLICITACAO_RESGATE_TYPES.IResultadoAliquota;

  const resultadoAliquotaProgressivo =
    {} as SOLICITACAO_RESGATE_TYPES.IResultadoAliquota;

  if (
    checkIfSomeItemsAreTrue([
      aliquotaAtual === ALIQUOTA.TIPO_REGIME_PROGRESSIVO,
      indicadorPermiteEditarAliquota,
    ])
  ) {
    const retornoCalculoRegime = await obterCalculoRegimeFactory({
      tipoRegimeTributario: ALIQUOTA.TIPO_REGIME_PROGRESSIVO,
      ...defaultFactoryParams,
    });

    resultadoAliquotaProgressivo.fundos = UTILS.ordenarListaFundos(
      retornoCalculoRegime.fundos,
    );
    resultadoAliquotaProgressivo.calculo = retornoCalculoRegime.calculo;
  }

  if (
    checkIfSomeItemsAreTrue([
      aliquotaAtual === ALIQUOTA.TIPO_REGIME_REGRESSIVO,
      indicadorPermiteEditarAliquota,
    ])
  ) {
    const retornoCalculoRegime = await obterCalculoRegimeFactory({
      tipoRegimeTributario: ALIQUOTA.TIPO_REGIME_REGRESSIVO,
      ...defaultFactoryParams,
    });

    resultadoAliquotaRegressivo.fundos = UTILS.ordenarListaFundos(
      retornoCalculoRegime.fundos,
    );
    resultadoAliquotaRegressivo.calculo = retornoCalculoRegime.calculo;
  }

  return { resultadoAliquotaRegressivo, resultadoAliquotaProgressivo };
};

export const obterBancosCorporativoXPrevFactory = (
  dadosRecuperarBancos: RESPONSE_TYPES.IRecuperarBancosResponse[] | undefined,
) => {
  const listaBancosServicoCorporativo = tryGetValueOrDefault(
    [
      dadosRecuperarBancos?.map(banco => ({
        ...banco,
        codigoBanco: String(banco.codigoBanco).padStart(3, '0'),
      })),
    ],
    [],
  );

  const listaBancosCorporativoEPrev = listaBancosServicoCorporativo?.filter(
    bancoListaCorporativa =>
      LISTA_BANCOS_BASE_PREV.some(
        bancoListaPrev =>
          bancoListaCorporativa.codigoBanco === bancoListaPrev.codigoBanco,
      ),
  );

  const listaDiferencaBancosEntreOrigens = LISTA_BANCOS_BASE_PREV.filter(
    bancoListaPrev =>
      !listaBancosServicoCorporativo.some(
        bancoListaCorporativa =>
          bancoListaCorporativa.codigoBanco === bancoListaPrev.codigoBanco,
      ),
  );

  const listaBancosConsolidada = [
    ...listaBancosCorporativoEPrev,
    ...listaDiferencaBancosEntreOrigens,
  ];

  return listaBancosConsolidada.sort((a, b) => {
    return a.codigoBanco.localeCompare(b.codigoBanco);
  });
};

export const listarTiposResgateFiltroFactory = (
  saldoTotalBloqueado: number,
): SOLICITACAO_RESGATE_TYPES.IListarTiposResgateFiltroReturnFactoryParams[] => {
  return CONSTS.TIPO_RESGATE.filter(item => {
    if (saldoTotalBloqueado) return item.id === CONSTS.ID_PARCIAL;

    return item;
  });
};
