import { Text } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import { GridItem } from 'seguros/components/CardResumoProduto/InfoGridItemImpressao/styled';

type InfoGridItemProps = {
  icon: string;
  label: string;
  value: string | React.ReactElement | null;
};

export const InfoGridItemImpressao: React.FC<InfoGridItemProps> = ({
  icon,
  label,
  value,
}: InfoGridItemProps) => (
  <GridItem xs={1} md={1 / 2} lg={1 / 2} xl={1 / 3}>
    <Icon name={icon} />
    <div>
      <Text variant="caption-02" color="text-light">
        {label}
      </Text>
      <Text variant="caption-02" color="text">
        {value}
      </Text>
    </div>
  </GridItem>
);
