import styled from 'styled-components/macro';

export const Ul = styled.ul`
  margin: 20px 0px;
  list-style-position: inside;
`;

export const Li = styled.li`
  margin: 10px 0px;
`;
