import React from 'react';
import { Button, Text } from '@cvp/design-system/react';
import { formatarData } from 'main/utils';
import { converterBase64 } from 'main/utils/converterBase64';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';
import * as S from 'previdencia/features/Aporte/styles';

const ComprovanteAporteViaBoleto: React.FC = () => {
  const { featureData } =
    usePrevidenciaContext<APORTE_TYPES.IAporteContextData>();

  const responseEfetuarAporte = featureData?.dadosAporteAdicional?.entidade;

  const gerarBoletoPdf = (): void => {
    const blob = converterBase64(responseEfetuarAporte?.boletoPdf);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  };

  return (
    <>
      <S.ContainerBrancoAporte>
        <Text variant="body02-sm">Numero da Cobrança:</Text>
        <Text variant="body02-sm">
          {responseEfetuarAporte?.dadosBoleto?.nossoNumero}
        </Text>
      </S.ContainerBrancoAporte>
      <S.ContainerCinzaAporte>
        <Text variant="body02-sm">Data de Vencimento:</Text>
        <Text variant="body02-sm">
          {formatarData(responseEfetuarAporte?.dadosBoleto?.dataVencimento)}
        </Text>
      </S.ContainerCinzaAporte>
      <S.ContainerBrancoAporte>
        <Text variant="body02-sm">Linha Digitável:</Text>
        <Text variant="body02-sm">
          {tryGetValueOrDefault(
            [responseEfetuarAporte?.dadosBoleto?.linhaDigitavel],
            '',
          )}
        </Text>
      </S.ContainerBrancoAporte>
      <S.ContainerAporte>
        <S.ActionsAporte>
          <Button variant="outlined" onClick={gerarBoletoPdf}>
            Gerar Boleto
          </Button>
        </S.ActionsAporte>
      </S.ContainerAporte>
    </>
  );
};
export default ComprovanteAporteViaBoleto;
