import styled from 'styled-components/macro';
import { Display as DisplayDS } from '@cvp/design-system/react';

export const ContainerComplement = styled(DisplayDS)(
  ({ theme: { color } }) => ({
    display: 'flex',
    flexDirection: 'column',
    background: `${color.brandPrimary.light}26`,
    padding: '10px',
    borderRadius: '8px',
    margin: '0 0 20px 0',
    width: '100%',
    transition: 'ease all 250ms',

    '&:hover': {
      background: `${color.brandPrimary.light}4d`,
      transition: 'ease all 250ms',
    },

    '& p': {
      margin: 0,
    },

    '& span': {
      fontWeight: 'bold',
    },
  }),
);
