import { obterEndpointDefinirReservaDestino } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import {
  ReservasDestinoRequest,
  ResponseDefinirReservaDestino,
} from '../types/DefinirReservaDestino.types';

export const definirReservaDestino = async (
  cpf: string,
  codContaOrigem: string,
  numTransferencia: string | undefined,
  reservasDestino: ReservasDestinoRequest[] | undefined,
): Promise<ResponseDefinirReservaDestino | undefined> => {
  if (reservasDestino && numTransferencia) {
    const { data } = await api.post<
      IApiResponse<ResponseDefinirReservaDestino>
    >(obterEndpointDefinirReservaDestino(), {
      cpf,
      codContaOrigem,
      numTransferencia,
      reservasDestino,
    });

    if (data?.dados?.sucesso === false)
      throw new Error(
        data?.dados?.mensagens && data?.dados?.mensagens[0]?.descricao,
      );

    return data.dados.entidade;
  }

  return undefined;
};
