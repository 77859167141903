import styled from 'styled-components/macro';

export const DetalhesPlano = styled.div({
  '.saldo': {
    alignItems: 'center',
  },
});

export const Title = styled.div(({ theme }) => ({
  borderBottom: `2px solid ${theme.color.neutral['02']}`,
}));
