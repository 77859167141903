import Text from 'main/components/PEComponents/Text';
import Display from 'main/components/PEComponents/Display';
import Radio from 'main/components/PEComponents/Radio';
import Card from 'main/components/PEComponents/Card';
import Divider from 'main/components/PEComponents/Divider';
import Button from 'main/components/PEComponents/Button';
import Skeleton from 'main/components/PEComponents/Skeleton';
import Checkbox from 'main/components/PEComponents/Checkbox';
import Grid from 'main/components/PEComponents/Grid';
import Container from 'main/components/PEComponents/Container';
import Accordion from 'main/components/PEComponents/Accordion';
import Disclaimer from 'main/components/PEComponents/Disclaimer';
import Modal from 'main/components/PEComponents/Modal';
import Select from 'main/components/PEComponents/Select';
import Switch from 'main/components/PEComponents/Switch';
import Tabs from 'main/components/PEComponents/Tabs';
import Tag from 'main/components/PEComponents/Tag';
import TextField from 'main/components/PEComponents/TextField';
import StepBar from 'main/components/PEComponents/StepBar';
import SearchField from 'main/components/PEComponents/SearchField';
import QuickAccess from 'main/components/PEComponents/QuickAccess';
import Progress from 'main/components/PEComponents/Progress';
import Pagination from 'main/components/PEComponents/Pagination';
import Bullets from 'main/components/PEComponents/Bullets';
import Breadcrumb from 'main/components/PEComponents/Breadcrumb';
import Avatar from 'main/components/PEComponents/Avatar';
import ToggleButtons from 'main/components/PEComponents/ToggleButtons';

interface PEComponents {
  Text: any;
  Display: any;
  Radio: any;
  Card: any;
  Divider: any;
  Button: any;
  Skeleton: any;
  Checkbox: any;
  Grid: any;
  Container: any;
  Accordion: any;
  Disclaimer: any;
  Modal: any;
  Select: any;
  Switch: any;
  Tabs: any;
  Tag: any;
  TextField: any;
  StepBar: any;
  SearchField: any;
  QuickAccess: any;
  Progress: any;
  Pagination: any;
  Bullets: any;
  Breadcrumb: any;
  Avatar: any;
  ToggleButtons: any;
}

export const PE: PEComponents = {
  Text,
  Display,
  Radio,
  Card,
  Divider,
  Button,
  Skeleton,
  Checkbox,
  Grid,
  Container,
  Accordion,
  Disclaimer,
  Modal,
  Select,
  Switch,
  Tabs,
  Tag,
  TextField,
  StepBar,
  SearchField,
  QuickAccess,
  Progress,
  Pagination,
  Bullets,
  Breadcrumb,
  Avatar,
  ToggleButtons,
};
