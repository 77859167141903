import React, { useContext } from 'react';
import { Text } from '@cvp/design-system/react';
import { obterChaveArmazenamentoTokenCliente } from 'config/storage_keys';
import { AppContext } from 'main/contexts/AppContext';
import { getSessionItem } from 'main/utils/storage';
import { capitalize } from 'main/utils/string';
import { maskCpfCnpj } from 'contratosPrestamista/utils/maskCpfCnpj';
import { IMatrizAcessoCliente } from 'previdencia/types/MatrizDeAcesso';
import * as S from 'previdencia/features/Aporte/styles';

const ComprovanteAporteParticipante: React.FC = () => {
  const tokenCliente = obterChaveArmazenamentoTokenCliente();
  const resultTokenCliente = getSessionItem<IMatrizAcessoCliente>(tokenCliente);
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);

  return (
    <>
      <S.ContainerListaAporte>
        <Text variant="body02-lg">Dados do Participante</Text>
        <S.ContainerCinzaAporte>
          <Text variant="body02-sm">Nome do Cliente </Text>
          <Text variant="body02-sm">
            {capitalize(resultTokenCliente.matrizAcessoCliente.nome)}
          </Text>
        </S.ContainerCinzaAporte>
        <S.ContainerBrancoAporte>
          <Text variant="body02-sm">Número do CPF</Text>
          <Text variant="body02-sm">{maskCpfCnpj(cpfCnpj)}</Text>
        </S.ContainerBrancoAporte>
      </S.ContainerListaAporte>
    </>
  );
};
export default ComprovanteAporteParticipante;
