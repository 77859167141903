import styled from 'styled-components';
import { Display, Button, Text } from '@cvp/design-system/react';

export const SelectContainer = styled.div({
  marginTop: 15,
});

export const TextAlign = styled(Text)({
  margin: '30px 20px 0px 20px',
});

export const DisplayContainer = styled(Display)({
  display: 'flex',
  alignItems: 'center',
});

export const ButtonStyle = styled(Button)({
  marginTop: 30,
  marginLeft: 10,
});
