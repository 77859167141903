import {
  PRINCIPAL_USERS,
  TODOS_USUARIOS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import { IMenuItem } from 'main/components/Menu/Sidebar/types';

export const menuAtendimento: IMenuItem = {
  label: 'Atendimento',
  alt: 'atendimento',
  path: '/atendimento',
  icon: 'contact',
  roles: [
    ...TODOS_USUARIOS,
    USER_PROFILES.SAC_CAIXA_TERCEIROS,
    USER_PROFILES.ANALISTA_MANUTENCAO,
    USER_PROFILES.ANALISTA_SAIDA,
    USER_PROFILES.ANALISTA_ENTRADA,
    USER_PROFILES.ANALISTA_CONSULTA,
  ],
  subItems: [
    {
      label: 'Ocorrências - ASC',
      path: '/ocorrencias',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.SAC_CAIXA_TERCEIROS,
        USER_PROFILES.ANALISTA_MANUTENCAO,
        USER_PROFILES.ANALISTA_SAIDA,
        USER_PROFILES.ANALISTA_ENTRADA,
        USER_PROFILES.ANALISTA_CONSULTA,
      ],
      subItems: [
        {
          label: 'Registrar ocorrência',
          path: '/registro-ocorrencias/registrar-ocorrencia',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.SAC_CAIXA_TERCEIROS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
            USER_PROFILES.ANALISTA_CONSULTA,
          ],
        },
        {
          label: 'Consultar ocorrência',
          path: '/registro-ocorrencias/consultar-ocorrencia',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.SAC_CAIXA_TERCEIROS,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
            USER_PROFILES.ANALISTA_CONSULTA,
          ],
        },
      ],
    },
    {
      label: 'Webchat',
      path: '/atendimento/webchat',
      roles: [
        ...TODOS_USUARIOS,
        USER_PROFILES.ANALISTA_ENTRADA,
        USER_PROFILES.ANALISTA_CONSULTA,
      ],
    },
    {
      label: 'Consulta cadastro Portal do cliente',
      path: '/atendimento/area-logada',
      roles: [USER_PROFILES.ANALISTA_TI],
    },
  ],
};
