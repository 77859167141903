import styled from 'styled-components/macro';
import {
  Modal as ModalBase,
  Button as ButtonBase,
  TextField as TextFieldBase,
  Text as TextBase,
  Display,
  Grid,
} from '@cvp/design-system/react';
import Select from 'main/components/form/Select';

export const LoadingBottomDocuSign = styled.div({
  position: 'fixed',
  boxSizing: 'border-box',
  margin: '32px -25px',
  paddingLeft: '0',
  zIndex: 9998,
  right: 0,
  bottom: 40,
});

export const ContainerDadosBancarios = styled(Grid)(({ theme }) => ({
  margin: '5px 0px',
}));

export const ItemListaDadosBancarios = styled(Display)(
  ({ theme: { breakpoint } }) => ({
    display: 'flex',
    alignItems: 'center',

    'span, strong, p': {
      margin: '5px',
    },
  }),
);

export const GridItem = styled(Grid.Item)(({ marginFormDadosBancarios }) => ({
  padding: '5px 12px 5px 0px',

  '& > div': {
    margin: marginFormDadosBancarios && '0px',
  },

  '& > p, & > div > label': {
    marginBottom: '5px',
    textAlign: 'left',
    width: '100%',
  },

  '& > div > div > input': {
    height: '50px',
  },
}));

export const MoldaConfirmarContatos = styled(ModalBase)<{
  showCloseButton?: boolean;
}>(({ theme: { breakpoint }, showCloseButton = true }) => ({
  '&&': {
    [breakpoint.lg()]: {
      minWidth: '600px',
      minHeight: '500px',
    },
    '> button: nth-child(1)': {
      display: showCloseButton ? 'inline-block' : 'none',
    },
  },
}));

export const ModalWithoutCloseButton = styled(ModalBase)<{
  showCloseButton: boolean;
  small: boolean;
}>(({ showCloseButton, small }) => ({
  '&&': {
    '> button': {
      display: showCloseButton ? 'inline-block' : 'none',
    },
  },
  maxWidth: small ? '440px' : '800px',
}));

export const ModalDocuSign = styled(ModalWithoutCloseButton)(
  ({ theme: { breakpoint } }) => ({
    '&&': {
      [breakpoint.lg()]: {
        minWidth: '800px',
        minHeight: '700px',
      },
    },
  }),
);

export const Content = styled.div({
  paddingBottom: '3.5rem',
});

export const LoadignModalAssinaturaDocuSign = styled.div({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '100%',
  left: '0',
});

export const ButtonLinkDocuSign = styled(ButtonBase)({
  minWidth: '40px',
  padding: 0,
});

export const MensagemObrigatoriaDestaqueDocuSing = styled.span({
  fontWeight: 'bold',
});

export const MensagemObrigatoriaDestaqueConcluirDocuSign = styled.span({
  fontStyle: 'italic',
});

export const TextField = styled(TextFieldBase)(() => ({
  margin: '12px 8px',
}));

export const SelectInstituicaoBancaria = styled(Select)<{ width?: number }>(
  ({ width }) => ({
    marginLeft: 16,
    width: width || 360,
  }),
);

export const ContainerButton = styled(Display)`
  margin-bottom: 20px;
`;

export const ContainerContatos = styled.div`
  margin: 15px 0;
`;

export const Text = styled(TextBase)<{
  fWeight: string;
  mt: string | number;
}>(({ fWeight, mt }) => ({
  fontWeight: fWeight,
  marginTop: mt,

  '&& > span': {
    fontWeight: 'bold',
  },
}));

export const LabelTipoDocusignError = styled.strong`
  color: ${props => props.theme.color.feedback.error};
`;
