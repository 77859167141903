import { useState } from 'react';
import { FormikProps, useFormik } from 'formik';

import { tryGetValueOrDefault } from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import * as FACTORY from 'previdencia/features/SolicitacaoResgate/factories/solicitacaoResgateFactory';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as RESPONSE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgateResponse';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const useDefinirContribuicao = () => {
  const { featureData } =
    usePrevidenciaContext<SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData>();

  const [selectedTableInputFocus, setSelectedTableInputFocus] =
    useState<string>('');

  const { valorContribuicaoRegularlAtual, fundos } = tryGetValueOrDefault(
    [featureData?.dadosConsultaContribuicaoRegular],
    {} as RESPONSE_TYPES.IConsultarContribuicaoRegularResponse,
  );

  const initialStateFormik = FACTORY.formikDefinicaoContribuicaoRegularFactory([
    ...fundos,
  ]);

  const formik: FormikProps<SOLICITACAO_RESGATE_TYPES.IFormikValuesDefinicaoContribuicaoRegular> =
    useFormik<SOLICITACAO_RESGATE_TYPES.IFormikValuesDefinicaoContribuicaoRegular>(
      {
        enableReinitialize: true,
        initialValues: initialStateFormik,
        validateOnMount: true,
        onSubmit: () => undefined,
      },
    );

  const handleFundoSelecionado = (codigoFundo: string): void => {
    formik.setValues({
      ...formik.values,
      listaFundosParaContribuicaoRegular:
        formik.values.listaFundosParaContribuicaoRegular.map(fundo =>
          FACTORY.modificarFundoParaContribuicaoRegularSelecionadoFactory({
            fundo,
            codigoFundo,
            valorContribuicaoRegularlAtual,
          }),
        ),
    });

    setSelectedTableInputFocus(codigoFundo);
  };

  const handleValorContribuicao = (
    codigoFundo: string,
    valorContribuicao: string,
  ): void => {
    formik.setValues({
      ...formik.values,
      listaFundosParaContribuicaoRegular:
        formik.values.listaFundosParaContribuicaoRegular.map(fundo =>
          FACTORY.distribuirContribuicaoRegularFactory({
            fundo,
            codigoFundo,
            valorContribuicao: Number(valorContribuicao),
          }),
        ),
    });
  };

  const colunas = CONSTS.COLUNAS_DEFINIR_CONTRIBUICAO({
    handleFundoSelecionado,
    selectedTableInputFocus,
    setSelectedTableInputFocus,
    handleValorContribuicao,
  });

  return {
    formik,
    colunas,
  };
};

export default useDefinirContribuicao;
