import styled from 'styled-components/macro';
import { Button } from '@cvp/design-system/react';

export const Text = styled.p({
  fontSize: '16px',
  lineHeight: '24px',
  marginBottom: '55px',
});

export const GoBackButton = styled(Button)(
  ({ theme: { breakpoint, color } }) => ({
    display: 'flex',
    position: 'absolute',
    left: '10px',
    top: '10px',

    '& > :first-child': {
      marginRight: '13.5px',
    },
    span: {
      img: {
        color: color.line.light,
      },
    },
    [breakpoint.lg()]: {
      position: 'initial',
    },
  }),
);

export const Title = styled.p(({ theme: { breakpoint, color } }) => ({
  marginBottom: '16px',
  fontSize: '28px',
  lineHeight: '42px',
  fontWeight: 400,
  color: color.line.secondary,
  [breakpoint.lg()]: {
    fontSize: '32px',
    lineHeight: '48px',
  },
}));

export const Container = styled.div({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'white',
});

export const Main = styled.div(({ theme: { breakpoint } }) => ({
  width: '100%',
  maxWidth: '1080px',
  minHeight: 'calc(100vh - 181px)',
  padding: '0 15px',
  paddingTop: '80px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [breakpoint.lg()]: {
    paddingTop: '60px',
  },
}));

export const Logo = styled.img(({ theme: { breakpoint } }) => ({
  width: '100%',
  maxWidth: '200px',
  height: 'auto',
  marginBottom: '45px',

  [breakpoint.lg()]: {
    alignSelf: 'flex-start',
  },
}));

export const ServiceErrorSVG = styled.img(({ theme: { breakpoint } }) => ({
  width: '100vw',

  [breakpoint.lg()]: {
    width: '50%',
  },
}));

export const Wrapper = styled.div(({ theme: { breakpoint } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  [breakpoint.lg()]: {
    flexDirection: 'row',
  },
}));

export const TextWrapper = styled.div(({ theme: { breakpoint } }) => ({
  maxWidth: 'unset',
  textAlign: 'center',
  [breakpoint.lg()]: {
    textAlign: 'left',
    maxWidth: '480PX',
  },
}));
