import React from 'react';
import {
  Accordion,
  Card,
  Grid,
  Text,
  Display,
  Tag,
} from '@cvp/design-system/react';
import { TextLabel } from 'main/styles/GlobalStyle';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { formatarCnpj } from 'main/utils/cpf_cnpj';
import { DetalheTarefaCardSkeleton } from './DetalheTarefaCardSkeleton';
import HistoricoSituacaoPortabilidade from './HistoricoSituacaoPortabilidade';
import CardFallback from './CardFallback';
import { IDadosCardProps } from '../../types/IDadosCardProps';

const DadosDaPortabilidade: React.FunctionComponent<IDadosCardProps> = ({
  isLoading,
  dadosPortabilidade,
}) => {
  if (isLoading) {
    return <DetalheTarefaCardSkeleton />;
  }
  if (!isLoading && dadosPortabilidade) {
    return (
      <Display type="display-block">
        <Card>
          <Card.Content>
            <Accordion data-testid="accordionDadosPortabilidade">
              <Accordion.Item title="Dados da solicitação de portabilidade">
                <Grid>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Número Portabilidade
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="numeroPortabilidade"
                      >
                        {dadosPortabilidade?.portabilidade.numeroPortabilidade}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Valor Portabilidade
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="valorPortabilidade"
                      >
                        {formatarValorPadraoBrasileiro(
                          dadosPortabilidade?.portabilidade.valorPortabilidade,
                        )}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Nome da entidade cessionária
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="nomeEntidadeCessionaria"
                      >
                        {
                          dadosPortabilidade?.portabilidade
                            .nomeEntidadeCessionaria
                        }
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        CNPJ Fundo Cedente
                      </TextLabel>
                      <Text variant="body02-sm" data-testid="cnpjFundoCedente">
                        {formatarCnpj(
                          dadosPortabilidade?.portabilidade.cnpjFundoCedente ??
                            '',
                        )}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">Nome do Fundo</TextLabel>
                      <Text variant="body02-sm" data-testid="nomeFundo">
                        {dadosPortabilidade?.contrato.nomeFundoAtual}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        CNPJ Fundo Cessionário
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="cnpjFundoCessionario"
                      >
                        {formatarCnpj(
                          dadosPortabilidade?.portabilidade
                            .cnpjFundoCessionario ?? '',
                        )}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 2}>
                    <div>
                      <TextLabel variant="body02-sm">Fundos</TextLabel>
                      <Display variant="body02-sm" data-testid="fundos">
                        {dadosPortabilidade?.contrato.familiaFundosAtual
                          .split(';')
                          .map(item => (
                            <Tag key={item} variant="primary" value={item} />
                          ))}
                      </Display>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Histórico da situação
                      </TextLabel>
                      <HistoricoSituacaoPortabilidade
                        dadosPortabilidade={dadosPortabilidade}
                      />
                    </div>
                  </Grid.Item>
                </Grid>
              </Accordion.Item>
            </Accordion>
          </Card.Content>
        </Card>
      </Display>
    );
  }

  return <CardFallback title="Dados da solicitação de portabilidade" />;
};

export { DadosDaPortabilidade };
