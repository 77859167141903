import styled from 'styled-components/macro';
import { Grid } from '@cvp/design-system/react';

export const TextClientName = styled.div(() => ({
  maxWidth: '100%',
  overflow: 'hidden',
  fontSize: '12px',
  whitespace: 'nowrap',
}));

export const GridItem = styled(Grid.Item)`
  height: 40px;
  padding-bottom: 10px;
`;
