import { useEffect } from 'react';
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import { useSimulacaoRendaContextData } from 'previdencia/features/SimulacaoRenda/hooks/useSimulacaoRendaContextData';
import useSimularBeneficio from 'previdencia/features/SimulacaoRenda/hooks/useSimularBeneficio';
import { ETipoTributacao } from 'previdencia/features/SimulacaoRenda/types/enum';
import usePrevSummary from 'previdencia/hooks/usePrevSummary';

export const useSimularMultipolosBeneficios = () => {
  const {
    featureData: { podeAlterarRegimeTributario, requestSimularRenda },
    exibirModal,
  } = useSimulacaoRendaContextData();
  const { informacaoPorCertificado } = usePrevSummary();

  const requestSimulacaoProgressivo = useSimularBeneficio({
    ...requestSimularRenda,
    tipoTributacao: ETipoTributacao.PROGRESSIVA,
  });
  const requestSimulacaoRegressivo = useSimularBeneficio({
    ...requestSimularRenda,
    tipoTributacao: ETipoTributacao.REGRESSIVA,
  });

  const regimeTributario = tryGetValueOrDefault(
    [informacaoPorCertificado?.opcaoImpostoTipo],
    '',
  );

  useEffect(() => {
    if (regimeTributario) {
      const regimeAtual = getTernaryResult(
        regimeTributario === ETipoTributacao.PROGRESSIVA,
        requestSimulacaoProgressivo,
        requestSimulacaoRegressivo,
      );

      const regimeDiferente = getTernaryResult(
        regimeTributario === ETipoTributacao.PROGRESSIVA,
        requestSimulacaoRegressivo,
        requestSimulacaoProgressivo,
      );
      if (podeAlterarRegimeTributario) {
        regimeDiferente.fetchData();
      } else {
        exibirModal(true);
      }
      regimeAtual.fetchData();
    }
  }, [podeAlterarRegimeTributario, regimeTributario]);

  return {
    requestSimulacaoProgressivo,
    requestSimulacaoRegressivo,
  };
};
