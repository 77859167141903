import { FormikProps } from 'formik';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';

export function showErrorInput<T>(formik: FormikProps<T>, field: string) {
  const formikErrorField = formik.errors[field as keyof T];

  if (
    checkIfAllItemsAreTrue([
      !!formik.touched[field as keyof T],
      !!formikErrorField,
    ])
  ) {
    return {
      error: formikErrorField,
      errorMessage: formikErrorField,
    };
  }

  return {};
}
