import { useState } from 'react';
import { obterChaveArmazenamentoDadosFuncionalidades } from 'config/storage_keys';
import {
  getSessionItem,
  removeSessionItem,
  setSessionItem,
} from 'main/utils/storage';

export const useFeatureData = () => {
  const FEATURE_DATA_KEY = obterChaveArmazenamentoDadosFuncionalidades();
  const [featureData, setFeatureDataInMemory] = useState<unknown | null>(() => {
    const localFeatureData = getSessionItem(FEATURE_DATA_KEY);
    if (localFeatureData) return localFeatureData;
    return {} as unknown;
  });

  const setFeatureData = (data: unknown) => {
    setFeatureDataInMemory(data);
    setSessionItem(FEATURE_DATA_KEY, data);
  };

  const clearFeatureData = () => {
    setFeatureDataInMemory({});
    removeSessionItem(FEATURE_DATA_KEY);
  };

  return {
    featureData,
    setFeatureData,
    clearFeatureData,
  };
};
