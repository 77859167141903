export const obterBasePathArquivosExtranet = (): string =>
  '//statics.caixavidaeprevidencia.com.br/extranet';

export const obterUrlDoArquivoDesempenhoFundosInvestimento = (): string =>
  `${obterBasePathArquivosExtranet()}/Previdencia/Fundos_invest_prev/Relatorio_Gestao.pdf`;

export const obterUrlArquivoCarteiraDiaria = (): string =>
  `${obterBasePathArquivosExtranet()}/Previdencia/Fundos_invest_prev/Carteiras_Diarias_CVP.pdf`;

export const obterUrlArquivoPortifolioFundosInvestimentoPF = (): string =>
  `${obterBasePathArquivosExtranet()}/Previdencia/Fundos_invest_prev/Portfolio_Novos_Fundos.pdf`;

export const obterUrlArquivoPortifolioFundosInvestimentoPJ = (): string =>
  `${obterBasePathArquivosExtranet()}/Previdencia/Fundos_invest_prev/fundos_PJ.pdf`;

export const obterUrlImagemGraficoComposicaoCarteiras = (): string =>
  `${obterBasePathArquivosExtranet()}/Previdencia/Fundos_invest_prev/grafico_composicao_carteiras.jpg`;
