import { Card, Display, Divider, Grid, Text } from '@cvp/design-system/react';
import ListarFops from 'extranet/components/ListarFops/ListarFops';
import { useFormFops } from 'extranet/hooks/useFormFops';

import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { LinkFop } from 'main/styles/GlobalStyle';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useFop223Context } from '../context/Fop223Context';
import { IResponseObterListaFopsAtivos } from 'main/features/Administracao/types/IFops';

const FopPrevidencia: React.FC = () => {
  const { loadingFops, responseFops } = useFormFops();
  const history = useHistory();
  const { setIsDigitalAgency } = useFop223Context();
  const digitalAgency = !!window.localStorage.getItem(
    '@portal-eco:DigitalAgency',
  );

  useEffect(() => {
    setIsDigitalAgency(digitalAgency);
  }, []);

  if (loadingFops) {
    return <SkeletonLoading blocks={4} />;
  }
  const responseEntidade = responseFops?.entidade;

  const fop64 = tryGetValueOrDefault(
    [responseEntidade],
    [] as IResponseObterListaFopsAtivos[],
  ).filter(el => el.codigo === 64);

  const goToFOP63 = () => {
    history.push({
      pathname: '/extranet/fop-063',
      state: {
        from: 'LISTA_FOPS',
        dataLocation: fop64,
      },
    });
  };

  const goToFOP62 = () => {
    history.push({
      pathname: '/extranet/fop-062',
      state: {
        from: 'LISTA_FOPS',
        dataLocation: fop64,
      },
    });
  };
  const goToFOP223 = () => {
    history.push({
      pathname: '/extranet/fop-223',
      state: {
        from: 'LISTA_FOPS',
      },
    });
  };

  return (
    <Display type="display-block">
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Grid>
            <Grid.Item xs={1}>
              <Text
                variant="headline-05"
                color="primary"
                key="formulario-titulo"
              >
                Formulários (FOPs) de Previdência
              </Text>
            </Grid.Item>
          </Grid>
          <Divider />
          <Text variant="body01-lg" color="primary" margin>
            <strong> Mais utilizados - Manutenção</strong>
          </Text>
          <Text variant="body02-md" margin>
            Estes processos já podem ser executados de forma digital no Serviços
            Online.
          </Text>
          <RenderConditional condition={!!digitalAgency}>
            <Text variant="body01-md" color="primary" margin>
              <LinkFop
                variant="text"
                tipoFop="prev_manutencao"
                onClick={goToFOP223}
              >
                <b>FOP 223 03 - Digital </b> - 08/2023 - Solicitação de
                Transferência com Alteração de Taxa
              </LinkFop>
            </Text>
          </RenderConditional>

          <ListarFops tipoFop="prev_manutencao" dataToList={responseEntidade} />

          <Text variant="body01-lg" color="primary" margin>
            Adesão
          </Text>

          <ListarFops tipoFop="prev_adesao" dataToList={responseEntidade} />
          <Text variant="body02-md" color="primary" margin>
            Saída
          </Text>
          <ListarFops tipoFop="prev_saida" dataToList={responseEntidade} />
          <Text variant="body02-md" color="primary" margin>
            Pessoa Jurídica
          </Text>
          <Text variant="body01-sm" color="primary" margin>
            <LinkFop variant="text" onClick={goToFOP62}>
              <b>FOP 062 07 </b>- 02/2019 - Solicitação de Estudo de Plano Prev
              Empresarial
            </LinkFop>
          </Text>
          <Text variant="body01-sm" color="primary" margin>
            <LinkFop variant="text" onClick={goToFOP63}>
              <b>FOP 063 11 </b>- 02/2019 - Solicitação para Elaboração de
              Contrato de Plano Empresarial
            </LinkFop>
          </Text>
          <ListarFops tipoFop="prev_pj" dataToList={responseEntidade} />
          <Text variant="body02-md" color="primary" margin>
            Outros Documentos
          </Text>
          <ListarFops tipoFop="prev_outros" dataToList={responseEntidade} />
          <ListarFops tipoFop="corp_outros" dataToList={responseEntidade} />
          <Divider />
        </Card.Content>
      </Card>
    </Display>
  );
};
export default FopPrevidencia;
