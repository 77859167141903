import styled from 'styled-components/macro';

export const ContainerResumoCliente = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0px 50px;
  margin-bottom: 50px;
`;

export const ItemResumoCliente = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px',
  borderBottom: '1px solid #ccc',
  textAlign: 'left',
  transition: '200ms',

  '&:hover': {
    backgroundColor: theme.color.neutral['06'],
    transition: '200ms',
  },
}));
