import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { useContext } from 'react';
import * as PECO_TYPES from '../types/CancelamentoUsePeco';
import * as REQUEST_TYPES from '../types/IRequestCancelamento';
import * as RESPONSE_TYPES from '../types/IResponseCancelamento';

export const useValidarCancelamentoPrevidencia =
  (): PECO_TYPES.UseValidarCancelamentoPrevidencia => {
    const {
      cliente: { numCertificado },
    } = useContext(AppContext);

    const { response, loading: loadingValidarCancelamento } = usePeco<
      REQUEST_TYPES.IRequestValidarCancelamento,
      RESPONSE_TYPES.IResponseValidarCancelamento
    >({
      api: { operationPath: PECOS.ValidarCancelamentoPreviencia },
      autoFetch: true,
      payload: {
        certificado: numCertificado,
      },
    });
    const responseValidarCancelamento = response?.entidade;

    return { loadingValidarCancelamento, responseValidarCancelamento };
  };
