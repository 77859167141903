import Accordion from 'main/components/PEComponents/Accordion/Accordion';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { TableSemHeader } from 'prestamista/components/TabelaSemHeader';
import {
  COLUNAS_TABELA_COBERTURAS,
  SEM_DADOS_COBERTURAS,
} from 'prestamista/features/dadosSeguro/constants/Coverturas';
import React from 'react';
import { ICoberturasProps } from 'prestamista/features/dadosSeguro/types/ICoberturas';
import { CoberturasSkeleton } from 'prestamista/features/dadosSeguro/components/Coberturas';

export const Coberturas: React.FC<ICoberturasProps> = ({ data, loading }) => {
  if (loading) return <CoberturasSkeleton />;
  return (
    <Accordion open>
      <Accordion.Item
        title="Coberturas"
        key="Coberturas"
        data-testid="tabela-coberturas"
      >
        <TableSemHeader
          noHeader
          responsive
          striped
          columns={COLUNAS_TABELA_COBERTURAS}
          data={tryGetValueOrDefault([data], [])}
          noDataComponent={SEM_DADOS_COBERTURAS}
          pagination
          paginationPerPage={10}
          paginationComponentOptions={{
            rowsPerPageText: 'Itens por página',
            rangeSeparatorText: 'de',
          }}
        />
      </Accordion.Item>
    </Accordion>
  );
};
