import { getFieldErrorMessage } from 'main/features/prospeccao/features/questionario/utils';
import masks from 'main/utils/masks';
import { DefaultFormFieldProps } from 'reter/features/retencao/types/DefaultFormFieldProps';

export const defaultFormFieldPropsFactory: DefaultFormFieldProps = (
  meta,
  label,
  setFieldValue,
  field,
  placeholder,
  testComponent,
  valueInput,
) => ({
  error: meta.touched && meta.error,
  errorMessage: getFieldErrorMessage(meta.touched, meta.error),
  label,
  onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(field, value === 'cpf' ? masks.cpf.unmask(value) : value);
  },
  placeholder,
  'data-testid': testComponent,
  value: valueInput,
});
