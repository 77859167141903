import React from 'react';
import { SelectItem } from 'main/components/form/Select';
import useFieldLink from 'main/hooks/useFieldLink';
import { SelectSolicitacoesProps } from 'previdencia/features/HistoricoSolicitacoes/types/HistoricoSolicitacoesProps';
import * as S from './styles';

const SelectSolicitacoes: React.FC<SelectSolicitacoesProps> = ({
  tiposSolicitacoes,
  definirTipoSolicitado,
}) => {
  const [solicitacaoLink] = useFieldLink('');

  function isSelected(value: string): boolean {
    if (solicitacaoLink.get().value === value) {
      definirTipoSolicitado(value);

      return true;
    }
    return false;
  }

  return (
    <S.SelectStyle
      placeholder="Selecione uma Solicitação"
      link={solicitacaoLink}
      validationRules={[]}
    >
      {tiposSolicitacoes?.map(solicitacao => {
        return (
          <SelectItem
            key={solicitacao.valor}
            value={solicitacao.valor}
            text={solicitacao.nomeParametro}
            selected={isSelected(solicitacao.valor)}
          />
        );
      })}
    </S.SelectStyle>
  );
};

export default React.memo(SelectSolicitacoes);
