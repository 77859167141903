import { IDataTableColumn } from 'react-data-table-component';

export const COLUNAS_TABELA_ASSISTENCIAS: IDataTableColumn[] = [
  {
    name: '',
    selector: 'descricaoServico',
  },
  {
    name: '',
    cell: () => '-',
    maxWidth: '200px',
    center: true,
  },
];

export const SEM_DADOS_ASSISTENCIAS =
  'Não há dados de assistências a serem exibidos.';

export const DADOS_COBERTURA = {
  MORTE_NATURAL: 'MORTE POR CAUSAS NATURAIS E ACIDENTAIS',
  MORTE_ACIDENTAL_ADICIONAL: 'INDENIZAÇÃO ESPECIAL POR MORTE ACIDENTAL',
  INVALIDEZ_PERMANENTE: 'INVALIDEZ PERMANENTE TOTAL OU PARCIAL POR ACIDENTE',
  SEGURADA_DOENCAS_GRAVES:
    'COBERTURA DE DOENÇAS CRÔNICAS GRAVES EM ESTÁGIO AVANÇADO',
  MORTE_ACIDENTAL_CONJUGE: 'MORTE ACIDENTAL DO CÔNJUGE/COMPANHEIRO(A)',
  DIAGNOSTICO_CANCER: 'DIAGNÓSTICO DE CÂNCER DE MAMA, ÚTERO E OVÁRIO',
};

export const TEXTOS_DADOS_APOLICE_VIDA = {
  OBS_IMPORTANTE: {
    TITULO: 'IMPORTANTE',
    REEMBOLSO: 'Os serviços gratuitos não contemplam reembolso.',
    DETALHES_COBERTURAS: `Detalhes sobre as coberturas, riscos excluídos e limites de
    indenização, dentre outras informações do seguro devem ser
    consultadas nas condições gerais da apólice contratada.`,
    IOF: 'No valor do seguro já está incluído o IOF.',
    DETALHES_IOF: (
      <>
        <strong>IOF:</strong> Trata-se de imposto exigido pelo Governo para
        operações financeiras dentre elas a venda de seguros, incluído no valor
        do seguro/parcela paga.
      </>
    ),
  },
};
