import React, { useEffect, useState } from 'react';
import { PE } from 'main/components/PEComponents';
import useFieldLink from 'main/hooks/useFieldLink';
import { validate } from 'main/features/Validation/utils/validateRules';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import Input from 'main/components/form/Input';
import masks from 'main/utils/masks';
import RenderConditional from 'main/components/RenderConditional';
import { minValue, required } from 'main/features/Validation/validations';
import { OpcaoAlterarContribuicaoPros } from 'previdencia/features/AlterarValorContribuicao/types/AlterarValorContribuicaoComponentProps';
import {
  Botao,
  ContainerInput,
  ContainerInputChild,
} from 'previdencia/features/AlterarValorContribuicao/components/styles';
import { ValidaInputMinValue } from 'previdencia/types/Forms';

const OpcaoAlterarContribuicao: React.FC<OpcaoAlterarContribuicaoPros> = ({
  resumoContribuicao: { valorTotalSobrevivenciasAtivas, listaProtecoesAtivas },
  loadingValoresContribuicao,
  valorMinimo,
  onChange,
  onRedistribuir,
  onAlterarValor,
  onAlterarBeneficioProtecao,
}) => {
  const [mostrarInputAlterarValor, setMostrarInputAlterarValor] =
    useState<boolean>(false);

  const [valueRadio, setValueRadio] = useState<string>('');

  const [valorSobrevivenciaLink, validarValorSobrevivenciaLink] =
    useFieldLink('0');

  const valorSobrevivencia = valorSobrevivenciaLink.get().value;

  const validacaoInput: ValidaInputMinValue = {
    min: valorMinimo,
  };

  const legendaInputSobrevivencia = `Valor Mínimo ${tryGetMonetaryValueOrDefault(
    valorMinimo,
  )}`;

  const atribuirValorInicialInputSobrevivencia = (): void => {
    if (valorTotalSobrevivenciasAtivas) {
      valorSobrevivenciaLink.set({
        value: valorTotalSobrevivenciasAtivas.toFixed(2),
        isValid: true,
      });
    }
  };

  const validarValorContribuicaoSobrevivencia = (): void => {
    if (validate([validarValorSobrevivenciaLink])) {
      const valorConvertido =
        Number(masks.currencyInput.unmask(valorSobrevivenciaLink.get().value)) /
        100;

      onAlterarValor(valorConvertido.toString());
    }
  };

  const alterarValorContribuicaoSobrevivencia = (): void => {
    onChange();
    setValueRadio('1');
    if (!mostrarInputAlterarValor) setMostrarInputAlterarValor(true);
  };

  const redistribuirValorContribuicaoSobrevivencia = (): void => {
    setValueRadio('2');
    onRedistribuir();
    if (mostrarInputAlterarValor) setMostrarInputAlterarValor(false);
  };

  const alterarValorContribuicaoBeneficioProtecao = (): void => {
    setValueRadio('3');
    onChange();

    if (mostrarInputAlterarValor) setMostrarInputAlterarValor(false);

    onAlterarBeneficioProtecao();
  };

  useEffect(atribuirValorInicialInputSobrevivencia, [
    valorTotalSobrevivenciasAtivas,
  ]);

  useEffect(() => {
    onChange();
  }, [valorSobrevivencia]);

  return (
    <>
      <PE.Text variant="body02-sm" fontWeight="bold">
        O que deseja fazer?
      </PE.Text>

      <PE.Display alignItems="center">
        <PE.Radio
          name="group"
          value="1"
          onChange={alterarValorContribuicaoSobrevivencia}
          checked={valueRadio === '1'}
        />
        <PE.Text variant="body02-sm">
          Alterar valor da contribuição de sobrevivência
        </PE.Text>
      </PE.Display>

      <RenderConditional condition={mostrarInputAlterarValor}>
        <ContainerInput>
          <PE.Text variant="body02-sm" marginTop={20}>
            Qual o novo valor que deseja contribuir para a{' '}
            <strong>sobrevivência?</strong>
          </PE.Text>

          <ContainerInputChild>
            <Input
              link={valorSobrevivenciaLink}
              inputMask={masks.currencyInput}
              validationRules={[required(), minValue(() => '', validacaoInput)]}
              legend={legendaInputSobrevivencia}
            />
            <Botao
              onClick={validarValorContribuicaoSobrevivencia}
              variant="secondary"
              loading={loadingValoresContribuicao}
            >
              Confirmar
            </Botao>
          </ContainerInputChild>
        </ContainerInput>
      </RenderConditional>

      <PE.Display alignItems="center">
        <PE.Radio
          name="group"
          value="2"
          onChange={redistribuirValorContribuicaoSobrevivencia}
          checked={valueRadio === '2'}
        />
        <PE.Text variant="body02-sm">
          Redistribuir valor da contribuição de sobrevivência
        </PE.Text>
      </PE.Display>

      <RenderConditional condition={!!listaProtecoesAtivas.length}>
        <PE.Display alignItems="center">
          <PE.Radio
            name="group"
            value="3"
            onChange={alterarValorContribuicaoBeneficioProtecao}
            checked={valueRadio === '3'}
          />
          <PE.Text variant="body02-sm">
            Alterar valor da contribuição do benefício de proteção
          </PE.Text>
        </PE.Display>
      </RenderConditional>
    </>
  );
};

export default OpcaoAlterarContribuicao;
