import { api, IApiResponse } from 'main/services';
import { DadosInstituicaoBancaria } from 'main/types/Bancos/DadosInstituicaoBancaria';
import { obterEndpointRecuperarListaBancos } from 'previdencia/config/endpoints';

export const obterListaBancos = async (
  cpfCnpj: string,
): Promise<DadosInstituicaoBancaria[] | undefined> => {
  const { data } = await api.post<IApiResponse<DadosInstituicaoBancaria[]>>(
    obterEndpointRecuperarListaBancos(),
    { cpfCnpj },
  );

  const messageError = data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(messageError);

  return data?.dados?.entidade?.map(dadosBanco => {
    if (dadosBanco.codigoBanco?.toString().length < 3) {
      return {
        ...dadosBanco,
        codigoBanco: dadosBanco.codigoBanco.toString().padStart(3, '0'),
      };
    }

    return {
      ...dadosBanco,
      codigoBanco: dadosBanco.codigoBanco.toString(),
    };
  });
};
