import { PECOS_PRESTAMISTA } from 'contratosPrestamista/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import {
  IRequesInserirPropostaDps,
  IRequestAtualizarStatusPropostaDps,
} from '../features/dpsEletronicaPostecipado/types/IRequestAtualizarStatusPropostaDps';

export const usePecoInserirAtualizarPropostaDps = () => {
  const {
    loading: loadingAtualizarPropostaDps,
    fetchData: fetchAtualizarPropostaDps,
  } = usePeco<IRequestAtualizarStatusPropostaDps, unknown>({
    api: {
      operationPath: PECOS_PRESTAMISTA.AtualizarPropostasDps,
    },
  });
  const {
    loading: loadingInserirPropostaDps,
    fetchData: fetchInserirPropostaDps,
  } = usePeco<IRequesInserirPropostaDps, unknown>({
    api: {
      operationPath: PECOS_PRESTAMISTA.InserirPropostaDps,
    },
  });

  return {
    loadingAtualizarPropostaDps,
    fetchAtualizarPropostaDps,
    loadingInserirPropostaDps,
    fetchInserirPropostaDps,
  };
};
