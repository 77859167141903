import RenderConditional from 'main/components/RenderConditional';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import * as CONSTS from 'painelAdministracao/constants/constants';
import { AdmContext } from 'painelAdministracao/context/AdministracaoContext';
import useEditarFop from 'painelAdministracao/hooks/useEditarFop';
import { useHandleAtualizarFop } from 'painelAdministracao/hooks/useHandleAtualizarFop';
import { useContext, useState } from 'react';
import { ModalEditarConfirmacao } from './ModalEditarConfirmacao';
import { ModalEditarFormulario } from './ModalEditarFormulario';

const ModalEditar = () => {
  const [mostrarConfirmacao, setMostrarConfirmacao] = useState(false);
  const { formik, handleChangeDataVersao, handleChangeAtivo } = useEditarFop();
  const { modalEditar, handleFecharModal, fopEditar, documentoEditar } =
    useContext(AdmContext);

  const { loading, atualizarFop } = useHandleAtualizarFop(
    formik,
    fopEditar,
    documentoEditar,
    handleFecharModal,
  );

  const onClose = () => {
    handleFecharModal();
    formik.setValues(CONSTS.INITIAL_FORMIK_STATE);
  };

  const handleAtualizar = () => {
    if (!formik.values.ativo) {
      setMostrarConfirmacao(true);
    } else {
      atualizarFop();
    }
  };

  return (
    <>
      <RenderConditional
        condition={checkIfAllItemsAreTrue([modalEditar, !mostrarConfirmacao])}
      >
        <ModalEditarFormulario
          onClose={onClose}
          fopEditar={fopEditar}
          formik={formik}
          handleChangeDataVersao={handleChangeDataVersao}
          handleChangeAtivo={handleChangeAtivo}
          handleAtualizar={handleAtualizar}
          loading={loading}
        />
      </RenderConditional>
      <RenderConditional
        condition={checkIfAllItemsAreTrue([modalEditar, mostrarConfirmacao])}
      >
        <ModalEditarConfirmacao
          fopEditar={fopEditar}
          handleConfirm={() => {
            atualizarFop();
            setMostrarConfirmacao(false);
          }}
          handleClose={() => setMostrarConfirmacao(false)}
        />
      </RenderConditional>
    </>
  );
};

export default ModalEditar;
