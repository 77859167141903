import React from 'react';
import RenderConditional from 'main/components/RenderConditional';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';

type RenderContentEmbedProps = {
  reportConfig: models.IReportEmbedConfiguration;
  eventHandlersMap: Map<string, any>;
  reportSettingsLoaded: boolean;
};

export const RenderContentEmbed: React.FC<RenderContentEmbedProps> = ({
  eventHandlersMap,
  reportConfig,
  reportSettingsLoaded,
}) => {
  return (
    <RenderConditional condition={reportSettingsLoaded}>
      <div
        style={{ height: '100vh', margin: '1% auto', width: '100%' }}
        data-testid="containerIframePowerBI"
      >
        <PowerBIEmbed
          embedConfig={reportConfig}
          eventHandlers={eventHandlersMap}
          cssClassName="report-style-class"
        />
      </div>
    </RenderConditional>
  );
};
