import React from 'react';
import { endOfDay, subDays } from 'date-fns';
import { Grid, Button, Display, Select } from '@cvp/design-system/react';
import masks from 'main/utils/masks';
import InputSelect from 'main/components/form/InputSelect';
import Input from 'main/components/form/Input';
import { required } from 'main/features/Validation/validations';
import useFieldLink from 'main/hooks/useFieldLink';
import RenderConditional from 'main/components/RenderConditional';
import Calendar, {
  ICalendarRangeDateProps,
} from 'main/components/Calendar/Calendar';
import { validate } from 'main/features/Validation/utils/validateRules';
import { IConsultarStatusUploadForm } from '../types/IConsultarStatusUploadForm';

type FormConsultaCpfContratoProps = {
  handleConsultar: (data: IConsultarStatusUploadForm) => void;
};

const FormConsultaCpfContrato: React.FunctionComponent<
  FormConsultaCpfContratoProps
> = ({ handleConsultar }) => {
  const [tipoConsulta] = useFieldLink<string>('');
  const [termoPesquisa, validateTermoPesquisa] = useFieldLink<string>('');
  const [dateLink] = useFieldLink<ICalendarRangeDateProps>({
    dateEnd: null,
    dateStart: null,
  });

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (
      tipoConsulta.get().value === 'periodo' &&
      dateLink.get().value.dateStart &&
      dateLink.get().value.dateEnd
    ) {
      handleConsultar({
        dataInicial: dateLink.get().value.dateStart,
        dataFinal: dateLink.get().value.dateEnd,
      });
      return;
    }
    if (validate([validateTermoPesquisa])) {
      handleConsultar({
        [tipoConsulta.get().value]: termoPesquisa.get().value,
      });
    }
  };

  const maxDate = endOfDay(new Date());
  const minDate = subDays(new Date(), 7);
  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <Grid.Item xs={1 / 3}>
          <InputSelect
            link={tipoConsulta}
            validationRules={[required()]}
            label="Selecione o termo da consulta"
            placeholder="Escolha a opção"
          >
            <Select.Item
              selected={tipoConsulta.get().value === 'cpfCnpj'}
              key="cpfCnpj"
              value="cpfCnpj"
              text="CPF/CNPJ"
            />
            <Select.Item
              selected={tipoConsulta.get().value === 'codContrato'}
              key="codContrato"
              value="codContrato"
              text="Número de contrato"
            />
            <Select.Item
              selected={tipoConsulta.get().value === 'periodo'}
              key="periodo"
              value="periodo"
              text="Período"
            />
          </InputSelect>
        </Grid.Item>
        <RenderConditional condition={tipoConsulta.get().value === 'periodo'}>
          <Grid.Item xs={1 / 3}>
            <Calendar
              id="data-venda"
              maxDate={maxDate}
              minDate={minDate}
              endDate={dateLink.get().value.dateEnd}
              range
              placeholder="Selecione a data"
              value={dateLink.get().value.dateStart}
              onChange={(dateStart, dateEnd) => {
                dateLink.set({
                  value: { dateStart, dateEnd },
                  isValid: true,
                  errorMsg: '',
                });
              }}
              data-testid="data-venda"
            />
          </Grid.Item>
        </RenderConditional>
        <RenderConditional condition={tipoConsulta.get().value !== 'periodo'}>
          <Grid.Item xs={1 / 3}>
            <Input
              label="*Digite o termo da consulta"
              link={termoPesquisa}
              inputMask={masks.numberOnly}
              validationRules={[required()]}
            />
          </Grid.Item>
        </RenderConditional>
      </Grid>
      <Display>
        <Button type="submit" variant="primary">
          Consultar
        </Button>
      </Display>
    </form>
  );
};

export default FormConsultaCpfContrato;
