import React, { useRef, useState } from 'react';
import { Text, Button, Display } from '@cvp/design-system/react';
import TabelaFundos from 'previdencia/components/TabelaFundos';
import Modal from 'main/components/Modal';
import { TabelaFundosHandles } from 'previdencia/components/TabelaFundos/TabelaFundos';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { Fundo } from 'previdencia/types/Fundo.type';
import RenderConditional from 'main/components/RenderConditional';
import TextoInformativoRedistribuirValor from '../TextoInformativoRedistribuirValor';
import {
  obterValorTotalFundosRequest,
  validarDadosServico,
} from '../../utils/transferenciaFundosInvestimento';
import { useTransferenciaFundosContext } from '../../contexts/ContextTransferenciaFundos';
import * as S from './styles';
import {
  converterFundoParaRequestTransferenciaEntreFundos,
  converterResponseTransferenciaEntreFundosParaFundos,
} from '../../factories/transferenciaFundoInvestimentoFactory';
import { ITransferenciaFundosFeatureData } from '../../types/ITransferenciaFundosFeatureData';
import { tryGetValueOrDefault } from 'main/utils/conditional';

const RedistribuirValor: React.FC = () => {
  const tabelaFundosRef = useRef<TabelaFundosHandles>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmarOpecao, setOpenConfirmarOpecao] = useState(false);
  const [mensagemModal, setMensagemModal] = useState('');

  const {
    etapa,
    fundosOrigem,
    responseRedistribuirFundos,
    dadosInvalidos,
    direcionarNovaTela,
    escolherFundosDestino,
  } = useTransferenciaFundosContext();
  const request =
    converterFundoParaRequestTransferenciaEntreFundos(fundosOrigem);
  const { featureData, setFeatureData } =
    usePrevidenciaContext<ITransferenciaFundosFeatureData>();

  const fundos = converterResponseTransferenciaEntreFundosParaFundos(
    responseRedistribuirFundos,
  );
  const valorTotal = obterValorTotalFundosRequest(request);

  const dadosValidados = validarDadosServico(
    responseRedistribuirFundos,
    request,
  );

  if (dadosValidados?.isError) {
    const mensagem: string[] = [];
    mensagem.push(dadosValidados.mensagem);
    dadosInvalidos(mensagem);
  }

  const redistribuirFundos = () => {
    setOpenConfirmarOpecao(false);
    const validacao = tabelaFundosRef.current?.validateFundos();
    if (validacao?.tabelaAlterada && !validacao?.valoresDiferentes) {
      escolherFundosDestino(validacao.tabelaAlterada);
    }
    if (validacao?.valoresDiferentes) {
      setMensagemModal(validacao.mensagemValoresDiferentes || '');
      setOpenModal(true);
      return undefined;
    }

    return undefined;
  };

  const excedeuQuantidadeMaxima =
    tryGetValueOrDefault(
      [
        featureData?.fundosDestinoSelecionados?.filter(
          item => item.selecionado,
        ),
      ],
      [],
    ).length >
    tryGetValueOrDefault([responseRedistribuirFundos?.numMaxFundos], 4);

  const nenhumFundoSelecionado =
    tryGetValueOrDefault(
      [
        featureData?.fundosDestinoSelecionados?.filter(
          item => item.selecionado,
        ),
      ],
      [],
    ).length === 0;

  const somarQuantidadeFundosParaTransferir = () => {
    const novo = tryGetValueOrDefault(
      [Number(responseRedistribuirFundos?.qndDispNovoFundo)],
      0,
    );
    const existente = tryGetValueOrDefault(
      [Number(responseRedistribuirFundos?.qndDispFundoExistente)],
      0,
    );

    return novo + existente;
  };
  return (
    <>
      <TextoInformativoRedistribuirValor valorTransferencia={valorTotal} />
      <RenderConditional
        condition={!!responseRedistribuirFundos?.qndDispFundoExistente}
      >
        <Text variant="body02-sm" margin>
          {responseRedistribuirFundos?.qndDispFundoExistente} fundo(s)
          existente(s)
        </Text>
      </RenderConditional>
      <RenderConditional
        condition={!!responseRedistribuirFundos?.qndDispNovoFundo}
      >
        <Text variant="body02-sm" margin>
          {responseRedistribuirFundos?.qndDispNovoFundo} novo(s) fundo(s)
        </Text>
      </RenderConditional>
      <TabelaFundos
        fundos={fundos}
        quantidadeMaximaFundos={somarQuantidadeFundosParaTransferir() || 0}
        valorTotal={valorTotal}
        isLoading={false}
        ref={tabelaFundosRef}
        tipoResgate={undefined}
        resgate={undefined}
        setFundosSelecionados={(fundosDestinos: Fundo[]) => {
          setFeatureData({
            ...featureData,
            fundosDestinoSelecionados: fundosDestinos,
          });
        }}
      />
      <S.ContainerButtons>
        <S.ButtonVoltarStyle
          variant="outlined"
          onClick={() => direcionarNovaTela('definirFundosOrigem')}
        >
          Voltar
        </S.ButtonVoltarStyle>

        <S.ButtonAlterarStyle
          variant="primary"
          disabled={
            etapa === 'definirFundosDestino' &&
            (excedeuQuantidadeMaxima || nenhumFundoSelecionado)
          }
          onClick={() => setOpenConfirmarOpecao(true)}
        >
          Alterar
        </S.ButtonAlterarStyle>
      </S.ContainerButtons>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        {mensagemModal}
      </Modal>

      <Modal
        open={openConfirmarOpecao}
        onClose={() => setOpenConfirmarOpecao(false)}
      >
        <Text margin>
          Essa ação não é uma simulação, e após confirmada não poderá ser
          cancelada.
        </Text>
        <Text margin>Deseja continuar com a transação?</Text>

        <Display justify="center">
          <Button
            variant="outlined"
            onClick={() => setOpenConfirmarOpecao(false)}
            data-testid="nao"
          >
            Não
          </Button>
          <Button
            variant="primary"
            onClick={() => redistribuirFundos()}
            data-testid="sim"
          >
            Sim
          </Button>
        </Display>
      </Modal>
    </>
  );
};

export default RedistribuirValor;
