import React from 'react';
import { Divider, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { Table } from 'main/components/Table';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { TabelaApoliceSorteiosProps } from 'seguros/types/ApoliseCobertura';
import * as CONSTS from 'seguros/constants/TabelaApoliceSorteios';
import * as S from 'seguros/components/TabelasApoliceImpressao/components/styles';

export const TabelaApoliceSorteiosImpressao: React.FC<
  TabelaApoliceSorteiosProps
> = ({ data, columns }) => {
  return (
    <RenderConditional condition={!!data.length}>
      <Text variant="body02-md" color="primary" margin>
        Sorteios
      </Text>
      <S.WrapperTableContainer>
        <Table
          noHeader
          responsive
          striped
          columns={tryGetValueOrDefault(
            [columns],
            CONSTS.COLUNAS_TABELA_SORTEIOS,
          )}
          data={tryGetValueOrDefault([data], [])}
          noDataComponent={CONSTS.SEM_DADOS}
        />
        <Divider />
      </S.WrapperTableContainer>
    </RenderConditional>
  );
};
