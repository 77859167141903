import React from 'react';
import RenderConditional from 'main/components/RenderConditional';
import { Display, Card, Text, Grid, Button } from '@cvp/design-system/react';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { tipoEnderecoMapper } from 'previdencia/utils/previdenciaMappers';
import { formatarData, formatarDataAmigavel } from 'main/utils';
import masks from 'main/utils/masks';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { VerParticipanteProps } from 'previdencia/features/DadosParticipante/types/DadosParticipante';
import * as S from './styles';
import {
  obterTextoEstadoCivil,
  procurarPorTipoTelefone,
} from '../../utils/dadosParticipante';

const VerParticipante: React.FunctionComponent<VerParticipanteProps> = ({
  edit,
  data,
}) => {
  const { goDadosPlano } = usePrevNavigation();
  const telefoneComercial = procurarPorTipoTelefone(data, {
    tipoUm: 'C',
    tipoDois: 'O',
  });
  const telefoneResidencial = procurarPorTipoTelefone(data, {
    tipoUm: 'D',
    tipoDois: 'T',
  });

  return (
    <S.ParticipantViewContainer>
      <Display type="display-block">
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Display type="display-block">
              <Text tabIndex={0} variant="headline-06" color="primary" margin>
                Dados do Participante
              </Text>
              <Grid>
                <Grid.Item xs={1} sm={1 / 2} md={1 / 4}>
                  <Text
                    id="estado_civil"
                    variant="caption-02"
                    color="text-light"
                  >
                    Estado Civil
                  </Text>
                  <Text variant="caption-02" color="text">
                    {obterTextoEstadoCivil(data?.estadoCivil)}
                  </Text>
                </Grid.Item>
                <Grid.Item xs={1} sm={1 / 2} md={1 / 4}>
                  <Text variant="caption-02" color="text-light">
                    Sexo
                  </Text>
                  <Text variant="caption-02" color="text">
                    {data?.sexo}
                  </Text>
                </Grid.Item>
                <Grid.Item xs={1} sm={1 / 2} md={1 / 4}>
                  <Text variant="caption-02" color="text-light">
                    Data de Nascimento
                  </Text>
                  <Text
                    aria-label={tryGetValueOrDefault(
                      [formatarDataAmigavel(data?.dataNascimento.toString())],
                      'Vazio',
                    )}
                    variant="caption-02"
                    color="text"
                  >
                    {formatarData(data?.dataNascimento)}
                  </Text>
                </Grid.Item>
                <Grid.Item xs={1} sm={1 / 2} md={1 / 4}>
                  <Text variant="caption-02" color="text-light">
                    Tipo de Endereço
                  </Text>
                  <Text variant="caption-02" color="text">
                    {tipoEnderecoMapper(data?.tipoEndereco ?? '')}
                  </Text>
                </Grid.Item>
                <Grid.Item xs={1} sm={1 / 2} md={1 / 4}>
                  <Text variant="caption-02" color="text-light">
                    CEP
                  </Text>
                  <Text
                    aria-label={data?.cep}
                    variant="caption-02"
                    color="text"
                  >
                    {masks.cep.mask(tryGetValueOrDefault([data?.cep], ''))}
                  </Text>
                </Grid.Item>
                <Grid.Item xs={1} sm={1 / 2} md={1 / 4}>
                  <Text variant="caption-02" color="text-light">
                    Endereço
                  </Text>
                  <Text variant="caption-02" color="text">
                    {data?.endereco}
                  </Text>
                </Grid.Item>
                <Grid.Item xs={1} sm={1 / 2} md={1 / 4}>
                  <Text variant="caption-02" color="text-light">
                    Número
                  </Text>
                  <Text variant="caption-02" color="text">
                    {data?.numero}
                  </Text>
                </Grid.Item>
                <Grid.Item xs={1} sm={1 / 2} md={1 / 4}>
                  <Text variant="caption-02" color="text-light">
                    Complemento
                  </Text>
                  <Text
                    aria-label={tryGetValueOrDefault(
                      [data?.complemento],
                      'Vazio',
                    )}
                    variant="caption-02"
                    color="text"
                  >
                    {tryGetValueOrDefault([data?.complemento], '-')}
                  </Text>
                  <Text variant="caption-02" color="text" />
                </Grid.Item>
                <Grid.Item xs={1} sm={1 / 2} md={1 / 4}>
                  <Text variant="caption-02" color="text-light">
                    Bairro
                  </Text>
                  <Text variant="caption-02" color="text">
                    {data?.bairro}
                  </Text>
                </Grid.Item>
                <Grid.Item xs={1} sm={1 / 2} md={1 / 4}>
                  <Text variant="caption-02" color="text-light">
                    Cidade
                  </Text>
                  <Text variant="caption-02" color="text">
                    {data?.cidade}
                  </Text>
                </Grid.Item>
                <Grid.Item xs={1} sm={1 / 2} md={1 / 4}>
                  <Text
                    aria-label="Estado"
                    variant="caption-02"
                    color="text-light"
                  >
                    UF
                  </Text>
                  <Text variant="caption-02" color="text">
                    {data?.uf}
                  </Text>
                </Grid.Item>
                <Grid.Item xs={1} sm={1 / 2} md={1 / 4}>
                  <Text variant="caption-02" color="text-light">
                    E-mail
                  </Text>
                  <Text variant="caption-02" color="text">
                    {data?.email}
                  </Text>
                </Grid.Item>
                <Grid.Item xs={1} sm={1 / 2} md={1 / 4}>
                  <Text variant="caption-02" color="text-light">
                    Telefone Celular
                  </Text>
                  <Text variant="caption-02" color="text">
                    {masks.phone.mask(
                      tryGetValueOrDefault(
                        [
                          `${telefoneComercial?.codigoArea}` +
                            `${telefoneComercial?.numeroTelefone}`,
                        ],
                        '',
                      ),
                    )}
                  </Text>
                </Grid.Item>
                <Grid.Item xs={1} sm={1 / 2} md={1 / 4}>
                  <Text variant="caption-02" color="text-light">
                    Outro Telefone
                  </Text>
                  <Text variant="caption-02" color="text">
                    <RenderConditional
                      condition={
                        telefoneResidencial?.numeroTelefone !== '11111111'
                      }
                    >
                      {masks.phone.mask(
                        tryGetValueOrDefault(
                          [
                            `${telefoneResidencial?.codigoArea}` +
                              `${telefoneResidencial?.numeroTelefone}`,
                          ],
                          '',
                        ),
                      )}
                    </RenderConditional>
                  </Text>
                </Grid.Item>
              </Grid>
            </Display>
            <S.Actions>
              <Button
                aria-label="Voltar para Dados do Plano"
                variant="outlined"
                onClick={() => goDadosPlano()}
              >
                Voltar
              </Button>
              <Button
                aria-label="Atualizar Dados do Participante"
                onClick={edit}
              >
                Atualizar dados
              </Button>
            </S.Actions>
          </Card.Content>
        </Card>
      </Display>
    </S.ParticipantViewContainer>
  );
};

export default VerParticipante;
