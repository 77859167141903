import { Text, Checkbox } from '@cvp/design-system/react';

import { FlexContainer } from 'main/styles/GlobalStyle';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const ColunaDescricaoFundo = ({
  row,
  handleFundoSelecionado,
  isTipoResgateTotal,
}: SOLICITACAO_RESGATE_TYPES.IColunaDescricaoFundoProps) => {
  return (
    <FlexContainer alignItems="center">
      <Checkbox
        id={row.codigoFundo}
        checked={row.selecionado}
        disabled={isTipoResgateTotal}
        onChange={() => {
          handleFundoSelecionado(row.codigoFundo);
        }}
      />
      <Text variant="body02-md" style={{ marginLeft: 12 }}>
        {row.descricaoFundo}
      </Text>
    </FlexContainer>
  );
};

export default ColunaDescricaoFundo;
