import React from 'react';
import { Text, Grid, Display, Button } from '@cvp/design-system/react';

import Table from 'main/components/Table';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import useConfirmarAlteracao from 'previdencia/features/AlteracaoFormaDadosPagamento/hooks/useConfirmarAlteracao';
import * as CONSTS from 'previdencia/features/AlteracaoFormaDadosPagamento/constants/constants';
import * as ALTERACAO_CONTA_TYPES from 'previdencia/features/AlteracaoFormaDadosPagamento/types/AlteracaoFormaDadosPagamento';

const ConfirmarAlteracao: React.FC<
  ALTERACAO_CONTA_TYPES.IConfirmarAlteracaoProps
> = ({
  proximo,
  voltar,
  canalId,
  dadosListaBancos,
  dadosNovaConta,
  dadosCertificado,
  idPessoa,
  tipoBeneficio,
}) => {
  const {
    isAtualizacaoDados,
    setConfirmacaoDadosNovaConta,
    loadingDadosCanalPagamento,
    dadosBancarios,
    dadosDaConta,
    setConfirmacaoDadosAtualizarFormaPagamento,
    loadingDadosAtualizacaoFormaPagamento,
    colunas,
    colunasDadosAntigos,
  } = useConfirmarAlteracao({
    proximo,
    canalId,
    dadosListaBancos,
    dadosNovaConta,
    dadosCertificado,
    idPessoa,
    tipoBeneficio,
  });

  const handleCriarAtualizar = () => {
    if (isAtualizacaoDados) {
      setConfirmacaoDadosAtualizarFormaPagamento(true);
      return;
    }
    setConfirmacaoDadosNovaConta(true);
  };
  const handleLabel = () => {
    if (isAtualizacaoDados) {
      return 'Para:';
    }
    return 'Para conta adicionada:';
  };

  return (
    <>
      <Grid.Item xs={1}>
        <Text variant="body01-md" color="text" margin>
          Tem certeza que deseja realizar essa alteração?
        </Text>
      </Grid.Item>
      <Grid.Item xs={1}>
        <Text variant="body01-md" color="text-light">
          De:
        </Text>
        <Table
          noHeader
          responsive
          striped
          highlightOnHover
          data={tryGetValueOrDefault([dadosBancarios], [])}
          columns={colunasDadosAntigos}
          noDataComponent={CONSTS.TABELA_SEM_DADOS}
        />
      </Grid.Item>

      <Grid.Item xs={1}>
        <Text variant="body01-md" color="text-light">
          {handleLabel()}
        </Text>
        <Table
          noHeader
          responsive
          striped
          highlightOnHover
          data={tryGetValueOrDefault([dadosDaConta], [])}
          columns={colunas}
          noDataComponent={CONSTS.TABELA_SEM_DADOS}
        />
      </Grid.Item>
      <Grid.Item xs={1}>
        <Display>
          <Button variant="outlined" onClick={voltar}>
            Cancelar
          </Button>
          <Button
            onClick={() => handleCriarAtualizar()}
            loading={checkIfSomeItemsAreTrue([
              loadingDadosAtualizacaoFormaPagamento,
              loadingDadosCanalPagamento,
            ])}
            disabled={checkIfSomeItemsAreTrue([
              loadingDadosAtualizacaoFormaPagamento,
              loadingDadosCanalPagamento,
            ])}
          >
            Confirmar
          </Button>
        </Display>
      </Grid.Item>
    </>
  );
};

export default ConfirmarAlteracao;
