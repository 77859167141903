/* eslint-disable @typescript-eslint/no-explicit-any  */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
import { useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import * as ListaBancosApi from 'main/features/ValidacaoContaCaixa/services/listaBancos.api';
import { DadosInstituicaoBancaria } from 'main/features/ValidacaoContaCaixa/types/DadosInstituicaoBancaria';

export const useListaBancos = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { toastError } = useToast();
  const [response, setResponse] = useState<
    DadosInstituicaoBancaria[] | undefined
  >();

  const obterListaBancos = async () => {
    try {
      setLoading(true);
      const result = await ListaBancosApi.obterListaBancos();

      setResponse(result);
    } catch (requestError: any) {
      toastError(requestError);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    response,
    obterListaBancos,
  };
};
