import {
  IDpsEletronicaFeatureDataFluxoPF,
  IDpsEletronicaFeatureDataFluxoPJ,
} from 'contratosPrestamista/features/dpseletronica/types/IDpsEletronicaFeatureDataFluxoPF';
import masks from 'main/utils/masks';
import { IDadosBasePessoa } from '../../../types/ConsultarSubcricao';
import { PARAMETROS_DPS } from '../../../constants/parametrosDps';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { formatarDataAdicionandoSeparador } from 'main/utils';
import { removeEmptySpace } from 'main/utils/string';

export const construirPayloadSolicitarDpsFluxoPF = (
  data?: IDpsEletronicaFeatureDataFluxoPF | null,
) => ({
  codigoSegmento: PARAMETROS_DPS.CODIGO_SEGMENTO_PF,
  codigoProdutoOrigem: PARAMETROS_DPS.CODIGO_PRODUTO_ORIGEM_PF,
  numeroProposta: masks.numberOnly.mask(data?.dadosContrato?.codContrato),
  cpf: data?.dadosContrato?.cpfCnpj,
  codigoUsuario: data?.codigoUsuario,
  codigoSistemaOrigem: PARAMETROS_DPS.CODIGO_SISTEMA_ORIGEM,
  dataHoraValidadeProposta: data?.dadosContrato?.dtaVenda,
  codigoCoberturaOrigem: PARAMETROS_DPS.CODIGO_COBERTURA_ORIGEM,
  valorGarantia: +tryGetValueOrDefault(
    [data?.dadosContrato?.valorAcumulo],
    0,
  ).toFixed(2),
  dadosCadastrais: data?.dadosCadastrais,
});

export const construirPayloadSolicitarDpsFluxoPJ = (
  socio: IDadosBasePessoa,
  data?: IDpsEletronicaFeatureDataFluxoPJ | null,
) => ({
  codigoSegmento: PARAMETROS_DPS.CODIGO_SEGMENTO_PJ,
  codigoProdutoOrigem: PARAMETROS_DPS.CODIGO_PRODUTO_ORIGEM_PJ,
  numeroProposta: masks.numberOnly.mask(data?.dadosContrato?.codContrato),
  cpf: socio.cpf,
  codigoUsuario: data?.codigoUsuario,
  codigoSistemaOrigem: PARAMETROS_DPS.CODIGO_SISTEMA_ORIGEM,
  dataHoraValidadeProposta: data?.dadosContrato?.dtaVenda,
  codigoCoberturaOrigem: PARAMETROS_DPS.CODIGO_COBERTURA_ORIGEM,
  valorGarantia: +tryGetValueOrDefault([data?.valorAcumuloPJ], 0).toFixed(2),
  dadosCadastrais: {
    nome: socio.nome,
    dataNascimento: formatarDataAdicionandoSeparador(socio.dataNascimento),
    sexo: socio.sexo,
    contato: {
      email: removeEmptySpace(socio.email),
      telefone: removeEmptySpace(socio.telefone),
    },
    endereco: {
      logradouro: removeEmptySpace(socio.endereco),
      complemento: '',
      bairro: removeEmptySpace(socio.bairro),
      cidade: removeEmptySpace(socio.cidade),
      uf: removeEmptySpace(socio.uf),
      cep: removeEmptySpace(socio.cep),
    },
  },
});
