export const COBRANCA_GERADA =
  'Caso exista alguma cobrança gerada, esta alteração valerá apenas para a próxima cobrança.';

export const INFORMACOES_PAGAMENTOS =
  'Veja abaixo as informações da forma de pagamento cadastradas para esse plano. Para alterá-las, informe os dados da conta para débito e clique no botão ALTERAR.';

export const NAO_INCLUIU_CONTA_BANCARIA_ERRO =
  'Não foi possível incluir a nova conta bancária. Revise os dados ou informe outra conta.';

export const ESTA_ALTERACAO_APLICA =
  'Esta alteração não se aplica a este certificado. Em caso de dúvida, fale agora com um dos nossos Especialistas Online pelo chat ';

export const TABELA_SEM_DADOS = 'Não há dados para exibir.';

export const TIPO_CONTRIBUICAO_CREG1 = 'CREG1';

export const TIPO_CONTA = {
  DEBITO_CONTA_SIGLA: 'CB',
  DEBITO_CONTA: 'Débito em conta',
};

export const BTN_LABEL_FORM = {
  OCULTAR: 'Ocultar formulário',
  ADICIONAR: 'Adicionar Conta',
};

export const NUMERO_BANCO_CAIXA = '104';

export const TIPO_PAGAMENTO_ID = '5';

export const TIPO_BENEFICIO_PR = 'PR';

export const INITIAL_FORMIK_STATE = {
  agencia: '',
  conta: '',
  operacao: '',
  digitoBanco: '',
};

export const TIPOS_OPERACAO = [
  {
    id: '001',
    operacao: '001 - Conta Corrente',
  },
  {
    id: '013',
    operacao: '013 - Conta Poupança',
  },
  {
    id: '023',
    operacao: '023 - Conta Caixa Fácil',
  },
  {
    id: '1288',
    operacao: '1288 - Poupança Caixa Fácil',
  },
  {
    id: '3701',
    operacao: '3701 - Conta Corrente',
  },
];

export const OPERACAO_CONTA_BANCO = {
  NUMERO_CONTA_CORRENTE: '001',
  NUMERO_CONTA_POUPANCA: '013',
  NUMERO_CONTA_CAIXA_FACIL: '023',
  NUMERO_POUPANCA_CAIXA_FACIL: '1288',
  NUMERO_CONTA_CORRENTE_2: '3701',
  SIGLA_CONTA_CORRENTE: 'CC',
  SIGLA_CONTA_POUPANCA: 'CP',
};

export const CONTA_VALIDA = {
  CODIGO_VALIDACAO_X5: 'X5',
  CODIGO_VALIDACAO_999: '999',
};

export const FORMIK_VALIDATION_MESSAGES = {
  CAMPO_OBRIGATORIO: 'Campo obrigatório',
  MAX_CHAR: 'Quantidade máxima de 4 dígitos.',
};

export const GENERO = {
  MASCULINO: 'Masculino',
  FEMINIMO: 'Feminimo',
  SIGLA_MASCULINO: 'M',
  SIGLA_FEMINIMO: 'F',
};

export const DIGITO_AGENCIA = '0';
