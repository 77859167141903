import { useContext } from 'react';
import { usePeco } from 'main/hooks/usePeco';
import { AppContext } from 'main/contexts/AppContext';
import { PECOS_PRESTAMISTA } from 'prestamista/config/endpoints';
import { usePrestamistaContext } from 'prestamista/contexts/PrestamistaContextProvider';
import * as PECO_TYPES from 'prestamista/features/dadosSegurado/types/DadosSeguradoUsePeco';
import * as REQUEST_TYPES from 'prestamista/features/dadosSegurado/types/DadosSeguradoRequest';
import * as RESPONSE_TYPES from 'prestamista/features/dadosSegurado/types/DadosSeguradoResponse';
import * as DADOS_SEGURADO_TYPES from 'prestamista/features/dadosSegurado/types/DadosSegurado';

export const usePecoDadosSegurado = (): PECO_TYPES.IUseDadosSegurado => {
  const { cpfCnpj } =
    usePrestamistaContext<DADOS_SEGURADO_TYPES.IDadosSeguradoContextData>();

  const { response: dadosSegurado, loading: loadingDadosSegurado } = usePeco<
    REQUEST_TYPES.IListarDadosCadastroSeguroPrestamistaPayload,
    RESPONSE_TYPES.IListarDadosCadastroSeguroPrestamistaResponse[]
  >({
    api: {
      operationPath: PECOS_PRESTAMISTA.ListarDadosCadastroSeguroPrestamista,
    },
    handleResponse: { throwToastErrorBFF: false },
    autoFetch: true,
    payload: {
      numeroCpf: cpfCnpj,
    },
  });

  return {
    dadosSegurado: dadosSegurado?.entidade?.[0],
    loadingDadosSegurado,
  };
};

export const usePecoEnderecoPeloCep = ({
  cep,
}: PECO_TYPES.IUseEnderecoPeloCepParams): PECO_TYPES.IUseEnderecoPeloCep => {
  const { clientePrestamista } = useContext(AppContext);

  const {
    response: dadosEnderecoPeloCep,
    loading: loadingDadosEnderecoPeloCep,
    fetchData: obterEnderecoPeloCep,
  } = usePeco<
    REQUEST_TYPES.IEnderecoPeloCepPayload,
    RESPONSE_TYPES.IEnderecoPeloCepResponse
  >({
    api: { operationPath: PECOS_PRESTAMISTA.BuscarEnderecoCep },
    payload: {
      cpfCnpj: clientePrestamista.cpfCnpj,
      cep,
    },
  });

  return {
    dadosEnderecoPeloCep,
    loadingDadosEnderecoPeloCep,
    obterEnderecoPeloCep,
  };
};

export const usePecoAtualizarDadosSegurado =
  (): PECO_TYPES.IUseAtualizarDadosSegurado => {
    const {
      response: dadosSeguradoAtualizados,
      loading: loadingDadosSeguradoAtualizados,
      fetchData: atualizarDadosSegurado,
    } = usePeco<unknown, RESPONSE_TYPES.IAtualizarDadosSeguradoResponse>({
      api: {
        operationPath:
          PECOS_PRESTAMISTA.AtualizarDadosCadastroSeguroPrestamista,
      },
    });
    return {
      dadosSeguradoAtualizados,
      loadingDadosSeguradoAtualizados,
      atualizarDadosSegurado,
    };
  };
