import { PECOS_PRESTAMISTA } from 'contratosPrestamista/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import { IImportanciaSegurada } from 'main/features/prospeccao/types/IImportanciaSegurada';
import {
  IConsultarSubscricaoPjRequest,
  IConsultarSubscricaoPjResponse,
} from 'contratosPrestamista/types';

export const usePecoFluxoPJ = () => {
  const {
    response: responseConsultarSubscricao,
    fetchData: fetchConsultarSubscricao,
    loading: loadingConsultarSubscricao,
  } = usePeco<IConsultarSubscricaoPjRequest, IConsultarSubscricaoPjResponse>({
    api: {
      operationPath: PECOS_PRESTAMISTA.ConsultarSubscricaoPj,
    },
    handleResponse: {
      throwToastErrorBFF: false,
      throwToastErrorGI: false,
    },
  });

  const { loading: loadingObterIs, fetchData: fetchDataImportanciaSegurada } =
    usePeco<{ cpfCnpjCliente: string }, IImportanciaSegurada>({
      api: {
        operationPath: PECOS_PRESTAMISTA.ObterValorImportanciaSegurada,
      },
      handleResponse: {
        throwToastErrorBFF: false,
        throwToastErrorGI: false,
      },
    });

  return {
    responseConsultarSubscricao,
    loadingConsultarSubscricao,
    fetchConsultarSubscricao,
    loadingObterIs,
    fetchDataImportanciaSegurada,
  };
};
