import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { useQuery } from 'react-query';
import { obterCertificadoDetalhe } from '../service/consultarProdutosApi';

const useObterCertificadosDetlhadoPrevidencia = (codCliente?: string) => {
  return useQuery(
    ['detalhes-previdencia', codCliente],
    () => obterCertificadoDetalhe(codCliente),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
    },
  );
};

export default useObterCertificadosDetlhadoPrevidencia;
