import React from 'react';
import { Text } from '@cvp/design-system/react';
import VoltarDadosPlano from 'main/components/Button/voltarDadosPlano/VoltarDadosPlano';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import InformacoesProduto from 'previdencia/features/SimulacaoRenda/components/InformacoesProduto';
import SimulacaoRendaNaoPermitida from 'previdencia/features/SimulacaoRenda/components/SimulacaoRendaNaoPermitida';
import TiposRenda from 'previdencia/features/SimulacaoRenda/components/TiposRenda';
import useConsultarDadosBasicos from 'previdencia/features/SimulacaoRenda/hooks/useConsultarDadosBasicos';
import { ResponseConsultarDadosBasicos } from 'previdencia/features/SimulacaoRenda/types/dadosBasicos';
import { UseQueryResult } from 'react-query';
import {
  LEGENDA,
  TITULO,
} from 'previdencia/features/SimulacaoRenda/constants/IndicacaoTipoRenda';

const IndicacaoTipoRenda: React.FC = () => {
  const {
    data: dadosBasicos,
    isLoading: loadingDadosBasicos,
    error,
    isError,
  }: UseQueryResult<
    ResponseConsultarDadosBasicos | undefined
  > = useConsultarDadosBasicos();

  const mensagemErroSimulacaoNaoPermitida = error as Error;

  if (loadingDadosBasicos) {
    return <SkeletonLoading blocks={2} />;
  }

  return (
    <>
      <RenderConditional condition={!isError}>
        <Text variant="body01-sm" margin>
          {TITULO}
        </Text>

        <InformacoesProduto dados={dadosBasicos} />

        <TiposRenda planoContratado={dadosBasicos?.codTipoPagamento} />

        <Text variant="caption-02" margin>
          {LEGENDA}
        </Text>

        <VoltarDadosPlano />
      </RenderConditional>

      <RenderConditional condition={isError}>
        <SimulacaoRendaNaoPermitida
          mensagemErro={mensagemErroSimulacaoNaoPermitida?.message}
        />
      </RenderConditional>
    </>
  );
};

export default IndicacaoTipoRenda;
