import { Card, Divider, Grid, Skeleton, Text } from '@cvp/design-system/react';
import Icon from 'main/components/Icon/Icon';
import { Display } from 'main/features/Auth/components';
import React from 'react';
import * as S from 'sinistro/features/statusSinistro/pages/styles';

const CardProdutoSkeleton: React.FC = () => {
  return (
    <Grid.Item xs={1} md={1 / 3} xl={1 / 4}>
      <Card>
        <S.ContainerCertificados>
          <Card.Content padding={[1, 2, 0]}>
            <Display alignItems="center">
              <S.CertificadosText>
                <Skeleton lines={1} />
              </S.CertificadosText>
            </Display>
          </Card.Content>
          <Divider />
          <Card.Content padding={[1, 2, 0]}>
            <Display alignItems="center">
              <S.IconContainer>
                <Icon name="documentPaper" />
              </S.IconContainer>
              <span>
                <Text variant="caption-02">
                  <strong>Certificado</strong>
                </Text>
                <Text variant="body03-md">
                  <Skeleton lines={1} />
                </Text>
              </span>
            </Display>
            <Display alignItems="center">
              <S.IconContainer>
                <Icon name="user" />
              </S.IconContainer>
              <span>
                <Text variant="caption-02">
                  <strong>Sinistro</strong>
                </Text>
                <Text variant="body03-md">
                  <Skeleton lines={1} />
                </Text>
              </span>
            </Display>

            <Display alignItems="center">
              <S.IconContainer>
                <Icon name="calendar" />
              </S.IconContainer>
              <span>
                <Text variant="caption-02">
                  <strong>Data Sinistro</strong>
                </Text>
                <Text variant="body03-md">
                  <Skeleton lines={1} />
                </Text>
              </span>
            </Display>

            <Display alignItems="center">
              <S.IconContainer>
                <Icon name="documentBack" />
              </S.IconContainer>
              <span>
                <Text variant="caption-02">
                  <strong>Status</strong>
                </Text>
                <Text variant="body03-md">
                  <Skeleton lines={1} />
                </Text>
              </span>
            </Display>
          </Card.Content>
        </S.ContainerCertificados>
      </Card>
    </Grid.Item>
  );
};

export default CardProdutoSkeleton;
