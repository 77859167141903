import React, { useState, useContext } from 'react';
import { Display } from '@cvp/design-system/react';
import FabButton, { Action } from 'portabilidade/components/FabButton';
import Icon from 'main/components/Icon';
import DetalhesPreAnalise from 'main/features/prospeccao/components/DetalhesPreAnalise';
import { ProspeccaoContext } from 'main/features/prospeccao/contexts/ProspeccaoContext';
import { useHistory } from 'react-router';
import RenderConditional from 'main/components/RenderConditional';
import { ModalFinalizarAvaliacao } from './ModalFinalizarAvaliacao';
import DetalhesDoParecer from './DetalhesDoParecer';
import { useAtualizarStatusProspeccao } from '../hooks/useAtualizarStatusProspeccao';
import { useObterPerfilUsuario } from 'main/features/Auth/hooks/useObterPerfilUsuario';

type DetalhesDefinirParecerProps = {
  perfilAvaliador: string;
  approveRejectIds: { approve: number; reject: number };
  title: string;
  permitirParecer: (tipoSituacaoVigente: number) => boolean;
};

const DetalhesDefinirParecer: React.FC<DetalhesDefinirParecerProps> = ({
  perfilAvaliador,
  approveRejectIds,
  title,
  permitirParecer,
}) => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const { updateStatus, isLoadingUpdateStatus } =
    useAtualizarStatusProspeccao();
  const { propeccao } = useContext(ProspeccaoContext);
  const perfilUsuario = useObterPerfilUsuario();

  const handleConfirm = async (justificativa: string, status: number) => {
    await updateStatus({
      cpfCnpjCliente: propeccao.cpfCnpjCliente,
      idProspeccao: propeccao.id,
      perfil: perfilUsuario,
      tipoSituacaoProspeccao: status,
      justificativa,
    });
    setOpenModal(false);
    history.push('/');
  };

  const possuiParecer = propeccao?.respostasFormulario?.avaliacoes?.find(
    x => x.PerfilAvaliador.toUpperCase() === perfilAvaliador,
  );

  return (
    <Display type="display-block">
      <DetalhesPreAnalise key="01">
        <RenderConditional condition={!!possuiParecer}>
          <DetalhesDoParecer title={title} resultadoAvaliacao={possuiParecer} />
        </RenderConditional>
      </DetalhesPreAnalise>

      <FabButton iconName="floatMenu">
        <Action
          disabled={!permitirParecer(propeccao.codigoTipoSituacaoVigente)}
          text="Finalizar avaliação"
          onClick={() => setOpenModal(true)}
        >
          <Icon name="edit" />
        </Action>
      </FabButton>
      <ModalFinalizarAvaliacao
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleConfirm={handleConfirm}
        title={title}
        loading={isLoadingUpdateStatus}
        approveRejectIds={approveRejectIds}
      />
    </Display>
  );
};

export default DetalhesDefinirParecer;
