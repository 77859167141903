/* eslint-disable */

import { isMatriculaCef } from 'main/features/Auth/utils/cefMatriculaFormatter';
import { IMask, MaskMethods } from '../types/IMask';
import { FORMATA_EMAIL, REMOVE_CARACTERES_EMAIL } from './regex';
export interface CommonMasks extends IMask {
  cpf: MaskMethods;
  cnpj: MaskMethods;
  cpfCnpj: MaskMethods;
  matriculaCef: MaskMethods;
  rg: MaskMethods;
  date: MaskMethods;
  dateFop: MaskMethods;
  phone: MaskMethods;
  name: MaskMethods;
  homePhone: MaskMethods;
  homePhoneNoPrefix: MaskMethods;
  commercialPhone: MaskMethods;
  numberOnly: MaskMethods;
  agency: MaskMethods;
  agencyCode: MaskMethods;
  year: MaskMethods;
  currency: MaskMethods;
  currencyInput: MaskMethods;
  none: MaskMethods;
  cep: MaskMethods;
  address: MaskMethods;
  number: MaskMethods;
  complement: MaskMethods;
  neighborhood: MaskMethods;
  city: MaskMethods;
  uf: MaskMethods;
  percentage: MaskMethods;
  tokenOtp: MaskMethods;
  phoneWithoutDDD: MaskMethods;
  emailInput: MaskMethods;
}
function unmask(mask: string) {
  return mask.replace(/(\.)?(-)?([/])?/g, '');
}

const masks: CommonMasks = {
  cpf: {
    mask(cpf: string) {
      const cpfSemFormatacao = cpf?.replace(/\D/g, '');
      if (cpfSemFormatacao?.length <= 14) {
        return cpfSemFormatacao
          .substr(0, 11)
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
      }
      return cpf?.substring(0, 11);
    },
    unmask,
  },
  cnpj: {
    mask(cnpj: string) {
      const cnpjSemFormatacao = cnpj?.replace(/\D/g, '');
      if (cnpjSemFormatacao.length === 14) {
        return cnpjSemFormatacao
          .replace(/^(\d{2})(\d)/, '$1.$2')
          .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
          .replace(/\.(\d{3})(\d)/, '.$1/$2')
          .replace(/(\d{4})(\d)/, '$1-$2');
      }
      return cnpj?.substring(0, 14);
    },
    unmask,
  },
  cpfCnpj: {
    mask(document: string) {
      const cleanedDocument = document.replace(/\D/g, '');

      if (cleanedDocument.length <= 11) {
        return cleanedDocument
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
      } else {
        return cleanedDocument
          .substring(0, 14)
          .replace(/^(\d{2})(\d)/, '$1.$2')
          .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
          .replace(/\.(\d{3})(\d)/, '.$1/$2')
          .replace(/(\d{4})(\d)/, '$1-$2');
      }
    },
    unmask,
  },
  matriculaCef: {
    mask(matricula: string) {
      const matriculaUpperCase = matricula
        ?.toUpperCase()
        .replace(/[^\w\s]/gi, '');
      const onlyNumberRegex = /^[0-9]*$/;

      if (
        onlyNumberRegex.test(matriculaUpperCase) &&
        matriculaUpperCase?.length == 6
      ) {
        const matriculaFormatada = matriculaUpperCase
          .replace(/\D/g, '')
          .substring(0, 6);
        return `C${matriculaFormatada}`;
      }

      if (isMatriculaCef(matriculaUpperCase)) {
        return matriculaUpperCase.substring(0, 7);
      }
      return matriculaUpperCase;
    },
    unmask,
  },
  rg: {
    mask(rg: string) {
      return rg
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4');
    },
    unmask,
  },

  date: {
    mask(dateMask: string) {
      return dateMask
        ?.replace(/\D/g, '')
        .substring(0, 8)
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2');
    },
    unmask: (text: string) => text,
  },
  dateFop: {
    mask(dateMask: string) {
      const maskDate = dateMask
        ?.replace(/\D/g, '')
        .substring(0, 6)
        .replace(/(\d{4})(\d)/, '$1-$2');
      return maskDate.split('-').reverse().join('/');
    },
    unmask: (text: string) => text,
  },

  phone: {
    mask(phoneMask: string) {
      if (!phoneMask) {
        return '';
      }
      return phoneMask
        ?.replace(/\D/g, '')
        .replace(/^(\d)/, '($1')
        .replace(/^(\(\d{2})(\d)/, '$1) $2')
        .replace(/(\d{5})(\d{4})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    },
    unmask: (text: string) => masks.numberOnly.mask(text),
  },
  name: {
    mask(value: string) {
      return value.replace(/\d/g, '');
    },
    unmask,
  },
  homePhone: {
    mask(phoneMask: string) {
      if (!phoneMask) {
        return '';
      }
      return phoneMask
        ?.replace(/\D/g, '')
        .replace(/^(\d)/, '($1')
        .replace(/^(\(\d{2})(\d)/, '$1) $2')
        .replace(/(\d{4})(\d{4})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    },
    unmask: (text: string) => masks.numberOnly.mask(text),
  },

  homePhoneNoPrefix: {
    mask(phoneMask) {
      if (!phoneMask) {
        return '';
      }
      return phoneMask
        ?.replace(/\D/g, '')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    },
    unmask: text => masks.numberOnly.mask(text),
  },

  movelPhoneNoPrefix: {
    mask(phoneMask) {
      if (!phoneMask) {
        return '';
      }
      return phoneMask
        ?.replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    },
    unmask: text => masks.numberOnly.mask(text),
  },

  commercialPhone: {
    mask(phoneMask: string) {
      return phoneMask
        .replace(/\D/g, '')
        .replace(/^(\d)/, '($1')
        .replace(/^(\(\d{2})(\d)/, '$1) $2')
        .replace(/(\d{4,5})(\d{4})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    },
    unmask: (text: string) => masks.numberOnly.mask(text),
  },
  numberOnly: {
    mask(value: string, length = 200) {
      if (!value) {
        return '';
      }
      return value.replace(/\D/g, '').substring(0, length);
    },
    unmask: (text: string) => text.replace(/\D/g, ''),
  },
  agency: {
    mask(value: string, length = 4) {
      if (value === '') {
        return value;
      }

      return value.replace(/\D/g, '').substring(0, length);
    },
    unmask,
  },
  agencyCode: {
    mask(value: string, length = 4) {
      if (value === '') {
        return value;
      }

      while (value?.length < length) value = '0' + value;
      return value;
    },
    unmask,
  },
  year: {
    mask(value: string) {
      if (value === '') {
        return value;
      }

      value = value.replace(/\D/g, '');

      if (value?.length >= 2) {
        value = value.substring(0, 2);
      }

      return `${value} anos`;
    },
    unmask(value: string) {
      const onlyNumber = value.replace(/\D/g, '');
      if (value?.length > 3 && value.substring(2).trim() === 'ano') {
        return onlyNumber.substring(0, onlyNumber?.length - 1);
      }
      return onlyNumber.substring(0, 2);
    },
  },
  currency: {
    mask(value: number = 0) {
      if (typeof value === 'string') {
        return masks.currencyInput.mask(value);
      }
      if (!Number.isNaN(value) && Number.isFinite(value)) {
        const finalValue = value
          ?.toFixed(2)
          .replace('.', ',')
          .replace(/\d(?=(\d{3})+,)/g, '$&.');
        return `R$ ${finalValue}`;
      }
      return '';
    },
    unmask(mask: string) {
      return mask?.replace(/\D/g, '');
    },
  },
  currencyInput: {
    mask(value: string) {
      let newValue = `${value}`.replace(/\R\$/g, '');
      newValue = newValue.replace(',', '').split('.').join('');
      const valueParsed = parseFloat(newValue);
      return newValue === '' ? '' : masks.currency.mask(valueParsed / 100);
    },
    unmask(mask: string) {
      return mask?.replace(/\D/g, '');
    },
  },
  none: {
    mask(text: string) {
      return text;
    },
    unmask(text: string) {
      return text;
    },
  },
  cep: {
    mask(cep: string) {
      if (!cep) {
        return '';
      }
      return cep
        .replace(/\D/g, '')
        .replace(/(.{8})(.)/g, '$1')
        .replace(/^(\d{5})(\d)/, '$1-$2');
    },
    unmask,
  },
  address: {
    mask(value: string) {
      return value
        .replace(/\$|\@|\%|\#|\!|\&|\*|\(|\)|\>|\<|\?|\/|\|\;|\:/g, '')
        .substring(0, 50);
    },
    unmask,
  },
  number: {
    mask(value: string) {
      if (!value) {
        return '';
      }
      return value.replace(/\D/g, '').substring(0, 15);
    },
    unmask: (value: string) => masks.numberOnly.mask(value),
  },
  complement: {
    mask(value: string) {
      return value
        .replace(/\$|\@|\%|\#|\!|\&|\*|\(|\)|\>|\<|\?|\/|\|\;|\:\¨\¨¨/g, '')
        .substring(0, 100);
    },
    unmask,
  },
  neighborhood: {
    mask(value: string) {
      return value
        .replace(/\$|\@|\%|\#|\!|\&|\*|\(|\)|\>|\<|\?|\/|\|\;|\:\¨\¨¨/g, '')
        .substring(0, 30);
    },
    unmask,
  },
  city: {
    mask(value: string) {
      return value
        .replace(/\$|\@|\%|\#|\!|\&|\*|\(|\)|\>|\<|\?|\/|\|\;|\:\¨\¨¨/g, '')
        .substring(0, 35);
    },
    unmask,
  },
  uf: {
    mask(value: string) {
      return value
        .replace(/\d|\$|\@|\%|\#|\!|\&|\*|\(|\)/g, '')
        .substring(0, 2);
    },
    unmask,
  },
  percentage: {
    mask(value: string, length = 3) {
      if (parseFloat(value) > 100) {
        return '100';
      }
      if (!value) {
        return '';
      }
      return value.replace(/\D/g, '').substring(0, length);
    },
    unmask: (text: string) => text.replace(/\D/g, ''),
  },
  tokenOtp: {
    mask(value: string) {
      return value
        .substring(0, 9)
        .replace(/\D/g, '')
        .replace(/^(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');
    },
    unmask,
  },
  bankAccount: {
    mask(valueAccount: string, lengthAccount = 13) {
      if (!valueAccount) {
        return '';
      }
      return valueAccount.replace(/\D/g, '').substring(0, lengthAccount);
    },
    unmask: (text: string) => text.replace(/\D/g, ''),
  },
  dataBankDigits: {
    mask(value: string, length = 1) {
      if (!value) {
        return '';
      }
      return value
        .replace(/\$|\@|\%|\#|\!|\&|\*|\(|\)|\>|\<|\?|\/|\|\;|\:\¨\¨¨/g, '')
        .substring(0, length);
    },
    unmask: (text: string) =>
      text.replace(/\$|\@|\%|\#|\!|\&|\*|\(|\)|\>|\<|\?|\/|\|\;|\:\¨\¨¨/g, ''),
  },
  removeSpaces: {
    mask(value: string, length = 1) {
      if (!value) {
        return '';
      }
      return value.replace(/\s/g, '');
    },
    unmask: (text: string) => text,
  },
  removeAcentos: {
    mask(value: string, length = 1) {
      if (!value) {
        return '';
      }

      return value.replace('Ã¡', 'á').replace('Ã©', 'é').replace('Ã¡', 'á');
    },
    unmask: (text: string) => text,
  },
  phoneWithoutDDD: {
    mask(phoneMask = '') {
      const numericPhone = phoneMask.replace(/\D/g, '');
      const formattedPhone = numericPhone.slice(0, 9);

      if (formattedPhone.length === 8) {
        return formattedPhone.replace(/(\d{4})(\d{4})/, '$1-$2');
      }

      if (formattedPhone.length === 9) {
        return `${formattedPhone.slice(0, 5)}-${formattedPhone.slice(5, 9)}`;
      }

      return formattedPhone;
    },
    unmask: (text: string) => {
      const numericPhone = text.replace(/\D/g, '');

      if (numericPhone.length > 9) {
        return numericPhone.slice(0, 9);
      }

      return numericPhone;
    },
  },
  emailInput: {
    mask(value: string) {
      return value.replace(FORMATA_EMAIL, '').trim();
    },
    unmask(mask: string) {
      return mask
        .replace(FORMATA_EMAIL, '')
        .replace(REMOVE_CARACTERES_EMAIL, '')
        .trim();
    },
  },
};

export default masks;
