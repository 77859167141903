import { usePeco } from 'main/hooks/usePeco';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import { PECOS } from 'previdencia/config/endpoints';
import {
  PayloadAnosDisponiveisDetalhes,
  ResponseAnosDisponiveisDetalhes,
  UseObterAnosDetalhes,
} from 'previdencia/features/ReajusteAnualPlano/types/ResponseAnosDisponiveisDetalhes';

const useObterAnosDetalhes = (
  cpf: string,
  numeroCertificado: string,
  numeroRegistro?: string,
): UseObterAnosDetalhes => {
  const { loading, response } = usePeco<
    PayloadAnosDisponiveisDetalhes,
    ResponseAnosDisponiveisDetalhes
  >({
    api: { operationPath: PECOS.ObterHistorico },
    autoFetch: true,
    payload: {
      cpf,
      NumeroCertificado: numeroCertificado,
      numeroRegistro,
    },
  });

  return {
    data: response?.entidade,
    isLoading: checkIfSomeItemsAreTrue([!response?.entidade, loading]),
  };
};

export default useObterAnosDetalhes;
