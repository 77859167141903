import React from 'react';
import { Button, Display, Text, Card, Divider } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import { formatarData } from 'main/utils';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import { ICardProdutoVida } from 'consultaCliente/features/listaCardProduto/interfaces/ICardProdutoVida';
import TagStatusApolice from '../../../../../components/TagStatusApolice';
import * as S from '../styles';
import NomeCertificadoCardItem from '../NomeCertificadoCardItem';
import RenderConditional from 'main/components/RenderConditional';

export interface CardProdutoVidaProps {
  data: ICardProdutoVida;
  onClick(): void;
}

export const CardVida: React.FunctionComponent<CardProdutoVidaProps> = ({
  onClick,
  data,
}) => {
  const produtosSemDetalhes = ['9311', '9386', '9711'];
  return (
    <Card>
      <S.Container>
        <Card.Content padding={[1, 2, 0]}>
          <Display alignItems="center">
            <S.Text
              statusLength={data.situacaoRegistro?.length === 26 ? 8 : 12}
              title={data.descricaoProduto}
              status={
                data.situacaoRegistro !== null
                  ? data.situacaoRegistro.substr(0, 1)
                  : 'C'
              }
            >
              {data.descricaoProduto}
            </S.Text>
          </Display>
          <Display justify="end">
            <TagStatusApolice status={data.situacaoRegistro} />
          </Display>
        </Card.Content>
        <Divider />
        <Card.Content padding={[1, 2, 0]}>
          <Display alignItems="center">
            <S.IconContainer>
              <Icon name="creditCardVerse" />
            </S.IconContainer>
            <span>
              <Text variant="caption-02">Apólice</Text>
              <Text variant="body01-sm">{data.numeroApolice}</Text>
            </span>
          </Display>
          <NomeCertificadoCardItem
            nomeCliente={data.nomeSegurado}
            numCertificado={data.numeroBilhete}
          />
          <Display alignItems="center">
            <S.IconContainer>
              <Icon name="calendar" />
            </S.IconContainer>
            <span>
              <Text variant="caption-02">Início da Vigência</Text>
              <Text variant="body03-md">
                {formatarData(data.dataInicioVigencia)}
              </Text>
            </span>
          </Display>
        </Card.Content>
        <RenderConditional
          condition={!produtosSemDetalhes.includes(data.codigoProduto)}
        >
          <FeatureAuthorizer
            requiredRoles={[
              ...PRINCIPAL_USERS,
              USER_PROFILES.ANALISTA_CONSULTA,
              USER_PROFILES.ANALISTA_MANUTENCAO,
              USER_PROFILES.ANALISTA_SAIDA,
              USER_PROFILES.ANALISTA_ENTRADA,
            ]}
          >
            <Card.Content padding={[1, 4, 0]}>
              <Display justify="space-between">
                <Button onClick={onClick} fullWidth small>
                  Detalhes
                </Button>
              </Display>
            </Card.Content>
          </FeatureAuthorizer>
        </RenderConditional>
      </S.Container>
    </Card>
  );
};
