import { Text } from '@cvp/design-system/react';
import {
  EnumTipoRendaCodigo,
  EtapasFluxoSimulacaoRenda,
} from 'previdencia/features/SimulacaoRenda/types/enum';
import { TipoRenda } from 'previdencia/features/SimulacaoRenda/types/tiposRenda';
import {
  RENDA_COM_PRAZO,
  RENDA_PRAZO_CERTO,
  RENDA_REVERSIVEL,
  RENDA_TEMPORARIA,
  RENDA_VITALICIA,
} from 'previdencia/features/SimulacaoRenda/constants/detalhesSimulacao';
import { ISimulacaoRendaContextData } from 'previdencia/features/SimulacaoRenda/types/ISimulacaoRendaContextData';

export const COLUNAS_TABELA_HISTORICO_SIMULACAO_RENDA = [
  {
    name: 'Data e Hora',
    selector: 'dthDiaSimulacaoFormatada',
    wrap: true,
    maxWidth: '220px',
    minWidth: '140px',
  },
  {
    name: 'Tipo de Renda',
    selector: 'nomTipoPagamento',
    minWidth: '140px',
  },
  {
    name: 'Tributação',
    selector: 'tipoTributacao',
    minWidth: '90px',
  },
  {
    name: 'Período de Recebimento',
    selector: 'descPeridoBeneficiarioRecebe',
    minWidth: '140px',
  },
  {
    name: 'Renda Líquida',
    selector: 'vlrBeneficioLiquido',
    maxWidth: '160px',
    minWidth: '120px',
  },
];

export const TIPO_RENDA_CODIGO: string[] = [
  EnumTipoRendaCodigo.Vitalicia,
  EnumTipoRendaCodigo.Temporaria,
  EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido,
  EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario,
  EnumTipoRendaCodigo.PrazoCerto,
];

export const TIPOS_RENDA_SIMULACAO_RENDA: TipoRenda[] = [
  {
    codigo: EnumTipoRendaCodigo.Temporaria,
    tipo: 'Temporária',
    descricao: (
      <>
        <Text variant="body01-sm">
          O cliente receberá uma renda mensal pelo prazo que escolheu.
        </Text>
        <Text variant="body01-sm">
          Esse benefício termina no caso de seu falecimento ou caso o prazo
          acabe.
        </Text>
      </>
    ),
  },
  {
    codigo: EnumTipoRendaCodigo.PrazoCerto,
    tipo: 'Prazo certo',
    descricao: (
      <>
        <Text variant="body01-sm">
          O cliente receberá uma renda mensal pelo prazo que escolheu.
        </Text>
        <Text variant="body01-sm">
          Caso venha a falecer durante esse período, seus beneficiários
          receberão a sua renda até que o prazo termine.
        </Text>
      </>
    ),
  },
  {
    codigo: EnumTipoRendaCodigo.Vitalicia,
    tipo: 'Vitalícia',
    descricao: (
      <>
        <Text variant="body01-sm">
          O cliente receberá uma renda mensal enquanto viver.
        </Text>
        <Text variant="body01-sm">
          Esse benefício só termina no caso de seu falecimento.
        </Text>
      </>
    ),
  },
  {
    codigo: EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido,
    tipo: 'Vitalícia com prazo mínimo garantido',
    descricao: (
      <>
        <Text variant="body01-sm">
          O cliente receberá uma renda mensal enquanto viver.
        </Text>
        <Text variant="body01-sm">
          Caso venha a falecer, seus beneficiários receberão a sua renda por um
          prazo.
        </Text>
        <Text variant="body01-sm">
          Esse prazo começa a contar assim que transformar a sua reserva em
          renda.
        </Text>
      </>
    ),
  },
  {
    codigo: EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario,
    tipo: 'Vitalícia reversível ao beneficiário',
    descricao: (
      <>
        <Text variant="body01-sm">
          O cliente receberá uma renda mensal enquanto viver.
        </Text>
        <Text variant="body01-sm">
          Caso venha a falecer, apenas um beneficiário receberá a sua renda
          enquanto ele viver. Essa renda pode ser integral ou parcial.
        </Text>
      </>
    ),
  },
];

export const TIPOS_RENDA_INFORMACAOA_ADICIONAL: TipoRenda[] = [
  {
    codigo: EnumTipoRendaCodigo.Temporaria,
    tipo: 'temporária',
    descricao: <Text variant="body02-sm">{RENDA_TEMPORARIA.EXEMPLO}</Text>,
  },
  {
    codigo: EnumTipoRendaCodigo.PrazoCerto,
    tipo: 'prazo certo',
    descricao: (
      <>
        <Text variant="body02-sm">{RENDA_PRAZO_CERTO.EXEMPLO_INICIO}</Text>
        <Text variant="body02-sm">{RENDA_PRAZO_CERTO.EXEMPLO_FIM}</Text>
      </>
    ),
  },
  {
    codigo: EnumTipoRendaCodigo.Vitalicia,
    tipo: 'vitalícia',
    descricao: <Text variant="body02-sm">{RENDA_VITALICIA.EXEMPLO}</Text>,
  },
  {
    codigo: EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido,
    tipo: 'vitalícia com prazo mínimo garantido',
    descricao: (
      <>
        <Text variant="body02-sm">{RENDA_COM_PRAZO.EXEMPLO_INICIO}</Text>
        <Text variant="body02-sm">{RENDA_COM_PRAZO.EXEMPLO_FIM}</Text>
      </>
    ),
  },
  {
    codigo: EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario,
    tipo: 'vitalícia reversível ao beneficiário',
    descricao: <Text variant="body02-sm">{RENDA_REVERSIVEL.EXEMPLO}</Text>,
  },
];

export const DADOS_INICIAIS_SIMULACAO_RENDA: ISimulacaoRendaContextData = {
  etapaAtual: EtapasFluxoSimulacaoRenda.INICIO_SIMULACAO,
  requestSimularRenda: {},
  podeAlterarRegimeTributario: false,
  exibirModalConsulta: false,
};
