import { useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import { api, IApiResponse } from 'main/services';
import { obterEndpointUploadProposta } from 'contratosPrestamista/config/endpoints';
import { useQueryClient } from 'react-query';
import { IResponseListaProposta } from '../types/IResponseListaProposta';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
/* eslint-disable @typescript-eslint/no-explicit-any  */
export const useUploadProposta = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorReponse, setError] = useState<any>(false);
  const client = useQueryClient();

  const toast = useToast();
  const upload = async (
    proposta: IResponseListaProposta,
    files: FileList,
  ): Promise<boolean> => {
    try {
      setLoading(true);
      const data = new FormData();
      const file = files[0];
      data.append('arquivo', file);
      data.append('numeroProposta', proposta.numeroProposta);
      data.append('numeroLinhaDoProduto', proposta.numeroLinhaDoProduto);
      data.append('codigoDoEstipulante', proposta.codigoDoEstipulante);
      const result = await api.post<IApiResponse<unknown>>(
        obterEndpointUploadProposta(),
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      if (result.data.dados.sucesso) {
        toast.toastSuccess();
        client.resetQueries('propostas', { exact: true });

        return !!result.data.dados.sucesso;
      }
      toast.toastError();
    } catch (error: any) {
      setError(error);
      toast.toastError();
    } finally {
      setLoading(false);
    }

    return false;
  };

  return {
    upload,
    loading,
    errorReponse,
  };
};
