import React, { useContext, useEffect } from 'react';
import { ListaPreAnalise } from 'main/features/prospeccao/components/ListaPreAnalise';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { IProspeccao } from 'main/features/prospeccao/types/IProspeccao';
import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';
import { ProspeccaoContext } from 'main/features/prospeccao/contexts/ProspeccaoContext';
import { useListarProspeccoes } from '../../../hooks/useListarProspeccoes';

const ListaAnaliseTimeProduto: React.FC = () => {
  const { getAnalistaProdutoRouteDetails } = useContext(ProspeccaoContext);

  const { prospeccoesPerfilProduto, obterListaProspeccoesPerfilProduto } =
    useListarProspeccoes();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    obterListaProspeccoesPerfilProduto();
  }, []);

  if (prospeccoesPerfilProduto.loading) return <SkeletonLoading lines={4} />;

  return (
    <div>
      <ListaPreAnalise
        profile={USER_PROFILES.ANALISTA_PRODUTO}
        onClickDetails={(prospeccao: IProspeccao) =>
          getAnalistaProdutoRouteDetails(prospeccao)
        }
        data={prospeccoesPerfilProduto.items ?? []}
        title="Análise técnica"
        noDataMessage="Não há prospecções para análise técnica"
      />
    </div>
  );
};

export default ListaAnaliseTimeProduto;
