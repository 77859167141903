import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { parseDateStringToDate } from 'main/utils/date';
import { PECOS } from 'previdencia/config/endpoints';
import { ResponseExtratoUnificado } from 'previdencia/features/PosicaoConsolidada/types/ResponseExtratoUnificado';
import { IRequestExtratoUnificado } from 'previdencia/features/PosicaoConsolidada/types/RequestExtratoUnificado';

export const useExtratoUnificado = () => {
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);
  const { loading, response, fetchData } = usePeco<
    IRequestExtratoUnificado,
    ResponseExtratoUnificado
  >({
    api: { operationPath: PECOS.ObterExtratoUnificado },
    autoFetch: true,
    payload: {
      cpf: cpfCnpj,
      dataFinal: parseDateStringToDate(new Date()),
      dataInicial: parseDateStringToDate(new Date()),
    },
  });

  return {
    loading,
    response: response?.entidade,
    obterExtratoUnificado: fetchData,
  };
};
