import { api } from 'main/services/api';

import { obterChaveArmazenamentoTokenCliente } from 'config/storage_keys';
import { TIPOS_SEGMENTO } from 'consultaCliente/constants/constants';
import { IApiResponse } from 'main/services';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { getSessionItem } from 'main/utils/storage';
import { capitalize } from 'main/utils/string';
import { obterEndpointContratosPrestamista } from 'prestamista/config/endpoints';
import {
  obterEndpointCertificadoPrevidenciaDetalhar,
  obterEndpointCertificadosPrevidencia,
} from 'previdencia/config/endpoints';
import { obterTokenMatriz } from 'previdencia/services/matrizAcesso.api';
import { IMatrizAcessoCliente } from 'previdencia/types/MatrizDeAcesso';
import { obterEndpointSegurosVida } from 'vida/config/endpoints';
import { mapRegimeTributario } from 'consultaCliente/utils/prevdomainMappers';
import { ICardProdutoPrestamista } from 'consultaCliente/features/listaCardProduto/interfaces/ICardProdutoPrestamista';
import { ICardProdutoVida } from 'consultaCliente/features/listaCardProduto/interfaces/ICardProdutoVida';
import { ICertificadoPrevidenciaDetalharPayload } from 'consultaCliente/features/listaCardProduto/interfaces/ICertificadoPrevidenciaDetalharPayload';
import { ICertificadoPrevidenciaDetalharResponse } from 'consultaCliente/features/listaCardProduto/interfaces/ICertificadoPrevidenciaDetalharResponse';
import { IDadosBasicosCertificadoPrevidencia } from 'consultaCliente/features/listaCardProduto/interfaces/IDadosBasicosCertificadoPrevidencia';

export const obterProdutosPrevidencia = async (codCliente?: string) => {
  if (!codCliente) return undefined;
  await obterTokenMatriz(codCliente);
  const matrizCached = getSessionItem<IMatrizAcessoCliente>(
    obterChaveArmazenamentoTokenCliente(),
  );

  if (matrizCached) {
    const { data } = await api.post<
      IApiResponse<IDadosBasicosCertificadoPrevidencia[]>
    >(obterEndpointCertificadosPrevidencia(), {
      cpfCliente: codCliente,
    });
    if (data?.dados?.sucesso) {
      const response = data.dados.entidade;
      return response?.map(item => ({
        ...item,
        regimeTributario: mapRegimeTributario(item.opcaoImpostoTipo),
        descricaoProduto: capitalize(
          item.produto.descricaoProduto?.toLowerCase(),
        ),
      })) as IDadosBasicosCertificadoPrevidencia[];
    }
  }

  return [] as IDadosBasicosCertificadoPrevidencia[];
};

export const obterCertificadoDetalhe = async (codCliente?: string) => {
  if (!codCliente) return undefined;
  const { data } = await api.post<
    IApiResponse<ICertificadoPrevidenciaDetalharResponse[]>
  >(obterEndpointCertificadoPrevidenciaDetalhar(), {
    cpf: codCliente,
  } as ICertificadoPrevidenciaDetalharPayload);
  if (data?.dados?.sucesso) {
    return data.dados.entidade;
  }

  return [] as ICertificadoPrevidenciaDetalharResponse[];
};

export const obterProdutosVida = async (codCliente: string) => {
  const { data } = await api.post<IApiResponse<ICardProdutoVida[]>>(
    obterEndpointSegurosVida(),
    {
      cpf: codCliente,
    },
  );
  if (data.sucesso) {
    const response = data.dados.entidade;
    return response?.map((item: ICardProdutoVida) => ({
      ...item,
      nomeProduto: capitalize(
        tryGetValueOrDefault([item.descricaoProduto?.toLowerCase()], ''),
      ),
    })) as ICardProdutoVida[];
  }
  return [] as ICardProdutoVida[];
};

export const obterProdutosPrestamista = async (codCliente: string) => {
  const { data } = await api.post<IApiResponse<ICardProdutoPrestamista[]>>(
    obterEndpointContratosPrestamista(),
    {
      cpfcnpj: codCliente,
      segmento: TIPOS_SEGMENTO.PRESTAMISTA,
    },
  );

  if (data.sucesso) {
    const response = data.dados.entidade;

    return response?.map((item: ICardProdutoPrestamista) => ({
      ...item,
      nomeProduto: capitalize(
        tryGetValueOrDefault(
          [item?.produto?.descricaoComunicacaoProduto?.toLowerCase()],
          '',
        ),
      ),
    })) as ICardProdutoPrestamista[];
  }
  return [] as ICardProdutoPrestamista[];
};
