import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { ResponseSimularBeneficios } from 'previdencia/features/SimulacaoRenda/types/simularBeneficios';

export const exibirTextoReservaMinima = (
  simulacao: ResponseSimularBeneficios | undefined,
): boolean => {
  return checkIfAllItemsAreTrue([
    !!simulacao?.descMensagemError,
    !!simulacao?.codTipoPagamento,
  ]);
};
