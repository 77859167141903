import styled from 'styled-components';

export const WrapperMessage = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 14,
  marginTop: 26,
}));

export const WrapperIcom = styled.div(() => ({
  marginRight: 12,
}));
