export const tipoEmailConstants = {
  EXTATRO_PREVIDENCIA: 'ExtratoPrevidencia',
  CONSULTA_IR: 'InformeRendimentos',
  SEGUNDA_VIA_CERTIFICADO: 'SegundaViaCertificadoPrevidencia',
  SEGUNDA_VIA_BOLETO_CONTRIBUICOES_PAGAS: 'SegundaViaBoletoContribuicoesPagas',
  SIMULACAO_RESGATE: 'SimulacaoResgate',
  COMPROVANTE_RESGATE: 'ComprovanteResgate',
  COMPROVANTE_BENEFICIARIO: 'ComprovanteBeneficiario',
  COBERTURA_PLANO_PREVIDENCIA: 'CoberturasPlanoPrevidencia',
  SIMULACAO_RENDA_PREVIDENCIA: 'SimularRendaPrevidencia',
  CARTEIRA_INVESTIMENTOS: 'CarteiraInvestimentosPosicaoConsolidadaDoDia',
  EXTRATO_COTAS_RENTABILIDADE: 'ExtratoCotasRentabilidade',
  ATIVACAO_CONTRIBUICAO: 'Ativacao',
  SUPENSAO_CONTRIBUICAO: 'Suspensao',
  CANCELAMENTO_CUIDADO_EXTRA: 'CancelamentoCuidadoExtra',
  QUITACAO_ANUAL_DEBITOS: 'ExtratoQuitacaoAnual',
  COMPROVANTE_APORTE_PREVIDENCIA: 'ComprovanteAporteAdicionalPrevidencia',
  REAJUSTE_ANUAL_PLANO_PREVIDENCIA: 'ReajusteAnualPlano',
  GUIA_PREENCHIMENTO_IR: 'GuiaPreenchimentoIR',
  EMISSAO_CERTIFICADO_VIDA: 'CertificadoSeguros',
  CARTEIRA_INVESTIMENTOS_POSICAO_CONSOLIDADA:
    'CarteiraInvestimentosPosicaoConsolidadaDoDia',
  DADOS_APOLICE_VIDA: 'DadosApoliceVida',
  HISTORICO_PAGAMENTOS: 'ConsultarParcelasPagamentosSeguroVida',
  INFORMACOES_BENEFICIARIO: 'InformacoesBeneficiario',
  DADOS_SEGURO_IRPF: 'DadosSeguroIRPF',
  DADOS_SEGURADO: 'DadosSegurado',
  DECLARACAO_QUITACAO_ANUAL: 'DeclaracaoQuitacaoAnual',
};
