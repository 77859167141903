import React, { useEffect } from 'react';
import { Select as SelectDS } from '@cvp/design-system/react';
import withValidation from 'main/features/Validation/hocs/withValidation';
import ValidableProps from 'main/features/Validation/types/ValidableProps';

import * as S from './styles';

type Item = {
  value: string;
  text: string;
  selected: boolean;
};

interface SelectProps extends ValidableProps<string> {
  placeholder?: string;
  Item?: React.FC<Item> | null;
  dettach?: boolean;
  label?: string;
  [x: string]: unknown;
  isDisabled?: boolean;
}

const Select: React.FC<SelectProps> = ({
  link,
  children,
  dettach,
  label,
  isDisabled,
  ...props
}) => {
  const handleChange = (e: React.ChangeEvent<Item>) => {
    const { value } = e.target;

    link.set({
      value,
      isValid: true,
      errorMsg: '',
    });
  };
  useEffect(() => {
    if (isDisabled)
      link.ref.current?.inputElement?.setAttribute('disabled', '');
    return () => {
      link.ref.current?.inputElement?.removeAttribute('disabled');
    };
  }, [isDisabled]);

  return (
    <S.SelectComponent
      {...props}
      onChange={handleChange}
      error={!link.get().isValid}
      errorMessage={link.get().errorMsg}
      dettach={dettach}
      label={label}
      isDisabled={isDisabled}
    >
      {children}
    </S.SelectComponent>
  );
};

export const SelectItem = SelectDS.Item;

Select.defaultProps = {
  placeholder: '',
  Item: null,
  dettach: false,
  label: '',
};

SelectItem.defaultProps = {
  value: '',
  text: '',
  selected: false,
};

export default withValidation<SelectProps, string>(Select);
