/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
/* eslint-disable @typescript-eslint/no-explicit-any  */
import React from 'react';
import * as S from 'main/components/Table/customStyles';
import { maskCpfCnpj } from 'contratosPrestamista/utils/maskCpfCnpj';
import ListColumnActionReversao from './ListColumnActionReversao';
import { IResponseListaSegurosCancelados } from '../types/IResponseListaSegurosCancelados';
import { Text } from './styles';

export const gerarColunasReversao = (
  callToAction: (proposta: IResponseListaSegurosCancelados) => void,
) => [
  {
    name: 'Proposta',
    selector: 'numeroProposta',
    center: true,
    width: '180px',
    cell: (proposta: any) => (
      <S.Linked
        title="Veja aqui os detalhes da proposta"
        to={{
          pathname: `/prestamista/cancelamentos/${proposta.numContrato}/detalhes`,
          state: {
            proposta,
          },
        }}
      >
        {proposta.numeroPropostaEstipulante}
      </S.Linked>
    ),
  },
  {
    name: 'Nome',
    sortable: true,
    selector: 'nomeSegurado',
    center: true,
    width: '250px',
    cell: ({ nomeSegurado }: any) => (
      <Text title={nomeSegurado}>{nomeSegurado}</Text>
    ),
  },
  {
    name: 'CPF/CNPJ',
    selector: 'cpfCnpj',
    center: true,

    cell: ({ cpfCnpj }: any) => <span>{maskCpfCnpj(cpfCnpj)}</span>,
  },
  {
    name: 'Valor do Prêmio',
    selector: 'valorPremio',
    sortable: true,
    center: true,
  },
  {
    name: 'Agência',
    selector: 'codigoAgenciaVenda',
    center: true,
    width: '70px',
  },
  {
    name: 'Data limite para retenção',
    selector: 'dtaFimReversao',
    sortable: true,
    center: true,
  },
  {
    name: 'Reter prestamista',
    selector: 'numeroProposta',
    sortable: false,
    center: true,
    cell: (proposta: any) => (
      <ListColumnActionReversao
        dados={proposta}
        callToAction={() => callToAction(proposta)}
      />
    ),
  },
];
