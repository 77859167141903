import { IMenuItem } from 'main/components/Menu/Sidebar/types';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';

export const menuExternoVida: IMenuItem = {
  label: 'Vida',
  alt: 'vida',
  path: '/vida',
  icon: 'vida',
  roles: [
    ...PRINCIPAL_USERS,
    USER_PROFILES.ANALISTA_MANUTENCAO,
    USER_PROFILES.ANALISTA_SAIDA,
    USER_PROFILES.ANALISTA_ENTRADA,
    USER_PROFILES.ANALISTA_CONSULTA,
  ],
  subItems: [
    {
      label: 'Formulários (FOPs)',
      path: '/extranet/fop-vida',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_MANUTENCAO,
        USER_PROFILES.ANALISTA_SAIDA,
        USER_PROFILES.ANALISTA_ENTRADA,
        USER_PROFILES.ANALISTA_CONSULTA,
      ],
    },
    {
      label: 'Relatório Vida PU Vencido',
      path: '/hierarquia-vidapu',
      roles: [
        USER_PROFILES.ANALISTA_TI,
        USER_PROFILES.ECONOMIARIO,
        USER_PROFILES.ANALISTA_POS_VENDA,
        USER_PROFILES.ANALISTA_MANUTENCAO,
      ],
    },
    {
      label: 'Propostas',
      path: '/propostas-vida',
      roles: [
        USER_PROFILES.ANALISTA_TI,
        USER_PROFILES.ECONOMIARIO,
        USER_PROFILES.ANALISTA_CONSULTA,
        USER_PROFILES.ANALISTA_ENTRADA,
      ],
      subItems: [
        {
          label: 'DPS (link)',
          path: '/propostas-vida/envio',
          roles: [
            USER_PROFILES.ANALISTA_TI,
            USER_PROFILES.ECONOMIARIO,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_ENTRADA,
          ],
          subItems: [
            {
              label: 'Envio DPS (link)',
              path: '/propostas-vida/envio',
              roles: [
                USER_PROFILES.ANALISTA_TI,
                USER_PROFILES.ECONOMIARIO,
                USER_PROFILES.ANALISTA_CONSULTA,
                USER_PROFILES.ANALISTA_ENTRADA,
              ],
            },
          ],
        },
      ],
    },
  ],
};
