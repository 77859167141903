import React from 'react';
import Table from 'main/components/Table';
import { Button, Display } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { formatarData } from 'main/utils';
import { PrevRedirectByStatus } from 'previdencia/features/HistoricoSolicitacoes/types/enum';
import { useHistory } from 'react-router';
import { StatusResgate } from 'previdencia/types/StatusResgate';
import RenderConditional from 'main/components/RenderConditional';
import { COLUNAS_SOLICITACAO_RESGATE } from 'previdencia/features/CancelamentoSolicitacaoResgate/constants/constants';
import { TabelaSolicitacoesResgateProps } from 'previdencia/features/CancelamentoPrevidencia/types/TabelaSolitacoes';

const TabelaSolicitacoesResgate: React.FC<TabelaSolicitacoesResgateProps> = ({
  data,
  onClick,
  loading,
}) => {
  const history = useHistory();

  const RenderBotaoReassinar: React.FC<{
    numeroResgate: string;
    statusResgate: string;
  }> = ({ numeroResgate, statusResgate }) => (
    <Button
      variant="primary"
      onClick={() => {
        history.push({
          pathname: PrevRedirectByStatus.RESGATE,
          state: {
            from: 'HISTORICO_CANCELAMENTO_RESGATE',
            data: { idRequisicao: numeroResgate, statusResgate },
          },
        });
      }}
    >
      Assinar
    </Button>
  );

  return (
    <Table
      noHeader
      responsive
      data={
        data?.map(
          ({
            descricaoStaResgate,
            valorResgate,
            dataSolicitacao,
            numeroResgate,
            indicacaoCancelavel,
            staRegaste,
            ...rest
          }) => ({
            dataSolicitacao: formatarData(dataSolicitacao),
            valor: formatarValorPadraoBrasileiro(valorResgate),
            status: descricaoStaResgate,
            acao: (
              <Display>
                <RenderConditional
                  condition={staRegaste === StatusResgate.AGUARDANDO_ASSINATURA}
                >
                  <RenderBotaoReassinar
                    numeroResgate={numeroResgate}
                    statusResgate={staRegaste}
                  />
                </RenderConditional>
                <RenderConditional condition={indicacaoCancelavel === 'S'}>
                  <Button
                    type="submit"
                    variant="secondary"
                    onClick={() => {
                      onClick(numeroResgate);
                    }}
                    loading={loading}
                  >
                    Cancelar
                  </Button>
                </RenderConditional>
              </Display>
            ),
            ...rest,
          }),
        ) ?? []
      }
      columns={COLUNAS_SOLICITACAO_RESGATE}
      noDataComponent="Não há dados para exibir."
      pagination
      paginationPerPage={10}
      paginationComponentOptions={{
        rowsPerPageText: 'Items por página',
        rangeSeparatorText: 'de',
      }}
    />
  );
};

export default TabelaSolicitacoesResgate;
