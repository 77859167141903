import { Fundo } from 'previdencia/types/Fundo.type';
import { RequestDefinirFundoContribuicaoRegular } from '../types/ContribuicaoRegular.types';
import {
  ReservasDestinoRequest,
  ResponseFundosContrib,
} from '../types/DefinirReservaDestino.types';
import { ResponseFundosPorCertificado } from '../types/TiposTransferenciaFundoInvestimento.type';
import {
  RequestTransferenciaEntreFundos,
  ResponseTransferenciaEntreFundos,
} from '../types/TransferenciaEntreFundos.type';

export const converterResponseFundosPorCertificadoParaFundo = (
  data: ResponseFundosPorCertificado | undefined,
): Fundo[] => {
  const fundos: Fundo[] = [];
  if (!data?.retornoCertificado?.certificadoOrigem) {
    return [];
  }
  data?.retornoCertificado.certificadoOrigem.fundos.forEach((e, index) => {
    fundos[index] = {
      codFundo: e.codFundo,
      descFundo: e.desFundo,
      rentabilidade: e.pctRentabUltimoAno,
      perfilRisco: e.desPerfilFundo,
      saldo: e.vlrSaldo,
      contribuicaoAtual: 0,
      selecionado: false,
      valor: 0,
      valorMinimo: parseInt(data.retornoCertificado.vlrMinTransferencia, 10),
    };
  });

  return fundos;
};

export const converterResponseFundosContribParaFundo = (
  data: ResponseFundosContrib[] | undefined,
): Fundo[] => {
  const fundos: Fundo[] = [];

  data?.forEach((e, index) => {
    fundos[index] = {
      codFundo: e.codFundo,
      descFundo: e.desFundo,
      rentabilidade: e.pctRentabUltimoAno,
      perfilRisco: e.desPerfilFundo,
      saldo: e.vlrSaldo,
      contribuicaoAtual: e.contribuicaoAtual,
      selecionado: false,
      valor: 0,
      valorMinimo: e.vlrMinimo,
    };
  });

  return fundos;
};

export const converterFundoParaRequestTransferenciaEntreFundos = (
  data: Fundo[] | undefined,
): RequestTransferenciaEntreFundos[] => {
  const requestServico: RequestTransferenciaEntreFundos[] = [];

  data?.forEach(e => {
    if (e.selecionado && e.valor !== 0) {
      if (e.isParcial) {
        requestServico.push({
          fundoId: e.codFundo,
          valorSolicitado: e.valor,
          staTipRetirada: 'P',
        });
      }
      if (!e.isParcial) {
        requestServico.push({
          fundoId: e.codFundo,
          valorSolicitado: e.valor,
          staTipRetirada: 'T',
        });
      }
    }
  });

  return requestServico;
};

export const converterFundoParaLstReservasDestinoRequest = (
  data: Fundo[] | undefined,
  fundosDestino: ResponseTransferenciaEntreFundos | undefined,
): ReservasDestinoRequest[] => {
  const requestServico: ReservasDestinoRequest[] = [];

  data?.forEach(e => {
    if (e.selecionado) {
      const result = fundosDestino?.reservasDestino.find(
        fundo => fundo.codFundo === e.codFundo,
      );
      requestServico.push({
        codFundo: e.codFundo,
        codObjetivo: result?.codObjetivo || 0,
        codReserva: result?.codReserva || '',
        vlrSaldo: e.valor,
      });
    }
  });

  return requestServico;
};

export const converterResponseTransferenciaEntreFundosParaFundos = (
  data: ResponseTransferenciaEntreFundos | undefined,
): Fundo[] => {
  const fundos: Fundo[] = [];

  data?.reservasExistentes?.forEach(e => {
    fundos.push({
      valor: 0,
      valorMinimo: data.vlrMinimo,
      codFundo: e.codFundo,
      perfilRisco: e.desPerfilFundo,
      rentabilidade: e.pctRentabUltimoAno,
      saldo: parseInt(e.vlrSaldo, 10),
      contribuicaoAtual: parseInt(e.vlrContribuicao, 10),
      selecionado: false,
      descFundo: e.desFundo,
    });
  });

  data?.reservasDestino?.forEach(e => {
    fundos.push({
      valor: 0,
      valorMinimo: e.vlrMinDiversificacao,
      codFundo: e.codFundo,
      perfilRisco: e.desPerfilFundo,
      rentabilidade: e.pctRentabUltimoAno,
      saldo: 0,
      contribuicaoAtual: 0,
      selecionado: false,
      descFundo: e.desFundo,
    });
  });
  return fundos;
};

export const converterFundoParaRequestDefinirFundoContribuicaoRegular = (
  data: Fundo[],
): RequestDefinirFundoContribuicaoRegular => {
  const request: RequestDefinirFundoContribuicaoRegular = {
    codFundo: '',
    vlrRequisitado: 0,
  };
  data.forEach(e => {
    if (e.selecionado) {
      request.codFundo = e.codFundo;
      request.vlrRequisitado = e.valor;
    }
  });

  return request;
};
