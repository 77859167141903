import { useState, useContext, useEffect } from 'react';
import { obterDadosParticipante } from 'main/services/dadosParticipante.api';
import { AppContext } from 'main/contexts/AppContext';
import { obterTelefonePrincipal } from 'main/utils/filter';

export const useObterEmailDefault = () => {
  const { cliente } = useContext(AppContext);
  const [emailDefault, setEmailDefault] = useState<string>('');
  const [numerosTelefone, setNumerosTelefone] = useState<string>('');
  const [recuperacaoEmailExecutada, setRecuperacaoEmailExecutada] =
    useState<boolean>(false);

  const obterEmailDefault = async (): Promise<string> => {
    try {
      setEmailDefault('');
      setRecuperacaoEmailExecutada(false);
      const dadosParticipante = await obterDadosParticipante(
        cliente.cpfCnpj,
        cliente.numCertificado,
      );

      const email = dadosParticipante?.email ?? '';
      const numeroTelefone = obterTelefonePrincipal(
        dadosParticipante?.telefones,
      );
      setEmailDefault(email);
      setNumerosTelefone(numeroTelefone);
      return email;
    } catch {
      setEmailDefault('');
    } finally {
      setRecuperacaoEmailExecutada(true);
    }
    return '';
  };

  useEffect(() => {
    if (cliente.cpfCnpj && cliente.numCertificado) {
      obterEmailDefault();
    }
  }, [cliente.cpfCnpj, cliente.numCertificado]);

  return {
    emailDefault,
    numerosTelefone,
    recuperacaoEmailExecutada,
  };
};
