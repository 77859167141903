/* eslint-disable react/prop-types */
import { Formik } from 'formik';
import { ISecaoItem } from 'main/features/prospeccao/types/IFormProspeccao';
import { removeMask } from '../../utils';
import { DynamicForm } from './DynamicForm';

type DynamicFormProps = {
  initialValues: ISecaoItem;
  onUpdate: (values: ISecaoItem, sectionIndex: number) => void;
  sectionIndex: number;
  goBackForm: () => void;
};

export const FormikFormContext: React.FC<DynamicFormProps> = ({
  initialValues,
  sectionIndex,
  onUpdate,
  goBackForm,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        campos: initialValues.campos,
      }}
      onSubmit={values => {
        const valuesUnMasked = values.campos
          .filter(campo => campo.armazenar)
          .map(campo => ({
            ...campo,
            resposta: removeMask(campo.resposta, campo),
          }));
        onUpdate({ ...initialValues, campos: valuesUnMasked }, sectionIndex);
      }}
    >
      <DynamicForm data={initialValues} goBackForm={goBackForm} />
    </Formik>
  );
};

FormikFormContext.defaultProps = {
  initialValues: {} as ISecaoItem,
  onUpdate: () => undefined,
  sectionIndex: 0,
};
