import masks from 'main/utils/masks';

const multiplicadoresPadrao = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9];

const calcular = (numeroContrato: number[], multiplicador: number[]) => {
  const total = numeroContrato
    .map((item, index) => item * multiplicador[index])
    .reduce((acc, current) => acc + current);
  const resultadoCalculo = (total * 10) % 11;
  return resultadoCalculo === 10 ? 0 : resultadoCalculo;
};

const formatarNumeroDoContrato = (numeroContrato: string) =>
  numeroContrato.substring(0, 16).split('').map(Number).reverse();

const validarPrimeiroDigito = (numeroContrato: string) =>
  calcular(formatarNumeroDoContrato(numeroContrato), multiplicadoresPadrao);

const validarSegundoDigito = (
  primeiroDigito: number,
  numeroContrato: string,
) => {
  const multiplicadores = [...multiplicadoresPadrao, 2];
  const numerosDoContrato = [
    primeiroDigito,
    ...formatarNumeroDoContrato(numeroContrato),
  ];

  return calcular(numerosDoContrato, multiplicadores);
};

export const validarNumeroContrato = (numeroContrato?: string) => {
  const numeroContratoSemMascara = masks.numberOnly.mask(numeroContrato);
  if (!numeroContratoSemMascara) return false;
  const resultadoPrimeiraValidacao = validarPrimeiroDigito(
    numeroContratoSemMascara,
  );
  const resultadoSegundaValidacao = validarSegundoDigito(
    resultadoPrimeiraValidacao,
    numeroContratoSemMascara,
  );
  const digitosContratoInformado = numeroContratoSemMascara.substring(16, 18);
  const digitosValidacao = `${resultadoPrimeiraValidacao}${resultadoSegundaValidacao}`;

  return digitosContratoInformado === digitosValidacao;
};

export const validarNumeroContratoSIFEC = (numeroContrato?: string) => {
  if (!numeroContrato) return false;
  const rangeValores = [1350000, 2999999];
  const numeroContratoSemFormatacao = Number(
    masks.numberOnly.mask(numeroContrato),
  );

  return (
    numeroContratoSemFormatacao >= rangeValores[0] &&
    numeroContratoSemFormatacao <= rangeValores[1]
  );
};
