import React, { useEffect } from 'react';
import useFieldLink from 'main/hooks/useFieldLink';
import masks from 'main/utils/masks';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import * as PROPS_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblComponentProps';
import * as TRANSF_VGBL_STYLES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/styles';

const InputValorReserva: React.FC<PROPS_TYPES.InputValorReservaProps> = ({
  inputItem,
}) => {
  const [valorLink] = useFieldLink<string>('');

  const limparInputSeFundoNaoSelecionado = (): void => {
    if (!inputItem.selecionado) {
      valorLink.set({
        value: '',
        isValid: true,
      });
    }
  };

  const atribuirValorSaldoReservaAoInput = (): void => {
    valorLink.set({
      value: tryGetValueOrDefault([inputItem.saldo], '') as string,
      isValid: true,
    });
  };

  useEffect(() => {
    limparInputSeFundoNaoSelecionado();
    atribuirValorSaldoReservaAoInput();
  }, [
    inputItem.selecionado,
    inputItem.percentualDistribuicao,
    inputItem.saldo,
  ]);

  return (
    <TRANSF_VGBL_STYLES.InputTableTransferenciaInterna
      link={valorLink}
      inputMask={masks.currencyInput}
      disabled
      placeholder="R$ 0,00"
    />
  );
};

export default React.memo(InputValorReserva);
