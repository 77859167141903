import { Modal as ModalDS } from '@cvp/design-system/react';
import styled from 'styled-components';

export const Modal = styled(ModalDS)`
  max-width: 850px;
  @media print {
    button[class*='Modalstyle__CloseButton'] {
      display: none;
    }
  }
`;
