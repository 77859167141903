import {
  IAuthContextData,
  IAuthState,
  ICredentials,
  ILoginResponse,
  IUser,
} from 'main/features/Auth/interfaces';
import * as AuthApi from 'main/features/Auth/services/authApi';
import { useToast } from 'main/hooks/useToast';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { USER_PROFILES } from '../config/userProfiles';
import getAuthData, {
  destroySession,
  getPermissionsInSession,
  isMarcadorControleValid,
  saveUserSessionInfo,
  setPermissionsInSession,
  setUserName,
} from '../utils/auth';
import { formatarMatricula } from '../utils/cefMatriculaFormatter';

export const AuthContext = createContext<IAuthContextData>(
  {} as IAuthContextData,
);

export const AuthProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const queryClient = useQueryClient();
  const { toastError } = useToast();
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [userPermissions, setUserPermissions] = useState<string[]>(() => {
    const result = getPermissionsInSession();
    if (result && result.length > 0) return result;
    return [];
  });
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleUserRoles = (user: IUser) => {
    if (user.perfil)
      return user.perfil.split(',').map(item => item.toUpperCase());
    return [USER_PROFILES.ECONOMIARIO, USER_PROFILES.ANALISTA_POS_VENDA];
  };

  const [data, setData] = useState<IAuthState>(() => {
    const { user, tokenInfo, sessionId, digitalAgency } = getAuthData();

    if (user && user.marcadorControle && tokenInfo) {
      setUserRoles(handleUserRoles(user));
      return { user, tokenInfo, sessionId, digitalAgency };
    }
    return {} as IAuthState;
  });
  const setCustomPermissions = (permissions: string[]) => {
    const newPermissions = [...userPermissions, ...permissions];
    setUserPermissions(newPermissions);
    setPermissionsInSession(newPermissions);
  };

  const removeCustomPermission = (permission: string) => {
    const newPermissions = userPermissions.filter(x => x !== permission);
    setUserPermissions(newPermissions);
    setPermissionsInSession(newPermissions);
  };

  const saveUserData = (user: IUser) => {
    setUserRoles(handleUserRoles(user));
    saveUserSessionInfo(user);
    setData(getAuthData());
  };

  const finishLoginSso = (user: IUser) => {
    saveUserData(user);
    setUserName(user.nomeAcesso);
  };

  const login = useCallback(
    async ({ username, password }: ICredentials): Promise<ILoginResponse> => {
      try {
        const matricula = formatarMatricula(username);
        setLoading(true);
        setUserName(matricula);

        const { dados } = await AuthApi.login({
          username: matricula,
          password,
        });

        if (dados.sucesso) {
          const user = dados.entidade;
          if (user) {
            user.nomeAcesso = matricula.trim().toUpperCase();
            saveUserData(user);
            return { success: true, roles: handleUserRoles(user) };
          }
        }

        const [serviceResponseMessage] = dados.mensagens ?? [];
        if (serviceResponseMessage !== undefined) {
          toastError(serviceResponseMessage.descricao);
        }

        if (serviceResponseMessage === undefined) {
          toastError(Object.values(dados).slice(0, 1).toString());
        }

        return { success: false, roles: [] };
      } catch (error: any) {
        toastError(error);
        return Promise.reject(error);
      } finally {
        setLoading(false);
      }
    },
    [toastError],
  );

  const atualizarAgencia = (user: IUser) => {
    saveUserSessionInfo(user);
    setData(getAuthData());
  };

  const logout = useCallback(() => {
    queryClient.clear();
    destroySession();
    setData({} as IAuthState);
  }, [queryClient]);

  const values = useMemo(
    () => ({
      user: data.user,
      isLoading,
      userRoles,
      userPermissions,
      sessionId: data.sessionId,
      setUserPermissions,
      setCustomPermissions,
      removeCustomPermission,
      atualizarAgencia,
      isMarcadorControleValid,
      login,
      logout,
      finishLoginSso,
    }),
    [data.user, isLoading, userRoles, userPermissions, data.sessionId],
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
