export const COLUNAS_SOLICITACAO_RESGATE = [
  {
    name: 'Data Solicitação',
    selector: 'dataSolicitacao',
    wrap: true,
    width: '140px',
    sortable: true,
  },
  {
    name: 'Tipo de Resgate',
    selector: 'tipoResgate',
    width: '120px',
    sortable: true,
  },
  {
    name: 'Valor do Resgate',
    selector: 'valor',
    width: '150px',
    sortable: true,
  },
  {
    name: 'Situação',
    selector: 'status',
    minWidth: '100px',
    sortable: true,
  },
  {
    name: 'Ação',
    selector: 'acao',
    minWidth: '140px',
  },
];

export const TEXTOS_CANCELAMENTO = {
  TITULO: 'Histórico e Cancelamento de Resgate',
  DESCRICAO:
    'Veja o histórico de solicitações feitas para o certificado ou cancele uma solicitação de resgate.',
};
