import { Button, Display, Modal, Radio, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { aplicarMascaraTelefone } from 'main/utils/phone';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ACAO_RETENCAO,
  CHAVE_ID_ACAO_RETENCAO_EXECUTADA,
  TIPO_ASSINATURA,
} from '../../constants';
import { DetalhesPortabilidade } from '../../types/DetalhesPortabilidade';
import { TipoAssinatura } from '../../types/TipoAssinaturaDocuSign';
import {
  ButtonLink,
  MensagemObrigatoriaDestaque,
  MensagemObrigatoriaDestaqueConcluir,
} from '../styles';

type ModalConfirmaTipoEnvioProps = {
  dadosPortabilidade?: DetalhesPortabilidade;
  open: boolean;
  definirAcao(acao: string, mensagem: string): void;
  executarAcaoRetencao(confirm: boolean): void;
  handleClose(close: boolean): void;
  email: string;
  mobileNumber: string;
  tipoRetencao: string;
  mensagemConfirmacaoRetencao: string;
  tituloModal: string;
};

function ValidaTipoEnvioCancelamentoPortabilidade(
  tipoRetencao: string,
  selectedMethod: TipoAssinatura | undefined,
  handleDefinirAcao: (acao: string, mensagem: string) => void,
) {
  if (tipoRetencao === ACAO_RETENCAO.SOLICITAR_CANCELAMENTO.ID_ACAO) {
    const acao =
      selectedMethod?.tipo === 'EMAIL'
        ? ACAO_RETENCAO.SOLICITAR_CANCELAMENTO_VIA_EMAIL
        : ACAO_RETENCAO.SOLICITAR_CANCELAMENTO_VIA_SMS;
    handleDefinirAcao(acao.ID_ACAO, acao.MENSAGEM_CONFIRMACAO);
  }
}

function ValidaTipoEnvioProrrogacaoPortabilidade(
  tipoRetencao: string,
  selectedMethod: TipoAssinatura | undefined,
  handleDefinirAcao: (acao: string, mensagem: string) => void,
) {
  if (tipoRetencao === ACAO_RETENCAO.SOLICITAR_PRORROGACAO.ID_ACAO) {
    const acao =
      selectedMethod?.tipo === 'EMAIL'
        ? ACAO_RETENCAO.SOLICITAR_PRORROGACAO_VIA_EMAIL
        : ACAO_RETENCAO.SOLICITAR_PRORROGACAO_VIA_SMS;
    handleDefinirAcao(acao.ID_ACAO, acao.MENSAGEM_CONFIRMACAO);
  }
}

export const ModalConfirmaTipoEnvio: React.FunctionComponent<
  ModalConfirmaTipoEnvioProps
> = ({
  executarAcaoRetencao,
  handleClose,
  definirAcao,
  open,
  email,
  mobileNumber,
  tipoRetencao,
  mensagemConfirmacaoRetencao,
  tituloModal,
  dadosPortabilidade,
}) => {
  const [selectedMethod, setSelectedMethod] = useState<TipoAssinatura>();
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const history = useHistory();

  const idsProrrogacao = [
    ACAO_RETENCAO.SOLICITAR_PRORROGACAO.ID_ACAO,
    ACAO_RETENCAO.SOLICITAR_PRORROGACAO_VIA_EMAIL.ID_ACAO,
    ACAO_RETENCAO.SOLICITAR_PRORROGACAO_VIA_SMS.ID_ACAO,
  ];

  const closeModal = () => {
    setSelectedMethod(undefined);
    handleClose(false);
  };

  const handleDefinirAcao = (acao: string, mensagem: string) => {
    definirAcao(acao, mensagem);
    sessionStorage.setItem(CHAVE_ID_ACAO_RETENCAO_EXECUTADA, acao);
  };

  const checkDisableButton = () => {
    if (selectedMethod?.tipo === 'EMAIL' && email) return true;
    if (selectedMethod?.tipo === 'SMS' && mobileNumber) return true;
    return false;
  };

  const setMetodoRetencao = () => {
    ValidaTipoEnvioProrrogacaoPortabilidade(
      tipoRetencao,
      selectedMethod,
      handleDefinirAcao,
    );

    ValidaTipoEnvioCancelamentoPortabilidade(
      tipoRetencao,
      selectedMethod,
      handleDefinirAcao,
    );
  };

  const renderColorTextByCondition = !mobileNumber || !email ? 'error' : 'text';

  useEffect(() => {
    setMetodoRetencao();
    setDisableButton(checkDisableButton());
  }, [selectedMethod]);

  const redirectToContactsPage = () => {
    closeModal();
    setTimeout(() => {
      history.push({
        pathname: `/portabilidades/${dadosPortabilidade?.portabilidade.id}/contatos`,
        state: { dadosPortabilidade },
      });
    }, 0);
  };

  return (
    <Modal show={open} onClose={closeModal}>
      <RenderConditional
        condition={!selectedMethod?.tipo}
        component={<Text>{tituloModal}</Text>}
      />
      <RenderConditional
        condition={!!selectedMethod?.tipo}
        component={<Text>{mensagemConfirmacaoRetencao}</Text>}
      />

      <Display justify="center">
        <div>
          <Display type="inline-block">
            <Radio
              data-testid="btnEmail"
              onChange={() => setSelectedMethod({ tipo: 'EMAIL' })}
              value="EMAIL"
              name="selectedMethod"
              checked={selectedMethod?.tipo === TIPO_ASSINATURA.EMAIL}
            />
            E-mail
          </Display>
        </div>
        <div>
          <Display type="inline-block">
            <Radio
              data-testid="btnSms"
              onChange={() => setSelectedMethod({ tipo: 'SMS' })}
              value="SMS"
              name="selectedMethod"
              checked={selectedMethod?.tipo === TIPO_ASSINATURA.SMS}
            />
            SMS
          </Display>
        </div>
      </Display>
      <RenderConditional condition={selectedMethod?.tipo === 'EMAIL'}>
        <Display justify="center">
          <div>
            <Text margin variant="body02-md">
              <RenderConditional
                condition={!email}
                component={<span>E-mail:</span>}
              />
              <strong> {email}</strong>
            </Text>
            <Text
              color={!email ? 'error' : 'text'}
              margin
              variant="body-medium1"
            >
              *Esse e-mail está correto? Caso seja necessário, faça a
              atualização clicando{' '}
              <ButtonLink variant="text" onClick={redirectToContactsPage}>
                aqui.
              </ButtonLink>
            </Text>
          </div>
        </Display>
      </RenderConditional>
      <RenderConditional condition={selectedMethod?.tipo === 'SMS'}>
        <Display justify="center">
          <div>
            <Text variant="body02-md">
              <RenderConditional
                condition={!mobileNumber}
                component={<span>Número:</span>}
              />
              <strong>{aplicarMascaraTelefone(mobileNumber)}</strong>
            </Text>
          </div>
          <Text variant="body-medium1" color={renderColorTextByCondition}>
            *Esse número de celular está correto? Caso seja necessário, faça a
            atualização clicando{' '}
            <ButtonLink variant="text" onClick={redirectToContactsPage}>
              aqui.
            </ButtonLink>
          </Text>
        </Display>
      </RenderConditional>
      <RenderConditional condition={!!selectedMethod?.tipo}>
        <Display justify="center">
          <Button
            variant="primary"
            onClick={executarAcaoRetencao}
            data-testid="submit"
            disabled={!disableButton}
          >
            {idsProrrogacao.includes(tipoRetencao) ? 'Prorrogar' : 'Reter'}
          </Button>
          <Button
            variant="secondary"
            onClick={closeModal}
            data-testid="cancelar"
          >
            Cancelar
          </Button>
        </Display>
        <Display justify="center">
          <Text variant="body02-sm" color="primary">
            <MensagemObrigatoriaDestaque>
              Importante:
            </MensagemObrigatoriaDestaque>{' '}
            É necessário que clique no botão{' '}
            <MensagemObrigatoriaDestaqueConcluir>
              “Concluir”
            </MensagemObrigatoriaDestaqueConcluir>{' '}
            ao final das etapas da Assinatura Eletrônica para a efetivação da
            transação.
          </Text>
        </Display>
      </RenderConditional>
    </Modal>
  );
};

export default ModalConfirmaTipoEnvio;
