export const DISPOSTO_LEI_COBERTURA =
  'Cumprindo o disposto na lei 12.007/09, disponibilizamos a Declaração de Quitação Anual de Débitos, referente à cobertura de proteção de seu plano de previdência.';

export const DOCUMENTO_QUITACAO_ANUAL =
  'O documento de quitação anual visa atender o titular do plano e/ou responsável financeiro, quando houver a contratação do cuidado extra no plano.';

export const LEI_PESSOA_JURIDICA =
  'A Lei 12.007/09, sancionada pelo Presidente da Republica de 29 de julho de 2009, determina que as Pessoas Jurídicas prestadoras de serviços publicos ou privados, enviem aos seus clientes declaração de quitação anual, sobre o serviço prestado.';

export const SEM_DADOS_FILTRO_ANO = `Não há dados para o ano selecionado.`;
