import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { IResponseAcaoCoberturas } from '../types/AcaoCoberturas.types';
import { CoberturaContratada } from '../types/CoberturasContratadas.types';
import {
  EnumStatusAtivacaosuspensao,
  EnumTipoBeneficios,
} from '../types/EnumsStatusAtivacaoSuspensao.types';

export const parametrosEnvioEmailFactory = (
  dadosComprovante: IResponseAcaoCoberturas | undefined,
  dadosPlano: CoberturaContratada | undefined,
) => {
  return {
    valorContribuicaoEsperado:
      dadosComprovante?.dadosBeneficio?.valorBeneficioEsperado || '0.0',
    numeroCertificado: dadosPlano?.certificado,
    tipoContribuicao: dadosPlano?.tipoContribuicao,
    numeroBeneficio: dadosPlano?.numeroBeneficio,
    numeroPlano: dadosPlano?.numeroPlano,
    idManutencaoCerificado: dadosComprovante?.idManutencaoCerificado,
    categoria: dadosComprovante?.subCategoriaProduto,
    dataOperacao: dadosComprovante?.dataOperacao,
    cpfCnpj: dadosComprovante?.cpfPessoaCertificado,
    beneficioId: dadosComprovante?.dadosBeneficio.beneficioId,
  };
};

export const tipoEmailFactory = (
  dadosComprovante: IResponseAcaoCoberturas | undefined,
): string => {
  if (dadosComprovante?.tipoContribuicao === EnumTipoBeneficios.RISCO) {
    return tipoEmailConstants.CANCELAMENTO_CUIDADO_EXTRA;
  }

  if (
    dadosComprovante?.dadosBeneficio.status ===
    EnumStatusAtivacaosuspensao.Suspenso
  ) {
    return tipoEmailConstants.SUPENSAO_CONTRIBUICAO;
  }

  return tipoEmailConstants.ATIVACAO_CONTRIBUICAO;
};
