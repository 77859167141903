import React from 'react';
import { Accordion, Text } from '@cvp/design-system/react';
import * as CONSTS from 'seguros/constants/AlteracaoValorCapital';

const AlterarValorCapitalFaq = () => {
  return (
    <Accordion>
      <Accordion.Item
        title={CONSTS.TEXTOS_ALTERACAO_VALOR_CAPITAL.FAQ.QUESTAO_01.PERGUNTA}
      >
        <Text variant="body02-sm">
          {CONSTS.TEXTOS_ALTERACAO_VALOR_CAPITAL.FAQ.QUESTAO_01.PARAGRAFO_01}
        </Text>
        <Text variant="body02-sm">
          <strong>Obs.:</strong>{' '}
          {CONSTS.TEXTOS_ALTERACAO_VALOR_CAPITAL.FAQ.QUESTAO_01.PARAGRAFO_02}
        </Text>
      </Accordion.Item>
      <Accordion.Item
        title={CONSTS.TEXTOS_ALTERACAO_VALOR_CAPITAL.FAQ.QUESTAO_02.PERGUNTA}
      >
        <Text variant="body02-sm">
          {CONSTS.TEXTOS_ALTERACAO_VALOR_CAPITAL.FAQ.QUESTAO_02.PARAGRAFO_01}
        </Text>
      </Accordion.Item>
    </Accordion>
  );
};

export default AlterarValorCapitalFaq;
