/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useFormikContext, FieldArray, Field, FieldProps } from 'formik';
import {
  Button,
  Display,
  TextField,
  Grid,
  Card,
  Radio,
  Text,
  Modal,
} from '@cvp/design-system/react';
import {
  ISecaoItem,
  Opcoes,
} from 'main/features/prospeccao/types/IFormProspeccao';
import { useToast } from 'main/hooks/useToast';
import { ProspeccaoContext } from 'main/features/prospeccao/contexts/ProspeccaoContext';
import { TextArea } from 'main/components/form';
import { AuthContext } from 'main/features/Auth/contexts/AuthContext';
import RenderConditional from 'main/components/RenderConditional';
import { TooltipWrapper } from 'main/components/Wrappers/TooltipWrapper';
import masks from 'main/utils/masks';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import * as Validators from '../../utils/validators';
import DynamicFormSelect from './DynamicFormSelect';
import { DynamicFormUpload } from './DynamicFormUpload';
import {
  findAction,
  setAgenciaCurrentUser,
  setImportanciaSegurada,
  handleOnBlurActions,
  getButtonSumitLabel,
  shouldToRenderAlfanumericoInput,
  getGridLayout,
  validateField as validateFieldHelper,
  getFieldErrorMessage,
  getMask,
  removeMask,
} from '../../utils';

import { useFormularioProspeccao } from '../../hooks/useFormularioProspeccao';

type FormValues = {
  campos: Opcoes[];
};

type DynamicFormProps = {
  data: ISecaoItem;
  goBackForm: () => void;
};

export const DynamicForm: React.FC<DynamicFormProps> = ({
  goBackForm,
  data,
}) => {
  const { layout } = data;
  const { user } = useContext(AuthContext);
  const {
    formProspeccao,
    modal,
    bloqueioEnvio,
    setModal,
    setBloqueioEnvio,
    setField,
  } = useContext(ProspeccaoContext);
  const { obterValorImportanciaSegurada } = useFormularioProspeccao();
  const {
    handleSubmit,
    handleBlur,
    values,
    isValid,
    setFieldValue,
    setValues,
  } = useFormikContext<FormValues>();
  const { toastError } = useToast();

  const validateField = (value: string) => {
    return validateFieldHelper(values.campos, value);
  };

  const submit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (isValid && !bloqueioEnvio) {
      handleSubmit(evt);
      return;
    }
    toastError(
      bloqueioEnvio && modal?.mensagem
        ? modal.mensagem
        : 'Preencha os campos obrigatórios!',
    );
  };

  const onGoBackForm = (): void => {
    setModal({ open: false, mensagem: '' });
    setBloqueioEnvio(false);
    goBackForm();
  };

  const updateItemWithOptionSelected = (
    index: number,
    idEscolhida?: string,
  ) => {
    if (idEscolhida) {
      values.campos[index].resposta = idEscolhida;

      setValues(values);
    }
  };

  const updateSubFieldsWithOptionSelected = (
    index: number,
    indexOption: number,
    idEscolhida?: string,
  ) => {
    if (idEscolhida) {
      values.campos[index].opcoes[indexOption].resposta = idEscolhida;

      setValues(values);
    }
  };

  const handleOnBlur = (item: Opcoes) => {
    setField(item.id, removeMask(item.resposta, item));
    handleOnBlurActions({
      item,
      values: values.campos,
      context: formProspeccao,
      setFieldValue,
      setModal,
      setBloqueioEnvio,
    });
  };

  const handleCloseModal = () => {
    setModal({
      open: !modal.open,
      mensagem: modal.mensagem,
    });
  };

  const handleMaskValue = (item: Opcoes, value: string): string => {
    const tipoMascaraAtributosView = checkIfAllItemsAreTrue([
      !!item.atributosView,
      !!item.atributosView?.mask,
    ]);

    const tipoMascaraPadrao = checkIfAllItemsAreTrue([
      !!item.tipo,
      !!masks[tryGetValueOrDefault([item.tipo], '')],
    ]);

    if (tipoMascaraAtributosView) {
      return masks[tryGetValueOrDefault([item.atributosView?.mask], '')].mask(
        value,
      );
    }

    if (tipoMascaraPadrao) {
      return masks[tryGetValueOrDefault([item.tipo], '')].mask(value).trim();
    }

    return value;
  };

  const getImportanciaSegurada = async () => {
    const field = findAction('LOAD_IMPORTANCIA_SEGURADA', values.campos);
    if (field?.atributosView) {
      const [action] = field?.atributosView?.actions ?? [];
      const cpfCnpj = formProspeccao[action?.event.payload.formFields[0]];
      const valorTotalContratos = await obterValorImportanciaSegurada(
        cpfCnpj.toString(),
      );
      setImportanciaSegurada(values.campos, valorTotalContratos, setFieldValue);
    }
  };

  useEffect(() => {
    getImportanciaSegurada();
    setAgenciaCurrentUser(values.campos, user, setFieldValue);
  }, []);

  return (
    <form onSubmit={submit}>
      <Grid>
        <FieldArray
          name="campos"
          render={() =>
            values.campos.map((item, index) => {
              return (
                <>
                  {shouldToRenderAlfanumericoInput(item) ? (
                    <Grid.Item key={item.id} xs={getGridLayout(layout)}>
                      <TooltipWrapper tooltip={item?.atributosView?.tooltip}>
                        <Field
                          name={`campos[${index}].id`}
                          validate={validateField}
                        >
                          {({ field, meta }: FieldProps) => (
                            <TextField
                              {...field}
                              label={Validators.renderLabelWithRequiredCheck(
                                item.obrigatorio,
                                item.descricao,
                              )}
                              value={getMask(item)}
                              placeholder={item.atributosView?.placeHolder}
                              error={Validators.showErrorIfFieldInvalid(
                                meta.touched,
                                meta.error,
                              )}
                              disabled={item.atributosView?.readonly}
                              errorMessage={getFieldErrorMessage(
                                meta.touched,
                                meta.error,
                              )}
                              onChange={({
                                target: { value },
                              }: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue(
                                  `campos[${index}].resposta`,
                                  handleMaskValue(item, value),
                                );
                              }}
                              onBlur={(e: React.FocusEvent<unknown>) => {
                                handleOnBlur(item);
                                handleBlur(e);
                              }}
                            />
                          )}
                        </Field>
                      </TooltipWrapper>
                    </Grid.Item>
                  ) : null}
                  <RenderConditional condition={item?.tipo === 'label'}>
                    <Grid.Item key={item.id} xs={getGridLayout(layout)}>
                      <div>
                        <Text
                          variant="body-medium2"
                          style={{ marginBottom: 12 }}
                        >
                          {item.descricao}
                        </Text>
                        <Text
                          variant="body-medium2"
                          style={{ marginBottom: 12 }}
                        >
                          {getMask(item)}
                        </Text>
                      </div>
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional condition={item?.tipo === 'texto'}>
                    <Grid.Item key={item.id} xs={getGridLayout(layout)}>
                      <TooltipWrapper tooltip={item.atributosView?.tooltip}>
                        <Text
                          variant="body-medium2"
                          style={{ marginBottom: 12 }}
                        >
                          {item.descricao}
                        </Text>
                        <TextArea
                          spellCheck
                          value={item.resposta}
                          placeholder={item.atributosView?.placeHolder}
                          onChange={({
                            target: { value },
                          }: React.ChangeEvent<{ value: string }>) => {
                            setFieldValue(`campos[${index}].resposta`, value);
                          }}
                          onBlur={handleBlur}
                        />
                      </TooltipWrapper>
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional
                    condition={
                      item?.tipo === 'lista' && item?.opcoes?.length > 0
                    }
                  >
                    <Grid.Item key={item.id} xs={getGridLayout(layout)}>
                      <Field
                        name={`campos[${index}].id`}
                        validate={validateField}
                      >
                        {({ meta }: FieldProps) => {
                          return (
                            <DynamicFormSelect
                              onChange={(id: string) =>
                                updateItemWithOptionSelected(index, id)
                              }
                              data={item}
                              error={Validators.showErrorIfFieldInvalid(
                                meta.touched,
                                meta.error,
                              )}
                              errorMessage={getFieldErrorMessage(
                                meta.touched,
                                meta.error,
                              )}
                            />
                          );
                        }}
                      </Field>
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional condition={item?.tipo === 'radio'}>
                    <Grid.Item key={item.id} xs={getGridLayout(layout)}>
                      {item.descricao}
                      {item.opcoes.map(opt => (
                        <Display alignItems="center">
                          <Radio
                            key={opt.id}
                            name={`campos[${index}].id`}
                            value={opt.id}
                            checked={values.campos[index].resposta === opt.id}
                            onChange={() =>
                              updateItemWithOptionSelected(index, opt?.id)
                            }
                          />
                          {opt.descricao}
                        </Display>
                      ))}
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional condition={item?.tipo === 'subcampos'}>
                    <Grid.Item key={item.id} xs={getGridLayout(layout)}>
                      {item.descricao}
                      <div>
                        {item.opcoes.map(opt => (
                          <>
                            {opt.tipo === 'radio' && (
                              <div style={{ marginLeft: 20, marginTop: 10 }}>
                                {opt.descricao}
                                {opt?.opcoes?.map((optItem, optItemIndex) => (
                                  <Display alignItems="center">
                                    <Radio
                                      key={optItem.id}
                                      name={`campos[${index}].opcoes[${optItemIndex}].id`}
                                      value={optItem.id}
                                      checked={
                                        values.campos[index].resposta ===
                                        optItem.id
                                      }
                                      onChange={() =>
                                        updateSubFieldsWithOptionSelected(
                                          index,
                                          optItemIndex,
                                          optItem?.id,
                                        )
                                      }
                                    />
                                    {optItem.descricao}
                                  </Display>
                                ))}
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional condition={item.tipo === 'upload'}>
                    <Grid.Item key={item.id} xs={getGridLayout(layout)}>
                      <div>
                        <Text
                          variant="body-medium2"
                          style={{ marginBottom: 12 }}
                        >
                          <strong> {item.descricao}</strong>
                        </Text>
                        <DynamicFormUpload />
                      </div>
                    </Grid.Item>
                  </RenderConditional>
                  <RenderConditional condition={item.tipo === 'paragrafo'}>
                    <Grid.Item
                      key={item.id}
                      xs={
                        item.atributosView?.layout
                          ? getGridLayout(item.atributosView?.layout)
                          : getGridLayout(layout)
                      }
                    >
                      <div>
                        <Text
                          variant="body-medium2"
                          style={{ marginBottom: 12 }}
                        >
                          {item.descricao}
                        </Text>
                      </div>
                    </Grid.Item>
                  </RenderConditional>
                </>
              );
            })
          }
        />
      </Grid>

      <Card.Content>
        <Display>
          <Button type="button" variant="secondary" onClick={onGoBackForm}>
            Voltar
          </Button>
          <Button type="submit" variant="primary">
            {getButtonSumitLabel(formProspeccao?.isLastStep)}
          </Button>
        </Display>
      </Card.Content>
      <Modal show={modal.open} onClose={() => handleCloseModal()}>
        <Text variant="body-medium3">{modal?.mensagem}</Text>
        <Display justify="center" style={{ marginTop: 20 }}>
          <Button
            variant="secondary"
            onClick={() => handleCloseModal()}
            data-testid="confirmar"
          >
            OK
          </Button>
        </Display>
      </Modal>
    </form>
  );
};
