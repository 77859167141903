/**
 * Calcula as datas de início e término com base em um período pré-definido.
 *
 * @param {string} periodo - O período em dias para subtrair da data atual para calcular a data inicial.
 * @returns {{ dataHoje: Date, dataInicial: Date }} Um objeto contendo a data atual (`dataHoje`) e a data inicial (`dataInicial`).
 */
export const obterRangeDataBaseadoNoPeriodoPreDefinido = (periodo: string) => {
  const dataHoje = new Date();
  const dataInicial = new Date();
  dataInicial.setDate(dataInicial.getDate() - Number(periodo));

  return { dataHoje, dataInicial };
};

/**
 * Obtém as datas de início e fim do ano anterior.
 *
 * @returns {{ diaMesInicioAnoAnterior: Date, diaMesFimAnoAnterior: Date }} Um objeto contendo as datas de 01/01 do ano anterior (`diaMesInicioAnoAnterior`) e 31/12 do ano anterior (`diaMesFimAnoAnterior`).
 */
export const obterRangeDataAnoAnterior = () => {
  const anoAtual = new Date().getFullYear();
  const diaMesInicioAnoAnterior = new Date(anoAtual - 1, 0, 1);
  const diaMesFimAnoAnterior = new Date(anoAtual - 1, 11, 31);

  return { diaMesInicioAnoAnterior, diaMesFimAnoAnterior };
};

/**
 * Obtém as datas de início do ano atual e a data atual.
 *
 * @returns {{ diaMesInicioAnoAtual: Date, diaMesFimAnoAtual: Date }} Um objeto contendo as datas de 01/01 do ano atual (`diaMesInicioAnoAtual`) e a data atual (`diaMesFimAnoAtual`).
 */
export const obterRangeDataAnoAtual = () => {
  const anoAtual = new Date().getFullYear();
  const diaMesInicioAnoAtual = new Date(anoAtual, 0, 1);
  const diaMesFimAnoAtual = new Date();

  return { diaMesInicioAnoAtual, diaMesFimAnoAtual };
};
