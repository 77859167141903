import { useContext, useState } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { useToast } from 'main/hooks/useToast';
import RangeDate from 'previdencia/types/RangeDate';
import * as Api from 'previdencia/features/RentabilidadeCotas/services/imprimirDadosExtratoCotas.api';

const useImprimirExtratoRentabilidadeCotas = () => {
  const { toastError } = useToast();

  const {
    cliente: { numCertificado, cpfCnpj },
  } = useContext(AppContext);

  const [loading, setLoading] = useState<boolean>(false);

  const [response, setResponse] = useState<string>();

  const gerarArquivoImprimir = async (date?: RangeDate) => {
    try {
      setLoading(true);
      const result = await Api.imprimirDadosExtratoCotas(
        numCertificado,
        cpfCnpj,
        date,
      );

      setResponse(result);
    } catch (requestError: any) {
      toastError(requestError.message);
    } finally {
      setLoading(false);
    }
  };

  return { gerarArquivoImprimir, loading, response };
};

export default useImprimirExtratoRentabilidadeCotas;
