import { usePeco } from 'main/hooks/usePeco';
import { getTimer60Minutos } from 'main/utils';
import { PECOS_VIDA } from 'seguros/config/endpoints';
import { IDadosCertificadoSeguroResponse } from 'seguros/types/IResponseCertificadosResumo';

type RequestObterCerficadosSeguros = {
  numeroContrato: string;
  codigoProduto: string;
};

export const useCertificadoSeguroResumo = (
  payload: RequestObterCerficadosSeguros,
) => {
  const certificadoSeguros = usePeco<
    RequestObterCerficadosSeguros,
    IDadosCertificadoSeguroResponse
  >({
    api: { operationPath: PECOS_VIDA.ConsultarDadosCertificadoSeguro },
    handleResponse: { throwToastErrorBFF: false },
    autoFetch: false,
    cache: true,
    cacheTime: getTimer60Minutos(),
    cacheKey: payload.numeroContrato,
    payload: { ...payload },
  });

  return {
    certificadoSeguros,
  };
};
