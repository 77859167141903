export enum TipoProduto {
  VIDA = 'Vida',
  PRESTAMISTA = 'Prestamista',
  PREVIDENCIA = 'Previdência',
}

export enum StatusSinistroResponse {
  CODIGO_EMPRESA = '500',
  COMPLEMENTO = 'S',
  DATA_INICIAL = '1960-01-01',
  DATA_FINAL = '9999-12-31',
  CODIGO_STATUS = '6',
}
