import LinkedValue from 'main/features/Validation/types/LinkedValue';
import { subYears, format } from 'date-fns';

export function quitacaoAnualDate(): string[] {
  const years = [];
  const nowYear = new Date();
  const pastYear = nowYear.setFullYear(nowYear.getFullYear() - 1);

  for (let i = 0; i < 5; i += 1) {
    const yearsSub = format(subYears(pastYear, i), 'yyyy');
    years.push(yearsSub);
  }

  return years;
}

export const isSelectAno = (
  value: LinkedValue<string>,
  ano: string,
): boolean => {
  return ano === value.get().value;
};
