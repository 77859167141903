import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import {
  Display as DisplayBase,
  Card as CardBase,
  TextField as TextFieldBase,
  Button as ButtonBase,
} from '@cvp/design-system/react';

export const Display = styled(DisplayBase)(() => ({
  minHeight: 'inherit',
}));

export const Card = styled(CardBase)(({ theme: { breakpoint } }) => ({
  [breakpoint.lg()]: {
    width: '45%',
  },
}));

export const CardItem = styled(DisplayBase)(() => ({
  margin: '4px 0',
}));

export const TextField = styled(TextFieldBase)(() => ({
  margin: '24px 8px',
}));

export const TextFieldLogin = styled(TextFieldBase)(({ theme: { color } }) => ({
  margin: '24px 8px',

  '.eRuRbi:focus': {
    border: `2px solid ${color.brandPrimary.medium}`,
  },
}));

export const Button = styled(ButtonBase)(({ theme: { color } }) => ({
  '&:focus': {
    border: `2px solid ${color.brandPrimary.medium}`,
  },

  '&:active': {
    border: 'none',
  },
}));

export const Linked = styled(Link)(
  ({ theme: { breakpoint, color, font } }) => ({
    '&&': {
      cursor: 'pointer',
      textDecoration: `underline ${color.brandPrimary.medium}`,
      color: color.brandPrimary.medium,
      fontSize: font.size.md,
      [breakpoint.lg()]: {
        maxWidth: '150px',
      },
    },
  }),
);

export const LinkedLogin = styled(Link)(
  ({ theme: { breakpoint, color, font } }) => ({
    '&&': {
      cursor: 'pointer',
      textDecoration: `underline ${color.brandPrimary.medium}`,
      color: color.brandPrimary.medium,
      fontSize: font.size.md,
      [breakpoint.lg()]: {
        maxWidth: '150px',
      },
    },

    '&:focus': {
      outline: `2px solid ${color.brandPrimary.medium}`,
      borderRadius: '1px',
    },
  }),
);

export const ExternalLink = styled.a(
  ({ theme: { breakpoint, color, font } }) => ({
    cursor: 'pointer',
    textDecoration: `underline ${color.brandPrimary.medium}`,
    color: color.brandPrimary.medium,
    fontSize: font.size.md,
    [breakpoint.lg()]: {
      maxWidth: '150px',
    },
  }),
);

export const ExternalLinkLogin = styled.a(
  ({ theme: { breakpoint, color, font } }) => ({
    cursor: 'pointer',
    textDecoration: `underline ${color.brandPrimary.medium}`,
    color: color.brandPrimary.medium,
    fontSize: font.size.md,
    [breakpoint.lg()]: {
      maxWidth: '150px',
    },

    '&:focus': {
      outline: `2px solid ${color.brandPrimary.medium}`,
      borderRadius: '1px',
    },
  }),
);

export const LinksContainer = styled.div({
  textAlign: 'left',
  paddingBottom: '1rem',
});

export const LogoAcessoNegado = styled.img(({ theme: { breakpoint } }) => ({
  width: '100%',
  maxWidth: '200px',
  height: 'auto',
  marginBottom: '45px',

  [breakpoint.lg()]: {
    alignSelf: 'flex-start',
  },
}));
