import { tryGetValueOrDefault } from 'main/utils/conditional';
import { capitalize } from 'main/utils/string';
import { ICertificadoPrevidenciaDetalharResponse } from '../interfaces/ICertificadoPrevidenciaDetalharResponse';
import { IDadosBasicosCertificadoPrevidencia } from '../interfaces/IDadosBasicosCertificadoPrevidencia';

export function preencherClassificacaoDoPlano(
  listaCertificados: IDadosBasicosCertificadoPrevidencia[] | undefined,
  detalhesCertificados: ICertificadoPrevidenciaDetalharResponse[] | undefined,
): IDadosBasicosCertificadoPrevidencia[] {
  return tryGetValueOrDefault([listaCertificados], []).map(
    item =>
      ({
        ...item,
        produto: {
          ...item.produto,
          classificacaoDoPlano: capitalize(
            tryGetValueOrDefault(
              [
                tryGetValueOrDefault([detalhesCertificados], []).find(
                  detalhe => detalhe.codConta === item.certificadoNumero,
                )?.produto.classificacaoDoPlano,
              ],
              '',
            ),
          ),
        },
      } as IDadosBasicosCertificadoPrevidencia),
  );
}
