import { SetStateAction, useEffect, useState } from 'react';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import { useRegistrarOcorrenciaContext } from 'registroOcorrenciaASC/features/registrarOcorrencia/contexts/RegistrarOcorrenciaContext';
import {
  usePecoConsultarAssunto,
  usePecoConsultarSubAssunto,
} from 'registroOcorrenciaASC/features/registrarOcorrencia/hooks';
import * as CONSTS from 'registroOcorrenciaASC/features/registrarOcorrencia/constants/constants';
import * as RESPONSE_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrenciasResponse';

export const useRenderizarCardOcorrencia = () => {
  const { cpfCnpjCliente, salvarDadosOcorrencia, toggleFormularioFop } =
    useRegistrarOcorrenciaContext();

  const {
    dadosConsultaAssunto,
    loadingDadosConsultaAssunto,
    obterDadosAssunto,
    setDadosConsultaAssunto,
  } = usePecoConsultarAssunto();

  const {
    dadosConsultaSubAssunto,
    loadingDadosConsultaSubAssunto,
    obterDadosSubAssunto,
    setDadosConsultaSubAssunto,
  } = usePecoConsultarSubAssunto();

  const [produtoOcorrencia, setProdutoOcorrencia] = useState<string>('');

  const [macroassuntoOcorrencia, setMacroassuntoOcorrencia] =
    useState<string>('');

  const [assuntoOcorrencia, setAssuntoOcorrencia] = useState<string>('');

  const isComunicadoSinistroPrestamista = checkIfAllItemsAreTrue([
    produtoOcorrencia === CONSTS.FORM_COMUNICADO.SINISTRO_PRESTAMISTA.PRODUTO,
    macroassuntoOcorrencia ===
      CONSTS.FORM_COMUNICADO.SINISTRO_PRESTAMISTA.MACROASSUNTO,
    assuntoOcorrencia === CONSTS.FORM_COMUNICADO.SINISTRO_PRESTAMISTA.ASSUNTO,
  ]);

  const validaOpcoesOcorrencia = checkIfAllItemsAreTrue([
    !!produtoOcorrencia,
    !!macroassuntoOcorrencia,
    !!assuntoOcorrencia,
  ]);

  const limparDadosMacroAssunto = (): void => {
    setDadosConsultaAssunto(
      {} as SetStateAction<
        | IHandleReponseResult<RESPONSE_TYPES.IConsultarAssuntoResponse>
        | undefined
      >,
    );

    setMacroassuntoOcorrencia('');
  };

  const limparDadosAssunto = (): void => {
    setDadosConsultaSubAssunto(
      {} as SetStateAction<
        | IHandleReponseResult<RESPONSE_TYPES.IConsultarSubAssuntoResponse>
        | undefined
      >,
    );

    setAssuntoOcorrencia('');
  };

  const definirMacroAssunto = (): void => {
    if (produtoOcorrencia) {
      obterDadosAssunto({ unidadeNegocio: produtoOcorrencia });

      limparDadosMacroAssunto();

      limparDadosAssunto();
    }
  };

  const definirAssunto = (): void => {
    if (macroassuntoOcorrencia) {
      obterDadosSubAssunto({
        unidadeNegocio: produtoOcorrencia,
        codigoAssunto: Number(macroassuntoOcorrencia),
      });

      limparDadosAssunto();
    }
  };

  useEffect(definirMacroAssunto, [produtoOcorrencia]);

  useEffect(definirAssunto, [macroassuntoOcorrencia]);

  useEffect(() => {
    salvarDadosOcorrencia({
      ...CONSTS.initialRegistrarOcorrenciaDataState.formDadosOcorrencia,
      produto: produtoOcorrencia,
      macroassunto: macroassuntoOcorrencia,
      assunto: assuntoOcorrencia,
    });

    toggleFormularioFop(isComunicadoSinistroPrestamista);
  }, [produtoOcorrencia, macroassuntoOcorrencia, assuntoOcorrencia]);

  useEffect(() => {
    setProdutoOcorrencia('');

    limparDadosMacroAssunto();

    limparDadosAssunto();
  }, [cpfCnpjCliente.valor]);

  return {
    setProdutoOcorrencia,
    setMacroassuntoOcorrencia,
    setAssuntoOcorrencia,
    assuntoOcorrencia,
    produtoOcorrencia,
    loadingDadosConsultaAssunto,
    dadosConsultaAssunto,
    macroassuntoOcorrencia,
    loadingDadosConsultaSubAssunto,
    dadosConsultaSubAssunto,
    validaOpcoesOcorrencia,
  };
};
