import React, { useContext } from 'react';
import { Button, Display, Text, Grid } from '@cvp/design-system/react';
import MiltonDefault from 'assets/img/ilustracoes/Temp-Coins.svg';
import { ProspeccaoContext } from 'main/features/prospeccao/contexts/ProspeccaoContext';

export const Cadastro: React.FC = () => {
  const { getRouteCadastro } = useContext(ProspeccaoContext);

  return (
    <Grid justify="center" alignItems="center" style={{ height: '100%' }}>
      <Grid.Item xs="1">
        <Text variant="headline-03" color="secondary" margin align="center">
          Pré-Análise de Proposta
        </Text>
        <Grid.Item xs={1}>
          <Display justify="space-around">
            <Display justify="center" alignItems="center">
              <img src={MiltonDefault} alt="Ilustração" />
              <div>
                <Text margin>
                  Cadastre agora potenciais clientes e antecipe a análise de
                  risco.
                </Text>
                <Text margin color="text-light" variant="body02-sm">
                  Para cadastrar novas prospecções, clique no botão{' '}
                  <strong>Cadastrar pré-análise</strong>.
                </Text>
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => getRouteCadastro()}
                >
                  Cadastrar pré-análise
                </Button>
              </div>
            </Display>
            <div />
          </Display>
        </Grid.Item>
      </Grid.Item>
    </Grid>
  );
};
