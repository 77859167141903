import { useContext, useState } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { useAuth } from 'main/features/Auth/hooks';
import { useToast } from 'main/hooks/useToast';
import { DadosClienteModalTipoDocuSingProps } from 'main/components/AssinaturaDocuSign/ModalTipoDocuSign';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { SolicitarAssinaturaBeneficioProtecaoResponse } from 'previdencia/types/SolicitarAssinaturaBeneficioProtecao';
import { ASSINATURA_BENEFICIO_PROTECAO } from 'previdencia/constants/constants';
import * as SolicitarAssinaturaBeneficioProtecao from '../services/solicitarAssinaturaBeneficioProtecao.api';

const useSolicitarAssinaturaBeneficioProtecao = () => {
  const { toastError } = useToast();
  const { user } = useAuth();

  const {
    cliente: { numCertificado },
  } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);

  const [response, setResponse] =
    useState<SolicitarAssinaturaBeneficioProtecaoResponse>();

  const solicitarAssinaturaBeneficioProtecao = async (
    dadosParticipante: DadosClienteModalTipoDocuSingProps,
    numeroDocumento: string,
  ) => {
    try {
      setIsLoading(true);

      const codigoTelefone = dadosParticipante?.mobileNumber?.substring(0, 2);

      const numeroTelefone = dadosParticipante?.mobileNumber?.substring(2);

      const result =
        await SolicitarAssinaturaBeneficioProtecao.solicitarAssinaturaBeneficioProtecao(
          {
            codigoEmpresa: ASSINATURA_BENEFICIO_PROTECAO.CODIGO_EMPRESA,
            codigoCertificado: numCertificado,
            dadosDoEconomiario: {
              matriculaEconomiario: user.nomeAcesso,
              nome: user.nomeUsuario,
            },
            telefone: {
              codigoTelefone: tryGetValueOrDefault([codigoTelefone], ''),
              numero: tryGetValueOrDefault([numeroTelefone], ''),
            },
            dadosAssinatura: {
              tipoAssinatura: 'OFERTA_RISCO',
              tipoEnvio: 'SMS',
            },
            dadosDoSolicitante: {
              nome: tryGetValueOrDefault([dadosParticipante.nome], ''),
              numeroDocumento,
              cpf: dadosParticipante.cpfCnpj,
              email: tryGetValueOrDefault([dadosParticipante.email], ''),
            },
          },
        );

      setResponse(result);
    } catch (error: any) {
      toastError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    response,
    solicitarAssinaturaBeneficioProtecao,
  };
};

export default useSolicitarAssinaturaBeneficioProtecao;
