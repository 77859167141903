import styled from 'styled-components/macro';

export const Container = styled.div(({ theme }) => ({
  padding: '20px',
  justifyContent: 'end',
  gap: '50px',

  [theme.breakpoint.md()]: {
    display: 'flex',
  },
}));
