export const PrazoAcumulacao = () => {
  return (
    <table>
      <thead>
        <tr>
          <th>Prazo de Acumulação</th>
          <th>Alíquotas Regressivas</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Até 2 anos</td>
          <td>35%</td>
        </tr>
        <tr>
          <td>Até 2 a 4 anos</td>
          <td>30%</td>
        </tr>
        <tr>
          <td>De 6 a 8 anos</td>
          <td>20%</td>
        </tr>
        <tr>
          <td>De a 10 anos</td>
          <td>15%</td>
        </tr>
        <tr>
          <td>Acima de 10 anos</td>
          <td>10%</td>
        </tr>
      </tbody>
    </table>
  );
};
