import React from 'react';
import { Display, Grid, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { AccordionFaqFiltroProps } from 'reter/features/retencao/types/AccordionFaqFiltroProps';
import * as S from 'reter/features/retencao/components/AccordionFaqFiltro/styles';

const AccordionFaqFiltro: React.FC<AccordionFaqFiltroProps> = ({
  search,
  setSearch,
  filteredSearch,
}) => {
  return (
    <>
      <Grid>
        <Grid.Item xs="1">
          <S.TituloPagina variant="headline-01" color="primary">
            FAQ
          </S.TituloPagina>
          <Display>
            <S.InputPesquisaContainer>
              <S.InputPesquisa
                name="search"
                placeholder="Palavra-chave"
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
              />
            </S.InputPesquisaContainer>
          </Display>
        </Grid.Item>
      </Grid>

      <RenderConditional
        condition={Boolean(search)}
        component={
          <Grid>
            <Grid.Item xs="1">
              <Text variant="body01-lg">
                {filteredSearch.length}{' '}
                {filteredSearch.length === 1 ? 'resposta' : 'respostas'} com a
                palavra{' '}
                <S.PalavraChavePesquisada>{`"${search}"`}</S.PalavraChavePesquisada>
              </Text>
            </Grid.Item>
          </Grid>
        }
      />

      <Grid>
        <Grid.Item xs="1">
          <S.SubtituloPerguntasFrequentes type="display-block">
            <Text variant="body01-lg" color="white">
              {search
                ? `Perguntas com a palavra "${search}"`
                : 'Perguntas Frequentes'}
            </Text>
          </S.SubtituloPerguntasFrequentes>
        </Grid.Item>
      </Grid>
    </>
  );
};

export default AccordionFaqFiltro;
