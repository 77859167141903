import { AppContext } from 'main/contexts/AppContext';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { useContext, useEffect } from 'react';
import { IUseApoliceDadosCertificadoVida } from 'seguros/types/ApoliseCoberturaUsePeco';
import { useCertificadoSeguroResumo } from './useCertificadoSeguroResumo';

export const useApoliceDadosCertificadoVida =
  (): IUseApoliceDadosCertificadoVida => {
    const { clienteVida } = useContext(AppContext);
    const {
      certificadoSeguros: { fetchData, response, loading },
    } = useCertificadoSeguroResumo({
      numeroContrato: tryGetValueOrDefault([clienteVida?.numCertificado], '0'),
      codigoProduto: tryGetValueOrDefault([clienteVida?.codigoProduto], '0'),
    });

    useEffect(() => {
      fetchData();
    }, []);

    const entidade = response?.entidade;

    const dados = entidade?.individual?.proposta;

    return { dados, loading };
  };
