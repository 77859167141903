import React from 'react';
import { Text } from '@cvp/design-system/react';
import { IFromFeedback } from 'contratosPrestamista/features/cancelamento/types/IFromFeedback';

const FormFeedback: React.FC<IFromFeedback> = ({ mensagem }) => {
  return (
    <div data-testid="modalFeedback">
      <Text
        data-testid="mensagemFeedback"
        variant="body-sm-02"
        margin
        color="success"
      >
        {mensagem}
      </Text>
    </div>
  );
};

export default FormFeedback;
