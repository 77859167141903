export const isMatriculaCef = (matricula: string) =>
  /^C[0-9-]*$/.test(matricula);

/**
 * Caso a matrícula seja de funcionário CEF, remove letras, zeros a esquerda e caracteres especiais da matricula.
 * @param matricula
 * @returns
 */
export const formatarMatricula = (matricula: string) => {
  if (!matricula) return '';

  const matriculaCef = matricula.toUpperCase();
  if (isMatriculaCef(matriculaCef)) {
    return matriculaCef.substr(0, 7);
  }

  return matriculaCef;
};

export const formatarMatriculaComZeroAEsquerda = (
  event: React.FormEvent<HTMLInputElement>,
) => {
  const matricula = event.currentTarget.value;
  if (isMatriculaCef(matricula) && matricula.length === 6) {
    const matriculaComZero = matricula.substr(1, 5).padStart(6, '0');
    return `C${matriculaComZero}`;
  }

  return matricula;
};
