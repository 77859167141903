import * as RESPONSE_TYPES from 'seguros/types/DadosSeguradoResponse';
import { tryGetValueOrDefault } from 'main/utils/conditional';

export const initialFormikStateFactory = (
  informacoesSegurado: RESPONSE_TYPES.IDadosSeguradoResponse,
) => ({
  cep: tryGetValueOrDefault(
    [informacoesSegurado?.dadosEnderecoSegurado.cep],
    '',
  ),
  endereco: tryGetValueOrDefault(
    [informacoesSegurado?.dadosEnderecoSegurado.endereco],
    '',
  ),
  complemento: tryGetValueOrDefault(
    [informacoesSegurado?.dadosEnderecoSegurado.complemento],
    '',
  ),
  bairro: tryGetValueOrDefault(
    [informacoesSegurado?.dadosEnderecoSegurado.bairro],
    '',
  ),
  cidade: tryGetValueOrDefault(
    [informacoesSegurado?.dadosEnderecoSegurado.cidade],
    '',
  ),

  uf: tryGetValueOrDefault([informacoesSegurado?.dadosEnderecoSegurado.uf], ''),

  ddd: tryGetValueOrDefault(
    [informacoesSegurado?.dadosTelefoneSegurado.prefixoTelefone],
    '',
  ),
  telefone: tryGetValueOrDefault(
    [informacoesSegurado?.dadosTelefoneSegurado.numeroTelefone],
    '',
  ),
  celular: tryGetValueOrDefault(
    [informacoesSegurado?.dadosTelefoneSegurado.numeroCelular],
    '',
  ),

  email: tryGetValueOrDefault([informacoesSegurado?.email], ''),
  dadosPessoaisOutrosProdutos: false,
  dadosPessoaisParceiros: false,
});
