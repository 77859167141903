import React from 'react';
import Icon from 'main/components/Icon';
import { Text, Display, Grid } from '@cvp/design-system/react';
import { maskCpfCnpj } from 'contratosPrestamista/utils/maskCpfCnpj';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { formatarDataAmigavel } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';

import { ModalProps } from 'painelInadimplencia/types/ModalDetalhesInadimplencia';
import RenderConditional from 'main/components/RenderConditional';
import { PREV, SEGMENTO } from 'painelInadimplencia/constants/constants';
import useSegmento from 'painelInadimplencia/hooks/useSegmento';
import ModalBlur from 'main/components/ModalBlur/ModalBlur';

let periodicidadePagamento = '';
const ModalDetalhesInadimplencia: React.FC<ModalProps> = ({
  open,
  dados,
  onClose,
}) => {
  const { validarSegmentoRota } = useSegmento();

  if (!open) return null;

  switch (dados?.opcaoPagamento) {
    case 'PM':
      periodicidadePagamento = 'Pagamento Mensal';
      break;
    case 'PA':
      periodicidadePagamento = 'Pagamento Anual';
      break;
    case 'PU':
      periodicidadePagamento = 'Pagamento Único';
      break;
    case 'PT':
      periodicidadePagamento = 'Pagamento Trimestral';
      break;
    case 'PS':
      periodicidadePagamento = 'Pagamento Semestral';
      break;
    default:
      break;
  }

  return (
    <ModalBlur onClose={onClose}>
      <Grid>
        <Grid.Item xs={1 / 2}>
          <Display alignItems="center">
            <Icon name="user" />
            <span>
              <Text variant="caption-02">Nome</Text>
              <Text variant="body02-xs">
                {tryGetValueOrDefault([dados?.nomeCliente], '-')}
              </Text>
            </span>
          </Display>
        </Grid.Item>
        <Grid.Item xs={1 / 2}>
          <Display alignItems="center">
            <Icon name="documentFront" />
            <span>
              <Text variant="caption-02">CPF</Text>
              <Text variant="body02-xs">
                {tryGetValueOrDefault(
                  [maskCpfCnpj(dados?.cpf ? dados.cpf.toString() : '')],
                  '-',
                )}
              </Text>
            </span>
          </Display>
        </Grid.Item>
        <Grid.Item xs={1 / 2}>
          <Display alignItems="center">
            <Icon name="documentPaper" />
            <span>
              <Text variant="caption-02">Nome do Produto</Text>
              <Text variant="body02-xs">
                {tryGetValueOrDefault([dados?.produto], '-')}
              </Text>
            </span>
          </Display>
        </Grid.Item>
        <Grid.Item xs={1 / 2}>
          <Display alignItems="center">
            <Icon name="waiting" />
            <span>
              <Text variant="caption-02">Periodicidade do produto</Text>
              <Text variant="body02-xs">
                {tryGetValueOrDefault([periodicidadePagamento], '-')}
              </Text>
            </span>
          </Display>
        </Grid.Item>
        <Grid.Item xs={1 / 2}>
          <Display alignItems="center">
            <Icon name="financialWallet" />
            <span>
              <Text variant="caption-02">Opção de Pagamento</Text>
              <Text variant="body02-xs">
                {tryGetValueOrDefault([dados?.formaPagamento], '-')}
              </Text>
            </span>
          </Display>
        </Grid.Item>
        <Grid.Item xs={1 / 2}>
          <Display alignItems="center">
            <Icon name="financialMoneyBag" />
            <span>
              <Text variant="caption-02">
                <RenderConditional
                  condition={validarSegmentoRota(SEGMENTO.VIDA)}
                >
                  Valor de prêmio
                </RenderConditional>
                <RenderConditional
                  condition={validarSegmentoRota(SEGMENTO.PREV)}
                >
                  Valor de Contribuição
                </RenderConditional>
              </Text>
              <Text variant="body02-xs">
                {tryGetValueOrDefault(
                  [tryGetMonetaryValueOrDefault(dados?.valorProposta)],
                  '-',
                )}
              </Text>
            </span>
          </Display>
        </Grid.Item>
        <Grid.Item xs={1 / 2}>
          <Display alignItems="center">
            <Icon name="calendar" />
            <span>
              <Text variant="caption-02">
                Último mês referência da parcela inadimplente
              </Text>
              <Text variant="body02-xs">
                {tryGetValueOrDefault(
                  [formatarDataAmigavel(dados?.ultimaCobranca)],
                  '-',
                )}
              </Text>
            </span>
          </Display>
        </Grid.Item>
        <Grid.Item xs={1 / 2}>
          <Display alignItems="center">
            <Icon name="waiting" />
            <span>
              <Text variant="caption-02">
                Tempo de vida do contrato (em mês);
              </Text>
              <Text variant="body02-xs">
                {tryGetValueOrDefault([dados?.tempoContrato], '-')}
              </Text>
            </span>
          </Display>
        </Grid.Item>
        <Grid.Item xs={1 / 2}>
          <Display alignItems="center">
            <Icon name="filterEqualize" />
            <span>
              <Text variant="caption-02">Beneficiários Indicados?</Text>
              <Text variant="body02-xs">
                {tryGetValueOrDefault([dados?.beneficiariosExistentes], '-')}
              </Text>
            </span>
          </Display>
        </Grid.Item>

        <RenderConditional condition={dados?.segmento === PREV}>
          <Grid.Item xs={1 / 2}>
            <Display alignItems="center">
              <Icon name="filterEqualize" />
              <span>
                <Text variant="caption-02">Comunicabilidade (sim ou não).</Text>
                <Text variant="body02-xs">
                  {tryGetValueOrDefault([dados?.comunicabilidade], '-')}
                </Text>
              </span>
            </Display>
          </Grid.Item>
        </RenderConditional>
      </Grid>
    </ModalBlur>
  );
};

export default ModalDetalhesInadimplencia;
