import {
  Button,
  Grid,
  Text,
  TextField,
  Display,
} from '@cvp/design-system/react';
import { FormikProps, useFormik } from 'formik';
import Calendar from 'main/components/Calendar/Calendar';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { formatarData } from 'main/utils';
import * as UTILS from 'previdencia/features/Resgate/utils/validacoes';
import React from 'react';
import { useHistory } from 'react-router';
import * as REQUEST_TYPES from 'previdencia/features/Resgate/types/RelatorioResgateRequest';

interface IConsultaPeriodoResgatesProps
  extends REQUEST_TYPES.IRelatorioResgatePayload {
  bloquearForm: boolean;
  handleSearchSubmit: (values: REQUEST_TYPES.IRelatorioResgatePayload) => void;
}

const ConsultaPeriodoResgates: React.FC<IConsultaPeriodoResgatesProps> = ({
  bloquearForm,
  handleSearchSubmit,
  codAgencia,
  dataFim,
  dataInicio,
}: IConsultaPeriodoResgatesProps) => {
  const history = useHistory();
  const formik: FormikProps<REQUEST_TYPES.IRelatorioResgatePayload> =
    useFormik<REQUEST_TYPES.IRelatorioResgatePayload>({
      initialValues: { codAgencia, dataFim, dataInicio },
      onSubmit: values => {
        handleSearchSubmit(values);
      },
      validationSchema: UTILS.validationFormConsultaPeriodoResgatesSchema,
      validateOnMount: true,
    });

  return (
    <form
      className="hide-print"
      data-testid="form"
      onSubmit={event => formik.handleSubmit(event)}
      style={{ height: '100%' }}
    >
      <Grid justify="flex-start" alignItems="center">
        <Grid.Item xs={1} lg={1 / 4} justify="center" alignItems="center">
          <Text variant="body01-md">Agência:</Text>
          <TextField
            name="codAgencia"
            placeholder="Agência"
            data-testid="codAgencia"
            value={formik.values.codAgencia}
            disabled={process.env.NODE_ENV === 'production' || bloquearForm}
            onChange={formik.handleChange}
          />
        </Grid.Item>
        <RenderConditional condition={!bloquearForm}>
          <Grid.Item lg={2 / 4} xs={1} justify="center" alignItems="center">
            <Calendar
              data-testid="calendar"
              id="periodo"
              placeholder=""
              maxDate={new Date()}
              range
              value={
                formik.values.dataInicio
                  ? new Date(formik.values.dataInicio)
                  : null
              }
              endDate={
                formik.values.dataFim ? new Date(formik.values.dataFim) : null
              }
              onChange={(dataInicial, dataFinal) => {
                formik.validateForm();
                formik.setFieldValue('dataInicio', dataInicial?.toISOString());
                formik.setFieldValue('dataFim', dataFinal?.toISOString());
              }}
            />
          </Grid.Item>
        </RenderConditional>

        <RenderConditional condition={bloquearForm}>
          <Grid.Item xs={1} lg={1 / 4} justify="center" alignItems="center">
            <Text variant="body01-md">De:</Text>
            <TextField
              name="dataInicio"
              placeholder=""
              data-testid="dataInicio"
              value={formatarData(formik.values.dataInicio)}
              disabled="true"
            />
          </Grid.Item>
          <Grid.Item xs={1} lg={1 / 4} justify="center" alignItems="center">
            <Text variant="body01-md">Até:</Text>
            <TextField
              name="dataFim"
              placeholder=""
              data-testid="dataFim"
              value={formatarData(formik.values.dataFim)}
              disabled="true"
            />
          </Grid.Item>
        </RenderConditional>
      </Grid>
      <RenderConditional condition={!bloquearForm}>
        <Grid justify="center" alignItems="center">
          <Grid.Item xs={1}>
            <Display>
              <Button
                type="button"
                onClick={() => {
                  history.goBack();
                }}
                variant="outlined"
                data-testid="voltar"
              >
                Voltar
              </Button>
              <Button
                variant="secondary"
                type="submit"
                disabled={!formik.isValid}
                data-testid="consultar"
              >
                Consultar
              </Button>
            </Display>
          </Grid.Item>
        </Grid>
      </RenderConditional>
    </form>
  );
};

export default React.memo(ConsultaPeriodoResgates);
