import React, { useState, useEffect } from 'react';
import { Grid, Checkbox } from '@cvp/design-system/react';
import {
  formatarValorPadraoDigitoBrasileiro,
  tryGetMonetaryValueOrDefault,
} from 'main/utils/money';
import {
  COLUNAS_TABELA_FUNDOS_PJ,
  COLUNAS_TABELA_REDISTRIBUIR_FUNDOS_PJ,
} from './constants/constants';
import { EnumEtapas } from 'previdencia/types/Fundo.type';
import Table, { Skeleton } from 'main/components/Table';
import {
  modificarMultifundoPjSelecionado,
  modificarMultifundoPjPorTipoTransferencia,
  modificarMultifundoPjValorTransferencia,
} from 'previdencia/utils/tabelaFundos';
import { levelRisk } from 'previdencia/utils/riskStatus';
import RenderConditional from 'main/components/RenderConditional';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import {
  MultifundoPjData,
  Reserva,
  SelecionarTransferenciaProps,
} from 'previdencia/types/MultifundosPJ';
import { LinhaExpansivel } from './LinhaExpansivel';
import { RiskBarStyle } from '../stylesPrevidencia';
import { LinhaExpansivelDestino } from './LinhaExpansivelDestino';
import { InputValor } from './InputValor';

import { ITransferenciaMultifundosPjFeatureData } from './types/ITransferenciaMultifundosPjFeatureData';
import { TabelaFundosDefaultValue } from './types/enum';
import { TabelaMultifundosProps } from './types/TabelaMultifundos.types';
import { LoadingContainer } from './styles';
import { ordenarReservasMultifundoPjData } from 'previdencia/features/TransferenciaMultifundos/utils/TransferenciaMultifundos';

const TabelaMultifundosPJ: React.FC<TabelaMultifundosProps> = ({
  fundos,
  quantidadeMaximaFundos,
  valorMinimo,
  valorMaximo,
  isLoading,
  setFundosSelecionados = () => undefined,
}) => {
  const { featureData } =
    usePrevidenciaContext<ITransferenciaMultifundosPjFeatureData>();

  const [tabelaFundos, setTabelaFundos] = useState<MultifundoPjData[]>(
    tryGetValueOrDefault([fundos], []),
  );

  const execedeuQuantidadeMaximaPermitida =
    tabelaFundos.filter(item => item.selecionado).length >=
    quantidadeMaximaFundos;

  const handleSelectedFundo = (id: string, expanded: boolean): void => {
    setTabelaFundos(prevFundos => {
      const fundosSelecionados = prevFundos.map(fundo => {
        return modificarMultifundoPjSelecionado(fundo, id, expanded);
      });

      setFundosSelecionados(
        tryGetValueOrDefault(
          [fundosSelecionados.filter(item => item.selecionado)],
          [],
        ),
      );
      return fundosSelecionados;
    });
  };

  const handleTipoTransferencia = (
    props: SelecionarTransferenciaProps,
  ): void => {
    setTabelaFundos(prevFundos => {
      const fundosSelecionados = modificarMultifundoPjPorTipoTransferencia({
        ...props,
        fundos: prevFundos,
      });

      setFundosSelecionados(
        tryGetValueOrDefault(
          [fundosSelecionados.filter(item => item.selecionado)],
          [],
        ),
      );
      return fundosSelecionados;
    });
  };

  const handleValorTransferencia = (
    props: SelecionarTransferenciaProps,
  ): void => {
    setTabelaFundos(prevFundos => {
      const fundosSelecionados = modificarMultifundoPjValorTransferencia({
        ...props,
        fundos: prevFundos,
      });

      setFundosSelecionados(
        tryGetValueOrDefault(
          [fundosSelecionados.filter(item => item.selecionado)],
          [],
        ),
      );
      return fundosSelecionados;
    });
  };

  const handleValorContribuicaoRegular = (id: string, valor: number) => {
    setTabelaFundos(prevFundos => {
      const novosFundos = [...prevFundos];

      const index = novosFundos.findIndex(item => item.codigoFundo === id);

      novosFundos[index] = {
        ...novosFundos[index],
        valorContribuicao: valor,
      };
      setFundosSelecionados(
        tryGetValueOrDefault(
          [novosFundos.filter(item => item.selecionado)],
          [],
        ),
      );
      return novosFundos;
    });
  };

  useEffect(() => {
    if (featureData?.etapa === EnumEtapas.selecionarFundosOrigem) {
      setTabelaFundos(tryGetValueOrDefault([fundos], []));
    }
  }, [fundos]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <Skeleton colunas={COLUNAS_TABELA_FUNDOS_PJ} />
      </LoadingContainer>
    );
  }

  const expandirLinha = () => {
    if (featureData?.etapa === EnumEtapas.selecionarFundosDestino) {
      return (
        <LinhaExpansivelDestino handleAlterar={handleValorTransferencia} />
      );
    }
    return <LinhaExpansivel handleAlterar={handleTipoTransferencia} />;
  };

  function desabilitarCheckbox(
    selecionado: boolean | undefined,
    reservas: Reserva[],
  ) {
    const etapaDestino =
      featureData?.etapa === EnumEtapas.selecionarFundosDestino;

    const possuiReservaComValor = reservas.some(({ descricaoReserva }) => {
      return (
        Number(
          featureData?.dataResumo?.find(
            e =>
              e.resumo.toLocaleLowerCase() ===
              descricaoReserva?.toLocaleLowerCase(),
          )?.valor,
        ) !== 0
      );
    });

    const verificaReserva = checkIfAllItemsAreTrue([
      !possuiReservaComValor,
      etapaDestino,
    ]);

    const verificaExcedente = checkIfAllItemsAreTrue([
      execedeuQuantidadeMaximaPermitida,
      !selecionado,
    ]);

    return checkIfSomeItemsAreTrue([verificaExcedente, verificaReserva]);
  }

  const formatData = ordenarReservasMultifundoPjData(tabelaFundos).map(
    ({
      valorSaldo,
      codigoFundo,
      descricaoPerfilFundo,
      selecionado,
      percentualRentabilidadeUltimoAno,
      ...rest
    }) => ({
      ...rest,
      descricaoPerfilFundo: `${codigoFundo} - ${descricaoPerfilFundo}`,
      check: (
        <Checkbox
          data-tag="allowRowEvents"
          id={codigoFundo}
          disabled={desabilitarCheckbox(selecionado, rest.reservas)}
          checked={selecionado}
          onChange={() => handleSelectedFundo(codigoFundo, !selecionado)}
        />
      ),
      perfil: (
        <RiskBarStyle
          risk={levelRisk(
            tryGetValueOrDefault(
              [descricaoPerfilFundo],
              TabelaFundosDefaultValue.PERFIL_RISCO_MODERADO,
            ),
          )}
        />
      ),
      valorSaldo: tryGetMonetaryValueOrDefault(
        tryGetValueOrDefault([valorSaldo], 0),
      ),
      rentabilidade: `${formatarValorPadraoDigitoBrasileiro(
        percentualRentabilidadeUltimoAno,
        2,
        true,
      )}%`,
      codigoFundo,
      valorMinimo,
      selecionado,
    }),
  );

  const formatDataRedistribuir = tabelaFundos?.map(
    ({ codigoFundo, selecionado, ...rest }) => ({
      check: (
        <Checkbox
          data-tag="allowRowEvents"
          id={codigoFundo}
          checked={selecionado}
          disabled={checkIfAllItemsAreTrue([
            execedeuQuantidadeMaximaPermitida,
            !selecionado,
          ])}
          onChange={() => handleSelectedFundo(codigoFundo, !selecionado)}
        />
      ),
      valor_contribuicao: (
        <InputValor
          noLegend
          show={selecionado}
          valorMaximo={tryGetValueOrDefault([valorMaximo], 0)}
          valorMinimo={valorMinimo}
          onBlur={e => handleValorContribuicaoRegular(codigoFundo, e)}
        />
      ),
      codigoFundo,
      ...rest,
    }),
  );

  return (
    <Grid.Item xs={1}>
      <RenderConditional
        condition={featureData?.etapa !== EnumEtapas.redistribuirValores}
      >
        <Table
          noHeader
          responsive
          expandableRows
          expandOnRowClicked
          expandableRowsHideExpander
          expandableRowsComponent={expandirLinha()}
          columns={COLUNAS_TABELA_FUNDOS_PJ}
          noDataComponent="Não há dados para exibir."
          onRowExpandToggled={(expanded, row) => {
            handleSelectedFundo(row.codigoFundo, expanded);
          }}
          data={
            quantidadeMaximaFundos === 0
              ? []
              : tryGetValueOrDefault([formatData], [])
          }
          expandableRowDisabled={row =>
            checkIfAllItemsAreTrue([
              execedeuQuantidadeMaximaPermitida,
              !row.selecionado,
            ])
          }
        />
      </RenderConditional>
      <RenderConditional
        condition={featureData?.etapa === EnumEtapas.redistribuirValores}
      >
        <Table
          responsive
          data={tryGetValueOrDefault([formatDataRedistribuir], [])}
          columns={COLUNAS_TABELA_REDISTRIBUIR_FUNDOS_PJ}
          noDataComponent="Não há dados para exibir."
        />
      </RenderConditional>
    </Grid.Item>
  );
};

export default TabelaMultifundosPJ;
