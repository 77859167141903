import React from 'react';
import { CardResumoProduto, QuitacaoAnualDebitos } from 'seguros/components';

const DeclaracaoDeQuitacaoAnualDebitos = () => {
  return (
    <>
      <CardResumoProduto />
      <QuitacaoAnualDebitos />
    </>
  );
};

export default DeclaracaoDeQuitacaoAnualDebitos;
