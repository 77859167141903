import React from 'react';
import DetalhesDefinirParecer from '../components/DetalhesDefinirParecer';
import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';
import { useAtualizarStatusProspeccao } from '../hooks/useAtualizarStatusProspeccao';

const DetalhesDefinirParecerTimeProduto: React.FC = () => {
  const { devePermitirParecerTecnico } = useAtualizarStatusProspeccao();

  return (
    <DetalhesDefinirParecer
      perfilAvaliador={USER_PROFILES.ANALISTA_PRODUTO}
      approveRejectIds={{ approve: 5, reject: 6 }}
      title="Parecer Técnico"
      permitirParecer={devePermitirParecerTecnico}
    />
  );
};

export default DetalhesDefinirParecerTimeProduto;
