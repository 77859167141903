import { useContext } from 'react';

import { usePeco } from 'main/hooks/usePeco';
import { parseDateStringToDate } from 'main/utils';
import { AppContext } from 'main/contexts/AppContext';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { PECOS } from 'previdencia/config/endpoints';
import * as REQUEST_TYPES from 'evolucaoPatrimonial/types/EvolucaoPatrimonialRequest';
import * as RESPONSE_TYPES from 'evolucaoPatrimonial/types/EvolucaoPatrimonialResponse';
import * as EVOLUCAO_PATRIMONIAL_TYPES from 'evolucaoPatrimonial/types/EvolucaoPatrimonial';

export const usePecoConsultarEvolucaoPatrimonial = ({
  dataInicial,
  dataFinal,
}: EVOLUCAO_PATRIMONIAL_TYPES.IUsePecoConsultarEvolucaoPatrimonialParams) => {
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);

  const {
    loading: loadingConsultaEvolucaoPatrimonial,
    fetchData: fetchDataConsultarEvolucaoPatrimonial,
    response: dadosConsultaEvolucaoPatrimonial,
  } = usePeco<
    REQUEST_TYPES.IRequestConsultarEvolucaoPatrimonial,
    RESPONSE_TYPES.IResponseConsultarEvolucaoPatrimonial
  >({
    api: {
      operationPath: PECOS.ConsultarEvolucaoPatrimonial,
    },
    handleResponse: {
      throwToastErrorBFF: false,
    },
    autoFetch: true,
    payload: {
      dataInicial: parseDateStringToDate(dataInicial),
      dataFinal: parseDateStringToDate(dataFinal),
      cpfCliente: tryGetValueOrDefault([cpfCnpj], ''),
    },
  });

  return {
    loadingConsultaEvolucaoPatrimonial,
    fetchDataConsultarEvolucaoPatrimonial,
    dadosConsultaEvolucaoPatrimonial,
  };
};
