import { IApiResponse } from 'main/services';
import { formatarData } from 'main/utils';
import masks from 'main/utils/masks';
import { IProspeccao } from 'main/features/prospeccao/types/IProspeccao';

export const mapListProspeccao = (
  data: IApiResponse<IProspeccao[]>,
): IApiResponse<IProspeccao[]> => {
  if (data.sucesso && data.dados && data.dados.entidade) {
    const resultToMap = data;
    const entidadeRows = resultToMap.dados.entidade || [];
    const resultMapped = entidadeRows.map(prospeccao => ({
      ...prospeccao,
      dataHoraCadastroFormatada: formatarData(prospeccao.dataHoraCadastro),
      valorProspeccaoFormatado: masks.currency.mask(prospeccao.valorProspeccao),
    }));

    resultToMap.dados.entidade = resultMapped;

    return resultToMap;
  }

  return data;
};
