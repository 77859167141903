import styled from 'styled-components/macro';
import { Display as DisplayDS, Text as TextDS } from '@cvp/design-system/react';

export const ContainerData = styled(DisplayDS)`
  display: flex;
  flex-direction: column;

  h6,
  p {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export const ContainerButton = styled(DisplayDS)`
  margin-top: 40px;
`;

export const TitlePage = styled(TextDS)`
  margin-bottom: 40px;
`;
