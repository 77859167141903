import { useFormik } from 'formik';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { IDadosAtualizacaoCliente } from '../types/IDadosAtualizacaoCliente';
import { IResponseConsultarClienteAL } from '../types/IResponseConsultarClienteAL';
import { useAtualizarClienteAreaLogada } from './useAtualizarClienteAreaLogada';
import { useConsultarClienteAreaLogada } from './useConsultarClienteAreaLogada';
import { useDeletarClienteAreaLogada } from './useDeletarClienteAreaLogada';
import { useToast } from 'main/hooks/useToast';

export const useResetCadastro = () => {
  const {
    state: { numCpf },
  } = useLocation<{ numCpf: string }>();
  const history = useHistory();
  const [numCpfCnpj, setNumCpfCnpj] = useState<string>('');
  const { toastSuccess, toastError } = useToast();
  const {
    fetchData: obterDadosCliente,
    loading: loadingObterDadosCliente,
    response: responseObterDadosCliente,
  } = useConsultarClienteAreaLogada(numCpfCnpj);
  const {
    fetchData: atualizarDadosCliente,
    loading: loadingAtualizarDadosCliente,
  } = useAtualizarClienteAreaLogada();

  const { fetchData: deletarCliente, loading: loadingDeletarCliente } =
    useDeletarClienteAreaLogada();
  const [areaLogada, baseUnica] = tryGetValueOrDefault(
    [responseObterDadosCliente?.entidade?.bases],
    [],
  );
  const [openModalAtualizacaoDados, setOpenModalAtualizacaoDados] =
    useState(false);
  const [openModalConfirmacaoDelete, setOpenModalConfirmacaoDelete] =
    useState(false);
  const { resultadoAnalise, habilitarReset, habilitarAtualizacaoCliente } =
    tryGetValueOrDefault(
      [responseObterDadosCliente?.entidade],
      {} as IResponseConsultarClienteAL,
    );
  const formik = useFormik<IDadosAtualizacaoCliente>({
    initialValues: {
      nome: '',
      email: '',
      telefone: '',
    },
    onSubmit: async values => {
      const retorno = await atualizarDadosCliente({
        ...values,
        numCpf,
      });
      if (retorno?.sucessoBFF) {
        setNumCpfCnpj('');
        toastSuccess();
        history.goBack();
      } else toastError();
    },
  });

  const handleDeletarCliente = async () => {
    const retorno = await deletarCliente({ numCpf });
    if (retorno?.sucessoBFF) {
      toastSuccess();
      history.goBack();
    } else toastError();
  };

  const handleOpenCloseModalAtualizacaoDados = () =>
    setOpenModalAtualizacaoDados(!openModalAtualizacaoDados);

  const handleOpenCloseModalConfirmacaoDelete = () =>
    setOpenModalConfirmacaoDelete(!openModalConfirmacaoDelete);

  const handleNovaConsulta = () => history.goBack();
  useEffect(() => {
    if (numCpf) {
      obterDadosCliente({ numCpf });
    }
  }, [numCpf]);

  useEffect(() => {
    if (baseUnica) {
      formik.setValues({
        nome: baseUnica.nome,
        email: baseUnica.email,
        telefone: `${baseUnica.numDDD}${baseUnica.numTelefone}`,
      });
    }
  }, [baseUnica]);

  return {
    handleDeletarCliente,
    formik,
    resultadoAnalise,
    habilitarReset,
    habilitarAtualizacaoCliente,
    openModalConfirmacaoDelete,
    setOpenModalConfirmacaoDelete,
    openModalAtualizacaoDados,
    setOpenModalAtualizacaoDados,
    areaLogada,
    baseUnica,
    loadingAtualizarDadosCliente,
    loadingDeletarCliente,
    loadingObterDadosCliente,
    handleNovaConsulta,
    handleOpenCloseModalAtualizacaoDados,
    handleOpenCloseModalConfirmacaoDelete,
  };
};
