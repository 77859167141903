import {
  checkIfSomeItemsAreTrue,
  getTernaryResult,
} from 'main/utils/conditional';
import * as CONSTS from 'previdencia/features/AlteracaoFormaDadosPagamento/constants/constants';

export const converterCodigoOperacaoParaTipoContaBanco = (
  codigo: string,
): string => {
  switch (codigo) {
    case CONSTS.OPERACAO_CONTA_BANCO.NUMERO_CONTA_CORRENTE:
      return CONSTS.OPERACAO_CONTA_BANCO.SIGLA_CONTA_CORRENTE;
    case CONSTS.OPERACAO_CONTA_BANCO.NUMERO_CONTA_POUPANCA:
      return CONSTS.OPERACAO_CONTA_BANCO.SIGLA_CONTA_POUPANCA;
    case CONSTS.OPERACAO_CONTA_BANCO.NUMERO_CONTA_CAIXA_FACIL:
      return CONSTS.OPERACAO_CONTA_BANCO.SIGLA_CONTA_POUPANCA;
    case CONSTS.OPERACAO_CONTA_BANCO.NUMERO_POUPANCA_CAIXA_FACIL:
      return CONSTS.OPERACAO_CONTA_BANCO.SIGLA_CONTA_POUPANCA;
    case CONSTS.OPERACAO_CONTA_BANCO.NUMERO_CONTA_CORRENTE_2:
      return CONSTS.OPERACAO_CONTA_BANCO.SIGLA_CONTA_CORRENTE;
    default:
      return '';
  }
};

export const verificarContaValida = (
  codigoValidacao: string | undefined,
): boolean => {
  return checkIfSomeItemsAreTrue([
    codigoValidacao === CONSTS.CONTA_VALIDA.CODIGO_VALIDACAO_X5,
    codigoValidacao === CONSTS.CONTA_VALIDA.CODIGO_VALIDACAO_999,
  ]);
};

export const buscarMetodoPagamento = (metodoPagamento: string): string => {
  return getTernaryResult(
    metodoPagamento === CONSTS.TIPO_CONTA.DEBITO_CONTA_SIGLA,
    CONSTS.TIPO_CONTA.DEBITO_CONTA,
    metodoPagamento,
  );
};

export const formatarNumeroContaCorrente = (numeroConta: string): string => {
  return numeroConta.toString().padStart(12, '0');
};
