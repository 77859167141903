import React from 'react';
import { Grid, Text } from '@cvp/design-system/react';
import Accordion from 'main/components/PEComponents/Accordion/Accordion';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { dadosBancariosFormaPagamento } from 'seguros/utils/validarDadosConta';
import { ApoliceDadosBancariosProps } from 'seguros/types/DadosApolice';
import { Display } from './styles';

export const ApoliceDadosBancarios: React.FC<ApoliceDadosBancariosProps> = ({
  data,
}) => {
  const isPagamentoDebito = checkIfAllItemsAreTrue([
    !!data?.dadosDebito?.agenciaOperacao,
    !!data?.dadosDebito?.numeroConta,
  ]);

  return (
    <RenderConditional condition={Boolean(data?.dadosDebito)}>
      <Text className="hide-print" variant="headline-05" color="primary" margin>
        Dados da Apólice
      </Text>
      <Accordion className="hide-print" open>
        <Accordion.Item title="Dados Bancários" key="dadosBancarios">
          <Grid>
            <RenderConditional condition={isPagamentoDebito}>
              <Grid.Item xs={1 / 3}>
                <Display>
                  <div>
                    <Text variant="caption-02" color="text-light">
                      Agência
                    </Text>
                    <Text variant="caption-02" color="text">
                      {data?.dadosDebito?.agenciaCodigo}
                    </Text>
                  </div>
                </Display>
              </Grid.Item>
              <Grid.Item xs={1 / 3}>
                <Display>
                  <div>
                    <Text variant="caption-02" color="text-light">
                      Operação
                    </Text>
                    <Text variant="caption-02" color="text">
                      {data?.dadosDebito?.agenciaOperacao}
                    </Text>
                  </div>
                </Display>
              </Grid.Item>
              <Grid.Item xs={1 / 3}>
                <Display>
                  <div>
                    <Text variant="caption-02" color="text-light">
                      Conta de Débito
                    </Text>
                    <Text variant="caption-02" color="text">
                      {data?.dadosDebito?.numeroConta} -{' '}
                      {data?.dadosDebito?.digitoConta}
                    </Text>
                  </div>
                </Display>
              </Grid.Item>
            </RenderConditional>

            <Grid.Item xs={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Periodicidade de Pagamento
                  </Text>
                  <Text variant="caption-02" color="text">
                    {tryGetValueOrDefault([data?.periodoPagamento], '-')}
                  </Text>
                </div>
              </Display>
            </Grid.Item>
            <Grid.Item xs={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Dia de Vencimento
                  </Text>
                  <Text variant="caption-02" color="text">
                    {data?.dadosDebito?.dia}
                  </Text>
                </div>
              </Display>
            </Grid.Item>
            <Grid.Item xs={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Forma de Pagamento
                  </Text>
                  <Text variant="caption-02" color="text">
                    {dadosBancariosFormaPagamento(isPagamentoDebito)}
                  </Text>
                </div>
              </Display>
            </Grid.Item>
          </Grid>
        </Accordion.Item>
      </Accordion>
    </RenderConditional>
  );
};
