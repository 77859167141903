import useFieldLink from 'main/hooks/useFieldLink';
import { CalendarData } from 'main/components/form/Calendar/Calendar.type';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import { isCnpj, isCpf } from 'main/utils/cpf_cnpj';
import * as RESPONSE_TYPES from 'registroOcorrenciaASC/features/consultarOcorrencia/types/ConsultarOcorrenciaResponse';

export const useRenderizarFormConsultaOcorrencia = (
  setListaHistoricoSolicitacao: React.Dispatch<
    React.SetStateAction<RESPONSE_TYPES.IHistoricoSolicitacaoRetorno[]>
  >,
) => {
  const [inputDateLink] = useFieldLink({
    initialDate: null,
    finalDate: null,
  } as CalendarData);

  const [cpfCnpjLink] = useFieldLink('');

  const limparForm = (): void => {
    setListaHistoricoSolicitacao([]);

    inputDateLink.set({
      value: { initialDate: null, finalDate: null },
      isValid: true,
    });

    cpfCnpjLink.set({ value: '', isValid: true });
  };

  const camposFormConsultaPreenchidos: boolean = checkIfAllItemsAreTrue([
    !!inputDateLink.get().value.finalDate,
    !!inputDateLink.get().value.initialDate,
    checkIfSomeItemsAreTrue([
      isCnpj(cpfCnpjLink.get().value),
      isCpf(cpfCnpjLink.get().value),
    ]),
  ]);

  return {
    limparForm,
    camposFormConsultaPreenchidos,
    cpfCnpjLink,
    inputDateLink,
  };
};
