import React from 'react';
import { tryGetValueOrDefault } from 'main/utils/conditional';

interface IForProps<T> {
  each: readonly T[];
  fallback?: React.ReactNode | React.JSX.Element;
  children: (
    item: T,
    index: number,
    array?: readonly T[],
  ) => React.ReactNode | React.JSX.Element;
}

function For<T>(props: IForProps<T>) {
  const { each, fallback, children } = props;

  if (each.length === 0) return tryGetValueOrDefault([<>{fallback}</>], <></>);
  return <>{each.map((item, index, array) => children(item, index, array))}</>;
}

export default For;
