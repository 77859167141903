import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS_VIDA } from 'seguros/config/endpoints';
import * as PECO_TYPES from 'seguros/types/ConsultaSinistroUsePeco';
import * as REQUEST_TYPES from 'seguros/types/ConsultaSinistroRequest';
import * as RESPONSE_TYPES from 'seguros/types/ConsultaSinistroResponse';

const useConsultaSinistro = (): PECO_TYPES.IUseConsultaSinistro => {
  const { clienteVida } = useContext(AppContext);

  const { response: dadosSinistro, loading: loadingDadosSinistro } = usePeco<
    REQUEST_TYPES.IDadosSinistroPayload,
    RESPONSE_TYPES.IDadosSinistroResponse[]
  >({
    api: { operationPath: PECOS_VIDA.ConsultaSinistro },
    autoFetch: true,
    handleResponse: { throwToastErrorBFF: false },
    payload: {
      numContrato: clienteVida?.numCertificado,
    },
  });

  return { dadosSinistro, loadingDadosSinistro };
};

export default useConsultaSinistro;
