import {
  Button,
  Card,
  Display,
  Divider,
  Grid,
  Text,
} from '@cvp/design-system/react';
import ptBR from 'date-fns/locale/pt-BR';
import { CalendarInput } from 'main/components/Calendar/Calendar/styles';
import { enableMenuItem } from 'main/components/Menu/Sidebar';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { AppContext } from 'main/contexts/AppContext';
import { formatarData, parseDateStringToDate } from 'main/utils';
import { converterBase64 } from 'main/utils/converterBase64';
import Grafico from 'previdencia/features/PosicaoConsolidada/components/Grafico/Grafico';
import TabelaCarteiraInvestimento from 'previdencia/features/PosicaoConsolidada/components/TabelaCarteiraInvestimento';
import TextoGraficos from 'previdencia/features/PosicaoConsolidada/components/TextoGraficos/TextoGraficos';
import {
  COMPOSICAO_CARTEIRA_TEXTO,
  RELACAO_RISCO_RETORNO_TEXTO,
  SUBTITULO_PAGINA_TEXTO,
} from 'previdencia/features/PosicaoConsolidada/constants/constants';
import { useExtratoUnificado } from 'previdencia/features/PosicaoConsolidada/hooks/useExtratoUnificado';
import { useGraficoRentabilidadeCarteira } from 'previdencia/features/PosicaoConsolidada/hooks/useGraficoRentabilidadeCarteira';
import useLaminaRentabilidade from 'previdencia/features/PosicaoConsolidada/hooks/useLaminaRentabilidade';
import { ItensExtrato } from 'previdencia/features/PosicaoConsolidada/types/ResponseExtratoUnificado';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import * as S from 'previdencia/features/PosicaoConsolidada/pages/styles';
import { InfoGridItem } from 'previdencia/components/PrevidenciaResumo/InfoGridItem/InfoGridItem';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import masks from 'main/utils/masks';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import EmailSenderModal from 'main/components/EmailSenderModal';
import { useImprimirPosicaoConsolidada } from 'previdencia/features/PosicaoConsolidada/hooks/useImprimirPosicaoConsolidada';

const PosicaoConsolidada: React.FC = () => {
  registerLocale('ptBR', ptBR);
  const dataHoje = new Date();
  const [dataPosicao, setDataPosicao] = useState<Date>(dataHoje);
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);

  const [dadosLamina, setDadosLamina] = useState<ItensExtrato>(
    {} as ItensExtrato,
  );

  const {
    obterExtratoUnificado,
    response: extratoUnificado,
    loading,
  } = useExtratoUnificado();

  const {
    loading: loadingGraficos,
    responseInvestimento,
    responseRentabilidade,
    gerarGraficosRentabilidade,
  } = useGraficoRentabilidadeCarteira();

  const {
    loading: loadingImprimir,
    response: responseImprimir,
    gerarArquivoImprimir,
  } = useImprimirPosicaoConsolidada();
  const {
    data: laminaArquivo,
    refetch,
    isSuccess,
    isRefetchError,
    isFetching,
  } = useLaminaRentabilidade(cpfCnpj);

  const isSucessLaminaRentabilidade = useCallback(() => {
    return checkIfAllItemsAreTrue([
      !!isSuccess,
      !isFetching,
      !isRefetchError,
      !!laminaArquivo,
    ]);
  }, [isSuccess, isFetching, isRefetchError, laminaArquivo]);

  const { goHome } = usePrevNavigation();

  const refetchDataRange = async (data: Date): Promise<void> => {
    setDataPosicao(data);
    if (!data) {
      return;
    }
    const dataFiltrada = parseDateStringToDate(data);

    await obterExtratoUnificado({
      cpf: cpfCnpj,
      dataInicial: dataFiltrada,
      dataFinal: dataFiltrada,
    });
  };

  const objetoEmail = {
    tipoEmail: tipoEmailConstants.CARTEIRA_INVESTIMENTOS_POSICAO_CONSOLIDADA,
    parametrosEnvio: {
      cpfCnpj,
      data: parseDateStringToDate(dataPosicao),
      numCertificado: extratoUnificado?.itensExtrato?.[0]?.contaId,
    },
  };

  useEffect(() => {
    if (isSucessLaminaRentabilidade() && laminaArquivo) {
      const blob = converterBase64(laminaArquivo[0].binario);

      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  }, [isSucessLaminaRentabilidade, laminaArquivo]);

  useEffect(() => {
    gerarGraficosRentabilidade();
    refetchDataRange(dataHoje);
    enableMenuItem('menuPosicaoConsolidada', true);
  }, []);

  useEffect(() => {
    if (checkIfAllItemsAreTrue([!!responseImprimir, !loadingImprimir])) {
      const mywindow = window.open('', 'response', 'resizable=yes');
      mywindow?.document?.write(responseImprimir as string);
      setTimeout(() => {
        mywindow?.print();
      }, 100);
    }
  }, [responseImprimir, loadingImprimir]);

  return (
    <Display type="display-block">
      <RenderConditional
        condition={checkIfSomeItemsAreTrue([loading, loadingGraficos])}
      >
        <SkeletonLoading />
      </RenderConditional>
      <RenderConditional
        condition={checkIfAllItemsAreTrue([!loading, !loadingGraficos])}
      >
        {' '}
        <Card className="hide-print">
          <Card.Content className="hide-print" padding={[4, 4, 4]}>
            <Text variant="headline-05" color="primary" margin>
              Posição Consolidada do Dia
            </Text>
            <Text variant="body02-sm" color="text" margin>
              {SUBTITULO_PAGINA_TEXTO}
            </Text>

            <Display type="flex">
              <InfoGridItem
                icon="user"
                label="Titular"
                value={tryGetValueOrDefault(
                  [extratoUnificado?.nomePessoaCertificado],
                  '-',
                )}
              />
              <InfoGridItem
                icon="documentFront"
                label="CPF"
                value={tryGetValueOrDefault(
                  [masks.cpf.mask(extratoUnificado?.cpfPessoaCertificado)],
                  '-',
                )}
              />
            </Display>
          </Card.Content>
        </Card>
        <Card>
          <Card.Content paddind={[4, 4, 4]}>
            <Grid>
              <Grid.Item xs={1} className="show-print">
                <Text variant="headline-05" color="primary" margin>
                  Posição Consolidada do Dia - {formatarData(dataPosicao)}
                </Text>
              </Grid.Item>
              <Grid.Item xs={1} md={2 / 4} lg={1 / 4} className="hide-print">
                <Text variant="body01-md">Posição em:</Text>
                <DatePicker
                  selected={dataPosicao}
                  placeholderText="Selecione o dia"
                  onChange={(date: Date) => refetchDataRange(date)}
                  locale="ptBR"
                  dateFormat="dd/MM/yyyy"
                  dayClassName={() => 'calendar-day'}
                  maxDate={dataHoje}
                  minDate={
                    new Date(new Date().setFullYear(dataHoje.getFullYear() - 1))
                  }
                  selectsEnd
                  customInput={
                    <CalendarInput
                      error={!dataPosicao}
                      data-testid="calendar-input-final"
                    />
                  }
                />
              </Grid.Item>
              <Grid.Item xs={1}>
                <TabelaCarteiraInvestimento
                  dadosLamina={dadosLamina}
                  setDadosLamina={setDadosLamina}
                  extratoUnificado={extratoUnificado}
                  isFetching={isFetching}
                  refetch={refetch}
                />
              </Grid.Item>
              <S.ContainerPrint>
                <S.ContainerGrafico>
                  <RenderConditional
                    condition={responseInvestimento?.toString() !== undefined}
                  >
                    <Grafico
                      titulo="Composição da Carteira"
                      textoAuxiliar={COMPOSICAO_CARTEIRA_TEXTO}
                      response={responseInvestimento}
                      alt="Gráfico - Investimento"
                    />
                  </RenderConditional>
                  <RenderConditional
                    condition={responseRentabilidade?.toString() !== undefined}
                  >
                    <Grafico
                      titulo="Relação Risco/Retorno*"
                      textoAuxiliar={RELACAO_RISCO_RETORNO_TEXTO}
                      response={responseRentabilidade}
                      alt="Gráfico - Rentabilidade"
                    />
                  </RenderConditional>
                </S.ContainerGrafico>
              </S.ContainerPrint>
              <TextoGraficos data={formatarData(dataPosicao)} />
              <Divider />
              <S.DisplayWidth className="hide-print" alignItems="center">
                <Button variant="outlined" onClick={goHome}>
                  Voltar
                </Button>
                <Button
                  variant="secondary"
                  loading={loadingImprimir}
                  disabled={loadingImprimir}
                  onClick={() =>
                    gerarArquivoImprimir(parseDateStringToDate(dataPosicao))
                  }
                >
                  Imprimir
                </Button>
                <EmailSenderModal objetoEmail={objetoEmail} />
              </S.DisplayWidth>
            </Grid>
          </Card.Content>
        </Card>
      </RenderConditional>
    </Display>
  );
};

export default PosicaoConsolidada;
