import styled from 'styled-components';

export const ContainerInfo = styled.div(({ theme: { color } }) => ({
  border: `1px solid ${color.line.primary}`,
  background: `${color.brandPrimary.medium}0d`,
  padding: 20,
  borderRadius: 8,
  marginTop: 20,
  marginBottom: 20,

  '& p': {
    fontWeight: 'bold',
  },
}));

export const ContainerInfoValor = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',

  '& p': {
    marginRight: 10,
  },
});
