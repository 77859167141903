export const INCLUSAO_ALTERACAO =
  'Inclua, exclua ou altere quanto o cliente quer destinar para cada beneficiário. No caso do falecimento do cliente os beneficiários receberão a reserva e, caso tenha o Cuidado Extra as indenizações.';

export const TEXTO_ATENCAO = {
  DADOS_PESSOAIS:
    'Os dados pessoais de dependentes, principalmente de crianças e adolescentes, são utilizados única e exclusivamente para a manutenção do contrato.',
  MORTE_TITULAR:
    '* Em caso de morte do titular, caso não haja beneficiário indicado, a Caixa Vida e Previdência seguirá a ordem de vocação hereditária',
  SALVAR_ALTERACOES: 'artigo 792 do Código Civil de 2002.',
  ARTIGO_CODIGO_CIVIL:
    'Para salvar todas as alterações realizadas clique no botão Confirmar alterações abaixo.',
};

export const HERDEIROS_LEGAIS = '** HERDEIROS LEGAIS';

export enum EXISTE_PERMISSAO_ASSINATURA {
  Sim = 'S',
  Nao = 'N',
}

export const TEXTO_INFORMATIVO_ASSINATURA = {
  PARAGRAFO_01:
    'Para finalizar o processo de alteração de beneficiário e ratificar que ela foi realizada com a anuência do cliente, um código de validação será enviado por SMS para o celular cadastrado.',
  PARAGRAFO_02:
    'Solicite o código ao cliente, faça a validação e, em seguida, a assinatura eletrônica. ',

  PARAGRAFO_03:
    'Com a assinatura eletrônica, não é necessário imprimir o comprovante nem fazer o envio para a Caixa Vida e Previdência.',
  PARAGRAFO_04:
    'Lembre-se, a alteração de beneficiário está disponível como autosserviço para os clientes via app ou área logada do site, sem necessidade de envio de documentos.',
};

export const TEXTO_IMPRIMIR_COMPROVANTE = {
  PARAGRAFO_01: 'Documento assinado digitalmente. ',
  PARAGRAFO_02:
    'Não é necessário o envio do comprovante para a Caixa Vida e Previdência.',

  PARAGRAFO_03:
    'Caso o cliente deseje, imprima ou envie uma cópia do comprovante por e-mail.',
  PARAGRAFO_04:
    ' É obrigatória a impressão do comprovante para assinatura do titular e o envio para a Seguradora da via correspondente. O não envio poderá tornar sem efeito a solicitação registrada.',
};
