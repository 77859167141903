import React from 'react';
import { Divider, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import InformacoesRenda from 'previdencia/features/SimulacaoRenda/components/InformacoesRenda';
import * as S from 'previdencia/features/SimulacaoRenda/components/InformacoesRenda/styles';
import RedirecionamentoUltimasSimulacoes from 'previdencia/features/SimulacaoRenda/components/RedirecionamentoUltimasSimulacoes';
import TabelaDadosSimulacao from 'previdencia/features/SimulacaoRenda/components/TabelaDadosSimulacao';
import TextoInformativoSimulacaoRenda from 'previdencia/features/SimulacaoRenda/components/TextoInformativoSimulacaoRenda';
import TextoInformativoTipoPagamento from 'previdencia/features/SimulacaoRenda/components/TextoInformativoTipoPagamento';
import TextoReceberRenda from 'previdencia/features/SimulacaoRenda/components/TextoReceberRenda';
import TextoRendaMensalLiquida from 'previdencia/features/SimulacaoRenda/components/TextoRendaMensalLiquida';
import TextoRendaSimulada from 'previdencia/features/SimulacaoRenda/components/TextoRendaSimulada';
import * as ResultadoSimulacaoConsts from 'previdencia/features/SimulacaoRenda/constants/resultadoSimulacao';
import * as RendaMensalConsts from 'previdencia/features/SimulacaoRenda/constants/textoRendaMensalLiquida';
import { useSimularMultipolosBeneficios } from 'previdencia/features/SimulacaoRenda/hooks/useSimularMultipolosBeneficios';
import {
  exibirTextoRendaMensalLiquida,
  exibirTextoReservaMinima,
} from 'previdencia/features/SimulacaoRenda/utils';

const ResultadoSimulacao: React.FC = () => {
  const { requestSimulacaoProgressivo, requestSimulacaoRegressivo } =
    useSimularMultipolosBeneficios();

  const simulacaoProgressivo = requestSimulacaoProgressivo.response?.entidade;
  const simulacaoRegressivo = requestSimulacaoRegressivo.response?.entidade;

  const codigoTipoPagamento = simulacaoProgressivo?.codTipoPagamento;

  const simulacaoDisponivel = tryGetValueOrDefault(
    [simulacaoProgressivo],
    simulacaoRegressivo,
  );

  if (
    checkIfSomeItemsAreTrue([
      requestSimulacaoProgressivo.loading,
      requestSimulacaoRegressivo.loading,
    ])
  ) {
    return <SkeletonLoading blocks={1} />;
  }

  return (
    <>
      <Text variant="body02-md" margin>
        {ResultadoSimulacaoConsts.TITULO}
      </Text>

      <InformacoesRenda
        simulacaoProgressivo={simulacaoProgressivo}
        simulacaoRegressivo={simulacaoRegressivo}
      />

      <Divider />
      <RenderConditional
        condition={exibirTextoReservaMinima(simulacaoDisponivel)}
      >
        <TextoRendaSimulada dados={simulacaoDisponivel} />
        <Divider />
      </RenderConditional>

      <RenderConditional
        condition={exibirTextoRendaMensalLiquida(simulacaoDisponivel)}
      >
        <Text variant="body02-sm" margin>
          <strong>{RendaMensalConsts.TITULO}</strong>
        </Text>
        <S.Resultado>
          <RenderConditional
            condition={exibirTextoRendaMensalLiquida(simulacaoProgressivo)}
          >
            <S.ResultadoItem>
              <TextoRendaMensalLiquida dados={simulacaoProgressivo} />
            </S.ResultadoItem>
          </RenderConditional>
          <RenderConditional
            condition={exibirTextoRendaMensalLiquida(simulacaoRegressivo)}
          >
            <S.ResultadoItem>
              <TextoRendaMensalLiquida dados={simulacaoRegressivo} />
            </S.ResultadoItem>
          </RenderConditional>
        </S.Resultado>
        <Divider />
      </RenderConditional>

      <RenderConditional condition={!!simulacaoDisponivel?.codTipoPagamento}>
        <TextoInformativoTipoPagamento dados={simulacaoDisponivel} />
        <Divider />
      </RenderConditional>

      <TabelaDadosSimulacao
        simulacaoProgressivo={simulacaoProgressivo}
        simulacaoRegressivo={simulacaoRegressivo}
      />

      <Divider />

      <TextoReceberRenda />

      <Divider />

      <TextoInformativoSimulacaoRenda
        codigoTipoPagamento={codigoTipoPagamento}
      />

      <Divider />

      <RedirecionamentoUltimasSimulacoes />
    </>
  );
};

export default ResultadoSimulacao;
