import { FormikProps } from 'formik';
import { isCnpj, isCpf } from 'main/utils/cpf_cnpj';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import * as CONSTS from 'seguros/constants/DadosSegurado';
import * as SEGURADO_TYPES from 'seguros/types/DadosSegurado';
import * as RESPONSE_TYPES from 'seguros/types/DadosSeguradoResponse';

export const dynamicInfoInsured = ({
  cpfCnpj,
  seguradoPF,
  seguradoPJ,
}: SEGURADO_TYPES.DynamicInfoInsured): string => {
  if (isCpf(cpfCnpj)) return seguradoPF;

  if (isCnpj(cpfCnpj)) return seguradoPJ;

  return seguradoPF;
};

export const checkIfOptionIsDefaultEmpty = (
  item = '',
  defaultValue = '-',
): string =>
  item === CONSTS.DEFAULT_VALUE.DEFAULT_EMPTY_API_SEGURADO
    ? defaultValue
    : item;

export const customValidationCepFormik = (
  values: SEGURADO_TYPES.IFormikValues,
  cidadeValida: boolean,
): string | null => {
  if (!values.cep.length) return CONSTS.VALIDACAO.CAMPO_OBRIGATORIO;

  if (values.cep.length < CONSTS.DEFAULT_VALUE.QTD_MAXIMA_DIGITOS_CEP)
    return CONSTS.VALIDACAO.QTD_MINIMA_CEP;

  if (!cidadeValida) return CONSTS.VALIDACAO.CEP_INVALIDO;

  return null;
};

export const permitirConsentimento = (
  dados: RESPONSE_TYPES.IConsultarConsentimentoResponse | undefined,
  subprocesso: string,
): boolean => {
  const tipoConsentimento = dados?.consentimentos.find(
    e => e.subProcessoNegocio.codigo === Number(subprocesso),
  );

  return (
    tipoConsentimento?.consentimento.tipo ===
    Number(CONSTS.DEFAULT_VALUE.SUB_PROCESSO_PARCEIROS)
  );
};

export const showErrorInput = (
  formik: FormikProps<SEGURADO_TYPES.IFormikValues>,
  field: string,
) => {
  const formikErrorField = formik.errors[field as keyof typeof formik.values];

  if (
    checkIfAllItemsAreTrue([
      !!formik.touched[field as keyof typeof formik.values],
      !!formikErrorField,
    ])
  ) {
    return {
      error: formikErrorField,
      errorMessage: formikErrorField,
    };
  }

  return {};
};
