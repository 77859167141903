import {
  FilterTypes,
  IFilterOption,
} from 'main/components/Table/TableFilter/IFilterOption';
import { formatarData } from 'main/utils';
import * as ENUM_TYPES from 'registroOcorrenciaASC/types/enum';
import * as RESPONSE_TYPES from 'registroOcorrenciaASC/features/consultarOcorrencia/types/ConsultarOcorrenciaResponse';
import * as CONSULTA_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/consultarOcorrencia/types/ConsultarOcorrencia';
import * as S from 'registroOcorrenciaASC/features/consultarOcorrencia/pages/ConsultarOcorrencia/styles';

export const DEFAULT_VALUE = {
  CODIGO_NIVEL: 2,
  DESCRICAO_CANAL: 'Web - CAIXA',
  CLASSIFICACAO: 'Resposta',
};

export const COLUNAS_TABELA_HISTORICO_SOLICITACAO = (
  irParaDetalhesOcorrencia: ({
    numeroSolicitacao,
    cpfCnpj,
  }: CONSULTA_OCORRENCIA_TYPES.IrParaDetalhesOcorrenciaParams) => void,
) => [
  {
    name: 'Protocolo',
    selector: 'numeroProtocolo',
    center: true,
    minWidth: '170px',
  },
  {
    name: 'Solicitação',
    selector: 'numeroSolicitacaoBtn',
    center: true,
    minWidth: '150px',
    cell: (row: RESPONSE_TYPES.IHistoricoSolicitacaoRetorno) => (
      <S.TableLink
        variant="body02-sm"
        onClick={() =>
          irParaDetalhesOcorrencia({
            numeroSolicitacao: row.numeroSolicitacao,
            cpfCnpj: row.numeroCpfCnpjCliente,
            numeroContrato: row.numeroContrato,
          })
        }
      >
        {row.numeroSolicitacao}
      </S.TableLink>
    ),
  },
  {
    name: 'Nome Cliente',
    selector: 'nomeCliente',
    center: true,
    wrap: true,
    minWidth: '150px',
  },
  {
    name: 'Situação',
    selector: 'statusSolicitacao',
    center: true,
    wrap: true,
  },
  {
    name: 'Assunto',
    selector: 'descricaoAssunto',
    center: true,
    wrap: true,
    minWidth: '200px',
  },
  {
    name: 'Segmento',
    selector: 'descricaoSegmento',
    center: true,
  },
  {
    name: 'Abertura',
    selector: 'dataCriacao',
    center: true,
    minWidth: '130px',
    cell: (row: RESPONSE_TYPES.IHistoricoSolicitacaoRetorno) =>
      formatarData(row.dataCriacao),
  },
];

export const FILTRO_OPCOES_TABELA_HISTORICO_SOLICITACAO: IFilterOption[] = [
  {
    key: 'descricaoSegmento',
    value: 'Segmento',
    type: FilterTypes.TEXT,
  },
  {
    key: 'numeroProtocolo',
    value: 'Protocolo',
    type: FilterTypes.TEXT,
  },
  {
    key: 'numeroSolicitacao',
    value: 'Solicitação',
    type: FilterTypes.TEXT,
  },
];

export const NAO_HA_DADOS_TABELA = 'Não há dados para serem exibidos.';

export const TEXTOS_DETALHES_OCORRENCIA = {
  NUMERO_PROTOCOLO: 'Número de Protocolo',
  NUMERO_SOLICITACAO: 'Número da Solicitação',
  ASSUNTO: 'Assunto',
  CPF_CNPJ: 'CPF/CNPJ do Cliente',
  INTERLOCUTOR: 'Interlocutor',
  DATA_REGISTRO: 'Data de Registro',
  SITUACAO: 'Situação',
  CANAL_ABERTURA: 'Canal de Abertura',
  MENSAGEM: 'Mensagem',
};

export const TEXTOS_COMPLEMENTOS = {
  LABEL_DATA_CADASTRO: 'Data de Cadastro:',
  LABEL_COMPLEMENTO: 'Complemento:',
  MSG_NENHUM_COMPLEMENTO: 'Nenhum complemento adicionado à esta ocorrência.',
};

export const TEXTOS_ANEXOS = {
  LABEL_ARQUIVO: 'Arquivo:',
  LABEL_TAMANHO: 'Tamanho:',
  LABEL_DATA: 'Data:',
  MSG_NENHUM_ANEXO: 'Nenhum anexo adicionado à esta ocorrência.',
};

export const TEXTOS_ADICIONAR_COMPLEMENTO = {
  DESCRICAO: `Se necessário, preencha o formulário abaixo e clique em
  'Complementar' para adicionar informações à Ocorrência.`,
  LABEL_TEXTAREA: 'Complemento*',
  CHARACTERES_RESTANTES_TEXTAREA: 'Caracteres restantes:',
  TAMANHO_MAX_ANEXO: 'Tamanho máximo do arquivo excedido.',
  MSG_COMPLEMENTO_ADICIONADO: 'Novo complemento adicionado com sucesso.',
};

export const INITIAL_FORMIK_STATE_DETALHES_OCORRENCIA = {
  conteudoTextarea: '',
  quantidadeCaracteresTextarea:
    ENUM_TYPES.DefaultValue.QTD_MAX_CARACTERES_TEXTAREA_REGISTRAR_COMPLEMENTO,
};

export const VALIDATION_DEFAULT_MESSAGE_SCHEMA = {
  CAMPO_OBRIGATORIO: 'Campo obrigatório.',
};

export const STATUS_SOLICITACAO_LEITURA = [
  'Resolvida',
  'Cancelada',
  'Closed',
  'Canceled',
];

export const STATUS_SOLICITACAO_CONCLUSAO = ['Resolvida', 'Closed'];
