import * as Yup from 'yup';
import * as CONSTS from 'seguros/constants/AlteracaoValorCapital';

export const formikAlteracaoValorCapitalSchema = Yup.object().shape({
  valorCapitalSeguradoCoberturaMorte: Yup.string().required(
    CONSTS.VALIDACAO_CAMPOS.CAMPO_OBRIGATORIO,
  ),
  emailCliente: Yup.string()
    .email(CONSTS.VALIDACAO_CAMPOS.EMAIL_INVALIDO)
    .required(CONSTS.VALIDACAO_CAMPOS.CAMPO_OBRIGATORIO),
  localData: Yup.string().required(CONSTS.VALIDACAO_CAMPOS.CAMPO_OBRIGATORIO),
  nomeCompletoRepresentanteLegal: Yup.string().required(
    CONSTS.VALIDACAO_CAMPOS.CAMPO_OBRIGATORIO,
  ),
  cpfRepresentanteLegal: Yup.string().required(
    CONSTS.VALIDACAO_CAMPOS.CAMPO_OBRIGATORIO,
  ),
  emailRepresentanteLegal: Yup.string()
    .email(CONSTS.VALIDACAO_CAMPOS.EMAIL_INVALIDO)
    .required(CONSTS.VALIDACAO_CAMPOS.CAMPO_OBRIGATORIO),
});
