import { useEffect } from 'react';
import { Card, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import HistoricoSimulacoes from 'previdencia/features/SimulacaoRenda/components/HistoricoSimulacoes';
import IndicacaoTipoRenda from 'previdencia/features/SimulacaoRenda/components/IndicacaoTipoRenda';
import InformacoesAdicionaisTipoRenda from 'previdencia/features/SimulacaoRenda/components/InformacoesAdicionaisTipoRenda';
import ResultadoSimulacao from 'previdencia/features/SimulacaoRenda/components/ResultadoSimulacao';
import { DADOS_INICIAIS_SIMULACAO_RENDA } from 'previdencia/features/SimulacaoRenda/constants/constants';
import { useParametrosRegimeTributario } from 'previdencia/features/SimulacaoRenda/hooks/useParametrosRegimeTributario';
import { useSimulacaoRendaContextData } from 'previdencia/features/SimulacaoRenda/hooks/useSimulacaoRendaContextData';
import { EtapasFluxoSimulacaoRenda } from 'previdencia/features/SimulacaoRenda/types/enum';
import { verificarSePodeAlterarRegimeTributario } from 'previdencia/features/SimulacaoRenda/utils/simulacaoRenda';
import ModalConsultaSimulacao from 'previdencia/features/SimulacaoRenda/components/ModalConsultaSimulacao/ModalConsultaSimulacao';

export const FluxoSimulacaoRenda: React.FC = () => {
  const { featureData, setFeatureData, clearFeatureData } =
    useSimulacaoRendaContextData();

  const { response: responseRegimeTributario } =
    useParametrosRegimeTributario();

  useEffect(() => {
    setFeatureData(DADOS_INICIAIS_SIMULACAO_RENDA);
    return () => {
      clearFeatureData();
    };
  }, []);

  useEffect(() => {
    const podeAlterarRegimeTributario = verificarSePodeAlterarRegimeTributario(
      responseRegimeTributario,
    );

    setFeatureData({
      ...featureData,
      podeAlterarRegimeTributario,
    });
  }, [responseRegimeTributario]);

  const fluxosTela = [
    <IndicacaoTipoRenda />,
    <InformacoesAdicionaisTipoRenda />,
    <ResultadoSimulacao />,
    <HistoricoSimulacoes />,
  ];

  const etapaAtual = tryGetValueOrDefault(
    [featureData?.etapaAtual],
    EtapasFluxoSimulacaoRenda.INICIO_SIMULACAO,
  );

  return (
    <RenderConditional condition={featureData !== null}>
      <FeatureAuthorizer
        requiredRoles={[...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA]}
        requiredPermissions={[PREV_PERMISSIONS.SIMULADOR_DE_RENDA]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Text variant="headline-05" color="primary" margin>
              Simulação de Renda
            </Text>
            {fluxosTela[etapaAtual]}
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
      <ModalConsultaSimulacao />
    </RenderConditional>
  );
};
