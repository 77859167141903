import { useState, useContext } from 'react';
import { ResponseEmissaoCertificado } from 'previdencia/features/EmissaoCertificado/types/ResponseEmissaoCertificado';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { AppContext } from 'main/contexts/AppContext';
import { SendEmailRequest } from 'main/types/EmailSender/IEmailSender';
import { usePeco } from 'main/hooks/usePeco';
import { ParametrosEmissaoSegundaViaCertificado } from '../types/ParametrosEmissaoSegundaViaCertificado';
import { PECOS } from 'previdencia/config/endpoints';
import { UseObterEmissaoCertificado } from '../types/UseObterEmissaoCertificado';

const useObterEmissaoCertificado = (): UseObterEmissaoCertificado => {
  const [data, setData] = useState<string>();

  const { cliente } = useContext(AppContext);

  const { fetchData, loading } = usePeco<
    ParametrosEmissaoSegundaViaCertificado,
    ResponseEmissaoCertificado
  >({
    api: { operationPath: PECOS.ObterCertificado },

    autoFetch: false,
  });

  const emitirCertificado = async (
    cpfCnpj: string,
    numeroCertificado: string,
  ): Promise<ResponseEmissaoCertificado | undefined> => {
    try {
      const response = await fetchData({ cpfCnpj, numeroCertificado });
      setData(response?.entidade);
      return data;
    } catch (error: unknown) {
      return undefined;
    }
  };

  const montarObjetoEmail = (): SendEmailRequest => {
    return {
      tipoEmail: tipoEmailConstants.SEGUNDA_VIA_CERTIFICADO,
      parametrosEnvio: {
        cpfCnpj: cliente.cpfCnpj,
        numeroCertificado: cliente.numCertificado,
      },
    };
  };

  return {
    data,
    loading,
    emitirCertificado,
    montarObjetoEmail,
  };
};

export default useObterEmissaoCertificado;
