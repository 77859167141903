import React from 'react';
import { Grid, Text, Divider } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { formatarData } from 'main/utils/date';
import Table from 'main/components/Table';
import BotaoGerarBoleto from '../BotaoGerarBoleto';
import * as S from './styles';
import { ContribuicoesConvertido } from '../../types/ResponseContribuicoes';
import { colunas, STATUS_COBRANCA_CONVERTIDO } from '../../constants/constants';
import { obterMeioPagamento } from '../../utils/contribuicoes';
import { CONFIRA_EXTRATO, EMITIR_SEGUNDA_VIA } from '../../constants/texts';

const ContribuicoesLista: React.FC<{
  contribuicoesLista?: ContribuicoesConvertido[];
}> = ({ contribuicoesLista }) => {
  const colunaBoleto = (
    permiteEmissaoBoleto: string,
    status: string,
    numCobranca: string,
    linhaDigitavel: string,
  ) => {
    if (permiteEmissaoBoleto === 'S') {
      return (
        <BotaoGerarBoleto
          numeroCobranca={numCobranca}
          linhaDigitavel={linhaDigitavel}
        />
      );
    }
    if (status !== STATUS_COBRANCA_CONVERTIDO.PAGA) {
      return (
        <>
          <p>Entrar em contato com a</p>
          <p>Central de Relacionamento</p>
          <p>e reprogramar o débito,</p>
          <p>0800 702 4000.</p>
        </>
      );
    }
    return '-';
  };
  const montarData = () => {
    return (
      contribuicoesLista?.map(
        ({
          valorCobrado,
          dataBaixa,
          dataVencimento,
          status,
          descStatus,
          permiteEmissaoBoleto,
          numCobranca,
          linhaDigitavel,
          codMeioPagamento,
        }) => ({
          dataVencimento: formatarData(dataVencimento),
          dataPagamento:
            status === STATUS_COBRANCA_CONVERTIDO.PAGA
              ? formatarData(dataBaixa)
              : '-',
          formaPagamento: obterMeioPagamento(codMeioPagamento),
          valorContribuicao: formatarValorPadraoBrasileiro(valorCobrado),
          statusRequisicao: descStatus,
          boleto: colunaBoleto(
            permiteEmissaoBoleto,
            status,
            numCobranca,
            linhaDigitavel,
          ),
        }),
      ) ?? []
    );
  };
  return (
    <S.Contribuicoes>
      <Grid>
        <Grid.Item xs={1}>
          <br />
          <Text variant="body02-sm" color="text" margin>
            {CONFIRA_EXTRATO}
          </Text>
          <Text variant="body02-sm" color="text">
            {EMITIR_SEGUNDA_VIA}
          </Text>
          <Divider />
        </Grid.Item>
        <Grid.Item xs={1}>
          <Table
            noHeader
            responsive
            data={montarData()}
            columns={colunas}
            noDataComponent="Não há dados para exibir."
            pagination
            paginationPerPage={10}
            paginationComponentOptions={{
              rowsPerPageText: 'Items por página',
              rangeSeparatorText: 'de',
            }}
          />
        </Grid.Item>
      </Grid>
    </S.Contribuicoes>
  );
};

ContribuicoesLista.defaultProps = {
  contribuicoesLista: undefined,
};

export default ContribuicoesLista;
