import React from 'react';
import Accordion from 'main/components/PEComponents/Accordion/Accordion';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { TableSemHeader } from 'seguros/components/TabelaApoliceCoberturas/styles';
import { TabelaApoliceCarenciasProps } from 'seguros/types/ApoliseCobertura';
import { ICarencia } from 'seguros/types/IResponseCertificadosResumo';
import * as CONSTS from 'seguros/constants/TabelaApoliceCarencias';

export const TabelaApoliceCarencias: React.FC<
  TabelaApoliceCarenciasProps<ICarencia>
> = ({ data, columns }) => {
  return (
    <RenderConditional condition={!!data.length}>
      <Accordion open className="hide-print">
        <Accordion.Item title="Carências" key="carencias">
          <TableSemHeader
            noHeader
            responsive
            striped
            highlightOnHover
            columns={tryGetValueOrDefault(
              [columns],
              CONSTS.COLUNAS_TABELA_CARENCIAS,
            )}
            data={data}
            noDataComponent={CONSTS.SEM_DADOS_CARENCIAS}
          />
        </Accordion.Item>
      </Accordion>
    </RenderConditional>
  );
};
