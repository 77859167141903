import styled from 'styled-components/macro';

export const RiskBarContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'start',
  width: '100%',
});

export const RiskProfileLabel = styled.p({
  fontSize: '10px',
  paddingBottom: '10px',
});

export const Bar = styled.div({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '10px',
  background:
    'linear-gradient(270deg, #bb1f40 0%, #f2a206 42.64%, #b3cf42 89.56%);',
});

export const StatusIndicatorSection = styled.div<{ risk: number }>(
  ({ risk }) => ({
    position: 'absolute',
    top: '3px',
    bottom: 0,
    left: `calc((99% - 12px) * ${risk} / 100)`,
    width: '12px',
    height: '12px',
  }),
);

export const TriangleIcon = styled.div(({ theme }) => ({
  overflow: 'visible !important',
  width: 'fit-content',
  height: 'fit-content',
  borderLeft: '6px solid transparent',
  borderRight: '6px solid transparent',
  borderBottom: `12px solid ${theme.color.neutral['03']}`,
}));

const formatRiskAlign = (level: number) => {
  if (level === 1) {
    return 'left';
  }

  if (level === 2) {
    return 'center';
  }

  return 'right';
};

export const InvestorTypeLabel = styled.label<{ level: number }>(
  ({ theme, level }) => ({
    position: 'relative',
    top: '16px',
    textAlign: formatRiskAlign(level),
    color: theme.color.neutral['03'],
    fontSize: '10px',
    fontWeight: 500,
    padding: '0 20px',

    [theme.breakpoint.md()]: {
      padding: 0,
    },
  }),
);
