import { Grid, Text } from '@cvp/design-system/react';

import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import * as EVOLUCAO_PATRIMONIAL_TYPES from 'evolucaoPatrimonial/types/EvolucaoPatrimonial';
import * as S from './styles';

const CardVisaoGeral = ({
  dadosConsulta,
}: EVOLUCAO_PATRIMONIAL_TYPES.ICardVisaoGeralProps) => {
  return (
    <S.ContainerInfo>
      <Grid>
        <Grid.Item xs={1}>
          <Text variant="body02-lg" color="text">
            Acumulado no período
          </Text>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2}>
          <Text variant="body02-sm" color="text">
            Patrimônio final
          </Text>
          <Text variant="body02-sm" color="text">
            <strong>
              {tryGetMonetaryValueOrDefault(dadosConsulta?.patrimonio, '-')}
            </strong>
          </Text>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2}>
          <Text variant="body02-sm" color="text">
            Rentabilidade
          </Text>
          <Text variant="body02-sm" color="text">
            <strong>
              {tryGetMonetaryValueOrDefault(
                dadosConsulta?.percentualRentabilidade,
                '-',
              )}
            </strong>
          </Text>
        </Grid.Item>
      </Grid>
    </S.ContainerInfo>
  );
};

export default CardVisaoGeral;
