import styled from 'styled-components/macro';
import { Display, Text } from '@cvp/design-system/react';

export const TituloFormulario = styled(Text)({
  marginTop: '2rem',
});

export const ContainerFormulario = styled(Display)({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  columnGap: '10px',
});

export const ContainerColuna = styled(Display)({
  margin: '0px',
});

export const ContainerConta = styled(Display)({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  columnGap: '10px',
  margin: '0px',
});
