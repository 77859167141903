import { api, IApiResponse } from 'main/services';
import { parseDateStringToDate } from 'main/utils';
import { removeEmptySpaceAndSpecialCharacters } from 'main/utils/string';
import {
  obterEndpointListarDocumentosIncluidos,
  obterEndpointUploadDocumento,
} from '../config/endpoints';
import { IConsultarStatusUploadRequest } from '../types/IConsultarStatusUploadForm';
import { IEntradaUploadDocumento } from '../../../types/IEntradaUploadDocumento';
import { IListarDocumentosIncluidosReponse } from '../types/IListarDocumentosIncluidosResponse';

export const obterListaDocumentosIncluidos = async (
  payload: IConsultarStatusUploadRequest,
) => {
  const resultado = await api.post<
    IApiResponse<IListarDocumentosIncluidosReponse[]>
  >(obterEndpointListarDocumentosIncluidos(), {
    ...payload,
    dataInicial: parseDateStringToDate(payload.dataInicial),
    dataFinal: parseDateStringToDate(payload.dataFinal),
  });
  return resultado.data;
};

const formatarNomeArquivo = (nomeArquivo: string) => {
  const nomeArquivoQuebrado = nomeArquivo.split('.');
  const nomeArquivoFormatado = removeEmptySpaceAndSpecialCharacters(
    nomeArquivoQuebrado[0],
  );
  return `${nomeArquivoFormatado}.${nomeArquivoQuebrado[1]}`;
};

export const realizarUploadAnexo = async (
  entrada: IEntradaUploadDocumento,
  files: FileList,
) => {
  const data = new FormData();
  const file = files[0];
  data.append('Arquivo', file);
  data.append('NomeDocumento', formatarNomeArquivo(file.name));
  data.append('CpfCnpj', entrada.cpfCnpj);
  data.append('CodContrato', entrada.codContrato);
  data.append('DesSistemaOrigem', entrada.desSistemaOrigem);
  data.append('DtaVenda', parseDateStringToDate(entrada.dtaVenda));
  data.append('NumOperacao', entrada.numOperacao);

  const result = await api.post<IApiResponse<unknown>>(
    obterEndpointUploadDocumento(),
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return result.data;
};
