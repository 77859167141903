import { obterEndpointImprimirPosicaoConsolidada } from 'previdencia/config/endpoints';
import { api } from 'main/services';

export const obterArquivoImprimir = async (
  cpfCnpj: string,
  dataBusca: string,
): Promise<string> => {
  const { data } = await api.post(
    obterEndpointImprimirPosicaoConsolidada(),
    {
      cpf: cpfCnpj,
      dataInicial: dataBusca,
      dataFinal: dataBusca,
    },
    { responseType: 'text' },
  );

  return data;
};
