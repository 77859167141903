import React from 'react';
import { Text, Display, Button } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import { aplicarMascaraTelefone } from 'main/utils/phone';
import { ModalWithoutCloseButton } from './styles';

type ModalAlertaContatosProps = {
  open: boolean;
  onClose: () => void;
  redirect: () => void;
  dados: {
    email?: string;
    telefone?: string;
  };
};
const ModalAlertaContatos: React.FC<ModalAlertaContatosProps> = ({
  open,
  onClose,
  redirect,
  dados,
}) => {
  return (
    <ModalWithoutCloseButton show={open} onClose={() => onClose()} small>
      <Text margin color="secondary">
        <strong>
          <Icon name="warning" /> Atenção!
        </strong>
      </Text>
      <Display type="display-block">
        <Text variant="caption-02" margin align="left">
          Antes de prosseguir, confirme se o número do celular e o e-mail do
          cliente estão atualizados.
        </Text>
        <Text variant="caption-02" margin align="left">
          Eles são{' '}
          <Text color="secondary" display="inline" variant="caption-02">
            OBRIGATÓRIOS
          </Text>{' '}
          para concluir o processo de assinatura eletrônica.
        </Text>
      </Display>
      <Display justify="center">
        <div>
          <span>
            <strong> E-mail:</strong>
          </span>
          <Text variant="body-medium3">
            {dados?.email ? dados?.email?.toLowerCase() : 'Não informado'}
          </Text>
        </div>
      </Display>
      <Display justify="center">
        <div>
          <span>
            <strong> Telefone:</strong>
          </span>
          <Text variant="body-medium3">
            {dados?.telefone
              ? aplicarMascaraTelefone(dados?.telefone)
              : 'Não informado'}
          </Text>
        </div>
      </Display>
      <Display justify="center">
        <Button variant="outlined" onClick={redirect} data-testid="cancelar">
          Atualizar
        </Button>
        <Button variant="primary" onClick={onClose} data-testid="submit">
          Continuar
        </Button>
      </Display>
    </ModalWithoutCloseButton>
  );
};

export default ModalAlertaContatos;
