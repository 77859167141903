import { Button, Text, Display } from '@cvp/design-system/react';
import { formatarData } from 'main/utils';
import {
  ModalObservacoesAndamentoProps,
  ObservacaoAndamento,
} from 'sinistro/features/statusSinistro/types/ModalObservacoes';
import { normalizarFormatoSeparadorData } from 'sinistro/features/statusSinistro/utils/normalizarFormatoSeparadorData';
import { TipoProduto } from 'sinistro/features/statusSinistro/types/enum';
import * as S from 'sinistro/features/statusSinistro/components/ModalObservacoesAndamento/styles';

const ModalObservacoesAndamento = ({
  data,
  exibirBotaoFecharModal,
  handleClose,
}: ModalObservacoesAndamentoProps): React.ReactElement => {
  return (
    <S.ModalObservacoesAndamento
      show={data.open}
      showCloseButton={exibirBotaoFecharModal}
      onClose={() => handleClose()}
    >
      <Text variant="body02-md" margin>
        <strong>Observações</strong>
      </Text>
      {data.observacoesAndamento.map(
        ({ type, dataInclusao, descricao }: ObservacaoAndamento) => (
          <>
            <Text variant="body-medium3" align="left">
              <strong>Data:</strong>{' '}
              {type === TipoProduto.VIDA
                ? formatarData(dataInclusao)
                : normalizarFormatoSeparadorData(dataInclusao)}
            </Text>
            <S.TextWithMargin variant="body-medium3" align="left">
              <strong>Descrição:</strong> {descricao}
            </S.TextWithMargin>
          </>
        ),
      )}

      <Display justify="center">
        <Button
          variant="secondary"
          onClick={handleClose}
          data-testid="cancelar"
        >
          Certo, entendi
        </Button>
      </Display>
    </S.ModalObservacoesAndamento>
  );
};

export default ModalObservacoesAndamento;
