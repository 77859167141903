import { useContext } from 'react';
import { usePeco } from 'main/hooks/usePeco';
import { AppContext } from 'main/contexts/AppContext';
import { getTimer60Minutos } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { PECOS_PRESTAMISTA } from 'prestamista/config/endpoints';
import {
  RequestObterDadosCertificadoPrestamista,
  ResponseObterDadosCertificadoPrestamista,
} from 'prestamista/types/ICertifucatiPrestamista';

export const usePecoCertificadoPrestamista = (dynamicCache?: string) => {
  const { clientePrestamista } = useContext(AppContext);

  const certificadoPrestamista = usePeco<
    Partial<RequestObterDadosCertificadoPrestamista>,
    ResponseObterDadosCertificadoPrestamista[]
  >({
    api: {
      operationPath: PECOS_PRESTAMISTA.DetalhesSeguroPrestamista,
    },
    autoFetch: false,
    cache: true,
    cacheTime: getTimer60Minutos(),
    cacheKey: `${clientePrestamista.cpfCnpj},${
      clientePrestamista.numeroContrato
    },${tryGetValueOrDefault([dynamicCache], '')}`,
    payload: {
      cpfCnpj: tryGetValueOrDefault([clientePrestamista?.cpfCnpj], '0'),
      numeroContrato: tryGetValueOrDefault(
        [clientePrestamista?.numeroContrato],
        '0',
      ),
    },
  });

  const dadosCertificadoPrestamista = tryGetValueOrDefault(
    certificadoPrestamista?.response?.entidade as [
      ResponseObterDadosCertificadoPrestamista | undefined,
    ],
    undefined,
  );

  return {
    certificadoPrestamista,
    dadosCertificadoPrestamista,
  };
};
