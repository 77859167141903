import { formatarData } from 'main/utils';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import {
  ICertificadoProdutoSelecionado,
  IProdutoSinistroVida,
} from 'sinistro/features/statusSinistro/types/IStatusSinistro';
import { LinhaTempoSinistroDados } from 'sinistro/features/statusSinistro/types/StatusSinistroComponentProps';

export function timelineVidaFactory(
  sinistroVida: IProdutoSinistroVida[] | undefined,
  produtoSelecionado: ICertificadoProdutoSelecionado,
): LinhaTempoSinistroDados[] {
  const eventos = tryGetValueOrDefault(
    [
      sinistroVida?.find(produto => {
        const codigoFonte = `${produto.codigoFonte}`.padStart(3, '0');
        const numeroAvisoSinistro = `${codigoFonte}${produto.numeroProtocoloSinistro}`;

        const verificaCertificado =
          String(produto.numeroCertificado) === produtoSelecionado.certificado;
        const verificaSinistro =
          numeroAvisoSinistro === produtoSelecionado.numeroAvisoSinistro;

        return checkIfAllItemsAreTrue([verificaCertificado, verificaSinistro]);
      })?.timeline?.timeline,
    ],
    [],
  );

  return eventos.map(evento => {
    return {
      data: formatarData(evento.dataStatus),
      descricaoStatus: evento.descricaoDetalhadaStatus,
      descricaoCobertura: '',
      textoAuxiliarStatus: evento.descricaoComplementar,
    } as LinhaTempoSinistroDados;
  });
}
