import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { DefaultParamRequest } from 'previdencia/types/enum';
import {
  IPayloadCalcularBeneficioProtecao,
  IPayloadCalcularBeneficioProtecaoPeco,
  IResponseCalcularBeneficioProtecao,
} from '../types/CalcularBaneficioProtecaoTypes';

const useCalcularBeneficioProtecao = (
  payloadCalcular: IPayloadCalcularBeneficioProtecaoPeco,
) => {
  const {
    cliente: { numCertificado },
  } = useContext(AppContext);
  const { response, loading, fetchData } = usePeco<
    IPayloadCalcularBeneficioProtecao,
    IResponseCalcularBeneficioProtecao
  >({
    api: {
      operationPath: PECOS.CalcularBeneficioProtecao,
    },
    autoFetch: false,
    payload: {
      codigoEmpresa: DefaultParamRequest.CODIGO_EMPRESA,
      codigoCertificado: numCertificado,
      ...payloadCalcular,
    },
  });

  return { response, loading, fetchData };
};

export default useCalcularBeneficioProtecao;
