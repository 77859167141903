import React from 'react';
import { Card } from '@cvp/design-system/react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { RELATORIO_CVP } from 'relatorios/config/relatoriosConfig';
import {
  RenderContentEmbed,
  DisclaimerError,
} from 'relatorios/features/powerbi/components';
import { usePowerBI } from '../hooks/usePowerBI';

const DashboardUploadPrestamista: React.FC = () => {
  const { eventHandlersMap, reportConfig, loading, reportSettingsLoaded } =
    usePowerBI(RELATORIO_CVP);

  return (
    <>
      {loading ? (
        <SkeletonLoading lines={20} data-testid="skeletonLoading" />
      ) : (
        <Card>
          <Card.Content>
            <DisclaimerError show={!reportSettingsLoaded} />
            <RenderContentEmbed
              eventHandlersMap={eventHandlersMap}
              reportConfig={reportConfig}
              reportSettingsLoaded={reportSettingsLoaded}
            />
          </Card.Content>
        </Card>
      )}
    </>
  );
};

export default DashboardUploadPrestamista;
