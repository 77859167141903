import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Display,
  Grid,
  Hidden,
  Modal,
  Text,
} from '@cvp/design-system/react';
import ilustracaoSvg from 'assets/img/ilustracoes/prev/milton.svg';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { dadosResumoPrestamistaFactory } from 'prestamista/factories/dadosResumoPrestamistaFactory';
import { usePecoCertificadoPrestamista } from 'prestamista/hooks/usePecoCertificadoPrestamista';
import usePrestNavigation from 'prestamista/hooks/usePrestNavigation';
import { InfoGridItem } from 'seguros/components/CardResumoProduto/InfoGridItem/InfoGridItem';
import TagStatusSeguro from 'seguros/components/TagStatusSeguro';
import * as S from 'prestamista/components/CardResumoPrestamista/styles';

export const CardResumoPrestamista: React.FC = () => {
  const { goHome } = usePrestNavigation();

  const [consultaFeita, setConsultaFeita] = useState(false);

  const {
    certificadoPrestamista: { response, loading, fetchData },
    dadosCertificadoPrestamista,
  } = usePecoCertificadoPrestamista();

  useEffect(() => {
    fetchData();
    setConsultaFeita(true);
  }, []);

  if (loading) return <SkeletonLoading lines={4} />;

  const dadosCertificado = dadosResumoPrestamistaFactory(
    dadosCertificadoPrestamista,
  );

  const showModal = checkIfAllItemsAreTrue([
    !response?.entidade,
    !loading,
    consultaFeita,
  ]);
  return (
    <>
      <Display type="display-block">
        <Card>
          <Card.Content padding={[6, 8, 6, 8]}>
            <Grid>
              <Grid.Item xs={1}>
                <Display alignItems="center">
                  <Text variant="headline-05" color="primary">
                    {dadosCertificado?.descricaoProduto}
                  </Text>
                  <TagStatusSeguro
                    status={dadosCertificado.descricaoStatusCertificado}
                  />
                </Display>
              </Grid.Item>
              <Hidden only={['sm', 'xs', 'md', 'lg']}>
                <Grid.Item lg={1 / 10} xl={1 / 10}>
                  <Display
                    style={{ height: '100%' }}
                    justify="center"
                    alignItems="center"
                  >
                    <img src={ilustracaoSvg} alt="Ilustração Apólice" />
                  </Display>
                </Grid.Item>
              </Hidden>
              <Grid.Item lg={9 / 10} md={1}>
                <Display alignItems="center">
                  <Grid>
                    <InfoGridItem
                      icon="user"
                      label="Nome do Cliente"
                      value={
                        <S.TextClientName>
                          {dadosCertificado.nomeRazaoSocial}
                        </S.TextClientName>
                      }
                    />
                    <InfoGridItem
                      icon="documentFront"
                      label="Documento do Cliente"
                      value={dadosCertificado.cpfCnpjSegurado}
                    />
                    <InfoGridItem
                      icon="documentPaper"
                      label="Número da Apólice"
                      value={dadosCertificado.numeroApolice}
                    />
                    <InfoGridItem
                      icon="documentBack"
                      label="Contrato"
                      value={dadosCertificado.numeroContrato}
                    />
                    <InfoGridItem
                      icon="calendar"
                      label="Vigência"
                      value={`${dadosCertificado.dataIniVigencia} - ${dadosCertificado.dataFimVigencia}`}
                    />
                    <InfoGridItem
                      icon="information"
                      label="Situação"
                      value={dadosCertificado.descricaoStatusCertificado}
                    />
                  </Grid>
                </Display>
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </Display>

      <Modal show={showModal} onClose={goHome}>
        <Text variant="body02-sm" margin>
          Não foi possível recuperar os dados do certificado prestamista.
        </Text>
        <Display justify="center">
          <Button variant="secondary" onClick={goHome}>
            Certo, entendi!
          </Button>
        </Display>
      </Modal>
    </>
  );
};
