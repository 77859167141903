import styled from 'styled-components/macro';
import { Select as SelectDS } from '@cvp/design-system/react';

export const Select = styled(SelectDS)(({ ...rest }) => ({
  ...rest,
}));

Select.Item = styled(SelectDS.Item)(({ ...rest }) => ({
  ...rest,
}));

export default Select;
