import { AppContext } from 'main/contexts/AppContext';
import { useContext } from 'react';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import {
  IResponseAcaoCoberturas,
  IPayloadAcaoCoberturas,
  IPayloadAcaoCoberturasPeco,
} from '../types/AcaoCoberturas.types';

export const useSuspenderContribuicao = (
  request: IPayloadAcaoCoberturas | undefined,
) => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const { loading, response, fetchData } = usePeco<
    IPayloadAcaoCoberturasPeco,
    IResponseAcaoCoberturas
  >({
    api: { operationPath: PECOS.SuspenderContribuicao },
    autoFetch: false,
    payload: {
      cpfCnpj,
      NumeroCertificado: numCertificado,
      ...request,
    },
  });

  return { loading, response, fetchData };
};
