import React from 'react';
import {
  Text,
  Button,
  Card,
  Divider,
  Skeleton,
} from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import RenderConditional from 'main/components/RenderConditional';
import * as S from './styles';
import { EnumCobertura } from '../../types/enum';
import { CardCoberturaProps } from '../../types/CardCoberturaProps';

const CardCobertura: React.FC<CardCoberturaProps> = ({
  onClick,
  title,
  valorContribuicao,
  formaContribuicao,
  valorBeneficio,
  cobertura,
  loading,
}) => {
  return (
    <Card>
      <Card.Content>
        <S.CardContainer>
          <S.Title data-testid="title" variant="body02-sm" color="text">
            {title}
          </S.Title>
          <Divider />
          <Text variant="body02-sm" color="text">
            Contribuindo com
          </Text>
          <RenderConditional condition={!!loading}>
            <Skeleton lines={1} />
          </RenderConditional>
          <RenderConditional condition={!loading}>
            <Text
              data-testid="valorContribuicao"
              variant="body02-sm"
              color="text"
            >
              <b>{formatarValorPadraoBrasileiro(valorContribuicao)}</b>
            </Text>
          </RenderConditional>

          <Text variant="body02-sm" color="text">
            {formaContribuicao}
          </Text>
          <Divider />
          <Text data-testid="cobertura" variant="body02-sm" color="text">
            o valor da{' '}
            {cobertura === EnumCobertura.pensao
              ? 'renda mensal'
              : 'indenização'}{' '}
            será de:
          </Text>
          <RenderConditional condition={!!loading}>
            <Skeleton lines={1} />
          </RenderConditional>
          <RenderConditional condition={!loading}>
            <Text
              data-testid="valorBeneficio"
              variant="body02-sm"
              color="text"
              margin
            >
              <b>{formatarValorPadraoBrasileiro(valorBeneficio)}</b>
            </Text>
          </RenderConditional>

          <Button
            disabled={loading}
            onClick={() => onClick(title, valorContribuicao, valorBeneficio)}
            data-testid="adquirir"
          >
            Adquirir
          </Button>
        </S.CardContainer>
      </Card.Content>
    </Card>
  );
};
export default CardCobertura;
