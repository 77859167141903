import styled from 'styled-components/macro';
import {
  TextField as TextFieldDS,
  Select as SelectDS,
} from '@cvp/design-system/react';

export const TextField = styled(TextFieldDS)`
  & input {
    height: 45px;
  }

  & label {
    margin-bottom: 0.2rem;
  }
`;

export const Select = styled(SelectDS)`
  & input,
  & div {
    height: 45px;
  }

  & label ~ div {
    line-height: 45px;
  }

  & > div ~ div {
    height: 0px;
  }

  & label {
    margin-bottom: 0.2rem;
  }
`;
