import styled from 'styled-components/macro';

export const ParticipantViewContainer = styled.div({
  h6: {
    wordBreak: 'break-all',
  },
});

export const Actions = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  button: {
    width: '100%',
  },

  [theme.breakpoint.md()]: {
    flexDirection: 'row',
    button: {
      width: 'unset',
    },
  },
}));
