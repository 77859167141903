import React from 'react';
import { Button, Grid, Text } from '@cvp/design-system/react';
import { CalendarData } from 'main/components/form/Calendar/Calendar.type';
import { SelectItem } from 'main/components/form/Select';
import LinkedValue from 'main/features/Validation/types/LinkedValue';
import { required, validDate } from 'main/features/Validation/validations';
import useFieldLink from 'main/hooks/useFieldLink';
import * as S from 'previdencia/features/SimulacaoRenda/components/DadosRendaVitaliciaReversivel/styles';
import { useSimulacaoRendaContextData } from 'previdencia/features/SimulacaoRenda/hooks/useSimulacaoRendaContextData';
import { EtapasFluxoSimulacaoRenda } from 'previdencia/features/SimulacaoRenda/types/enum';

const DadosRendaVitaliciaReversivel: React.FC = () => {
  const { setEtapaAtual } = useSimulacaoRendaContextData();

  const [dataNascimentoLink, validarDataNascimento] = useFieldLink(
    {} as CalendarData,
  );
  const [generoLink, validarCampoGenero] = useFieldLink('');
  const [porcentagemLink, validarPorcentagem] = useFieldLink('');

  const isSelected = (value: string, link: LinkedValue<string>): boolean => {
    return value === link.get().value;
  };

  const simularRendaVitaliciaReversivel = (): void => {
    if (
      validarDataNascimento().isValid &&
      validarCampoGenero().isValid &&
      validarPorcentagem().isValid
    ) {
      setEtapaAtual(EtapasFluxoSimulacaoRenda.RESULTADO_SIMULACAO, {
        dthNascimentoConjuge: dataNascimentoLink
          .get()
          .value.initialDate?.toISOString(),
        staGeneroConjuge: generoLink.get().value,
        pctReversao: porcentagemLink.get().value,
        cpfBeneficiario: '0',
      });
    }
  };

  return (
    <>
      <Grid>
        <Grid.Item xs={1} md={1 / 3}>
          <Text variant="caption-01" color="text-light">
            Porcentagem reversível ao beneficiário
          </Text>
          <S.InputSelectSimulacao
            link={porcentagemLink}
            placeholder="Selecione uma porcentagem"
            validationRules={[required()]}
          >
            <SelectItem
              value="100"
              text="Toda a sua renda"
              selected={isSelected('100', porcentagemLink)}
            />
            <SelectItem
              value="75"
              text="75%"
              selected={isSelected('75', porcentagemLink)}
            />
            <SelectItem
              value="50"
              text="50%"
              selected={isSelected('50', porcentagemLink)}
            />
          </S.InputSelectSimulacao>
        </Grid.Item>

        <Grid.Item xs={1} md={1 / 3}>
          <Text variant="caption-01" color="text-light">
            Sexo do beneficiário
          </Text>
          <S.InputSelectSimulacao
            link={generoLink}
            placeholder="Selecione o sexo"
            validationRules={[required()]}
          >
            <SelectItem
              value="M"
              text="Masculino"
              selected={isSelected('M', generoLink)}
            />
            <SelectItem
              value="F"
              text="Feminino"
              selected={isSelected('F', generoLink)}
            />
          </S.InputSelectSimulacao>
        </Grid.Item>

        <Grid.Item xs={1} md={1 / 3}>
          <Text variant="caption-01" color="text-light">
            Data de nascimento
          </Text>
          <S.InputDataNascimento
            link={dataNascimentoLink}
            validationRules={[validDate()]}
            maxDate={new Date()}
          />
        </Grid.Item>
      </Grid>

      <S.ContainerButtons>
        <Button
          variant="outlined"
          onClick={() =>
            setEtapaAtual(EtapasFluxoSimulacaoRenda.INICIO_SIMULACAO)
          }
        >
          Voltar
        </Button>
        <Button variant="primary" onClick={simularRendaVitaliciaReversivel}>
          Simular
        </Button>
      </S.ContainerButtons>
    </>
  );
};

export default DadosRendaVitaliciaReversivel;
