import * as Yup from 'yup';

export const validationFormConsultaPeriodoResgatesSchema = Yup.object().shape({
  codAgencia: Yup.string().required('Campo obrigatório'),
  dataInicio: Yup.date()
    .required('Campo obrigatório')
    .max(new Date(), 'Esta data não pode ser futura'),
  dataFim: Yup.date()
    .required('Campo obrigatório')
    .max(new Date(), 'Esta data não pode ser futura')
    .min(
      Yup.ref('dataInicio'),
      'A data de fim deve ser posterior à data de início',
    ),
});
