import React from 'react';
import { Select } from '@cvp/design-system/react';
import { Opcoes } from 'main/features/prospeccao/types/IFormProspeccao';
import { DynamicFormSelectOption } from 'main/features/prospeccao/types/DesignSystemSelectProps';
import * as Validators from '../../utils/validators';

type DynamicFormSelectProps = {
  data: Opcoes;
  onChange: (id: string) => void;
  error?: boolean;
  errorMessage?: string;
};

const DynamicFormSelect: React.FC<DynamicFormSelectProps> = ({
  data,
  onChange,
  error,
  errorMessage,
}) => {
  return (
    <Select
      label={Validators.renderLabelWithRequiredCheck(
        data.obrigatorio,
        data.descricao,
      )}
      placeholder="Escolha uma opção"
      error={error}
      errorMessage={errorMessage}
      onChange={({
        target: { value },
      }: React.ChangeEvent<DynamicFormSelectOption>) => {
        onChange(value);
      }}
    >
      {data.opcoes.map(opt => (
        <Select.Item
          key={opt.id}
          value={opt.id}
          text={opt.descricao}
          selected={opt.id === data.resposta}
        />
      ))}
    </Select>
  );
};

export default DynamicFormSelect;
