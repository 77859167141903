import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { AppContext } from 'main/contexts/AppContext';
import { ResponseTiposRendas } from 'previdencia/features/SimulacaoRenda/types/tiposRenda';
import * as TipoRendasApi from 'previdencia/features/SimulacaoRenda/services/obterTiposRendas.api';

const useObterTiposRendas = (): UseQueryResult<
  ResponseTiposRendas | undefined
> => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  return useQuery(
    ['prev-obter-tipos-rendas', numCertificado],
    () => TipoRendasApi.obterTiposRendas(cpfCnpj, numCertificado),
    {
      cacheTime: reactQueryCacheDuration(),
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
};

export default useObterTiposRendas;
