export enum EnumStatusCard {
  ativos = 'ativos',
  inativos = 'inativos',
}

export enum EnumStatusCardVida {
  ativo = 'ativo',
  emitido = 'emitido',
}

export enum EnumStatusCardPrevidencia {
  Ativo = 'A',
  Suspenso = 'U',
  Transferencia = 'T',
  Beneficio = 'B',
}

export enum EnumFormaPagamento {
  CB = 'CB',
  FC = 'FC',
}
