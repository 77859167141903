export interface IDadosParticipanteTelefone {
  aceitaSms: string;
  codigoArea: string;
  extensaoNumero: string;
  idTelefone: string;
  localTelefone: string;
  numeroTelefone: string;
  telefonePrincipal: string;
  tipoTelefone: string;
}

export type DadosParticipante = {
  nome: string;
  numCpf: string;
  bairro: string;
  dataNascimento: Date;
  estadoCivil: string;
  endereco: string;
  numero: string;
  complemento: string;
  cidade: string;
  enderecoPadrao: string;
  email: string;
  idPessoa: string;
  idCidade: string;
  idEmail: string;
  cep: string;
  sexo: string;
  uf: string;
  idEndereco: string;
  telefones: IDadosParticipanteTelefone[];
  tipoEndereco: string;
  tipoEmail: string;
  tipoRua: string;
};

export type TelefoneDadosParticipante = {
  aceitaSms: string;
  codigoArea: string;
  extensaoNumero: string;
  idTelefone: string;
  localTelefone: string;
  numeroTelefone: string;
  telefonePrincipal: string;
  tipoTelefone: string;
};

export const TIPO_TELEFONE = {
  CELULAR: 'C',
  TELEFONE_DIRETO: 'D',
  FAX: 'F',
  OUTRO: 'O',
  CENTRAL: 'R',
  TELEX: 'T',
  TEL_FAX: 'X',
};
