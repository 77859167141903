import * as Yup from 'yup';
import LinkedValue from 'main/features/Validation/types/LinkedValue';
import { DadosParticipante, TIPO_TELEFONE } from 'main/types/Participante';
import {
  ACEITAR_SMS,
  TIPO_EMAIL,
  EXTENSAO_NUMERO,
  LOCAL_TELEFONE,
  TELEFONE_PRINCIPAL,
} from 'previdencia/features/DadosParticipante/constants/constants';
import {
  DadosCEP,
  CODIGO_PAIS,
  RequestAlterarEmail,
  RequestAlterarEndereco,
  RequestAlterarTelefone,
  ResponseConsultarConsentimento,
  CODIGO_CONSENTIMENTO,
} from 'previdencia/features/DadosParticipante/types/DadosParticipante';
import {
  compararTelefone,
  tratamentoCodigoAreaTelefone,
  tratamentoExcecaoCodigoAreaVazioUpdate,
  tratamentoExcecaoNumeroTelefoneVazioUpdate,
  tratamentoNumeroTelefone,
} from 'previdencia/features/DadosParticipante/utils/dadosParticipante';
import { tryGetValueOrDefault } from 'main/utils/conditional';

export const preencherDadosEndereco = (
  dadosParticipante: DadosParticipante | undefined,
  endereco: string,
  numero: string,
  complemento: string | undefined,
  bairro: string,
  cidade: string,
  dadosCep: DadosCEP | undefined,
): RequestAlterarEndereco | undefined => {
  let retorno: RequestAlterarEndereco = {} as RequestAlterarEndereco;

  if (
    dadosParticipante &&
    (dadosParticipante.endereco !== endereco ||
      dadosParticipante.numero !== numero ||
      dadosParticipante.complemento !== complemento ||
      dadosParticipante.bairro !== bairro ||
      dadosParticipante.cidade !== dadosCep?.cidade ||
      dadosParticipante.uf !== dadosCep.idEstado)
  ) {
    retorno = {
      idEndereco: dadosParticipante.idEndereco,
      endereco,
      numero,
      tipoEndereco: dadosParticipante.tipoEndereco,
      complemento: complemento || '',
      bairro,
      cidade: dadosCep?.cidade || cidade,
      idCidade: dadosCep?.idCidade || dadosParticipante.idCidade,
      Estado: dadosCep?.idEstado || dadosParticipante.uf,
      cep: dadosCep?.cep || dadosParticipante.cep,
      Pais: CODIGO_PAIS.brasil,
      tipoRua: dadosParticipante.tipoRua,
      enderecoPadrao: dadosParticipante.enderecoPadrao,
    };

    return retorno;
  }

  return undefined;
};

export const selecionarDadosTelefonesArmazenados = (
  dadosTelefoneCelular: RequestAlterarTelefone | undefined,
  dadosTelefoneResidencial: RequestAlterarTelefone | undefined,
  telefoneResidencial: string | undefined,
  telefoneCelular: string | undefined,
  telefoneResidencialAntigo: string,
  telefoneCelularAntigo: string,
): RequestAlterarTelefone[] | undefined => {
  const retorno: RequestAlterarTelefone[] = [] as RequestAlterarTelefone[];

  if (compararTelefone(telefoneCelular, telefoneCelularAntigo)) {
    retorno.push({
      telefoneId: dadosTelefoneCelular?.telefoneId || null,
      codigoArea:
        dadosTelefoneCelular?.codigoArea || telefoneCelular?.substr(0, 2),
      numeroTelefone:
        dadosTelefoneCelular?.numeroTelefone || telefoneCelular?.substr(2),
      aceitarSms: ACEITAR_SMS.SIM,
      tipoTelefone: dadosTelefoneCelular?.tipoTelefone || TIPO_TELEFONE.CELULAR,
      localTelefone:
        dadosTelefoneCelular?.localTelefone || LOCAL_TELEFONE.CELULAR,
      telefonePrincipal:
        dadosTelefoneCelular?.telefonePrincipal || TELEFONE_PRINCIPAL.SIM,
      extensaoNumero: EXTENSAO_NUMERO.PADRAO,
    });
  }

  if (compararTelefone(telefoneResidencial, telefoneResidencialAntigo)) {
    retorno.push({
      telefoneId: dadosTelefoneResidencial?.telefoneId || null,
      codigoArea: tratamentoExcecaoCodigoAreaVazioUpdate(telefoneResidencial),
      numeroTelefone:
        tratamentoExcecaoNumeroTelefoneVazioUpdate(telefoneResidencial),
      aceitarSms: ACEITAR_SMS.SIM,
      tipoTelefone:
        dadosTelefoneResidencial?.tipoTelefone || TIPO_TELEFONE.TELEFONE_DIRETO,
      localTelefone:
        dadosTelefoneResidencial?.localTelefone || LOCAL_TELEFONE.COMERCIAL,
      telefonePrincipal:
        dadosTelefoneCelular?.telefonePrincipal === TELEFONE_PRINCIPAL.SIM
          ? TELEFONE_PRINCIPAL.NAO
          : TELEFONE_PRINCIPAL.SIM,
      extensaoNumero: EXTENSAO_NUMERO.PADRAO,
    });
  }

  return retorno;
};

export const preencherDadosTelefone = (
  dadosParticipante: DadosParticipante | undefined,
  telefoneResidencial: string | undefined,
  telefoneCelular: string | undefined,
  telefoneResidencialAntigo: string,
  telefoneCelularAntigo: string,
): RequestAlterarTelefone[] | undefined => {
  let dadosTelefoneCelular: RequestAlterarTelefone | undefined;
  let dadosTelefoneResidencial: RequestAlterarTelefone | undefined;

  dadosParticipante?.telefones.forEach(e => {
    if (
      e.tipoTelefone === TIPO_TELEFONE.CELULAR ||
      e.tipoTelefone === TIPO_TELEFONE.OUTRO
    ) {
      dadosTelefoneCelular = {
        telefoneId: e.idTelefone,
        codigoArea: tratamentoCodigoAreaTelefone(
          e.numeroTelefone,
          telefoneCelular,
        ),
        numeroTelefone: tratamentoNumeroTelefone(
          e.numeroTelefone,
          telefoneCelular,
        ),
        tipoTelefone: e.tipoTelefone,
        localTelefone: LOCAL_TELEFONE.CELULAR,
        aceitarSms: ACEITAR_SMS.SIM,
        telefonePrincipal: ACEITAR_SMS.SIM,
        extensaoNumero: EXTENSAO_NUMERO.PADRAO,
      };
    }
    if (
      e.tipoTelefone === TIPO_TELEFONE.TELEX ||
      e.tipoTelefone === TIPO_TELEFONE.TELEFONE_DIRETO
    ) {
      dadosTelefoneResidencial = {
        telefoneId: e.numeroTelefone !== '' ? e.idTelefone : null,
        codigoArea: tratamentoCodigoAreaTelefone(
          e.numeroTelefone,
          telefoneResidencial,
        ),
        numeroTelefone: tratamentoNumeroTelefone(
          e.numeroTelefone,
          telefoneResidencial,
        ),
        tipoTelefone: e.tipoTelefone,
        localTelefone: LOCAL_TELEFONE.COMERCIAL,
        aceitarSms: ACEITAR_SMS.SIM,
        telefonePrincipal: ACEITAR_SMS.NAO,
        extensaoNumero: EXTENSAO_NUMERO.PADRAO,
      };
    }
  });

  const retorno = selecionarDadosTelefonesArmazenados(
    dadosTelefoneCelular,
    dadosTelefoneResidencial,
    telefoneResidencial,
    telefoneCelular,
    telefoneResidencialAntigo,
    telefoneCelularAntigo,
  );

  if (retorno && retorno.length > 0) {
    return retorno;
  }

  return undefined;
};

export const preencherDadosEmail = (
  dadosParticipante: DadosParticipante | undefined,
  email: string,
): RequestAlterarEmail | undefined => {
  let retorno: RequestAlterarEmail = {} as RequestAlterarEmail;

  if (dadosParticipante && dadosParticipante.email !== email) {
    retorno = {
      email,
      emailId: tryGetValueOrDefault([dadosParticipante.idEmail], ''),
      tipoEmail: dadosParticipante?.tipoEmail || TIPO_EMAIL.PESSOAL,
    };

    return retorno;
  }

  return undefined;
};

export const procurarPorSubprocessoMostrarConsentimento = (
  dados: ResponseConsultarConsentimento | undefined,
  subprocesso: number,
): boolean => {
  const tipo = dados?.consentimentos.find(
    e => e.subProcessoNegocio.codigo === subprocesso,
  );

  return tipo?.consentimento.tipo === CODIGO_CONSENTIMENTO.parceiros;
};

export const statusPreenchimentoFormValido = (
  enderecoLink: LinkedValue<string>,
  numeroLink: LinkedValue<string>,
  bairroLink: LinkedValue<string>,
  emailLink: LinkedValue<string>,
  telefoneResidencialLink: LinkedValue<string>,
  telefoneComercialLink: LinkedValue<string>,
): boolean => {
  const formValido = {
    endereco: enderecoLink.get().value,
    numero: numeroLink.get().value,
    bairro: bairroLink.get().value,
    email: emailLink.get().value,
    telefone:
      telefoneResidencialLink.get().value || telefoneComercialLink.get().value,
  };

  const formValidoSchema = Yup.object().shape({
    endereco: Yup.string().required('Campo obrigatório'),
    numero: Yup.string().required('Campo obrigatório'),
    bairro: Yup.string().required('Campo obrigatório'),
    email: Yup.string().email().required('Campo obrigatório'),
    telefone: Yup.string().required('Campo obrigatório'),
  });

  return !formValidoSchema.isValidSync(formValido);
};
