import React, { useEffect, useState } from 'react';
import { Button, Display, Text, TextField } from '@cvp/design-system/react';
import { useToast } from 'main/hooks/useToast';
import { enviarEmail } from 'main/services/emailService';
import {
  validateEmail,
  validateEmailToShowInEmailSender,
} from 'main/utils/emails';
import { useObterContatosDefaultPrevidencia } from 'main/hooks/useObterContatosDefaultPrevidencia';
import { IEmailSenderProps } from 'main/types/EmailSender/IEmailSenderProps';
import RenderConditional from '../RenderConditional';
import Modal from '../Modal';
import { tryGetValueOrDefault } from 'main/utils/conditional';

const EmailSenderModal: React.FC<IEmailSenderProps> = ({
  disabled,
  objetoEmail,
  onSucess,
  onError,
  seguro,
  defaultEmail,
}) => {
  const { toastSuccess } = useToast();
  const { data } = useObterContatosDefaultPrevidencia(
    objetoEmail?.parametrosEnvio?.numCertificado,
  );
  const emailDefault = tryGetValueOrDefault(
    [data?.emailDefault],
    tryGetValueOrDefault([defaultEmail], ''),
  );
  const [emailAddress, setEmailAddress] = useState('');
  const [isModalVisible, setModalVisibility] = useState(false);
  const [isErrorMessageVisible, setErrorMessageVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [sendEnabled, setSendEnable] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultTrigger = () => setSendEnable(sendEnabled);
  const onSucessTrigger = onSucess !== undefined ? onSucess : defaultTrigger;
  const onErrorTrigger = onError !== undefined ? onError : defaultTrigger;

  const onCloseModal = () => {
    setModalVisibility(false);
    setSendEnable(false);
    setEmailAddress('');
  };
  useEffect(() => {
    if (isModalVisible) {
      const enabled = validateEmail(emailDefault);
      setSendEnable(enabled);
      setEmailAddress(
        validateEmailToShowInEmailSender(
          objetoEmail.tipoEmail,
          objetoEmail.parametrosEnvio?.cpfCnpjBeneficiario,
          emailDefault,
        ),
      );
    }
  }, [isModalVisible]);

  const obterResult = async () => {
    return enviarEmail(
      {
        ...objetoEmail,
        enderecoEmail: emailAddress,
      },
      seguro,
    );
  };

  const onSendEmailClick = async () => {
    try {
      setSendEnable(false);
      setLoading(true);

      const sendResult = await obterResult();
      setLoading(false);

      if (sendResult.sucess) {
        setSendEnable(true);
        onCloseModal();
        onSucessTrigger();
        toastSuccess(String(sendResult.resultMessage));
      } else {
        const message = `${sendResult?.resultCode}: ${sendResult.resultMessage}`;
        setErrorMessage(message);
        setErrorMessageVisibility(true);
        onErrorTrigger(new Error(message));
      }
    } catch (error) {
      if (error instanceof Error) onErrorTrigger(error);
      else if (error !== undefined)
        onErrorTrigger(new Error((error as string)?.toString()));
    }
  };

  const onChangeEmail = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const enabled = validateEmail(value);
    setSendEnable(enabled);
    setEmailAddress(value);
  };

  return (
    <>
      <Button disabled={disabled} onClick={() => setModalVisibility(true)}>
        Enviar E-mail
      </Button>
      <Modal open={isModalVisible} onClose={onCloseModal}>
        <Text variant="headline-04" margin>
          Informe o e-mail para envio
        </Text>
        <TextField
          label="E-mail"
          type="email"
          placeholder={tryGetValueOrDefault([emailAddress], 'Email')}
          onChange={onChangeEmail}
          value={emailAddress}
        />
        <RenderConditional condition={isErrorMessageVisible}>
          <br />
          <Display margin>
            <Text variant="lead" color="error">
              {' '}
              {errorMessage}
            </Text>
          </Display>
        </RenderConditional>
        <Display type="inline-block">
          <Button
            variant="secondary"
            disabled={!sendEnabled}
            onClick={onSendEmailClick}
            loading={loading}
          >
            Enviar
          </Button>
        </Display>
      </Modal>
    </>
  );
};

export default EmailSenderModal;
