import { api, IApiResponse } from 'main/services';
import {
  obterUrlCadastrarProspeccao,
  obterUrlRecuperarFormularioProspeccao,
  obterUrlImportanciaSegurada,
} from 'main/features/prospeccao/config/endpoints';
import { IFormProspeccao } from 'main/features/prospeccao/types/IFormProspeccao';
import { IImportanciaSegurada } from 'main/features/prospeccao/types/IImportanciaSegurada';

export const obterFormulario = async (
  codigoProduto: string,
): Promise<IApiResponse<IFormProspeccao>> => {
  const result = await api.post<IApiResponse<IFormProspeccao>>(
    obterUrlRecuperarFormularioProspeccao(),
    {
      codigoProduto,
    },
  );

  return result.data;
};

export const cadastrarProspeeccao = async (form: IFormProspeccao) => {
  const result = await api.post<IApiResponse<number>>(
    obterUrlCadastrarProspeccao(),
    {
      idFormularioProspeccao: form.idFormulario.toString(),
      respostasFormularioProspeccao: JSON.stringify(form),
    },
  );

  return result.data;
};

export const obterImportanciaSegurada = async (
  cpfCnpjCliente: string,
): Promise<IApiResponse<IImportanciaSegurada>> => {
  const result = await api.post<IApiResponse<IImportanciaSegurada>>(
    obterUrlImportanciaSegurada(),
    {
      cpfCnpjCliente,
    },
  );
  return result.data;
};
