import React, { useState } from 'react';
import { Display, Text, Select, Button } from '@cvp/design-system/react';
import guid from 'main/utils/guid';
import { DynamicFormSelectOption } from 'main/features/prospeccao/types/DesignSystemSelectProps';
import { IProduto } from 'main/features/prospeccao/types/Produto';

type FormularioEscolhaProdutoProps = {
  handleClose: () => void;
  handleConfirm: (produto?: IProduto) => void;
  produtos: IProduto[];
};

const FormularioEscolhaProduto: React.FC<FormularioEscolhaProdutoProps> = ({
  produtos,
  handleClose,
  handleConfirm,
}) => {
  const [produtoEscolhido, setProdutoEscolhido] = useState<IProduto>();
  return (
    <>
      <Text variant="headline-05" color="primary" margin>
        Escolha um produto:
      </Text>
      <Text margin color="text-light" variant="body02-sm">
        Selecione um produto e clique no botão <strong>Confirmar</strong> para
        preenchimento do formulário de cadastro.
      </Text>
      <div style={{ width: '40%', marginTop: 10 }}>
        <Select
          placeholder="Escolha uma opção"
          onChange={({
            target: { value },
          }: React.ChangeEvent<DynamicFormSelectOption>) => {
            setProdutoEscolhido(produtos.find(x => x.codigo === Number(value)));
          }}
        >
          {produtos.map(item => (
            <Select.Item
              key={guid()}
              value={item.codigo}
              text={item.nome}
              selected={produtoEscolhido?.codigo === item.codigo}
            />
          ))}
        </Select>
      </div>
      <Display justify="center" style={{ marginTop: 20 }}>
        <Button
          variant="secondary"
          onClick={handleClose}
          data-testid="cancelar"
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={() => handleConfirm(produtoEscolhido)}
          data-testid="confirmar"
          disabled={!produtoEscolhido?.codigo}
        >
          Confirmar
        </Button>
      </Display>
    </>
  );
};

export default FormularioEscolhaProduto;
