import { Modal, Text } from '@cvp/design-system/react';
import React from 'react';
import { TextAlign } from '../styles';

type ModalPrimeiroAlertaProps = {
  open: boolean;
  handleClose: () => void;
};
const ModalPrimeiroAlerta: React.FC<ModalPrimeiroAlertaProps> = ({
  open,
  handleClose,
}) => (
  <Modal show={open} onClose={handleClose}>
    <Text variant="headline-04" margin>
      Atenção!
    </Text>
    <TextAlign variant="body03-sm">Prezado(a) colega CAIXA,</TextAlign>
    <TextAlign variant="body03-sm">
      Alterado para <strong>02 dias</strong> o prazo aceitação da assinatura do{' '}
      <strong>FOP 223</strong> - Solicitação de Transferência com alteração de
      Taxa.
    </TextAlign>
  </Modal>
);

export default ModalPrimeiroAlerta;
