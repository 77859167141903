import React, { useState } from 'react';
import { Display, Button, Text } from '@cvp/design-system/react';
import useFieldLink from 'main/hooks/useFieldLink';
import Input from 'main/components/form/Input';
import masks from 'main/utils/masks';
import { required } from 'main/features/Validation/validations';
import { validate } from 'main/features/Validation/utils/validateRules';
import { useReversaoCancelamento } from '../hooks/useReversaoCancelamento';
import { ISentType } from '../types/ISentType';
import { EModalWorkflowStep } from 'contratosPrestamista/features/cancelamento/types/IModalWorkflow';
import { IFormSendCodeProps } from 'contratosPrestamista/features/cancelamento/types/IFormSendCodeProps';

const FormSendCode: React.FC<IFormSendCodeProps> = ({
  handlerCancel,
  handleWorkflow,
  numContrato,
  handleIdSolicitacaoToken,
  setFormSendCode,
}) => {
  const [sentMethod] = useState<ISentType>({
    method: 'sms',
  });
  const [inputCelular, validateCelular] = useFieldLink('');
  const { enviarCodigoConfirmacao, loading } = useReversaoCancelamento();

  const handlerEnvio = async () => {
    if (validate([validateCelular])) {
      const payload = {
        tipoEnvio: sentMethod?.method,
        enderecoEmail: '',
        numeroCelular: inputCelular.get().value,
        numeroContrato: numContrato,
      };
      setFormSendCode(payload);
      const result = await enviarCodigoConfirmacao(payload);
      if (result?.dados.sucesso) {
        handleIdSolicitacaoToken(
          result.dados.entidade?.idSolicitacaoToken ?? '',
        );
        handleWorkflow({ step: EModalWorkflowStep.VALIDATE });
      }
    }
  };

  return (
    <div data-testid="modalInputCelular">
      <Text variant="headline-06" margin>
        Confirme os dados
      </Text>
      <Text variant="body-medium1" margin>
        Para finalizar, informe um número para que o cliente possa confirmar a
        retenção do contrato.
      </Text>
      <br />
      {loading ? (
        <Button variant="text" loading />
      ) : (
        <>
          {!loading && sentMethod && (
            <Display type="display-block">
              <Input
                link={inputCelular}
                inputMask={masks.phone}
                label="Celular"
                validationRules={[required()]}
                data-testid="inputCelular"
              />

              <Display justify="center" style={{ marginTop: '2rem ' }}>
                <Button
                  type="click"
                  variant="primary"
                  onClick={handlerEnvio}
                  data-testid="enviarCodigo"
                >
                  Continuar
                </Button>
                <Button
                  variant="secondary"
                  onClick={handlerCancel}
                  data-testid="cancelar"
                >
                  Cancelar
                </Button>
              </Display>
            </Display>
          )}
        </>
      )}
    </div>
  );
};

export default FormSendCode;
