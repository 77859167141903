import React from 'react';
import { Card, Display, Grid, Text } from '@cvp/design-system/react';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import VoltarDadosPlano from 'main/components/Button/voltarDadosPlano/VoltarDadosPlano';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import * as S from './styles';
import { REAJUSTE_ANUAL } from '../constants/constants';
import HistoricoAtualizacao from '../components/HistoricoAtualizacao';
import ReajusteAnualPlanoHeader from '../components/ReajusteAnualPlanoHeader';

const ReajusteAnualPlano: React.FC = () => {
  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredRoles={[
          ...PRINCIPAL_USERS,
          USER_PROFILES.ANALISTA_MANUTENCAO,
          USER_PROFILES.ANALISTA_CONSULTA,
        ]}
        requiredPermissions={[PREV_PERMISSIONS.REAJUSTE_E_REENQUADRAMENTO]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <ReajusteAnualPlanoHeader />
              <Grid.Item xs={1}>
                <S.WrapperWarning>
                  <Text
                    variant="body02-lg"
                    color="warning"
                    style={{ marginBottom: 12 }}
                    margin
                  >
                    IMPORTANTE
                  </Text>
                  <Text
                    variant="body03-md"
                    color="text-light"
                    style={{ marginBottom: 12 }}
                    margin
                  >
                    {REAJUSTE_ANUAL.ALTERACAO_VALOR_CONTRIBUICAO}
                  </Text>
                  <Text
                    variant="body03-md"
                    color="text-light"
                    style={{ marginBottom: 12 }}
                    margin
                  >
                    Veja com funciona:
                  </Text>
                  <Text variant="body03-md" color="text-light" margin>
                    {REAJUSTE_ANUAL.COMO_FUNCIONA}
                  </Text>
                </S.WrapperWarning>
              </Grid.Item>

              <Grid.Item xs={1}>
                <HistoricoAtualizacao />
              </Grid.Item>
            </Grid>

            <Display>
              <VoltarDadosPlano />
            </Display>
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
    </Display>
  );
};

export default ReajusteAnualPlano;
