import React from 'react';
import { Skeleton, Card, Display } from '@cvp/design-system/react';

const CardSkeleton: React.FunctionComponent = () => (
  <Display type="display-block" data-testid="cardSkeleton">
    <Card>
      <Card.Content data-testid="skeletonLines">
        <Skeleton lines={1} />
      </Card.Content>
    </Card>
  </Display>
);

export default CardSkeleton;
