import { Card, Disclaimer, Display } from '@cvp/design-system/react';
import { Formik } from 'formik';
import Icon from 'main/components/Icon';
import { useHistory, useLocation } from 'react-router';
import * as S from '../components/DetalheTarefaCards/styles';
import FormEdicaoContatos from '../components/FormEdicaoContatos';
import { ACAO_RETENCAO } from '../constants';
import { useAtualizarDadosRetencao } from '../hooks/useAtualizarDadosRetencao';
import { useEdicaoContatos } from '../hooks/useEdicaoContatos';
import { AtualizacaoPortabilidade } from '../types/AtualizacaoPortabilidade';
import { Email, Telefone } from '../types/Cliente';
import { DetalhesPortabilidade } from '../types/DetalhesPortabilidade';

type LocationState = {
  dadosPortabilidade: DetalhesPortabilidade;
};
type FormValues = {
  emails: Email[];
  telefones: Telefone[];
};

const EdicaoContatos = () => {
  const {
    state: { dadosPortabilidade },
  } = useLocation<LocationState>();
  const { emails, telefones } = useEdicaoContatos(dadosPortabilidade);
  const { atualizarDados } = useAtualizarDadosRetencao();
  const history = useHistory();

  const handleAtualizarDados = async (dados: FormValues) => {
    const atualizarDadosPayload: AtualizacaoPortabilidade = {
      numPortabilidade: dadosPortabilidade.portabilidade.id.toString(),
      acao: ACAO_RETENCAO.ALTERAR_DADOS_CLIENTE.ID_ACAO,
      dadosCliente: {
        emails: dados.emails ?? [],
        telefones: dados.telefones,
      },
    };
    await atualizarDados(atualizarDadosPayload);

    history.goBack();
  };

  return (
    <Display type="display-block">
      <Card>
        <Card.Content>
          <Display>
            <S.Disclaimer onClose={() => undefined}>
              <Disclaimer.Content
                icon={<Icon name="information" />}
                text="Os contatos marcados como principal serão utilizados para receber
                as solicitações de assinatura."
              />
            </S.Disclaimer>
            <br />
          </Display>
          <Formik
            initialValues={{
              telefones,
              emails,
            }}
            onSubmit={values => handleAtualizarDados(values)}
          >
            <FormEdicaoContatos dadosPortabilidade={dadosPortabilidade} />
          </Formik>
        </Card.Content>
      </Card>
    </Display>
  );
};

export default EdicaoContatos;
