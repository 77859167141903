import { tryGetValueOrDefault } from 'main/utils/conditional';
import * as CONSTS from 'prestamista/features/dadosSegurado/constants/constants';
import * as DADOS_SEGURADO_TYPES from 'prestamista/features/dadosSegurado/types/DadosSegurado';
import { ITelefone } from 'prestamista/types/ICertifucatiPrestamista';

export const customValidationCepFormik = (
  values: DADOS_SEGURADO_TYPES.IFormikValues,
  cidadeValida: boolean,
): string | null => {
  if (!values.cep.length) return CONSTS.MSG_VALIDACAO_ERRO.CAMPO_OBRIGATORIO;

  if (values.cep.length < CONSTS.QTD_MAXIMA_DIGITOS_CEP)
    return CONSTS.MSG_VALIDACAO_ERRO.QTD_MINIMA_CEP;

  if (!cidadeValida) return CONSTS.MSG_VALIDACAO_ERRO.CEP_INVALIDO;

  return null;
};

export const obterDadosTelefonePorTipo = (
  codigoTipoTelefone: number,
  listaTelefones: ITelefone[] | undefined,
): ITelefone => {
  const listaTelefonesFiltradaPorTipo: ITelefone[] = tryGetValueOrDefault(
    [
      listaTelefones?.filter(
        telefone => telefone.codigoTipoTelefone === codigoTipoTelefone,
      ),
    ],
    [],
  );

  const ultimoIndiceTelefonePorTipo: number =
    listaTelefonesFiltradaPorTipo?.length &&
    listaTelefonesFiltradaPorTipo.length - 1;

  return listaTelefonesFiltradaPorTipo[ultimoIndiceTelefonePorTipo];
};
