import { useState } from 'react';
import { api, IApiResponse } from 'main/services';
import { useToast } from 'main/hooks/useToast';
import { obterEndpointRecuperarUsuario } from '../config/endpoints';
import { setUserName } from '../utils/auth';
import { formatarMatricula } from '../utils/cefMatriculaFormatter';

export const useRecuperarAcesso = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { toastError, toastSuccess } = useToast();

  const recuperar = async (username: string): Promise<boolean> => {
    try {
      setLoading(true);
      const matricula = formatarMatricula(username);
      setUserName(matricula);
      const response = await api.post<IApiResponse<unknown>>(
        obterEndpointRecuperarUsuario(),
        {
          nomeAcesso: matricula,
        },
      );
      const [serviceResponseMessage] = response.data.dados.mensagens ?? [];

      if (response.data.dados.sucesso) {
        toastSuccess(serviceResponseMessage.descricao);
        return true;
      }
      toastError(serviceResponseMessage?.descricao);
    } catch (error: any) {
      toastError(error);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
    return false;
  };

  return {
    recuperar,
    loading,
  };
};
