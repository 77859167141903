import React, { useEffect, useState } from 'react';
import {
  Display,
  Grid,
  Button,
  Text,
  TextField,
  Modal,
  Select,
} from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import masks from 'main/utils/masks';
import Icon from 'main/components/Icon';
import {
  ISubscricaoPessoaFisica,
  ISubscricaoSocio,
} from '../types/ConsultarSubcricao';
import { Formik } from 'formik';
import {
  AdicionarSocioValidationSchema,
  EdicaoSocioValidationSchema,
} from '../features/dpseletronica/factories/esquemasValidacao';
import { IModalGerenciarSociosProps } from '../types/IModalGerenciarSociosProps';
import {
  checkIfAllItemsAreTrue,
  getTernaryResult,
} from 'main/utils/conditional';
import { ISelecItem } from 'main/types/DesignSystem/ISelectItem';

const ModalGerenciarSocios: React.FC<IModalGerenciarSociosProps> = ({
  openModal,
  handleClose,
  loading,
  dadosPessoa,
  tipo,
  handleExcluirSocio,
  disableDelete,
  disableValidate = false,
  handleSubmitForm = () => undefined,
}) => {
  const [form, setForm] = useState<ISubscricaoSocio | ISubscricaoPessoaFisica>(
    ({} as ISubscricaoSocio) || ({} as ISubscricaoPessoaFisica),
  );

  const obterLabelBotao = () => {
    switch (tipo) {
      case 'ADICIONAR':
        return 'Adicionar';
      case 'REENVIAR_LINK_DPS':
        return 'Reenviar Link';
      case 'ENVIAR_DPS':
        return 'Enviar link DPS';
      default:
        return 'Atualizar dados';
    }
  };

  useEffect(() => {
    if (checkIfAllItemsAreTrue([!loading, !!dadosPessoa?.cpf])) {
      setForm(dadosPessoa);
    }
  }, [dadosPessoa, tipo, loading]);

  const obterTituloModal = () => {
    const state = checkIfAllItemsAreTrue([disableValidate, !disableDelete]);
    return getTernaryResult(
      state,
      'Se necessário, realize a exclusão do sócio clicando em Excluir',
      `Se necessário, realize a atualização dos dados e clique em ${obterLabelBotao()}`,
    );
  };

  return (
    <Modal
      show={openModal}
      onClose={handleClose}
      style={{ minWidth: 700, width: '100%' }}
    >
      <Text margin variant="headline-06">
        <Icon name="warning" />
        {obterTituloModal()}
      </Text>
      <RenderConditional condition={loading}>
        <Display alignItems="center" justify="center">
          <Button variant="text" loading={loading} />
        </Display>
      </RenderConditional>
      <Formik
        initialValues={dadosPessoa}
        onSubmit={handleSubmitForm}
        validationSchema={
          form.cpf
            ? EdicaoSocioValidationSchema
            : AdicionarSocioValidationSchema
        }
      >
        {({
          handleSubmit,
          handleBlur,
          setFieldValue,
          values,
          errors,
          touched,
        }) => {
          const hasError = (field: string) => {
            return touched[field] && errors[field];
          };
          return (
            <form onSubmit={handleSubmit}>
              <RenderConditional condition={!loading}>
                <Grid>
                  <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
                    <TextField
                      label="CPF*"
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('cpf', masks.numberOnly.mask(value));
                      }}
                      onBlur={handleBlur}
                      placeholder="CPF"
                      margin
                      value={masks.cpf.mask(values.cpf)}
                      name="cpf"
                      error={hasError('cpf')}
                      errorMessage={hasError('cpf')}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
                    <TextField
                      label="Nome*"
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('nome', value);
                      }}
                      onBlur={handleBlur}
                      placeholder="Nome"
                      margin
                      value={values.nome}
                      name="nome"
                      error={hasError('nome')}
                      errorMessage={hasError('nome')}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
                    <TextField
                      label="Data Nascimento*"
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('dataNascimento', value);
                      }}
                      onBlur={handleBlur}
                      placeholder="Data Nascimento"
                      value={masks.date.mask(values.dataNascimento)}
                      name="dataNascimento"
                      error={hasError('dataNascimento')}
                      errorMessage={checkIfAllItemsAreTrue([
                        !!touched?.dataNascimento,
                        !!errors?.dataNascimento,
                      ])}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
                    <Select
                      label="Sexo*"
                      placeholder="Escolha a opção"
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<ISelecItem>) => {
                        setFieldValue('sexo', value);
                      }}
                      style={{ textAlign: 'left' }}
                      onBlur={handleBlur}
                    >
                      <Select.Item
                        selected={values.sexo === 'M'}
                        value="M"
                        text="Masculino"
                      />
                      <Select.Item
                        selected={values.sexo === 'F'}
                        value="F"
                        text="Feminino"
                      />
                    </Select>
                  </Grid.Item>
                  <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
                    <TextField
                      label="Telefone*"
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('telefone', masks.numberOnly.mask(value));
                      }}
                      onBlur={handleBlur}
                      placeholder="Telefone"
                      value={masks.phone.mask(values.telefone)}
                      name="telefone"
                      error={hasError('telefone')}
                      errorMessage={checkIfAllItemsAreTrue([
                        !!touched?.telefone,
                        !!errors?.telefone,
                      ])}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} md={1 / 2} lg={1 / 2}>
                    <TextField
                      label="E-mail*"
                      onChange={({
                        target: { value },
                      }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('email', value);
                      }}
                      onBlur={handleBlur}
                      placeholder="E-mail"
                      value={values.email}
                      name="email"
                      error={hasError('email')}
                      errorMessage={hasError('email')}
                    />
                  </Grid.Item>
                </Grid>
                <br />
                <Display>
                  <RenderConditional condition={!disableValidate}>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={Object.keys(errors).length > 0}
                    >
                      {obterLabelBotao()}
                    </Button>
                  </RenderConditional>

                  <RenderConditional
                    condition={checkIfAllItemsAreTrue([
                      !disableDelete,
                      !!form.cpf,
                    ])}
                  >
                    <Button
                      variant="outlined"
                      onClick={async () => {
                        if (handleExcluirSocio)
                          await handleExcluirSocio(form.cpf);
                      }}
                    >
                      Excluir
                    </Button>
                  </RenderConditional>
                </Display>
              </RenderConditional>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ModalGerenciarSocios;
