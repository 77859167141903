import React, { ReactElement } from 'react';
import { Grid, Card, Display, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import Icon from 'main/components/Icon';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import usePrevSummary from 'previdencia/hooks/usePrevSummary';
import TagStatusDocumento from 'previdencia/components/TagStatusDocumento';
import { useTransferenciaInternaContext } from 'previdencia/features/TransferenciaInternaEmissaoConjugado/contexts/TransferenciaInternaContext';
import * as PROPS_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblComponentProps';
import * as S from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/ResumoTransferenciaInterna/styles';
import * as TRANSF_VGBL_STYLES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/styles';

const ResumoTransferenciaInterna: React.FC<
  PROPS_TYPES.ResumoTransferenciaInternaProps
> = ({ loadingDadosOrigem }) => {
  const { dadosOrigem, saldoTotalFundosAtuais } =
    useTransferenciaInternaContext();

  const { informacaoPorCertificado } = usePrevSummary();

  const statusCertificado: string = tryGetValueOrDefault(
    [informacaoPorCertificado?.situacao],
    '',
  );

  const fundosAtuais: ReactElement[] = tryGetValueOrDefault(
    [
      dadosOrigem?.fundosVgbl?.map(fundo => (
        <Text
          key={fundo.codigoFundo}
          variant="caption-02"
          color="text"
          data-testid="resumoTransferenciaInternaFundosAtuais"
        >
          {fundo.descricaoFundo}
        </Text>
      )),
    ],
    [],
  );

  return (
    <>
      <Card data-testid="resumoTransferenciaInterna">
        <Card.Content padding={[6, 8, 6, 8]}>
          <Grid>
            <Grid.Item xs={1}>
              <Text variant="headline-05" color="primary">
                Transferência Interna com Emissão de Conjugado
              </Text>
            </Grid.Item>

            <RenderConditional condition={loadingDadosOrigem}>
              <SkeletonLoading
                blocks={1}
                data-testid="skeletonResumoTransferenciaInterna"
              />
            </RenderConditional>

            <RenderConditional condition={!loadingDadosOrigem}>
              <Grid.Item xs={1}>
                <TRANSF_VGBL_STYLES.Display alignItems="center">
                  <Text
                    variant="headline-05"
                    color="primary"
                    data-testid="resumoTransferenciaInternaNomeProdutoVgbl"
                  >
                    {tryGetValueOrDefault([dadosOrigem?.nomeProdutoVgbl], '-')}
                  </Text>
                  <TagStatusDocumento status={statusCertificado} />
                </TRANSF_VGBL_STYLES.Display>
              </Grid.Item>
              <Grid.Item xs={1} md={1 / 2}>
                <S.ContainerIconInfo>
                  <Icon name="documentPaper" />
                  <Display>
                    <Text variant="caption-02" color="text-light">
                      Certificado
                    </Text>
                    <Text
                      variant="caption-02"
                      color="text"
                      data-testid="resumoTransferenciaInternaCertificadoVgbl"
                    >
                      {tryGetValueOrDefault(
                        [dadosOrigem?.certificadoVgbl],
                        '-',
                      )}
                    </Text>
                  </Display>
                </S.ContainerIconInfo>
                <S.ContainerIconInfo>
                  <Icon name="user" />
                  <Display>
                    <Text variant="caption-02" color="text-light">
                      Nome
                    </Text>
                    <Text
                      variant="caption-02"
                      color="text"
                      data-testid="resumoTransferenciaInternaNomeClienteVgbl"
                    >
                      {tryGetValueOrDefault(
                        [dadosOrigem?.nomeClienteVgbl],
                        '-',
                      )}
                    </Text>
                  </Display>
                </S.ContainerIconInfo>
                <S.ContainerIconInfo>
                  <Icon name="documentBack" />
                  <Display>
                    <Text variant="caption-02" color="text-light">
                      Plano
                    </Text>
                    <Text
                      variant="caption-02"
                      color="text"
                      data-testid="resumoTransferenciaInternaNomeProdutoVgbl"
                    >
                      {tryGetValueOrDefault(
                        [dadosOrigem?.nomeProdutoVgbl],
                        '-',
                      )}
                    </Text>
                  </Display>
                </S.ContainerIconInfo>
                <S.ContainerIconInfo>
                  <Icon name="patient" />
                  <Display>
                    <Text variant="caption-02" color="text-light">
                      Tábua Atuarial
                    </Text>
                    <Text
                      variant="caption-02"
                      color="text"
                      data-testid="resumoTransferenciaInternaTabuaAtuarial"
                    >
                      {tryGetValueOrDefault([dadosOrigem?.tabua], '-')}
                    </Text>
                  </Display>
                </S.ContainerIconInfo>
              </Grid.Item>
              <Grid.Item xs={1} md={1 / 2}>
                <S.ContainerIconInfo>
                  <Icon name="chart" />
                  <Display>
                    <Text variant="caption-02" color="text-light">
                      Regime Tributário
                    </Text>
                    <Text
                      variant="caption-02"
                      color="text"
                      data-testid="resumoTransferenciaInternaRegimeTributarioVgbl"
                    >
                      {tryGetValueOrDefault(
                        [dadosOrigem?.regimeTributarioVgbl],
                        '-',
                      )}
                    </Text>
                  </Display>
                </S.ContainerIconInfo>
                <S.ContainerIconInfo>
                  <Icon name="financialMoneyBag" />
                  <Display>
                    <Text variant="caption-02" color="text-light">
                      Fundos
                    </Text>
                    {fundosAtuais}
                  </Display>
                </S.ContainerIconInfo>
                <S.ContainerIconInfo>
                  <Icon name="prestamista" />
                  <Display>
                    <Text variant="caption-02" color="text-light">
                      Saldo
                    </Text>
                    <Text
                      variant="caption-02"
                      color="text"
                      data-testid="resumoTransferenciaInternaSaldoTotalFundosAtuais"
                    >
                      {tryGetMonetaryValueOrDefault(saldoTotalFundosAtuais)}
                    </Text>
                  </Display>
                </S.ContainerIconInfo>
              </Grid.Item>
            </RenderConditional>
          </Grid>
        </Card.Content>
      </Card>
    </>
  );
};

export default ResumoTransferenciaInterna;
