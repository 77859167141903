import styled from 'styled-components';
import { Card as CardDS } from '@cvp/design-system/react';

export const Card = styled(CardDS)`
  @media print {
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
`;

export const CardContent = styled(CardDS.Content)`
  @media print {
    padding: 0;
  }
`;
