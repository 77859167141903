import React, { useContext } from 'react';
import { Display, Text, IconButton, Tooltip } from '@cvp/design-system/react';
import { TArquivoProps } from 'painelAdministracao/types/ListagemTypes';
import { ContainerAccordion } from 'painelAdministracao/pages/styles';
import { BOTOES } from 'painelAdministracao/constants/constants';
import masks from 'main/utils/masks';
import Icon from 'main/components/Icon';
import { AdmContext } from 'painelAdministracao/context/AdministracaoContext';

const Arquivo: React.FC<TArquivoProps> = ({ fop, arquivo }) => {
  const { handleEditarFop } = useContext(AdmContext);

  return (
    <ContainerAccordion>
      <Display alignItems="center">
        <Text variant="body02-sm">
          <b>
            FOP {`000${fop.codigo}`.slice(-3)}{' '}
            {`00${fop.numeroVersao}`.slice(-2)}{' '}
          </b>
          - {arquivo?.nomeArquivo} - {masks.dateFop.mask(fop.dataVersao)}
        </Text>
        <Tooltip variant="gray" text={BOTOES.EDITAR} position="top">
          <IconButton
            small
            variant="outlined"
            onClick={() => handleEditarFop(fop, arquivo)}
          >
            <Icon name="edit" />
          </IconButton>
        </Tooltip>
      </Display>
    </ContainerAccordion>
  );
};

export default Arquivo;
