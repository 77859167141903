/* eslint-disable @typescript-eslint/no-explicit-any  */
import { useHistory } from 'react-router';

const usePrevNavigation = (): {
  navigateTo: (path: string, data?: any) => void;
  navigateToVida: (path: string, data?: any) => void;
  goHomeVida: () => void;
  goHome: () => void;
  goDadosPlano: () => void;
} => {
  const history = useHistory();

  return {
    navigateTo: (path: string, data: any) =>
      history.push(`/cliente/produtos/previdencia/${path}`, data),
    navigateToVida: (path: string, data: any) =>
      history.push(`/cliente/produtos/vida/${path}`, data),
    goHomeVida: () => history.push(`/cliente/produtos/vida`),
    goHome: () => history.push(`/cliente/produtos/`),
    goDadosPlano: () =>
      history.push(`/cliente/produtos/previdencia/dados-plano`),
  };
};

export default usePrevNavigation;
