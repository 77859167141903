import { obterEndpointDadosPlano } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { IResponseDadosPlano } from '../types/IResponseDadosPlano';

export const obterDadosPlano = async (
  Cpf: string,
  NumeroCertificado: string,
): Promise<IResponseDadosPlano | undefined> => {
  const { data } = await api.post<IApiResponse<IResponseDadosPlano>>(
    obterEndpointDadosPlano(),
    {
      Cpf,
      NumeroCertificado,
    },
  );

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data?.dados?.entidade;
};
