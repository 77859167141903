import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  Display,
  Card,
  Button,
  Grid,
  Text,
  Select,
} from '@cvp/design-system/react';
import { Field, FieldProps, Formik } from 'formik';
import Modal from 'main/components/Modal';
import { useToast } from 'main/hooks/useToast';
import masks from 'main/utils/masks';
import { useValidarConta } from 'main/features/ValidacaoContaCaixa/hooks/useValidarConta';
import { TextField } from 'main/features/Auth/components';
import SkeletonLoading from 'main/components/SkeletonLoading';
import RenderConditional from 'main/components/RenderConditional';
import { useListaBancos } from 'main/features/ValidacaoContaCaixa/hooks/useListaBancos';
import { verificarContaValida } from 'main/features/ValidacaoContaCaixa/utils/verificarContaValida';
import {
  validarCpf,
  validarSelect,
  validarCampo,
} from 'reter/features/retencao/utils/validarCamposForm';
import { useAlterarDadosBancarios } from 'reter/features/retencao/hooks/useAlterarDadosBancarios';
import { habilitarAtualizacaoForm } from 'reter/features/retencao/utils/habilitarAtualizacaoForm';
import { NumeroInstituicao } from 'reter/features/retencao/utils/enum';
import { defaultFormFieldPropsFactory } from 'reter/features/retencao/factories/defaultFormFieldPropsFactory';
import { DADOS_OPERACAO } from 'reter/features/retencao/constants/constants';
import { LocationState } from 'reter/features/retencao/types/LocationState';
import { FormState } from 'reter/features/retencao/types/FormState';
import { initialFormStateFactory } from 'reter/features/retencao/factories/initialFormStateFactory';
import * as S from 'reter/features/retencao/pages/AlterarDadosBancarios/styles';

const AlterarDadosBancarios: React.FC = () => {
  const history = useHistory();
  const { toastError } = useToast();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [operacaoFlag, setOperacaoFlag] = useState<string>('');
  const [formState, setFormState] = useState<FormState>(
    initialFormStateFactory,
  );

  const {
    state: { numeroResgate },
  } = useLocation<LocationState>();

  const {
    response: listaBancos,
    loading: isFetchingBankList,
    obterListaBancos,
  } = useListaBancos();

  const {
    response: respostaValidada,
    loading: isFetchingValidate,
    obterValidacaoConta,
  } = useValidarConta();

  const {
    response: mensagemAtualizacaoDadosBancarios,
    loading: isFetchingUpdateForm,
    obterAtualizacaoDadosBancarios,
  } = useAlterarDadosBancarios();

  const handleFormSubmit = (values: FormState): void => {
    const obterNomeInstituicao = listaBancos?.find(
      val => val.codigoBanco === values.codigoInstituicao,
    )?.nomeBanco;

    setFormState(prevState => ({
      ...prevState,
      ...values,
      nomeInstituicao: obterNomeInstituicao,
    }));

    if (values.codigoInstituicao === NumeroInstituicao.CONTA_CAIXA) {
      obterValidacaoConta({ ...values });
      return;
    }

    obterAtualizacaoDadosBancarios({
      ...values,
      numResgate: numeroResgate,
      nomeInstituicao: obterNomeInstituicao,
    });
  };

  const handleSubmitModal = (): void => {
    setOpenModal(false);
    history.push('/reter/recursos-pendentes-recebimento');
  };

  useEffect(() => {
    if (isFetchingValidate) {
      return;
    }

    if (verificarContaValida(respostaValidada?.codigoRetorno)) {
      toastError(respostaValidada?.descricaoMensagemSistema);
    }

    if (
      respostaValidada &&
      !verificarContaValida(respostaValidada?.codigoRetorno)
    ) {
      obterAtualizacaoDadosBancarios({
        ...formState,
        numResgate: numeroResgate,
      });
    }
  }, [respostaValidada, isFetchingValidate]);

  useEffect(() => {
    if (isFetchingUpdateForm) {
      return;
    }

    if (mensagemAtualizacaoDadosBancarios) {
      setOpenModal(true);
    }
  }, [mensagemAtualizacaoDadosBancarios, isFetchingUpdateForm]);

  useEffect(() => {
    obterListaBancos();
  }, []);

  if (isFetchingBankList) {
    return <SkeletonLoading blocks={1} lines={6} />;
  }

  return (
    <Display type="display-block">
      <Formik
        initialValues={initialFormStateFactory}
        onSubmit={values => handleFormSubmit(values)}
      >
        {({ handleSubmit, handleBlur, values, setFieldValue }) => (
          <Card>
            <Card.Content>
              <Grid.Item xs={1}>
                <Text variant="body02-md" color="primary">
                  Alteração de conta para débito
                </Text>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Text variant="body02-ms" color="text">
                  Informe a conta que seu cliente deseja resgatar a reserva
                  financeira:
                </Text>
              </Grid.Item>
              <form onSubmit={handleSubmit}>
                <Grid>
                  <Grid.Item xs={1} md={1 / 3}>
                    <Field name="cpf" validate={validarCpf}>
                      {({ field, meta }: FieldProps) => (
                        <TextField
                          {...field}
                          onBlur={handleBlur}
                          {...defaultFormFieldPropsFactory(
                            meta,
                            '*CPF',
                            setFieldValue,
                            'cpf',
                            'Digite o CPF',
                            'inputCpf',
                            masks.cpf.mask(values.cpf),
                          )}
                        />
                      )}
                    </Field>
                  </Grid.Item>
                  <Grid.Item xs={1} md={1 / 3}>
                    <Field name="nome" validate={validarCampo}>
                      {({ field, meta }: FieldProps) => (
                        <TextField
                          {...field}
                          onBlur={handleBlur}
                          {...defaultFormFieldPropsFactory(
                            meta,
                            '*Nome',
                            setFieldValue,
                            'nome',
                            'Digite o nome',
                            'inputNome',
                            values.nome,
                          )}
                        />
                      )}
                    </Field>
                  </Grid.Item>
                </Grid>
                <RenderConditional
                  condition={Boolean(listaBancos)}
                  component={
                    <Grid>
                      <Grid.Item xs={1} md={1 / 3} xl={1 / 3}>
                        <Field
                          name="codigoInstituicao"
                          validate={validarSelect}
                        >
                          {({ field, meta }: FieldProps) => (
                            <S.Select
                              {...field}
                              {...defaultFormFieldPropsFactory(
                                meta,
                                '*Código da instituição',
                                setFieldValue,
                                'codigoInstituicao',
                                'Selecione a instituição',
                                'selectCodigoInstituicao',
                              )}
                              onChange={({
                                target: { value },
                              }: React.ChangeEvent<HTMLInputElement>) => {
                                setOperacaoFlag(value);
                                setFieldValue('codigoInstituicao', value);
                              }}
                              error={meta.error}
                              errorMessage={meta.error}
                            >
                              {listaBancos?.map(item => (
                                <Select.Item
                                  key={item.codigoBanco}
                                  value={item.codigoBanco}
                                  text={`${item.codigoBanco} - ${item.nomeBanco}`}
                                  selected={
                                    values.codigoInstituicao ===
                                    item.codigoBanco
                                  }
                                />
                              ))}
                            </S.Select>
                          )}
                        </Field>
                      </Grid.Item>
                    </Grid>
                  }
                />
                <Grid>
                  <Grid.Item xs={1} md={1 / 3} xl={1 / 3}>
                    <Field name="agencia" validate={validarCampo}>
                      {({ field, meta }: FieldProps) => (
                        <TextField
                          {...field}
                          {...defaultFormFieldPropsFactory(
                            meta,
                            '*Agência',
                            setFieldValue,
                            'agencia',
                            'Digite a agência',
                            'inputAgencia',
                            values.agencia,
                          )}
                          onBlur={handleBlur}
                        />
                      )}
                    </Field>
                  </Grid.Item>
                  <Grid.Item xs={1} md={1 / 3} xl={1 / 3}>
                    <Field name="digitoAgencia">
                      {({ field, meta }: FieldProps) => (
                        <TextField
                          {...field}
                          {...defaultFormFieldPropsFactory(
                            meta,
                            'Dígito',
                            setFieldValue,
                            'digitoAgencia',
                            'Digite o dígito',
                            'inputDigitoAgencia',
                            values.digitoAgencia,
                          )}
                          onBlur={handleBlur}
                        />
                      )}
                    </Field>
                  </Grid.Item>
                </Grid>
                <RenderConditional
                  condition={operacaoFlag === NumeroInstituicao.CONTA_CAIXA}
                  component={
                    <Grid>
                      <Grid.Item xs={1} md={1 / 3} xl={1 / 3}>
                        <Field name="operacao">
                          {({ field, meta }: any) => (
                            <S.Select
                              {...field}
                              {...defaultFormFieldPropsFactory(
                                meta,
                                'Operação',
                                setFieldValue,
                                'operacao',
                                'Selecione a operação',
                              )}
                              data-testid="selectOperacao"
                            >
                              {DADOS_OPERACAO.map(item => (
                                <Select.Item
                                  key={item.id}
                                  text={item.text}
                                  value={item.value}
                                  selected={values.operacao === item.value}
                                />
                              ))}
                            </S.Select>
                          )}
                        </Field>
                      </Grid.Item>
                    </Grid>
                  }
                />
                <Grid>
                  <Grid.Item xs={1} md={1 / 3}>
                    <Field name="conta" validate={validarCampo}>
                      {({ field, meta }: FieldProps) => (
                        <TextField
                          {...field}
                          onBlur={handleBlur}
                          {...defaultFormFieldPropsFactory(
                            meta,
                            '*Conta',
                            setFieldValue,
                            'conta',
                            'Digite a conta',
                            'inputConta',
                            values.conta,
                          )}
                        />
                      )}
                    </Field>
                  </Grid.Item>
                  <Grid.Item xs={1} md={1 / 3}>
                    <Field name="digito" validate={validarCampo}>
                      {({ field, meta }: FieldProps) => (
                        <TextField
                          {...field}
                          onBlur={handleBlur}
                          {...defaultFormFieldPropsFactory(
                            meta,
                            '*Dígito',
                            setFieldValue,
                            'digito',
                            'Digite o dígito',
                            'inputDigito',
                            values.digito,
                          )}
                        />
                      )}
                    </Field>
                  </Grid.Item>
                </Grid>
                <Grid>
                  <Grid.Item xs={1} md={1 / 3}>
                    <Display>
                      <Button
                        variant="outlined"
                        type="button"
                        onClick={() => history.goBack()}
                      >
                        Voltar
                      </Button>
                      <Button
                        loading={isFetchingValidate || isFetchingUpdateForm}
                        type="submit"
                        disabled={habilitarAtualizacaoForm(
                          values,
                          'operacao',
                          'digitoAgencia',
                          'nomeInstituicao',
                        )}
                        data-testid="atualizarDadosBancarios"
                      >
                        Atualizar
                      </Button>
                    </Display>
                  </Grid.Item>
                </Grid>
              </form>
            </Card.Content>
          </Card>
        )}
      </Formik>
      <Modal open={openModal} onClose={() => handleSubmitModal()}>
        <Text variant="body01-md" color="text">
          {mensagemAtualizacaoDadosBancarios}
        </Text>
        <Display type="inline-block">
          <Button variant="primary" onClick={() => handleSubmitModal()}>
            OK
          </Button>
        </Display>
      </Modal>
    </Display>
  );
};

export default AlterarDadosBancarios;
