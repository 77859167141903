import styled from 'styled-components/macro';
import { Button } from '@cvp/design-system/react';

export const ButtonVoltarStyle = styled(Button)(({ theme }) => ({
  width: '100%',
  marginBottom: '15px',
  [theme.breakpoint.md()]: {
    width: '46px',
    ':hover': {
      backgroundColor: theme.color.neutral['08'],
      color: theme.color.brandPrimary.medium,
    },
  },
}));

export const ButtonAlterarStyle = styled(Button)(({ theme }) => ({
  width: '100%',
  [theme.breakpoint.md()]: {
    width: '46px',
    height: '46px',
  },
}));

export const ContainerButtons = styled.div(({ theme }) => ({
  padding: '20px',
  justifyContent: 'start',
  gap: '50px',

  [theme.breakpoint.md()]: {
    display: 'flex',
  },
}));
