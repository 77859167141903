import styled from 'styled-components';

export const ResgateInfoContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const WrapperContentInfo = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 18,
});

export const WrapperSection = styled.div(({ theme }) => ({
  width: '100%',
  marginTop: 16,
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoint.md()]: {
    display: 'grid',
    gridTemplateColumns: '2fr 2fr',
  },
}));

export const WrapperInfo = styled.div(({ theme }) => ({
  border: `1px solid ${theme.color.brandPrimary.light}`,
  padding: 20,
  borderRadius: 8,
}));

export const TextInfo = styled.p<{ maxWidth?: number; marginRight?: number }>(
  ({ maxWidth, marginRight }) => ({
    fontSize: 18,
    color: '#414042',
    marginRight: marginRight || 10,
    maxWidth: maxWidth || 130,
  }),
);

export const Section = styled.div<{ isBorderLeft?: boolean }>(
  ({ theme, isBorderLeft }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: 20,

    [theme.breakpoint.md()]: {
      borderLeft: isBorderLeft ? `1px solid ${theme.color.neutral['03']}` : 0,
    },
  }),
);
