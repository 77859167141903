import { ISelectOption } from '../features/fops/pages/ISelectOption';

export enum EnumFopPrevidencia {
  prevManutencao = 'prev_manutencao',
  Suspenso = 'U',
  Beneficio = 'B',
}

export const selectOptionsFilial: ISelectOption[] = [
  { key: 'centroOeste', value: 'CENTRO OESTE' },
  { key: 'nordeste', value: 'NORDESTE' },
  { key: 'norte', value: 'NORTE' },
  { key: 'saoPaulo', value: 'SÃO PAULO' },
  { key: 'sudeste', value: 'SUDESTE' },
  { key: 'sul', value: 'SUL' },
  { key: 'sured', value: 'SURED' },
];
export const selectOptLiberacaoDaReserva: ISelectOption[] = [
  { key: 'sugestao', value: 'Sugestão da Caixa Seguradora (ver tabela)' },
  {
    key: 'liberacaoTotal',
    value:
      'Liberação total da reserva (100% da reserva constituída pela empresa)',
  },
  { key: 'semLiberacao', value: 'Sem liberação da reserva' },
  { key: 'outraRegra', value: 'Definir outra regra de liberação da reserva' },
];

export const selectOptionsRegra: ISelectOption[] = [
  { key: 'aposentadoria', value: 'Valor de aposentadoria' },
  {
    key: 'contribuicaoMensal',
    value: 'Valor de contribuição mensal',
  },
  {
    key: 'contribuicaoAnual',
    value: 'Valor de contribuição Anual',
  },
];

export const selectOptionsBeneficio: ISelectOption[] = [
  { key: 'rendaTemporaria', value: 'Renda temporária' },
  {
    key: 'prazoMinimoGarantido',
    value: 'Prazo mínimo garantido',
  },
  {
    key: 'vitalicia',
    value: 'Vitalícia',
  },
  {
    key: 'vitaliciareversivel',
    value: 'Vitalícia Reversível',
  },
  {
    key: 'rendaPorPrazoCerto',
    value: 'Renda por prazo certo',
  },
];

export const selectOptionsPrazoBeneficio: ISelectOption[] = [
  { key: '001', value: '1' },
  { key: '002', value: '2' },
  { key: '003', value: '3' },
  { key: '004', value: '4' },
  { key: '005', value: '5' },
  { key: '006', value: '6' },
  { key: '007', value: '7' },
  { key: '008', value: '8' },
  { key: '009', value: '9' },
  { key: '10', value: '10' },
];

export const selectOptionsReversao: ISelectOption[] = [
  { key: '50', value: '50%' },
  { key: '75', value: '75%' },
  { key: '100', value: '100%' },
];

export const selectOptionsPagamento: ISelectOption[] = [
  {
    key: 'averbado',
    value: 'Averbado: Pagamento feito apenas pelo participante',
  },
  {
    key: 'instituido',
    value: 'Instituído: Pagamento feito apenas pela empresa',
  },
  {
    key: 'planoInstituido',
    value:
      'Plano Instituído com Divisão de Pagamento: O Pagamento é feito pela empresa e também pelo participante',
  },
];

export const selectOptionsValoresParticipantes: ISelectOption[] = [
  {
    key: 'sim',
    value: 'SIM',
  },
  {
    key: 'nao',
    value: 'NÃO',
  },
];

export const selectOptionsValorContribuicao: ISelectOption[] = [
  {
    key: 'valorFixoContribuicao',
    value: 'Valor fixo',
  },
  {
    key: 'valorBaseContribuicao',
    value: 'Valor com base no percentual do salário',
  },
  {
    key: 'outraFormaContribuicao',
    value: 'Outra Forma de Pagamento',
  },
];

export const selectOptionsCuidadoExtra: ISelectOption[] = [
  {
    key: 'peculio',
    value: 'Pecúlio',
  },
  {
    key: 'pensao',
    value: 'Pensão por prazo certo',
  },
  {
    key: 'semCuidadoExtra',
    value: 'Sem cuidado extra',
  },
];

export const selectOptionsRegraCuidado: ISelectOption[] = [
  {
    key: 'valorIndenizacao',
    value: 'Valor da Indenização',
  },
  {
    key: 'pagamentoMensal',
    value: 'Valor de pagamento mensal',
  },
  {
    key: 'pagamentoAnual',
    value: 'Valor de pagamento anual',
  },
];

export const selectOptionsRegraCuidadoPensao: ISelectOption[] = [
  {
    key: 'valorIndenizacaoRecebido',
    value: 'Valor de Indenização: estipular o valor a ser recebido',
  },
  {
    key: 'valorContribuicaoMensal',
    value:
      'Valor de contribuição mensal: quanto o participante irá contribuir mensalmente',
  },
];

export const selectOptionsAnosPensao: ISelectOption[] = [
  {
    key: '1',
    value: '1',
  },
  {
    key: '2',
    value: '2',
  },
  {
    key: '3',
    value: '3',
  },
  {
    key: '4',
    value: '4',
  },
  {
    key: '5',
    value: '5',
  },
  {
    key: '6',
    value: '6',
  },
  {
    key: '7',
    value: '7',
  },
  {
    key: '8',
    value: '8',
  },
  {
    key: '9',
    value: '9',
  },
  {
    key: '10',
    value: '10',
  },
];

// FOP 063

export const selectOptionsFaturamento: ISelectOption[] = [
  {
    key: 'microempresa',
    value: 'Microempresa -  Menor ou igual a R$ 360 mil',
  },
  {
    key: 'pequenaEmpresa',
    value:
      'Pequena empresa - Maior que R$ 360 mil e menor ou igual a R$ 4,8 milhões',
  },
  {
    key: 'mediaEmpresa',
    value:
      'Média empresa - Maior que R$ 4,8 milhões e menor ou igual a R$ 300 milhões',
  },
  {
    key: 'grandeEmpresa',
    value: 'Grande empresa - Maior que R$ 300 milhões',
  },
];

export const selectOptionsRegraCuidado63: ISelectOption[] = [
  {
    key: 'valorIndenizacao',
    value: 'Valor da Indenização',
  },
  {
    key: 'pagamentoMensal',
    value: 'Valor de pagamento mensal',
  },
];

export const selectOptionsTipoFundo: ISelectOption[] = [
  { key: 'rendaFixaPosFixado', value: 'Renda Fixa Pós Fixado' },
  { key: 'rendaFixa', value: 'Renda Fixa' },
  { key: 'creditoPrivado', value: 'Crédito Privado' },
  {
    key: 'multiEstrategiaLivreConservador',
    value: 'Multi Estratégia Livre Conservador',
  },
  { key: 'rendaFixaModerado', value: 'Renda Fixa Moderado' },
  { key: 'indicePrecos', value: 'Índice de preços' },
  {
    key: 'MultiRendaVariavel15',
    value: 'Multi Renda Variável 15',
  },
  {
    key: 'multiEstrategiaLivreModerado',
    value: 'Multi Estratégia Livre Moderado',
  },
  {
    key: 'Inflacao Ativa',
    value: 'Inflação Ativa',
  },
  {
    key: 'multiRendaVariavel30',
    value: 'Multi Renda Variável 30',
  },
  {
    key: 'multiRendaVariavel49',
    value: 'Multi Renda Variável 49',
  },
  {
    key: 'multiRendaVariavel70',
    value: 'Multi Renda Variável 70',
  },
  {
    key: 'multiRendaVariavel70Livre',
    value: 'Multi Renda Variável 70 Livre',
  },
];

export const selectOptionsConcessaoBeneficio: ISelectOption[] = [
  { key: 'idadeAposentadoria', value: 'Idade de aposentadoria' },
  {
    key: 'prazoContribuicao',
    value: 'Prazo da contribuição (diferimento)',
  },
];
export const selectOptionsPagamentoFatura: ISelectOption[] = [
  { key: 'aporteUnico', value: 'Aporte único' },
  {
    key: 'mensal',
    value: 'Mensal',
  },
];
export const selectOptionsOperacao: ISelectOption[] = [
  { key: '022', value: '022' },
  {
    key: '003',
    value: '003',
  },
];
export const selectOptionsDadosCobranca: ISelectOption[] = [
  { key: 'boleto', value: 'Boleto' },
  {
    key: 'debito',
    value: 'Débito em conta',
  },
];

export const selectOptionsVencimentoFatura: ISelectOption[] = [
  { key: '5', value: '5' },
  { key: '6', value: '6' },
  { key: '7', value: '7' },
  { key: '8', value: '8' },
  { key: '9', value: '9' },
  { key: '10', value: '10' },
  { key: '11', value: '11' },
  { key: '12', value: '12' },
  { key: '13', value: '13' },
  { key: '14', value: '14' },
  { key: '15', value: '15' },
  { key: '16', value: '16' },
  { key: '17', value: '17' },
  { key: '18', value: '18' },
  { key: '19', value: '19' },
  { key: '20', value: '20' },
  { key: '21', value: '21' },
  { key: '22', value: '22' },
  { key: '23', value: '23' },
  { key: '24', value: '24' },
  { key: '25', value: '25' },
];

export const selectOptionsModalidade: ISelectOption[] = [
  { key: 'PGBL', value: 'PGBL' },
  { key: 'VGBL', value: 'VGBL' },
  { key: 'PGBLVGBL', value: 'PGBL e VGBL' },
];

export const selectOptionsModalidadePGBL: ISelectOption[] = [
  { key: 'PGBL', value: 'PGBL' },
];
export const selectOptionsModalidadeVGBL: ISelectOption[] = [
  { key: 'PGBL', value: 'PGBL' },
  { key: 'PGBLVGBL', value: 'PGBL e VGBL' },
];

export const selectOptionsUF: ISelectOption[] = [
  { key: 'AC', value: 'AC' },
  { key: 'AL', value: 'AL' },
  { key: 'AM', value: 'AM' },
  { key: 'AP', value: 'AP' },
  { key: 'BA', value: 'BA' },
  { key: 'CE', value: 'CE' },
  { key: 'DF', value: 'DF' },
  { key: 'ES', value: 'ES' },
  { key: 'GO', value: 'GO' },
  { key: 'MA', value: 'MA' },
  { key: 'MG', value: 'MG' },
  { key: 'MS', value: 'MS' },
  { key: 'MT', value: 'MT' },
  { key: 'PA', value: 'PA' },
  { key: 'PB', value: 'PB' },
  { key: 'PE', value: 'PE' },
  { key: 'PI', value: 'PI' },
  { key: 'PR', value: 'PR' },
  { key: 'RJ', value: 'RJ' },
  { key: 'RN', value: 'RN' },
  { key: 'RO', value: 'RO' },
  { key: 'RR', value: 'RR' },
  { key: 'RS', value: 'RS' },
  { key: 'SC', value: 'SC' },
  { key: 'SE', value: 'SE' },
  { key: 'SP', value: 'SP' },
  { key: 'TO', value: 'TO' },
];

export const selectOptionsRegraAporte: ISelectOption[] = [
  { key: 'semAporte', value: 'Sem aporte inicial' },
  {
    key: 'comAporte',
    value: 'Com aporte inicial',
  },
  {
    key: 'portabilidade',
    value: 'Portabilidade',
  },
];
