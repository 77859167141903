import React, { useState } from 'react';
import {
  Grid,
  Card,
  Display,
  Text,
  Button,
  Radio,
} from '@cvp/design-system/react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import RenderConditional from 'main/components/RenderConditional';
import masks from 'main/utils/masks';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { useTransferenciaInternaContext } from 'previdencia/features/TransferenciaInternaEmissaoConjugado/contexts/TransferenciaInternaContext';
import useFormaPagamento from 'previdencia/features/TransferenciaInternaEmissaoConjugado/hooks/useFormaPagamento';
import FormCadastrarNovaConta from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/FormCadastrarNovaConta';
import * as RESPONSE_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblResponse';
import * as CONSTS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/constants/constants';
import * as TRANSF_VGBL_STYLES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/styles';
import { toastError } from 'main/hooks/useToast';

const FormaPagamento: React.FC = () => {
  const {
    incluirCuidadoGarantido,
    confirmarAquisicao,
    salvarContaCadastradaSelecionada,
  } = useTransferenciaInternaContext();

  const [selecionarContaCadastrada, setSelecionarContaCadastrada] =
    useState<RESPONSE_TYPES.IListaDadosBancariosVgbl>();

  const [exibirFormNovaConta, setExibirFormNovaConta] =
    useState<boolean>(false);

  const {
    listaDadosBancariosVgbl,
    loadingListaDadosBancariosVgbl,
    listarDadosBancariosVgbl,
    listaDiasUteis,
    loadingListaDiasVencimento,
    listaTiposOperacoesBancarias,
    loadingListaTiposOperacoesBancariasVgbl,
    loadingInserirDadosBancariosTransfVgbl,
    inserirDadosBancarios,
    formularioDadosBancarios,
    setFormularioDadosBancarios,
  } = useFormaPagamento();

  const handleChangeContaCadastrada = (
    conta: RESPONSE_TYPES.IListaDadosBancariosVgbl,
  ): void => {
    setSelecionarContaCadastrada(conta);
    salvarContaCadastradaSelecionada(conta);
  };

  const transformarDadosTabelaListaContasCadastradas = () => {
    return listaDadosBancariosVgbl?.entidade?.dadosBancarios?.map(item => ({
      ...item,
      check: (
        <Radio
          name="group"
          value={item}
          checked={item.conta === selecionarContaCadastrada?.conta}
          onChange={() => handleChangeContaCadastrada(item)}
        />
      ),
      agencia: masks.agency.mask(item.agencia),
      diaVencimento: `Dia ${item.diaVencimento}`,
      conta: `${item.conta}-${item.digitoConta}`,
    }));
  };

  const validarRetornoInserirContaBancaria = () => {
    inserirDadosBancarios(selecionarContaCadastrada)
      .then(res => {
        if (res?.entidade) {
          confirmarAquisicao();
        } else {
          toastError('Erro ao enviar dados bancários');
        }
      })
      .catch(() => toastError('Erro ao enviar dados bancários'));
  };

  return (
    <>
      <Display type="display-block">
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-05" color="primary" margin>
                  {CONSTS.TEXTOS_FORMA_PAGAMENTO.TITULO}
                </Text>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Text variant="body02-md" color="text-light" margin>
                  {CONSTS.TEXTOS_FORMA_PAGAMENTO.SUBTITULO}
                </Text>
                <Text variant="body02-md" margin>
                  {CONSTS.TEXTOS_FORMA_PAGAMENTO.DESCRICAO}
                </Text>

                <RenderConditional
                  condition={checkIfSomeItemsAreTrue([
                    loadingListaDadosBancariosVgbl,
                    loadingInserirDadosBancariosTransfVgbl,
                  ])}
                >
                  <SkeletonLoading blocks={1} />
                </RenderConditional>

                <RenderConditional
                  condition={checkIfAllItemsAreTrue([
                    !loadingListaDadosBancariosVgbl,
                    !loadingInserirDadosBancariosTransfVgbl,
                  ])}
                >
                  <TRANSF_VGBL_STYLES.TabelaTransferenciaInterna
                    columns={CONSTS.COLUNAS_TABELA_FORMA_PAGAMENTO}
                    noHeader
                    striped
                    highlightOnHover
                    responsive
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={{
                      rowsPerPageText: 'Items por página',
                      rangeSeparatorText: 'de',
                    }}
                    title="Cuidado Extra"
                    data={tryGetValueOrDefault(
                      [transformarDadosTabelaListaContasCadastradas()],
                      [],
                    )}
                    noDataComponent={CONSTS.NAO_HA_DADOS_TABELA}
                  />
                </RenderConditional>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Display>
                  <Button
                    variant={exibirFormNovaConta ? 'secondary' : 'primary'}
                    onClick={() =>
                      setExibirFormNovaConta(exibirForm => !exibirForm)
                    }
                    disabled={checkIfSomeItemsAreTrue([
                      loadingListaDadosBancariosVgbl,
                      loadingListaDiasVencimento,
                      loadingListaTiposOperacoesBancariasVgbl,
                    ])}
                    loading={checkIfSomeItemsAreTrue([
                      loadingListaDadosBancariosVgbl,
                      loadingListaDiasVencimento,
                      loadingListaTiposOperacoesBancariasVgbl,
                    ])}
                  >
                    {exibirFormNovaConta
                      ? CONSTS.TEXTOS_FORMA_PAGAMENTO.BOTAO_OCULTAR_FORMULARIO
                      : CONSTS.TEXTOS_FORMA_PAGAMENTO.BOTAO_ADD_CONTA_CAIXA}
                  </Button>
                </Display>
              </Grid.Item>
              <RenderConditional condition={exibirFormNovaConta}>
                <Grid.Item xs={1}>
                  <FormCadastrarNovaConta
                    exibirForm={setExibirFormNovaConta}
                    loadingInserirDadosBancariosTransfVgbl={
                      loadingInserirDadosBancariosTransfVgbl
                    }
                    listaTiposOperacoesBancarias={listaTiposOperacoesBancarias}
                    listarDadosBancariosVgbl={listarDadosBancariosVgbl}
                    listaDiasUteis={listaDiasUteis}
                    inserirDadosBancarios={inserirDadosBancarios}
                    formularioDadosBancarios={formularioDadosBancarios}
                    setFormularioDadosBancarios={setFormularioDadosBancarios}
                  />
                </Grid.Item>
              </RenderConditional>
              <Grid.Item xs={1}>
                <Display>
                  <Button variant="outlined" onClick={incluirCuidadoGarantido}>
                    Voltar
                  </Button>
                  <Button
                    onClick={validarRetornoInserirContaBancaria}
                    disabled={!selecionarContaCadastrada}
                    loading={loadingListaDadosBancariosVgbl}
                  >
                    Continuar
                  </Button>
                </Display>
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </Display>
    </>
  );
};

export default FormaPagamento;
