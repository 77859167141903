/**
 * @param date data a ser formatada
 * @returns ex: 02 de abril de 2021
 */
export const formatarDataAmigavel = (date?: string): string => {
  if (!date) return '';
  return new Date(date).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
};

/**
 *
 * @param date datetime
 * @returns 02 de abril de 2021 às 16:00
 */

export const formatarDataHoraAmigavel = (date?: string): string => {
  if (!date) return '';
  const dateFormatted = new Date(date).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
  const hourFormatted = new Date(date).toLocaleTimeString('pt-BR', {
    hour12: false,
  });

  return `${dateFormatted} às ${hourFormatted}`;
};

/**
 *
 * @param date datetime
 * @returns 02/04/2021 às 16:00
 */
export const formatarDataHora = (date?: string): string => {
  if (!date) return '';
  const dateFormatted = new Date(date).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const hourFormatted = new Date(date).toLocaleTimeString('pt-BR', {
    hour12: false,
  });

  return `${dateFormatted} - ${hourFormatted}`;
};

/**
 *
 * @param date datetime
 * @returns 02/04/2021
 */

export const formatarData = (date?: string | Date): string => {
  if (!date) return '';
  const dateFormatted = new Date(date).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  });
  return `${dateFormatted}`;
};

/**
 *
 * @param date partial date string
 * @description input: 0301, output: 03/01
 * @returns partial masked date string
 */

export const maskDate = (date?: string): string => {
  if (!date) return '';
  const onlyDateNumbers = date.replace(/\D/g, '');
  if (onlyDateNumbers.length < 3) {
    return onlyDateNumbers;
  }
  const formatedDateArray = onlyDateNumbers.split('');
  if (onlyDateNumbers.length < 5) {
    formatedDateArray.splice(2, 0, '/');
  } else {
    formatedDateArray.splice(2, 0, '/');
    formatedDateArray.splice(5, 0, '/');
  }
  return formatedDateArray.join('');
};

export const currentDate = (): string => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getUTCDate();

  if (month < 10 && day > 9) {
    return `${year}-0${month}-${day}T00:00:00`;
  }
  if (month < 10 && day < 10) {
    return `${year}-0${month}-0${day}T00:00:00`;
  }
  if (month > 9 && day < 10) {
    return `${year}-${month}-0${day}T00:00:00`;
  }
  return `${year}-${month}-${day}T00:00:00`;
};

/**
 *
 * @param date ISO String date
 * @description ex: 2022-03-11T22:20:42.637Z
 * @returns 1646967600000
 */
export const dateWithoutTime = (date: string) => {
  if (!date) return '';
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

/**
 *
 * @param date
 * @description ex: 2022-03-11T22:20:42.637Z
 * @returns 2022-03-11T00:00:00.007Z
 */
export const dateWithoutTimeISOString = () => {
  const newDate = new Date();
  newDate.setHours(0, 0, 0, 0);
  return newDate.toISOString();
};

/**
 *
 * @param date
 * @description ex: returns datetime ISO string with localtimezone
 * @returns 2023-07-10T15:36:00.007Z
 */
export const getTodayDateISOWithTimezone = (): string => {
  const timezoneOffsetMilliseconds = new Date().getTimezoneOffset() * 60000;
  return new Date(Date.now() - timezoneOffsetMilliseconds)
    .toISOString()
    .slice(0, 19);
};

/**
 *
 * @param date
 * @description ex: Date object with localtimezone
 * @returns Date object
 */
export const getDateNowWithTimezone = (): Date => {
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  return new Date(Date.now() - timezoneOffset);
};

export const verificarPeriodoMenorUmAno = (
  dataInicial: Date | null,
  dataFinal: Date | null,
): boolean => {
  if (dataInicial && dataFinal) {
    const anoDataInicial = dataInicial.getFullYear();
    const mesDataInicial = dataInicial.getMonth() + 1;
    const diaDataInicial = dataInicial.getUTCDate();

    const anoDataFinal = dataFinal.getFullYear();
    const mesDataFinal = dataFinal.getMonth() + 1;
    const diaDataFinal = dataFinal.getUTCDate();

    if (anoDataFinal === anoDataInicial) {
      return true;
    }

    if (anoDataFinal - anoDataInicial === 1 && mesDataFinal < mesDataInicial) {
      return true;
    }

    if (mesDataFinal === mesDataInicial && diaDataFinal <= diaDataInicial) {
      return true;
    }
  }

  return false;
};

export const dataPorExtenso = (data: Date) => {
  const diaSemana = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ][data.getDay()];
  const diaDoMes = data.getDate();
  const mes = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ][data.getMonth()];
  const ano = data.getFullYear();

  return `${diaSemana}, ${diaDoMes} de ${mes} de ${ano} às ${data.toLocaleTimeString()}`;
};

export const primeiroDiaDoMes = (dateValue: Date) => {
  const year = dateValue.getFullYear();
  const month = dateValue.getMonth();
  return new Date(year, month, 1);
};

export const ultimoDiaDoMes = (dateValue: Date) => {
  const year = dateValue.getFullYear();
  const month = dateValue.getMonth();
  return new Date(year, month + 1, 0);
};

export const replaceDate = (data: string) => {
  return new Date(data?.replace(/(\d+[/])(\d+[/])/, '$2$1'));
};

/**
 * @param date ISO String date or date object
 * @description ex: 2022-09-01T22:20:42.637Z
 * @returns date without time, ex: 2022-09-01
 */
export const parseDateStringToDate = (date?: string | Date | null) => {
  if (typeof date === 'string') return date.split('T')[0];

  if (date && typeof date.getMonth === 'function')
    return date.toISOString().split('T')[0];

  return '';
};

export const getExpirationDateInMilliseconds = (milliseconds: number) =>
  new Date().setMilliseconds(new Date().getMilliseconds() + milliseconds);

export const minutosParaMilissegundos = (minutos: number) => {
  return minutos * 60 * 1000;
};

export const getTimer15Minutos = () => {
  return minutosParaMilissegundos(15);
};

export const getTimer30Minutos = () => {
  return minutosParaMilissegundos(30);
};

export const getTimer60Minutos = () => {
  return minutosParaMilissegundos(60);
};

export const converterFormatoData = (data: string): string => {
  // Divida a string da data em dia, mês e ano
  const partes = data.split('/');

  // Verifique se a string tem três partes (dia, mês, ano)
  if (partes.length !== 3) {
    return data;
  }

  return `${partes[2]}-${partes[1]}-${partes[0]}`;
};

/**
 * Recebe uma data no padrao EN-US sem caractere separador, ex: 19600812
 * @param apiDate data a ser formatada
 * @returns 1960-08-12
 * @
 */
export const formatarDataAdicionandoSeparador = (apiDate: string) => {
  if (!apiDate) return '-';
  if (apiDate.includes('-')) return apiDate;

  let novaData = apiDate;

  if (novaData.includes('/')) novaData = converterFormatoData(novaData);

  if (!novaData || typeof novaData !== 'string') {
    throw new Error('Data inválida');
  }

  if (novaData.includes('-')) return novaData;

  const regex = /^(\d{4})(\d{2})(\d{2})$/;
  const match = novaData.match(regex);

  if (!match) {
    throw new Error('Formato de data inválido');
  }

  const [, year, month, day] = match;

  return `${year}-${month}-${day}`;
};

/**
 * Formata uma data no formato "MMM/YYYY" (ex: "JAN/2024").
 * @param dataReferencia A data de referência no formato ISO (YYYY-MM-DDTHH:MM:SS).
 * @returns A data formatada no formato "MMM/YYYY" ou uma string vazia se a data for inválida ou não fornecida.
 */
export const formatarDataMesAno = (dataReferencia?: string | null): string => {
  if (!dataReferencia) return '';

  try {
    const data = new Date(dataReferencia);
    const meses = [
      'JAN',
      'FEV',
      'MAR',
      'ABR',
      'MAI',
      'JUN',
      'JUL',
      'AGO',
      'SET',
      'OUT',
      'NOV',
      'DEZ',
    ];
    const mes = meses[data.getMonth()];
    const ano = data.getFullYear();
    return `${mes}/${ano}`;
  } catch (error) {
    console.error('Erro ao formatar a data:', error);
    return '';
  }
};
