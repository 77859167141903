import React from 'react';
import { Text } from '@cvp/design-system/react';
import * as CONSTS from 'previdencia/features/AlteracaoBeneficiarios/constants/constants';
import * as S from './styles';

const TextoInformativoAssinatura: React.FC = () => {
  return (
    <S.ContainerTextoInformativo>
      <Text variant="body01-sm" color="text-light" margin>
        {CONSTS.TEXTO_INFORMATIVO_ASSINATURA.PARAGRAFO_01}
      </Text>
      <Text variant="body01-sm" color="text-light" margin>
        {CONSTS.TEXTO_INFORMATIVO_ASSINATURA.PARAGRAFO_02}
      </Text>
      <Text variant="body01-sm" color="text-light" margin>
        <b> {CONSTS.TEXTO_INFORMATIVO_ASSINATURA.PARAGRAFO_03}</b>
      </Text>
      <Text variant="body01-sm" color="text-light" margin>
        {CONSTS.TEXTO_INFORMATIVO_ASSINATURA.PARAGRAFO_04}
      </Text>
    </S.ContainerTextoInformativo>
  );
};

export default TextoInformativoAssinatura;
