import { useAuth } from 'main/features/Auth/hooks';
import { usePeco } from 'main/hooks/usePeco';
import { getTimer60Minutos } from 'main/utils';
import { PECOS_PRESTAMISTA } from 'contratosPrestamista/config/endpoints';
import {
  IConsultarStatusPropostaRequest,
  IConsultarStatusPropostaResponse,
} from 'contratosPrestamista/features/uploadproposta/types/IConsultarStatusProposta';
import { IUseConsultarListaStatusProposta } from 'contratosPrestamista/features/uploadproposta/types/IUseConsultarListaStatusProposta';

const ZERO = '0';
const STRING_VAZIA = '';

const getCacheKey = (jsonPayload: IConsultarStatusPropostaRequest) => {
  return Object.values(jsonPayload)
    .reduce((prev, current) => `${prev}${current}`, '')
    ?.toString();
};

export const useConsultarListaStatusPropostaPorCpf = (
  payload: IConsultarStatusPropostaRequest,
): IUseConsultarListaStatusProposta => {
  const jsonPayload: IConsultarStatusPropostaRequest = {
    ...payload,
    agencia: ZERO,
    codProposta: STRING_VAZIA,
    dataInicial: payload.dataInicial,
    dataFinal: payload.dataFinal,
  };
  const { response, loading } = usePeco<
    IConsultarStatusPropostaRequest,
    IConsultarStatusPropostaResponse[]
  >({
    api: {
      operationPath: PECOS_PRESTAMISTA.ListarStatusPropostasPrestamista,
    },
    autoFetch: true,
    payload: jsonPayload,
    cache: true,
    cacheTime: getTimer60Minutos(),
    cacheKey: getCacheKey(jsonPayload),
  });

  return {
    data: response,
    isFetching: loading,
  };
};

export const useConsultarListaStatusPropostaPorAgencia = (
  payload: IConsultarStatusPropostaRequest,
): IUseConsultarListaStatusProposta => {
  const { user } = useAuth();
  const jsonPayload: IConsultarStatusPropostaRequest = {
    ...payload,
    agencia: user?.agenciaVinculada,
    cpfCnpj: ZERO,
    codProposta: STRING_VAZIA,
    dataInicial: payload.dataInicial,
    dataFinal: payload.dataFinal,
  };

  const { response, loading } = usePeco<
    IConsultarStatusPropostaRequest,
    IConsultarStatusPropostaResponse[]
  >({
    api: {
      operationPath: PECOS_PRESTAMISTA.ListarStatusPropostasPrestamista,
    },
    autoFetch: true,
    payload: jsonPayload,
    cache: true,
    cacheTime: getTimer60Minutos(),
    cacheKey: getCacheKey(jsonPayload),
  });
  return {
    data: response,
    isFetching: loading,
  };
};
