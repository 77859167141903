import React from 'react';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as TransferArrows } from 'assets/icons/transfer-arrows.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as FloatMenuIcon } from 'assets/icons/float_menu.svg';
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/external_link.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import { ReactComponent as SuccessCheck } from 'assets/icons/success_check.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as SearchStatus } from 'assets/icons/search_status.svg';
import { ReactComponent as MultiUsers } from 'assets/icons/multi_users.svg';
import { ReactComponent as FilterEqualize } from 'assets/icons/filter_equalize.svg';
import { ReactComponent as ReactivateIcon } from 'assets/icons/reactivate.svg';
import { ReactComponent as ChartIcon } from 'assets/icons/chart.svg';
import { ReactComponent as AnalyticsListIcon } from 'assets/icons/analytics_list.svg';
import { ReactComponent as DocumentPaperIcon } from 'assets/icons/document_paper.svg';
import { ReactComponent as DocumentFrontIcon } from 'assets/icons/document_front.svg';
import { ReactComponent as FinancialCreditCardVerseIcon } from 'assets/icons/financial_credicard_verse.svg';
import { ReactComponent as FinancialPayCoinsIcon } from 'assets/icons/financial_paycoins.svg';
import { ReactComponent as FinancialPayCoins2Icon } from 'assets/icons/financial_paycoin2.svg';
import { ReactComponent as ConfigurationIcon } from 'assets/icons/configuration.svg';
import { ReactComponent as HomeFamilyIcon } from 'assets/icons/home_family.svg';
import { ReactComponent as FinancialMoneyBagIcon } from 'assets/icons/financial_moneybag.svg';
import { ReactComponent as ExtratoIcon } from 'assets/icons/extrato.svg';
import { ReactComponent as FinancialWalletIcon } from 'assets/icons/financial_wallet.svg';
import { ReactComponent as InformationIcon } from 'assets/icons/information.svg';
import { ReactComponent as DocumentBackIcon } from 'assets/icons/document_back.svg';
import { ReactComponent as UploadArrowIcon } from 'assets/icons/upload_arrow.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as Peculio } from 'assets/icons/peculio.svg';
import { ReactComponent as PensaoConjuge } from 'assets/icons/pensao_conjuge.svg';
import { ReactComponent as PensaoPrazoCerto } from 'assets/icons/pensao_prazo_certo.svg';
import { ReactComponent as Reserva } from 'assets/icons/reserva.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/rounded-warning.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/rounded-success.svg';
import { ReactComponent as TwoUsers } from 'assets/icons/two_users.svg';
import { ReactComponent as PersonTicked } from 'assets/icons/personTicked.svg';
import { ReactComponent as Settings } from 'assets/icons/settings.svg';
import { ReactComponent as HandPlus } from 'assets/icons/hand_plus.svg';
import { ReactComponent as Sinistro } from 'assets/icons/sinistro.svg';
import { ReactComponent as Waiting } from 'assets/icons/waiting.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as QuestionHelpIcon } from 'assets/icons/question_help.svg';
import { ReactComponent as PatientIcon } from 'assets/icons/patient.svg';
import { ReactComponent as ContactIcon } from 'assets/icons/contact.svg';
import { ReactComponent as CapitalizacaoIcon } from 'assets/icons/capitalizacao.svg';
import { ReactComponent as VidaIcon } from 'assets/icons/vida.svg';
import { ReactComponent as PrestamistaIcon } from 'assets/icons/prestamista.svg';

type IconConfig = {
  [key: string]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
};
export const Icons: IconConfig = {
  home: HomeIcon,
  transferArrows: TransferArrows,
  close: CloseIcon,
  user: UserIcon,
  menu: MenuIcon,
  edit: EditIcon,
  calendar: CalendarIcon,
  download: DownloadIcon,
  floatMenu: FloatMenuIcon,
  externalLink: ExternalLinkIcon,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  successCheck: SuccessCheck,
  search: Search,
  searchStatus: SearchStatus,
  multiUsers: MultiUsers,
  filterEqualize: FilterEqualize,
  reactivate: ReactivateIcon,
  chart: ChartIcon,
  analyticsList: AnalyticsListIcon,
  documentPaper: DocumentPaperIcon,
  documentFront: DocumentFrontIcon,
  creditCardVerse: FinancialCreditCardVerseIcon,
  financialPayCoin: FinancialPayCoinsIcon,
  financialPayCoin2: FinancialPayCoins2Icon,
  configuration: ConfigurationIcon,
  homeFamily: HomeFamilyIcon,
  financialMoneyBag: FinancialMoneyBagIcon,
  extrato: ExtratoIcon,
  financialWallet: FinancialWalletIcon,
  information: InformationIcon,
  documentBack: DocumentBackIcon,
  uploadArrowIcon: UploadArrowIcon,
  trash: TrashIcon,
  plus: PlusIcon,
  reserva: Reserva,
  peculio: Peculio,
  pensaoconjuge: PensaoConjuge,
  pensaoprazocerto: PensaoPrazoCerto,
  warning: WarningIcon,
  success: SuccessIcon,
  twoUsers: TwoUsers,
  personTicked: PersonTicked,
  settings: Settings,
  handPlus: HandPlus,
  sinistro: Sinistro,
  waiting: Waiting,
  chatIcon: ChatIcon,
  questionHelp: QuestionHelpIcon,
  patient: PatientIcon,
  contact: ContactIcon,
  capitalizacao: CapitalizacaoIcon,
  vida: VidaIcon,
  prestamista: PrestamistaIcon,
};

type IconsProps = {
  name?: string;
};

const Icon: React.FC<IconsProps> = ({ name }) => {
  if (!name) return <></>;
  const IconToRender = Icons[name];
  return <IconToRender />;
};

Icon.defaultProps = {
  name: '',
};

export default Icon;
