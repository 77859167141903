import { IApiResponse } from 'main/services';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { PECOS_ASC } from 'registroOcorrenciaASC/config/endpoints';
import * as CONSTS from 'registroOcorrenciaASC/features/consultarOcorrencia/constants/constants';
import * as PECO_TYPES from 'registroOcorrenciaASC/features/consultarOcorrencia/types/ConsultarOcorrenciaUsePeco';
import * as RESPONSE_TYPES from 'registroOcorrenciaASC/features/consultarOcorrencia/types/ConsultarOcorrenciaResponse';
import * as REQUEST_TYPES from 'registroOcorrenciaASC/features/consultarOcorrencia/types/ConsultarOcorrenciaRequest';

export const useHistoricoSolicitacao =
  (): PECO_TYPES.IUseHistoricoSolicitacao => {
    const {
      response: dadosHistoricoSolicitacao,
      loading: loadingDadosHistoricoSolicitacao,
      fetchData: obterHistoricoSolicitacao,
    } = usePeco<
      REQUEST_TYPES.IHistoricoSolicitacaoPayload,
      RESPONSE_TYPES.IHistoricoSolicitacaoResponse
    >({
      api: { operationPath: PECOS_ASC.RegistroOcorrenciaHistoricoSolicitacao },
      payload: {
        codigoNivel: CONSTS.DEFAULT_VALUE.CODIGO_NIVEL,
        descricaoCanal: CONSTS.DEFAULT_VALUE.DESCRICAO_CANAL,
      },
      handleResponse: { throwToastErrorBFF: false },
    });

    return {
      dadosHistoricoSolicitacao,
      loadingDadosHistoricoSolicitacao,
      obterHistoricoSolicitacao,
    };
  };

export const useDetalharDadosOcorrencia = ({
  numeroSolicitacao,
  cpfCnpj,
}: PECO_TYPES.UseDetalharOcorrenciaParams): PECO_TYPES.IUseDetalharOcorrencia => {
  const {
    response: dadosDetalhesOcorrencia,
    loading: loadingDadosDetalhesOcorrencia,
    fetchData: obterDetalhesOcorrencia,
  } = usePeco<
    REQUEST_TYPES.IDetalharOcorrenciaPayload,
    RESPONSE_TYPES.IDetalharOcorrenciaResponse
  >({
    api: { operationPath: PECOS_ASC.RegistroOcorrenciaDetalharSolicitacao },
    autoFetch: true,
    payload: {
      solicitacao: numeroSolicitacao,
      cpfCnpj,
    },
  });

  return {
    dadosDetalhesOcorrencia,
    loadingDadosDetalhesOcorrencia,
    obterDetalhesOcorrencia,
  };
};

export const useBuscarAnexos = ({
  cpfCnpj,
}: PECO_TYPES.UseBuscarAnexosParams): PECO_TYPES.IUseBuscarAnexos => {
  const {
    response: dadosAnexos,
    loading: loadingDadosAnexos,
    fetchData: buscarAnexos,
  } = usePeco<
    Partial<REQUEST_TYPES.IBuscarAnexosPayload>,
    RESPONSE_TYPES.IBuscarAnexosResponse
  >({
    api: { operationPath: PECOS_ASC.RegistroOcorrenciaBuscarAnexos },
    payload: {
      cpfCnpj,
    },
  });

  return {
    dadosAnexos,
    loadingDadosAnexos,
    buscarAnexos,
  };
};

export const useCriarComentario = (): PECO_TYPES.IUseCriarComentario => {
  const {
    response: dadosComentario,
    loading: loadingDadosComentario,
    fetchData: criarComentario,
  } = usePeco<
    REQUEST_TYPES.ICriarComentarioPayload,
    RESPONSE_TYPES.ICriarComentarioResponse
  >({
    api: { operationPath: PECOS_ASC.RegistroOcorrenciaComentarioCliente },
  });

  return {
    dadosComentario,
    loadingDadosComentario,
    criarComentario,
  };
};

export const useConsultaDocumentoAssinadoFop223 =
  (): PECO_TYPES.IUseConsultaDocumentoAssinadoFop223 => {
    const {
      loading: loadingConsultaDocumentoAssinadoFop223,
      fetchData: consultarDocumentoAssinadoFop223,
    } = usePeco<
      REQUEST_TYPES.IConsultaDocumentoAssinadoFop223Payload,
      IApiResponse<boolean>
    >({
      api: { operationPath: PECOS.ConsultarDocumentoAssinadoFop223 },
    });

    return {
      loadingConsultaDocumentoAssinadoFop223,
      consultarDocumentoAssinadoFop223,
    };
  };
