import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import {
  IPaylodListarFundos,
  IResponseListarFundos,
} from 'previdencia/features/TransferenciaMultifundos/types/IFundos';
import { useContext } from 'react';

const useListarFundos = () => {
  const {
    cliente: { numCertificado },
  } = useContext(AppContext);
  const { response, loading, fetchData } = usePeco<
    IPaylodListarFundos,
    IResponseListarFundos
  >({
    api: {
      operationPath: PECOS.ListarFundos,
    },
    autoFetch: true,
    payload: {
      certificado: numCertificado,
    },
  });

  return { response: response?.entidade, loading, fetchData };
};

export default useListarFundos;
