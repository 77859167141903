import React from 'react';
import {
  Text,
  Card,
  Accordion,
  Grid,
  Display,
  Button,
} from '@cvp/design-system/react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import RenderConditional from 'main/components/RenderConditional';
import Icon from 'main/components/Icon';
import { formatarData } from 'main/utils';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import * as UTILS from 'registroOcorrenciaASC/features/consultarOcorrencia/utils/ConsultarOcorrenciaUtils';
import * as CONSTS from 'registroOcorrenciaASC/features/consultarOcorrencia/constants/constants';
import * as CONSULTA_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/consultarOcorrencia/types/ConsultarOcorrencia';
import * as S from 'registroOcorrenciaASC/features/consultarOcorrencia/components/CardAnexos/styles';
import * as OCORRENCIA_STYLES from 'registroOcorrenciaASC/styles/styles';

const CardAnexos: React.FC<CONSULTA_OCORRENCIA_TYPES.CardAnexosProps> = ({
  loadingDadosAnexos,
  loadingDadosDetalhesOcorrencia,
  dadosDetalhesOcorrencia,
  baixarAnexo,
  isReconsultarFop223,
  loadingConsultaDocumentoAssinadoFop223,
  reconsultarFop223,
}): React.ReactElement => {
  const dadosAnexo = dadosDetalhesOcorrencia?.entidade?.anexos;

  return (
    <OCORRENCIA_STYLES.CardContainer>
      <Card.Content padding={[6, 8, 6, 8]}>
        <Accordion data-testid="accordionAnexosOcorrencia">
          <Accordion.Item title="Anexos">
            <RenderConditional
              condition={checkIfSomeItemsAreTrue([
                loadingDadosDetalhesOcorrencia,
                loadingDadosAnexos,
                loadingConsultaDocumentoAssinadoFop223,
              ])}
            >
              <SkeletonLoading lines={4} />
            </RenderConditional>

            <RenderConditional
              condition={checkIfAllItemsAreTrue([
                !loadingDadosDetalhesOcorrencia,
                !loadingDadosAnexos,
                !loadingConsultaDocumentoAssinadoFop223,
                !!dadosAnexo?.length,
              ])}
            >
              <S.ContainerAttachments itemCount={dadosAnexo?.length}>
                {dadosAnexo?.map(anexo => {
                  return (
                    <S.ContainerAttach
                      key={anexo.idSalesforce}
                      onClick={() => baixarAnexo(anexo.idSalesforce)}
                    >
                      <S.ContainerAttachDownload>
                        <Icon name="download" />
                        <div>
                          <Text variant="caption-01" title={anexo.nomeArquivo}>
                            <span>{CONSTS.TEXTOS_ANEXOS.LABEL_ARQUIVO}</span>{' '}
                            {UTILS.charSizeLimit(anexo.nomeArquivo)}
                          </Text>
                          <Text variant="caption-01">
                            <span>{CONSTS.TEXTOS_ANEXOS.LABEL_TAMANHO}</span>{' '}
                            {UTILS.fileSizeMask(anexo.tamanho)}
                          </Text>
                          <Text variant="caption-01">
                            <span>{CONSTS.TEXTOS_ANEXOS.LABEL_DATA}</span>{' '}
                            {formatarData(anexo.ultimaModificacao)}
                          </Text>
                        </div>
                      </S.ContainerAttachDownload>
                    </S.ContainerAttach>
                  );
                })}
              </S.ContainerAttachments>
            </RenderConditional>

            <RenderConditional condition={isReconsultarFop223}>
              <Grid.Item xs={1}>
                <Display justify="center">
                  <Button
                    variant="secondary"
                    onClick={reconsultarFop223}
                    loading={loadingConsultaDocumentoAssinadoFop223}
                    disabled={loadingConsultaDocumentoAssinadoFop223}
                  >
                    Consultar e Anexar
                  </Button>
                </Display>
              </Grid.Item>
            </RenderConditional>

            <RenderConditional
              condition={checkIfAllItemsAreTrue([
                !loadingDadosDetalhesOcorrencia,
                !dadosAnexo?.length,
              ])}
            >
              <Text variant="body02-sm" data-testid="mensagemNenhumAnexo">
                {CONSTS.TEXTOS_ANEXOS.MSG_NENHUM_ANEXO}
              </Text>
            </RenderConditional>
          </Accordion.Item>
        </Accordion>
      </Card.Content>
    </OCORRENCIA_STYLES.CardContainer>
  );
};

export default CardAnexos;
