import styled from 'styled-components/macro';
import { Button } from '@cvp/design-system/react';

export const TextItalic = styled.p({
  fontSize: 14.5,
  fontStyle: 'italic',
  fontWeight: 600,
  lineHeight: '150%',
  color: '#808288',
});

export const WrapperSelectAnoQuitacao = styled.div({
  marginLeft: 14,
  width: '30%',
});

export const ButtonAnoConsulta = styled(Button)({
  marginRight: 15,
  marginBottom: 25,
});
