export enum HistoricoSolicitacoesTipo {
  TIPO_SOLICITACAO_TODOS = 'Todos',
}

export enum FiltroPeriodo {
  ULTIMO_MES = '30',
  ULTIMOS_3_MESES = '90',
  ULTIMOS_6_MESES = '180',
  ULTIMOS_9_MESES = '270',
  ULTIMOS_12_MESES = '365',
  PERIODO_PERSONALIZADO = '-1',
}

export enum StatusRequisicaoComprovante {
  CONCLUIDA = 'CONCLUIDA',
  AGUARDANDO_ASSINATURA = 'AGUARDANDOASSINATURA',
  EM_PAGAMENTO = 'EMPAGAMENTO',
  SOLICITADA = 'SOLICITADA',
}

export const PrevRedirectByStatus: Record<string, string> = {
  TRANSFUND: '/cliente/produtos/previdencia/transferencia-fundo-investimento',
  RESGATE: '/cliente/produtos/previdencia/efetuar-resgate/sucesso',
  MANBENFR: '/cliente/produtos/previdencia/beneficiarios',
  MREACONTRS: '/cliente/produtos/previdencia/incluir-beneficio-de-protecao',
  T_ADS:
    '/cliente/produtos/previdencia/transferencia-interna-emissao-conjugado',
};

export enum CodigoTipoRequisicao {
  ALTERACAO_ENDERECO_TEL_EMAIL = 'MTROEND',
}
