import React, { useEffect } from 'react';
import { Text, Display } from '@cvp/design-system/react';

import {
  buildColumnsHierarquia,
  TiposVisao,
} from 'painelPortabilidade/constants/constants';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import RenderConditional from 'main/components/RenderConditional';
import { RelatorioPortabilidadeProps } from 'painelPortabilidade/types/ConsultarPortabilidadeUsePeco';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { TablePortabilidade } from 'painelPortabilidade/components/TablePortabilidade/TablePortabilidade';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'main/features/Auth/hooks';

const RelatorioHierarquia: React.FC<RelatorioPortabilidadeProps> = ({
  loading,
  dataHierarquia,
  dataAgencia,
  handleConsultar,
  payloadConsulta,
}) => {
  const history = useHistory();
  const { user } = useAuth();
  const agenciaVinculadaUser = user?.agenciaVinculada;

  const handleEnterUnidade = (unidadeTable: string) => {
    history.push({
      pathname: '/portabilidades',
      state: { unidadeTable },
    });
  };
  useEffect(() => {
    const dadosPayload = tryGetValueOrDefault(
      [payloadConsulta?.unidadeInicial],
      agenciaVinculadaUser,
    );
    if (dataAgencia?.tipoHierarquia === TiposVisao.AG) {
      history.push({
        pathname: '/portabilidades',
        state: { dadosPayload },
      });
    }
  }, [
    agenciaVinculadaUser,
    dataAgencia?.tipoHierarquia,
    history,
    payloadConsulta?.unidadeInicial,
  ]);

  const ColumnsTable = (unidade: (unidade: string | number) => void) => {
    if (dataHierarquia?.tipoFilhos === TiposVisao.AG) {
      return buildColumnsHierarquia(
        handleEnterUnidade,
        dataHierarquia?.tipoFilhos,
      );
    }
    return buildColumnsHierarquia(unidade, dataHierarquia?.tipoFilhos);
  };

  return (
    <RenderConditional condition={!loading}>
      <Display>
        <TablePortabilidade
          noHeader
          responsive
          data={tryGetValueOrDefault([dataHierarquia?.dados], [])}
          columns={ColumnsTable((unidade: string | number) => {
            handleConsultar(unidade);
          })}
          noDataComponent="Não há dados para exibir."
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
          paginationComponentOptions={{
            rowsPerPageText: 'Items por página',
            rangeSeparatorText: 'de',
          }}
        />
      </Display>
      <RenderConditional condition={!!dataHierarquia?.valorTotalPortabilidade}>
        <Text variant="body02-sm" color="text" margin>
          Total:{' '}
          {tryGetMonetaryValueOrDefault(
            dataHierarquia?.valorTotalPortabilidade,
          )}
        </Text>
      </RenderConditional>
    </RenderConditional>
  );
};

export default RelatorioHierarquia;
