import React from 'react';
import { Text, Display, Button } from '@cvp/design-system/react';

import Icon from 'main/components/Icon';
import Modal from 'main/components/Modal';
import * as REGIME_TRIBUTARIO_TYPES from 'previdencia/features/AlteracaoRegimeTributario/types/AlteracaoRegimeTributario';

const ModalAlertaAlteracaoExpirada: React.FC<
  REGIME_TRIBUTARIO_TYPES.IModalAlertaAlteracaoExpiradaProps
> = ({ open, onClose, mensagem }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-label="Modal alerta"
      aria-describedby="mensagem-alerta"
    >
      <Text margin color="secondary" role="heading" aria-level={1}>
        <strong>
          <Icon name="warning" /> Atenção!
        </strong>
      </Text>
      <Display type="display-block">
        <Text
          variant="caption-02"
          margin
          align="left"
          role="document"
          id="mensagem-alerta"
        >
          {mensagem}
        </Text>
      </Display>
      <Display justify="center">
        <Button
          variant="secondary"
          onClick={onClose}
          role="button"
          aria-label="Certo, entendi!"
        >
          Certo, entendi!
        </Button>
      </Display>
    </Modal>
  );
};

export default ModalAlertaAlteracaoExpirada;
