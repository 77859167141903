import React from 'react';
import { Accordion, Card, Grid, Text } from '@cvp/design-system/react';
import { TextLabel } from 'main/styles/GlobalStyle';
import { IAvaliacao } from 'main/features/prospeccao/types/IDetalhesProspeccao';

const DetalhesDoParecer: React.FC<{
  title: string;
  resultadoAvaliacao?: IAvaliacao;
}> = ({ title, resultadoAvaliacao }) => {
  return (
    <Card>
      <Card.Content>
        <Accordion open>
          <Accordion.Item title={title}>
            <Grid>
              <Grid.Item xs={1} lg={1 / 3}>
                <div>
                  <TextLabel variant="body02-sm">Favorável</TextLabel>
                  <Text variant="body02-sm" proposta-testid="nomeSegurado">
                    {resultadoAvaliacao?.Aprovada ? 'Sim' : 'Não'}
                  </Text>
                </div>
              </Grid.Item>
              <Grid.Item xs={1} lg={1}>
                <div>
                  <TextLabel variant="body02-sm">Motivo</TextLabel>
                  <Text variant="body02-sm" proposta-testid="cpfCnpjSegurado">
                    {resultadoAvaliacao?.Justificativa}
                  </Text>
                </div>
              </Grid.Item>
            </Grid>
          </Accordion.Item>
        </Accordion>
      </Card.Content>
    </Card>
  );
};

export default DetalhesDoParecer;
