import { EnumTipo, EnumTipoLabel } from 'painelAdministracao/constants/enum';

export const definirLabelTipo = (tipo?: string) => {
  switch (tipo) {
    case EnumTipo.VIDA:
      return EnumTipoLabel.VIDA;
    case EnumTipo.PREVIDENCIA:
      return EnumTipoLabel.PREVIDENCIA;
    case EnumTipo.PREST:
      return EnumTipoLabel.PREST;
    case EnumTipo.PREV_SIMULADOR:
      return EnumTipoLabel.PREV_SIMULADOR;
    case EnumTipo.PREV_ADESAO:
      return EnumTipoLabel.PREV_ADESAO;
    case EnumTipo.PREV_MANUTENCAO:
      return EnumTipoLabel.PREV_MANUTENCAO;
    case EnumTipo.CORP:
      return EnumTipoLabel.CORP;
    case EnumTipo.PREV_PJ:
      return EnumTipoLabel.PREV_PJ;
    case EnumTipo.PREV_SAIDA:
      return EnumTipoLabel.PREV_SAIDA;
    case EnumTipo.PREV_OUTROS:
      return EnumTipoLabel.PREV_OUTROS;
    case EnumTipo.PREST_VIDA:
      return EnumTipoLabel.PREST_VIDA;
    default:
      return tipo;
  }
};

export const validarDataVersao = (dataVersao?: string) => {
  const novaData = new Date();
  if (dataVersao) {
    const data = new Date(dataVersao);
    if (!Number.isNaN(data.getTime())) {
      return data;
    }
    return novaData;
  }
  return novaData;
};
