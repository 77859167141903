import { useContext } from 'react';
import { useToast } from 'main/hooks/useToast';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { useQuery, UseQueryResult } from 'react-query';
import { ResponseCoberturas } from 'previdencia/types/Beneficiarios';
import { AppContext } from 'main/contexts/AppContext';
import * as CoberturasApi from '../services/dadosCoberturas.api';

const useObterDadosCoberturas = (): UseQueryResult<
  ResponseCoberturas | undefined
> => {
  const { toastError } = useToast();
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  return useQuery(
    ['prev-dados-coberturas-', numCertificado],
    () => CoberturasApi.dadosCoberturas(cpfCnpj, numCertificado),
    {
      cacheTime: reactQueryCacheDuration(),
      retry: false,
      onError: (erro: Error) => toastError(erro.message),
    },
  );
};
export default useObterDadosCoberturas;
