import { useEffect, useState } from 'react';
import { Display } from '@cvp/design-system/react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import RenderConditional from 'main/components/RenderConditional';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import * as DadosParticipanteApi from 'previdencia/hooks/useObterDadosParticipante';
import EditarParticipante from '../components/EditarParticipante';
import VerParticipante from '../components/VerParticipante';

const DadosParticipante = (): React.ReactElement => {
  const [edit, setEdit] = useState(false);
  const {
    loading: isLoading,
    response: data,
    obterDadosParticipante,
  } = DadosParticipanteApi.useObterDadosParticipante();

  useEffect(() => {
    obterDadosParticipante();
  }, []);

  const desabilitarEdicao = () => {
    obterDadosParticipante();
    setEdit(false);
  };

  if (edit) {
    return (
      <EditarParticipante
        onCancelar={desabilitarEdicao}
        dadosParticipante={data}
      />
    );
  }

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredRoles={[
          ...PRINCIPAL_USERS,
          USER_PROFILES.ANALISTA_MANUTENCAO,
          USER_PROFILES.ANALISTA_SAIDA,
          USER_PROFILES.ANALISTA_CONSULTA,
        ]}
        requiredPermissions={[PREV_PERMISSIONS.INFORMACOES_DO_PARTICIPANTE]}
      >
        <RenderConditional condition={isLoading}>
          <SkeletonLoading blocks={1} />
        </RenderConditional>

        <RenderConditional condition={!isLoading}>
          <VerParticipante data={data} edit={() => setEdit(true)} />
        </RenderConditional>
      </FeatureAuthorizer>
    </Display>
  );
};

export default DadosParticipante;
