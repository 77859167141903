import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import * as API from 'registroOcorrenciaASC/features/consultarOcorrencia/hooks/useConsultarOcorrencia';
import * as CONSTS from 'registroOcorrenciaASC/features/consultarOcorrencia/constants/constants';
import * as CONSULTA_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/consultarOcorrencia/types/ConsultarOcorrencia';
import * as RESPONSE_TYPES from 'registroOcorrenciaASC/features/consultarOcorrencia/types/ConsultarOcorrenciaResponse';

export const useConsultarDadosOcorrencia = () => {
  const history = useHistory();

  const [listaHistoricoSolicitacao, setListaHistoricoSolicitacao] = useState<
    RESPONSE_TYPES.IHistoricoSolicitacaoRetorno[]
  >([]);

  const {
    dadosHistoricoSolicitacao,
    loadingDadosHistoricoSolicitacao,
    obterHistoricoSolicitacao,
  } = API.useHistoricoSolicitacao();

  const mensagemErro = dadosHistoricoSolicitacao?.mensagens?.[0].descricao;

  const carregarListaOcorrencias = (): void => {
    if (dadosHistoricoSolicitacao) {
      setListaHistoricoSolicitacao(
        dadosHistoricoSolicitacao.entidade
          ?.retornoSolicitacoHistoricoSolicitacoes as RESPONSE_TYPES.IHistoricoSolicitacaoRetorno[],
      );
    }
  };

  const limparEIniciarNovaConsulta = (): void => {
    setListaHistoricoSolicitacao([]);
  };

  const irParaDetalhesOcorrencia = ({
    numeroSolicitacao,
    cpfCnpj,
    numeroContrato,
  }: CONSULTA_OCORRENCIA_TYPES.IrParaDetalhesOcorrenciaParams): void => {
    history.push({
      pathname: `/registro-ocorrencias/detalhes-ocorrencia`,
      state: { numeroSolicitacao, cpfCnpj, numeroContrato },
    });
  };

  const colunas = CONSTS.COLUNAS_TABELA_HISTORICO_SOLICITACAO(
    irParaDetalhesOcorrencia,
  ) as [];

  useEffect(carregarListaOcorrencias, [dadosHistoricoSolicitacao]);

  return {
    listaHistoricoSolicitacao,
    obterHistoricoSolicitacao,
    setListaHistoricoSolicitacao,
    loadingDadosHistoricoSolicitacao,
    mensagemErro,
    colunas,
    limparEIniciarNovaConsulta,
  };
};
