import React from 'react';
import Icon from 'main/components/Icon';
import { Hidden } from '@cvp/design-system/react';
import * as S from './styes';

type BotaoFiltrarProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

const BotaoFiltrar: React.FC<BotaoFiltrarProps> = ({ onClick }) => {
  return (
    <S.Container>
      <S.Button type="button" onClick={onClick}>
        <Hidden only={['xs']}>
          <S.Label color="primary">Filtrar</S.Label>
        </Hidden>
        <Icon name="filterEqualize" />
      </S.Button>
    </S.Container>
  );
};

export default BotaoFiltrar;
