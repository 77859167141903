import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useMessageEvent } from 'main/hooks/useMessageEvent';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { PE } from 'main/components/PEComponents';
import Modal from 'main/components/Modal';
import { IMessageEvent } from 'main/types/IMessageEvent';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import RenderConditional from 'main/components/RenderConditional';
import ModalTipoDocuSing, {
  DadosClienteModalTipoDocuSingProps,
} from 'main/components/AssinaturaDocuSign/ModalTipoDocuSign';
import ModalAlertaContatos from 'main/components/AssinaturaDocuSign/ModalAlertaContatos';
import { useObterContatosDefaultPrevidencia } from 'main/hooks/useObterContatosDefaultPrevidencia';
import ModalTokenDocuSign from 'main/components/AssinaturaDocuSign/ModalTokenDocuSign';
import ButtonsValidaDadosTabelaFundos from 'previdencia/components/ButtonsValidaDadosTabelaFundos';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import TabelaFundos from 'previdencia/components/TabelaFundos';
import { TabelaFundosHandles } from 'previdencia/components/TabelaFundos/TabelaFundos';
import {
  Fundo,
  QuantidadeMaximaFundosPorTipos,
} from 'previdencia/types/Fundo.type';
import { useObterDadosParticipante } from 'previdencia/hooks/useObterDadosParticipante';
import useObterValoresContribuicao from 'previdencia/features/AlterarValorContribuicao/hooks/useObterValoresContribuicao';
import useAlterarValorContribuicao from 'previdencia/features/AlterarValorContribuicao/hooks/useAlterarValorContribuicao';
import useSolicitarAlteracaoValorContribuicaoRenda from 'previdencia/features/AlterarValorContribuicao/hooks/useSolicitarAlteracaoValorContribuicaoRenda';
import useObterDadosBeneficioProtecao from 'previdencia/features/AlterarValorContribuicao/hooks/useObterDadosBeneficioProtecao';
import useSolicitarAssinaturaBeneficioProtecao from 'previdencia/hooks/useSolicitarAssinaturaBeneficioProtecao';
import OpcaoAlterarContribuicao from 'previdencia/features/AlterarValorContribuicao/components/OpcaoAlterarContribuicao';
import ValoresContribuicao from 'previdencia/features/AlterarValorContribuicao/components/ValoresContribuicao';
import AlterarValorContribuicaoBeneficioProtecao from 'previdencia/features/AlterarValorContribuicao/components/AlterarValorContribuicaoBeneficioProtecao';
import ModalConfirmacaoAlteracaoValorContribuicaoBeneficioProtecao from 'previdencia/features/AlterarValorContribuicao/components/ModalConfirmacaoAlteracaoValorContribuicaoBeneficioProtecao';
import {
  converterFundosRequest,
  converterListaFundos,
} from 'previdencia/features/AlterarValorContribuicao/factories/AlterarValorContribuicaoFactory';
import {
  RequestReserve,
  ResponseDadosBeneficioProtecao,
  SolicitacaoAlteracaoContribuicaoRenda,
} from 'previdencia/features/AlterarValorContribuicao/types/ResponsesAlterarValorContribuicao';
import { DadosResponseAlterarValorContribuicaoBeneficioProtecao } from 'previdencia/features/AlterarValorContribuicao/types/AlterarValorContribuicaoBeneficioProtecao';
import {
  deveIrFimDaTela,
  scrollEndScreen,
} from 'previdencia/features/AlterarValorContribuicao/utils/fundosUtils';
import { resumoValoresContribuicao } from 'previdencia/features/AlterarValorContribuicao/utils/contribuicaoUtils';
import {
  deveObterDadosParticipante,
  obterDadosClienteModalTipoDocuSingProps,
} from 'previdencia/features/AlterarValorContribuicao/utils/dadosParticipanteUtils';
import {
  deveEfetuarAssinaturaDocuSign,
  deveExibirModalTokenDocuSign,
} from 'previdencia/features/AlterarValorContribuicao/utils/modalUtils';
import { DefaultValue } from 'previdencia/features/AlterarValorContribuicao/types/enum';
import SolicitarAlteracaoContribuicaoRenda from 'previdencia/features/AlterarValorContribuicao/components/SolicitarAlteracaoContribuicaoRenda';
import { useCalcularBeneficio } from 'previdencia/features/AlterarValorContribuicao/hooks/useCalcularBeneficio';
import { DataModalConfirmacao } from 'previdencia/features/AlterarValorContribuicao/types/InitialStateTypes';
import { initialStateDataModalConfirmacao } from 'previdencia/features/AlterarValorContribuicao/factories/initialStateFactory';
import { isInRange } from 'previdencia/features/AlterarValorContribuicao/utils/isInRange';
import { montarPayloadCalcularBeneficioFactory } from 'previdencia/features/AlterarValorContribuicao/factories/montarPayloadCalcularBeneficioFactory';
import { montarMensagemModalRangeValoresContribuicaoFactory } from 'previdencia/features/AlterarValorContribuicao/factories/montarMensagemModalRangeValoresContribuicaoFactory';
import {
  DadosTipoRenda,
  NovoValorBeneficioProtecao,
} from 'previdencia/features/AlterarValorContribuicao/types/FactoryTypes';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import * as CONSTS from 'previdencia/features/AlterarValorContribuicao/constants/constants';

const AlterarValorContribuicao = (): React.ReactElement => {
  const history = useHistory();

  const { subscribe, unsubscribe } = useMessageEvent();
  const { goDadosPlano } = usePrevNavigation();

  const [exibirBotaoFecharModal, setExibirBotaoFecharModal] =
    useState<boolean>(false);

  const [exibirFundosNovos, setExibirFundosNovos] = useState<boolean>(false);
  const [
    tipoAlteracaoRedistribuirSelecionado,
    setTipoAlteracaoRedistribuirSelecionado,
  ] = useState<boolean>(false);

  const {
    data: reponseObterContatosDefault,
    isFetching: loadingObterContatos,
    isFetched: recuperacaoEmailExecutada,
  } = useObterContatosDefaultPrevidencia();

  const [
    openModalRangeValorValidoBeneficio,
    setOpenModalRangeValorValidoBeneficio,
  ] = useState<boolean>(false);

  const [dataModalConfirmacao, setDataModalConfirmacao] =
    useState<DataModalConfirmacao>(initialStateDataModalConfirmacao);

  const [openAlertaContatos, setOpenAlertaContatos] = useState<boolean>(false);
  const { emailDefault, numerosTelefone } = reponseObterContatosDefault ?? {};

  const [openModalDocusign, setOpenModalDocusign] = useState<boolean>(false);
  const [
    modalMsgAlteracaoValorBeneficioProtecao,
    setmodalMsgAlteracaoValorBeneficioProtecao,
  ] = useState<boolean>(false);

  const dadosParticipanteHook = useObterDadosParticipante();

  const {
    isLoading: isLoadingSolicitarAssinaturaAlteracaoValorBeneficioProtecao,
    response: dadosSolicitarAssinaturaAlteracaoValorBeneficioProtecao,
    solicitarAssinaturaBeneficioProtecao,
  } = useSolicitarAssinaturaBeneficioProtecao();

  const [dadosClienteModalTipoDocuSing, setDadosClienteModalTipoDocuSing] =
    useState<DadosClienteModalTipoDocuSingProps>();

  const [
    resultAlterarValorContribuicaoBeneficioProtecao,
    setResultAlterarValorContribuicaoBeneficioProtecao,
  ] = useState<DadosResponseAlterarValorContribuicaoBeneficioProtecao>();

  const {
    responseMultiFundo,
    loading: loadingValoresContribuicao,
    responseValoresContribuicao,
    contribuicaoValor,
    setContribuicaoValor,
    buscarValoresContribuicao,
  } = useObterValoresContribuicao();

  const quantidadeMaximaFundosPorTipos: QuantidadeMaximaFundosPorTipos = {
    existente: {
      quantidade: Number(
        tryGetValueOrDefault(
          [responseMultiFundo?.fundosExistentes?.qtdeFundosDisponiveis],
          '0',
        ),
      ),
    },
    novo: {
      quantidade: Number(
        tryGetValueOrDefault(
          [responseMultiFundo?.fundosNovos?.qtdeFundosDisponiveis],
          '0',
        ),
      ),
    },
  };

  const resumoContribuicao = resumoValoresContribuicao(
    responseValoresContribuicao,
  );

  const [valorProtecaoSobrevivencia, setValorProtecaoSobrevivencia] = useState(
    resumoContribuicao.valorTotalSobrevivenciasAtivas.toString(),
  );

  const descricaoTabela: React.ReactElement = (
    <PE.Text variant="body02-sm">
      Selecione o fundo e indique como o cliente deseja redistribuir o valor de{' '}
      {tryGetMonetaryValueOrDefault(valorProtecaoSobrevivencia)} entre o(s)
      fundo(s) contratados.
    </PE.Text>
  );

  const [novoValorTipoBeneficio, setNovoValorTipoBeneficio] =
    useState<string>('');

  const [mostrarTabelaFundos, setMostrarTabelaFundos] =
    useState<boolean>(false);
  const tabelaFundosRef = useRef<TabelaFundosHandles>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [mensagemModal, setMensagemModal] = useState<string>('');

  const [tabelaAtualizada, setTabelaAtualizada] = useState<Fundo[] | undefined>(
    [],
  );

  const [tabelaAtualizadaRequest, setTabelaAtualizadaRequest] = useState<
    RequestReserve[] | undefined
  >(undefined);

  const { data: dadosAntigosFundo, isLoading: loadingDadosAntigosFundo } =
    useAlterarValorContribuicao(valorProtecaoSobrevivencia);

  const [consentimentoValue, setConsentimentoValue] = useState<boolean>(false);

  const [confirmarClienteCienteValue, setConfirmarClienteCienteValue] =
    useState<boolean>(false);

  const tabelaAntiga = converterListaFundos({
    data: dadosAntigosFundo?.return.fundosExistentes?.fundos,
  });

  const {
    dadosBeneficioProtecao,
    loadingDadosBeneficioProtecao,
    obterDadosBeneficioProtecao,
    mensagemBeneficioProtecao,
  } = useObterDadosBeneficioProtecao();

  const [
    exibirAlterarValorBeneficioProtecao,
    setExibirAlterarValorBeneficioProtecao,
  ] = useState<boolean>(false);

  const [
    exibirNovosValoresBeneficioProtecao,
    setExibirNovosValoresBeneficioProtecao,
  ] = useState<boolean>(false);

  const [exibirModalTokenDocuSign, setExibirModalTokenDocuSign] =
    useState<boolean>(false);

  const {
    response: responseSolicitacaoAlteracaoContribuicaoRenda,
    loading: loadingSolicitacaoAlteracaoContribuicaoRenda,
    setArrayResponse: setSolicitacaoAlteracaoContribuicaoRenda,
    obterSolicitacaoAlteracaoContribuicaoRenda,
  } = useSolicitarAlteracaoValorContribuicaoRenda();

  const voltarTelaAlterarValorContribuicao = (): void => {
    setSolicitacaoAlteracaoContribuicaoRenda(undefined);
    buscarValoresContribuicao();
  };

  const {
    loading: loadingCalculoBeneficioProtecao,
    response: responseCalculoBeneficioProtecao,
    obterCalculoBeneficio,
  } = useCalcularBeneficio();

  const mensagemRangeValoresContribuicao: DadosTipoRenda[] = [
    {
      tipoBeneficioProtecao: DefaultValue.TIPO_BENEFICIO_PECULIO,
      descricao: (
        <>
          <PE.Text variant="body02-sm">
            O novo valor de contribuição deve estar dentro da faixa permitida.
          </PE.Text>
          <PE.Text variant="body02-sm" marginTop={10}>
            Valor de contribuição deve estar entre{' '}
            <strong>
              {tryGetMonetaryValueOrDefault(
                responseCalculoBeneficioProtecao?.valoresMensal
                  ?.vlrMinContribuicaoPeculio,
              )}
            </strong>{' '}
            e{' '}
            <strong>
              {tryGetMonetaryValueOrDefault(
                responseCalculoBeneficioProtecao?.valoresMensal
                  ?.vlrMaxContribuicaoPeculio,
              )}
            </strong>
            .
          </PE.Text>
        </>
      ),
    },
    {
      tipoBeneficioProtecao: DefaultValue.TIPO_BENEFICIO_PENSAO,
      descricao: (
        <>
          <PE.Text variant="body02-sm">
            O novo valor de contribuição deve estar dentro da faixa permitida.
          </PE.Text>
          <PE.Text variant="body02-sm" marginTop={10}>
            Valor de contribuição deve estar entre{' '}
            <strong>
              {tryGetMonetaryValueOrDefault(
                responseCalculoBeneficioProtecao?.valoresMensal
                  ?.vlrMinContribuicaoPensao,
              )}
            </strong>{' '}
            e{' '}
            <strong>
              {tryGetMonetaryValueOrDefault(
                responseCalculoBeneficioProtecao?.valoresMensal
                  ?.vlrMaxContribuicaoPensao,
              )}
            </strong>
            .
          </PE.Text>
        </>
      ),
    },
  ];

  const mensagemRangeValoresPermitidos =
    montarMensagemModalRangeValoresContribuicaoFactory(
      mensagemRangeValoresContribuicao,
      novoValorTipoBeneficio,
    );

  const calculoBeneficioProtecao = async (
    novoValorBeneficioProtecao: NovoValorBeneficioProtecao,
  ): Promise<void> => {
    const responseCalculo = await obterCalculoBeneficio(
      montarPayloadCalcularBeneficioFactory(novoValorBeneficioProtecao),
    );

    if (
      novoValorBeneficioProtecao.tipoBeneficio ===
      DefaultValue.TIPO_BENEFICIO_PECULIO
    ) {
      const rangeValido = isInRange(
        novoValorBeneficioProtecao.novoValor,
        responseCalculo?.valoresMensal?.vlrMinContribuicaoPeculio,
        responseCalculo?.valoresMensal?.vlrMaxContribuicaoPeculio,
      );

      if (!rangeValido) {
        setOpenModalRangeValorValidoBeneficio(true);
      }

      setExibirNovosValoresBeneficioProtecao(rangeValido);

      setDataModalConfirmacao({
        tipoBeneficio: DefaultValue.TIPO_BENEFICIO_PECULIO,
        descricaoTipoBeneficio:
          novoValorBeneficioProtecao.descricaoTipoBeneficio,
        novoValor: novoValorBeneficioProtecao.novoValor,
        periodicidade: responseCalculo?.valoresMensal?.periodicidade,
        valorCustomizacao:
          responseCalculo?.valoresMensal?.vlrCustomizacaoPeculio,
        prazo: responseCalculo?.valoresMensal?.prazo,
      });
    }

    if (
      novoValorBeneficioProtecao.tipoBeneficio ===
      DefaultValue.TIPO_BENEFICIO_PENSAO
    ) {
      const rangeValido = isInRange(
        novoValorBeneficioProtecao.novoValor,
        responseCalculo?.valoresMensal?.vlrMinContribuicaoPensao,
        responseCalculo?.valoresMensal?.vlrMaxContribuicaoPensao,
      );

      if (!rangeValido) {
        setOpenModalRangeValorValidoBeneficio(true);
      }

      setExibirNovosValoresBeneficioProtecao(rangeValido);

      setDataModalConfirmacao({
        tipoBeneficio: DefaultValue.TIPO_BENEFICIO_PENSAO,
        descricaoTipoBeneficio:
          novoValorBeneficioProtecao.descricaoTipoBeneficio,
        novoValor: novoValorBeneficioProtecao.novoValor,
        periodicidade: responseCalculo?.valoresMensal?.periodicidade,
        valorCustomizacao:
          responseCalculo?.valoresMensal?.vlrCustomizacaoPensao,
        prazo: responseCalculo?.valoresMensal?.prazo,
      });
    }
  };

  const handleAtualizarValorContribuicao = (): void => {
    const validacao = tabelaFundosRef.current?.validateFundos();

    const quantidadeFundosSelecionados: number | undefined =
      validacao?.tabelaAlterada?.filter(item => item.selecionado)?.length;

    const checarRedistribuicaoMultifundo: boolean = checkIfAllItemsAreTrue([
      tipoAlteracaoRedistribuirSelecionado,
      tryGetValueOrDefault([quantidadeFundosSelecionados], 0) >
        DefaultValue.QTD_MINIMA_FUNDOS_SELECIONADOS,
      Number(valorProtecaoSobrevivencia) <
        DefaultValue.VALOR_MINIMO_REDISTRIBUICAO_MULTIPLOS_FUNDOS,
    ]);

    if (validacao?.tabelaAlterada) {
      if (checarRedistribuicaoMultifundo) {
        setMensagemModal(
          `Para contribuições menores que ${tryGetMonetaryValueOrDefault(
            DefaultValue.VALOR_MINIMO_REDISTRIBUICAO_MULTIPLOS_FUNDOS,
          )} é permitida a indicação somente de 1 fundo de investimento.`,
        );
        setOpenModal(true);
      } else {
        const fundosRequest: RequestReserve[] = converterFundosRequest(
          validacao.tabelaAlterada,
        );
        setTabelaAtualizadaRequest(fundosRequest);
        setTabelaAtualizada(validacao.tabelaAlterada);
      }
    }

    if (validacao?.valoresDiferentes) {
      const msgValoresDivergentes: string = tipoAlteracaoRedistribuirSelecionado
        ? String(validacao?.mensagemValoresDiferentes)
        : `O total da contribuição deve ser de ${tryGetMonetaryValueOrDefault(
            contribuicaoValor,
          )}`;

      setMensagemModal(msgValoresDivergentes);
      setOpenModal(true);
    }
  };

  const handleOnChangeOpcaoAlterarContribuicao = (): void => {
    setExibirFundosNovos(true);
    setTipoAlteracaoRedistribuirSelecionado(false);

    if (mostrarTabelaFundos) {
      setMostrarTabelaFundos(false);
    }

    if (exibirAlterarValorBeneficioProtecao) {
      setExibirAlterarValorBeneficioProtecao(false);
    }
  };

  const handleOnRedistribuirOpcaoAlterarContribuicao = (): void => {
    setValorProtecaoSobrevivencia(
      resumoContribuicao.valorTotalSobrevivenciasAtivas.toString(),
    );

    buscarValoresContribuicao();
    setExibirFundosNovos(false);
    setTipoAlteracaoRedistribuirSelecionado(true);

    if (!mostrarTabelaFundos) {
      setMostrarTabelaFundos(true);
    }

    if (exibirAlterarValorBeneficioProtecao) {
      setExibirAlterarValorBeneficioProtecao(false);
    }
  };

  const handleOnAlterarValorOpcaoAlterarContribuicao = (
    valorAtualizado: string,
  ): void => {
    setContribuicaoValor(valorAtualizado);

    setValorProtecaoSobrevivencia(valorAtualizado);

    if (!mostrarTabelaFundos) {
      setMostrarTabelaFundos(true);
    }

    if (exibirAlterarValorBeneficioProtecao) {
      setExibirAlterarValorBeneficioProtecao(false);
    }
  };

  const handleOnAlterarBeneficioProtecaoOpcaoAlterarContribuicao = (): void => {
    setTipoAlteracaoRedistribuirSelecionado(false);
    setmodalMsgAlteracaoValorBeneficioProtecao(!dadosBeneficioProtecao);

    if (dadosBeneficioProtecao && !exibirAlterarValorBeneficioProtecao) {
      setExibirAlterarValorBeneficioProtecao(true);
    }
  };

  const handleAssinar = (): void => {
    deveEfetuarAssinaturaDocuSign(
      solicitarAssinaturaBeneficioProtecao,
      dadosClienteModalTipoDocuSing,
      resultAlterarValorContribuicaoBeneficioProtecao,
      DefaultValue.URL_CAIXA,
    );
  };

  function deveExibirSkeletonLoading() {
    return checkIfSomeItemsAreTrue([
      loadingDadosAntigosFundo,
      loadingDadosBeneficioProtecao,
    ]);
  }

  useEffect(() => {
    obterDadosBeneficioProtecao();

    subscribe(event => {
      const {
        data: { eventName, eventSuccess },
      } = event as MessageEvent<IMessageEvent>;
      if (
        checkIfAllItemsAreTrue([
          eventName === 'retornoAcaoConcluirAssinatura',
          eventSuccess,
        ])
      ) {
        setExibirBotaoFecharModal(true);
      }
    });

    return () => {
      unsubscribe(() => setExibirBotaoFecharModal(false));
    };
  }, []);

  useEffect(() => {
    if (
      checkIfAllItemsAreTrue([!loadingObterContatos, recuperacaoEmailExecutada])
    ) {
      setOpenAlertaContatos(true);
    }
  }, [loadingObterContatos, recuperacaoEmailExecutada]);

  useEffect(() => {
    buscarValoresContribuicao();
    scrollEndScreen();
  }, [contribuicaoValor]);

  useEffect(() => {
    deveIrFimDaTela(exibirAlterarValorBeneficioProtecao);
  }, [exibirAlterarValorBeneficioProtecao]);

  useEffect(() => {
    if (tabelaAtualizadaRequest) {
      obterSolicitacaoAlteracaoContribuicaoRenda({
        novoValorContribuicao: valorProtecaoSobrevivencia,
        codPlano: resumoContribuicao.sobrevivenciaAtiva?.planId,
        codBeneficio: resumoContribuicao.sobrevivenciaAtiva?.benefitId,
        reserves: tabelaAtualizadaRequest,
      });
    }
  }, [tabelaAtualizadaRequest]);

  useEffect(() => {
    deveObterDadosParticipante(
      consentimentoValue,
      confirmarClienteCienteValue,
      dadosParticipanteHook.obterDadosParticipante,
    );
  }, [consentimentoValue, confirmarClienteCienteValue]);

  useEffect(() => {
    obterDadosClienteModalTipoDocuSingProps(
      setDadosClienteModalTipoDocuSing,
      dadosParticipanteHook.response,
    );
  }, [dadosParticipanteHook.response]);

  useEffect(() => {
    deveExibirModalTokenDocuSign(
      setConsentimentoValue,
      setConfirmarClienteCienteValue,
      setExibirModalTokenDocuSign,
      dadosSolicitarAssinaturaAlteracaoValorBeneficioProtecao,
    );
  }, [dadosSolicitarAssinaturaAlteracaoValorBeneficioProtecao]);

  return (
    <PE.Display type="block">
      <PrevidenciaResumo />

      <RenderConditional condition={deveExibirSkeletonLoading()}>
        <PE.Card>
          <PE.Card.Content>
            <SkeletonLoading blocks={1} />
          </PE.Card.Content>
        </PE.Card>
      </RenderConditional>

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          !deveExibirSkeletonLoading(),
          !responseSolicitacaoAlteracaoContribuicaoRenda,
        ])}
      >
        <PE.Card>
          <PE.Card.Content>
            <ValoresContribuicao
              resumoContribuicao={resumoContribuicao}
              loadingValoresContribuicao={loadingValoresContribuicao}
            />

            <RenderConditional condition={!exibirNovosValoresBeneficioProtecao}>
              <OpcaoAlterarContribuicao
                resumoContribuicao={resumoContribuicao}
                loadingValoresContribuicao={loadingValoresContribuicao}
                valorMinimo={Number(responseMultiFundo?.vlMinContribuicao)}
                onChange={handleOnChangeOpcaoAlterarContribuicao}
                onRedistribuir={handleOnRedistribuirOpcaoAlterarContribuicao}
                onAlterarValor={handleOnAlterarValorOpcaoAlterarContribuicao}
                onAlterarBeneficioProtecao={
                  handleOnAlterarBeneficioProtecaoOpcaoAlterarContribuicao
                }
              />
            </RenderConditional>

            <RenderConditional condition={mostrarTabelaFundos}>
              <PE.Display display="block" marginLeft="15px">
                <TabelaFundos
                  isLoading={checkIfSomeItemsAreTrue([
                    loadingValoresContribuicao,
                    loadingSolicitacaoAlteracaoContribuicaoRenda,
                  ])}
                  fundos={[
                    ...converterListaFundos({
                      data: responseMultiFundo?.fundosExistentes?.fundos,
                      valorMinimoContribuicao:
                        responseMultiFundo?.vlMinContribuicao,
                      tipoFundo: DefaultValue.TIPO_FUNDO_EXISTENTE,
                    }),
                    ...converterListaFundos({
                      data: responseMultiFundo?.fundosNovos?.fundos,
                      valorMinimoContribuicao:
                        responseMultiFundo?.vlMinContribuicao,
                      exibirFundosNovos,
                      tipoFundo: DefaultValue.TIPO_FUNDO_NOVO,
                    }),
                  ]}
                  valorTotal={Number(valorProtecaoSobrevivencia)}
                  valorMinimoContribuicao={
                    responseMultiFundo?.vlMinContribuicao
                  }
                  quantidadeMaximaFundos={CONSTS.QUANTIDADE_MAXIMA_FUNDOS}
                  ref={tabelaFundosRef}
                  tipoResgate={undefined}
                  resgate={undefined}
                  descricaoTabela={descricaoTabela}
                  quantidadeMaximaFundosPorTipos={
                    quantidadeMaximaFundosPorTipos
                  }
                />
                <ButtonsValidaDadosTabelaFundos
                  onClick={handleAtualizarValorContribuicao}
                  labelButtonSubmit="Alterar"
                  loading={loadingSolicitacaoAlteracaoContribuicaoRenda}
                />
              </PE.Display>
            </RenderConditional>

            <RenderConditional
              condition={
                !!resumoContribuicao.listaProtecoesAtivas.length &&
                exibirAlterarValorBeneficioProtecao
              }
            >
              <AlterarValorContribuicaoBeneficioProtecao
                resumoContribuicao={resumoContribuicao}
                dadosBeneficioProtecao={
                  dadosBeneficioProtecao as ResponseDadosBeneficioProtecao
                }
                setNovoValorTipoBeneficio={setNovoValorTipoBeneficio}
                loadingCalculoBeneficioProtecao={
                  loadingCalculoBeneficioProtecao
                }
                calculoBeneficioProtecao={calculoBeneficioProtecao}
              />
            </RenderConditional>

            <RenderConditional
              condition={
                exibirNovosValoresBeneficioProtecao &&
                !confirmarClienteCienteValue
              }
            >
              <ModalConfirmacaoAlteracaoValorContribuicaoBeneficioProtecao
                loadingCalculoBeneficioProtecao={
                  loadingCalculoBeneficioProtecao
                }
                open={exibirNovosValoresBeneficioProtecao}
                consentimento={[consentimentoValue, setConsentimentoValue]}
                setConfirmarClienteCienteValue={setConfirmarClienteCienteValue}
                setOpenModalDocusign={setOpenModalDocusign}
                dadosBeneficioProtecao={dadosBeneficioProtecao}
                dataModalConfirmacao={dataModalConfirmacao}
                exibirNovosValoresBeneficioProtecao={[
                  exibirNovosValoresBeneficioProtecao,
                  setExibirNovosValoresBeneficioProtecao,
                ]}
                setResultAlterarValorContribuicaoBeneficioProtecao={
                  setResultAlterarValorContribuicaoBeneficioProtecao
                }
              />
            </RenderConditional>

            <RenderConditional
              condition={checkIfAllItemsAreTrue([
                consentimentoValue,
                confirmarClienteCienteValue,
              ])}
            >
              <ModalTipoDocuSing
                tituloModal="Alteração de Valor de Contribuição do Benefício Proteção"
                open={openModalDocusign}
                handleClose={close => {
                  setConfirmarClienteCienteValue(close);
                  setConsentimentoValue(close);
                  setExibirNovosValoresBeneficioProtecao(close);
                  setOpenModalDocusign(close);
                }}
                executarAssinatura={handleAssinar}
                loading={checkIfSomeItemsAreTrue([
                  dadosParticipanteHook.loading,
                  isLoadingSolicitarAssinaturaAlteracaoValorBeneficioProtecao,
                ])}
                dadosCliente={
                  dadosClienteModalTipoDocuSing as DadosClienteModalTipoDocuSingProps
                }
              />
            </RenderConditional>

            <RenderConditional
              condition={checkIfAllItemsAreTrue([
                exibirModalTokenDocuSign,
                !!dadosSolicitarAssinaturaAlteracaoValorBeneficioProtecao,
              ])}
            >
              <ModalTokenDocuSign
                open={exibirModalTokenDocuSign}
                handleClose={() => setExibirModalTokenDocuSign(false)}
                urlTokenPage={tryGetValueOrDefault(
                  [
                    dadosSolicitarAssinaturaAlteracaoValorBeneficioProtecao?.urlAssinatura,
                  ],
                  '',
                )}
                exibirBotaoFecharModal={exibirBotaoFecharModal}
              />
            </RenderConditional>
            <PE.Display margin>
              <PE.Button variant="outlined" onClick={goDadosPlano}>
                Voltar
              </PE.Button>
            </PE.Display>
          </PE.Card.Content>
        </PE.Card>
      </RenderConditional>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        {mensagemModal}
      </Modal>

      <Modal
        open={modalMsgAlteracaoValorBeneficioProtecao}
        onClose={() => setmodalMsgAlteracaoValorBeneficioProtecao(false)}
      >
        {mensagemBeneficioProtecao}

        <PE.Display justify="center" marginTop={20}>
          <PE.Button
            variant="secondary"
            onClick={() => setmodalMsgAlteracaoValorBeneficioProtecao(false)}
          >
            Certo, entendi
          </PE.Button>
        </PE.Display>
      </Modal>

      <Modal
        open={openModalRangeValorValidoBeneficio}
        onClose={() => setOpenModalRangeValorValidoBeneficio(false)}
      >
        <RenderConditional condition={loadingCalculoBeneficioProtecao}>
          <SkeletonLoading blocks={1} />
        </RenderConditional>

        <RenderConditional condition={!loadingCalculoBeneficioProtecao}>
          {mensagemRangeValoresPermitidos?.descricao}
        </RenderConditional>

        <PE.Display justify="center" marginTop={20}>
          <PE.Button
            variant="secondary"
            onClick={() => setOpenModalRangeValorValidoBeneficio(false)}
          >
            Certo, entendi
          </PE.Button>
        </PE.Display>
      </Modal>

      <ModalAlertaContatos
        open={openAlertaContatos}
        onClose={() => setOpenAlertaContatos(false)}
        redirect={() =>
          history.push('/cliente/produtos/previdencia/dados-participante')
        }
        dados={{
          email: emailDefault,
          telefone: numerosTelefone,
        }}
      />

      <RenderConditional
        condition={!!responseSolicitacaoAlteracaoContribuicaoRenda}
      >
        <SolicitarAlteracaoContribuicaoRenda
          tabelaAtualizada={tabelaAtualizada as Fundo[]}
          tabelaAntiga={tabelaAntiga}
          resultadoSolicitacaoContribuicaoRenda={
            responseSolicitacaoAlteracaoContribuicaoRenda as SolicitacaoAlteracaoContribuicaoRenda
          }
          voltarTelaAlterarValorContribuicao={
            voltarTelaAlterarValorContribuicao
          }
        />
      </RenderConditional>
    </PE.Display>
  );
};

export default AlterarValorContribuicao;
