import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Grid, Card, Display, Text, Button } from '@cvp/design-system/react';
import { AppContext } from 'main/contexts/AppContext';
import { useObterContatosDefaultPrevidencia } from 'main/hooks/useObterContatosDefaultPrevidencia';
import { useMessageEvent } from 'main/hooks/useMessageEvent';
import ModalTipoDocuSign from 'main/components/AssinaturaDocuSign/ModalTipoDocuSign';
import ModalTokenDocuSign from 'main/components/AssinaturaDocuSign/ModalTokenDocuSign';
import RenderConditional from 'main/components/RenderConditional';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { IMessageEvent } from 'main/types/IMessageEvent';
import {
  PREV_REDIRECTS_SCREENS,
  PrevRedirectConfig,
} from 'previdencia/config/redirects';
import { useTransferenciaInternaContext } from 'previdencia/features/TransferenciaInternaEmissaoConjugado/contexts/TransferenciaInternaContext';
import useAquisicao from 'previdencia/features/TransferenciaInternaEmissaoConjugado/hooks/useAquisicao';
import ModalInformeCliente from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/Modal/ModalInformeCliente';
import { criarListaCoberturasFactory } from 'previdencia/features/TransferenciaInternaEmissaoConjugado/factories/aquisicaoVgblConjugadoFactory';
import { initialModalAquisicaoState } from 'previdencia/features/TransferenciaInternaEmissaoConjugado/factories/initialStateFactory';
import * as CONSTS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/constants/constants';
import * as VGBL_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgbl';
import * as S from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/Aquisicao/styles';
import * as TRANSF_VGBL_STYLES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/styles';

const Aquisicao: React.FC = () => {
  const { cliente } = useContext(AppContext);
  const history = useHistory();

  const {
    selecionarFormaPagamento,
    coberturasCuidadoGarantido,
    dadosOfertasVgblConjugado,
    saldoTotalFundosAtuais,
    contribuicaoRendaMensal,
    numeroSolicitacao: numeroSolicitacaoHistoricoSolicitacoes,
  } = useTransferenciaInternaContext();

  const { data: responseObterContatosDefault } =
    useObterContatosDefaultPrevidencia();

  const { emailDefault, numerosTelefone } = responseObterContatosDefault ?? {};

  const {
    loadingTransferirProdutoVgbl,
    transferirProdutoVgbl,
    loadingSolicitarAssinaturaTransfVgbl,
    solicitarAssinaturaTransfVgbl,
    urlAssinatura,
  } = useAquisicao({ ...responseObterContatosDefault });

  const [open, setOpen] = useState<VGBL_TYPES.ModalAquisicaoVgblConjugado>(
    initialModalAquisicaoState,
  );

  const [exibirBotaoFecharModal, setExibirBotaoFecharModal] =
    useState<boolean>(false);

  const [checkInfoAcaoTransferencia, setCheckInfoAcaoTransferencia] =
    useState<boolean>(false);

  const { subscribe, unsubscribe } = useMessageEvent();

  const tabelaFundosSelecionados =
    dadosOfertasVgblConjugado?.produtos?.[0]?.fundos?.filter(
      fundoSelecionado => fundoSelecionado.selecionado,
    );

  const criarListaFundosSelecionadosFactory =
    (): VGBL_TYPES.ListaFundosSelecionados[] => {
      let listaFundosSelecionados: VGBL_TYPES.ListaFundosSelecionados[] = [];

      const resumoFundosSelecionados = {
        valorReserva: tryGetMonetaryValueOrDefault(saldoTotalFundosAtuais),
        valorTransferencia: tryGetMonetaryValueOrDefault(
          saldoTotalFundosAtuais,
        ),
        fundos: tabelaFundosSelecionados?.map(fundo => (
          <Text variant="body02-sm">
            {fundo.descricaoFundo} -{' '}
            <strong>Tx {fundo.taxaAdministracao.replace('.', ',')}%</strong>
          </Text>
        )),
      };

      listaFundosSelecionados = [
        ...listaFundosSelecionados,
        resumoFundosSelecionados,
      ];

      return listaFundosSelecionados;
    };

  const totalContribuicoesMensais: number =
    tryGetValueOrDefault(
      [coberturasCuidadoGarantido.pensao?.valorContribuicao],
      0,
    ) +
    tryGetValueOrDefault(
      [coberturasCuidadoGarantido.peculio?.valorContribuicao],
      0,
    ) +
    tryGetValueOrDefault([Number(contribuicaoRendaMensal)], 0);

  const redirecionarHistoricoSolicitacoes = (): void => {
    history.push(
      PrevRedirectConfig[PREV_REDIRECTS_SCREENS.HISTORICO_SOLICITACOES],
    );
  };

  const handleAssinar = (): void => {
    solicitarAssinaturaTransfVgbl();
    setOpen(prevState => ({
      ...prevState,
      modalDocusign: true,
    }));
  };

  const handleModalInformeCliente = (): void => {
    setOpen(prevState => ({
      ...prevState,
      modalInformeCliente: !prevState.modalInformeCliente,
    }));
  };

  const confirmarTransferenciaVgblConjugado = async (): Promise<void> => {
    const retornoConfirmacaoTransferenciaVgblConjugado =
      await transferirProdutoVgbl();

    const numeroSolicitacaoTransferencia =
      retornoConfirmacaoTransferenciaVgblConjugado?.entidade?.dados
        .numeroSolicitacao;

    if (numeroSolicitacaoTransferencia) {
      setOpen(prevState => ({
        ...prevState,
        modalTipoDocusign: true,
      }));
    }
  };

  const executarAcoesAposAssinaturaDocusign = (): void => {
    subscribe(event => {
      const {
        data: { eventName, eventSuccess },
      } = event as MessageEvent<IMessageEvent>;

      if (
        checkIfAllItemsAreTrue([
          eventName === 'retornoAcaoConcluirAssinatura',
          eventSuccess,
        ])
      ) {
        setExibirBotaoFecharModal(true);
      }
    });
  };

  const executarAssinaturaHistoricoSolicitacoes = (): void => {
    if (numeroSolicitacaoHistoricoSolicitacoes) {
      handleModalInformeCliente();
      setOpen(prevState => ({
        ...prevState,
        modalTipoDocusign: true,
      }));
    }
  };

  const handleCloseModalTipoDocusign = (): void => {
    setOpen(prevState => ({
      ...prevState,
      modalTipoDocusign: !prevState.modalTipoDocusign,
    }));

    redirecionarHistoricoSolicitacoes();
  };

  const handleCloseModalTokenDocusign = (): void => {
    setOpen(prevState => ({
      ...prevState,
      modalDocusign: !prevState.modalDocusign,
    }));

    redirecionarHistoricoSolicitacoes();
  };

  const handleRedirectRequestHistory = (e: KeyboardEvent): void => {
    e.preventDefault();

    if (e.key === CONSTS.F5_BTN) {
      redirecionarHistoricoSolicitacoes();
    }
  };

  useEffect(() => {
    executarAcoesAposAssinaturaDocusign();
    executarAssinaturaHistoricoSolicitacoes();

    return () => {
      unsubscribe(() => setExibirBotaoFecharModal(false));
    };
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleRedirectRequestHistory);

    return () =>
      window.removeEventListener('keydown', handleRedirectRequestHistory);
  }, [history, open.modalTipoDocusign, open.modalDocusign]);

  return (
    <>
      <Display type="display-block">
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-05" color="primary" margin>
                  {CONSTS.TEXTOS_AQUISICAO_VGBL.TITULO}
                </Text>
              </Grid.Item>
              <Grid.Item xs={1}>
                <TRANSF_VGBL_STYLES.TabelaTransferenciaInterna
                  marginBottom={20}
                  columns={CONSTS.COLUNAS_TABELA_AQUISICAO_COBERTURA}
                  noHeader
                  highlightOnHover
                  striped
                  responsive
                  title="Cobertura Aquisição"
                  data={criarListaCoberturasFactory({
                    coberturasCuidadoGarantido,
                  })}
                  noDataComponent={CONSTS.NAO_HA_DADOS_TABELA}
                />
              </Grid.Item>

              <Grid.Item xs={1}>
                <TRANSF_VGBL_STYLES.TabelaTransferenciaInterna
                  marginBottom={20}
                  columns={CONSTS.COLUNAS_TABELA_AQUISICAO_FUNDOS}
                  noHeader
                  highlightOnHover
                  striped
                  responsive
                  title="Fundos Aquisição"
                  data={criarListaFundosSelecionadosFactory()}
                  noDataComponent={CONSTS.NAO_HA_DADOS_TABELA}
                />
              </Grid.Item>

              <Grid.Item xs={1}>
                <Text variant="body02-sm">
                  Contribuição de renda mensal é de{' '}
                  {tryGetMonetaryValueOrDefault(contribuicaoRendaMensal)}.
                </Text>

                <RenderConditional
                  condition={
                    !!coberturasCuidadoGarantido.pensao?.valorContribuicao
                  }
                >
                  <Text variant="body02-sm">
                    Contribuição de cuidado garantido mensal -{' '}
                    <strong>Pensão por prazo certo</strong> de{' '}
                    {tryGetMonetaryValueOrDefault(
                      coberturasCuidadoGarantido.pensao?.valorContribuicao,
                    )}
                    .
                  </Text>
                </RenderConditional>

                <RenderConditional
                  condition={
                    !!coberturasCuidadoGarantido.peculio?.valorContribuicao
                  }
                >
                  <Text variant="body02-sm" margin>
                    Contribuição de cuidado garantido mensal -{' '}
                    <strong>Pecúlio</strong> de{' '}
                    {tryGetMonetaryValueOrDefault(
                      coberturasCuidadoGarantido.peculio?.valorContribuicao,
                    )}
                    .
                  </Text>
                </RenderConditional>

                <Text variant="body02-sm" margin>
                  <strong>
                    Total de contribuições mensais é de:{' '}
                    {tryGetMonetaryValueOrDefault(totalContribuicoesMensais)}.
                  </strong>
                </Text>

                <S.Text variant="body02-sm" margin>
                  <S.Checkbox
                    onClick={() =>
                      setCheckInfoAcaoTransferencia(!checkInfoAcaoTransferencia)
                    }
                  />
                  {CONSTS.TEXTOS_AQUISICAO_VGBL.CONSENTIMENTO}
                </S.Text>

                <RenderConditional condition={!checkInfoAcaoTransferencia}>
                  <Text variant="body02-sm" color="error" margin>
                    {CONSTS.TEXTOS_AQUISICAO_VGBL.AVISO_CONSENTIMENTO}
                  </Text>
                </RenderConditional>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Display>
                  <Button variant="outlined" onClick={selecionarFormaPagamento}>
                    Voltar
                  </Button>
                  <Button
                    onClick={confirmarTransferenciaVgblConjugado}
                    disabled={checkIfSomeItemsAreTrue([
                      !checkInfoAcaoTransferencia,
                      loadingTransferirProdutoVgbl,
                    ])}
                    loading={loadingTransferirProdutoVgbl}
                  >
                    Confirmar
                  </Button>
                </Display>
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </Display>

      <ModalInformeCliente
        toggleModal={open.modalInformeCliente}
        handleModal={handleModalInformeCliente}
      />

      <ModalTipoDocuSign
        tituloModal={CONSTS.TEXTOS_AQUISICAO_VGBL.TITULO_MODAL_DOCUSIGN}
        open={open.modalTipoDocusign}
        handleClose={handleCloseModalTipoDocusign}
        executarAssinatura={handleAssinar}
        loading={loadingSolicitarAssinaturaTransfVgbl}
        dadosCliente={{
          email: emailDefault,
          mobileNumber: numerosTelefone,
          cpfCnpj: cliente.cpfCnpj,
          numeroCertificado: cliente.numCertificado,
          tipoProduto: undefined,
        }}
      />

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          open.modalDocusign,
          !!urlAssinatura,
        ])}
      >
        <ModalTokenDocuSign
          open={open.modalDocusign}
          handleClose={handleCloseModalTokenDocusign}
          urlTokenPage={tryGetValueOrDefault([urlAssinatura], '')}
          exibirBotaoFecharModal={exibirBotaoFecharModal}
        />
      </RenderConditional>
    </>
  );
};

export default Aquisicao;
