import React from 'react';
import { TextField } from '@cvp/design-system/react';

import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import masks from 'main/utils/masks';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';
import { ContainerInputHelper } from 'previdencia/features/SolicitacaoResgate/pages/styles';

const ColunaContribuicao = ({
  row,
  selectedTableInputFocus,
  setSelectedTableInputFocus,
  handleValorContribuicao,
}: SOLICITACAO_RESGATE_TYPES.IColunaContribuicaoProps) => {
  return (
    <ContainerInputHelper>
      <TextField
        onFocus={() => {
          setSelectedTableInputFocus(row.codigoFundo);
        }}
        autoFocus={row.codigoFundo === selectedTableInputFocus}
        disabled
        placeholder={tryGetMonetaryValueOrDefault('0')}
        value={masks.currency.mask(Number(row.valorContribuicao))}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement>) => {
          handleValorContribuicao(
            row.codigoFundo,
            (Number(masks.currencyInput.unmask(value)) / 100).toFixed(2),
          );
        }}
      />
    </ContainerInputHelper>
  );
};

export default ColunaContribuicao;
