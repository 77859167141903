import { Button, Card, Disclaimer } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import RenderConditional from 'main/components/RenderConditional';
import { Skeleton as TableSkeleton } from 'main/components/Table';
import TableFilters from 'main/components/Table/TableFilter/TableFilter';
import * as S from 'main/components/Table/customStyles';
import { parseDateStringToDate } from 'main/utils';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import * as P from 'contratosPrestamista/components/prestamistaStyles';
import {
  MENSAGEM_FILTRO_SEM_DADOS,
  STATUS_PROPOSTA_ENDPOINT,
} from 'contratosPrestamista/constants';
import {
  ColunasTabelaStatusProposta,
  FiltrosTabelaStatusProposta,
} from 'contratosPrestamista/features/uploadproposta/components/ColunasTabelaStatusProposta';
import {
  useConsultarListaStatusPropostaPorAgencia,
  useConsultarListaStatusPropostaPorCpf,
} from 'contratosPrestamista/features/uploadproposta/hooks/useConsultarStatusProposta';
import {
  IConsultarStatusPropostaResponse,
  IListagemStatusPropostaLocationState,
} from 'contratosPrestamista/features/uploadproposta/types/IConsultarStatusProposta';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

const ListagemStatusProposta: React.FC = () => {
  const history = useHistory();
  const colunas = useMemo(() => ColunasTabelaStatusProposta, []);
  const { state } = useLocation<IListagemStatusPropostaLocationState>();

  const { data: resultadoConsultaPorCpf, isFetching: loadingConsultaPorCpf } =
    useConsultarListaStatusPropostaPorCpf({
      cpfCnpj: state?.cpf,
      dataInicial: parseDateStringToDate(state?.dataInicial),
      dataFinal: parseDateStringToDate(state?.dataFinal),
    });

  const {
    data: resultadoConsultaPorAgencia,
    isFetching: loadingConsultaPorAgencia,
  } = useConsultarListaStatusPropostaPorAgencia({
    cpfCnpj: state?.cpf,
    dataInicial: parseDateStringToDate(state?.dataInicial),
    dataFinal: parseDateStringToDate(state?.dataFinal),
  });

  const loading = checkIfSomeItemsAreTrue([
    loadingConsultaPorAgencia,
    loadingConsultaPorCpf,
  ]);

  const dadosBFF: IConsultarStatusPropostaResponse[] = tryGetValueOrDefault(
    [resultadoConsultaPorAgencia?.entidade],
    tryGetValueOrDefault([resultadoConsultaPorCpf?.entidade], []),
  );

  const hasError = checkIfSomeItemsAreTrue([
    resultadoConsultaPorCpf?.sucessoGI === false,
    resultadoConsultaPorAgencia?.sucessoGI === false,
    resultadoConsultaPorCpf?.sucessoBFF === false,
    resultadoConsultaPorAgencia?.sucessoBFF === false,
  ]);

  return (
    <Card style={{ minHeight: 'auto' }}>
      <Card.Content data-testid="tabelaListaPropostas">
        <RenderConditional condition={loading}>
          <TableSkeleton colunas={colunas} />
        </RenderConditional>

        <RenderConditional condition={!loading}>
          <>
            <TableFilters
              dataToFilter={dadosBFF}
              filterOptions={FiltrosTabelaStatusProposta}
              filterTextPartial
              customButton={
                <Button
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Nova Consulta
                </Button>
              }
            >
              {dataFiltered => (
                <>
                  <S.Table
                    responsive
                    noHeader
                    data={dataFiltered}
                    columns={colunas}
                    noDataComponent={
                      dadosBFF !== undefined ? MENSAGEM_FILTRO_SEM_DADOS : ''
                    }
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={{
                      rowsPerPageText: 'Items por página',
                      rangeSeparatorText: 'de',
                    }}
                    style={{ marginTop: '3rem' }}
                  />
                </>
              )}
            </TableFilters>

            <RenderConditional condition={hasError}>
              <P.Disclaimer variant="error">
                <Disclaimer.Content
                  text={STATUS_PROPOSTA_ENDPOINT}
                  icon={<Icon name="information" />}
                />
              </P.Disclaimer>
            </RenderConditional>
          </>
        </RenderConditional>
      </Card.Content>
    </Card>
  );
};

export default ListagemStatusProposta;
