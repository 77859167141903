import React from 'react';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { Divider, Text } from '@cvp/design-system/react';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { TableSemHeader } from 'seguros/components/TabelaApoliceCoberturas/styles';
import * as CONSTS from 'seguros/constants/TabelaApoliceCoberturas';
import * as APOLICE_TYPES from 'seguros/types/ApoliseCobertura';
import * as S from 'seguros/components/TabelasApoliceImpressao/components/styles';
import DetalhesCoberturaImpressao from 'seguros/components/TabelasApoliceImpressao/components/DetalhesCoberturaImpressao';

export const TabelaApoliceCoberturasImpressao: React.FC<
  APOLICE_TYPES.TabelaApoliceCoberturasProps
> = ({ data, columns }) => {
  const hasMoreDetails = data.some(item => item.descricaoCobertura);

  const expandedItem = (row: unknown) => {
    return !!(row as any)?.descricaoCobertura;
  };

  return (
    <RenderConditional condition={!!data.length}>
      <Text variant="body02-md" color="primary" margin>
        Coberturas e Assistências
      </Text>

      <S.WrapperTableContainer>
        <TableSemHeader
          noHeader
          responsive
          columns={tryGetValueOrDefault(
            [columns],
            CONSTS.COLUNAS_TABELA_COBERTURAS,
          )}
          data={tryGetValueOrDefault([data], [])}
          expandableRows={hasMoreDetails}
          expandableRowExpanded={expandedItem}
          expandableRowDisabled={(
            row: APOLICE_TYPES.ITabelaApoliceCoberturasItens | any,
          ) => !row.descricaoCobertura}
          expandableRowsComponent={<DetalhesCoberturaImpressao />}
          expandableRowsHideExpander
          noDataComponent={CONSTS.SEM_DADOS_COBERTURAS}
        />
        <Divider />
      </S.WrapperTableContainer>
    </RenderConditional>
  );
};
