import React from 'react';
import DetalhesDefinirParecer from '../components/DetalhesDefinirParecer';
import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';
import { useAtualizarStatusProspeccao } from '../hooks/useAtualizarStatusProspeccao';

const DetalhesDefinirParecerMedico: React.FC = () => {
  const { devePermitirParecerMedico } = useAtualizarStatusProspeccao();

  return (
    <DetalhesDefinirParecer
      perfilAvaliador={USER_PROFILES.MEDICO}
      approveRejectIds={{ approve: 3, reject: 4 }}
      title="Parecer Médico"
      permitirParecer={devePermitirParecerMedico}
    />
  );
};

export default DetalhesDefinirParecerMedico;
