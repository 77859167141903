import React from 'react';
import {
  Card,
  Display,
  Text,
  Grid,
  Button,
  Modal,
} from '@cvp/design-system/react';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import RenderConditional from 'main/components/RenderConditional';
import ModalAtualizacaoDados from '../components/ModalAtualizacaoDados';
import CardSkeleton from 'main/components/Card/CardSkeleton';
import masks from 'main/utils/masks';
import CardFeatureDescription from '../components/CardFeatureDescription';
import { useResetCadastro } from '../hooks/useResetCadastro';

const ResetCadastro: React.FC = () => {
  const resetService = useResetCadastro();
  return (
    <>
      <CardFeatureDescription
        loadingObterDadosCliente={resetService.loadingObterDadosCliente}
      />
      <RenderConditional
        condition={checkIfSomeItemsAreTrue([
          !!resetService.areaLogada,
          !!resetService.baseUnica,
        ])}
      >
        <Display type="display-block">
          <Grid>
            <Grid.Item md={1 / 2}>
              <RenderConditional
                condition={resetService.loadingObterDadosCliente}
              >
                <CardSkeleton />
              </RenderConditional>
              <RenderConditional
                condition={!resetService.loadingObterDadosCliente}
              >
                <Card style={{ wordWrap: 'break-word' }}>
                  <Card.Content>
                    <Text variant="body-medium3" color="primary" margin>
                      <strong>{resetService.areaLogada?.nomeBase}</strong>
                    </Text>
                    <Display type="block">
                      <Grid>
                        <Grid.Item md={1} xs={1}>
                          <Text variant="body02-sm">
                            <strong>Nome:</strong>
                          </Text>
                          <Text variant="caption">
                            {resetService.areaLogada?.nome}
                          </Text>
                        </Grid.Item>
                        <Grid.Item md={1} xs={1}>
                          <Text variant="body02-sm">
                            <strong>E-mail:</strong>
                          </Text>
                          <Text variant="caption">
                            {resetService.areaLogada?.email}
                          </Text>
                        </Grid.Item>
                        <Grid.Item md={1} xs={1}>
                          <Text variant="body02-sm">
                            <strong>Telefone:</strong>
                          </Text>
                          <Text variant="caption">
                            {masks.phone.mask(
                              `${resetService.areaLogada?.numDDD}${resetService.areaLogada?.numTelefone}`,
                            )}
                          </Text>
                        </Grid.Item>
                        <Grid.Item md={1} xs={1}>
                          <Text variant="body02-sm">
                            <strong>Data último acesso:</strong>
                          </Text>
                          <Text variant="caption">
                            {resetService.areaLogada?.dataUltimoAcesso}
                          </Text>
                        </Grid.Item>
                      </Grid>
                    </Display>
                  </Card.Content>
                </Card>
              </RenderConditional>
            </Grid.Item>
            <Grid.Item md={1 / 2}>
              <RenderConditional
                condition={resetService.loadingObterDadosCliente}
              >
                <CardSkeleton />
              </RenderConditional>
              <RenderConditional
                condition={!resetService.loadingObterDadosCliente}
              >
                <Card style={{ wordWrap: 'break-word' }}>
                  <Card.Content>
                    <Text variant="body-medium3" color="primary" margin>
                      <strong>{resetService.baseUnica?.nomeBase}</strong>
                    </Text>
                    <Display>
                      <Grid>
                        <Grid.Item md={1} xs={1} margin>
                          <Text variant="body02-sm">
                            <strong>Nome:</strong>
                          </Text>
                          <Text variant="caption">
                            {resetService.baseUnica?.nome}
                          </Text>
                        </Grid.Item>
                        <Grid.Item md={1} xs={1}>
                          <Text variant="body02-sm">
                            <strong>E-mail:</strong>
                          </Text>
                          <Text variant="caption">
                            {resetService.baseUnica?.email}
                          </Text>
                        </Grid.Item>
                        <Grid.Item md={1} xs={1}>
                          <Text variant="body02-sm">
                            <strong>Telefone:</strong>
                          </Text>
                          <Text variant="caption">
                            {masks.phone.mask(
                              `${resetService.baseUnica?.numDDD}${resetService.baseUnica?.numTelefone}`,
                            )}
                          </Text>
                        </Grid.Item>
                      </Grid>
                    </Display>
                    <RenderConditional
                      condition={resetService.habilitarAtualizacaoCliente}
                    >
                      <Button
                        variant="secondary"
                        onClick={
                          resetService.handleOpenCloseModalAtualizacaoDados
                        }
                      >
                        Atualizar dados
                      </Button>
                    </RenderConditional>
                  </Card.Content>
                </Card>
              </RenderConditional>
            </Grid.Item>
          </Grid>
          <RenderConditional
            condition={checkIfSomeItemsAreTrue([
              !resetService.loadingAtualizarDadosCliente,
              !resetService.loadingAtualizarDadosCliente,
              !resetService.loadingDeletarCliente,
            ])}
          >
            <Display type="display-block">
              <Card>
                <Card.Content>
                  <Text variant="headline-04" color="primary" margin={10}>
                    Resultado da análise
                  </Text>
                  <Text variant="caption-02" margin>
                    {resetService.resultadoAnalise}
                  </Text>
                  <Display>
                    <RenderConditional condition={resetService.habilitarReset}>
                      <Button
                        loading={resetService.loadingDeletarCliente}
                        onClick={
                          resetService.handleOpenCloseModalConfirmacaoDelete
                        }
                      >
                        Deletar cadastro
                      </Button>
                    </RenderConditional>

                    <Button
                      variant="outlined"
                      onClick={resetService.handleNovaConsulta}
                    >
                      Nova Consulta
                    </Button>
                  </Display>
                </Card.Content>
              </Card>
            </Display>
          </RenderConditional>
        </Display>
      </RenderConditional>
      <ModalAtualizacaoDados
        open={resetService.openModalAtualizacaoDados}
        handleClose={resetService.handleOpenCloseModalAtualizacaoDados}
        loading={resetService.loadingAtualizarDadosCliente}
        formik={resetService.formik}
      />
      <Modal
        show={resetService.openModalConfirmacaoDelete}
        onClose={() =>
          resetService.setOpenModalConfirmacaoDelete(
            !resetService.openModalConfirmacaoDelete,
          )
        }
      >
        <Text variant="caption" margin>
          Tem certeza que deseja deletar o cadastro do cliente?
        </Text>

        <Display justify="center">
          <Button
            variant="primary"
            onClick={resetService.handleDeletarCliente}
            data-testid="confirmar"
            loading={resetService.loadingDeletarCliente}
          >
            Confirmar
          </Button>
          <Button
            variant="secondary"
            onClick={resetService.handleOpenCloseModalConfirmacaoDelete}
            data-testid="cancelar"
          >
            Cancelar
          </Button>
        </Display>
      </Modal>
    </>
  );
};

export default ResetCadastro;
