import React from 'react';
import { Radio, Text } from '@cvp/design-system/react';

import { tryGetValueOrDefault } from 'main/utils/conditional';
import { FlexContainer } from 'main/styles/GlobalStyle';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const ColunaNomeBanco = ({
  row,
  formik,
}: SOLICITACAO_RESGATE_TYPES.IColunaNomeBancoProps) => {
  return (
    <FlexContainer alignItems="center">
      <Radio
        name="nomeBanco"
        value={JSON.stringify(row)}
        checked={
          formik.values.contaExistente.codigoContaBancaria ===
          row.codigoContaBancaria
        }
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement>) => {
          formik.setValues({
            ...formik.values,
            contaExistente: JSON.parse(tryGetValueOrDefault([value], '')),
            isNovaConta: false,
          });
        }}
      />

      <Text variant="body02-md" style={{ marginLeft: 12 }}>
        {tryGetValueOrDefault([row.nomeBanco], '-')}
      </Text>
    </FlexContainer>
  );
};

export default ColunaNomeBanco;
