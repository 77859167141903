import React from 'react';
import { Display } from '@cvp/design-system/react';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';

const Home: React.FC = () => {
  return (
    <Display type="display-block">
      <PrevidenciaResumo />
    </Display>
  );
};

export default Home;
