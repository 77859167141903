import { obterEndpointAtualizarEndereco } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { RequestAlterarEndereco } from '../types/DadosParticipante';

export const atualizarEndereco = async (
  cpfCnpj: string,
  numeroCertificado: string,
  request: RequestAlterarEndereco | undefined,
): Promise<IApiResponse<undefined> | undefined> => {
  if (request) {
    const { data } = await api.post<IApiResponse<undefined>>(
      obterEndpointAtualizarEndereco(),
      {
        cpfCnpj,
        numeroCertificado,
        idEndereco: request.idEndereco,
        endereco: request.endereco,
        numero: request.numero,
        tipoEndereco: request.tipoEndereco,
        complemento: request.complemento,
        bairro: request.bairro,
        cidade: request.cidade,
        idCidade: request.idCidade,
        Estado: request.Estado,
        cep: request.cep,
        Pais: request.Pais,
        tipoRua: request.tipoRua,
        enderecoPadrao: request.enderecoPadrao,
        descricao: 'Atualizar Email - Dados Participante',
      },
    );
    const mensagemErro: string | undefined =
      data?.dados?.mensagens?.[0].descricao;

    if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

    return data;
  }

  return undefined;
};
