import React from 'react';
import { FormikProps, useFormik } from 'formik';
import { Button, Card, Display, Divider } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { WrapperSlideFadeContent } from 'main/components/Wrappers/styled/WrapperSlideFadeContent';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import AlterarValorCapitalHeader from 'seguros/components/AlteracaoValorCapital/AlterarValorCapitalHeader';
import AlterarValorCapitalFaq from 'seguros/components/AlteracaoValorCapital/AlterarValorCapitalFaq';
import AlteracaoValorCapitalTipoSolicitacao from './AlteracaoValorCapitalTipoSolicitacao';
import AlterarValorCapitalForm from 'seguros/components/AlteracaoValorCapital/AlterarValorCapitalForm';
import { CardResumoProduto } from 'seguros/components/CardResumoProduto/CardResumoProduto';
import * as UTILS from 'seguros/utils/AlteracaoValorCapital';
import * as CONSTS from 'seguros/constants/AlteracaoValorCapital';
import { IFormikValuesAlteracaoValorCapital } from 'seguros/types/AlteracaoValorCapital';
import useVidaNavigation from 'seguros/hooks/useVidaNavigation';
import * as S from 'seguros/components/AlteracaoValorCapital/styles';

export const AlteracaoValorCapital: React.FC = () => {
  const { goDadosSeguradoVida } = useVidaNavigation();

  const formik: FormikProps<IFormikValuesAlteracaoValorCapital> =
    useFormik<IFormikValuesAlteracaoValorCapital>({
      enableReinitialize: true,
      initialValues: CONSTS.INITIAL_STATE_FORMIK,
      validationSchema: UTILS.formikAlteracaoValorCapitalSchema,
      validateOnMount: true,
      onSubmit: () => {
        return undefined;
      },
    });

  return (
    <Display type="display-block">
      <CardResumoProduto />
      <Card>
        <Card.Content padding={[6, 8, 6, 8]}>
          <AlterarValorCapitalHeader />

          <AlterarValorCapitalFaq />

          <S.WrapperOptions>
            <AlteracaoValorCapitalTipoSolicitacao formik={formik} />
          </S.WrapperOptions>

          <RenderConditional
            condition={checkIfSomeItemsAreTrue([
              formik.values.isReducao,
              formik.values.isAumento,
            ])}
          >
            <WrapperSlideFadeContent>
              <AlterarValorCapitalForm formik={formik} />
            </WrapperSlideFadeContent>
          </RenderConditional>

          <Divider />

          <Display>
            <Button
              variant="outlined"
              onClick={goDadosSeguradoVida}
              data-testid="btnVoltar"
            >
              Voltar
            </Button>
          </Display>
        </Card.Content>
      </Card>
    </Display>
  );
};
