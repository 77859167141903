import React from 'react';
import { TextField, Select } from '@cvp/design-system/react';

import { showErrorInput } from 'main/utils/formik';
import * as ALTERACAO_CONTA_TYPES from 'previdencia/features/AlteracaoFormaDadosPagamento/types/AlteracaoFormaDadosPagamento';
import * as CONSTS from 'previdencia/features/AlteracaoFormaDadosPagamento/constants/constants';
import * as S from './styles';

const FormAdicionarConta: React.FC<
  ALTERACAO_CONTA_TYPES.IFormAdicionarContaProps
> = ({ formik }) => {
  return (
    <>
      <S.TituloFormulario variant="body01-md" color="text">
        Banco: CAIXA ECONÔMICA FEDERAL
      </S.TituloFormulario>
      <S.ContainerFormulario>
        <S.ContainerColuna>
          <Select
            {...formik.getFieldProps(formik.values.operacao)}
            label="Operação"
            placeholder="Escolha uma opção"
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('operacao', value);
            }}
            {...showErrorInput(formik, 'operacao')}
          >
            {CONSTS.TIPOS_OPERACAO.map(item => (
              <Select.Item
                key={item.id}
                value={item.id}
                text={item.operacao}
                selected={formik.values.operacao === item.id}
              />
            ))}
          </Select>
        </S.ContainerColuna>
        <TextField
          {...formik.getFieldProps(formik.values.agencia)}
          label="Agência*"
          name="agencia"
          maxLength="4"
          value={formik.values.agencia}
          {...showErrorInput(formik, 'agencia')}
        />
        <S.ContainerConta>
          <TextField
            {...formik.getFieldProps(formik.values.conta)}
            label="Conta*"
            name="conta"
            maxLength="13"
            value={formik.values.conta}
            {...showErrorInput(formik, 'conta')}
          />
          <TextField
            {...formik.getFieldProps(formik.values.digitoBanco)}
            label="Dígito*"
            name="digitoBanco"
            maxLength="1"
            value={formik.values.digitoBanco}
            {...showErrorInput(formik, 'digitoBanco')}
          />
        </S.ContainerConta>
      </S.ContainerFormulario>
    </>
  );
};

export default FormAdicionarConta;
