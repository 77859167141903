import React, { useEffect } from 'react';
import { Display, Modal, Button, Text } from '@cvp/design-system/react';

type ModalValorAcumuladoAcimaProps = {
  open: boolean;
  handleClose: () => void;
};

export const ModalValorAcumuladoAcima: React.FC<
  ModalValorAcumuladoAcimaProps
> = ({ open, handleClose }) => {
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  return (
    <Modal show={open} onClose={() => handleClose()}>
      <>
        <Text variant="body02-md" margin>
          <strong>Atenção!</strong>
        </Text>
        <Text variant="body-medium3">
          É necessário a abertura de solicitação no ASC para envio da Declaração
          Pessoal de Saúde (DPS), devidamente assinada pelo cliente.
        </Text>
        <br />
        <Text variant="body-medium3">
          Caso o cliente ainda não tenha preenchido a declaração, você poder
          efetuar o download do documento <strong>FOP 025 04</strong> em
          formulários no menu Prestamista {'>'} Formulários (FOPs).
        </Text>
        <br />
        <Display justify="center" style={{ marginTop: 20 }}>
          <Button
            variant="secondary"
            onClick={handleClose}
            data-testid="cancelar"
          >
            OK
          </Button>
        </Display>
      </>
    </Modal>
  );
};
