import { useUploadPropostaAntecipado } from 'contratosPrestamista/features/uploadproposta/hooks/useUploadPropostaAntecipado';
import Grid from 'main/components/PEComponents/Grid';
import InputFile, {
  DescriptionFormatsFilesAllowed,
} from 'main/components/form/InputFile';
import {
  filesFormatsAllowed,
  maxFileSizeAllowed,
} from 'main/features/Validation/validations';
import React, { useState } from 'react';
import * as S from 'contratosPrestamista/features/uploadproposta/components/styles';
import { ModalStatusUploadProposta } from './ModalStatusUploadProposta';
import { IUploadPropostaProps } from '../types/IUploadPropostaProps';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';

const UploadProposta: React.FC<IUploadPropostaProps> = ({
  dadosContrato,
  openModal,
  handleCloseModal,
  executeAction,
  dpsEnviada,
  listaEnvioDps,
  loadingSolicitarDps,
  arquivoProposta,
}) => {
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showMessageRedirect, setShowMessageRedirect] =
    useState<boolean>(false);
  const [anexoEnviado, setAnexoEnviado] = useState<boolean>(false);

  const { realizarUpload, loading: loadingRealizarUpload } =
    useUploadPropostaAntecipado();

  const confirmUpload = async () => {
    if (
      checkIfAllItemsAreTrue([
        !!dadosContrato,
        !arquivoProposta.get().value.length,
      ])
    ) {
      setAnexoEnviado(false);
      setShowMessageRedirect(!showMessageRedirect);
      setShowErrorModal(false);
    } else {
      setShowErrorModal(true);
    }

    if (
      checkIfAllItemsAreTrue([
        !!dadosContrato,
        !!arquivoProposta.get().value.length,
      ])
    ) {
      const result = await realizarUpload(
        dadosContrato,
        arquivoProposta.get().value,
      );

      if (result?.dados?.sucesso === true) {
        setAnexoEnviado(true);
        setShowMessageRedirect(!showMessageRedirect);
        arquivoProposta.set({
          value: {} as FileList,
          isValid: true,
          errorMsg: '',
        });
        setShowErrorModal(false);
      } else {
        setShowErrorModal(true);
      }
    }
  };

  return (
    <>
      <Grid>
        <Grid.Item xs={1 / 3}>
          <S.InputLabel>Upload de proposta</S.InputLabel>
          <DescriptionFormatsFilesAllowed fileSize="3" />
          <InputFile
            link={arquivoProposta}
            validationRules={[
              filesFormatsAllowed(),
              maxFileSizeAllowed(
                () => 'Tamanho máximo do arquivo excedido.',
                3,
              ),
            ]}
          />
        </Grid.Item>
      </Grid>
      <ModalStatusUploadProposta
        showErrorModal={showErrorModal}
        handleSubmit={() => {
          confirmUpload();
          executeAction();
        }}
        showMessageRedirect={showMessageRedirect}
        loadingRealizarUpload={
          (loadingRealizarUpload ?? false) || loadingSolicitarDps
        }
        dpsEnviada={dpsEnviada}
        anexoEnviado={anexoEnviado}
        listaEnvioDps={listaEnvioDps}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

export default UploadProposta;
