import { useRef } from 'react';
import { Display, Tag } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { UploadButton, InputFileContainer } from './styles';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import For from 'main/components/For/For';

const arrayVazio: FileList | Array<null> = [];

export const InputFileFormik: React.FC<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
> = ({ ...props }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const files = tryGetValueOrDefault(
    [fileInputRef?.current?.files as FileList | Array<null>],
    arrayVazio,
  );
  return (
    <InputFileContainer>
      <UploadButton>
        <input {...props} ref={fileInputRef} type="file" />
        Selecione o arquivo
      </UploadButton>
      <RenderConditional condition={!!files}>
        <Display justify="center">
          <For each={Array.from(files as FileList)}>
            {(item: File | null, index: number) => (
              <Tag
                variant="primary"
                key={tryGetValueOrDefault([item?.name], String(index))}
                value={tryGetValueOrDefault([item?.name], '')}
              />
            )}
          </For>
        </Display>
      </RenderConditional>
    </InputFileContainer>
  );
};
