import React from 'react';
import { useLocation } from 'react-router';
import { FormikProps, useFormik } from 'formik';
import useFieldLink from 'main/hooks/useFieldLink';
import { useToast } from 'main/hooks/useToast';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import {
  converterBase64,
  downloadBase64File,
} from 'main/utils/converterBase64';
import { validate } from 'main/features/Validation/utils/validateRules';
import * as API_ASC from 'registroOcorrenciaASC/hooks';
import * as API from 'registroOcorrenciaASC/features/consultarOcorrencia/hooks';
import * as CONSTS from 'registroOcorrenciaASC/features/consultarOcorrencia/constants/constants';
import * as UTILS from 'registroOcorrenciaASC/features/consultarOcorrencia/utils/ConsultarOcorrenciaUtils';
import * as CONSULTA_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/consultarOcorrencia/types/ConsultarOcorrencia';
import * as RESPONSE_TYPES from 'registroOcorrenciaASC/features/consultarOcorrencia/types/ConsultarOcorrenciaResponse';
import * as ENUM_TYPES from 'registroOcorrenciaASC/types/enum';

export const useDetalharOcorrencia = () => {
  const { toastSuccess, toastError } = useToast();

  const {
    state: { numeroSolicitacao, cpfCnpj, numeroContrato },
  } = useLocation<{
    numeroSolicitacao: string;
    cpfCnpj: string;
    numeroContrato: string;
  }>();

  const {
    dadosDetalhesOcorrencia,
    loadingDadosDetalhesOcorrencia,
    obterDetalhesOcorrencia,
  } = API.useDetalharDadosOcorrencia({
    numeroSolicitacao: tryGetValueOrDefault([numeroSolicitacao], ''),
    cpfCnpj,
  });

  const { loadingDadosAnexos, buscarAnexos } = API.useBuscarAnexos({
    cpfCnpj: tryGetValueOrDefault(
      [dadosDetalhesOcorrencia?.entidade?.numeroDocumento],
      '',
    ),
  });

  const { loadingDadosComentario, criarComentario } = API.useCriarComentario();

  const { enviarAnexo, loadingDadosEnvioAnexo } =
    API_ASC.useEnviarAnexoRegistroOcorrencia();

  const {
    consultarDocumentoAssinadoFop223,
    loadingConsultaDocumentoAssinadoFop223,
  } = API.useConsultaDocumentoAssinadoFop223();

  const [arquivoOcorrencia, validateArquivoOcorrencia] = useFieldLink<FileList>(
    {} as FileList,
  );

  const formik: FormikProps<CONSULTA_OCORRENCIA_TYPES.IFormikValuesDetalhesOcorrencia> =
    useFormik<CONSULTA_OCORRENCIA_TYPES.IFormikValuesDetalhesOcorrencia>({
      enableReinitialize: true,
      initialValues: CONSTS.INITIAL_FORMIK_STATE_DETALHES_OCORRENCIA,
      validationSchema: UTILS.FormikDetalhesOcorrenciaSchema,
      validateOnMount: true,
      onSubmit: () => undefined,
    });

  const handleDataTextarea = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    formik.setValues({
      ...formik.values,
      conteudoTextarea: e.target.value,
      quantidadeCaracteresTextarea:
        ENUM_TYPES.DefaultValue
          .QTD_MAX_CARACTERES_TEXTAREA_REGISTRAR_COMPLEMENTO -
        e.target.value.length,
    });
  };

  const baixarAnexo = async (idSalesforce: string): Promise<void> => {
    if (loadingDadosAnexos) return;

    const anexo = await buscarAnexos({ salesforceId: idSalesforce });

    if (anexo?.entidade) {
      const blob = converterBase64(anexo.entidade?.arquivo);

      downloadBase64File({
        blob,
        fileName: anexo.entidade?.nome,
      });
    }
  };

  const limparComplemento = (
    novoComentario: RESPONSE_TYPES.ICriarComentarioResponse | undefined,
    sucessoNovoAnexo: boolean,
  ): void => {
    if (checkIfSomeItemsAreTrue([!!novoComentario?.status, sucessoNovoAnexo])) {
      obterDetalhesOcorrencia();

      formik.resetForm();

      arquivoOcorrencia.set({
        value: {} as FileList,
        isValid: true,
      });

      toastSuccess(
        CONSTS.TEXTOS_ADICIONAR_COMPLEMENTO.MSG_COMPLEMENTO_ADICIONADO,
      );
    }
  };

  const adicionarComentarioAoComplemento = async (): Promise<
    RESPONSE_TYPES.ICriarComentarioResponse | undefined
  > => {
    const novoComentario = await criarComentario({
      cpfCnpj: tryGetValueOrDefault(
        [dadosDetalhesOcorrencia?.entidade?.numeroDocumento],
        '',
      ),
      classificacao: CONSTS.DEFAULT_VALUE.CLASSIFICACAO,
      comentario: formik.values.conteudoTextarea,
      canal: CONSTS.DEFAULT_VALUE.DESCRICAO_CANAL,
      idSolicitacao: tryGetValueOrDefault(
        [dadosDetalhesOcorrencia?.entidade?.idSolicitacao],
        '',
      ),
    });

    return novoComentario?.entidade;
  };

  const adicionarAnexoAoComplemento = async (
    novoComentario: RESPONSE_TYPES.ICriarComentarioResponse | undefined,
  ): Promise<boolean | undefined> => {
    if (
      checkIfAllItemsAreTrue([
        !!arquivoOcorrencia.get().value.length,
        !!dadosDetalhesOcorrencia?.entidade?.idSolicitacao,
        !!novoComentario,
      ])
    ) {
      const promessasEnvioAnexos = Array.from(
        arquivoOcorrencia.get().value,
      ).map(async arquivo => {
        const formData = new FormData();
        formData.append('Arquivo', arquivo);
        formData.append(
          'CodigoSolicitacao',
          tryGetValueOrDefault(
            [dadosDetalhesOcorrencia?.entidade?.idSolicitacao],
            '',
          ),
        );
        formData.append('DescricaoAnexo', '');

        return enviarAnexo(formData);
      });

      const novoAnexo = await Promise.all(promessasEnvioAnexos);

      return novoAnexo.every((anexo: any) => !!anexo?.dados?.sucesso);
    }

    return undefined;
  };

  const adicionarComplemento = async (): Promise<void> => {
    if (
      checkIfAllItemsAreTrue([
        !!arquivoOcorrencia.get().value.length,
        !validate([validateArquivoOcorrencia]),
      ])
    ) {
      return;
    }

    const novoComentario = await adicionarComentarioAoComplemento();

    const sucessoNovoAnexo = await adicionarAnexoAoComplemento(novoComentario);

    limparComplemento(novoComentario, !!sucessoNovoAnexo);
  };

  const exibirBotaoComplementar = (): boolean => {
    return CONSTS.STATUS_SOLICITACAO_LEITURA.includes(
      tryGetValueOrDefault(
        [dadosDetalhesOcorrencia?.entidade?.statusSolicitacao],
        '',
      ),
    );
  };

  const exibirCardStatusConclusao = (): boolean => {
    return CONSTS.STATUS_SOLICITACAO_CONCLUSAO.includes(
      tryGetValueOrDefault(
        [dadosDetalhesOcorrencia?.entidade?.statusSolicitacao],
        '',
      ),
    );
  };

  const habilitarBtnAddComplemento: boolean = checkIfSomeItemsAreTrue([
    loadingDadosEnvioAnexo,
    loadingDadosComentario,
  ]);

  const reconsultarFop223 = async (): Promise<void> => {
    const dadosFop223 = await consultarDocumentoAssinadoFop223({
      numeroContrato,
      idSolicitacao: dadosDetalhesOcorrencia?.entidade?.idSolicitacao,
    });
    const mensagem = dadosFop223?.mensagens?.[0]?.descricao;

    if (dadosFop223?.sucessoBFF) {
      toastSuccess(mensagem);
      obterDetalhesOcorrencia();
    } else {
      toastError(mensagem);
    }
  };

  const isReconsultarFop223 = checkIfAllItemsAreTrue([
    !!numeroContrato,
    !!dadosDetalhesOcorrencia?.entidade?.reconsultarFop,
  ]);

  return {
    loadingDadosAnexos,
    loadingDadosDetalhesOcorrencia,
    dadosDetalhesOcorrencia,
    baixarAnexo,
    formik,
    arquivoOcorrencia,
    handleDataTextarea,
    adicionarComplemento,
    habilitarBtnAddComplemento,
    exibirBotaoComplementar,
    exibirCardStatusConclusao,
    isReconsultarFop223,
    reconsultarFop223,
    loadingConsultaDocumentoAssinadoFop223,
  };
};
