import * as Yup from 'yup';
import masks from 'main/utils/masks';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import * as FUNC_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblFuncParams';
import * as VGBL_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgbl';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';
import * as CONSTS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/constants/constants';

export const validarCoberturasCuidadoGarantido = ({
  coberturasCuidadoGarantido,
  checkboxCoberturaIdeal,
  dadosOfertasVgblConjugado,
  dadosOrigem,
}: FUNC_TYPES.ValidarCoberturasCuidadoGarantido): boolean => {
  const permiteInclusaoPensao =
    dadosOrigem?.incluirPensao ===
    ENUM_TYPES.DefaultValue.NAO_PERMITIR_INCLUSAO_NOVA_COBERTURA;

  const permiteInclusaoPeculio =
    dadosOrigem?.incluirPeculio ===
    ENUM_TYPES.DefaultValue.NAO_PERMITIR_INCLUSAO_NOVA_COBERTURA;

  const valorContribuicaoPensao =
    coberturasCuidadoGarantido.pensao.valorContribuicao;

  const valorContribuicaoPeculio =
    coberturasCuidadoGarantido.peculio.valorContribuicao;

  const opcaoPensaoSelecionada = checkIfSomeItemsAreTrue([
    checkboxCoberturaIdeal.pensao,
    permiteInclusaoPensao,
  ]);

  const opcaoPeculioSelecionado = checkIfSomeItemsAreTrue([
    checkboxCoberturaIdeal.peculio,
    permiteInclusaoPeculio,
  ]);

  const validacaoCamposPensaoObrigatorios = checkIfAllItemsAreTrue([
    opcaoPensaoSelecionada,
    valorContribuicaoPensao >=
      Number(dadosOfertasVgblConjugado?.valorMinimoPensao),
    valorContribuicaoPensao <=
      Number(dadosOfertasVgblConjugado?.valorMaximoPensao),
    !!coberturasCuidadoGarantido.pensao.prazo,
  ]);

  const validacaoCamposPeculioObrigatorios = checkIfAllItemsAreTrue([
    opcaoPeculioSelecionado,
    valorContribuicaoPeculio >=
      Number(dadosOfertasVgblConjugado?.valorMinimoPeculio),
    valorContribuicaoPeculio <=
      Number(dadosOfertasVgblConjugado?.valorMaximoPeculio),
  ]);

  if (
    checkIfAllItemsAreTrue([opcaoPensaoSelecionada, opcaoPeculioSelecionado])
  ) {
    return checkIfAllItemsAreTrue([
      validacaoCamposPensaoObrigatorios,
      validacaoCamposPeculioObrigatorios,
    ]);
  }

  return checkIfSomeItemsAreTrue([
    validacaoCamposPensaoObrigatorios,
    validacaoCamposPeculioObrigatorios,
  ]);
};

export const validarExibicaoCardsCobertura = ({
  dadosOrigem,
  checkboxCoberturaIdeal,
}: FUNC_TYPES.ValidarExibicaoCardsCobertura) => {
  const permiteInclusaoPensao =
    dadosOrigem?.incluirPensao ===
    ENUM_TYPES.DefaultValue.NAO_PERMITIR_INCLUSAO_NOVA_COBERTURA;

  const permiteInclusaoPeculio =
    dadosOrigem?.incluirPeculio ===
    ENUM_TYPES.DefaultValue.NAO_PERMITIR_INCLUSAO_NOVA_COBERTURA;

  const exibirTodosCardsCoberturas = checkIfSomeItemsAreTrue([
    checkIfAllItemsAreTrue([
      checkboxCoberturaIdeal.pensao,
      checkboxCoberturaIdeal.peculio,
    ]),
    checkIfAllItemsAreTrue([permiteInclusaoPeculio, permiteInclusaoPensao]),
    checkIfAllItemsAreTrue([
      permiteInclusaoPeculio,
      checkboxCoberturaIdeal.pensao,
    ]),
    checkIfAllItemsAreTrue([
      checkboxCoberturaIdeal.peculio,
      permiteInclusaoPensao,
    ]),
  ]);

  const exibirCardIndividualPensao = checkIfAllItemsAreTrue([
    checkIfSomeItemsAreTrue([
      checkIfAllItemsAreTrue([
        checkboxCoberturaIdeal.pensao,
        !checkboxCoberturaIdeal.peculio,
      ]),
      permiteInclusaoPensao,
    ]),
    !exibirTodosCardsCoberturas,
  ]);

  const exibirCardIndividualPeculio = checkIfAllItemsAreTrue([
    checkIfSomeItemsAreTrue([
      checkIfAllItemsAreTrue([
        checkboxCoberturaIdeal.peculio,
        !checkboxCoberturaIdeal.pensao,
      ]),
      permiteInclusaoPeculio,
    ]),
    !exibirTodosCardsCoberturas,
  ]);

  return {
    permiteInclusaoPensao,
    permiteInclusaoPeculio,
    exibirCardIndividualPensao,
    exibirCardIndividualPeculio,
    exibirTodosCardsCoberturas,
  };
};

export const validationFormCadastrarNovaContaSchema = Yup.object().shape({
  agencia: Yup.string().required(
    CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO,
  ),
  conta: Yup.string().required(
    CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO,
  ),
  digito: Yup.string().required(
    CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO,
  ),
});

const createValidableState = (isValid: boolean, errorMsg: string) => ({
  isValid,
  errorMsg: isValid ? '' : errorMsg,
});

export const validateIntervalValue: VGBL_TYPES.ValidationFunctionBuilder<
  string,
  VGBL_TYPES.ValidaInputIntervalValue
> =
  (
    errorMessage = validacaoInputMinValue =>
      `O valor deve ser maior ou igual a ${validacaoInputMinValue?.min} e menor ou igual a ${validacaoInputMinValue?.max}`,
    valoresValidacao = {
      min: 0,
      max: 100,
    },
  ) =>
  value => {
    const valor = parseFloat(masks.currencyInput.unmask(value)) / 100;

    return createValidableState(
      checkIfAllItemsAreTrue([
        valor >= valoresValidacao.min,
        valor <= valoresValidacao.max,
      ]),
      errorMessage(valoresValidacao),
    );
  };
