import { useContext, useEffect } from 'react';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppContext } from 'main/contexts/AppContext';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import * as PECO_TYPES from 'seguros/types/ApoliseCoberturaUsePeco';
import { ITituloCapitalizacao } from 'seguros/types/IResponseCertificadosResumo';
import { useCertificadoSeguroResumo } from './useCertificadoSeguroResumo';

export const useApoliceDadosSorteiosVida =
  (): PECO_TYPES.IUsePecoTabelaApolice<ITituloCapitalizacao> => {
    const { clienteVida } = useContext(AppContext);
    const {
      certificadoSeguros: { fetchData, response, loading },
    } = useCertificadoSeguroResumo({
      numeroContrato: tryGetValueOrDefault([clienteVida?.numCertificado], '0'),
      codigoProduto: tryGetValueOrDefault([clienteVida?.codigoProduto], '0'),
    });
    useEffect(() => {
      fetchData();
    }, []);
    const entidade = response?.entidade;

    const titulosCapitalizacoes = tryGetValueOrDefault(
      [entidade?.individual?.titulosCapitalizacoes],
      [],
    );

    return {
      dados: titulosCapitalizacoes,
      loading,
    };
  };
