import { Button, Display, Text } from '@cvp/design-system/react';
import Input from 'main/components/form/Input';
import { validate } from 'main/features/Validation/utils/validateRules';
import { required } from 'main/features/Validation/validations';
import { useCountDown } from 'main/hooks/useCountDown';
import useFieldLink from 'main/hooks/useFieldLink';
import masks from 'main/utils/masks';
import React, { useContext } from 'react';
import ReversaoCancelamentoContext from 'contratosPrestamista/features/cancelamento/contexts/ReversaoCancelamentoContext';
import { useReversaoCancelamento } from 'contratosPrestamista/features/cancelamento/hooks/useReversaoCancelamento';
import { EModalWorkflowStep } from 'contratosPrestamista/features/cancelamento/types/IModalWorkflow';
import { IFormValidateCodeProps } from 'contratosPrestamista/features/cancelamento/types/IFormValidateCodeProps';

const FormValidateCode: React.FC<IFormValidateCodeProps> = ({
  idSolicitacaoToken,
  numContrato,
  handleIdSolicitacaoToken,
  handleWorkflow,
}) => {
  const { minutesRemaining, secondsRemaining, timerStatus, restartTimer } =
    useCountDown(300);
  const [inputCodigo, validateInputCodigo] = useFieldLink('');
  const { confirmarReversaoCancelamento, enviarCodigoConfirmacao, loading } =
    useReversaoCancelamento();
  const { formSendCode } = useContext(ReversaoCancelamentoContext);

  const handlerValidacao = async () => {
    if (validate([validateInputCodigo])) {
      const result = await confirmarReversaoCancelamento({
        idSolicitacaoToken,
        numeroProposta: numContrato,
        token: inputCodigo.get().value,
      });

      handleWorkflow({
        step: EModalWorkflowStep.FEEDBACK,
        mensagem: result?.mensagens?.at(0)?.descricao,
      });
    }
  };

  const handleResentToken = async () => {
    const result = await enviarCodigoConfirmacao({
      numeroContrato: numContrato,
      enderecoEmail: '',
      tipoEnvio: formSendCode.tipoEnvio,
      numeroCelular: formSendCode.numeroCelular,
    });
    if (result?.dados.sucesso) {
      handleIdSolicitacaoToken(result.dados.entidade?.idSolicitacaoToken ?? '');
      restartTimer(true);
    }
  };

  const renderTextTimeRemaining = () =>
    timerStatus === 'RUNNING'
      ? `Reenviar em ${minutesRemaining}:${secondsRemaining}`
      : 'Renviar';

  return (
    <div data-testid="modalValidacaoCodigo">
      <Text variant="headline-06" margin>
        Código enviado!
      </Text>

      <Text variant="body-medium1" margin>
        Informe o código enviado ao cliente:
      </Text>
      <br />

      {loading ? (
        <Button variant="text" loading />
      ) : (
        <Display justify="center">
          <Input
            link={inputCodigo}
            placeholder="Código"
            validationRules={[required()]}
            inputMask={masks.tokenOtp}
            data-testid="inputCodigoRecebido"
          />
          <Display justify="center" style={{ marginTop: '2rem ' }}>
            <Button
              type="click"
              variant="primary"
              onClick={handlerValidacao}
              data-testid="buttonValidarCodigo"
            >
              Confirmar
            </Button>
            <Button
              variant="secondary"
              onClick={handleResentToken}
              data-testid="cancelar"
              disabled={timerStatus === 'RUNNING'}
            >
              {renderTextTimeRemaining()}
            </Button>
          </Display>
        </Display>
      )}
    </div>
  );
};

export default FormValidateCode;
