import React from 'react';
import { Text } from '@cvp/design-system/react';

const TextoInformativo: React.FC = () => {
  return (
    <>
      <Text variant="body02-sm" color="text" margin>
        Aqui você pode ativar ou suspender contribuições de reserva ou cancelar
        Cuidado Extra.
      </Text>
      <Text variant="body02-sm" color="text" margin>
        As contribuições são compostas por:
      </Text>
      <Text variant="body02-sm">
        <strong>Reserva: </strong> é a parte acumulada, que vai rendendo ao
        longo do tempo.
      </Text>
      <Text variant="body02-sm" color="text" margin>
        <strong>Cuidado Extra: </strong> é a parte destinada a cuidar do cliente
        ou sua família, no caso de seu falecimento.
      </Text>
    </>
  );
};

export default TextoInformativo;
