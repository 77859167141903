import styled from 'styled-components/macro';
import Input from 'main/components/form/Input';

export const InputDiaVencimento = styled(Input)({
  width: 135,

  Input: {
    height: 40,
  },
});
