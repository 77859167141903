import React from 'react';
import { Text } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';
import * as S from 'previdencia/features/Aporte/styles';

const MensagemExcecaoAporte: React.FC<
  APORTE_TYPES.IMensagemExcecaoAporteProps
> = ({ icon, mensagem }): React.ReactElement => {
  return (
    <S.ContainerExcecaoAporte>
      <Icon name={icon} data-testid="excecao-icon" />

      <Text variant="body02-md" color="error" data-testid="message-excecao">
        {mensagem}
      </Text>
    </S.ContainerExcecaoAporte>
  );
};

export default MensagemExcecaoAporte;
