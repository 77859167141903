import React from 'react';
import { Text, Card, Display, Grid } from '@cvp/design-system/react';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';

const CardErrorDataAposentadoria: React.FC = () => {
  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Grid>
            <Grid.Item xs={1}>
              <Text variant="headline-06" color="primary" margin>
                Alteração do Prazo de Diferimento
              </Text>
              <Text variant="body02-md" color="text-light">
                Não foi possível encontrar ou retornar o dados para este
                certificado
              </Text>
            </Grid.Item>
          </Grid>
        </Card.Content>
      </Card>
    </Display>
  );
};

export default CardErrorDataAposentadoria;
