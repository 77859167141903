import React from 'react';
import { SelectItem } from 'main/components/form/Select';
import useFieldLink from 'main/hooks/useFieldLink';
import { SelectQuantidadeMesesProps } from 'previdencia/features/HistoricoSolicitacoes/types/HistoricoSolicitacoesProps';
import { filtroPeriodos } from 'previdencia/features/HistoricoSolicitacoes/factories/filtroPeriodosFactory';
import { FiltroPeriodo } from 'previdencia/features/HistoricoSolicitacoes/types/enum';
import * as S from 'previdencia/features/HistoricoSolicitacoes/components/SelectQuantidadeMeses/styles';

const SelectQuantidadeMeses: React.FC<SelectQuantidadeMesesProps> = ({
  definirPeriodoSolicitado,
}) => {
  const [periodoPreDefinidoLink] = useFieldLink(
    FiltroPeriodo.ULTIMOS_12_MESES as string,
  );

  const isSelected = (value: string): boolean => {
    if (periodoPreDefinidoLink.get().value === value) {
      definirPeriodoSolicitado(value);
      return true;
    }
    return false;
  };

  return (
    <S.SelectStyle
      placeholder="Selecione um período"
      link={periodoPreDefinidoLink}
      validationRules={[]}
    >
      {filtroPeriodos?.map(periodo => (
        <SelectItem
          key={periodo.value}
          value={periodo.value}
          text={periodo.label}
          selected={isSelected(periodo.value)}
        />
      ))}
    </S.SelectStyle>
  );
};

export default React.memo(SelectQuantidadeMeses);
