import styled, { keyframes } from 'styled-components/macro';

const effectFadeInSmoothSlideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const WrapperSlideFadeContent = styled.div`
  opacity: 0;
  animation: ${effectFadeInSmoothSlideDown} 0.5s ease-in-out forwards;

  @media print {
    opacity: 100;
    animation: none;
  }
`;
