import { useState, useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { useToast } from 'main/hooks/useToast';
import * as DetalhesHistoricoApi from 'previdencia/features/SimulacaoRenda/services/consultarDetalhesHistoricoSimulacaoRenda.api';

import { ResponseDetalhesHistoricoSimulacaoRenda } from '../types/detalhesHistoricoSimulacaoRenda';

export const useConsultarDetalhesHistoricoSimulacao = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { toastError } = useToast();
  const [response, setArrayResponse] = useState<
    ResponseDetalhesHistoricoSimulacaoRenda | undefined
  >();

  const {
    cliente: { numCertificado },
  } = useContext(AppContext);

  const ObterDetalhesSolicictacao = async (
    seqSimulacao: string,
  ): Promise<void> => {
    try {
      setLoading(true);

      const result =
        await DetalhesHistoricoApi.consultarDetalhesHistoricoSimulacaoRenda(
          numCertificado,
          seqSimulacao,
        );

      setArrayResponse(result);
    } catch (requestError: any) {
      toastError(requestError);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    response,
    ObterDetalhesSolicictacao,
  };
};
