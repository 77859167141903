import React, { useEffect, useMemo, useState } from 'react';
import { Card, Button } from '@cvp/design-system/react';
import TableFilters from 'main/components/Table/TableFilter/TableFilter';
import {
  FilterTypes,
  IFilterOption,
} from 'main/components/Table/TableFilter/IFilterOption';
import * as S from 'main/components/Table/customStyles';
import { formatarData } from 'main/utils';
import { maskCpfCnpj } from 'contratosPrestamista/utils/maskCpfCnpj';
import { Skeleton as TableSkeleton } from 'main/components/Table';
import masks from 'main/utils/masks';
import RenderConditional from 'main/components/RenderConditional';
import FormConsultaCpfContrato from 'contratosPrestamista/features/uploadproposta/components/FormConsultaCpfContrato';
import { useUploadPropostaAntecipado } from 'contratosPrestamista/features/uploadproposta/hooks/useUploadPropostaAntecipado';
import { IConsultarStatusUploadForm } from 'contratosPrestamista/features/uploadproposta/types/IConsultarStatusUploadForm';
import { IListarDocumentosIncluidosReponse } from 'contratosPrestamista/features/uploadproposta/types/IListarDocumentosIncluidosResponse';
import { IDataTableColumn } from 'react-data-table-component';
import { formatarDataUpload } from 'contratosPrestamista/utils/formatarDataUpload';

const ConsultaUpload: React.FC = () => {
  const [showList, setShowList] = useState<boolean>(false);
  const { obterListaStatusUpload, listaStatusUpload, loading } =
    useUploadPropostaAntecipado();
  const filterOptions: IFilterOption[] = [
    { key: 'dtaVenda', value: 'Data da Concessão do Crédito', type: 'date' },
    {
      key: 'cpfCnpj',
      value: 'CPF/CNPJ',
      type: 'text',
      unmask: masks.cpf.unmask,
    },
    {
      key: 'codContrato',
      value: 'Número de Contrato',
      type: 'text',
      unmask: masks.numberOnly.unmask,
    },
    {
      key: 'matricula',
      value: 'Matrícula',
      type: 'text',
    },
    {
      key: 'status',
      value: 'Status Upload',
      type: FilterTypes.TAG,
      checkboxConfig: {
        options: [
          { id: 0, description: 'UPLOAD EM ANALISE' },
          { id: 1, description: 'UPLOAD CONCLUIDO' },
        ],
      },
    },
  ];
  const colunas = useMemo(
    () => [
      {
        name: 'CPF/CNPJ',
        selector: 'cpfCnpj',
        sortable: true,
        cell: ({ cpfCnpj }: IListarDocumentosIncluidosReponse) => (
          <span>{maskCpfCnpj(cpfCnpj)}</span>
        ),
      },
      {
        name: 'Data da Concessão do Crédito',
        sortable: true,
        selector: 'dtaVenda',
        cell: ({ dtaVenda }: IListarDocumentosIncluidosReponse) => (
          <span>{formatarData(dtaVenda)}</span>
        ),
      },
      {
        name: 'Matrícula',
        selector: 'matricula',
        sortable: true,
      },
      {
        name: 'Sistema de Venda',
        selector: 'sistemaOrigem',
        sortable: true,
      },
      {
        name: 'Número de Contrato',
        selector: 'codContrato',
        sortable: true,
        width: '210px',
      },
      {
        name: 'Status Upload',
        selector: 'status',
        sortable: true,
        width: '210px',
      },
      {
        name: 'Data Upload',
        selector: 'dataUpload',
        cell: ({ dataUpload }: IListarDocumentosIncluidosReponse) => (
          <span>{formatarDataUpload(dataUpload)}</span>
        ),
        sortable: true,
        width: '210px',
      },
    ],
    [],
  );

  useEffect(() => {
    if (listaStatusUpload && listaStatusUpload.length > 0) setShowList(true);
  }, [listaStatusUpload]);

  const handleConsultarStatusUplaod = async (
    data: IConsultarStatusUploadForm,
  ) => {
    await obterListaStatusUpload(data);
  };

  return (
    <>
      <Card style={{ minHeight: 'auto' }}>
        <Card.Content data-testid="tabelaListaPropostas">
          <RenderConditional condition={!showList && !loading}>
            <FormConsultaCpfContrato
              handleConsultar={handleConsultarStatusUplaod}
            />
          </RenderConditional>

          {loading ? (
            <TableSkeleton colunas={colunas} />
          ) : (
            <RenderConditional condition={showList}>
              <TableFilters
                dataToFilter={listaStatusUpload}
                filterOptions={filterOptions}
                filterTextPartial
                customButton={
                  <Button onClick={() => setShowList(!showList)}>
                    Nova Consulta
                  </Button>
                }
              >
                {dataFiltered => (
                  <S.Table
                    responsive
                    noHeader
                    data={dataFiltered}
                    columns={colunas as IDataTableColumn<unknown>[]}
                    noDataComponent="Não há dados para exibir."
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={{
                      rowsPerPageText: 'Items por página',
                      rangeSeparatorText: 'de',
                    }}
                  />
                )}
              </TableFilters>
            </RenderConditional>
          )}
        </Card.Content>
      </Card>
    </>
  );
};

export default ConsultaUpload;
