import { getFieldErrorMessage } from 'main/features/prospeccao/features/questionario/utils';
import masks from 'main/utils/masks';
import * as PROPS_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblComponentProps';

export const defaultFormFieldPropsFactory: PROPS_TYPES.DefaultFormFieldProps = (
  meta,
  label,
  setFieldValue,
  field,
  placeholder,
  testComponent,
  valueInput,
) => {
  return {
    error: meta.touched && meta.error,
    errorMessage: getFieldErrorMessage(meta.touched, meta.error),
    label,
    onChange: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(field, value === 'cpf' ? masks.cpf.unmask(value) : value);
    },
    placeholder,
    'data-testid': testComponent,
    value: valueInput,
  };
};
