import { ISinistroTimelineFactory } from 'sinistro/features/statusSinistro/types/IStatusSinistro';
import { LinhaTempoSinistroDados } from 'sinistro/features/statusSinistro/types/StatusSinistroComponentProps';
import { TipoProduto } from 'sinistro/features/statusSinistro/types/enum';
import { timelineVidaFactory } from 'sinistro/features/statusSinistro/factories/timelineVidaFactory';
import { timelinePrevidenciaFactory } from 'sinistro/features/statusSinistro/factories/timelinePrevidenciaFactory';
import { timelinePrestamistaFactory } from 'sinistro/features/statusSinistro/factories/timelinePrestamistaFactory';

export const sinistroTimelineFactory = ({
  produtoSelecionado,
  sinistroPrevidencia,
  sinistroPrestamista,
  sinistroVida,
}: ISinistroTimelineFactory): Array<LinhaTempoSinistroDados> => {
  switch (produtoSelecionado?.tipoProduto) {
    case TipoProduto.VIDA:
      return timelineVidaFactory(sinistroVida, produtoSelecionado);
    case TipoProduto.PREVIDENCIA:
      return timelinePrevidenciaFactory(
        sinistroPrevidencia,
        produtoSelecionado,
      );
    case TipoProduto.PRESTAMISTA:
      return timelinePrestamistaFactory(
        sinistroPrestamista,
        produtoSelecionado,
      );
    default:
      return [];
  }
};
