import styled from 'styled-components/macro';
import { Modal, Button } from '@cvp/design-system/react';

import DataTable from 'main/components/Table';
import RiskBar from 'previdencia/components/RiskBar';

export const ContainerWarning = styled.div(({ theme }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.color.line.secondary,
  padding: 20,
  borderRadius: 8,
  marginTop: 20,

  '& *': {
    color: theme.color.brandSecondary.dark,
  },

  '& div:first-child': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,

    p: {
      fontWeight: 'bold',
      marginBottom: 0,
    },
  },

  '& p': {
    marginBottom: 5,
  },

  svg: {
    width: 25,
    height: 25,
    marginRight: 5,
  },
}));

export const ContainerNav = styled.div(() => ({
  marginTop: 20,

  button: {
    marginRight: 20,
  },
}));

export const Table = styled(DataTable)`
  .rdt_tablecol_sortable div {
    text-align: 'center';
  }
`;

export const ContainerTituloAliquota = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  background: theme.color.brandPrimary.gradient,
}));
export const ContainerSelecaoAliquota = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  background: theme.color.brandPrimary.gradient,

  '& label': {
    marginRight: '5px',
  },

  'button[role=radio]': {
    borderColor: 'white',

    '& span': {
      backgroundColor: 'white',
    },
  },
}));

export const ContainerCardAliquota = styled.div`
  #valoresDetalhados {
    margin: 20px;
  }
`;

export const ModalValoresDetalhadosAliquota = styled(Modal)`
  max-width: 90%;
  @media print {
    max-width: 100%;
  }
`;

export const ContainerAviso = styled.div(({ theme }) => ({
  padding: '10px 0',
  background: theme.color.brandPrimary.gradient,
}));

export const ContainerSelecaoNovaConta = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  margin: '20px 0 40px 0',

  '& label': {
    marginRight: '10px',
  },
}));

export const ButtonObsCertificadoRedirect = styled(Button)(({ theme }) => ({
  padding: 0,
  display: 'inline-flex',
  color: theme.color.brandSecondary.dark,
  fontWeight: 'bold',
}));

export const ContainerInputHelper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  h6: {
    color: theme.color.neutral['03'],
    fontSize: '11px',
  },

  'h6[color=error]': {
    color: theme.color.feedback.error,
  },
}));

export const ContainerTextoSucessoResgate = styled.div`
  margin: 30px 0 15px 0;
`;

export const CustomRiskBarStyle = styled(RiskBar)(() => ({
  minHeight: '60px',
  minWidth: '100px',
}));

export const ContainerBotaoDetalhesAliquota = styled.div`
  display: flex;
  justify-content: flex-end;
`;
