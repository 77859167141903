import React, { useContext, useEffect, useState } from 'react';
import { Text, Button, Grid, Divider, Display } from '@cvp/design-system/react';
import Table from 'main/components/Table';
import { formatarCpf } from 'main/utils/cpf_cnpj';
import { COLUNAS_INFORMES_BENEFICIARIOS } from 'previdencia/constants/constants';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { obterLinkAtendimentoOnline } from 'previdencia/config/endpoints';
import EmailSenderModal from 'main/components/EmailSenderModal';
import { useObterInformeIR } from 'previdencia/hooks/useObterInformeIR';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { AppContext } from 'main/contexts/AppContext';
import { DefaultParamRequest } from 'previdencia/types/enum';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import useObterBeneficiariosPrevidencia from '../../hooks/useObterBeneficiariosPrevidencia';
import { ultimosCincoAnos } from '../../utils/InformesBeneficiarios';
import * as S from './styles';

const ConsultaBeneficiariosPorAno: React.FC = () => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const [anoConsulta, setAnoConsulta] = useState('');
  const [cpfBeneficiario, setCpfBeneficiario] = useState('');
  const cincoAnos = ultimosCincoAnos();
  const { goDadosPlano } = usePrevNavigation();
  const {
    fetchData: consultarBeneficiariosPorAnoPrevidencia,
    response,
    loading: loadingListaBeneficiarios,
  } = useObterBeneficiariosPrevidencia(anoConsulta);
  const listaBeneficiarios = response?.entidade;
  const { loading, obterIR } = useObterInformeIR(cpfBeneficiario, anoConsulta);

  const montarObjetoEmail = () => {
    return {
      tipoEmail: tipoEmailConstants.CONSULTA_IR,
      parametrosEnvio: {
        cpfCnpj,
        cpfCnpjBeneficiario: cpfBeneficiario,
        numeroCertificado: numCertificado,
        anoBase: anoConsulta,
        tipoPessoa: DefaultParamRequest.PESSOA_FISICA,
      },
    };
  };

  useEffect(() => {
    if (anoConsulta) {
      consultarBeneficiariosPorAnoPrevidencia();
    }
  }, [anoConsulta]);

  useEffect(() => {
    if (cpfBeneficiario) {
      obterIR();
    }
  }, [cpfBeneficiario]);

  return (
    <>
      <Grid.Item xs={1}>
        <Text variant="body02-sm" color="text">
          Escolha o ano que deseja ver:
        </Text>
      </Grid.Item>
      <Grid.Item xs={1} sm={1 / 3} md={1}>
        {cincoAnos.map(e => {
          return (
            <S.ButtonAnoConsulta
              key={e}
              variant={e === anoConsulta ? 'secondary' : 'outlined'}
              disabled={checkIfAllItemsAreTrue([
                loadingListaBeneficiarios,
                e !== anoConsulta,
              ])}
              loading={checkIfAllItemsAreTrue([
                loadingListaBeneficiarios,
                e === anoConsulta,
              ])}
              onClick={() => setAnoConsulta(e)}
            >
              {e}
            </S.ButtonAnoConsulta>
          );
        })}
        <Divider />
      </Grid.Item>
      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          listaBeneficiarios?.length === 0,
          anoConsulta !== '',
        ])}
      >
        <Text variant="body02-sm" margin>
          Não existem beneficiários vinculados a este certificado. Para mais
          informações,{' '}
          <a
            href={obterLinkAtendimentoOnline('573Do0000000Aj0')}
            target="blank"
          >
            fale aqui
          </a>{' '}
          com um dos nossos especialistas online, disponível de segunda a
          sexta-feira das 9h às 21h.
        </Text>
      </RenderConditional>

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          !!listaBeneficiarios,
          tryGetValueOrDefault([listaBeneficiarios], [])?.length > 0,
        ])}
      >
        <Grid.Item xs={1}>
          <Text variant="body02-sm">
            O informe de rendimentos por beneficiário demonstra valores
            relativos ao CPF. Identifique o beneficiário para baixar o
            documento:
          </Text>
        </Grid.Item>
      </RenderConditional>

      <Grid.Item xs={1}>
        <Table
          data={
            listaBeneficiarios?.map(({ nomePessoa, cpf }) => ({
              nomeBeneficiario: nomePessoa,
              cpf: formatarCpf(cpf),
              informe: (
                <Display>
                  <Button
                    key={cpf}
                    variant="primary"
                    disabled={checkIfAllItemsAreTrue([
                      loading,
                      cpfBeneficiario !== cpf,
                    ])}
                    loading={checkIfAllItemsAreTrue([
                      loading,
                      cpfBeneficiario === cpf,
                    ])}
                    onClick={() => setCpfBeneficiario(cpf)}
                  >
                    Baixar
                  </Button>
                  <EmailSenderModal
                    objetoEmail={montarObjetoEmail()}
                    disabled={checkIfAllItemsAreTrue([
                      loading,
                      cpfBeneficiario !== cpf,
                    ])}
                  />
                </Display>
              ),
            })) ?? []
          }
          columns={COLUNAS_INFORMES_BENEFICIARIOS}
          noHeader
          responsive
          noDataComponent="Não há dados para o ano selecionado."
          pagination
          paginationPerPage={10}
          paginationComponentOptions={{
            rowsPerPageText: 'Items por página',
            rangeSeparatorText: 'de',
          }}
        />
      </Grid.Item>
      <Grid.Item xs={1}>
        <Button variant="outlined" onClick={() => goDadosPlano()}>
          Voltar
        </Button>
      </Grid.Item>
    </>
  );
};

export default ConsultaBeneficiariosPorAno;
