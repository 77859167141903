import React from 'react';
import { PE } from 'main/components/PEComponents';
import Select, { SelectItem } from 'main/components/form/Select';
import { validate } from 'main/features/Validation/utils/validateRules';
import { required, validDateRange } from 'main/features/Validation/validations';
import LinkedValue from 'main/features/Validation/types/LinkedValue';
import { CalendarData } from 'main/components/form/Calendar/Calendar.type';
import ValidateResult from 'main/features/Validation/types/ValidateResult';
import { useToast } from 'main/hooks/useToast';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import * as S from './styles';

type Option = {
  value: string;
  text: string;
};

type PeriodoExtratoProps = {
  options: Option[];
  periodoLink: LinkedValue<string>;
  inputDateLink: LinkedValue<CalendarData>;
  validatePeriodo: () => ValidateResult;
  validateInputDate: () => ValidateResult;
  loading: boolean;
  refetch: () => void;
  emitirDisabled?: boolean;
  children?: React.ReactNode;
};
const PeriodoExtrato: React.FC<PeriodoExtratoProps> = ({
  options,
  periodoLink,
  inputDateLink,
  validatePeriodo,
  validateInputDate,
  loading,
  refetch,
  emitirDisabled,
  children,
}) => {
  const { goDadosPlano } = usePrevNavigation();

  function isSelected(value: string) {
    return periodoLink.get().value === value;
  }
  const { toastError } = useToast();
  const periodoCustomizado = inputDateLink.get().value;
  const dettach = false;

  return (
    <div className="hide-print">
      <S.WrapperSelect>
        <Select
          placeholder="Escolha a opção"
          link={periodoLink}
          validationRules={[required()]}
          dettach={dettach}
        >
          {options.map(item => (
            <SelectItem
              value={item.value}
              text={item.text}
              selected={isSelected(item.value)}
            />
          ))}
        </Select>
      </S.WrapperSelect>

      {isSelected('custom') && (
        <>
          <PE.Display>
            <PE.Text variant="body01-lg" color="primary">
              Defina um período:
            </PE.Text>
          </PE.Display>
          <PE.Display>
            <S.DisplayCalendar
              placeholder="Informe o periodo"
              link={inputDateLink}
              validationRules={[validDateRange()]}
              range
            />
          </PE.Display>
        </>
      )}
      <PE.Display marginTop={50}>
        <PE.Button variant="outlined" onClick={goDadosPlano}>
          Voltar
        </PE.Button>
        <PE.Button
          loading={loading}
          disabled={emitirDisabled ?? false}
          onClick={() => {
            const validations = [validatePeriodo];
            if (isSelected('custom')) {
              validations.push(validateInputDate);
            }
            if (validate(validations)) {
              if (
                periodoCustomizado.initialDate &&
                periodoCustomizado.finalDate &&
                periodoCustomizado.finalDate < periodoCustomizado.initialDate
              ) {
                toastError('A data final deve ser posterior a data inicial');
              } else {
                refetch();
              }
            }
          }}
        >
          Emitir extrato
        </PE.Button>
        {children}
      </PE.Display>
    </div>
  );
};

export default PeriodoExtrato;
