import styled from 'styled-components/macro';
import DataTable from 'react-data-table-component';
import {
  Modal,
  Text as TextDS,
  Display as DisplayDS,
} from '@cvp/design-system/react';
import Input from 'main/components/form/Input';

export const TabelaTransferenciaInterna = styled(DataTable)<{
  marginBottom?: string | number;
}>(({ theme: { color, font }, marginBottom }) => ({
  marginBottom,

  '.rdt_TableCol, .rdt_TableCell': {
    fontSize: font.size.sm,
    fontFamily: font.family.base,
  },
  '.rdt_TableCol': {
    fontWeight: font.weight.lg,
    '&:hover': {
      color: color.brandPrimary.light,
    },
  },
}));

export const ModalTransferenciaInterna = styled(Modal)<{
  showCloseButton: boolean;
  size: number;
}>(({ showCloseButton, size }) => ({
  '&&': {
    maxWidth: `${size}px`,
    '& > button': {
      display: showCloseButton ? 'inline-block' : 'none',
    },
  },
}));

export const InputTableTransferenciaInterna = styled(Input)`
  input {
    height: 40px;
    width: 90%;
    text-align: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Text = styled(TextDS)<{
  fontWeight?: string;
  textAlign?: string;
  marginBottom?: string | number;
  borderBottom?: string;
}>(({ fontWeight, textAlign, marginBottom, borderBottom }) => ({
  fontWeight,
  textAlign,
  marginBottom,
  borderBottom,
}));

export const Display = styled(DisplayDS)<{
  marginTop?: string | number;
  flexDirection?: string;
  display?: string;
}>(({ marginTop, flexDirection, display }) => ({
  marginTop,
  flexDirection,
  display,
}));
