import styled from 'styled-components/macro';
import { Text as TextDS } from '@cvp/design-system/react';

export const ContainerButtons = styled.div({
  display: 'flex',
  gap: '15px',
});

export const ContainerInfo = styled.div(({ theme: { color } }) => ({
  border: `1px solid ${color.line.primary}`,
  background: `${color.brandPrimary.medium}0d`,
  padding: 20,
  borderRadius: 8,
  marginTop: 20,
  marginBottom: 20,

  '& p:first-child': {
    marginBottom: 10,
  },
}));

export const Text = styled(TextDS)({
  marginBottom: 40,
});
