import styled from 'styled-components/macro';

export const WrapperInputPorcent = styled.div(() => ({
  width: 70,
  paddingTop: 12,
  marginBottom: 12,
  display: 'flex',
  alignItems: 'center',
}));

export const InputPorcent = styled.input(({ theme }) => ({
  width: '100%',
  height: 35,
  padding: 10,
  borderRadius: 3,
  border: `1px solid ${theme.color.neutral['02']}`,
  outline: 'none',
  marginRight: 5,

  '&:focus': {
    borderColor: theme.color.brandPrimary.light,
  },
}));

export const WrapperButtonAction = styled.div(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 20,
}));
