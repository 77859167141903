import { Display, Grid, Text, Button } from '@cvp/design-system/react';

import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { ALIQUOTA } from 'previdencia/constants/constants';
import * as REGIME_TRIBUTARIO_TYPES from 'previdencia/features/AlteracaoRegimeTributario/types/AlteracaoRegimeTributario';

const MensagemSemPermissaoAlteracao = ({
  opcaoTributacaoIrrfAtual,
}: REGIME_TRIBUTARIO_TYPES.IMensagemSemPermissaoAlteracaoProps) => {
  const { goDadosPlano } = usePrevNavigation();

  return (
    <Grid.Item xs={1}>
      <Display>
        <Text variant="body02-md" color="text" margin>
          Este certificado já optou pelo regime tributário{' '}
          <strong>
            {tryGetValueOrDefault(
              [
                getTernaryResult(
                  opcaoTributacaoIrrfAtual === ALIQUOTA.TRIBUTACAO_PROGRESSIVA,
                  ALIQUOTA.PROGRESSIVO,
                  ALIQUOTA.REGRESSIVO,
                ),
              ],
              '-',
            )}
          </strong>
          . Esta opção é irretratável.
        </Text>
      </Display>
      <Display>
        <Button variant="outlined" onClick={goDadosPlano} aria-label="Voltar">
          Voltar
        </Button>
      </Display>
    </Grid.Item>
  );
};

export default MensagemSemPermissaoAlteracao;
