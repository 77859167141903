import React from 'react';
import { Grid, Display, Text, Button } from '@cvp/design-system/react';
import useFieldLink from 'main/hooks/useFieldLink';
import masks from 'main/utils/masks';
import { required, intervalValue } from 'main/features/Validation/validations';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import * as S from './styles';

type DadosVencimentoProps = {
  diaPagamento: string | undefined;
  proximaTela: (dia: string) => void;
};

const DadosVencimento: React.FC<DadosVencimentoProps> = ({
  diaPagamento,
  proximaTela,
}) => {
  const [diaVencimentoLink, validateDiaVencimento] = useFieldLink('');
  const { goDadosPlano } = usePrevNavigation();

  return (
    <Grid>
      <Grid.Item xs={1}>
        <Display>
          <Text variant="body02-md" color="text">
            Dia de vencimento atual:
          </Text>
          <Text
            variant="body02-sm"
            color="text"
            data-testid="diaVencimentoAtual"
          >
            {diaPagamento}
          </Text>
        </Display>
        <Display>
          <Text variant="body02-md" color="text">
            Novo dia de vencimento:
          </Text>
          <S.InputDiaVencimento
            link={diaVencimentoLink}
            inputMask={masks.numberOnly}
            validationRules={[
              required(),
              intervalValue(() => '', { min: 5, max: 25 }),
            ]}
            placeholder=""
            legend="valores entre 5 e 25"
            data-testid="novoDiaVencimento"
          />
        </Display>
        <br />
        <Display>
          <Button variant="outlined" onClick={() => goDadosPlano()}>
            Voltar
          </Button>
          <Button
            data-testid="alterarDiaVencimento"
            variant="primary"
            onClick={() => {
              if (validateDiaVencimento().isValid) {
                proximaTela(diaVencimentoLink.get().value);
              }
            }}
          >
            Alterar
          </Button>
        </Display>
      </Grid.Item>
    </Grid>
  );
};

export default DadosVencimento;
