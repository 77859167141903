import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { FormikProps, useFormik } from 'formik';

import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import {
  PrevRedirectConfig,
  PREV_REDIRECTS_SCREENS,
} from 'previdencia/config/redirects';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { StatusResgate } from 'previdencia/types/StatusResgate';
import { IConsultarTiposPagamentoResponse } from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgateResponse';
import * as FACTORY from 'previdencia/features/SolicitacaoResgate/factories/solicitacaoResgateFactory';
import * as UTILS from 'previdencia/features/SolicitacaoResgate/utils/SolicitacaoResgate';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const useEfetuarResgate = () => {
  const history = useHistory();

  const { featureData } =
    usePrevidenciaContext<SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData>();

  const [quantidadeCharInput, setQuantidadeCharInput] = useState<string>(
    CONSTS.DADOS_BANCO_CONTA.QTD_CHAR_INPUT.MAXIMA,
  );

  const dadosRecuperarBancos = tryGetValueOrDefault(
    [featureData?.dadosRecuperarBancos],
    [],
  );

  const listaMotivosResgate = tryGetValueOrDefault(
    [featureData?.dadosListarMotivosResgate],
    [],
  );

  const { tipoPagamentos } = tryGetValueOrDefault(
    [featureData?.dadosConsultaTiposPagamento],
    {} as IConsultarTiposPagamentoResponse,
  );

  const contasBancariasValidasParaResgate = tipoPagamentos?.filter(
    tipoPagamento =>
      tipoPagamento.tipoCanal ===
      CONSTS.TIPOS_CANAL_PAGAMENTO.RESGATE_TRANSFERENCIA,
  );

  const formik: FormikProps<SOLICITACAO_RESGATE_TYPES.IFormikValuesEfetuarResgate> =
    useFormik<SOLICITACAO_RESGATE_TYPES.IFormikValuesEfetuarResgate>({
      enableReinitialize: true,
      initialValues: CONSTS.INITIAL_STATE_EFETUAR_RESGATE,
      validateOnMount: true,
      onSubmit: () => undefined,
    });

  const { novaConta } = formik.values;

  const listaTiposConta = FACTORY.definirTiposContaFactory(formik);

  const maxLengthContaCaixaDinamica = UTILS.obterMaxlengthContaCaixa(novaConta);

  const colunas = CONSTS.COLUNAS_INFO_BANCARIAS(formik);

  const handleMotivoSelecionado = (codigo: string): void => {
    const motivoSelecionado = listaMotivosResgate.find(motivo => {
      return motivo.codigo === codigo;
    });

    formik.setValues({
      ...formik.values,
      motivoResgate: tryGetValueOrDefault([motivoSelecionado?.codigo], ''),
    });
  };

  const colunasMotivoResgate = CONSTS.COLUNAS_MOTIVO_RESGATE({
    handleMotivoSelecionado,
    formik,
  });

  const limparFormularioNovaConta = (): void => {
    formik.setValues({
      ...formik.values,
      novaConta: {
        ...novaConta,
        agencia: '',
        conta: '',
        digitoConta: '',
      },
    });
  };

  const redirecionarParaHistoricoCancelamentoResgate = (): void => {
    const isStatusAguardandoAssinaturaASE = checkIfAllItemsAreTrue([
      !!featureData?.dadosRetornoConfirmacaoResgate?.numeroResgate,
      featureData?.dadosRetornoConfirmacaoResgate?.statusResgate ===
        StatusResgate.AGUARDANDO_ASSINATURA,
    ]);

    if (isStatusAguardandoAssinaturaASE) {
      history.push(
        PrevRedirectConfig[
          PREV_REDIRECTS_SCREENS.HISTORICO_CANCELAMENTO_RESGATE
        ],
      );
    }
  };

  const isMotivoResgatePreenchido = !!formik.values.motivoResgate;

  const isContaExistentePreenchida = !!formik.values.contaExistente.codigoBanco;

  const isNovaContaPreenchida = checkIfAllItemsAreTrue([
    !!formik.values.novaConta.agencia,
    !!formik.values.novaConta.banco.value,
    !!formik.values.novaConta.conta,
    !!formik.values.novaConta.digitoConta,
    !!formik.values.novaConta.tipoConta.codigo,
  ]);

  const isFormularioPreenchido = checkIfAllItemsAreTrue([
    isMotivoResgatePreenchido,
    checkIfSomeItemsAreTrue([
      isContaExistentePreenchida,
      isNovaContaPreenchida,
    ]),
  ]);

  useEffect(() => {
    setQuantidadeCharInput(maxLengthContaCaixaDinamica);
    limparFormularioNovaConta();
  }, [novaConta.banco.value, novaConta.tipoConta.codigo]);

  useEffect(redirecionarParaHistoricoCancelamentoResgate, []);

  return {
    contasBancariasValidasParaResgate,
    colunas,
    formik,
    listaTiposConta,
    dadosRecuperarBancos,
    quantidadeCharInput,
    novaConta,
    listaMotivosResgate,
    colunasMotivoResgate,
    isFormularioPreenchido,
  };
};

export default useEfetuarResgate;
