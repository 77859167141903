import React from 'react';
import BottomNavTab from 'main/components/Menu/BottomNav/BottomNavTab';
import BottomNavMenu from 'main/components/Menu/BottomNav/BottomNavMenu';
import { BottomNavBar } from './styles';
import { IMenu } from '../Sidebar/types';

type BottomNavProps = {
  menuMapProfile: IMenu;
  children: React.ReactNode;
};
const BottomNav: React.FunctionComponent<BottomNavProps> = ({
  menuMapProfile,
  children,
}) => {
  return (
    <BottomNavBar>
      {children}
      <BottomNavTab icon="home" label="Home" linkTo="/" />
      <BottomNavTab icon="user" label="Perfil" slideFrom="right">
        <BottomNavMenu
          items={menuMapProfile.items ?? []}
          type={menuMapProfile.type || menuMapProfile.name}
        />
      </BottomNavTab>
    </BottomNavBar>
  );
};

export default BottomNav;
