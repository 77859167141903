export const TextosInstrucoesRegerarPdf = {
  POSSIVEL_CAUSA_PROBLEMA:
    'Possível causa: alguns equipamentos de digitalização podem gerar arquivos incompatíveis que ocasionam erros no upload da proposta.',
  ACOES_SENDO_TOMADAS:
    'Estamos trabalhando para resolver o mais rápido possível.',
  SOLUCAO_ALTERNATIVA:
    'Se possível, utilize outro equipamento e tente novamente. Caso o problema persista e o arquivo esteja no formato PDF, siga os passos a seguir:',
  SOLUCAO_ALTERNATIVA_PASSO_1: '1. Abra o arquivo no seu leitor de PDF;',
  SOLUCAO_ALTERNATIVA_PASSO_2:
    '2. Acione as opções de impressão do arquivo (ou pressione CTRL+P);',
  SOLUCAO_ALTERNATIVA_PASSO_3:
    '3. Selecione como destino da impressão (impressora) a opção que salva/imprime o arquivo como PDF;',
  SOLUCAO_ALTERNATIVA_PASSO_4:
    '4. Salve o novo arquivo PDF que será gerado com um nome diferente do anterior;',
  SOLUCAO_ALTERNATIVA_PASSO_5:
    '5. Realize o upload do novo arquivo PDF gerado;',
  ORIENTACAO_CANAIS_ATENDIMENTO:
    'Se após os passos acima ainda não for possível fazer o upload da proposta, procure nossos canais de atendimento ao Economiário.',
};
