import React from 'react';
import { Text, Card, Accordion } from '@cvp/design-system/react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import RenderConditional from 'main/components/RenderConditional';
import { formatarData } from 'main/utils';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import * as CONSTS from 'registroOcorrenciaASC/features/consultarOcorrencia/constants/constants';
import * as CONSULTA_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/consultarOcorrencia/types/ConsultarOcorrencia';
import * as S from 'registroOcorrenciaASC/features/consultarOcorrencia/components/CardComplementos/styles';
import * as OCORRENCIA_STYLES from 'registroOcorrenciaASC/styles/styles';

const CardComplementos: React.FC<
  CONSULTA_OCORRENCIA_TYPES.CardComplementosProps
> = ({ loadingDadosDetalhesOcorrencia, dadosDetalhesOcorrencia }) => {
  const dadosComplemento = dadosDetalhesOcorrencia?.entidade?.complementos;

  return (
    <OCORRENCIA_STYLES.CardContainer>
      <Card.Content padding={[6, 8, 6, 8]}>
        <Accordion data-testid="accordionComplementosOcorrencia">
          <Accordion.Item title="Complementos">
            <RenderConditional condition={loadingDadosDetalhesOcorrencia}>
              <SkeletonLoading lines={4} />
            </RenderConditional>

            <RenderConditional
              condition={checkIfAllItemsAreTrue([
                !loadingDadosDetalhesOcorrencia,
                !!dadosComplemento?.length,
              ])}
            >
              {dadosComplemento?.map(complemento => (
                <S.ContainerComplement key={complemento.comentario}>
                  <Text variant="body02-sm">
                    <span>
                      {CONSTS.TEXTOS_COMPLEMENTOS.LABEL_DATA_CADASTRO}
                    </span>{' '}
                    {formatarData(complemento.dataCriacao)}
                  </Text>
                  <Text variant="body02-sm">
                    <span>{CONSTS.TEXTOS_COMPLEMENTOS.LABEL_COMPLEMENTO}</span>{' '}
                    {complemento.comentario}
                  </Text>
                </S.ContainerComplement>
              ))}
            </RenderConditional>

            <RenderConditional
              condition={checkIfAllItemsAreTrue([
                !loadingDadosDetalhesOcorrencia,
                !dadosComplemento?.length,
              ])}
            >
              <Text variant="body02-sm" data-testid="mensagemNenhumComplemento">
                {CONSTS.TEXTOS_COMPLEMENTOS.MSG_NENHUM_COMPLEMENTO}
              </Text>
            </RenderConditional>
          </Accordion.Item>
        </Accordion>
      </Card.Content>
    </OCORRENCIA_STYLES.CardContainer>
  );
};

export default CardComplementos;
