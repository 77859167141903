import React, { useEffect, useState } from 'react';
import { Checkbox, Text } from '@cvp/design-system/react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import Table from 'main/components/Table';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { FlexContainer } from 'main/styles/GlobalStyle';
import RenderConditional from 'main/components/RenderConditional';
import * as CONDICIONAL from 'main/utils/conditional';
import { levelRisk } from 'previdencia/utils/riskStatus';
import InputRedistribuirValor from 'previdencia/components/InputRedistribuirValor';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import ButtonsEtapasAporte from 'previdencia/features/Aporte/components/ButtonsEtapasAporte';
import FundosDistribuicaoAporteHeader from 'previdencia/features/Aporte/components/FundosDistribuicaoAporte/FundosDistribuicaoAporteHeader';
import { usePecoFundosDistribuicaoAporte } from 'previdencia/features/Aporte/hooks/usePecoAporte';
import * as FACTORY from 'previdencia/features/Aporte/factories/aporteFactory';
import * as UTILS from 'previdencia/features/Aporte/utils/aporteUtils';
import * as CONSTS from 'previdencia/features/Aporte/constants/constants';
import * as RESPONSE_TYPES from 'previdencia/features/Aporte/types/AporteResponse';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';
import * as S from 'previdencia/features/Aporte/styles';

const FundosDistribuicaoAporte: React.FC<
  APORTE_TYPES.IFundosDistribuicaoAporteProps
> = ({ fundosDistribuicao, aporteFormaPagamento, aporteDadosBancarios }) => {
  const {
    buscarFundosDistribuicaoAporte,
    dadosFundosDistribuicaoAporte,
    loadingDadosFundosDistribuicaoAporte,
  } = usePecoFundosDistribuicaoAporte();

  const { featureData } =
    usePrevidenciaContext<APORTE_TYPES.IAporteContextData>();

  const valorTotalTabela: APORTE_TYPES.IValorTotalFundosAporte[] = [];
  const [somaValorTotal, setSomaValorTotal] = useState(0);
  const [valorComparadoRestante, setValorComparadoRestante] = useState(0);
  const [distribuicao] = useState('true');
  const fundosSelecionados: RESPONSE_TYPES.IDadosFundosAporte[] = [];
  const [quantidadeMaximaFundos, setQuantidadeMaximaFundos] = useState('0');
  const [quantidadeDisponivelFundosNovos, setQuantidadeDisponivelFundosNovos] =
    useState('0');
  const [valorMinimoValido, setValorMinimoValido] = useState(0);

  const resultListaFundos = FACTORY.converterResponseParaListaDadosFundos(
    dadosFundosDistribuicaoAporte?.entidade?.dadosFundosExistentes.dadosFundos,
  );

  const [tabelaFundos, setTabelaFundos] =
    useState<RESPONSE_TYPES.IDadosFundosAporte[]>(resultListaFundos);

  const resultListaFundosNovos = FACTORY.converterResponseParaListaDadosFundos(
    dadosFundosDistribuicaoAporte?.entidade?.dadosFundosNovos.dadosFundos,
  );

  const [tabelaFundosNovos, setTabelaFundosNovos] = useState<
    RESPONSE_TYPES.IDadosFundosAporte[]
  >(resultListaFundosNovos);

  const valorContribuicao =
    Number(aporteFormaPagamento.get().value.valorContribuicao) / 100;

  useEffect(() => {
    buscarFundosDistribuicaoAporte({
      valorContribuicao: valorContribuicao.toString(),
      distribuicaoPersonalizada: distribuicao,
    });
  }, []);

  useEffect(() => {
    setTabelaFundos(resultListaFundos);
    setTabelaFundosNovos(resultListaFundosNovos);

    setQuantidadeMaximaFundos(
      CONDICIONAL.tryGetValueOrDefault(
        [
          dadosFundosDistribuicaoAporte?.entidade?.dadosFundosExistentes
            .qntMaxFundoPermitida,
        ],
        '0',
      ),
    );

    setQuantidadeDisponivelFundosNovos(
      CONDICIONAL.tryGetValueOrDefault(
        [
          dadosFundosDistribuicaoAporte?.entidade?.dadosFundosNovos
            .qntFundoDisponivel,
        ],
        '0',
      ),
    );
  }, [dadosFundosDistribuicaoAporte?.entidade]);

  const handleFundoAporteSelecionado = (
    fundoId: string,
    reservaId: string,
    tipoFundo: string,
  ): void => {
    setQuantidadeMaximaFundos(
      CONDICIONAL.tryGetValueOrDefault(
        [
          dadosFundosDistribuicaoAporte?.entidade?.dadosFundosExistentes
            .qntMaxFundoPermitida,
        ],
        '0',
      ),
    );

    const dadosTabela = UTILS.setDadosTabela(
      fundoId,
      reservaId,
      quantidadeMaximaFundos,
    );

    if (tipoFundo === CONSTS.enumTipoFundo.Existente) {
      setTabelaFundos(dadosTabela);
    } else {
      setTabelaFundosNovos(dadosTabela);
    }
  };

  const handleSetValorFundoAporteSelecionado = (
    fundo: RESPONSE_TYPES.IDadosFundosAporte,
    fundoId: string,
    reservaId: string,
    valorAtual: string,
  ): void => {
    if (fundo.selecionado) {
      FACTORY.modificaValorSelecionado(
        fundo,
        fundoId,
        reservaId,
        valorAtual,
        quantidadeMaximaFundos,
      );

      fundosSelecionados?.push({
        ...fundo,
        valorContribuicao: valorAtual,
      });

      const valorMinimoNaoAtingido = fundosSelecionados.filter(
        fundoSelecionado =>
          CONDICIONAL.checkIfAllItemsAreTrue([
            !!fundoSelecionado.valorContribuicao,
            Number(fundoSelecionado.valorMinimo) >
              Number(fundoSelecionado.valorContribuicao),
          ]),
      ).length;

      setValorMinimoValido(valorMinimoNaoAtingido);
    }
  };

  const seguirProximaEtapa = (): void => {
    fundosDistribuicao.set({
      value: fundosSelecionados,
      isValid: true,
      errorMsg: '',
    });

    featureData?.proximaEtapa();
  };

  const fundosAtivos = (
    listaFundos: RESPONSE_TYPES.IDadosFundosAporte[],
  ): number => {
    let fundoSelecionado = 0;

    listaFundos?.forEach(fundo => {
      fundoSelecionado += UTILS.adicionarSubtrair(fundo.selecionado);
    });

    return fundoSelecionado;
  };

  const renderTabelaFundosExistentes = () => {
    return tabelaFundos?.map(item => {
      return {
        descricaoFundo: (
          <FlexContainer alignItems="center">
            <Checkbox
              id={item.fundoId + item.reservaId}
              checked={item.selecionado}
              onChange={() => {
                handleFundoAporteSelecionado(
                  item.fundoId,
                  item.reservaId,
                  CONSTS.enumTipoFundo.Existente,
                );
              }}
            />
            <Text style={{ marginLeft: 12 }} variant="body02-md">
              {item?.descricaoFundo}
            </Text>
          </FlexContainer>
        ),
        perfil: (
          <S.RiskBarAporteStyleAporte risk={levelRisk(item.descPerfilFundo)} />
        ),
        rentUlt12Meses: <Text variant="body02-md">{item?.rentUlt12Meses}</Text>,
        saldo: <Text variant="body02-md">{item?.saldo}</Text>,
        valor: (
          <>
            <InputRedistribuirValor
              valorMinimo={Number(item.valorMinimo)}
              valorInicialInput={0}
              isResgate={undefined}
              hidden={!item?.selecionado}
              maxLength="15"
              ref={inputRedistribuir => {
                valorTotalTabela[parseFloat(item.fundoId)] = {
                  value: CONDICIONAL.tryGetValueOrDefault(
                    [inputRedistribuir?.value],
                    0,
                  ),
                  cod: item.fundoId,
                  selecionado: true,
                };
                setSomaValorTotal(
                  UTILS.somarFundosAporteSelecionados(valorTotalTabela),
                );
                setValorComparadoRestante(
                  Number(aporteFormaPagamento.get().value.valorContribuicao) /
                    100 -
                    Number(somaValorTotal),
                );
                handleSetValorFundoAporteSelecionado(
                  item,
                  item.fundoId,
                  item.reservaId,
                  CONDICIONAL.tryGetValueOrDefault(
                    [inputRedistribuir?.value],
                    0,
                  ).toString(),
                );
              }}
            />
          </>
        ),
      };
    });
  };

  const renderTabelaFundosNovos = () => {
    return tabelaFundosNovos?.map(item => {
      return {
        descricaoFundo: (
          <FlexContainer alignItems="center">
            <Checkbox
              id={item.fundoId + item.reservaId}
              checked={item.selecionado}
              disabled={CONDICIONAL.checkIfAllItemsAreTrue([
                !item.selecionado,
                fundosAtivos(tabelaFundosNovos) ===
                  Number(quantidadeDisponivelFundosNovos),
              ])}
              onChange={() => {
                handleFundoAporteSelecionado(
                  item.fundoId,
                  item.reservaId,
                  CONSTS.enumTipoFundo.Novo,
                );
              }}
            />
            <Text style={{ marginLeft: 12 }} variant="body02-md">
              {item?.descricaoFundo}
            </Text>
          </FlexContainer>
        ),
        perfil: (
          <S.RiskBarAporteStyleAporte risk={levelRisk(item.descPerfilFundo)} />
        ),
        rentUlt12Meses: <Text variant="body02-md">{item?.rentUlt12Meses}</Text>,
        saldo: <Text variant="body02-md">{item?.saldo}</Text>,
        valor: (
          <InputRedistribuirValor
            isResgate={undefined}
            hidden={!item?.selecionado}
            valorInicialInput={0}
            valorMinimo={Number(item.valorMinimo)}
            ref={inputRedistribuir => {
              valorTotalTabela[parseFloat(item.fundoId)] = {
                selecionado: true,
                value: CONDICIONAL.tryGetValueOrDefault(
                  [inputRedistribuir?.value],
                  0,
                ),
                cod: item.fundoId,
              };
              setValorComparadoRestante(
                Number(aporteFormaPagamento.get().value.valorContribuicao) /
                  100 -
                  Number(somaValorTotal),
              );
              setSomaValorTotal(
                UTILS.somarFundosAporteSelecionados(valorTotalTabela),
              );
              handleSetValorFundoAporteSelecionado(
                item,
                item.fundoId,
                item.reservaId,
                CONDICIONAL.tryGetValueOrDefault(
                  [inputRedistribuir?.value],
                  0,
                ).toString(),
              );
            }}
          />
        ),
      };
    });
  };

  if (loadingDadosFundosDistribuicaoAporte) {
    return <SkeletonLoading blocks={4} />;
  }

  return (
    <>
      <Text variant="body02-md" color="primary" margin>
        {CONSTS.FUNDOS_DISTRIBUICAO_APORTE_LABELS.DISTRIBUICAO_FUNDOS}
      </Text>

      <Text variant="body02-sm" margin>
        {CONSTS.FORMA_DISTRIBUICAO_FUNDOS.ESCOLHA_FORMA}{' '}
        <strong>{quantidadeMaximaFundos}</strong> fundo(s).
      </Text>

      <FundosDistribuicaoAporteHeader
        aporteFormaPagamento={aporteFormaPagamento}
        aporteDadosBancarios={aporteDadosBancarios}
      />

      <Text variant="body02-md" color="primary" margin>
        {CONSTS.FUNDOS_DISTRIBUICAO_APORTE_LABELS.FUNDOS_EXISTENTES}
      </Text>

      <Table
        noHeader
        responsive
        striped
        highlightOnHover
        data={CONDICIONAL.tryGetValueOrDefault(
          [renderTabelaFundosExistentes()],
          [],
        )}
        columns={CONSTS.COLUNAS_TABELA_FUNDOS_APORTE}
        noDataComponent={CONSTS.NAO_HA_DADOS_TABELA}
      />

      <RenderConditional condition={!!tabelaFundosNovos.length}>
        <br />
        <Text variant="body02-md" color="primary">
          {CONSTS.FUNDOS_DISTRIBUICAO_APORTE_LABELS.FUNDOS_NOVOS}
        </Text>
        <Table
          noHeader
          responsive
          striped
          highlightOnHover
          data={CONDICIONAL.tryGetValueOrDefault(
            [renderTabelaFundosNovos()],
            [],
          )}
          columns={CONSTS.COLUNAS_TABELA_FUNDOS_APORTE}
          noDataComponent={CONSTS.NAO_HA_DADOS_TABELA}
        />
      </RenderConditional>

      <S.WraperTotalFundosAporte>
        <Text variant="body03-md">
          {CONSTS.FUNDOS_DISTRIBUICAO_APORTE_LABELS.RESTANTE}
          <strong>
            {tryGetMonetaryValueOrDefault(valorComparadoRestante)}
          </strong>
        </Text>
        <Text variant="body03-md">
          {CONSTS.FUNDOS_DISTRIBUICAO_APORTE_LABELS.TOTAL_SELECIONADO}
          <strong>{tryGetMonetaryValueOrDefault(somaValorTotal)}</strong>
        </Text>
      </S.WraperTotalFundosAporte>

      <ButtonsEtapasAporte
        proximaEtapa={seguirProximaEtapa}
        disabled={CONDICIONAL.checkIfSomeItemsAreTrue([
          !!Number(valorComparadoRestante),
          !!valorMinimoValido,
          !dadosFundosDistribuicaoAporte?.entidade,
        ])}
        buttonLabel="Avançar"
      />
    </>
  );
};

export default FundosDistribuicaoAporte;
