import { APP_CURRENT_VERSION } from 'config/app';
import { obterChaveArmazenamentoModalNovaFeaturePorPeriodo } from 'config/storage_keys';
import { useAuth } from 'main/features/Auth/hooks';
import { getDateNowWithTimezone } from 'main/utils';
import {
  getLocalItem,
  removeLocalItem,
  setLocalItem,
} from 'main/utils/storage';
import React, { useEffect, useState } from 'react';
import RenderConditional from '../RenderConditional/RenderConditional';
import ModalDisponibilidade from './StatusSinistro/ModalDisponibilidade';
import ModalIndisponibilidade from './StatusSinistro/ModalIndisponibilidade';
import ModalPrimeiroAlerta from './StatusSinistro/ModalPrimeiroAlerta';
import ModalDpsEletronica from './StatusSinistro/ModalDpsEletronica';

type TipoModal =
  | 'primeiroalerta'
  | 'indisponibilidade'
  | 'disponibilidade'
  | 'dpseletronica';

const ModalNewFeatureByPeriod: React.FC = () => {
  const currentDate = getDateNowWithTimezone();
  const storageKey = obterChaveArmazenamentoModalNovaFeaturePorPeriodo();
  const { user, isMarcadorControleValid } = useAuth();
  const [openModal, setOpenModal] = useState(false);

  const [currentModal, setCurrentModal] = useState<TipoModal>('dpseletronica');

  const handleClose = (key: string) => {
    setLocalItem(storageKey, `${APP_CURRENT_VERSION}-true-${key}`);
    setOpenModal(false);
  };

  const modais = [
    {
      startDate: '2023-08-17T00:00:00.000Z',
      endDate: '2023-08-31T23:59:00.000Z',
      component: 'primeiroalerta' as TipoModal,
    },
    {
      startDate: '2023-07-12T00:00:00.000Z',
      endDate: '2023-07-18T19:00:00.000Z',
      component: 'indisponibilidade' as TipoModal,
    },
    {
      startDate: '2023-07-19T00:00:00.000Z',
      endDate: '2023-07-25T19:00:00.000Z',
      component: 'disponibilidade' as TipoModal,
    },
    {
      startDate: '2024-01-03T00:00:00.000Z',
      endDate: '2024-01-18T23:59:59.000Z',
      component: 'dpseletronica' as TipoModal,
    },
  ];

  const isCurrentDateValid = (startDate: string, endDate: string) => {
    const firstCheck = currentDate >= new Date(startDate);
    const secondCheck = currentDate <= new Date(endDate);
    return firstCheck && secondCheck;
  };

  const isNotificationAvailable = (key: string) => {
    let oldVersion = getLocalItem<string>(storageKey);
    if (typeof oldVersion === 'boolean') {
      removeLocalItem(storageKey);
      oldVersion = '';
    }
    const [version, read, featureyKey] = (oldVersion ?? '').split('-');
    const checkFeature = featureyKey === undefined ? true : featureyKey === key;
    const checkVersion = version === APP_CURRENT_VERSION;
    const isRead = checkVersion && checkFeature && Boolean(read);
    return isMarcadorControleValid() && !isRead;
  };

  const getCurrentModalToShow = () => {
    modais.forEach(item => {
      if (
        isNotificationAvailable(item.component) &&
        isCurrentDateValid(item.startDate, item.endDate)
      ) {
        setCurrentModal(item.component);
        setOpenModal(true);
      }
    });
  };

  useEffect(() => {
    getCurrentModalToShow();
  }, [user]);

  return (
    <>
      <RenderConditional condition={currentModal === 'primeiroalerta'}>
        <ModalPrimeiroAlerta
          open={currentModal === 'primeiroalerta' && openModal}
          handleClose={() => handleClose('primeiroalerta')}
        />
      </RenderConditional>
      <RenderConditional condition={currentModal === 'indisponibilidade'}>
        <ModalIndisponibilidade
          open={currentModal === 'indisponibilidade' && openModal}
          handleClose={() => handleClose('indisponibilidade')}
        />
      </RenderConditional>
      <RenderConditional condition={currentModal === 'disponibilidade'}>
        <ModalDisponibilidade
          open={currentModal === 'disponibilidade' && openModal}
          handleClose={() => handleClose('disponibilidade')}
        />
      </RenderConditional>
      <RenderConditional condition={currentModal === 'dpseletronica'}>
        <ModalDpsEletronica
          open={currentModal === 'dpseletronica' && openModal}
          handleClose={() => handleClose('dpseletronica')}
        />
      </RenderConditional>
    </>
  );
};

export default ModalNewFeatureByPeriod;
