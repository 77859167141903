import { Text } from '@cvp/design-system/react';
import { maskCpfCnpj } from 'contratosPrestamista/utils/maskCpfCnpj';
import { Button } from 'main/components/Header/styles';
import RenderConditional from 'main/components/RenderConditional';
import { Display } from 'main/features/Auth/components';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { IStatusPropostaDpsAgrupado } from '../types/IResponseListarStatusPropostaDps';
import { STATUS_DPS } from './MapeamentoStatus';
import { tryGetValueOrDefault } from 'main/utils/conditional';

export const ColunasTabelaSocios = (
  handleOpenModalReenviarLink: (cpf: string | number) => void,
  handleOpenModalSolicitarDps: (cpf: string | number) => void,
) => [
  {
    name: 'CPF',
    selector: 'cpf',
    cell: (row: IStatusPropostaDpsAgrupado) => (
      <span>
        {maskCpfCnpj(
          tryGetValueOrDefault([row.numCpfSocioFormatado?.toString()], ''),
        )}
      </span>
    ),
  },
  {
    name: 'Capital Segurado Individual',
    width: '150px',
    selector: 'capitalSegurado',
    cell: (row: IStatusPropostaDpsAgrupado) => (
      <span>{formatarValorPadraoBrasileiro(row.capitalSegurado)}</span>
    ),
  },
  {
    name: 'Total de acúmulo de risco',
    selector: 'valorAcumulo',
    width: '180px',
    cell: (row: IStatusPropostaDpsAgrupado) => (
      <span>{formatarValorPadraoBrasileiro(row.valorAcumulo)}</span>
    ),
  },
  {
    name: 'Status DPS',
    selector: 'socios',
    width: '150px',
    cell: (row: IStatusPropostaDpsAgrupado) => {
      return <Text variant="caption-02">{STATUS_DPS[row.statusDps]}</Text>;
    },
  },
  {
    name: 'Ação',
    selector: 'acao',
    cell: (row: IStatusPropostaDpsAgrupado) => (
      <Display>
        <RenderConditional condition={!!row.reenviarLinkDps}>
          <Button
            small
            variant="outlined"
            onClick={async () => handleOpenModalReenviarLink(row.numCpfSocio)}
          >
            Reenviar link DPS
          </Button>
        </RenderConditional>
        <RenderConditional condition={!!row.solicitarDps}>
          <Button
            small
            variant="primary"
            onClick={async () => handleOpenModalSolicitarDps(row.numCpfSocio)}
          >
            Solicitar DPS
          </Button>
        </RenderConditional>
      </Display>
    ),
  },
];
