import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { AppContext } from 'main/contexts/AppContext';
import {
  IObterContatosDefaultPrevidencia,
  useObterContatosDefaultPrevidencia,
} from 'main/hooks/useObterContatosDefaultPrevidencia';
import { useMessageEvent } from 'main/hooks/useMessageEvent';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { formatarData } from 'main/utils';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { IMessageEvent } from 'main/types/IMessageEvent';
import {
  PrevRedirectConfig,
  PREV_REDIRECTS_SCREENS,
} from 'previdencia/config/redirects';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { StatusResgate } from 'previdencia/types/StatusResgate';
import { usePecoSolicitarAssinaturaEletronica } from 'previdencia/features/SolicitacaoResgate/hooks/usePecoSolicitacaoResgate';
import * as UTILS from 'previdencia/features/SolicitacaoResgate/utils/SolicitacaoResgate';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const useResgateSucesso = () => {
  const history = useHistory();

  const location = useLocation<SOLICITACAO_RESGATE_TYPES.IParamsLocationData>();

  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const { featureData, setFeatureData } =
    usePrevidenciaContext<SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData>();

  const { subscribe, unsubscribe } = useMessageEvent();

  const [openModalMensagemPendencia, setOpenModalMensagemPendencia] =
    useState(false);
  const [urlAssinatura, setUrlAssinatura] = useState<string>('');
  const [openModalTipo, setModalTipo] = useState<boolean>(false);
  const [openModalDocUsign, setOpenModalDocUsign] = useState<boolean>(false);
  const [exibirBotaoFecharModal, setExibirBotaoFecharModal] =
    useState<boolean>(false);

  const { data: responseObterContatosDefault } =
    useObterContatosDefaultPrevidencia();

  const { emailDefault, numerosTelefone, numeroTelefoneSemDDD, numeroDDD } =
    tryGetValueOrDefault<IObterContatosDefaultPrevidencia>(
      [responseObterContatosDefault],
      {} as IObterContatosDefaultPrevidencia,
    );

  const dadosCliente = {
    email: emailDefault,
    cpfCnpj,
    mobileNumber: numerosTelefone,
  };

  const {
    statusResgate,
    solicitarAssinaturaEletronica,
    motivoPendenciaResgate,
  } = tryGetValueOrDefault(
    [featureData?.dadosRetornoConfirmacaoResgate],
    {} as SOLICITACAO_RESGATE_TYPES.IDadosRetornoConfirmacaoResgate,
  );

  const { data: locationHistoricoSolicitacoes } = tryGetValueOrDefault(
    [location.state],
    {} as SOLICITACAO_RESGATE_TYPES.IParamsLocationData,
  );

  const isOrigemRedirectHistorico = UTILS.verificarOrigemRedirectHistorico(
    location.state?.from,
  );

  const numeroResgate = tryGetValueOrDefault(
    [
      locationHistoricoSolicitacoes?.idRequisicao,
      featureData?.numeroResgateConsolidado,
    ],
    '',
  );

  const objetoEmail = {
    tipoEmail: tipoEmailConstants.COMPROVANTE_RESGATE,
    parametrosEnvio: {
      numeroResgate,
      numeroCertificado: numCertificado,
      cpfCnpj,
    },
  };

  const dataSolicitacao = formatarData(new Date());

  const textoInformativoFinalizacaoResgate =
    CONSTS.INFORMATIVO_INICIO(dataSolicitacao);

  const solicitarAssinaturaParams = {
    codResgate: numeroResgate,
    email: emailDefault,
    numeroTelefone: numeroTelefoneSemDDD,
    dddTelefone: numeroDDD,
  };

  const { solicitarAssinatura, loadingDadosSolicitacaoAssinaturaEletronica } =
    usePecoSolicitarAssinaturaEletronica();

  const handleAssinar = async (): Promise<void> => {
    const dadosSolicitacaoAssinatura = await solicitarAssinatura(
      solicitarAssinaturaParams,
    );

    const urlAssinaturaEletronica =
      dadosSolicitacaoAssinatura?.entidade?.urlAssinatura;

    if (urlAssinaturaEletronica) {
      setUrlAssinatura(urlAssinaturaEletronica);
      setOpenModalDocUsign(true);
      setModalTipo(false);
    }
  };

  const handleCloseModalTipo = async (): Promise<void> => {
    setModalTipo(false);

    history.push(
      PrevRedirectConfig[PREV_REDIRECTS_SCREENS.HISTORICO_CANCELAMENTO_RESGATE],
    );
  };

  const fecharModaisAssinatura = (): void => {
    setModalTipo(false);
    setOpenModalDocUsign(false);
  };

  const handleCloseModal = (): void => {
    if (isOrigemRedirectHistorico) {
      fecharModaisAssinatura();

      history.push(
        PrevRedirectConfig[PREV_REDIRECTS_SCREENS[location.state?.from]],
      );
    } else fecharModaisAssinatura();
  };

  const handleCloseModalPendencia = (): void => {
    setOpenModalMensagemPendencia(false);
  };

  const salvarRetornoConfirmacaoResgateContexto = (): void => {
    if (
      checkIfAllItemsAreTrue([
        isOrigemRedirectHistorico,
        !!location.state?.data?.idRequisicao,
      ])
    ) {
      setFeatureData({
        ...featureData,
        dadosRetornoConfirmacaoResgate: {
          ...featureData?.dadosRetornoConfirmacaoResgate,
          numeroResgate: location.state?.data.idRequisicao,
          solicitarAssinaturaEletronica: true,
        } as SOLICITACAO_RESGATE_TYPES.IDadosRetornoConfirmacaoResgate,
      });

      setModalTipo(true);
    }
  };

  useEffect(() => {
    setModalTipo(solicitarAssinaturaEletronica);
    setOpenModalMensagemPendencia(statusResgate === StatusResgate.PENDENTE);
  }, [featureData]);

  useEffect(() => {
    subscribe(event => {
      const {
        data: { eventName, eventSuccess },
      } = event as MessageEvent<IMessageEvent>;

      if (
        checkIfAllItemsAreTrue([
          eventName === 'retornoAcaoConcluirAssinatura',
          eventSuccess,
        ])
      ) {
        setModalTipo(false);
        setExibirBotaoFecharModal(true);
      }
    });

    return () => {
      unsubscribe(() => setExibirBotaoFecharModal(false));
    };
  }, []);

  useEffect(salvarRetornoConfirmacaoResgateContexto, [
    location.state?.from,
    location.state?.data?.idRequisicao,
  ]);

  return {
    statusResgate,
    numeroResgate,
    objetoEmail,
    openModalTipo,
    loadingDadosSolicitacaoAssinaturaEletronica,
    dadosCliente,
    openModalDocUsign,
    urlAssinatura,
    exibirBotaoFecharModal,
    openModalMensagemPendencia,
    motivoPendenciaResgate,
    textoInformativoFinalizacaoResgate,
    handleAssinar,
    handleCloseModalTipo,
    handleCloseModal,
    handleCloseModalPendencia,
  };
};

export default useResgateSucesso;
