import { Button } from '@cvp/design-system/react';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import { useRegistrarOcorrenciaContext } from 'registroOcorrenciaASC/features/registrarOcorrencia/contexts/RegistrarOcorrenciaContext';
import { useEnviarOcorrencia } from 'registroOcorrenciaASC/features/registrarOcorrencia/hooks';
import * as REGISTRO_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrencias';

const BotaoEnviarOcorrencia = ({
  validateArquivoOcorrencia,
  habilitarBotaoFormFopPreenchido,
}: REGISTRO_OCORRENCIA_TYPES.IBotaoEnviarOcorrenciaProps): React.ReactElement => {
  const { formDadosOcorrencia } = useRegistrarOcorrenciaContext();

  const { enviarOcorrenciaASC, validarEnvioOcorrencia } = useEnviarOcorrencia({
    validateArquivoOcorrencia,
  });

  return (
    <Button
      variant="primary"
      onClick={enviarOcorrenciaASC}
      data-testid="enviarOcorrenciaASC"
      disabled={checkIfSomeItemsAreTrue([
        formDadosOcorrencia.botaoRegistroOcorrenciaHabilitado,
        validarEnvioOcorrencia,
        !!habilitarBotaoFormFopPreenchido,
      ])}
      loading={validarEnvioOcorrencia}
    >
      Enviar
    </Button>
  );
};

export default BotaoEnviarOcorrencia;
