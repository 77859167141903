import {
  ResponseExtratoUnificado,
  ItensExtrato,
} from 'previdencia/features/PosicaoConsolidada/types/ResponseExtratoUnificado';
import { DOCUMENTOS_FUNDOS_INITIAL_STATE } from 'previdencia/features/PosicaoConsolidada/constants/constants';

export function extratoCarteira(data: ResponseExtratoUnificado | undefined) {
  return data?.itensExtrato.reduce<ItensExtrato[]>((acc, item) => {
    acc.push({
      contaId: item.contaId,
      corFundo: item.corFundo,
      descricaoCompletaFundo: item.descricaoCompletaFundo,
      lucratividadeUltimos12Meses: `${
        item.lucratividadeUltimos12Meses ?? '0.0'
      }%`,
      modalidadeTributacao: [
        item.descricaoCategoriaSubProduto,
        item.tipoOpcaoTaxa,
      ],
      percentualSaldo: `${item.percentualSaldo ?? '0.0'}%`,
      taxaAdministrativa: `${item.taxaAdministrativa ?? '0.0'}%`,
      saldoTotal: item.saldoTotal,
      documentos: item.documentosFundos.length
        ? item.documentosFundos.map(doc => doc)
        : [DOCUMENTOS_FUNDOS_INITIAL_STATE],
      flutuacaoUltimos12Meses: `${item.flutuacaoUltimos12Meses ?? '0.0'}%`,
    });

    return acc;
  }, []);
}

export function totalCarteira(data: ResponseExtratoUnificado | undefined) {
  let percentualSaldo = 0;
  let saldoTotal = 0;

  const totalCarteiraInvestimentos: ItensExtrato[] = [];

  extratoCarteira(data)?.forEach(item => {
    percentualSaldo += Number(item.percentualSaldo.split('%')[0]);
    saldoTotal += Number(item.saldoTotal);
  });

  totalCarteiraInvestimentos.push({
    contaId: 'Carteira',
    corFundo: 'transparent',
    flutuacaoUltimos12Meses: `${data?.percentualTotalFlutuacao || 0}**`,
    lucratividadeUltimos12Meses: `${data?.percentualTotalLucro || 0}**`,
    percentualSaldo: `${Math.round(percentualSaldo)}%`,
    saldoTotal: String(saldoTotal),
    taxaAdministrativa: '',
    descricaoCompletaFundo: '',
    documentos: [],
    modalidadeTributacao: [],
  });

  return totalCarteiraInvestimentos;
}
