import { CardResumoProduto } from 'seguros/components';
import { Display } from '@cvp/design-system/react';
import DadosSegurado from 'seguros/components/DadosSegurado/DadosSegurado';

const DadosDoSegurado = () => {
  return (
    <Display type="display-block">
      <CardResumoProduto />
      <DadosSegurado />
    </Display>
  );
};

export default DadosDoSegurado;
