import { Button, Card, Display, Grid, Text } from '@cvp/design-system/react';
import { obterChaveArmazenamentoTokenCliente } from 'config/storage_keys';
import ModalAlertaContatos from 'main/components/AssinaturaDocuSign/ModalAlertaContatos';
import ModalAlertaDadosBancarios from 'main/components/AssinaturaDocuSign/ModalAlertaDadosBancarios';
import ModalTipoDocuSign from 'main/components/AssinaturaDocuSign/ModalTipoDocuSign';
import ModalTokenDocuSign from 'main/components/AssinaturaDocuSign/ModalTokenDocuSign';
import VoltarDadosPlano from 'main/components/Button/voltarDadosPlano/VoltarDadosPlano';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { AppContext } from 'main/contexts/AppContext';
import { useAuth } from 'main/features/Auth/hooks';
import useFieldLink from 'main/hooks/useFieldLink';
import useInstituicoesBancarias from 'main/hooks/useInstituicoesBancarias';
import { useMessageEvent } from 'main/hooks/useMessageEvent';
import { useObterContatosDefaultPrevidencia } from 'main/hooks/useObterContatosDefaultPrevidencia';
import { useObterEmailDefault } from 'main/hooks/useObterEmailDefault';
import { IMessageEvent } from 'main/types/IMessageEvent';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { getSessionItem } from 'main/utils/storage';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import CancelamentoPrevidencia from 'previdencia/features/CancelamentoPrevidencia/components/Cancelamento';
import { motivoEstorno } from 'previdencia/features/CancelamentoPrevidencia/constants/motivoEstornoConstants';
import { initialStateModal } from 'previdencia/features/CancelamentoPrevidencia/factories/initialStateFactory';
import {
  useAtualizarDadosBancarios,
  useListarDadosBancarios,
} from 'previdencia/features/CancelamentoPrevidencia/hooks/useDadosBancariosCancelamento';
import useSolicitarCancelamentoPrevidencia from 'previdencia/features/CancelamentoPrevidencia/hooks/useSolicitarCancelamentoPrevidencia';
import * as CANCELAMENTO_TYPES from 'previdencia/features/CancelamentoPrevidencia/types/Cancelamento';
import useObterDadosPlano from 'previdencia/features/DadosPlano/hooks/useObterDadosPlano';
import { IMatrizAcessoCliente } from 'previdencia/types/MatrizDeAcesso';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useValidarCancelamentoPrevidencia } from 'previdencia/features/CancelamentoPrevidencia/hooks/useValidarCancelamento';

const DadosCancelamento = (): React.ReactElement => {
  const tokenCliente = obterChaveArmazenamentoTokenCliente();
  const resultTokenCliente = getSessionItem<IMatrizAcessoCliente>(tokenCliente);

  const history = useHistory();
  const { subscribe, unsubscribe } = useMessageEvent();
  const [openModal, setOpenModal] = useState(false);
  const [exibirBotaoFecharModal, setExibirBotaoFecharModal] =
    useState<boolean>(true);
  const [openModalToken, setOpenModalToken] = useState(false);
  const [optModalDocuSign, setModalDocuSign] = useState(false);
  const [motivoLink] = useFieldLink('');
  const [outroMotivoLink] = useFieldLink('');
  const [eventSucess, setEventSucess] = useState<boolean>(false);

  const { isLoading: loading } = useObterDadosPlano();
  const { cliente } = useContext(AppContext);

  const { numerosTelefone } = useObterEmailDefault();
  const { user } = useAuth();

  const [openAlertaContatos, setOpenAlertaContatos] =
    useState<CANCELAMENTO_TYPES.ModaisCancelamento>(initialStateModal);

  const { instituicoesBancarias } = useInstituicoesBancarias(cliente.cpfCnpj);

  const { loadingValidarCancelamento, responseValidarCancelamento } =
    useValidarCancelamentoPrevidencia();
  const {
    loading: loadingCancelamento,
    response: responseCancelamento,
    cancelarPrevidencia,
  } = useSolicitarCancelamentoPrevidencia();

  const motivoValue = motivoLink.get().value;
  const outroMotivoValue = outroMotivoLink.get().value;

  const isDisabled = checkIfSomeItemsAreTrue([
    !motivoValue,
    motivoValue === 'Outros' && !outroMotivoValue,
  ]);

  const hookAtualizarDadosBancarios = useAtualizarDadosBancarios();

  const {
    loading: loadingListaDadosBancarios,
    response: responseListaDadosBancarios,
    listarDadosBancarios,
  } = useListarDadosBancarios();

  const {
    data: reponseObterContatosDefault,
    isFetching: loadingObterContatos,
    isFetched: recuperacaoEmailExecutada,
  } = useObterContatosDefaultPrevidencia();
  const { emailDefault } = reponseObterContatosDefault ?? {};
  const obterDescricaoMotivo = () => {
    return motivoLink.get().value === 'Outros'
      ? outroMotivoLink.get().value
      : motivoEstorno
          .find(motivo => motivo.value === motivoLink.get().value)
          ?.label.trim() || '';
  };

  const handleAssinar = async () => {
    const result = await cancelarPrevidencia({
      accountId: cliente.numCertificado,
      codMotivo: motivoLink.get().value,
      descMotivo: obterDescricaoMotivo(),
      nomeEconomiario: user.nomeUsuario,
      usuarioEconomiario: user.nomeAcesso,
    });
    if (result) {
      setOpenModalToken(true);
      setModalDocuSign(false);
      setOpenModal(false);
    }
  };
  useEffect(() => {
    if (!loadingObterContatos && recuperacaoEmailExecutada) {
      setOpenAlertaContatos(prev => ({ ...prev, modalAlertaContatos: true }));
    }
  }, [loadingObterContatos, recuperacaoEmailExecutada]);

  useEffect(() => {
    subscribe(event => {
      const {
        data: { eventName, eventSuccess },
      } = event as MessageEvent<IMessageEvent>;
      if (
        checkIfAllItemsAreTrue([
          eventName === 'retornoAcaoConcluirAssinatura',
          eventSuccess,
        ])
      ) {
        setEventSucess(true);
      }
    });
    return () => {
      unsubscribe(() => setExibirBotaoFecharModal(true));
    };
  }, []);

  if (
    checkIfSomeItemsAreTrue([
      loading,
      loadingCancelamento,
      loadingValidarCancelamento,
    ])
  ) {
    return <SkeletonLoading blocks={2} />;
  }

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <Card>
        <RenderConditional
          condition={!responseValidarCancelamento?.permissaoCancelamento}
        >
          <Card.Content padding={[4, 4, 4]}>
            <Text variant="headline-05" color="primary" margin>
              Cancelar Certificado
            </Text>
            <Text variant="body02-md" color="text-light" margin>
              {responseValidarCancelamento?.mensagem}
            </Text>
          </Card.Content>
        </RenderConditional>
        <RenderConditional
          condition={!!responseValidarCancelamento?.permissaoCancelamento}
        >
          <RenderConditional condition={!eventSucess}>
            <Card.Content padding={[4, 4, 4]}>
              <Grid>
                <Grid.Item xs={1}>
                  <Text variant="headline-05" color="primary" margin>
                    Cancelar Certificado
                  </Text>
                  <Text variant="body02-md" color="text-light" margin>
                    Para que possamos melhorar nossos processos, por favor nos
                    informe porque o cliente deseja solicitar cancelamento do
                    cerfiticado e o estorno dos valores.
                  </Text>
                  <CancelamentoPrevidencia
                    motivoEstorno={motivoEstorno}
                    motivoLink={motivoLink}
                    outroMotivoLink={outroMotivoLink}
                  />
                </Grid.Item>
              </Grid>
              <Display>
                <VoltarDadosPlano />
                <Button
                  disabled={isDisabled}
                  variant="primary"
                  onClick={() => {
                    listarDadosBancarios();
                    setOpenModal(true);
                  }}
                >
                  Avançar
                </Button>
              </Display>
            </Card.Content>
          </RenderConditional>
          <ModalAlertaDadosBancarios
            open={openModal}
            onClose={() => setOpenModal(false)}
            onContinue={() => setModalDocuSign(true)}
            dados={{
              codBanco: responseListaDadosBancarios?.codigoContaBanco,
              agencia: responseListaDadosBancarios?.agencia,
              operacao: responseListaDadosBancarios?.operacao,
              conta: responseListaDadosBancarios?.conta,
              nomeTitular: resultTokenCliente.matrizAcessoCliente.nome,
              digitoVerificador: responseListaDadosBancarios?.digitoConta,
            }}
            isFetchingDados={loadingListaDadosBancarios}
            listaInstituicoesBancarias={instituicoesBancarias}
            hookAtualizar={hookAtualizarDadosBancarios}
          />
          <ModalTipoDocuSign
            open={optModalDocuSign}
            handleClose={() => {
              setModalDocuSign(false);
              setOpenModal(false);
            }}
            executarAssinatura={handleAssinar}
            dadosCliente={{
              email: emailDefault,
              mobileNumber: numerosTelefone,
              cpfCnpj: cliente.cpfCnpj,
              numeroCertificado: cliente.numCertificado,
              tipoProduto: undefined,
            }}
            tituloModal="Cancelamento Previdência"
          />
          <RenderConditional
            condition={checkIfAllItemsAreTrue([
              openModalToken,
              !!responseCancelamento,
            ])}
          >
            <ModalTokenDocuSign
              open={openModalToken}
              handleClose={() => setOpenModalToken(false)}
              urlTokenPage={tryGetValueOrDefault([responseCancelamento], '')}
              exibirBotaoFecharModal={exibirBotaoFecharModal}
            />
          </RenderConditional>

          <ModalAlertaContatos
            open={openAlertaContatos.modalAlertaContatos}
            onClose={() => setOpenAlertaContatos(initialStateModal)}
            redirect={() =>
              history.push('/cliente/produtos/previdencia/dados-participante')
            }
            dados={{
              email: emailDefault,
              telefone: numerosTelefone,
            }}
          />
        </RenderConditional>
        <RenderConditional condition={eventSucess}>
          <Card.Content>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-05" color="primary" margin>
                  Cancelar Certificado
                </Text>
                <Text variant="body02-md" color="text-light" margin>
                  O cancelamento do certificado foi solicitado com sucesso.
                </Text>
              </Grid.Item>
            </Grid>
          </Card.Content>
        </RenderConditional>
      </Card>
    </Display>
  );
};

export default DadosCancelamento;
