import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router';
import Icon from 'main/components/Icon';
import { Accordion, Card, Grid, Text, Display } from '@cvp/design-system/react';
import { TextLabel } from 'main/styles/GlobalStyle';
import masks from 'main/utils/masks';
import { formatarData } from 'main/utils';
import { maskCpfCnpj } from 'contratosPrestamista/utils/maskCpfCnpj';
import CardSkeleton from 'main/components/Card/CardSkeleton';
import FabButton, { Action } from 'portabilidade/components/FabButton';
import { useObterProposta } from '../hooks/useObterPropostas';
import { IResponseListaProposta } from '../types/IResponseListaProposta';
import ModalUploadArquivo from '../components/ModalUploadArquivo';

type LocationState = {
  proposta: IResponseListaProposta;
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
const DetalheProposta = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const {
    state: { proposta },
  } = useLocation<LocationState>();
  const { data, isLoading } = useObterProposta(
    id,
    proposta.numeroLinhaDoProduto,
    proposta.codigoDoEstipulante,
  );
  return (
    <Display type="display-block">
      {isLoading ? (
        <CardSkeleton />
      ) : (
        <Card>
          <Card.Content>
            <Accordion open>
              <Accordion.Item title="Dados da Proposta">
                <Grid>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Nome do Segurado
                      </TextLabel>
                      <Text variant="body02-sm" data-testid="nomeSegurado">
                        {data?.nomeSegurado}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        CPF/CNPJ do Segurado
                      </TextLabel>
                      <Text variant="body02-sm" data-testid="cpfCnpjSegurado">
                        {maskCpfCnpj(data?.cpfCnpjSegurado ?? proposta.cpfCnpj)}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Nº do Certificado
                      </TextLabel>
                      <Text variant="body02-sm" data-testid="numeroCertificado">
                        {data?.numeroCertificado}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">Nº da Proposta</TextLabel>
                      <Text variant="body02-sm" data-testid="numeroProposta">
                        {data?.numeroProposta ?? id}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Status da Proposta
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="descricaoStatusProposta"
                      >
                        {data?.descricaoStatusProposta}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">Agência</TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="codigoAgenciaVenda"
                      >
                        {data?.codigoAgenciaVenda}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Data Início Vigência
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="dataInicioVigencia"
                      >
                        {formatarData(data?.dataInicioVigencia ?? '')}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Data Fim Vigência
                      </TextLabel>
                      <Text variant="body02-sm" data-testid="dataFimVigencia">
                        {formatarData(data?.dataFimVigencia ?? '')}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Valor Prêmio Líquido
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="valorPremioLiquido"
                      >
                        {masks.currency.mask(data?.valorPremioLiquido)}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Valor da Importância Segurada
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="valorImportanciaSegurada"
                      >
                        {masks.currency.mask(data?.valorImportanciaSegurada)}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Descrição Periodicidade da Cobrança
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="descricaoPeriodicidadeCobranca"
                      >
                        {data?.descricaoPeriodicidadeCobranca}
                      </Text>
                    </div>
                  </Grid.Item>
                </Grid>
              </Accordion.Item>
            </Accordion>
          </Card.Content>
          <FabButton iconName="floatMenu">
            <Action text="Realizar upload" onClick={() => setOpenModal(true)}>
              <Icon name="uploadArrowIcon" />
            </Action>
          </FabButton>
          {openModal && (
            <ModalUploadArquivo
              open={openModal}
              proposta={proposta}
              handleClose={setOpenModal}
            />
          )}
        </Card>
      )}
    </Display>
  );
};

export default DetalheProposta;
