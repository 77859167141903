import { usePeco } from 'main/hooks/usePeco';
import { PECOS_VIDA } from 'seguros/config/endpoints';
import * as REQUEST_TYPES from 'painelPortabilidade/types/ConsultarPortabilidadeRequest';
import * as RESPONSE_TYPES from 'painelPortabilidade/types/ConsultarPortabilidadeResponse';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { useAuth } from 'main/features/Auth/hooks';
import { payloadAgencia } from 'painelPortabilidade/types/ConsultarPortabilidadeAgencia';

const usePecoConsultarPortabilidadeAgencia = (payload?: payloadAgencia) => {
  const { user } = useAuth();
  const {
    response: dadosHierarquiaPortabilidadeAgencia,
    loading: loadingHierarquiaPortabilidadeAgencia,
    fetchData: fetchDataAgencia,
  } = usePeco<
    REQUEST_TYPES.IDadosPortabilidadePayloadAgencia,
    RESPONSE_TYPES.IDadosPortabilidadeResponseAgencia
  >({
    api: { operationPath: PECOS_VIDA.ConsultarPortabilidadeHierarquiaAgencia },
    autoFetch: true,
    handleResponse: { throwToastErrorBFF: false },
    payload: {
      codigo: tryGetValueOrDefault(
        [payload?.codigoUnidade],
        user?.agenciaVinculada,
      ),
    },
  });

  return {
    dadosHierarquiaPortabilidadeAgencia,
    loadingHierarquiaPortabilidadeAgencia,
    fetchDataAgencia,
  };
};

export default usePecoConsultarPortabilidadeAgencia;
