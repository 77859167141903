/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
import { Button, Display, Grid, Text } from '@cvp/design-system/react';
import { TextLabel } from 'main/styles/GlobalStyle';
import { formatarData } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { formatarCpf } from 'main/utils/cpf_cnpj';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import * as RESPONSE_TYPES from 'previdencia/features/Resgate/types/RelatorioResgateResponse';
import React, { useCallback } from 'react';
import * as S from './styles';

interface IDetalhesResgateProps {
  detalhe: RESPONSE_TYPES.IRelatorioResgateResponse | undefined;
  close: () => void;
  open: boolean;
}

const ModalDetalhesResgate: React.FC<IDetalhesResgateProps> = ({
  detalhe,
  close,
  open,
}): React.ReactElement => {
  const detalheResgate = tryGetValueOrDefault(
    [detalhe],
    {} as RESPONSE_TYPES.IRelatorioResgateResponse,
  );

  const imprimir = useCallback(() => {
    window.print();
  }, []);

  return (
    <div>
      <S.Modal show={open} onClose={close}>
        <Display type="display-block">
          <Text variant="headline-05" color="primary" margin>
            Informações do Resgate
          </Text>
          <Grid justify="space-between">
            <Grid.Item xs={1} lg={1 / 3}>
              <div>
                <TextLabel variant="body02-sm">Nome do Cliente</TextLabel>
                <Text variant="body02-sm" data-testid="nomeCliente">
                  {tryGetValueOrDefault([detalheResgate.nomeCliente], '-')}
                </Text>
              </div>
            </Grid.Item>
            <Grid.Item xs={1} lg={1 / 3}>
              <div>
                <TextLabel variant="body02-sm">CPF/CNPJ</TextLabel>
                <Text variant="body02-sm" data-testid="cpfCnpjCliente">
                  {tryGetValueOrDefault(
                    [formatarCpf(detalheResgate.cpfCnpjCliente)],
                    '-',
                  )}
                </Text>
              </div>
            </Grid.Item>
            <Grid.Item xs={1} lg={1 / 3}>
              <div>
                <TextLabel variant="body02-sm">Agência</TextLabel>
                <Text variant="body02-sm" data-testid="codigoAgencia">
                  {tryGetValueOrDefault([detalheResgate.codigoAgencia], '-')} -{' '}
                  {tryGetValueOrDefault([detalheResgate.nomeAgencia], '-')}
                </Text>
              </div>
            </Grid.Item>
            <Grid.Item xs={1} lg={1 / 3}>
              <div>
                <TextLabel variant="body02-sm">Filial</TextLabel>
                <Text variant="body02-sm" data-testid="nomeFilial">
                  {tryGetValueOrDefault([detalheResgate.nomeFilial], '-')}
                </Text>
              </div>
            </Grid.Item>
            <Grid.Item xs={1} lg={1 / 3}>
              <div>
                <TextLabel variant="body02-sm">Sureg</TextLabel>
                <Text variant="body02-sm" data-testid="codigoSureg">
                  {tryGetValueOrDefault([detalheResgate.codigoSureg], '-')} -{' '}
                  {tryGetValueOrDefault([detalheResgate.nomeSureg], '-')}
                </Text>
              </div>
            </Grid.Item>
            <Grid.Item xs={1} lg={1 / 3}>
              <div>
                <TextLabel variant="body02-sm">Matricula</TextLabel>
                <Text variant="body02-sm" data-testid="numeroMatricula">
                  {tryGetValueOrDefault([detalheResgate.numeroMatricula], '-')}
                </Text>
              </div>
            </Grid.Item>
            <Grid.Item xs={1} lg={1 / 3}>
              <div>
                <TextLabel variant="body02-sm">Código Fonte</TextLabel>
                <Text variant="body02-sm" data-testid="codigoFonte">
                  {tryGetValueOrDefault([detalheResgate.codigoFonte], '-')}
                </Text>
              </div>
            </Grid.Item>
            <Grid.Item xs={1} lg={1 / 3}>
              <div>
                <TextLabel variant="body02-sm">Gerado em</TextLabel>
                <Text variant="body02-sm" data-testid="dataGeracao">
                  {tryGetValueOrDefault(
                    [formatarData(detalheResgate.dataGeracao)],
                    '-',
                  )}
                </Text>
              </div>
            </Grid.Item>
            <Grid.Item xs={1} lg={1 / 3}>
              <div>
                <TextLabel variant="body02-sm">Início da Vigência</TextLabel>
                <Text variant="body02-sm" data-testid="dataInicioVigencia">
                  {tryGetValueOrDefault(
                    [formatarData(detalheResgate.dataInicioVigencia)],
                    '-',
                  )}
                </Text>
              </div>
            </Grid.Item>
            <Grid.Item xs={1} lg={1 / 3}>
              <div>
                <TextLabel variant="body02-sm">Código do Certificado</TextLabel>
                <Text variant="body02-sm" data-testid="codigoCertificado">
                  {tryGetValueOrDefault(
                    [detalheResgate.codigoCertificado],
                    '-',
                  )}
                </Text>
              </div>
            </Grid.Item>
            <Grid.Item xs={1} lg={1 / 3}>
              <div>
                <TextLabel variant="body02-sm">Número da Proposta</TextLabel>
                <Text variant="body02-sm" data-testid="numeroProposta">
                  {tryGetValueOrDefault([detalheResgate.numeroProposta], '-')}
                </Text>
              </div>
            </Grid.Item>
            <Grid.Item xs={1} lg={1 / 3}>
              <div>
                <TextLabel variant="body02-sm">Tipo de Resgate</TextLabel>
                <Text variant="body02-sm" data-testid="tipoResgate">
                  {tryGetValueOrDefault([detalheResgate.tipoResgate], '-')}
                </Text>
              </div>
            </Grid.Item>
            <Grid.Item xs={1} lg={1 / 3}>
              <div>
                <TextLabel variant="body02-sm">Valor do Resgate</TextLabel>
                <Text variant="body02-sm" data-testid="valorResgate">
                  {tryGetValueOrDefault(
                    [
                      formatarValorPadraoBrasileiro(
                        detalheResgate.valorResgate,
                      ),
                    ],
                    '-',
                  )}
                </Text>
              </div>
            </Grid.Item>
          </Grid>
          <Button className="hide-print" variant="primary" onClick={imprimir}>
            Imprimir
          </Button>
        </Display>
      </S.Modal>
    </div>
  );
};

export default ModalDetalhesResgate;
