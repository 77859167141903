import { Button as ButtonDS } from '@cvp/design-system/react';
import styled from 'styled-components';

export const ContainerSkeleton = styled.div`
  div {
    margin: 4px 2px;
  }
`;

export const ConteudoSkeleton = styled.span`
  margin: 6px 2px 2px 2px;
  width: 70%;
`;
export const TituloSkeleton = styled.span`
  margin: 0px 2px;
  width: 70%;
`;

export const StatusContatoSkeleton = styled.span`
  display: flex;
  justify-content: flex-end;
  margin: 0px 2px;
  width: 30%;
`;

export const ButtonSkeleton = styled(ButtonDS)`
  background-color: ${props => props.theme.color.neutral['06']};
  min-height: 34px;
`;
