import { PECOS } from 'previdencia/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import { IResponseDefinirContribuicaoRegular } from '../types/IFundos';
import {
  FundoRegular,
  IPayloadDefinirContribuicao,
} from '../types/IContribuicaoRegular';

const useDefinirContribuicaoRegular = (
  numeroTransferencia?: string,
  fundos?: FundoRegular[],
) => {
  const { response, loading, fetchData } = usePeco<
    IPayloadDefinirContribuicao,
    IResponseDefinirContribuicaoRegular
  >({
    api: {
      operationPath: PECOS.DefinirRegularMultiFundoPj,
    },
    autoFetch: false,
    payload: {
      numeroTransferencia,
      fundos,
    },
  });

  return { response: response?.entidade, loading, fetchData };
};

export default useDefinirContribuicaoRegular;
