import getAuthData from 'main/features/Auth/utils/auth';
import { api, IApiResponse } from 'main/services';
import {
  obterEndpointListarPropostas,
  obterEndpointDetalharProposta,
} from 'contratosPrestamista/config/endpoints';
import { IResponseListaProposta } from '../types/IResponseListaProposta';
import { IResponsePecoDetalharProposta } from '../../../types/IResponsePecoDetalharProposta';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
export const obterListaPropostas = async () => {
  const {
    user: { agenciaVinculada },
  } = getAuthData();
  const resultado = await api.post<IApiResponse<IResponseListaProposta[]>>(
    obterEndpointListarPropostas(),
    {
      numeroAgencia: agenciaVinculada,
      comUpload: false,
    },
  );

  const returnMessage: string | undefined =
    resultado?.data?.dados?.mensagens?.[0].descricao;

  if (resultado?.data?.dados?.sucesso === false) throw new Error(returnMessage);

  return resultado.data.dados.entidade?.map(item => ({
    ...item,
    codigoAgenciaVenda: item.codigoAgenciaVenda.toString(),
  }));
};

export const obterProposta = async (
  numeroProposta: string,
  numeroLinhaDoProduto: string,
  codigoDoEstipulante: string,
) => {
  const resultado = await api.post<IApiResponse<IResponsePecoDetalharProposta>>(
    obterEndpointDetalharProposta(),
    {
      numeroProposta,
      numeroLinhaDoProduto,
      codigoDoEstipulante,
      seqProposta: '0',
    },
  );
  return resultado.data.dados.entidade;
};
