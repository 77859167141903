import { api } from 'main/services/api';
import { IApiResponse } from 'main/services';
import { obterEndPointValorImportanciaSegurada } from 'ferramentas/config/endpoints';
import { IValorImportanciaSegurada } from '../interfaces/IValorImportanciaSegurada';

export const obterValorImportanciaSegurada = async (codCliente: string) => {
  if (codCliente) {
    const { data } = await api.post<IApiResponse<IValorImportanciaSegurada>>(
      obterEndPointValorImportanciaSegurada(),
      {
        cpfCnpjCliente: codCliente,
      },
    );

    if (data?.dados?.sucesso === true) {
      return data?.dados?.entidade;
    }

    return {
      quantidadeContratos: 0,
      valorTotalContratos: 0,
    } as IValorImportanciaSegurada;
  }
  return null;
};
