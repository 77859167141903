/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Redirect, Route as RouteBase } from 'react-router-dom';
import { useAuth } from 'main/features/Auth/hooks/useAuth';
import { IRouteProps } from 'main/components/Route/IRouteProps';
import RouteContext from 'main/contexts/RouteContext';
import AppPrivateLayout from 'main/components/Layout/AppPrivateLayout';
import AppBreadcrumb from 'main/components/Layout/AppBreadcrumb';
import { useFeatureAuthorizer } from 'main/features/Auth/hooks/useFeatureAuthorizer';

export interface IPrivateRoute extends IRouteProps {
  requiredRoles?: string[];
  requiredPermissions?: string[];
}

const PrivateRoute: React.FunctionComponent<IPrivateRoute> = ({
  component: Component,
  authenticated = true,
  requiredRoles = [],
  requiredPermissions = [],
  ...rest
}) => {
  const { breadcrumbs } = useContext(RouteContext);
  const { user, isMarcadorControleValid, userRoles, userPermissions } =
    useAuth();
  const { verifyRoles, verifyPermissions } = useFeatureAuthorizer();

  const isUserLogged = !!user && isMarcadorControleValid() && authenticated;

  return (
    <RouteBase
      {...rest}
      render={props => {
        if (isUserLogged) {
          if (requiredRoles?.length > 0) {
            const showByPermission =
              verifyRoles(requiredRoles, userRoles) &&
              verifyPermissions(requiredPermissions, userPermissions);
            if (!showByPermission)
              return (
                <Redirect
                  to={{
                    pathname: '/acesso-negado',
                    state: { from: props.location },
                  }}
                />
              );
          }
          return (
            <AppPrivateLayout>
              <AppBreadcrumb routes={breadcrumbs} />
              <Component {...props} {...rest} />
            </AppPrivateLayout>
          );
        }

        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
