import React, { useState } from 'react';
import { Display, Modal, Button, Text } from '@cvp/design-system/react';
import InputFile, {
  DescriptionFormatsFilesAllowed,
} from 'main/components/form/InputFile';
import useFieldLink from 'main/hooks/useFieldLink';
import {
  filesFormatsAllowed,
  maxFileSizeAllowed,
  requiredFile,
} from 'main/features/Validation/validations';
import { validate } from 'main/features/Validation/utils/validateRules';
import RenderConditional from 'main/components/RenderConditional';
import TextoErroUploadProposta from 'contratosPrestamista/components/TextoErroUploadProposta';
import { IResponseListaProposta } from '../types/IResponseListaProposta';
import { useUploadProposta } from '../hooks/useUploadProposta';
import { ALERTA_FINALIZACAO_UPLOAD_PROPOSTA } from 'contratosPrestamista/constants';

type ModalUploadArquivoProps = {
  open: boolean;
  handleClose(close: boolean): void;
  proposta: IResponseListaProposta;
  dpsFlow?: boolean;
};

const ModalUploadArquivo: React.FunctionComponent<ModalUploadArquivoProps> = ({
  open,
  handleClose,
  proposta,
  dpsFlow = false,
}) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [arquivoProposta, validateArquivoProposta] = useFieldLink<FileList>(
    {} as FileList,
  );
  const { loading, upload } = useUploadProposta();

  const handleCloseModal = () => {
    arquivoProposta.set({ value: {} as FileList, isValid: false });
    handleClose(false);
  };

  const handleUpload = async () => {
    if (validate([validateArquivoProposta])) {
      const arquivo = arquivoProposta.get().value;
      const result = await upload(proposta, arquivo);
      if (result) {
        setShowError(false);
        handleCloseModal();
      } else {
        setShowError(true);
      }
    }
  };

  return (
    <Modal
      show={open}
      onClose={handleCloseModal}
      data-testid="modalUploadArquivo"
    >
      <RenderConditional condition={!showError}>
        <RenderConditional condition={!dpsFlow}>
          <Text variant="body02-sm" margin>
            {ALERTA_FINALIZACAO_UPLOAD_PROPOSTA}
          </Text>
        </RenderConditional>
        <DescriptionFormatsFilesAllowed fileSize="5" />
        {loading ? (
          <Button variant="text" loading />
        ) : (
          <Display justify="center">
            <div>
              <InputFile
                link={arquivoProposta}
                validationRules={[
                  requiredFile(),
                  filesFormatsAllowed(),
                  maxFileSizeAllowed(),
                ]}
              />
              <Display style={{ marginTop: '2rem ' }}>
                <Button
                  type="click"
                  variant="primary"
                  onClick={handleUpload}
                  data-testid="confirmar"
                >
                  Confirmar
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleCloseModal}
                  data-testid="cancelar"
                >
                  Cancelar
                </Button>
              </Display>
            </div>
          </Display>
        )}
      </RenderConditional>
      <RenderConditional condition={showError}>
        <TextoErroUploadProposta />
      </RenderConditional>
    </Modal>
  );
};

export default ModalUploadArquivo;
