import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  getTernaryResult,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { ALIQUOTA } from 'previdencia/constants/constants';
import * as REGIME_TRIBUTARIO_TYPES from 'previdencia/features/AlteracaoRegimeTributario/types/AlteracaoRegimeTributario';

const useTabelasAliquotas = ({
  dadosTabela,
  loadingDadosAliquota,
  opcaoRegimeTributario,
}: REGIME_TRIBUTARIO_TYPES.IUseTabelasAliquotas) => {
  const exibirDadosTabelaAliquota = (tipoAliquota = '') => {
    const isAliquotaProgressiva: boolean =
      tipoAliquota === ALIQUOTA.TRIBUTACAO_PROGRESSIVA;

    const isAliquotaRegressiva: boolean =
      tipoAliquota === ALIQUOTA.TRIBUTACAO_REGRESSIVA;

    const totalListaAliquotaProgressiva =
      dadosTabela?.fundosAliquotaProgressivo.length;

    const totalListaAliquotaRegressiva =
      dadosTabela?.fundosAliquotaRegressivo.length;

    const isTabelasAliquota: boolean = checkIfAllItemsAreTrue([
      !loadingDadosAliquota,
      checkIfSomeItemsAreTrue([
        checkIfAllItemsAreTrue([
          isAliquotaProgressiva,
          !!totalListaAliquotaProgressiva,
        ]),
        checkIfAllItemsAreTrue([
          isAliquotaRegressiva,
          !!totalListaAliquotaRegressiva,
        ]),
      ]),
    ]);

    const isOpcaoSelecionada: boolean = checkIfSomeItemsAreTrue([
      opcaoRegimeTributario === tipoAliquota,
      checkIfAllItemsAreTrue([
        tipoAliquota === ALIQUOTA.TRIBUTACAO_PROGRESSIVA,
        !dadosTabela?.fundosAliquotaRegressivo.length,
      ]),
      checkIfAllItemsAreTrue([
        tipoAliquota === ALIQUOTA.TRIBUTACAO_REGRESSIVA,
        !dadosTabela?.fundosAliquotaProgressivo.length,
      ]),
    ]);

    const tituloOpcaoAliquota: string = getTernaryResult(
      tipoAliquota === ALIQUOTA.TRIBUTACAO_PROGRESSIVA,
      ALIQUOTA.PROGRESSIVO,
      ALIQUOTA.REGRESSIVO,
    );

    const dadosTabelaAliquota = tryGetValueOrDefault(
      [
        getTernaryResult(
          tipoAliquota === ALIQUOTA.TRIBUTACAO_PROGRESSIVA,
          dadosTabela?.fundosAliquotaProgressivo,
          dadosTabela?.fundosAliquotaRegressivo,
        ),
      ],
      [],
    );

    const isErroDadosAliquotas: boolean = checkIfAllItemsAreTrue([
      !loadingDadosAliquota,
      !dadosTabela?.fundosAliquotaProgressivo.length,
      !dadosTabela?.fundosAliquotaRegressivo.length,
    ]);

    return {
      isTabelasAliquota,
      isOpcaoSelecionada,
      tituloOpcaoAliquota,
      dadosTabelaAliquota,
      isErroDadosAliquotas,
    };
  };

  return { exibirDadosTabelaAliquota };
};

export default useTabelasAliquotas;
