import { Modal } from '@cvp/design-system/react';
import React, { useMemo, useState } from 'react';
import FormFeedback from 'contratosPrestamista/features/cancelamento/components/FormFeedback';
import ReversaoCancelamentoContext from 'contratosPrestamista/features/cancelamento/contexts/ReversaoCancelamentoContext';
import {
  IModalWorkflow,
  EModalWorkflowStep,
} from 'contratosPrestamista/features/cancelamento/types/IModalWorkflow';
import { IRequestSendCode } from 'contratosPrestamista/features/cancelamento/types/IRequestSendCode';
import FormConfirmReversao from 'contratosPrestamista/features/cancelamento/components/FormConfirmReversao';
import FormSendCode from 'contratosPrestamista/features/cancelamento/components/FormSendCode';
import FormValidateCode from 'contratosPrestamista/features/cancelamento/components/FormValidateCode';
import { IModalSendAndValidateCodeProps } from 'contratosPrestamista/features/cancelamento/types/IModalSendAndValidateCodeProps';

const ModalSendAndValidateCode: React.FC<IModalSendAndValidateCodeProps> = ({
  handleClose,
  open,
  numContrato,
}) => {
  const [workflow, handleWorkflow] = useState<IModalWorkflow>({
    step: EModalWorkflowStep.CONFIRM,
  });
  const [formSendCode, setFormSendCode] = useState<IRequestSendCode>(
    {} as IRequestSendCode,
  );
  const [idSolicitacaoToken, setIdSolicitacaoToken] = useState('');
  const contextValue = useMemo(
    () => ({
      formSendCode,
      idSolicitacaoToken,
    }),
    [idSolicitacaoToken, formSendCode],
  );

  const handlerCloseModal = () => {
    handleClose(false);
    handleWorkflow({ step: EModalWorkflowStep.CONFIRM });
  };
  return (
    <Modal
      show={open}
      onClose={handlerCloseModal}
      data-testid="modalReversaoCancelamento"
    >
      <ReversaoCancelamentoContext.Provider value={contextValue}>
        {workflow.step === EModalWorkflowStep.CONFIRM && (
          <FormConfirmReversao
            handleWorkflow={handleWorkflow}
            handlerCancel={handlerCloseModal}
          />
        )}

        {workflow.step === EModalWorkflowStep.SEND && (
          <FormSendCode
            numContrato={numContrato}
            handlerCancel={handlerCloseModal}
            handleWorkflow={handleWorkflow}
            handleIdSolicitacaoToken={setIdSolicitacaoToken}
            setFormSendCode={setFormSendCode}
          />
        )}
        {workflow.step === EModalWorkflowStep.VALIDATE && (
          <FormValidateCode
            numContrato={numContrato}
            idSolicitacaoToken={idSolicitacaoToken}
            handleIdSolicitacaoToken={setIdSolicitacaoToken}
            handleWorkflow={handleWorkflow}
          />
        )}
        {workflow.step === EModalWorkflowStep.FEEDBACK && (
          <FormFeedback mensagem={String(workflow.mensagem)} />
        )}
      </ReversaoCancelamentoContext.Provider>
    </Modal>
  );
};

export default ModalSendAndValidateCode;
