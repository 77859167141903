import { Button, Display, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { AppContext } from 'main/contexts/AppContext';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import TabelaHistoricoSimulacoes from 'previdencia/features/SimulacaoRenda/components/TabelaHistoricoSimulacoes';
import useConsultarHistoricoSimulacaoRenda from 'previdencia/features/SimulacaoRenda/hooks/useConsultarHistoricoSimulacaoRenda';
import React, { useContext, useEffect, useState } from 'react';

import { useConsultarDetalhesHistoricoSimulacao } from 'previdencia/features/SimulacaoRenda/hooks/useConsultarDetalhesHistoricoSimulacao';
import { useSimulacaoRendaContextData } from 'previdencia/features/SimulacaoRenda/hooks/useSimulacaoRendaContextData';
import {
  EnumTipoRendaCodigo,
  EtapasFluxoSimulacaoRenda,
} from 'previdencia/features/SimulacaoRenda/types/enum';
import { IRequestSimularBeneficioApi } from 'previdencia/features/SimulacaoRenda/types/simularBeneficios';

const HistoricoSimulacoes: React.FC = () => {
  const { setEtapaAtual } = useSimulacaoRendaContextData();
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const [exibirDetalhes, setExibirDetalhes] = useState<boolean>(false);
  const { data, isLoading } = useConsultarHistoricoSimulacaoRenda();
  const {
    response,
    loading: loadingDetalhes,
    ObterDetalhesSolicictacao,
  } = useConsultarDetalhesHistoricoSimulacao();

  const handleObterDetalhes = (seqSimulacao: string) => {
    setExibirDetalhes(true);
    ObterDetalhesSolicictacao(seqSimulacao);
  };
  const obterValorPorcentagem = () => {
    return response?.descPctBeneficiarioRecebe.split('%')[0];
  };
  const montarPayload = (): IRequestSimularBeneficioApi => {
    if (
      response?.codTipoPagamento ===
      EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario
    ) {
      return {
        cpf: cpfCnpj,
        numeroCertificado: numCertificado,
        tipoRenda: response.codTipoPagamento,
        dthNascimentoConjuge: response?.dthNascimentoConjuge,
        staGeneroConjuge: response?.staGeneroConjuge?.substring(0, 1),
        pctReversao: obterValorPorcentagem(),
        cpfBeneficiario: '0',
      };
    }
    if (
      checkIfSomeItemsAreTrue([
        response?.codTipoPagamento === EnumTipoRendaCodigo.Temporaria,
        response?.codTipoPagamento ===
          EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido,
        response?.codTipoPagamento === EnumTipoRendaCodigo.PrazoCerto,
      ])
    ) {
      return {
        cpf: cpfCnpj,
        numeroCertificado: numCertificado,
        tipoRenda: response?.codTipoPagamento,
        qtdPrazoAnos: response?.qtdPrazoAnos,
      };
    }
    return {
      cpf: cpfCnpj,
      numeroCertificado: numCertificado,
      tipoRenda: response?.codTipoPagamento,
    };
  };

  useEffect(() => {
    if (checkIfAllItemsAreTrue([!loadingDetalhes, !!response])) {
      setEtapaAtual(
        EtapasFluxoSimulacaoRenda.RESULTADO_SIMULACAO,
        montarPayload(),
      );
    }
  }, [response, loadingDetalhes]);

  if (checkIfSomeItemsAreTrue([loadingDetalhes, isLoading])) {
    return <SkeletonLoading blocks={1} />;
  }

  return (
    <>
      <RenderConditional condition={!exibirDetalhes}>
        <Text variant="body02-md" color="primary" margin>
          Histórico de Simulações
        </Text>
        <Text variant="body02-sm" margin>
          Veja as simulações recentes deste certificado:
        </Text>
        <TabelaHistoricoSimulacoes
          dados={data}
          obterDetalhes={handleObterDetalhes}
        />
        <Display>
          <Button
            variant="outlined"
            onClick={() =>
              setEtapaAtual(EtapasFluxoSimulacaoRenda.INICIO_SIMULACAO)
            }
          >
            Efetuar Nova Simulação
          </Button>
        </Display>
      </RenderConditional>
    </>
  );
};

export default HistoricoSimulacoes;
