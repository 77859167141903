import styled from 'styled-components/macro';
import DataTable from 'react-data-table-component';

export const ButtonAction = styled.button(() => ({
  backgroundColor: 'transparent',
  border: 0,
  margin: 10,
  cursor: 'pointer',
}));

export const WrapperModalidade = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
}));

export const Wrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const Table = styled(DataTable)(({ theme: { color, font } }) => ({
  '.rdt_TableCol, .rdt_TableCell': {
    fontSize: font.size.sm,
    fontFamily: font.family.base,
  },
  '.rdt_TableCol': {
    fontWeight: font.weight.lg,
    '&:hover': {
      color: color.brandPrimary.light,
    },
  },
  '.rdt_TableRow:last-child': {
    background: color.brandPrimary.light,
  },
  '.rdt_TableCell': {
    div: {
      background: 'transparent',
    },
  },
}));

export const CelulaCarteiraUsuario = styled.div<{ contaId: boolean }>(
  ({ theme: { color }, contaId }) => ({
    background: contaId ? color.brandPrimary.light : color.neutral['08'],
    color: contaId ? color.neutral['08'] : color.neutral['01'],
    width: contaId ? '100%' : 'auto',
    height: contaId ? '100%' : 'auto',
    display: contaId ? 'flex' : 'block',
    alignItems: contaId ? 'center' : 'normal',
  }),
);

export const IndicativoCor = styled.div<{ corFundo: string }>(
  ({ corFundo }) => ({
    backgroundColor: `${corFundo}!important`,
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    marginRight: '5px',
    printColorAdjust: 'exact',
  }),
);

export const ContainerCertificado = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
}));
