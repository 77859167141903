import { useContext, useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import * as ComprovanteResgateApi from 'previdencia/services/comprovante.api';
import { AppContext } from 'main/contexts/AppContext';
import { ResponseComprovanteSolicitacao } from '../types/HistoricoSolicitacoes';

const useComprovanteResgate = (
  codigoRequisicao: string,
  idRequisicao: string,
) => {
  const { toastError } = useToast();
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const [data, setData] = useState<ResponseComprovanteSolicitacao>();

  const obterComprovanteHistorico = async () => {
    try {
      const result = await ComprovanteResgateApi.obterComprovanteOperacao(
        cpfCnpj,
        numCertificado,
        idRequisicao,
        codigoRequisicao,
      );
      if (result && result.dados) setData(result.dados.entidade);
    } catch (error: any) {
      toastError(error?.message);
    }
  };

  return {
    data,
    obterComprovanteHistorico,
  };
};
export default useComprovanteResgate;
