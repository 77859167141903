import styled from 'styled-components/macro';
import { TextField } from '@cvp/design-system/react';

export const Input = styled(TextField)(({ theme: { color } }) => ({
  input: {
    background: color.neutral['08'],
  },
}));

export const Container = styled.div(() => ({
  display: 'block',
}));

export const Legend = styled.p<{ isValid: boolean }>(
  ({ theme: { color }, isValid }) => ({
    color: isValid ? color.neutral['03'] : color.feedback.error,
    fontSize: '11px',
  }),
);
