import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { useAuth } from 'main/features/Auth/hooks';
import masks from 'main/utils/masks';
import ConsultaPeriodoResgates from 'previdencia/features/Resgate/components/ConsultaPeriodoResgates';
import ListaResgates from 'previdencia/features/Resgate/components/ListaResgates';
import ModalDetalhesResgate from 'previdencia/features/Resgate/components/ModalDetalhesResgate';
import { useRelatorioResgate } from 'previdencia/features/Resgate/hooks/useRelatorioResgate';
import * as REQUEST_TYPES from 'previdencia/features/Resgate/types/RelatorioResgateRequest';
import * as RESPONSE_TYPES from 'previdencia/features/Resgate/types/RelatorioResgateResponse';
import { useCallback, useState } from 'react';
import * as S from './styles';

const Resgates = () => {
  const { user } = useAuth();
  const { response, loading, fetchData } = useRelatorioResgate();
  const [exibirLista, setExibirLista] = useState<boolean>(false);
  const [detalhe, setDetalhe] = useState<
    RESPONSE_TYPES.IRelatorioResgateResponse | undefined
  >(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const handleSearchSubmit = useCallback(
    (values: REQUEST_TYPES.IRelatorioResgatePayload) => {
      fetchData(values);
    },
    [fetchData],
  );

  const exibirDetalhe = useCallback(
    (data: RESPONSE_TYPES.IRelatorioResgateResponse) => {
      setDetalhe(data);
      setOpen(true);
    },
    [],
  );

  return (
    <>
      <S.Card style={{ minHeight: 'auto' }}>
        <S.CardContent>
          <ConsultaPeriodoResgates
            bloquearForm={exibirLista}
            codAgencia={masks.agencyCode.mask(
              user?.agenciaVinculada.toString(),
            )}
            dataInicio=""
            dataFim=""
            handleSearchSubmit={values => {
              setExibirLista(true);
              handleSearchSubmit(values);
            }}
          />
          <RenderConditional condition={exibirLista}>
            <ListaResgates
              listaResgates={response?.entidade}
              loading={loading}
              voltar={() => setExibirLista(false)}
              exibirDetalhe={exibirDetalhe}
            />
          </RenderConditional>
        </S.CardContent>
      </S.Card>
      <ModalDetalhesResgate
        detalhe={detalhe}
        open={open}
        close={() => setOpen(false)}
      />
    </>
  );
};

export default Resgates;
