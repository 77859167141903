import React from 'react';
import { Card, Display, Grid, Hidden, Text } from '@cvp/design-system/react';
import ilustracao from 'assets/img/ilustracoes/prev/milton.svg';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { PREV_PERMISSIONS } from 'main/features/Auth/config/userProfiles';
import { formatarData } from 'main/utils';
import {
  checkIfAllItemsAreTrue,
  getTernaryResult,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import masks from 'main/utils/masks';
import { InfoGridItem } from 'previdencia/components/PrevidenciaResumo/InfoGridItem/InfoGridItem';
import * as S from 'previdencia/components/PrevidenciaResumo/styles';
import TagStatusDocumento from 'previdencia/components/TagStatusDocumento';
import { TEXTO_STATUS_T } from 'previdencia/constants/texts';
import usePrevSummary from 'previdencia/hooks/usePrevSummary';
import { statusCertificadoMapper } from 'previdencia/utils/previdenciaMappers';

const PrevidenciaResumo: React.FC = () => {
  const { informacaoPorCertificado, isLoading, id } = usePrevSummary();
  const statusCertificado =
    informacaoPorCertificado?.situacao === undefined
      ? ''
      : informacaoPorCertificado?.situacao;

  if (isLoading) {
    return (
      <Display>
        <SkeletonLoading lines={3} />
      </Display>
    );
  }
  const modalidadeRegine = `${tryGetValueOrDefault(
    [informacaoPorCertificado?.produto.modalidade],
    '',
  )} / ${tryGetValueOrDefault(
    [informacaoPorCertificado?.regimeTributario],
    '',
  )}`;

  const labelCertificado = getTernaryResult<string>(
    Boolean(informacaoPorCertificado?.produto.classificacaoDoPlano),
    `Certificado ${informacaoPorCertificado?.produto.classificacaoDoPlano}`,
    'Certificado',
  );

  return (
    <Card>
      <Card.Content className="hide-print" padding={[4, 4, 4]}>
        <Grid>
          <Grid.Item xs={1}>
            <Display alignItems="center">
              <Text variant="headline-05" color="primary">
                {informacaoPorCertificado?.produto.descricaoProduto}
              </Text>
              <TagStatusDocumento status={statusCertificado} />
            </Display>
            <RenderConditional
              condition={checkIfAllItemsAreTrue([
                statusCertificado === 'T',
                !!PREV_PERMISSIONS.INFORMACOES_DO_PARTICIPANTE,
              ])}
            >
              <S.WrapperStatusT>
                <Text variant="body02-sm" color="error">
                  Atenção!
                  <br />
                  {TEXTO_STATUS_T}
                </Text>
              </S.WrapperStatusT>
            </RenderConditional>
          </Grid.Item>
          <Hidden only={['xs', 'sm', 'md', 'lg']}>
            <Grid.Item xl={1 / 10}>
              <Display
                justify="center"
                alignItems="center"
                style={{ height: '100%' }}
              >
                <img src={ilustracao} alt="Ilustração" />
              </Display>
            </Grid.Item>
          </Hidden>
          <Grid.Item lg={9 / 10} md={1}>
            <Display>
              <Grid>
                <InfoGridItem
                  icon="documentPaper"
                  label={labelCertificado}
                  value={tryGetValueOrDefault(
                    [informacaoPorCertificado?.certificadoNumero],
                    '',
                  )}
                />
                <InfoGridItem
                  icon="user"
                  label="Titular"
                  value={tryGetValueOrDefault(
                    [informacaoPorCertificado?.pessoa.pessoaFisica.nome],
                    '',
                  )}
                />
                <InfoGridItem
                  icon="documentFront"
                  label="CPF"
                  value={masks.cpf.mask(id)}
                />
                <InfoGridItem
                  icon="documentBack"
                  label="Modalidade / Regime Tributário"
                  value={modalidadeRegine}
                />
                <InfoGridItem
                  icon="calendar"
                  label="Vigência"
                  value={`${formatarData(
                    informacaoPorCertificado?.abertura,
                  )} - ${formatarData(
                    informacaoPorCertificado?.aposentadoria,
                  )}`}
                />
                <InfoGridItem
                  icon="information"
                  label="Status"
                  value={statusCertificadoMapper(
                    tryGetValueOrDefault(
                      [informacaoPorCertificado?.situacao],
                      '',
                    ),
                  )}
                />
              </Grid>
            </Display>
          </Grid.Item>
        </Grid>
      </Card.Content>
      <Card.Content className="show-print" padding={[2, 2, 2]}>
        <Display alignItems="center">
          <Text variant="headline-05" color="primary">
            {informacaoPorCertificado?.produto.descricaoProduto}
          </Text>
          <TagStatusDocumento status={statusCertificado} />
        </Display>

        <Display justify="center">
          <div>
            <Text variant="caption-02" color="text-light">
              {labelCertificado}
            </Text>
            <Text variant="caption-02" color="text">
              {informacaoPorCertificado?.certificadoNumero}
            </Text>
          </div>

          <div>
            <Text variant="caption-02" color="text-light">
              Titular
            </Text>
            <S.TextClientName
              color="text"
              title={informacaoPorCertificado?.pessoa.pessoaFisica.nome}
            >
              {informacaoPorCertificado?.pessoa.pessoaFisica.nome}
            </S.TextClientName>
          </div>

          <div>
            <Text variant="caption-02" color="text-light">
              CPF
            </Text>
            <Text variant="caption-02" color="text">
              {masks.cpf.mask(id)}
            </Text>
          </div>

          <div>
            <Text variant="caption-02" color="text-light">
              Modalidade / Regime Tributário
            </Text>
            <Text variant="caption-02" color="text">
              {modalidadeRegine}
            </Text>
          </div>
          <div>
            <Text variant="caption-02" color="text-light">
              Vigência
            </Text>
            <Text variant="caption-02" color="text">
              {formatarData(informacaoPorCertificado?.abertura)} -{' '}
              {formatarData(informacaoPorCertificado?.aposentadoria)}
            </Text>
          </div>

          <div>
            <Text variant="caption-02" color="text-light">
              Status
            </Text>
            <Text variant="caption-02" color="text">
              {statusCertificadoMapper(
                informacaoPorCertificado?.situacao ?? '',
              )}
            </Text>
          </div>
        </Display>
      </Card.Content>
    </Card>
  );
};

export default PrevidenciaResumo;
