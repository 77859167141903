import React, { useEffect } from 'react';
import { Card, Display, Grid, Text } from '@cvp/design-system/react';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import DadosConsulta from '../components/DadosConsulta';

const HistoricoSolicitacoes: React.FC = () => {
  const { clearFeatureData } = usePrevidenciaContext();
  useEffect(() => {
    clearFeatureData();
  }, []);

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredPermissions={[PREV_PERMISSIONS.HISTORICO_DE_SOLICITACOES]}
        requiredRoles={[
          ...PRINCIPAL_USERS,
          USER_PROFILES.ANALISTA_CONSULTA,
          USER_PROFILES.ANALISTA_MANUTENCAO,
          USER_PROFILES.ANALISTA_SAIDA,
          USER_PROFILES.ANALISTA_ENTRADA,
        ]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-05" color="primary">
                  Histórico de Solicitações
                </Text>
              </Grid.Item>
              <DadosConsulta />
            </Grid>
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
    </Display>
  );
};

export default HistoricoSolicitacoes;
