import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import {
  IAlicotasAgrupadasPayload,
  IAlicotasAgrupadasResponse,
} from 'previdencia/types/AlicotasAgrupadas';

export const usePecoAlicotasAgrupadas = () => {
  const {
    response: dadosAlicotasAgrupadas,
    loading: loadingDadosAlicotasAgrupadas,
    fetchData: obterAlicotasAgrupadas,
  } = usePeco<Partial<IAlicotasAgrupadasPayload>, IAlicotasAgrupadasResponse[]>(
    {
      api: { operationPath: PECOS.ListarAlicotasAgrupadas },
      handleResponse: { throwToastErrorBFF: false },
    },
  );

  return {
    dadosAlicotasAgrupadas: dadosAlicotasAgrupadas?.entidade,
    loadingDadosAlicotasAgrupadas,
    obterAlicotasAgrupadas,
  };
};
