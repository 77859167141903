import React, { useEffect, useState } from 'react';
import { Card, Display, Text } from '@cvp/design-system/react';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import ComprovanteAcaoContribuicao from '../components/ComprovanteAcaoContribuicao';
import DadosContribuicoes from '../components/DadosContribuicoes';
import InformacoesPlano from '../components/InformacoesPlano';
import { IResponseAcaoCoberturas } from '../types/AcaoCoberturas.types';
import { CoberturaContratada } from '../types/CoberturasContratadas.types';
import { validarCodigoRequisicao } from '../utils/suspensaoContribuicoes';

const SuspensaoContribuicoes: React.FC = () => {
  const [etapaAtual, setEtapaAtual] = useState(0);
  const [dadosContribuicao, setDadosContribuicao] =
    useState<CoberturaContratada>();
  const [dadosComprovante, setDadosComprovante] =
    useState<IResponseAcaoCoberturas>();
  const [codRequisicao, setCodRequisicao] = useState('');

  const primeiraEtapa = () => {
    setEtapaAtual(0);
  };

  const proximaEtapa = () => {
    setEtapaAtual(etapaAtual + 1);
  };

  const voltarEtapa = () => {
    setEtapaAtual(etapaAtual - 1);
  };

  const coletarDados = (dados: CoberturaContratada | undefined) => {
    setDadosContribuicao(dados);
    proximaEtapa();
  };

  const coletarDadosComprovante = (
    dados: IResponseAcaoCoberturas | undefined,
  ) => {
    setDadosComprovante(dados);
    proximaEtapa();
  };

  useEffect(() => {
    setCodRequisicao(validarCodigoRequisicao(dadosContribuicao));
  }, [dadosContribuicao, dadosComprovante]);
  const fluxosTela = [
    <DadosContribuicoes mudarEtapa={coletarDados} />,
    <InformacoesPlano
      proximaEtapa={coletarDadosComprovante}
      etapaAnterior={voltarEtapa}
      dadosPlano={dadosContribuicao}
    />,
    <ComprovanteAcaoContribuicao
      dadosComprovante={dadosComprovante}
      dadosPlano={dadosContribuicao}
      primeiraEtapa={primeiraEtapa}
      codRequisicao={codRequisicao}
    />,
  ];

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredRoles={[...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO]}
        requiredPermissions={[
          PREV_PERMISSIONS.ATIVACAO_E_SUSPENSAO_DE_CONTRIBUICOES,
        ]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Text variant="headline-05" color="primary" margin>
              Ativação/Suspensão de Contribuições
            </Text>
            {fluxosTela[etapaAtual]}
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
    </Display>
  );
};

export default SuspensaoContribuicoes;
