/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
import { Breadcrumb, Display } from '@cvp/design-system/react';

/**
 * Componente cria breadcrumbs automático baseado no roteamento.
 * Caso seja necessário personalizar os nomes dos breadcrumbs, passe o parametro routes
 * @param routes
 * JSON com chave (path) e valor (nome da rota)
 * @returns React.FunctionComponent
 */
const AppBreadcrumb: React.FunctionComponent<{ routes?: unknown }> = ({
  routes,
}) => {
  const history = useHistory();

  return (
    <Display data-testid="breadcrumb-container" className="hide-print">
      <Breadcrumbs
        mappedRoutes={routes}
        WrapperComponent={(breadProps: any) => (
          <Breadcrumb>{breadProps.children}</Breadcrumb>
        )}
        LinkComponent={(breadProps: any) => (
          <Breadcrumb.Item
            data-testid="breadcrumb-link"
            onClick={() => history.push(breadProps.to)}
            value={breadProps.children}
          />
        )}
        ActiveLinkComponent={(breadProps: any) => (
          <Breadcrumb.Item
            data-testid="breadcrumb-active"
            value={breadProps.children}
          />
        )}
      />
    </Display>
  );
};

AppBreadcrumb.defaultProps = {
  routes: {},
};

export default AppBreadcrumb;
