import RenderConditional from 'main/components/RenderConditional';
import { useDetalharOcorrencia } from 'registroOcorrenciaASC/features/consultarOcorrencia/hooks';
import CardAdicionarComplemento from 'registroOcorrenciaASC/features/consultarOcorrencia/components/CardAdicionarComplemento/CardAdicionarComplemento';
import CardAnexos from 'registroOcorrenciaASC/features/consultarOcorrencia/components/CardAnexos/CardAnexos';
import CardComplementos from 'registroOcorrenciaASC/features/consultarOcorrencia/components/CardComplementos/CardComplementos';
import CardDetalhesOcorrencia from 'registroOcorrenciaASC/features/consultarOcorrencia/components/CardDetalhesOcorrencia/CardDetalhesOcorrencia';
import CardConclusao from 'registroOcorrenciaASC/features/consultarOcorrencia/components/CardConclusao/';

const DetalhesOcorrencia = () => {
  const {
    formik,
    arquivoOcorrencia,
    dadosDetalhesOcorrencia,
    adicionarComplemento,
    handleDataTextarea,
    baixarAnexo,
    exibirBotaoComplementar,
    exibirCardStatusConclusao,
    habilitarBtnAddComplemento,
    loadingDadosDetalhesOcorrencia,
    loadingDadosAnexos,
    isReconsultarFop223,
    loadingConsultaDocumentoAssinadoFop223,
    reconsultarFop223,
  } = useDetalharOcorrencia();

  return (
    <>
      <CardDetalhesOcorrencia
        loadingDadosDetalhesOcorrencia={loadingDadosDetalhesOcorrencia}
        dadosDetalhesOcorrencia={dadosDetalhesOcorrencia}
      />

      <CardComplementos
        loadingDadosDetalhesOcorrencia={loadingDadosDetalhesOcorrencia}
        dadosDetalhesOcorrencia={dadosDetalhesOcorrencia}
      />

      <CardAnexos
        loadingDadosAnexos={loadingDadosAnexos}
        loadingDadosDetalhesOcorrencia={loadingDadosDetalhesOcorrencia}
        dadosDetalhesOcorrencia={dadosDetalhesOcorrencia}
        baixarAnexo={baixarAnexo}
        isReconsultarFop223={isReconsultarFop223}
        loadingConsultaDocumentoAssinadoFop223={
          loadingConsultaDocumentoAssinadoFop223
        }
        reconsultarFop223={reconsultarFop223}
      />

      <RenderConditional condition={!exibirCardStatusConclusao()}>
        <CardAdicionarComplemento
          formikDetalhesOcorrencia={formik}
          arquivoOcorrencia={arquivoOcorrencia}
          handleDataTextarea={handleDataTextarea}
          adicionarComplemento={adicionarComplemento}
          habilitarBtnAddComplemento={habilitarBtnAddComplemento}
          exibirBotaoComplementar={exibirBotaoComplementar}
        />
      </RenderConditional>

      <RenderConditional condition={exibirCardStatusConclusao()}>
        <CardConclusao
          loadingDadosDetalhesOcorrencia={loadingDadosDetalhesOcorrencia}
          dadosDetalhesOcorrencia={dadosDetalhesOcorrencia}
        />
      </RenderConditional>
    </>
  );
};

export default DetalhesOcorrencia;
