import styled from 'styled-components/macro';

export const SelectRange = styled.div(({ theme: { breakpoint } }) => ({
  margin: '15px 0 60px',
  gap: '20px',

  [breakpoint.lg()]: {
    flexDirection: 'row',

    button: {
      alignSelf: 'flex-end',
      bottom: '8px',
    },
  },
}));
