import React from 'react';
import { Text, Grid, Display, Button, Divider } from '@cvp/design-system/react';

import { getTernaryResult } from 'main/utils/conditional';
import Modal from 'main/components/Modal';
import RenderConditional from 'main/components/RenderConditional';
import masks from 'main/utils/masks';
import useAlteracaoContaDebito from 'previdencia/features/AlteracaoFormaDadosPagamento/hooks/useAlteracaoContaDebito';
import TabelaContasBancarias from 'previdencia/features/AlteracaoFormaDadosPagamento/components/TabelaContasBancarias';
import FormAdicionarConta from 'previdencia/features/AlteracaoFormaDadosPagamento/components/FormAdicionarConta';
import * as CONSTS from 'previdencia/features/AlteracaoFormaDadosPagamento/constants/constants';
import * as ALTERACAO_CONTA_TYPES from 'previdencia/features/AlteracaoFormaDadosPagamento/types/AlteracaoFormaDadosPagamento';

const AlteracaoContaDebito: React.FC<
  ALTERACAO_CONTA_TYPES.AlteracaoContaDebitoProps
> = ({ voltar, dadosResponsavel, dadosBancos, alterar }) => {
  const {
    formik,
    canal,
    obterContaCadastradaSelecionada,
    exibirFormConta,
    toggleFormNovaConta,
    alterarFormaDadosPagamento,
    loadingDadosValidacaoConta,
    desabilitarAlteracaoFormaDadosPagamento,
    openModal,
    setOpenModal,
  } = useAlteracaoContaDebito({ alterar });

  return (
    <>
      <Grid.Item xs={1}>
        <Text variant="body02-md" color="primary">
          Alteração de conta para débito
        </Text>
      </Grid.Item>
      <Grid.Item xs={1}>
        <Text variant="body02-ms" color="text">
          {CONSTS.INFORMACOES_PAGAMENTOS}
        </Text>
      </Grid.Item>
      <RenderConditional condition={!!dadosResponsavel}>
        <Grid.Item xs={1}>
          <Text variant="body02-ms" color="text">
            <strong>CPF do responsável financeiro: </strong>
            {masks.cpf.mask(dadosResponsavel?.cpf)}
          </Text>
          <Text variant="body02-ms" color="text">
            <strong>Nome do responsável financeiro: </strong>
            {dadosResponsavel?.nome}
          </Text>
        </Grid.Item>
      </RenderConditional>
      <Grid.Item xs={1}>
        <Text variant="body01-md" color="text" margin>
          Selecione uma das contas a seguir:
        </Text>
        <TabelaContasBancarias
          dados={dadosBancos}
          canal={canal}
          obterContaCadastradaSelecionada={obterContaCadastradaSelecionada}
        />
      </Grid.Item>
      <Grid.Item xs={1}>
        <Button
          variant={getTernaryResult(exibirFormConta, 'secondary', 'primary')}
          onClick={toggleFormNovaConta}
        >
          {getTernaryResult(
            exibirFormConta,
            CONSTS.BTN_LABEL_FORM.OCULTAR,
            CONSTS.BTN_LABEL_FORM.ADICIONAR,
          )}
        </Button>
        <RenderConditional condition={exibirFormConta}>
          <FormAdicionarConta formik={formik} />
        </RenderConditional>
      </Grid.Item>
      <Divider />
      <Grid.Item xs={1}>
        <Display>
          <Button variant="outlined" onClick={voltar}>
            Voltar
          </Button>
          <Button
            onClick={alterarFormaDadosPagamento}
            loading={loadingDadosValidacaoConta}
            disabled={desabilitarAlteracaoFormaDadosPagamento}
          >
            Alterar
          </Button>
        </Display>
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Text variant="body01-md" color="text">
            {CONSTS.NAO_INCLUIU_CONTA_BANCARIA_ERRO}
          </Text>
        </Modal>
      </Grid.Item>
    </>
  );
};

export default AlteracaoContaDebito;
