import styled from 'styled-components/macro';
import DataTable from 'react-data-table-component';

export const Table = styled(DataTable)(({ theme: { color, font } }) => ({
  '.rdt_TableCol, .rdt_TableCell': {
    fontSize: font.size.sm,
    fontFamily: font.family.base,
  },
  '.rdt_TableCol': {
    fontWeight: font.weight.lg,
    '&:hover': {
      color: color.brandPrimary.light,
    },
  },
}));
