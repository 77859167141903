import { PECOS_AREA_LOGADA } from 'atendimento/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import {
  IRequestConsultarClienteAL,
  IResponseConsultarClienteAL,
} from '../types/IResponseConsultarClienteAL';

export const useConsultarClienteAreaLogada = (numCpfCnpj: string) => {
  const { loading, response, fetchData } = usePeco<
    IRequestConsultarClienteAL,
    IResponseConsultarClienteAL
  >({
    api: { operationPath: PECOS_AREA_LOGADA.ConsultarCliente },
    payload: {
      numCpf: numCpfCnpj,
    },
  });

  return {
    fetchData,
    loading,
    response,
  };
};
