import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { IResponseComprovanteOperacao } from 'previdencia/types/ResponseComprovante';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import {
  IPayloadComprovante,
  IPayloadComprovantePeco,
} from '../types/Comprovante.types';

const useObterComprovanteAtivacaoSuspensao = (
  request: IPayloadComprovante | undefined,
) => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const { loading, response, fetchData } = usePeco<
    IPayloadComprovantePeco,
    IResponseComprovanteOperacao
  >({
    api: { operationPath: PECOS.ObterComprovanteResgate },
    autoFetch: false,
    payload: {
      cpfCnpj,
      NumeroCertificado: numCertificado,
      ...request,
    },
  });

  return { loading, response, fetchData };
};
export default useObterComprovanteAtivacaoSuspensao;
