import { obterEndpointTiposSolicitacoes } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { ResponseTiposSolicitacoes } from '../types/HistoricoSolicitacoes';

export const obterTiposSolicitacoes = async (
  cpfCnpj: string,
  numeroCertificado: string,
): Promise<ResponseTiposSolicitacoes[] | undefined> => {
  const { data } = await api.post<
    IApiResponse<ResponseTiposSolicitacoes[] | undefined>
  >(obterEndpointTiposSolicitacoes(), {
    cpfCnpj,
    numeroCertificado,
  });

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data?.dados?.entidade;
};
