import styled from 'styled-components/macro';

export const ProgressStatusVendasContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProgressStatusVendas = styled.div.attrs((props: any) => ({
  width: props.width,
}))`
  width: ${props => (props.width ? props.width : 0)}px;
  background: ${props => props.theme.color.brandTertiary.dark};
  margin-right: 5px;
  height: 10px;
`;

export const ProgressStatusVendasValue = styled.span(
  ({ theme: { color } }) => ({
    color: color.brandTertiary.dark,
  }),
);
