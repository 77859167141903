import React from 'react';
import { Text } from '@cvp/design-system/react';
import { NIVEL_RISCO_TEXTO } from '../../constants/constants';

type TextoGraficosType = {
  data: string;
};
const TextoGraficos: React.FC<TextoGraficosType> = ({ data }) => {
  return (
    <>
      <Text variant="body02-sm" color="text" margin>
        Composição de {data}
      </Text>
      <Text variant="body02-sm" color="text" margin>
        {NIVEL_RISCO_TEXTO}
      </Text>
      <Text variant="body02-sm" color="text" margin>
        ** Média ponderada.
      </Text>
    </>
  );
};

export default TextoGraficos;
