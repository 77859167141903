import { obterEndpointConsultarConsentimento } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { ResponseConsultarConsentimento } from '../types/DadosParticipante';

export const consultarConsentimento = async (
  cpf: string,
  numeroCertificado: string,
  subProcesso: string,
): Promise<ResponseConsultarConsentimento | undefined> => {
  const { data } = await api.post<IApiResponse<undefined>>(
    obterEndpointConsultarConsentimento(),
    {
      cpf,
      numeroCertificado,
      numConsentimentoNegocio: '40',
      macroProcesso: '2',
      subProcesso,
    },
  );

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data?.dados?.entidade;
};
