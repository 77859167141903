import { Switch } from 'react-router-dom';
import PrivateRoute from 'main/components/Route';
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import { IPrivateRoute } from 'main/components/Route/PrivateRoute';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import DashboardUploadPrestamista from 'relatorios/features/powerbi/pages/DashboardUploadPrestamista';
import DashboardCancelamentoPrestamista from 'relatorios/features/powerbi/pages/DashboardCancelamentoPrestamista';

const routes: IPrivateRoute[] = [
  {
    path: '/relatorios/prestamista/upload',
    exact: true,
    component: DashboardUploadPrestamista,
    key: 'relatorios-upload-upload',
    authenticated: true,
    breadcrumb: 'Dashboard Upload Prestamista',
    requiredRoles: PRINCIPAL_USERS,
  },
  {
    path: '/relatorios/prestamista/cancelamento',
    exact: true,
    component: DashboardCancelamentoPrestamista,
    key: 'relatorios-cancelamento-prestamista',
    authenticated: true,
    breadcrumb: 'Dashboard Retenção de Prestamista',
    requiredRoles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
  },
];

/**
 * Utilizar caso seja necessário personalizar o nome dos breadcrumbs
 */
export const relatoriosBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
  '/relatorios': null,
  '/relatorios/prestamista': null,
};

const RotasRelatorios = (): React.ReactElement => (
  <Switch>
    {routes.map(route => (
      <PrivateRoute
        key={route.key}
        path={route.path}
        exact={route.exact}
        component={route.component}
        authenticated={route.authenticated}
        requiredRoles={route.requiredRoles}
      />
    ))}
  </Switch>
);

export default RotasRelatorios;
