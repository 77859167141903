import React, { useState } from 'react';
import { Hidden } from '@cvp/design-system/react';
import * as S from './styles';

type LinhaDigitavelBoletoProps = {
  linhaDigitavel: string;
};

const LinhaDigitavelBoleto: React.FC<LinhaDigitavelBoletoProps> = ({
  linhaDigitavel,
}) => {
  const [textoCopiado, setTextoCopiado] = useState(false);

  return (
    <S.LinhaDigitavel
      aria-label="copiar"
      onClick={() => {
        navigator.clipboard.writeText(linhaDigitavel);
        setTextoCopiado(true);
      }}
    >
      <h5>{linhaDigitavel}</h5>
      <Hidden only={['xs', 'sm']}>
        {(textoCopiado && <S.LabelCopy>Copiado!!</S.LabelCopy>) || (
          <S.LabelNotCopied>Não copiado!</S.LabelNotCopied>
        )}
      </Hidden>
    </S.LinhaDigitavel>
  );
};

export default LinhaDigitavelBoleto;
