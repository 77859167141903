import { toastError } from 'main/hooks/useToast';
import * as ConfirmarTransferenciaApi from 'previdencia/features/TransferenciaFundoInvestimento/services/confirmarTransferencia.api';
import {
  ISolicitarAssinaturaRequest,
  ISolicitarAssinaturaResponse,
} from 'previdencia/features/TransferenciaFundoInvestimento/types/ISolicitarAssinaturaRequest';
import { useState } from 'react';

const useSolicitarAssinatura = () => {
  const [resultadoAssinatura, setResultadoAssinatura] = useState(
    {} as ISolicitarAssinaturaResponse | undefined,
  );
  const [loadingSolicitarAssinatura, setLoadingSolicitarAssinatura] =
    useState<boolean>(false);
  const solicitarAssinatura = async (data: ISolicitarAssinaturaRequest) => {
    try {
      setLoadingSolicitarAssinatura(true);
      const result = await ConfirmarTransferenciaApi.solicitarAssinatura(data);
      setResultadoAssinatura(result);
      return result;
    } catch (error) {
      toastError();
    } finally {
      setLoadingSolicitarAssinatura(false);
    }
    return undefined;
  };
  return {
    loadingSolicitarAssinatura,
    resultadoAssinatura,
    solicitarAssinatura,
  };
};
export default useSolicitarAssinatura;
