import { Text } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';
import { ContainerInputHelper } from 'previdencia/features/SolicitacaoResgate/pages/styles';

const ColunaSaldoTotal = ({
  row,
  isTipoResgateParcial,
}: SOLICITACAO_RESGATE_TYPES.IColunaSaldoTotalProps) => {
  return (
    <ContainerInputHelper>
      <Text variant="body02-md">
        {tryGetMonetaryValueOrDefault(row.saldoTotal)}
      </Text>
      <RenderConditional condition={isTipoResgateParcial}>
        <Text variant="caption-02">
          {CONSTS.TEXTOS_COLUNA_SALDO_TOTAL.SALDO_MINIMO}{' '}
          {tryGetMonetaryValueOrDefault(row.valorMinimoPermanencia)}
        </Text>
      </RenderConditional>
    </ContainerInputHelper>
  );
};

export default ColunaSaldoTotal;
