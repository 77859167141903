import { obterEndpointCertificadosPorCpf } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { ResponseDadosCertificadosPorCpf } from '../types/DadosCertificado';

export const obterDadosCpfPorCertificado = async (
  cpfCnpj?: string,
): Promise<ResponseDadosCertificadosPorCpf[] | undefined> => {
  if (!cpfCnpj) return undefined;
  const { data } = await api.post<
    IApiResponse<ResponseDadosCertificadosPorCpf[] | undefined>
  >(obterEndpointCertificadosPorCpf(), {
    cpfCnpj,
  });

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data?.dados?.entidade;
};
