import styled from 'styled-components/macro';
import DataTable from 'react-data-table-component';
import RiskBar from 'previdencia/components/RiskBar';
import * as TRANSF_VGBL_STYLES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/styles';

export const RiskBarFundos = styled(RiskBar)(() => ({
  minHeight: '60px',
  minWidth: '100px',
}));

export const TabelaFundosInvestimento = styled(DataTable)(
  ({ theme: { color, font } }) => ({
    '.rdt_TableCell': {
      justifyContent: 'center',
      textAlign: 'center',
      padding: '10px 0',
    },
    '.rdt_TableCol': {
      fontSize: font.size.md,
      fontFamily: font.family.base,
      background: color.neutral['06'],
    },
  }),
);

export const TextoResumoTabela = styled(TRANSF_VGBL_STYLES.Text)(
  ({ theme: { color } }) => ({
    borderBottom: `1px solid ${color.neutral['01']}`,
  }),
);
