import { useContext, useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import { ResponseRevalidarTransferencia } from '../types/RevalidarTransferencia.types';
import * as RevalidarTransferenciaApi from '../services/revalidarTransferencia.api';

const useRevalidarTransferencia = () => {
  const { toastError } = useToast();
  const [loadingRevalidarTransferencia, setLoading] = useState<boolean>(false);
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);
  const [dataRevalidarTransferencia, setDataRevalidarTransferencia] = useState<
    ResponseRevalidarTransferencia | undefined
  >();
  const revalidarTransferencia = async (
    numTransferencia: string | undefined,
  ) => {
    try {
      setLoading(true);
      const result = await RevalidarTransferenciaApi.revalidarTransferencia(
        cpfCnpj,
        numTransferencia,
      );
      setDataRevalidarTransferencia(result);
    } catch (requestError: any) {
      toastError(requestError.message);
      const resultErro: ResponseRevalidarTransferencia = {
        numTransferencia: numTransferencia || '',
        pendenciasRevalidarTransferencia: [],
        sta: 'ERR',
        desSta: requestError.message,
      };
      setDataRevalidarTransferencia(resultErro);
    } finally {
      setLoading(false);
    }
  };
  return {
    loadingRevalidarTransferencia,
    dataRevalidarTransferencia,
    revalidarTransferencia,
  };
};

export default useRevalidarTransferencia;
