import { PECOS_PRESTAMISTA } from 'contratosPrestamista/config/endpoints';
import { IRequestPecoDetalharProposta } from 'contratosPrestamista/types/IRequestPecoDetalharProposta';
import { IResponsePecoDetalharProposta } from 'contratosPrestamista/types/IResponsePecoDetalharProposta';
import { usePeco } from 'main/hooks/usePeco';

export const usePecoDetalharPropostas = (
  entrada: IRequestPecoDetalharProposta,
) => {
  const {
    response: responseDetalharProposta,
    loading: loadingDetalharProposta,
  } = usePeco<IRequestPecoDetalharProposta, IResponsePecoDetalharProposta>({
    api: {
      operationPath: PECOS_PRESTAMISTA.DetalharPropostas,
    },
    payload: entrada,
    autoFetch: true,
  });

  return { responseDetalharProposta, loadingDetalharProposta };
};
