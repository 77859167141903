import React, { useEffect } from 'react';
import { Display, Card, Text, Grid, Divider } from '@cvp/design-system/react';
import { LinkFop } from 'main/styles/GlobalStyle';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { useFormFops } from 'extranet/hooks/useFormFops';
import { Button } from 'main/components/Header/styles';
import { IResponseObterListaFopsAtivos } from 'main/features/Administracao/types/IFops';

const Simuladores: React.FC = () => {
  const {
    loadingFops,
    responseFops,
    loadingDownloadArquivo,
    setLoadingDownloadArquivo,
    baixarArquivoFop,
  } = useFormFops();

  if (loadingFops) {
    return <SkeletonLoading blocks={1} />;
  }

  return (
    <Display type="display-block">
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Grid>
            <Grid.Item xs={1}>
              <Text
                variant="headline-05"
                color="primary"
                key="formulario-titulo"
              >
                Simuladores
              </Text>
            </Grid.Item>
          </Grid>
          <Divider />
          {loadingDownloadArquivo && <Button variant="text" loading />}

          {!loadingDownloadArquivo &&
            tryGetValueOrDefault(
              [responseFops?.entidade],
              [] as IResponseObterListaFopsAtivos[],
            )
              .filter(
                el =>
                  el.tipo === 'prev_simulador' &&
                  el.dadosArquivos?.filter(
                    e =>
                      e.indicadorUsoDoArquivo === 'D' &&
                      e.codigoIdentificadorUnico,
                  ),
              )
              .map((item: IResponseObterListaFopsAtivos) => (
                <Text variant="body01-sm" color="primary" margin>
                  <LinkFop
                    variant="text"
                    onClick={() => {
                      baixarArquivoFop(
                        item.dadosArquivos
                          ? item.dadosArquivos[0]?.codigoIdentificadorUnico
                          : '00000000',
                        item.dadosArquivos
                          ? item.dadosArquivos[0]?.nomeArquivo
                          : 'Planilha_teste.html',
                      );
                      setLoadingDownloadArquivo(true);
                    }}
                  >
                    <b>{item.nome}</b>
                  </LinkFop>
                </Text>
              ))}

          <Text variant="body01-sm" color="primary" margin>
            <LinkFop
              variant="text"
              href="https://www.caixavidaeprevidencia.com.br/previdencia/simulador-beneficio-fiscal"
              target="_blank"
            >
              <b>Simulador de Benefício Fiscal</b>
            </LinkFop>
          </Text>
          <Divider />
        </Card.Content>
      </Card>
    </Display>
  );
};
export default Simuladores;
