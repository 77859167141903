import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Display, Card, Text, Grid, Button } from '@cvp/design-system/react';
import EmailSenderModal from 'main/components/EmailSenderModal';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { AppContext } from 'main/contexts/AppContext';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import useObterDadosPlano from 'previdencia/features/DadosPlano/hooks/useObterDadosPlano';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import {
  IDadosClientePrev,
  IClientePrevidencia,
} from 'previdencia/types/IClientePrevidencia';
import { scrollScreen } from 'main/utils/screen';
import DetalhesPlano from '../components/DetalhesPlano';
import Coberturas from '../components/Coberturas';
import { useEnviarDadosPlanoEmail } from '../hooks/useEnviarDadosPlanoEmail';
import useObterDadosCoberturas from '../hooks/useObterDadosCoberturas';
import { IPagamentoRegular } from '../types';
import usePecoGerarExtrato from '../hooks/usePecoGerarExtrato';

const DadosPlano = (): React.ReactElement => {
  const { cliente }: { cliente: IClientePrevidencia<IDadosClientePrev> } =
    useContext(AppContext);
  const { data: dadosPlano, isLoading: loading } = useObterDadosPlano();
  const { data: coberturas, isLoading } = useObterDadosCoberturas();
  const { goHome } = usePrevNavigation();
  const location = useLocation<Location>();
  const pagamentoRegular = location.state as unknown as IPagamentoRegular;
  const { montarObjetoEmail } = useEnviarDadosPlanoEmail();
  const objetoEmail = montarObjetoEmail();

  const { fetchData } = usePecoGerarExtrato({
    numeroCertificado: tryGetValueOrDefault([cliente?.numCertificado], ''),
    cpfCnpj: tryGetValueOrDefault([cliente?.cpfCnpj], ''),
  });

  const pagamentoRegularDadosCliente: IDadosClientePrev = {
    cerficadosDetalhado: cliente.dados?.cerficadosDetalhado,
    diaVencimento: cliente.dados?.diaVencimento,
    duracao: cliente.dados?.duracao,
    formaPagamento: cliente.dados?.formaPagamento,
  };

  useEffect(() => {
    fetchData();
    scrollScreen(0);
  }, []);

  if (checkIfSomeItemsAreTrue([loading, isLoading])) {
    return <SkeletonLoading blocks={2} />;
  }

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredPermissions={[PREV_PERMISSIONS.INFORMACOES_DO_PARTICIPANTE]}
        requiredRoles={[
          ...PRINCIPAL_USERS,
          USER_PROFILES.ANALISTA_CONSULTA,
          USER_PROFILES.ANALISTA_MANUTENCAO,
          USER_PROFILES.ANALISTA_SAIDA,
          USER_PROFILES.ANALISTA_ENTRADA,
        ]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-05" color="primary" margin>
                  Dados do Plano
                </Text>
                <DetalhesPlano
                  dados={dadosPlano}
                  pagamentoRegular={
                    pagamentoRegular || pagamentoRegularDadosCliente
                  }
                />
                <Coberturas
                  coberturas={coberturas?.retorno?.beneficios}
                  certificados={
                    pagamentoRegular?.cerficadosDetalhado ||
                    pagamentoRegularDadosCliente?.cerficadosDetalhado
                  }
                />
              </Grid.Item>
            </Grid>
            <Display>
              <Button variant="outlined" onClick={goHome}>
                Voltar
              </Button>
              <EmailSenderModal objetoEmail={objetoEmail} />
            </Display>
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
    </Display>
  );
};

export default DadosPlano;
