import { usePeco } from 'main/hooks/usePeco';
import { PECOS_VIDA } from 'seguros/config/endpoints';
import * as REQUEST_TYPES from 'painelPortabilidade/types/ConsultarPortabilidadeRequest';
import * as RESPONSE_TYPES from 'painelPortabilidade/types/ConsultarPortabilidadeResponse';
import { IDadosPortabilidadePayload } from 'painelPortabilidade/types/ConsultarPortabilidadePayload';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { getTimer15Minutos } from 'main/utils';
import { useAuth } from 'main/features/Auth/hooks';

const usePecoConsultarPortabilidade = (
  payload?: IDadosPortabilidadePayload,
) => {
  const { user } = useAuth();
  const {
    response: dadosHierarquiaPortabilidade,
    loading: loadingHierarquiaPortabilidade,
    fetchData: fetchDataHierarquia,
  } = usePeco<
    REQUEST_TYPES.IDadosPortabilidadePayload,
    RESPONSE_TYPES.IDadosPortabilidadeResponse
  >({
    api: { operationPath: PECOS_VIDA.ConsultarPortabilidadeHierarquia },
    autoFetch: true,
    cache: true,
    cacheKey: `${tryGetValueOrDefault([payload?.codigoTipoVisao], '')}`,
    cacheTime: getTimer15Minutos(),
    handleResponse: { throwToastErrorBFF: false },

    payload: {
      codigoTipoVisao: tryGetValueOrDefault(
        [payload?.codigoTipoVisao],
        user?.agenciaVinculada,
      ),
      tipoVisao: tryGetValueOrDefault([payload?.tipoVisao], ''),
    },
  });

  return {
    dadosHierarquiaPortabilidade,
    loadingHierarquiaPortabilidade,
    fetchDataHierarquia,
  };
};

export default usePecoConsultarPortabilidade;
