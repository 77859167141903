import { STRING_SEM_CARACTERES_ESPECIAIS_E_ESPACOS } from './regex';

export const capitalize = (text: string) =>
  text.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

export const withPlaceholder = (text?: string) => text || '--';

export const isNegativeCurrent = (value: string) => {
  const newValue = `${value}`.replace(/[^0-9.-]+/g, '');
  const newNum = newValue.replace(',', '').split('.').join('');
  if (newNum === '') {
    return false;
  }

  const valueParsed = parseFloat(newNum);

  if (Math.sign(valueParsed) === -1) {
    return true;
  }

  return false;
};

export const removeEmptySpace = (value = '') => value?.replace(/\s/g, '');

export const removeEmptySpaceAndSpecialCharacters = (value = '') =>
  value?.replace(STRING_SEM_CARACTERES_ESPECIAIS_E_ESPACOS, '');

export const removeEmptySpaceAndForceUpperCase = (value = '') =>
  removeEmptySpace(value).toUpperCase();

export /**
 * Converte uma string em capitalize, exceto para as preposições fornecidas.
 * @param {string} str - A string a ser convertida.
 * @returns {string} A string convertida em capitalize.
 */
function capitalizeExceptPrepositions(str: string): string {
  const prepositions = [
    'a',
    'ante',
    'após',
    'até',
    'com',
    'contra',
    'de',
    'desde',
    'em',
    'entre',
    'para',
    'per',
    'perante',
    'por',
    'sem',
    'sob',
    'sobre',
    'trás',
    'ou',
  ];

  return str
    .split(' ')
    .map(word =>
      prepositions.includes(word.toLowerCase())
        ? word.toLowerCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    )
    .join(' ');
}
