import { Card, Display, Text } from '@cvp/design-system/react';

import CardVisaoGeral from 'evolucaoPatrimonial/components/CardVisaoGeral';
import Grafico from 'evolucaoPatrimonial/components/grafico/Grafico';
import FiltroGrafico from 'evolucaoPatrimonial/components/FiltroGrafico';
import useEvolucaoPatrimonial from 'evolucaoPatrimonial/hooks/useEvolucaoPatrimonial';
import * as CONSTS from 'evolucaoPatrimonial/constants/constants';

const EvolucaoPatrimonial = () => {
  const {
    formik,
    inputCalendar,
    filtrarEvolucaoPatrimonial,
    loadingConsultaEvolucaoPatrimonial,
    dadosConsulta,
    options,
    series,
  } = useEvolucaoPatrimonial();
  return (
    <Display type="display-block">
      <Card className="hide-print">
        <Card.Content className="hide-print" padding={[4, 4, 4]}>
          <Text variant="headline-05" color="primary" margin>
            Evolução Patrimonial
          </Text>

          <Text variant="body-sm" style={{ marginBottom: 20 }}>
            {CONSTS.DESCRICAO_EVOLUCAO_PATRIMONIAL}
          </Text>

          <FiltroGrafico
            formik={formik}
            inputCalendar={inputCalendar}
            filtrarEvolucaoPatrimonial={filtrarEvolucaoPatrimonial}
            loadingConsultaEvolucaoPatrimonial={
              loadingConsultaEvolucaoPatrimonial
            }
          />

          <Grafico
            dadosConsulta={dadosConsulta}
            loadingConsultaEvolucaoPatrimonial={
              loadingConsultaEvolucaoPatrimonial
            }
            options={options}
            series={series}
          />

          <CardVisaoGeral dadosConsulta={dadosConsulta} />
        </Card.Content>
      </Card>
    </Display>
  );
};

export default EvolucaoPatrimonial;
