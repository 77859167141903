import styled from 'styled-components/macro';
import { Text } from '@cvp/design-system/react';

export const ErrorMessage = styled(Text)({
  margin: '15px 0 50px',
});

export const AlertMessage = styled(Text)({
  margin: '15px 0 7px 0',
});
