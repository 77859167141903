import { Text } from '@cvp/design-system/react';
import React from 'react';

const TextoDadosCliente: React.FC = () => {
  return (
    <Text variant="body01-sm" color="primary-dark">
      Dados do Cliente
    </Text>
  );
};

export default TextoDadosCliente;
