import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import { ordenaDataVencimentoSemConversao } from 'previdencia/utils/previdencia';
import {
  CODIGO_MEIO_PAGAMENTO,
  CODIGO_STATUS_COBRANCA,
  STATUS_COBRANCA_CONVERTIDO,
} from '../constants/constants';
import {
  ContribuicoesConvertido,
  ResponseContribuicoes,
} from '../types/ResponseContribuicoes';

export const coverterDescStatus = (codStatusCobranca: string) => {
  if (
    checkIfSomeItemsAreTrue([
      codStatusCobranca === CODIGO_STATUS_COBRANCA.PAGO,
      codStatusCobranca === CODIGO_STATUS_COBRANCA.PARCIALMENTE_PAGO,
    ])
  ) {
    return STATUS_COBRANCA_CONVERTIDO.PAGA;
  }

  if (codStatusCobranca === CODIGO_STATUS_COBRANCA.INADIMPLENTE) {
    return STATUS_COBRANCA_CONVERTIDO.NAO_PAGO;
  }
  if (
    checkIfSomeItemsAreTrue([
      codStatusCobranca === CODIGO_STATUS_COBRANCA.GERADA,
      codStatusCobranca === CODIGO_STATUS_COBRANCA.COBRADA,
      codStatusCobranca === CODIGO_STATUS_COBRANCA.PENDENTE,
    ])
  ) {
    return STATUS_COBRANCA_CONVERTIDO.PENDENTE;
  }
  if (
    checkIfSomeItemsAreTrue([
      codStatusCobranca === CODIGO_STATUS_COBRANCA.CANCELADA,
      codStatusCobranca === CODIGO_STATUS_COBRANCA.CANCELADA_INADIMPLENCIA,
    ])
  ) {
    return STATUS_COBRANCA_CONVERTIDO.CANCELADA;
  }
  return '';
};
export const validarEmissaoBoleto = (e: ResponseContribuicoes) => {
  if (
    checkIfAllItemsAreTrue([
      e.codStatusCobranca === CODIGO_STATUS_COBRANCA.PENDENTE,
      e.codMeioPagamento === CODIGO_MEIO_PAGAMENTO.BOLETO,
      e.dataDentroLimite === true,
    ])
  ) {
    return 'S';
  }
  return 'N';
};

const removeDuplicados = (a: ResponseContribuicoes[]) => {
  return a.filter(function (item, pos, array) {
    return (
      array

        .map(function (mapItem) {
          return mapItem.numCobranca;
        })
        .indexOf(item.numCobranca) === pos
    );
  });
};

export const converterStatus = (
  data: ResponseContribuicoes[] | undefined,
): ContribuicoesConvertido[] => {
  const statusRetorno: ContribuicoesConvertido[] = [];

  const baixaParcial = data?.filter(
    item => item.codStatusCobranca === CODIGO_STATUS_COBRANCA.PARCIALMENTE_PAGO,
  );

  let dataFiltrada = data?.filter(
    item => item.codStatusCobranca !== CODIGO_STATUS_COBRANCA.PARCIALMENTE_PAGO,
  );

  if (baixaParcial) {
    dataFiltrada = [
      ...(dataFiltrada as unknown as ResponseContribuicoes[]),
      ...removeDuplicados(baixaParcial as unknown as ResponseContribuicoes[]),
    ];
  }

  dataFiltrada?.forEach(e => {
    if (
      checkIfSomeItemsAreTrue([
        e.codStatusCobranca === CODIGO_STATUS_COBRANCA.CANCELADA_INADIMPLENCIA,
        e.codStatusCobranca === CODIGO_STATUS_COBRANCA.CANCELADA,
      ])
    ) {
      return;
    }

    const contribuicao: ContribuicoesConvertido = {
      numCobranca: e.numCobranca,
      status: coverterDescStatus(e.codStatusCobranca),
      descStatus: coverterDescStatus(e.codStatusCobranca),
      dataVencimento: e.dataVencimento,
      dataBaixa: e.dataBaixa,
      valorCobrado:
        e.codStatusCobranca === CODIGO_STATUS_COBRANCA.PARCIALMENTE_PAGO
          ? e.valorBaixado
          : e.valorCobrado,
      permiteEmissaoBoleto: validarEmissaoBoleto(e),
      permiteReprocessar: e.permiteReprocessar,
      permiteImpressao: e.permiteImpressao,
      linhaDigitavel: e.linhaDigitavel,
      codMeioPagamento: e.codMeioPagamento,
    };

    statusRetorno.push(contribuicao);
  });

  return statusRetorno.sort(ordenaDataVencimentoSemConversao);
};

export const filtrarStatus = (
  data: ContribuicoesConvertido[] | undefined,
  status: string,
): ContribuicoesConvertido[] | undefined => {
  if (status === '') {
    return data;
  }
  const contribuicoes: ContribuicoesConvertido[] = [];

  data?.forEach(e => {
    if (e.status === status) contribuicoes.push(e);
  });

  return contribuicoes;
};
