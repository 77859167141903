import { tryGetValueOrDefault } from './conditional';
import { removerMascaraValorMonetario } from './money';

export const ordenarItens = (a: any, b: any): any => {
  return a.ordem - b.ordem;
};

export const ordenaValorMonetario =
  (prop: string) => (rowA: any, rowB: any) => {
    const a = rowA[prop];
    const b = rowB[prop];
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };
export const ordenaValor =
  (prop: string | number | null) => (rowA: any, rowB: any) => {
    const propParsed = tryGetValueOrDefault([prop?.toString()], '');
    const a = removerMascaraValorMonetario(rowA[propParsed]);
    const b = removerMascaraValorMonetario(rowB[propParsed]);
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };
