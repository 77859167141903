import { Display, Grid, Text, Skeleton } from '@cvp/design-system/react';
import { DadosFinanciamentoCampoSkeletonProps } from 'prestamista/features/dadosSeguro/types/IDadosFinanciamento';

export const DadosFinanciamentoCampoSkeleton = ({
  label,
}: DadosFinanciamentoCampoSkeletonProps) => (
  <Grid.Item xs={1} md={1 / 2} lg={1 / 3}>
    <Display>
      <div>
        <Text variant="caption-02" color="text-light">
          {label}
        </Text>
        <Text variant="caption-02" color="text">
          <Skeleton lines={1} />
        </Text>
      </div>
    </Display>
  </Grid.Item>
);
