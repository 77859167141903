import { MENUS_ALTERNATIVOS } from 'config/menusAlternativos';
import { IMenu } from 'main/components/Menu/Sidebar/types';
import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';

export const menuPrestamista: IMenu = {
  name: 'Prestamista',
  type: 'prestamista',
  roles: PRINCIPAL_USERS,
  items: [
    ...MENUS_ALTERNATIVOS,
    {
      alt: 'menu-consultas',
      label: 'Consultas',
      icon: 'search',
      path: '',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_CONSULTA,
        USER_PROFILES.ANALISTA_MANUTENCAO,
        USER_PROFILES.ANALISTA_SAIDA,
        USER_PROFILES.ANALISTA_ENTRADA,
      ],
      subItems: [
        {
          label: 'Dados Seguro',
          path: '/cliente/produtos/prestamista/dados-seguro',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
          ],
        },
        {
          label: 'Certificado Online',
          path: '/cliente/produtos/prestamista/emissao-certificado',
          roles: [
            ...PRINCIPAL_USERS,
            USER_PROFILES.ANALISTA_CONSULTA,
            USER_PROFILES.ANALISTA_MANUTENCAO,
            USER_PROFILES.ANALISTA_SAIDA,
            USER_PROFILES.ANALISTA_ENTRADA,
          ],
        },
      ],
    },
    {
      alt: 'dados-segurado',
      label: 'Dados Segurado',
      icon: 'user',
      path: '/cliente/produtos/prestamista/dados-segurado',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_CONSULTA,
        USER_PROFILES.ANALISTA_MANUTENCAO,
        USER_PROFILES.ANALISTA_SAIDA,
        USER_PROFILES.ANALISTA_ENTRADA,
      ],
    },
  ],
};
