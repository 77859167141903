import React from 'react';
import { Display, Text, Button } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';
import * as PROPS_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblComponentProps';
import * as TRANSF_VGBL_STYLES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/styles';

const ModalValorMinimoContribuicao: React.FC<
  PROPS_TYPES.ModalValorMinimoContribuicaoProps
> = ({ toggleModal, handleModal, valorMinimoContribuicao }) => {
  return (
    <TRANSF_VGBL_STYLES.ModalTransferenciaInterna
      show={toggleModal}
      handleClose={handleModal}
      size={ENUM_TYPES.ModalInfoPredefinitionSize.XS}
      data-testid="modalValorMinimoPermitido"
    >
      <TRANSF_VGBL_STYLES.Text
        margin
        color="secondary"
        fontWeight="bold"
        data-testid="modalValorMinimoPermitidoGrauCriticidade"
      >
        <Icon name="warning" /> Atenção!
      </TRANSF_VGBL_STYLES.Text>
      <Text
        variant="body02-sm"
        margin
        data-testid="modalValorMinimoPermitidoMensagem"
      >
        O valor mínimo para contribuição mensal deverá ser de pelo menos{' '}
        {tryGetMonetaryValueOrDefault(valorMinimoContribuicao)}
      </Text>
      <Display justify="center">
        <Button
          variant="secondary"
          onClick={handleModal}
          data-testid="modalValorMinimoPermitidoBotaoInfoEntendida"
        >
          Certo, entendi!
        </Button>
      </Display>
    </TRANSF_VGBL_STYLES.ModalTransferenciaInterna>
  );
};

export default ModalValorMinimoContribuicao;
