import { ResponseValoresContribuicao } from 'previdencia/features/AlterarValorContribuicao/types/ResponsesAlterarValorContribuicao';
import { DefaultValue } from 'previdencia/features/AlterarValorContribuicao/types/enum';
import { ResumoValoresContribuicao } from 'previdencia/features/AlterarValorContribuicao/types/ContribuicaoUtilsProps';

export function resumoValoresContribuicao(
  listaValores: ResponseValoresContribuicao[],
): ResumoValoresContribuicao {
  const listaProtecoesAtivas = listaValores.filter(itemProtecao => {
    return (
      itemProtecao.benefitType === DefaultValue.TIPO_BENEFICIO_RI &&
      !!itemProtecao.initialContributionValue &&
      itemProtecao.status === DefaultValue.TIPO_BENEFICIO_ATIVO
    );
  });

  const listaSobrevivenciasAtivas = listaValores.filter(itemSobrevivencia => {
    return (
      itemSobrevivencia.benefitType === DefaultValue.TIPO_BENEFICIO_PR &&
      !!itemSobrevivencia.initialContributionValue &&
      itemSobrevivencia.status === DefaultValue.TIPO_BENEFICIO_ATIVO
    );
  });

  const valorTotalSobrevivenciasAtivas = listaSobrevivenciasAtivas.reduce(
    (valorSobrevivenciaAnterior, valorSobrevivenciaAtual) =>
      valorSobrevivenciaAnterior +
      valorSobrevivenciaAtual.initialContributionValue,
    0,
  );

  const valorTotalProtecoesAtivas = listaProtecoesAtivas.reduce(
    (valorProtecaoAnterior, valorProtecaoAtual) =>
      valorProtecaoAnterior + valorProtecaoAtual.initialContributionValue,
    0,
  );

  const valorTotalContribuicoesAtivas =
    valorTotalSobrevivenciasAtivas + valorTotalProtecoesAtivas;

  const [sobrevivenciaAtiva] = listaSobrevivenciasAtivas;

  return {
    listaProtecoesAtivas,
    listaSobrevivenciasAtivas,
    valorTotalSobrevivenciasAtivas,
    valorTotalProtecoesAtivas,
    valorTotalContribuicoesAtivas,
    sobrevivenciaAtiva,
  };
}
