import React from 'react';
import {
  Text,
  Display,
  Button,
  Grid,
  Radio,
  Select,
} from '@cvp/design-system/react';
import masks from 'main/utils/masks';
import { TextArea } from 'main/components/form';
import * as OCORRENCIA_UTILS from 'registroOcorrenciaASC/utils/RegistroOcorrenciaASCUtils';
import * as CONSTS from 'registroOcorrenciaASC/features/registrarOcorrencia/constants/constants';
import * as REGISTRO_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrencias';
import * as S from 'registroOcorrenciaASC/features/registrarOcorrencia/components/ModalComunicadoSinistroPrestamista/styles';

const ModalComunicadoSinistroPrestamista: React.FC<
  REGISTRO_OCORRENCIA_TYPES.ModalComunicadoSinistroPrestamistaProps
> = ({
  formik,
  toggleModal,
  cleanModal,
  disableSaveBtnForm,
  salvarFormContexto,
}) => {
  return (
    <S.Modal
      show={formik.values.toggleModalComunicadoSinistroPrestamista}
      onClose={toggleModal}
    >
      <Text variant="headline-06" color="primary" margin>
        {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.TITULO_FOP}
      </Text>

      <Text variant="body01-lg" align="left" margin>
        {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.SUBTITULO_SEGURADO}
      </Text>

      <Grid>
        <Grid.Item xs={1} md={1 / 2} xl={4 / 10}>
          <S.TextField
            {...formik.getFieldProps(formik.values.nomeDadosSegurado)}
            label="Nome*"
            data-testid="nomeDadosSegurado"
            name="nomeDadosSegurado"
            value={formik.values.nomeDadosSegurado}
            {...OCORRENCIA_UTILS.showErrorInput(formik, 'nomeDadosSegurado')}
          />
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2} xl={2 / 10}>
          <S.TextField
            {...formik.getFieldProps(formik.values.cpfDadosSegurado)}
            label="CPF*"
            data-testid="cpfDadosSegurado"
            name="cpfDadosSegurado"
            value={masks.cpf.mask(formik.values.cpfDadosSegurado)}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('cpfDadosSegurado', masks.cpf.unmask(value));
            }}
            {...OCORRENCIA_UTILS.showErrorInput(formik, 'cpfDadosSegurado')}
          />
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2} xl={2 / 10}>
          <S.TextField
            {...formik.getFieldProps(formik.values.dataNascDadosSegurado)}
            label="Data de Nascimento*"
            data-testid="dataNascDadosSegurado"
            name="dataNascDadosSegurado"
            value={masks.date.mask(formik.values.dataNascDadosSegurado)}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'dataNascDadosSegurado',
            )}
          />
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2} xl={2 / 10}>
          <S.ContainerRadioButtons>
            <Text variant="body02-sm" margin>
              Sexo
            </Text>
            <div>
              <Display alignItems="center">
                <Radio
                  data-testid="sexoDadosSegurado"
                  name="sexoDadosSegurado"
                  value={CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.SEXO_MASCULINO}
                  checked={
                    formik.values.sexoDadosSegurado ===
                    CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.SEXO_MASCULINO
                  }
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('sexoDadosSegurado', value);
                  }}
                />
                {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.SEXO_MASCULINO}
              </Display>
              <Display alignItems="center">
                <Radio
                  data-testid="sexoDadosSegurado"
                  name="sexoDadosSegurado"
                  value={CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.SEXO_FEMININO}
                  checked={
                    formik.values.sexoDadosSegurado ===
                    CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.SEXO_FEMININO
                  }
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('sexoDadosSegurado', value);
                  }}
                />
                {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.SEXO_FEMININO}
              </Display>
            </div>
          </S.ContainerRadioButtons>
        </Grid.Item>
      </Grid>

      <S.Divider />

      <Text variant="body01-lg" align="left" margin>
        {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.SUBTITULO_RECLAMANTE}{' '}
        <S.DangerMessage>
          {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.SUBTITULO_RECLAMANTE_INFO}
        </S.DangerMessage>
      </Text>
      <Grid>
        <Grid.Item xs={1} lg={1 / 3}>
          <S.TextField
            {...formik.getFieldProps(formik.values.nomeDadosReclamante)}
            label="Nome*"
            data-testid="nomeDadosReclamante"
            name="nomeDadosReclamante"
            value={formik.values.nomeDadosReclamante}
            {...OCORRENCIA_UTILS.showErrorInput(formik, 'nomeDadosReclamante')}
          />
        </Grid.Item>
        <Grid.Item xs={1} lg={1 / 3}>
          <S.TextField
            {...formik.getFieldProps(formik.values.cpfDadosReclamante)}
            label="CPF*"
            data-testid="cpfDadosReclamante"
            name="cpfDadosReclamante"
            value={masks.cpf.mask(formik.values.cpfDadosReclamante)}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue(
                'cpfDadosReclamante',
                masks.cpf.unmask(value),
              );
            }}
            {...OCORRENCIA_UTILS.showErrorInput(formik, 'cpfDadosReclamante')}
          />
        </Grid.Item>
        <Grid.Item xs={1} lg={1 / 3}>
          <S.Select
            {...formik.getFieldProps(
              formik.values.grauParentescoDadosReclamante,
            )}
            label="Grau de Parentesco*"
            placeholder="Escolha uma opção"
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('grauParentescoDadosReclamante', value);
            }}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'grauParentescoDadosReclamante',
            )}
          >
            {CONSTS.LISTA_GRAU_PARENTESCO.map(item => (
              <Select.Item
                key={item.id}
                value={item.grauParentesco}
                text={item.grauParentesco}
                selected={
                  formik.values.grauParentescoDadosReclamante ===
                  item.grauParentesco
                }
              />
            ))}
          </S.Select>
        </Grid.Item>
        <Grid.Item xs={1} lg={1 / 2}>
          <S.TextField
            {...formik.getFieldProps(formik.values.email1DadosReclamante)}
            label="E-mail 1"
            data-testid="email1DadosReclamante"
            name="email1DadosReclamante"
            value={formik.values.email1DadosReclamante}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'email1DadosReclamante',
            )}
          />
        </Grid.Item>
        <Grid.Item xs={1} lg={1 / 2}>
          <S.TextField
            {...formik.getFieldProps(formik.values.email2DadosReclamante)}
            label="E-mail 2"
            data-testid="email2DadosReclamante"
            name="email2DadosReclamante"
            value={formik.values.email2DadosReclamante}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'email2DadosReclamante',
            )}
          />
        </Grid.Item>
        <Grid.Item xs={1} xl={5 / 10}>
          <S.TextField
            {...formik.getFieldProps(formik.values.enderecoDadosReclamante)}
            label="Endereço*"
            data-testid="enderecoDadosReclamante"
            name="enderecoDadosReclamante"
            value={formik.values.enderecoDadosReclamante}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'enderecoDadosReclamante',
            )}
          />
        </Grid.Item>
        <Grid.Item xs={1} lg={2 / 5} xl={2 / 10}>
          <S.TextField
            {...formik.getFieldProps(formik.values.cidadeDadosReclamante)}
            label="Cidade*"
            data-testid="cidadeDadosReclamante"
            name="cidadeDadosReclamante"
            value={formik.values.cidadeDadosReclamante}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'cidadeDadosReclamante',
            )}
          />
        </Grid.Item>
        <Grid.Item xs={1} lg={2 / 5} xl={2 / 10}>
          <S.TextField
            {...formik.getFieldProps(formik.values.bairroDadosReclamante)}
            label="Bairro*"
            data-testid="bairroDadosReclamante"
            name="bairroDadosReclamante"
            value={formik.values.bairroDadosReclamante}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'bairroDadosReclamante',
            )}
          />
        </Grid.Item>
        <Grid.Item xs={1} lg={1 / 5} xl={1 / 10}>
          <S.TextField
            {...formik.getFieldProps(formik.values.uFDadosReclamante)}
            label="UF"
            data-testid="uFDadosReclamante"
            name="uFDadosReclamante"
            value={formik.values.uFDadosReclamante}
            {...OCORRENCIA_UTILS.showErrorInput(formik, 'uFDadosReclamante')}
          />
        </Grid.Item>
        <Grid.Item xs={3 / 10} lg={1 / 6} xl={1 / 9}>
          <S.TextField
            {...formik.getFieldProps(
              formik.values.dDDTelResidencialDadosReclamante,
            )}
            label="DDD"
            data-testid="dDDTelResidencialDadosReclamante"
            name="dDDTelResidencialDadosReclamante"
            value={formik.values.dDDTelResidencialDadosReclamante}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'dDDTelResidencialDadosReclamante',
            )}
          />
        </Grid.Item>
        <Grid.Item xs={7 / 10} lg={2 / 6} xl={2 / 9}>
          <S.TextField
            {...formik.getFieldProps(
              formik.values.telResidencialDadosReclamante,
            )}
            label="Telefone Residencial"
            data-testid="telResidencialDadosReclamante"
            name="telResidencialDadosReclamante"
            value={masks.phoneWithoutDDD.mask(
              formik.values.telResidencialDadosReclamante,
            )}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue(
                'telResidencialDadosReclamante',
                masks.phoneWithoutDDD.unmask(value),
              );
            }}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'telResidencialDadosReclamante',
            )}
          />
        </Grid.Item>
        <Grid.Item xs={3 / 10} lg={1 / 6} xl={1 / 9}>
          <S.TextField
            {...formik.getFieldProps(
              formik.values.dDDTelComercialDadosReclamante,
            )}
            label="DDD"
            data-testid="dDDTelComercialDadosReclamante"
            name="dDDTelComercialDadosReclamante"
            value={formik.values.dDDTelComercialDadosReclamante}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'dDDTelComercialDadosReclamante',
            )}
          />
        </Grid.Item>
        <Grid.Item xs={7 / 10} lg={2 / 6} xl={2 / 9}>
          <S.TextField
            {...formik.getFieldProps(formik.values.telComercialDadosReclamante)}
            label="Telefone Comercial"
            data-testid="telComercialDadosReclamante"
            name="telComercialDadosReclamante"
            value={masks.phoneWithoutDDD.mask(
              formik.values.telComercialDadosReclamante,
            )}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue(
                'telComercialDadosReclamante',
                masks.phoneWithoutDDD.unmask(value),
              );
            }}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'telComercialDadosReclamante',
            )}
          />
        </Grid.Item>
        <Grid.Item xs={3 / 10} lg={1 / 6} xl={1 / 9}>
          <S.TextField
            {...formik.getFieldProps(
              formik.values.dDDTelCelularDadosReclamante,
            )}
            label="DDD"
            data-testid="dDDTelCelularDadosReclamante"
            name="dDDTelCelularDadosReclamante"
            value={formik.values.dDDTelCelularDadosReclamante}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'dDDTelCelularDadosReclamante',
            )}
          />
        </Grid.Item>
        <Grid.Item xs={7 / 10} lg={2 / 6} xl={2 / 9}>
          <S.TextField
            {...formik.getFieldProps(formik.values.telCelularDadosReclamante)}
            label="Telefone Celular"
            data-testid="telCelularDadosReclamante"
            name="telCelularDadosReclamante"
            value={masks.phoneWithoutDDD.mask(
              formik.values.telCelularDadosReclamante,
            )}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue(
                'telCelularDadosReclamante',
                masks.phoneWithoutDDD.unmask(value),
              );
            }}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'telCelularDadosReclamante',
            )}
          />
        </Grid.Item>
      </Grid>

      <S.Divider />

      <Text variant="body01-lg" align="left" margin>
        {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.SUBTITULO_SINISTRO}{' '}
        <S.DangerMessage>
          {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.SUBTITULO_SINISTRO_INFO}
        </S.DangerMessage>
      </Text>
      <Grid>
        <Grid.Item xs={1} md={1 / 2}>
          <S.Select
            {...formik.getFieldProps(
              formik.values.coberturaPleiteadaDadosSinistro,
            )}
            label="Cobertura Pleiteada*"
            placeholder="Escolha uma opção"
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('coberturaPleiteadaDadosSinistro', value);
            }}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'coberturaPleiteadaDadosSinistro',
            )}
          >
            {CONSTS.LISTA_COBERTURA_PLEITEADA.map(item => (
              <Select.Item
                key={item.id}
                value={item.coberturaPleiteada}
                text={item.coberturaPleiteada}
                selected={
                  formik.values.coberturaPleiteadaDadosSinistro ===
                  item.coberturaPleiteada
                }
              />
            ))}
          </S.Select>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 2}>
          <S.TextField
            {...formik.getFieldProps(formik.values.dataOcorrenciaDadosSinistro)}
            label="Data Sinistro / Ocorrência*"
            data-testid="dataOcorrenciaDadosSinistro"
            name="dataOcorrenciaDadosSinistro"
            value={masks.date.mask(formik.values.dataOcorrenciaDadosSinistro)}
            {...OCORRENCIA_UTILS.showErrorInput(
              formik,
              'dataOcorrenciaDadosSinistro',
            )}
          />
        </Grid.Item>
        <Grid.Item xs={1}>
          <S.ContainerTextArea>
            <Text variant="body02-sm" margin>
              {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.LABEL_HISTORICO_SINISTRO}
            </Text>
            <TextArea
              {...formik.getFieldProps(formik.values.historicoDadosSinistro)}
              data-testid="historicoDadosSinistro"
              name="historicoDadosSinistro"
              rows={5}
              spellCheck
              value={formik.values.historicoDadosSinistro}
              {...OCORRENCIA_UTILS.showErrorInput(
                formik,
                'historicoDadosSinistro',
              )}
            />
          </S.ContainerTextArea>
        </Grid.Item>
        <Grid.Item xs={1}>
          <Text variant="body02-sm" align="left" margin>
            <strong>
              {CONSTS.TEXTOS_FORM_DADOS_OCORRENCIA.CAMPOS_OBRIGATORIOS}
            </strong>
          </Text>
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item xs={1} md={1 / 3}>
          <Display justify="center">
            <Button
              variant="primary"
              onClick={salvarFormContexto}
              disabled={!disableSaveBtnForm(formik.values)}
            >
              Salvar
            </Button>
          </Display>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 3}>
          <Display justify="center">
            <Button variant="secondary" onClick={cleanModal}>
              Limpar
            </Button>
          </Display>
        </Grid.Item>
        <Grid.Item xs={1} md={1 / 3}>
          <Display justify="center">
            <Button variant="outlined" onClick={toggleModal}>
              Fechar
            </Button>
          </Display>
        </Grid.Item>
      </Grid>
    </S.Modal>
  );
};

export default ModalComunicadoSinistroPrestamista;
