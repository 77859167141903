import { useContext } from 'react';
import { useToast } from 'main/hooks/useToast';
import { useQuery, UseQueryResult } from 'react-query';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { IApiResponse } from 'main/services';
import * as AtualizarTelefonelApi from 'previdencia/features/DadosParticipante/services/atualizarTelefone.api';
import { AppContext } from 'main/contexts/AppContext';
import { RequestAlterarTelefone } from '../types/DadosParticipante';

const useAtualizarTelefone = (
  request: RequestAlterarTelefone[] | undefined,
  onCancelar: any,
): UseQueryResult<IApiResponse<undefined> | undefined> => {
  const { toastError, toastSuccess } = useToast();
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  return useQuery(
    ['prev-atualizar-telefone', cpfCnpj],
    () =>
      AtualizarTelefonelApi.atualizarTelefone(cpfCnpj, numCertificado, request),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      enabled: false,
      onError: (erro: Error) => toastError(erro.message),
      onSuccess: data => {
        if (data?.dados?.mensagens) {
          onCancelar();
          toastSuccess(String(data.dados.mensagens[0].descricao));
        }
      },
    },
  );
};

export default useAtualizarTelefone;
