import { Text } from '@cvp/design-system/react';
import { WrapperSlideFadeContent } from 'main/components/Wrappers/styled/WrapperSlideFadeContent';
import { ContainerExpandedInfo } from 'vida/features/DadosApolice/pages/styles';
import * as APOLICE_TYPES from 'seguros/types/ApoliseCobertura';

const DetalhesCobertura = ({
  data,
}: APOLICE_TYPES.ITabelaApoliceCoberturasItens | any) => {
  return (
    <WrapperSlideFadeContent>
      <ContainerExpandedInfo>
        <Text variant="body02-md">{data?.descricaoCobertura}</Text>
      </ContainerExpandedInfo>
    </WrapperSlideFadeContent>
  );
};

export default DetalhesCobertura;
