import React from 'react';
import { Card, Text, Display, Accordion } from '@cvp/design-system/react';

const CardFallback: React.FunctionComponent<{ title: string }> = ({
  title,
}) => (
  <Display type="inline-block">
    <Card>
      <Card.Content>
        <Accordion>
          <Accordion.Item title={title}>
            <Text variant="body02-sm">
              Não foi possível carregar as informações.
            </Text>
          </Accordion.Item>
        </Accordion>
      </Card.Content>
    </Card>
  </Display>
);

export default CardFallback;
