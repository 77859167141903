import styled from 'styled-components/macro';
import {
  Button as ButtonBase,
  Container as ContainerBase,
  Modal as ModalDS,
} from '@cvp/design-system/react';

export const Container = styled(ContainerBase)`
  @media print {
    display: none;
  }
`;

export const HeaderBar = styled.div(({ theme: { color } }) => ({
  background: color.brandPrimary.gradient,
  color: color.neutral['08'],
  [`@media print`]: {
    display: 'none',
  },
  position: 'relative',
}));

export const LoggedUserInfo = styled.div({
  padding: '2px 24px 0 24px',
});

export const Button = styled(ButtonBase)({
  padding: '8px 24px 2px 24px',
});

export const Modal = styled(ModalDS)(({ theme: { breakpoint } }) => ({
  [breakpoint.md()]: {
    maxWidth: '80%',
  },
  [breakpoint.lg()]: {
    maxWidth: '50%',
  },
}));
