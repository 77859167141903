import { api, IApiResponse } from 'main/services';
import { ICredentials, IUser } from 'main/features/Auth/interfaces';
import { getDataFromKey } from 'main/features/Auth/utils/auth';
import {
  obterEndpointAutenticacao,
  obterEndpointTokenGateWay,
} from '../config/endpoints';

export const login = async ({
  username: email,
  password,
}: ICredentials): Promise<IApiResponse<IUser>> => {
  const { data } = await api.post<IApiResponse<IUser>>(
    obterEndpointAutenticacao(),
    {
      password,
      nomeAcesso: email,
    },
  );

  return data;
};

export const obterChaveGateway = async (funcao: string, nomeAcesso: string) => {
  const url = obterEndpointTokenGateWay();

  const response = await api.post(
    url,
    {
      cpf: nomeAcesso,
      funcao,
    },
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
  );

  const { Mapdata, keyData } = response.data.dados;
  return getDataFromKey(Mapdata, keyData);
};
