import { scrollScreen } from 'main/utils/screen';
import { Fundo } from 'previdencia/types/Fundo.type';

export const fundosAtivos = (data?: Fundo[]): number => {
  let sum = 0;

  data?.forEach(fundo => {
    if (fundo.selecionado === true) sum += 1;
  });

  return sum;
};

export function scrollEndScreen() {
  scrollScreen(document.body.scrollHeight);
}

export function deveIrFimDaTela(value: boolean) {
  if (value) scrollEndScreen();
}
