import React from 'react';
import { Text } from '@cvp/design-system/react';
import * as S from './styles';

type GraficoType = {
  titulo: string;
  textoAuxiliar: string;
  response?: string;
  alt?: string;
};

const Grafico: React.FC<GraficoType> = ({
  titulo,
  textoAuxiliar,
  response,
  alt,
}) => {
  return (
    <S.WrapperGrafico>
      <Text variant="body02-md" color="primary" margin>
        <b>{titulo}</b>
      </Text>
      <Text variant="body02-md" color="text">
        {textoAuxiliar}
      </Text>
      <img src={response} alt={alt} />
    </S.WrapperGrafico>
  );
};

export default Grafico;
