import { IFilterableEntity } from 'main/types/IFilterableEntity';

export type FilterType = string | 'text' | 'date' | undefined;

export interface ITagFilterOption {
  column?: string;
  id: number;
  description: string;
}

export const FilterTypes = {
  TEXT: 'text',
  DATE: 'date',
  TAG: 'tag',
};

export interface IFilterOption {
  key: string;
  value: string;
  type: FilterType;
  unmask?: (value: string) => string;
  checkboxConfig?: {
    options: ITagFilterOption[];
    helpText?: string;
  };
}

export interface ISearchTerms {
  text: string;
  filter: string;
  filterType: FilterType;
  initialDate?: Date | null;
  endDate?: Date | null;
}

type DataProps = {
  exibir: boolean;
  dataInicio?: string;
  dataFim?: string;
};

export type TableFilterProps = {
  filterOptions?: IFilterOption[];
  dataToFilter?: IFilterableEntity[];
  filterTextPartial?: boolean;
  children:
    | ((filteredData: IFilterableEntity[]) => React.ReactNode)
    | React.ReactNode;
  customButton?: React.ReactNode;
  defaultFilter?: ISearchTerms;
  tagsFilterDescription?: string;
  dataProps?: DataProps;
  onSubmitCallback?: (searchTerms: ISearchTerms | undefined) => void;
};
