import React from 'react';
import { Button, Grid, Text } from '@cvp/design-system/react';
import { Input } from 'main/components/form';
import { intervalValue, required } from 'main/features/Validation/validations';
import useFieldLink from 'main/hooks/useFieldLink';
import * as S from 'previdencia/features/SimulacaoRenda/components/PrazoRecebimentoRenda/styles';
import {
  EnumTipoRendaCodigo,
  EtapasFluxoSimulacaoRenda,
} from 'previdencia/features/SimulacaoRenda/types/enum';
import { ValidaInputIntervalValue } from 'previdencia/features/SimulacaoRenda/types/tiposRenda';
import { useSimulacaoRendaContextData } from 'previdencia/features/SimulacaoRenda/hooks/useSimulacaoRendaContextData';
import { getTernaryResult } from 'main/utils/conditional';

const PrazoRecebimentoRenda: React.FC = () => {
  const { setEtapaAtual, featureData } = useSimulacaoRendaContextData();
  const tipoRenda = featureData?.requestSimularRenda?.tipoRenda;

  const [valorLink, validarValor] = useFieldLink('');

  const rangePrazoMaximo = getTernaryResult(
    tipoRenda === EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido,
    15,
    35,
  );

  const rangePrazoMinimo = getTernaryResult(
    tipoRenda === EnumTipoRendaCodigo.PrazoCerto,
    1,
    5,
  );

  const validacaoInput: ValidaInputIntervalValue = {
    min: rangePrazoMinimo,
    max: rangePrazoMaximo,
  };

  const simularPrazoRenda = (): void => {
    if (validarValor().isValid) {
      setEtapaAtual(EtapasFluxoSimulacaoRenda.RESULTADO_SIMULACAO, {
        qtdPrazoAnos: valorLink.get().value,
      });
    }
  };

  const label = getTernaryResult(
    tipoRenda === EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido,
    'Digite o prazo mínimo garantido:',
    'Por quanto tempo deseja receber a renda?',
  );

  return (
    <>
      <Grid>
        <Grid.Item xs={1} md={1 / 3}>
          <Text variant="caption-01" color="text-light">
            {label}
          </Text>

          <Input
            link={valorLink}
            placeholder="Digite o prazo..."
            validationRules={[
              required(),
              intervalValue(() => '', validacaoInput),
            ]}
            legend={`valores entre ${rangePrazoMinimo} e ${rangePrazoMaximo} anos`}
          />
        </Grid.Item>
      </Grid>

      <S.ContainerButtons>
        <Button
          variant="outlined"
          onClick={() =>
            setEtapaAtual(EtapasFluxoSimulacaoRenda.INICIO_SIMULACAO)
          }
        >
          Voltar
        </Button>
        <Button variant="primary" onClick={simularPrazoRenda}>
          Simular
        </Button>
      </S.ContainerButtons>
    </>
  );
};

export default PrazoRecebimentoRenda;
