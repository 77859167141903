import { Cliente } from './Cliente';
import { Contrato } from './Contrato';
import { HistoricoSituacao } from './HistoricoSituacao';
import { Portabilidade } from './Portabilidade';

export interface DetalhesPortabilidade {
  portabilidade: Portabilidade;
  historicoSituacaoRetencao: HistoricoSituacao[];
  contrato: Contrato;
  cliente: Cliente;
}

export const getDefaultInstance = (): DetalhesPortabilidade => ({
  portabilidade: {
    agenciaDigital: false,
    cnpjEntidadeCessionaria: '',
    cnpjFundoCedente: '',
    cnpjFundoCessionario: '',
    dataVencimento: '',
    id: 0,
    nomeEntidadeCessionaria: '',
    numeroAgenciaOrigem: 0,
    numeroPortabilidade: 0,
    valorPortabilidade: 0,
    usuarioResponsavel: {
      nomeAcesso: '',
      nomeUsuario: '',
      numeroAgencia: 0,
    },
  },
  cliente: {
    cpfCnpj: '',
    id: '',
    primeiroNome: '',
    sobrenome: '',
    telefones: [],
    emails: [],
  },
  contrato: {
    codigoEmpresa: 0,
    codigoProduto: 0,
    familiaFundosAtual: '',
    familiaFundosEnquadrado: '',
    modalidadePlano: '',
    nomeFundoAtual: '',
    numeroContrato: 0,
    segmento: '',
    segmentoExclusivo: false,
    taxaAdministrativaFinanceira: 0,
    tipoEnquadramento: '',
    valorCarregamento: 0,
    valorSaldoAtualizado: 0,
    valorSaldoCliente: 0,
  },
  historicoSituacaoRetencao: [],
});
