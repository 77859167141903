import styled from 'styled-components/macro';
import { Grid } from '@cvp/design-system/react';

export const ContainerTiposRenda = styled.div(({ theme: { color } }) => ({
  background: `${color.neutral['04']}1a`,
  padding: '15px 15px 1px 15px',
  borderRadius: 8,
  marginBottom: 10,
}));

export const ContainerTiposRendaItem = styled(Grid)(({ theme: { color } }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${color.neutral['04']}`,
  padding: '10px 0',

  '&:last-child': {
    borderBottom: `none`,
  },
}));
