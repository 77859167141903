import { useContext, useEffect } from 'react';
import { Display } from '@cvp/design-system/react';
import { AppContext } from 'main/contexts/AppContext';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { CardResumoProduto, DadosBeneficiarios } from 'seguros/components';
import { useCertificadoSeguroResumo } from 'seguros/hooks/useCertificadoSeguroResumo';

const InformacoesBeneficiarios = () => {
  const { clienteVida } = useContext(AppContext);

  const {
    certificadoSeguros: { response, loading, fetchData },
  } = useCertificadoSeguroResumo({
    numeroContrato: tryGetValueOrDefault([clienteVida?.numCertificado], '0'),
    codigoProduto: tryGetValueOrDefault([clienteVida?.codigoProduto], '0'),
  });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Display type="display-block">
      <CardResumoProduto />
      <DadosBeneficiarios data={response} loading={loading} />
    </Display>
  );
};

export default InformacoesBeneficiarios;
