import { DadosClienteModalTipoDocuSingProps } from 'main/components/AssinaturaDocuSign/ModalTipoDocuSign';
import {
  DadosParticipante,
  TelefoneDadosParticipante,
} from 'main/types/Participante';
import { retornarDadosClienteModalTipoDocuSingProps } from 'previdencia/features/AlterarValorContribuicao/factories/AlterarValorContribuicaoFactory';
import { DefaultValue } from 'previdencia/features/AlterarValorContribuicao/types/enum';

export function obterTelefone(
  telefones: TelefoneDadosParticipante[] | undefined,
): string {
  if (!telefones) return '';

  const result = telefones.find(
    telefone => telefone.aceitaSms === DefaultValue.TELEFONE_ACEITA_SMS,
  );

  if (!result) return '';

  return `${result.codigoArea}${result.numeroTelefone}`;
}

export async function deveObterDadosParticipante(
  consentimentoValue: boolean,
  confirmarClienteCienteValue: boolean,
  obterDadosParticipante: () => Promise<void>,
) {
  if (consentimentoValue && confirmarClienteCienteValue)
    await obterDadosParticipante();
}

export function obterDadosClienteModalTipoDocuSingProps(
  setDadosClienteModalTipoDocuSing: (
    value: React.SetStateAction<DadosClienteModalTipoDocuSingProps | undefined>,
  ) => void,
  response?: DadosParticipante,
) {
  if (response)
    setDadosClienteModalTipoDocuSing(
      retornarDadosClienteModalTipoDocuSingProps(response),
    );
}
