import {
  obterChaveArmazenamentoTokenCliente,
  obterChaveArmazenamentoTokenOperador,
} from 'config/storage_keys';
import { setPermissionsInSession } from 'main/features/Auth/utils/auth';
import { api, IApiResponse } from 'main/services';
import { ICliente } from 'main/types/ICliente';
import { getSessionItem, setSessionItem } from 'main/utils/storage';
import { obterEndpointMatrizDeAcesso } from 'previdencia/config/endpoints';
import {
  IMatrizAcessoCliente,
  ITokenOperador,
} from 'previdencia/types/MatrizDeAcesso';

const storageKeyOperador = obterChaveArmazenamentoTokenOperador();
const storageKeyCliente = obterChaveArmazenamentoTokenCliente();

export const obterTokenOperador = async () => {
  const tokenCached = getSessionItem<ITokenOperador>(storageKeyOperador);
  if (tokenCached && tokenCached?.tokenOperador)
    return tokenCached.tokenOperador;

  const result = await api.post<IApiResponse<ITokenOperador>>(
    obterEndpointMatrizDeAcesso(),
    { codCliente: '' },
  );

  const token = result?.data.dados.entidade;

  setSessionItem(storageKeyOperador, token);

  return token?.tokenOperador ?? '';
};

export const obterMatrizPeloCertificado = (numCertificado: string) => {
  const matrizAcessoCliente =
    getSessionItem<IMatrizAcessoCliente>(storageKeyCliente);

  const matriz = matrizAcessoCliente?.matrizAcessoCliente?.lstWebSession.find(
    x => x.certificado === numCertificado,
  );

  const permissoes = matriz?.lstServicesAvailable.map(item => item.operation);

  setPermissionsInSession(permissoes ?? []);

  return matriz;
};

export const obterTokenMatriz = async (codCliente?: string) => {
  const matrizCached = getSessionItem<IMatrizAcessoCliente>(storageKeyCliente);

  const certificadoAtual = getSessionItem<ICliente>('cliente');

  const tokenCertificado = (
    matrizCached?.matrizAcessoCliente?.lstWebSession ?? []
  ).find(x => x.certificado === certificadoAtual.numCertificado);

  if (tokenCertificado && tokenCertificado?.xPrevToken) {
    obterMatrizPeloCertificado(certificadoAtual.numCertificado);
    return tokenCertificado.xPrevToken;
  }

  const result = await api.post<IApiResponse<IMatrizAcessoCliente>>(
    obterEndpointMatrizDeAcesso(),
    { codCliente: codCliente ?? certificadoAtual.cpfCnpj },
  );

  setSessionItem(storageKeyCliente, result.data.dados.entidade);

  const token = obterMatrizPeloCertificado(certificadoAtual.numCertificado);

  return token?.xPrevToken;
};

export const obterTokenCliente = () => {
  const matrizCliente = getSessionItem<IMatrizAcessoCliente>(storageKeyCliente);
  const collectionCliente = matrizCliente.matrizAcessoCliente.lstWebSession;
  return collectionCliente.length === 0
    ? null
    : collectionCliente[0].xPrevToken;
};
