import masks from 'main/utils/masks';

export const maskCpfCnpj = (value: number | string | null): string => {
  if (!value) return '';

  const valueFormated = value?.toString().replace(/\D/g, '');

  if (valueFormated && valueFormated.length > 0 && valueFormated.length <= 11) {
    return masks.cpf.mask(valueFormated);
  }
  if (valueFormated.length >= 14 && valueFormated.length <= 18) {
    return masks.cnpj.mask(valueFormated);
  }

  return valueFormated.substring(0, 14);
};
