import React from 'react';
import { Tag } from '@cvp/design-system/react';
import { statusCertificadoMapper } from 'previdencia/utils/previdenciaMappers';

type statusCOlorsProp = {
  [key: string]: string;
};
const statusColors: statusCOlorsProp = {
  A: 'primary',
  B: 'primary',
  U: 'primary',
  D: 'inactive',
  E: 'inactive',
  P: 'warning',
  S: 'warning',
  C: 'error',
};

const TagStatusCertificado: React.FunctionComponent<{ status: string }> = ({
  status,
}) => {
  return (
    <Tag
      variant={statusColors[status]}
      value={statusCertificadoMapper(status)}
    />
  );
};

export default TagStatusCertificado;
