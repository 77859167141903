import styled from 'styled-components/macro';

export const DetalhesPlano = styled.div({
  '.saldo': {
    alignItems: 'center',
  },
});

export const Title = styled.div(({ theme }) => ({
  borderBottom: `2px solid ${theme.color.neutral['02']}`,
}));

export const Input = styled.input(() => ({
  border: '1px solid #A4A5A9',
  borderRadius: 4,
  height: 56,
  color: '#414042',
  paddingLeft: 12,
  paddingRight: 12,
  outline: 0,
  background: '#fff',
  fontSize: 18,
}));

export const WrapperSelect = styled.div({
  alignItems: 'center',
  maxWidth: 300,
  marginBottom: 50,
});

export const SelectRange = styled.div(({ theme: { breakpoint } }) => ({
  margin: '15px 0 100px',
  gap: '20px',

  [breakpoint.lg()]: {
    flexDirection: 'row',

    button: {
      alignSelf: 'flex-end',
      bottom: '8px',
    },
  },
}));
