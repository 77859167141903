import React, { useEffect, useState } from 'react';
import { Grid, Text } from '@cvp/design-system/react';
import useFieldLink from 'main/hooks/useFieldLink';
import ExtratoRentabilidade from 'previdencia/features/RentabilidadeCotas/components/ExtratoRentabilidadeCotas';
import useObterExtratoRentabilidadeCotas from 'previdencia/features/RentabilidadeCotas/hooks/useObterExtratoRentabilidadeCotas';
import { CalendarData } from 'main/components/form/Calendar/Calendar.type';
import SkeletonLoading from 'main/components/SkeletonLoading';
import RangeDate from 'previdencia/types/RangeDate';
import {
  calcularPeriodo,
  getOptions,
} from 'previdencia/features/RentabilidadeCotas/utils/calcularPeriodo';
import PeriodoExtrato from 'previdencia/components/PeriodoExtrato/PeriodoExtrato';
import RenderConditional from 'main/components/RenderConditional';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';

const ConsultarPeriodo: React.FC = () => {
  const [periodoLink, validatePeriodo] = useFieldLink('');
  const [inputDateLink, validateInputDate] = useFieldLink({
    finalDate: null,
    initialDate: null,
  } as CalendarData);

  const periodo = periodoLink.get().value;
  const periodoCustomizado = inputDateLink.get().value;
  const [rangeDate, setRangeDate] = useState<RangeDate>({} as RangeDate);

  const { fetchData, response, loading } =
    useObterExtratoRentabilidadeCotas(rangeDate);

  const sucesso = response?.entidade !== undefined;
  const data = response?.entidade;

  useEffect(() => {
    if (periodo === 'custom') {
      setRangeDate({ dataInicial: undefined, dataFinal: undefined });
      return;
    }
    setRangeDate(calcularPeriodo(periodo));
  }, [periodo]);

  useEffect(() => {
    setRangeDate({
      dataInicial: periodoCustomizado.initialDate,
      dataFinal: periodoCustomizado.finalDate,
    } as RangeDate);
  }, [periodoCustomizado]);

  return (
    <>
      <Grid className="hide-print">
        <Grid.Item xs={1 / 2}>
          <Text variant="body01-lg" color="primary" margin>
            Informe o período:
          </Text>

          <PeriodoExtrato
            options={getOptions()}
            periodoLink={periodoLink}
            inputDateLink={inputDateLink}
            validatePeriodo={validatePeriodo}
            validateInputDate={validateInputDate}
            refetch={fetchData}
            loading={loading}
          />
        </Grid.Item>
        <Grid.Item xs={1}>
          <RenderConditional condition={loading}>
            <SkeletonLoading />
          </RenderConditional>

          <RenderConditional
            condition={checkIfAllItemsAreTrue([sucesso, !loading])}
          >
            <ExtratoRentabilidade data={data} rangeDate={rangeDate} />
          </RenderConditional>
        </Grid.Item>
      </Grid>
    </>
  );
};

export default ConsultarPeriodo;
