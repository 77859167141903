const usePrint = (): { print: () => void } => {
  const print = () => {
    window.print();
    return null;
  };

  return { print };
};

export default usePrint;
