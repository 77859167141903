import Chart from 'react-apexcharts';
import { Disclaimer } from '@cvp/design-system/react';

import Icon from 'main/components/Icon';
import SkeletonLoading from 'main/components/SkeletonLoading';
import RenderConditional from 'main/components/RenderConditional';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { WrapperSlideFadeContent } from 'main/components/Wrappers/styled/WrapperSlideFadeContent';
import * as CONSTS from 'evolucaoPatrimonial/constants/constants';
import * as EVOLUCAO_PATRIMONIAL_TYPES from 'evolucaoPatrimonial/types/EvolucaoPatrimonial';
import * as S from './styles';

const Grafico = ({
  dadosConsulta,
  loadingConsultaEvolucaoPatrimonial,
  options,
  series,
}: EVOLUCAO_PATRIMONIAL_TYPES.IGraficoProps) => {
  return (
    <>
      <RenderConditional condition={loadingConsultaEvolucaoPatrimonial}>
        <S.Display type="block">
          <SkeletonLoading lines={8} />
        </S.Display>
      </RenderConditional>

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          !loadingConsultaEvolucaoPatrimonial,
          !!dadosConsulta,
        ])}
      >
        <WrapperSlideFadeContent>
          <S.Display type="block">
            <Chart options={options} series={series} type="area" height="400" />
          </S.Display>
        </WrapperSlideFadeContent>
      </RenderConditional>

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          !loadingConsultaEvolucaoPatrimonial,
          !dadosConsulta,
        ])}
      >
        <WrapperSlideFadeContent>
          <S.Display type="block">
            <S.Disclaimer variant="error">
              <Disclaimer.Content
                text={CONSTS.MENSAGEM_NO_DATA}
                icon={<Icon name="information" />}
              />
            </S.Disclaimer>
          </S.Display>
        </WrapperSlideFadeContent>
      </RenderConditional>
    </>
  );
};

export default Grafico;
