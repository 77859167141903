import styled from 'styled-components/macro';
import { Button } from '@cvp/design-system/react';

export const ButtonAnoConsultaIR = styled(Button)({
  marginRight: 15,
  marginBottom: 25,
});

export const ButtonContainer = styled.div(() => ({
  display: 'flex',
  marginTop: 10,
  gap: '10px',
}));

export const WrapperSelect = styled.div(() => ({
  alignItems: 'center',
  maxWidth: 300,
  marginBottom: 20,
}));
