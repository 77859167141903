import React from 'react';
import { Text, TextField } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import masks from 'main/utils/masks';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  getTernaryResult,
} from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import * as UTILS from 'previdencia/features/SolicitacaoResgate/utils/SolicitacaoResgate';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';
import * as S from 'previdencia/features/SolicitacaoResgate/pages/styles';

const ColunaValorRetirar = ({
  row,
  setSelectedTableInputFocus,
  selectedTableInputFocus,
  isTipoResgateParcial,
  isTipoResgateTotal,
  valorMinimoResgate,
  handleValorRetirar,
}: SOLICITACAO_RESGATE_TYPES.IColunaValorRetirarProps) => {
  return (
    <S.ContainerInputHelper>
      <TextField
        onFocus={() => {
          setSelectedTableInputFocus(row.codigoFundo);
        }}
        autoFocus={row.codigoFundo === selectedTableInputFocus}
        disabled={checkIfSomeItemsAreTrue([
          !row.selecionado,
          isTipoResgateTotal,
          row.tipoResgate === CONSTS.ID_SIGLA_TOTAL,
        ])}
        name={`listaFundosParaResgate[valorRetirar_${row.codigoFundo}]`}
        helpText={
          <RenderConditional condition={isTipoResgateParcial}>
            <>
              <Text variant="caption-02">
                Valor mínimo {tryGetMonetaryValueOrDefault(valorMinimoResgate)}
              </Text>
              <Text variant="caption-02">
                Valor máximo{' '}
                {tryGetMonetaryValueOrDefault(
                  getTernaryResult(
                    checkIfAllItemsAreTrue([
                      isTipoResgateParcial,
                      row.tipoResgate === CONSTS.ID_SIGLA_PARCIAL,
                    ]),
                    row.saldoTotal - row.valorMinimoPermanencia,
                    row.saldoTotal,
                  ),
                )}
              </Text>
            </>
          </RenderConditional>
        }
        placeholder={tryGetMonetaryValueOrDefault('0')}
        value={masks.currency.mask(Number(row.valorRetirar))}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement>) => {
          handleValorRetirar(
            row.codigoFundo,
            (Number(masks.currencyInput.unmask(value)) / 100).toFixed(2),
          );
        }}
        error={
          UTILS.errorValidationFormDataTable(
            row,
            valorMinimoResgate,
            isTipoResgateParcial,
          ).error
        }
        errorMessage={
          UTILS.errorValidationFormDataTable(
            row,
            valorMinimoResgate,
            isTipoResgateParcial,
          ).errorMessage
        }
      />
    </S.ContainerInputHelper>
  );
};

export default ColunaValorRetirar;
