import React from 'react';
import ReactDataTable, { IDataTableProps } from 'react-data-table-component';
import * as S from './styles';

const Table: React.FC<IDataTableProps> = props => {
  return (
    <S.ReactDataTable>
      <ReactDataTable {...props} />
    </S.ReactDataTable>
  );
};

export default Table;
