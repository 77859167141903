import getAuthData from 'main/features/Auth/utils/auth';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import { validate } from 'main/features/Validation/utils/validateRules';
import { useRegistrarOcorrenciaContext } from 'registroOcorrenciaASC/features/registrarOcorrencia/contexts/RegistrarOcorrenciaContext';
import { useEnviarAnexoRegistroOcorrencia } from 'registroOcorrenciaASC/hooks';
import {
  usePecoCriarProtocolo,
  usePecoCriarCamposDinamicos,
  usePecoCriarCliente,
  usePecoAtualizarCliente,
} from 'registroOcorrenciaASC/features/registrarOcorrencia/hooks/usePecoRegistrarOcorrencia';
import * as FACTORY from 'registroOcorrenciaASC/features/registrarOcorrencia/factories/RegistrarOcorrenciaFactory';
import * as RESPONSE_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrenciasResponse';
import * as REGISTRO_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrencias';

export const useEnviarOcorrencia = ({
  validateArquivoOcorrencia,
}: REGISTRO_OCORRENCIA_TYPES.IBotaoEnviarOcorrenciaProps) => {
  const { user } = getAuthData();

  const {
    cpfCnpjCliente,
    formDadosOcorrencia,
    formDadosCliente,
    formFop,
    salvarDadosAberturaOcorrencia,
  } = useRegistrarOcorrenciaContext();

  const { loadingDadosProtocolo, obterProtocolo } = usePecoCriarProtocolo();

  const { loadingDadosCriacaoCamposDinamicos, criarCamposDinamicos } =
    usePecoCriarCamposDinamicos();

  const { loadingDadosCriacaoCliente, criarCliente } = usePecoCriarCliente();

  const { loadingDadosAtualizacaoCliente, atualizarCliente } =
    usePecoAtualizarCliente();

  const { enviarAnexo, loadingDadosEnvioAnexo } =
    useEnviarAnexoRegistroOcorrencia();

  const payloadAberturaOcorrencia = FACTORY.payloadAberturaOcorrencia({
    formDadosOcorrencia,
    formDadosCliente,
    formFop,
    cpfCnpjCliente,
    user,
  });

  const payloadAtualizacaoCliente = FACTORY.payloadAtualizacaoCliente({
    formDadosCliente,
    cpfCnpjCliente,
  });

  const payloadCriacaoCliente = FACTORY.payloadCriacaoCliente({
    formDadosCliente,
    cpfCnpjCliente,
  });

  const enviarOcorrenciaCamposDinamicos = async (
    protocoloCriado: RESPONSE_TYPES.ICriarProtocoloResponse,
  ): Promise<void> => {
    if (formDadosOcorrencia.listaCamposDinamicos.length) {
      await criarCamposDinamicos(
        FACTORY.payloadCriacaoCamposDinamicos({
          formDadosOcorrencia,
          protocoloCriado,
        }),
      );
    }
  };

  const enviarOcorrenciaDadosCliente = async (): Promise<void> => {
    if (formDadosCliente.novoCliente) {
      await criarCliente(payloadCriacaoCliente);
    } else {
      await atualizarCliente(payloadAtualizacaoCliente);
    }
  };

  const enviarOcorrenciaDadosAnexo = async (
    protocoloCriado: RESPONSE_TYPES.ICriarProtocoloResponse,
  ): Promise<void> => {
    if (formDadosOcorrencia.arquivoAnexo.length) {
      const promessasEnvioAnexos = Array.from(
        formDadosOcorrencia.arquivoAnexo,
      ).map(async arquivo => {
        const formData = new FormData();
        formData.append('Arquivo', arquivo);
        formData.append(
          'CodigoSolicitacao',
          protocoloCriado?.codigoSolicitacao,
        );
        formData.append('DescricaoAnexo', '');

        await enviarAnexo(formData);
      });

      await Promise.all(promessasEnvioAnexos);
    }
  };

  const enviarOcorrenciaASC = async (): Promise<void> => {
    if (
      checkIfAllItemsAreTrue([
        !!formDadosOcorrencia.arquivoAnexo.length,
        !validate([validateArquivoOcorrencia]),
      ])
    ) {
      return;
    }

    const responseProtocoloOcorrenciaASC = await obterProtocolo(
      payloadAberturaOcorrencia,
    );

    const protocoloCriado = responseProtocoloOcorrenciaASC?.entidade;

    if (protocoloCriado?.codigoSolicitacao) {
      await enviarOcorrenciaCamposDinamicos(protocoloCriado);

      await enviarOcorrenciaDadosCliente();

      await enviarOcorrenciaDadosAnexo(protocoloCriado);

      salvarDadosAberturaOcorrencia({
        ...protocoloCriado,
        ocorrenciaAberta: true,
      });
    }
  };

  const validarEnvioOcorrencia = checkIfSomeItemsAreTrue([
    loadingDadosProtocolo,
    loadingDadosCriacaoCamposDinamicos,
    loadingDadosCriacaoCliente,
    loadingDadosAtualizacaoCliente,
    loadingDadosEnvioAnexo,
  ]);

  return {
    enviarOcorrenciaASC,
    validarEnvioOcorrencia,
  };
};
