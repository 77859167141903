import styled from 'styled-components/macro';

export const Container = styled.div(() => ({
  display: 'flex',
}));

export const Label = styled.div(({ theme: { color } }) => ({
  marginTop: '3px',
  marginRight: '10px',
  color: color.brandPrimary.medium,
}));

export const Button = styled.button(({ theme: { color } }) => ({
  background: 'none',
  display: 'flex',
  border: 'none',
  padding: '0',
  font: 'inherit',
  cursor: 'pointer',
  outline: 'inherit',
  '> svg': {
    fill: color.brandPrimary.medium,
  },
}));
