import { Grid } from '@cvp/design-system/react';
import Accordion from 'main/components/PEComponents/Accordion/Accordion';
import { IDadosFinanciamentoProps } from 'prestamista/features/dadosSeguro/types/IDadosFinanciamento';
import React from 'react';
import { DadosFinanciamentoSkeleton } from 'prestamista/features/dadosSeguro/components/DadosFinanciamento';
import { DadosFinanciamentoCampo } from './DadosFinanciamentoCampo';

export const DadosFinanciamento: React.FC<IDadosFinanciamentoProps> = ({
  data,
  loading,
}) => {
  if (loading) return <DadosFinanciamentoSkeleton />;
  return (
    <Accordion open>
      <Accordion.Item
        title="Informações Financeiras"
        key="Financiamento"
        data-testid="tabela-dadosFinanciamento"
      >
        <Grid>
          <DadosFinanciamentoCampo
            label="Bem segurado"
            value={data.bemSegurado}
          />
          <DadosFinanciamentoCampo
            value={data.dataIniVigencia}
            label="Início contrato"
          />
          <DadosFinanciamentoCampo
            value={data.dataFimVigencia}
            label="Fim contrato"
          />
          <DadosFinanciamentoCampo
            value={data.quantidadeMesesContrato}
            label="Meses de contrato"
          />
          <DadosFinanciamentoCampo
            value={data.dataVencimentoPremioEndosso}
            label="Dia de vencimento"
          />
          <DadosFinanciamentoCampo
            value={data.valorParcelaPremioEndosso}
            label="Valor do prêmio"
          />
          <DadosFinanciamentoCampo
            value={data.descricaoPeriodicidadeCobr}
            label="Periodicidade de pagamento"
          />
          <DadosFinanciamentoCampo
            value={data.descricaoTipoPagamento}
            label="Tipo de pagamento"
          />
          <DadosFinanciamentoCampo
            value={data.valorImpostoSeguroMip}
            label="Valor capital segurado"
          />
        </Grid>
      </Accordion.Item>
    </Accordion>
  );
};
