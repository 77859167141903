import * as Yup from 'yup';

const validationMessage = 'Campo obrigatório.';

const FormFopsValidationSchema = Yup.object({
  nomeEmpresa: Yup.string().required(validationMessage),
  atividadePrincipal: Yup.string().required(validationMessage),
  cnpj: Yup.string().required(validationMessage),
  nomeCompleto: Yup.string().required(validationMessage),
  email: Yup.string().required(validationMessage),
  // faturamento: Yup.string().required(validationMessage),
  // superintendenciaRegional: Yup.string().required(validationMessage),
  // numeroAgencia: Yup.string().required(validationMessage),
  // nomeAgencia: Yup.string().required(validationMessage),
  // matriculaIndicador: Yup.string().required(validationMessage),
  // nomeIndicador: Yup.string().required(validationMessage),
  // emailInstitucional: Yup.string().required(validationMessage),
  // logradouro: Yup.string().required(validationMessage),
  // bairro: Yup.string().required(validationMessage),
  // cidade: Yup.string().required(validationMessage),
  // cep: Yup.string().required(validationMessage),
  // filial: Yup.string().required(validationMessage),
  // nomeCompleto: Yup.string().required(validationMessage),
  // email: Yup.string().required(validationMessage),
  // telefone: Yup.string().required(validationMessage),
  // numeroParticipantes: Yup.string().required(validationMessage),
  // valorFixo: Yup.string().required(validationMessage),
  // valorPercentual: Yup.string().required(validationMessage),
  // valorFixoEmpresa: Yup.string().required(validationMessage),
  // valorFixoEmpresaCuidadoExtra: Yup.string().required(validationMessage),
  // valorFixoFuncionario: Yup.string().required(validationMessage),
  // valorFixoFuncionarioCuidadoExtra: Yup.string().required(validationMessage),
  // valorPercentualEmpresa: Yup.string().required(validationMessage),
  // valorPercentualEmpresaCuidadoExtra: Yup.string().required(validationMessage),
  // valorPercentualFuncionario: Yup.string().required(validationMessage),
  // valorPercentualFuncionarioCuidadoExtra:
  //   Yup.string().required(validationMessage),
  // valorFixoCuidadoExtra: Yup.string().required(validationMessage),
  // valorPercentualCuidadoExtra: Yup.string().required(validationMessage),
  // nomeRepresentante: Yup.string().required(validationMessage),
  // emailRepresentante: Yup.string().required(validationMessage),
  // cargoRepresentante: Yup.string().required(validationMessage),
  // valorPortabilidade: Yup.string().required(validationMessage),
  // aporteInicial: Yup.string().required(validationMessage),
  // prazoContribuicao: Yup.string().required(validationMessage),
  // idadeAposentadoria: Yup.string().required(validationMessage),
  // aporteUnico: Yup.string().required(validationMessage),
  // agencia: Yup.string().required(validationMessage),
  // conta: Yup.string().required(validationMessage),
});

export default FormFopsValidationSchema;
