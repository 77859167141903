import RenderConditional from 'main/components/RenderConditional';
import { Card, Display, Button } from '@cvp/design-system/react';
import RelatorioHierarquia from 'painelPortabilidade/pages/RelatorioHierarquia';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import useHierarquiaPortabilidade from 'painelPortabilidade/hooks/useHierarquiaPortabilidade';
import HeaderPainelPortabilidade from 'painelPortabilidade/components/Header/HeaderPainelPortabilidade';

const RelatoriolInadimplencia = () => {
  const {
    tipoVisao,
    unidade,
    setPayloadConsulta,
    loading,
    handleConsulta,
    dadosHierarquiaPortabilidade,
    dadosHierarquiaPortabilidadeAgencia,
    handleBackStep,
    isDisabled,
  } = useHierarquiaPortabilidade();

  return (
    <>
      <HeaderPainelPortabilidade tipoVisao={tipoVisao} unidade={unidade} />
      <Display type="display-block">
        <Card>
          <Card.Content>
            <Display>
              <Button
                disabled={checkIfSomeItemsAreTrue([loading, isDisabled])}
                type="submit"
                variant="outlined"
                onClick={() => handleBackStep()}
              >
                Voltar
              </Button>
            </Display>

            <RenderConditional condition={loading}>
              <SkeletonLoading lines={8} />
            </RenderConditional>

            <RenderConditional condition={!loading}>
              <RelatorioHierarquia
                setpayloadUnidade={setPayloadConsulta}
                handleConsultar={handleConsulta}
                loading={loading}
                dataHierarquia={dadosHierarquiaPortabilidade?.entidade}
                dataAgencia={dadosHierarquiaPortabilidadeAgencia?.entidade}
              />
            </RenderConditional>
          </Card.Content>
        </Card>
      </Display>
    </>
  );
};

export default RelatoriolInadimplencia;
