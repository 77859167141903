import { usePeco } from 'main/hooks/usePeco';
import { useToast } from 'main/hooks/useToast';
import { getBlobUrl } from 'main/utils/blob';
import { PECOS_PRESTAMISTA } from 'prestamista/config/endpoints';
import {
  IRequestEmitirCertificado,
  IResponseEmitirCertificado,
} from 'prestamista/types/EmissaoCertificado';

export const usePecoEmitirCertificado = (numeroContrato: string) => {
  const { toastError } = useToast();
  const { loading, error, response } = usePeco<
    IRequestEmitirCertificado,
    IResponseEmitirCertificado[]
  >({
    api: { operationPath: PECOS_PRESTAMISTA.GerarCertificadoSeguroPrestamista },
    autoFetch: true,
    payload: {
      numeroContrato,
    },
  });

  const emitirCertificado = async (base64: string) => {
    try {
      if (base64) {
        getBlobUrl(base64);
      }
    } catch {
      toastError(
        'Não foi possível emitir o certificado, tente novamente mais tarde.',
      );
    }
  };

  return {
    emitirCertificado,
    response,
    loading,
    error,
  };
};
