import { useContext } from 'react';
import { useToast } from 'main/hooks/useToast';
import { useQuery, UseQueryResult } from 'react-query';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { AppContext } from 'main/contexts/AppContext';
import { ResponseDadosCertificadosPorCpf } from '../types/DadosCertificado';
import * as DadosCpfPorCertificadoApi from '../services/dadosCpfPorCertificado.api';

const useObterDadosCpfPorCertificado = (): UseQueryResult<
  ResponseDadosCertificadosPorCpf[] | undefined
> => {
  const { toastError } = useToast();
  const { cliente } = useContext(AppContext);

  return useQuery(
    ['prev-consultar-dados-cpf-por-certificado', cliente?.cpfCnpj],
    () =>
      DadosCpfPorCertificadoApi.obterDadosCpfPorCertificado(cliente?.cpfCnpj),
    {
      refetchOnWindowFocus: false,
      cacheTime: reactQueryCacheDuration(),
      retry: false,
      onError: (erro: Error) => toastError(erro.message),
    },
  );
};

export default useObterDadosCpfPorCertificado;
