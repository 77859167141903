import React, { useEffect, useState } from 'react';
import Table from 'main/components/Table';
import { Button } from '@cvp/design-system/react';
import { formatarData } from 'main/utils';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { converterBase64 } from 'main/utils/converterBase64';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { COLUNAS_HISTORICO_SOLICITACOES } from 'previdencia/constants/constants';
import { TabelaMovimentacoesProps } from 'previdencia/features/HistoricoSolicitacoes/types/HistoricoSolicitacoesProps';
import useComprovanteResgate from 'previdencia/features/HistoricoSolicitacoes/hooks/useComprovanteResgate';
import {
  verificarBotaoClicado,
  verificarBotaoDesabilitado,
} from 'previdencia/features/HistoricoSolicitacoes/utils/historicoSolicitacoes';
import {
  PrevRedirectByStatus,
  StatusRequisicaoComprovante,
  CodigoTipoRequisicao,
} from 'previdencia/features/HistoricoSolicitacoes/types/enum';
import { useHistory } from 'react-router';
import { removeEmptySpaceAndForceUpperCase } from 'main/utils/string';
import { ResponseHistoricoSolicitacoes } from 'previdencia/features/HistoricoSolicitacoes/types/HistoricoSolicitacoes';

const TabelaMovimentacoes: React.FC<TabelaMovimentacoesProps> = ({
  dados,
  loading,
}) => {
  const [codRequisicao, setCodRequisicao] = useState('');
  const [idRequisicaoRequest, setIdRequisicaoRequest] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const { data, obterComprovanteHistorico } = useComprovanteResgate(
    codRequisicao,
    idRequisicaoRequest,
  );
  const history = useHistory();

  useEffect(() => {
    if (codRequisicao && idRequisicaoRequest) {
      setIsFetching(true);
      obterComprovanteHistorico();
    }
  }, [codRequisicao, idRequisicaoRequest]);

  useEffect(() => {
    if (data) {
      const blob = converterBase64(data.comprovante);
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
      setIsFetching(false);
    }
  }, [data]);

  const RenderBotaoBaixar: React.FC<{
    idRequisicao: string;
    tipoRequisicao: string;
  }> = ({ idRequisicao, tipoRequisicao }) => (
    <Button
      variant="primary"
      onClick={() => {
        setIdRequisicaoRequest(idRequisicao);
        setCodRequisicao(tipoRequisicao);
      }}
      loading={verificarBotaoClicado(
        idRequisicaoRequest,
        idRequisicao,
        isFetching,
      )}
      disabled={verificarBotaoDesabilitado(
        idRequisicaoRequest,
        idRequisicao,
        isFetching,
      )}
    >
      Baixar
    </Button>
  );

  const RenderBotaoReassinar: React.FC<{
    idRequisicao: string;
    tipoRequisicao: string;
  }> = ({ idRequisicao, tipoRequisicao }) => (
    <Button
      variant="primary"
      onClick={() => {
        history.push({
          pathname:
            PrevRedirectByStatus[
              removeEmptySpaceAndForceUpperCase(tipoRequisicao)
            ],
          state: { from: 'HISTORICO_SOLICITACOES', data: { idRequisicao } },
        });
      }}
    >
      Assinar
    </Button>
  );

  const omitirBotaoAcao = (dadosBotao: ResponseHistoricoSolicitacoes) => {
    const tiposRequisicaoOmitir = [
      CodigoTipoRequisicao.ALTERACAO_ENDERECO_TEL_EMAIL,
    ];

    const omitirBotao = tiposRequisicaoOmitir.find(
      codTipoRequisicao => codTipoRequisicao === dadosBotao.tipoRequisicao,
    );

    if (omitirBotao) {
      return '-';
    }

    return null;
  };

  const BotaoAcao = (dadosBotao: ResponseHistoricoSolicitacoes) => {
    return (
      omitirBotaoAcao(dadosBotao) ??
      {
        [StatusRequisicaoComprovante.CONCLUIDA]: (
          <RenderBotaoBaixar
            idRequisicao={dadosBotao.idRequisicao}
            tipoRequisicao={dadosBotao.tipoRequisicao}
          />
        ),
        [StatusRequisicaoComprovante.EM_PAGAMENTO]: (
          <RenderBotaoBaixar
            idRequisicao={dadosBotao.idRequisicao}
            tipoRequisicao={dadosBotao.tipoRequisicao}
          />
        ),
        [StatusRequisicaoComprovante.SOLICITADA]: (
          <RenderBotaoBaixar
            idRequisicao={dadosBotao.idRequisicao}
            tipoRequisicao={dadosBotao.tipoRequisicao}
          />
        ),
        [StatusRequisicaoComprovante.AGUARDANDO_ASSINATURA]: (
          <RenderBotaoReassinar
            idRequisicao={dadosBotao.idRequisicao}
            tipoRequisicao={dadosBotao.tipoRequisicao}
          />
        ),
      }[removeEmptySpaceAndForceUpperCase(dadosBotao.statusRequisicao)]
    );
  };

  const dadosHistoricoSolicitacoes = () => {
    return dados?.map(item => ({
      servico: item.codigoRequisicao,
      realizado: formatarData(item.dataRequisicao),
      canal: item.canalRequisicao,
      comprovante: BotaoAcao(item),
      status: item.statusRequisicao,
      valor: formatarValorPadraoBrasileiro(item.valorRequisicao),
    }));
  };

  if (loading) {
    return <SkeletonLoading blocks={1} />;
  }

  return (
    <Table
      noHeader
      responsive
      data={tryGetValueOrDefault([dadosHistoricoSolicitacoes()], [])}
      columns={COLUNAS_HISTORICO_SOLICITACOES}
      noDataComponent="Não há dados para exibir."
      pagination
      paginationPerPage={10}
      paginationComponentOptions={{
        rowsPerPageText: 'Items por página',
        rangeSeparatorText: 'de',
      }}
    />
  );
};

export default TabelaMovimentacoes;
