import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'main/components/Route';
import { IPrivateRoute } from 'main/components/Route/PrivateRoute';
import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';
import { mapBreadCrumbsFromRoutes } from 'main/components/Layout/AppBreadcrumb/mapBreadcrumb';
import StatusDPS from 'dps/features/solicitarDps/pages/StatusDPS';

const routes: IPrivateRoute[] = [
  {
    path: '/dps/consultar-status',
    component: StatusDPS,
    key: 'consultar-status',
    breadcrumb: 'Consultar Status',
    authenticated: true,
    withSidebar: true,
    exact: true,
    requiredRoles: [USER_PROFILES.ANALISTA_TI],
  },
  {
    path: '/dps/solicitar',
    component: StatusDPS,
    key: 'solicitar-DPS',
    breadcrumb: 'Solicitar DPS',
    authenticated: true,
    withSidebar: true,
    exact: true,
    requiredRoles: [USER_PROFILES.ANALISTA_TI],
  },
  {
    path: '/dps/pendentes',
    component: StatusDPS,
    key: 'pendentes',
    breadcrumb: 'DPS Pendentes',
    authenticated: true,
    withSidebar: true,
    exact: true,
    requiredRoles: [USER_PROFILES.ANALISTA_TI],
  },
];

export const painelDPSBreadcrumbs = {
  ...mapBreadCrumbsFromRoutes(routes),
  '/painelDPS': null,
};

const RotasDPS = (): React.ReactElement => (
  <Switch>
    {routes.map(route => (
      <PrivateRoute
        key={route.key}
        path={route.path}
        exact={route.exact}
        component={route.component}
        authenticated={route.authenticated}
        requiredRoles={route.requiredRoles}
      />
    ))}
  </Switch>
);

export default RotasDPS;
