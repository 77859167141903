import { useEffect } from 'react';
import { useToast } from 'main/hooks/useToast';
import { useQuery } from 'react-query';

import { useAuth } from 'main/features/Auth/hooks';
import * as comprovanteResgateApi from 'previdencia/services/comprovante.api';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import {
  TResponseConfirmarResgate,
  TUseObterComprovanteResgate,
} from 'previdencia/types/ObterComprovanteResgate';

const useObterComprovanteResgate = (
  cpf: string,
  numCertificado: string,
  idRequisicao: string,
  isSucessConfirmarResgate: boolean,
  codigoRequisicao: string,
  confirmarResgate?: TResponseConfirmarResgate | undefined,
): TUseObterComprovanteResgate => {
  const { toastError, toastSuccess } = useToast();
  const { user } = useAuth();

  const { data, isFetching, isSuccess, isRefetchError, refetch } = useQuery(
    ['-prev-comprovante-resgate', cpf, numCertificado, idRequisicao],
    () =>
      comprovanteResgateApi.obterComprovanteOperacao(
        cpf,
        numCertificado,
        idRequisicao,
        codigoRequisicao,
        user.nomeAcesso,
      ),
    {
      staleTime: reactQueryCacheDuration(),
      enabled: false,
      retry: false,
      onSuccess: () => toastSuccess('Gerando comprovante...'),
      onError: (error: Error) => toastError(error.message),
    },
  );

  useEffect(() => {
    if (isSucessConfirmarResgate && confirmarResgate) {
      refetch();
    }
  }, [isSucessConfirmarResgate, confirmarResgate, refetch]);

  return {
    data,
    isFetching,
    isSuccess,
    isRefetchError,
    refetch,
  };
};

export default useObterComprovanteResgate;
