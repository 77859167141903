import React, { PropsWithChildren } from 'react';
import { Display, Card, StepBar } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { useContratosPrestamistaContext } from 'contratosPrestamista/contexts/ContratosPrestamistaContext';
import {
  IDpsEletronicaFeatureDataFluxoPF,
  IDpsEletronicaFeatureDataFluxoPJ,
} from 'contratosPrestamista/features/dpseletronica/types/IDpsEletronicaFeatureDataFluxoPF';

const StepBarWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { featureData } = useContratosPrestamistaContext<
    IDpsEletronicaFeatureDataFluxoPF | IDpsEletronicaFeatureDataFluxoPJ
  >();

  return (
    <Card>
      <Card.Content>
        <RenderConditional condition={!!featureData?.showStepBar}>
          <Display>
            <StepBar
              step={featureData?.etapaAtual}
              steps={featureData?.etapas}
              disableClickEvent
            />
          </Display>
        </RenderConditional>
        {children}
      </Card.Content>
    </Card>
  );
};

export default StepBarWrapper;
