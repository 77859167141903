import React from 'react';
import { Accordion, Card, Grid, Text, Display } from '@cvp/design-system/react';
import { TextLabel } from 'main/styles/GlobalStyle';
import { useParams } from 'react-router';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { useListarPortabilidadesEntrada } from '../hooks/usePortabilidadeEntrada';
import { converterParaDetalhes } from '../factories/listagemPortabilidadeEntradaFactory';
import RenderConditional from 'main/components/RenderConditional';

const DetalhesPortabilidadeEntrada: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { response, loading } = useListarPortabilidadesEntrada();

  const recuperaPortabilidadeEntrada = () =>
    response?.dadosPortabilidadeResposta.find(
      x => x.dadosPortabilidade[0].numeroPortabilidade === id,
    );

  const portabilidade = converterParaDetalhes(recuperaPortabilidadeEntrada());

  return (
    <>
      <RenderConditional condition={loading}>
        <SkeletonLoading />
      </RenderConditional>
      <Display>
        <Card>
          <Card.Content>
            <Accordion open>
              <Accordion.Item title="Detalhe da Portabilidade">
                <Grid>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Número da proposta
                      </TextLabel>
                      <Text variant="body02-sm" prospeccao-testid="cpfcnpj">
                        {portabilidade?.numeroPortabilidade}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Data da solicitação
                      </TextLabel>
                      <Text variant="body02-sm" prospeccao-testid="nomeCliente">
                        {portabilidade?.dataSolicitacao}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Data do pedido da portabilidade no SIDE
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        prospeccao-testid="dataNascimento"
                      >
                        {portabilidade?.dataPedidoPortabilidadeSide}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Status da portabilidade
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        prospeccao-testid="dataNascimento"
                      >
                        {portabilidade?.statusPortabilidade}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Nome do participante
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="codigoAgenciaVenda"
                      >
                        {portabilidade?.nomeParticipante}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        CPF do participante
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="propostaInicioVigencia"
                      >
                        {portabilidade?.cpfParticipante}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Valor da portabilidade
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="valorPremioLiquido"
                      >
                        {portabilidade?.valorPortabilidade}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Nome da EACP/Seguradora cedente
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="valorPremioLiquido"
                      >
                        {portabilidade?.nomeSeguradoraCedente}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        CNPJ da EAPC/Seguradora cedente
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="valorImportanciaSegurada"
                      >
                        {portabilidade?.cnpjSeguradoraCedente}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        CNPJ do fundo cedente
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="descricaoPeriodicidadeCobranca"
                      >
                        {portabilidade?.cnpjFundoCedente}
                      </Text>
                    </div>
                  </Grid.Item>

                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Nome do plano cessionário
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="descricaoPeriodicidadeCobranca"
                      >
                        {portabilidade?.nomePlanoCessioanario}
                      </Text>
                    </div>
                  </Grid.Item>

                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Regime tributário
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="descricaoPeriodicidadeCobranca"
                      >
                        {portabilidade?.regimeTributario}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">Tipo de plano</TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="descricaoPeriodicidadeCobranca"
                      >
                        {portabilidade?.tipoPlano}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Número do Certificado
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="descricaoPeriodicidadeCobranca"
                      >
                        {portabilidade?.numeroCertificado}
                      </Text>
                    </div>
                  </Grid.Item>
                  {/* */}
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">Banco</TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="descricaoPeriodicidadeCobranca"
                      >
                        {portabilidade?.banco}
                      </Text>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={1} lg={1 / 3}>
                    <div>
                      <TextLabel variant="body02-sm">
                        Conta com dígito
                      </TextLabel>
                      <Text
                        variant="body02-sm"
                        data-testid="descricaoPeriodicidadeCobranca"
                      >
                        {portabilidade?.contaComDigito}
                      </Text>
                    </div>
                  </Grid.Item>
                </Grid>
              </Accordion.Item>
            </Accordion>
          </Card.Content>
        </Card>
      </Display>
    </>
  );
};

export default DetalhesPortabilidadeEntrada;
