import styled from 'styled-components/macro';
import { Card, Text } from '@cvp/design-system/react';

export const CardStyle = styled(Card)({
  width: '100%',
  position: 'relative',
});

export const CardStyleCancelado = styled(Card)(({ theme: { color } }) => ({
  width: '100%',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  background: color.neutral['06'],
  textAlign: 'center',
}));

export const ContainerTipoContribuicao = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  textAlign: 'center',
  padding: '20px',
  justifyContent: 'center',
  position: 'relative',
});

export const ContainerInformacoesAdicionais = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  textAlign: 'center',
  alignItems: 'center',
  position: 'relative',
});

export const ContainerInformacoesAdicionaisCuidadoExtra = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  textAlign: 'center',
  alignItems: 'center',
  position: 'relative',
});
export const ContainerSwitch = styled.div({
  display: 'block',
  position: 'absolute',
  right: '15px',
  top: 0,
});

export const TextSwitch = styled(Text)({
  fontSize: '11px',
  textAlign: 'center',
});

export const TextDescricaoItemContribuicao = styled(Text)({
  fontSize: '11px',
  margin: '5px 0 5px 0',
});

export const DescricaoContribuicao = styled.div(({ theme: { color } }) => ({
  borderLeft: `1px solid ${color.neutral['04']}`,
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  margin: 0,
  height: '60px',
}));

export const PrimeiroElementoDescricaoContribuicao = styled.div({
  alignItems: 'center',
  justifyContent: 'center',
  height: '60px',
  padding: 0,
  margin: 0,
});
