import axios from 'axios';
import { registrarAxiosMocks } from 'config/axiosConfig';

const { REACT_APP_API_BASE_URL } = process.env;

export const api = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
});

registrarAxiosMocks(api);
