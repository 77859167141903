import React, { useContext, useEffect } from 'react';
import { ListaPreAnalise } from 'main/features/prospeccao/components/ListaPreAnalise';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { IProspeccao } from 'main/features/prospeccao/types/IProspeccao';
import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';
import { ProspeccaoContext } from 'main/features/prospeccao/contexts/ProspeccaoContext';
import { useListarProspeccoes } from '../../../hooks/useListarProspeccoes';

const ListaProspeccoesAnaliseMedica: React.FC = () => {
  const { getMedicoRouteDetails } = useContext(ProspeccaoContext);

  const { prospeccoesPerfilMedico, obterListaProspeccoesPerfilMedico } =
    useListarProspeccoes();

  useEffect(() => {
    obterListaProspeccoesPerfilMedico();
  }, []);

  if (prospeccoesPerfilMedico.loading) return <SkeletonLoading lines={4} />;

  return (
    <div>
      <ListaPreAnalise
        profile={USER_PROFILES.MEDICO}
        onClickDetails={(prospeccao: IProspeccao) =>
          getMedicoRouteDetails(prospeccao)
        }
        data={prospeccoesPerfilMedico.items ?? []}
        title="Análise Médica"
        noDataMessage="Não há prospecções para análise médica"
      />
    </div>
  );
};

export default ListaProspeccoesAnaliseMedica;
