import React, { useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Grid, Pagination } from '@cvp/design-system/react';
import { AppContext } from 'main/contexts/AppContext';
import { IDadosBasicosCertificadoPrevidencia } from 'consultaCliente/features/listaCardProduto/interfaces/IDadosBasicosCertificadoPrevidencia';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { EnumFormaPagamento } from 'consultaCliente/types/ICardsApresentacao';
import {
  IDadosClientePrev,
  IClientePrevidencia,
} from 'previdencia/types/IClientePrevidencia';
import { CardPrevidencia } from './CardPrevidencia';
import RenderConditional from 'main/components/RenderConditional';
import TextoInformativoSemProdutosPrevidencia from './TextoInformativoSemProdutosPrevidencia';

type ListaCertificadosProps = {
  certificados?: IDadosBasicosCertificadoPrevidencia[];
  codCliente: string;
  itensPorPagina: number;
};

const ListaCertificados: React.FunctionComponent<ListaCertificadosProps> = ({
  certificados,
  codCliente,
  itensPorPagina,
}) => {
  const { navigateTo } = usePrevNavigation();
  const QueryClient = useQueryClient();
  const { setCliente } = useContext(AppContext);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [certificadosPage, setCertificadosPage] = useState<
    IDadosBasicosCertificadoPrevidencia[] | undefined
  >();

  const converterFormaPagamento = (pagamentoMetodo: string): string => {
    switch (pagamentoMetodo) {
      case EnumFormaPagamento.CB:
        return 'Débito em Conta';
      case EnumFormaPagamento.FC:
        return 'Ficha de Compensão';
      default:
        return '';
    }
  };

  const handleProductDetails = (item: IDadosBasicosCertificadoPrevidencia) => {
    setCliente({
      cpfCnpj: codCliente,
      numCertificado: item.certificadoNumero,
      dados: {
        cerficadosDetalhado: item.certificadoBeneficios,
        duracao: item.aposentadoria,
        diaVencimento: item?.pagamentoRegular?.pagamentoDia,
        formaPagamento: converterFormaPagamento(
          item?.pagamentoRegular?.pagamentoMetodo,
        ),
      },
    } as IClientePrevidencia<IDadosClientePrev>);
    QueryClient.setQueryData(`certificado:${item.certificadoNumero}`, item);
    navigateTo('dados-plano', {
      cerficadosDetalhado: item.certificadoBeneficios,
      duracao: item.aposentadoria,
      diaVencimento: item.pagamentoRegular?.pagamentoDia,
      formaPagamento: converterFormaPagamento(
        item.pagamentoRegular?.pagamentoMetodo,
      ),
    });
  };

  useEffect(() => {
    const tamanho = (certificados || [])?.length;
    const paginas = tamanho / itensPorPagina;
    setTotalPages(tamanho % itensPorPagina === 0 ? paginas : paginas + 1);
    setCertificadosPage(certificados?.slice(0, itensPorPagina));
  }, [certificados]);

  useEffect(() => {
    setCertificadosPage(
      certificados?.slice((page - 1) * itensPorPagina, page * itensPorPagina),
    );
  }, [page]);

  if ((certificados || [])?.length === 0) {
    return <TextoInformativoSemProdutosPrevidencia />;
  }
  return (
    <>
      <Grid>
        {certificadosPage?.map((item: IDadosBasicosCertificadoPrevidencia) => (
          <Grid.Item xs={1} lg={1 / 4}>
            <CardPrevidencia
              onClick={() => handleProductDetails(item)}
              key={item.certificadoNumero}
              empresaId={item.empresaId}
              certificadoNumero={item.certificadoNumero}
              certificadoTipo={item.certificadoTipo}
              produto={item.produto}
              pessoa={item.pessoa}
              situacao={item.situacao}
              regimeTributario={item.regimeTributario}
              apoliceId={item.apoliceId}
              abertura={item.abertura}
              emissao={item.emissao}
              aposentadoria={item.aposentadoria}
              pagamentoRegular={item.pagamentoRegular}
              certificadoBeneficios={item.certificadoBeneficios}
            />
          </Grid.Item>
        ))}
      </Grid>
      <br />
      <RenderConditional
        condition={(certificados || [])?.length > itensPorPagina}
      >
        <Pagination page={page} total={totalPages} onChange={setPage} />
      </RenderConditional>
    </>
  );
};

export default ListaCertificados;
