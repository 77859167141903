import React from 'react';
import {
  Button,
  Grid,
  Text,
  Select,
  TextField,
} from '@cvp/design-system/react';

import masks from 'main/utils/masks';
import Icon from 'main/components/Icon';
import RenderConditional from 'main/components/RenderConditional';
import { showErrorInput } from 'main/utils/formik';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { IListarFundosParaResgateResponse } from 'previdencia/types/ConsultaListaFundosParaResgate';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';
import * as S from 'previdencia/features/SolicitacaoResgate/pages/styles';

const FiltroSimulacaoResgate = ({
  formik,
  valorMinimoResgate,
  valorMaximoResgate,
  valorMinimoPermanencia,
  listaTiposResgateFiltro,
  isInputValorSolicitadoDesabilitado,
  isTipoResgateParcial,
  isDisabledConfirmarSimulacao,
  alterarValorSolicitadoTotal,
  confirmarFiltroDesejadoParaSimulacao,
  resetarInputFocus,
}: SOLICITACAO_RESGATE_TYPES.IFiltroSimulacaoResgateProps) => {
  const { goDadosPlano } = usePrevNavigation();

  const { featureData } =
    usePrevidenciaContext<SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData>();

  const { indicadorPermiteResgate } = tryGetValueOrDefault(
    [featureData?.dadosListarFundosParaResgate],
    {} as IListarFundosParaResgateResponse,
  );

  return (
    <>
      <RenderConditional condition={!!indicadorPermiteResgate}>
        <Grid.Item xs={1}>
          <Text variant="body02-md" margin>
            Simule um resgate e saiba quais são os encargos.
          </Text>
        </Grid.Item>

        <Grid.Item xs={1} xl={1 / 4}>
          <S.ContainerInputHelper>
            <Select
              name="tipoResgate"
              label="Tipo de Resgate:"
              placeholder="Selecione uma opção"
              onClick={resetarInputFocus}
              onBlur={() => {
                formik.setFieldTouched('tipoResgate', true);
              }}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) => {
                alterarValorSolicitadoTotal(value);
              }}
              {...showErrorInput(formik, 'tipoResgate')}
            >
              {listaTiposResgateFiltro.map(item => (
                <Select.Item
                  key={item.id}
                  value={item.id}
                  text={item.value}
                  selected={formik.values.tipoResgate === item.id}
                />
              ))}
            </Select>
          </S.ContainerInputHelper>
        </Grid.Item>

        <Grid.Item xs={1} xl={1 / 4}>
          <S.ContainerInputHelper>
            <TextField
              name="valorSolicitado"
              label="Valor Solicitado:"
              helpText={
                <RenderConditional
                  condition={formik.values.tipoResgate === CONSTS.ID_PARCIAL}
                >
                  <Text variant="caption-02">
                    Valor mínimo{' '}
                    {tryGetMonetaryValueOrDefault(valorMinimoResgate)}
                  </Text>
                  <Text variant="caption-02">
                    Valor máximo{' '}
                    {tryGetMonetaryValueOrDefault(
                      valorMaximoResgate - valorMinimoPermanencia,
                    )}
                  </Text>
                </RenderConditional>
              }
              disabled={isInputValorSolicitadoDesabilitado}
              placeholder={tryGetMonetaryValueOrDefault('0')}
              value={masks.currency.mask(Number(formik.values.valorSolicitado))}
              onBlur={formik.handleBlur}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue(
                  'valorSolicitado',
                  (Number(masks.currencyInput.unmask(value)) / 100).toFixed(2),
                );
              }}
              {...showErrorInput(formik, 'valorSolicitado')}
            />
          </S.ContainerInputHelper>
        </Grid.Item>

        <RenderConditional condition={isTipoResgateParcial}>
          <Grid.Item xs={1}>
            <S.ContainerWarning>
              <div>
                <Icon name="warning" />
                <Text color="text-dark" variant="body02-sm">
                  Observações para RESGATE PARCIAL:
                </Text>
              </div>
              <Text variant="body02-sm" margin>
                O plano deverá permanecer com reserva mínima de{' '}
                {tryGetMonetaryValueOrDefault(valorMinimoPermanencia)}.
              </Text>
            </S.ContainerWarning>
          </Grid.Item>
        </RenderConditional>
      </RenderConditional>

      <Grid.Item xs={1}>
        <S.ContainerNav>
          <Button variant="outlined" onClick={goDadosPlano}>
            Voltar
          </Button>

          <Button
            variant="primary"
            onClick={confirmarFiltroDesejadoParaSimulacao}
            disabled={isDisabledConfirmarSimulacao}
          >
            Confirmar
          </Button>
        </S.ContainerNav>
      </Grid.Item>
    </>
  );
};

export default FiltroSimulacaoResgate;
