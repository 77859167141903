import { isCnpj, isCpf } from 'main/utils/cpf_cnpj';

const useTipoPessoa = (cpfCnpj: string) => {
  if (isCpf(cpfCnpj)) {
    return 'PF';
  }
  if (isCnpj(cpfCnpj)) {
    return 'PJ';
  }

  return '';
};

export default useTipoPessoa;
