export const status = {
  VIDA_ATIVO: 'ATIVO',
  PREST_ATIVO: 'Ativo',
  PREV_ATIVO: 'A',
  PREV_ATIVO_COBRANCA_SUSPENSA: 'U',
};

export const tab = {
  PREVIDENCIA: 0,
  VIDA: 1,
  PRESTAMISTA: 2,
};
