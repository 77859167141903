import React, { useEffect, useState } from 'react';
import { TipoResgate } from 'previdencia/types/Fundo.type';
import Modal from 'main/components/Modal';
import { Select } from '@cvp/design-system/react';
import useFieldLink from 'main/hooks/useFieldLink';
import * as S from './styles';
import { TipoTransferencia } from 'previdencia/types/MultifundosPJ';

type SelectTipoResgateProps = {
  onTipoTransferencia: (tipo: string) => void;
  tipoResgate?: TipoResgate;
  hidden?: boolean;
  desabilitaTotal?: boolean;
};

const SelectTipoResgate: React.FC<SelectTipoResgateProps> = ({
  onTipoTransferencia,
  tipoResgate,
  hidden,
  desabilitaTotal,
}) => {
  const [tipoResgateLink] = useFieldLink(TipoTransferencia.TOTAL.toString());
  const tipoResgateSelecionado = tipoResgateLink.get().value;
  const [openModal, setOpenModal] = useState(false);
  const [mensagemModal, setMensagemModal] = useState<string>('');
  const [mostrarSelect, setMostrarSelect] = useState(true);

  const isSelected = (resgate: string) => {
    return tipoResgateSelecionado === resgate;
  };

  useEffect(() => {
    let mudarValor = true;
    if (tipoResgateSelecionado === TipoTransferencia.TOTAL && desabilitaTotal) {
      mudarValor = false;
      setMostrarSelect(false);
      setMensagemModal(
        'Não é possivel fazer mais de uma transferência total!!',
      );
      setOpenModal(true);
    }
    if (mudarValor) {
      if (!mostrarSelect) {
        setMostrarSelect(true);
      }
      onTipoTransferencia(tipoResgateSelecionado);
    }
  }, [tipoResgateSelecionado, hidden]);

  return (
    <>
      {!hidden && mostrarSelect && (
        <>
          <S.ResgateSelect
            placeholder="Selecione..."
            link={tipoResgateLink}
            data-testid="select-options"
            dettach
          >
            <Select.Item
              value="Total"
              text="Total"
              selected={isSelected(TipoTransferencia.TOTAL)}
            />
            {(tipoResgate === 'totalEParcial' && (
              <Select.Item
                value="Parcial"
                text="Parcial"
                selected={isSelected(TipoTransferencia.PARCIAL)}
              />
            )) || <S.SelectItemHidden />}
          </S.ResgateSelect>
        </>
      )}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        {mensagemModal}
      </Modal>
    </>
  );
};

SelectTipoResgate.defaultProps = {
  tipoResgate: 'totalEParcial',
  hidden: false,
  desabilitaTotal: false,
};

export default SelectTipoResgate;
