import React from 'react';
import { Dropdown } from 'styled-dropdown-component';
import BotaoIcone from '../BotaoFiltrar';
import * as S from './styles';

type FiltroStatusProps = {
  hidden: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  children: React.ReactNode;
  toggle: () => void;
};

const FiltroStatus: React.FC<FiltroStatusProps> = ({
  hidden,
  children,
  toggle,
  onClick,
}) => {
  return (
    <S.FilterComponent>
      <Dropdown fullWidth>
        <BotaoIcone onClick={onClick} />
        <S.DropdownMenu fullWidth hidden={hidden} toggle={toggle}>
          {children}
        </S.DropdownMenu>
      </Dropdown>
    </S.FilterComponent>
  );
};

export default FiltroStatus;
