import { useQuery } from 'react-query';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import * as PropostaApi from 'contratosPrestamista/features/proposta/services/proposta.api';
import { useToast } from 'main/hooks/useToast';

export const useObterListaPropostas = () => {
  return useQuery('propostas', PropostaApi.obterListaPropostas, {
    staleTime: reactQueryCacheDuration(),
    retry: false,
  });
};

export const useObterProposta = (
  id: string,
  numeroLinhaDoProduto: string,
  codigoDoEstipulante: string,
) => {
  const { toastError } = useToast();
  return useQuery(
    ['propostas', id],
    () =>
      PropostaApi.obterProposta(id, numeroLinhaDoProduto, codigoDoEstipulante),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      onError: (erro: Error) => toastError(erro.message),
    },
  );
};
