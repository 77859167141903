import React, { useState, useEffect } from 'react';
import { Card, Grid, Text } from '@cvp/design-system/react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import {
  CODIGO_CONSENTIMENTO,
  DadosAlteracao,
  EditarParticipanteProps,
  RequestAlterarEmail,
  RequestAlterarEndereco,
  RequestAlterarTelefone,
} from 'previdencia/features/DadosParticipante/types/DadosParticipante';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import { Input } from 'main/components/form';
import useFieldLink from 'main/hooks/useFieldLink';
import { minLength, required } from 'main/features/Validation/validations';
import masks from 'main/utils/masks';
import { useQueryClient } from 'react-query';
import * as S from 'previdencia/features/DadosParticipante/components/EditarParticipante/styles';
import FormAtualizarDadosParticipante from 'previdencia/features/DadosParticipante/components/FormAtualizarDadosParticipante/index';
import useObterEnderecoCEP from 'previdencia/features/DadosParticipante/hooks/useObterEnderecoCEP';
import useAtualizarEndereco from 'previdencia/features/DadosParticipante/hooks/useAtualizarEndereco';
import useAtualizarTelefone from 'previdencia/features/DadosParticipante/hooks/useAtualizarTelefone';
import useAtualizarEmail from 'previdencia/features/DadosParticipante/hooks/useAtualizarEmail';
import useConsultarConsentimento from 'previdencia/features/DadosParticipante/hooks/useConsultarConsentimento';
import RenderConditional from 'main/components/RenderConditional';

const EditarParticipante: React.FC<EditarParticipanteProps> = ({
  dadosParticipante,
  onCancelar,
}) => {
  function isCepValid(cepValue: string) {
    return cepValue.length === 8;
  }
  const queryClient = useQueryClient();
  const [cepLink] = useFieldLink(
    tryGetValueOrDefault([dadosParticipante?.cep], ''),
  );

  const [cep, setCep] = useState(cepLink.get().value);
  const cepAtual = dadosParticipante?.cep;
  const { data: dadosCep, refetch: refetchAtualizarCep } =
    useObterEnderecoCEP(cep);

  const [dadosEndereco, setDadosEndereco] = useState<
    RequestAlterarEndereco | undefined
  >();
  const [dadosTelefone, setDadosTelefone] = useState<
    RequestAlterarTelefone[] | undefined
  >();
  const [dadosEmail, setDadosEmail] = useState<
    RequestAlterarEmail | undefined
  >();

  const {
    isFetching: fetchingAtualizarEndereco,
    refetch: refetchAtualizarEndereco,
  } = useAtualizarEndereco(dadosEndereco, onCancelar);

  const {
    isFetching: fetchingAtualizarTelefone,
    refetch: refetchAtualizarTelefone,
  } = useAtualizarTelefone(dadosTelefone, onCancelar);

  const { isFetching: fetchingAtualizarEmail, refetch: refetchAtualizarEmail } =
    useAtualizarEmail(dadosEmail, onCancelar);

  const {
    data: dadosConsentimentoCaixaVidaPrevidencia,
    isLoading: loadingConsentimentoCaixaVidaPrevidencia,
  } = useConsultarConsentimento(CODIGO_CONSENTIMENTO.caixa.toString());

  useEffect(() => {
    if (cepLink.get().isValid) {
      refetchAtualizarCep();
    }
  }, [cep]);

  useEffect(() => {
    if (isCepValid(cepLink.get().value)) {
      setCep(cepLink.get().value);
    }
  }, [cepLink]);

  useEffect(() => {
    if (dadosEndereco) {
      refetchAtualizarEndereco();
    }

    if (dadosTelefone) {
      refetchAtualizarTelefone();
    }

    if (dadosEmail) {
      refetchAtualizarEmail();
    }
  }, [dadosEndereco, dadosTelefone, dadosEmail]);

  const atualizarDados = (dados: DadosAlteracao) => {
    setDadosEndereco(dados.dadosEndereco);
    setDadosTelefone(dados.dadosTelefone);
    setDadosEmail(dados.dadosEmail);
    queryClient.invalidateQueries('prev-dadosParticipante');
  };

  useEffect(() => {
    if (dadosCep?.cidade === null) {
      cepLink.set({
        value: dadosCep.cep,
        isValid: false,
        errorMsg: 'CEP inexistente',
      });
      setCep(tryGetValueOrDefault([cepAtual?.toString()], ''));
    }
  }, [dadosCep]);

  return (
    <S.ParticipantEditContainer>
      <PrevidenciaResumo />

      <RenderConditional condition={loadingConsentimentoCaixaVidaPrevidencia}>
        <SkeletonLoading blocks={1} />
      </RenderConditional>

      <RenderConditional condition={!loadingConsentimentoCaixaVidaPrevidencia}>
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Text tabIndex={0} variant="headline-05" color="primary" margin>
              Dados do Participante
            </Text>
            <Grid>
              <Grid.Item xs={1} sm={1 / 6} md={1 / 6} xl={1 / 6}>
                <Input
                  link={cepLink}
                  aria-label="Digite seu cep"
                  aria-required
                  label="Cep"
                  placeholder="Digite seu cep..."
                  validationRules={[
                    required(),
                    minLength(min => `Tamanho mínimo é ${min} caracteres`, 8),
                  ]}
                  inputMask={masks.cep}
                />
              </Grid.Item>
            </Grid>
            <Grid>
              <FormAtualizarDadosParticipante
                dadosParticipante={dadosParticipante}
                dadosCep={dadosCep}
                dadosConsentimentos={dadosConsentimentoCaixaVidaPrevidencia}
                fetchingDados={checkIfSomeItemsAreTrue([
                  fetchingAtualizarEmail,
                  fetchingAtualizarEndereco,
                  fetchingAtualizarTelefone,
                ])}
                onAtualizar={atualizarDados}
                onCancelar={async () => {
                  await refetchAtualizarCep().then(onCancelar);
                }}
                cepLink={cepLink}
              />
            </Grid>
          </Card.Content>
        </Card>
      </RenderConditional>
    </S.ParticipantEditContainer>
  );
};

export default EditarParticipante;
