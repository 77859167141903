import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';

export const STATUS_PROSPECCAO = {
  PRE_CADASTRADA: 1,
  AGUARDANDO_ANALISE_MEDICA: 2,
  AVALIACAO_MEDICA_FINALIZADA_APROVADA: 3,
  AVALIACAO_MEDICA_FINALIZADA_REPROVADA: 4,
  AVALIACAO_TECNICA_FINALIZADA_APROVADA: 5,
  AVALIACAO_TECNICA_FINALIZADA_REPROVADA: 6,
  EXCLUIDA: 7,
};

export const FILTER_STATUS_BY_PROFILE = {
  [(USER_PROFILES.ANALISTA_POS_VENDA, USER_PROFILES.ECONOMIARIO)]: [
    { id: 1, description: 'Cadastro em andamento' },
    { id: 2, description: 'Aguardando análise médica' },
    { id: 3, description: 'Avaliação médica - aprovada' },
    { id: 4, description: 'Avaliação médica - reprovada' },
    { id: 5, description: 'Avaliação técnica - aprovada' },
    { id: 6, description: 'Avaliação técnica - reprovada' },
  ],
  [USER_PROFILES.MEDICO]: [
    { id: 2, description: 'Aguardando análise Médica' },
    { id: 3, description: 'Avaliação médica - aprovada' },
    { id: 4, description: 'Avaliação médica - reprovada' },
  ],
  [USER_PROFILES.ANALISTA_PRODUTO]: [
    { id: 3, description: 'Avaliação médica - aprovada' },
    { id: 4, description: 'Avaliação médica - reprovada' },
    { id: 5, description: 'Avaliação técnica - aprovada' },
    { id: 6, description: 'Avaliação técnica - reprovada' },
  ],
  [USER_PROFILES.OPERADOR]: [
    { id: 5, description: 'Avaliação técnica - aprovada' },
    { id: 6, description: 'Avaliação técnica - reprovada' },
  ],
};
