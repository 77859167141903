import React from 'react';
import ValidableProps from 'main/features/Validation/types/ValidableProps';
import withValidation from 'main/features/Validation/hocs/withValidation';
import RenderConditional from 'main/components/RenderConditional';
import LinkedValue from 'main/features/Validation/types/LinkedValue';
import { Display, Text, Tag } from '@cvp/design-system/react';
import { UploadButton, Input } from './styles';

interface InputFileProps extends ValidableProps<FileList> {
  error?: boolean;
  maxSizeMb?: number;
  formatsAllowed?: string[];
  multiple?: boolean;
}

export const DescriptionFormatsFilesAllowed: React.FC<{
  fileSize?: string;
}> = ({ fileSize = '5' }) => (
  <Text variant="caption-02" margin>
    Formatos de arquivos suportados: PDF, JPG, JPEG e PNG.
    <br />
    Tamanho máximo do arquivo: {fileSize}MB
  </Text>
);

const InputFile: React.FC<InputFileProps> = ({ link, ...props }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    link.set({
      value: files ?? new FileList(),
      isValid: true,
      errorMsg: '',
    });
  };

  const retornarMensagemErro = (linkMessage: LinkedValue<FileList>) => {
    if (!linkMessage.get().errorMsg) return '';

    return linkMessage.get().errorMsg;
  };

  return (
    <>
      <UploadButton>
        <Input {...props} onChange={handleChange} />
        Selecione o arquivo
      </UploadButton>
      <Display justify="center">
        {Array.from(link.get().value).map(item => (
          <Tag variant="primary" value={item.name} />
        ))}
      </Display>
      <RenderConditional condition={!link.get().isValid}>
        <Display justify="center">
          <Text
            variant="caption-02"
            color="error"
            data-testid="mensagemErroUploadFile"
          >
            {retornarMensagemErro(link)}
          </Text>
        </Display>
      </RenderConditional>
    </>
  );
};

InputFile.defaultProps = {
  error: false,
  formatsAllowed: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
  multiple: false,
  maxSizeMb: 1,
};

export default withValidation<InputFileProps, FileList>(InputFile);
