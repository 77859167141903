import styled from 'styled-components/macro';

export const TableBodyWrapper = styled.div<{ isRemoved: boolean }>(
  ({ isRemoved }) => ({
    opacity: isRemoved ? '1' : '0.4',
  }),
);

export const InputPorcent = styled.input<{
  totalBeneficiario: boolean | undefined;
}>(({ theme, totalBeneficiario }) => ({
  width: 60,
  height: 35,
  padding: 10,
  borderRadius: 3,
  border: totalBeneficiario
    ? `0px solid ${theme.color.neutral['02']}`
    : `1px solid ${theme.color.neutral['02']}`,
  outline: 'none',
  marginRight: 5,

  '&:focus': {
    borderColor: theme.color.brandPrimary.light,
  },
}));

export const WrapperSwitch = styled.div({
  paddingRight: 14,
  alignSelf: 'center',
  justifyContent: 'flex-end',
  display: 'flex',
});
