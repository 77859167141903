import { useState, useContext } from 'react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import * as DadosParticipanteApi from 'main/services/dadosParticipante.api';
import { DadosParticipante } from '../../main/types/Participante';

export const useObterDadosParticipante = () => {
  const { cliente } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { toastError } = useToast();
  const [response, setResponse] = useState<DadosParticipante | undefined>();

  const obterDadosParticipante = async (): Promise<void> => {
    try {
      setLoading(true);
      const result = await DadosParticipanteApi.obterDadosParticipante(
        cliente?.cpfCnpj,
        cliente?.numCertificado,
      );

      setResponse(result);
    } catch (requestError: any) {
      toastError(requestError);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    response,
    obterDadosParticipante,
  };
};
