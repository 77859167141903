import React from 'react';
import {
  Display,
  Modal,
  Button,
  Text,
  Grid,
  TextField,
} from '@cvp/design-system/react';
import masks from 'main/utils/masks';
import { showErrorInput } from 'main/utils/formik';
import RenderConditional from 'main/components/RenderConditional';
import { IModalAtualizacaoDadosProps } from '../types/IModalAtualizacaoDadosProps';

const ModalAtualizacaoDados: React.FunctionComponent<
  IModalAtualizacaoDadosProps
> = ({ open, loading, handleClose, formik }) => (
  <Modal show={open} onClose={handleClose}>
    <Text variant="caption" margin>
      <strong>Atualização de dados na base única</strong>
    </Text>
    <div style={{ marginTop: 20 }}>
      <Grid>
        <Grid.Item xs={1} md={1} lg={1}>
          <TextField
            {...formik.getFieldProps(formik.values.email)}
            label="E-mail*"
            name="email"
            value={formik.values.email}
            {...showErrorInput(formik, 'email')}
            margin
          />
        </Grid.Item>
        <Grid.Item xs={1} md={1} lg={1}>
          <TextField
            {...formik.getFieldProps(formik.values.telefone)}
            label="Telefone*"
            name="telefone"
            value={masks.phone.mask(formik.values.telefone)}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('telefone', masks.phone.unmask(value));
            }}
            {...showErrorInput(formik, 'telefone')}
          />
        </Grid.Item>
      </Grid>
    </div>
    <Display justify="center">
      <Button
        variant="primary"
        onClick={() => formik.submitForm()}
        data-testid="confirmar"
        loading={loading}
      >
        Confirmar
      </Button>
      <RenderConditional condition={!loading}>
        <Button
          variant="secondary"
          onClick={handleClose}
          data-testid="cancelar"
        >
          Cancelar
        </Button>
      </RenderConditional>
    </Display>
  </Modal>
);

export default ModalAtualizacaoDados;
