import getAuthData from 'main/features/Auth/utils/auth';
import { obterLinkAtendimentoSalesForceOnline } from 'previdencia/config/endpoints';

const { REACT_APP_CHAT_BASE_URL } = process.env;

export const chatAtendimentoSalesForce = () => {
  const { user } = getAuthData();
  const chatLink = `${REACT_APP_CHAT_BASE_URL}/${obterLinkAtendimentoSalesForceOnline(
    '573Do0000000Aj0',
  )}`;

  const metadataLink = `&name=${user?.nomeUsuario}&email=${
    user?.email
  }&phone=${'0'}&document=${user?.nomeAcesso}`;

  return `${chatLink}${metadataLink}`;
};
