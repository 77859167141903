import React, { useState } from 'react';
import { Text, Display, Button, Select } from '@cvp/design-system/react';
import Modal from 'main/components/Modal';
import Icon from 'main/components/Icon';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import useFieldLink from 'main/hooks/useFieldLink';
import { useToast } from 'main/hooks/useToast';
import RenderConditional from 'main/components/RenderConditional';
import { required } from 'main/features/Validation/validations';
import SkeletonLoading from 'main/components/SkeletonLoading';
import InputSelect from 'main/components/form/InputSelect';
import * as S from 'main/components/AssinaturaDocuSign/styles';
import {
  ModalAlertaContatosProps,
  dadosBancario,
} from 'main/components/AssinaturaDocuSign/types/ModalAlertaDadosBancario.types';

const ModalAlertaDadosBancarios: React.FC<ModalAlertaContatosProps> = ({
  open,
  onClose,
  onContinue,
  listaInstituicoesBancarias,
  dados,
  isFetchingDados,
  hookAtualizar,
}) => {
  const { toastError, toastSuccess } = useToast();

  const [isInput, setIsInput] = useState(false);
  const [numeroBancoLink] = useFieldLink('');
  const [agenciaLink] = useFieldLink('');
  const [digitoAgenciaLink] = useFieldLink('');
  const [operacaoLink] = useFieldLink('');
  const [contaLink] = useFieldLink('');
  const [digitoContaLink] = useFieldLink('');
  const [tipoContaBancariaLink] = useFieldLink('CC');
  const [dadosBancarioAtualizado, setdadosBancarioAtualizado] =
    useState<dadosBancario>();

  const handleAtualizarDadosBancarios = async () => {
    const novoDadosBancario = {
      codBanco: numeroBancoLink.get().value,
      agencia: agenciaLink.get().value,
      digitoAgencia: digitoAgenciaLink.get().value,
      digitoVerificador: digitoContaLink.get().value,
      nomeTitular: dados.nomeTitular,
      conta: contaLink.get().value,
      tipoContaBancaria: tipoContaBancariaLink.get().value,
      operacao: operacaoLink.get().value,
    };
    setdadosBancarioAtualizado(novoDadosBancario);
    if (!isInput) {
      setIsInput(true);
    } else {
      const result = await hookAtualizar.atualizarDadosBancarios(
        novoDadosBancario,
      );
      if (result || hookAtualizar.response) {
        setIsInput(false);

        toastSuccess(result || hookAtualizar.response);
      } else {
        toastError(hookAtualizar.response);
      }
    }
  };

  const handleClose = () => {
    setIsInput(false);
    onClose();
  };

  const dadosAtualizado = tryGetValueOrDefault(
    [dadosBancarioAtualizado],
    dados,
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <Text color="secondary">
        <strong>
          <Icon name="warning" /> Atenção!
        </strong>
      </Text>
      <Display type="display-block">
        <Text variant="body02-md" align="center">
          Dados bancários informados no ato da compra:
        </Text>
      </Display>
      <RenderConditional condition={!isInput}>
        <RenderConditional condition={isFetchingDados}>
          <SkeletonLoading blocks={1} />
        </RenderConditional>
        <RenderConditional condition={!isFetchingDados}>
          <S.ContainerDadosBancarios>
            <S.GridItem xs={1}>
              <S.ItemListaDadosBancarios>
                <Text variant="body02-sm">
                  <strong>Código do Banco:</strong>
                </Text>
                <Text variant="body02-sm">
                  {tryGetValueOrDefault(
                    [dadosAtualizado?.codBanco],
                    'Não informado',
                  )}
                </Text>
              </S.ItemListaDadosBancarios>
            </S.GridItem>
            <S.GridItem xs={1}>
              <S.ItemListaDadosBancarios>
                <Text variant="body02-sm">
                  <strong>Titular:</strong>
                </Text>
                <Text variant="body02-sm">
                  {tryGetValueOrDefault(
                    [dadosAtualizado?.nomeTitular],
                    'Não informado',
                  )}
                </Text>
              </S.ItemListaDadosBancarios>
            </S.GridItem>
            <S.GridItem xs={1} md={1 / 2}>
              <S.ItemListaDadosBancarios>
                <Text variant="body02-sm">
                  <strong>Agência:</strong>
                </Text>
                <Text variant="body02-sm">
                  {tryGetValueOrDefault(
                    [dadosAtualizado?.agencia],
                    'Não informada',
                  )}
                </Text>
              </S.ItemListaDadosBancarios>
            </S.GridItem>
            <S.GridItem xs={1} md={1 / 2}>
              <S.ItemListaDadosBancarios>
                <Text variant="body02-sm">
                  <strong>Dígito:</strong>
                </Text>
                <Text variant="body02-sm">
                  {tryGetValueOrDefault(
                    [dadosAtualizado?.digitoAgencia],
                    'Não informado',
                  )}
                </Text>
              </S.ItemListaDadosBancarios>
            </S.GridItem>
            <S.GridItem xs={1}>
              <S.ItemListaDadosBancarios>
                <Text variant="body02-sm">
                  <strong>Operação:</strong>
                </Text>
                <Text variant="body02-sm">
                  {tryGetValueOrDefault(
                    [dadosAtualizado?.operacao],
                    'Não informada',
                  )}
                </Text>
              </S.ItemListaDadosBancarios>
            </S.GridItem>
            <S.GridItem xs={1} md={3 / 5}>
              <S.ItemListaDadosBancarios>
                <Text variant="body02-sm">
                  <strong>Conta:</strong>
                </Text>
                <Text variant="body02-sm">
                  {tryGetValueOrDefault(
                    [dadosAtualizado?.conta],
                    'Não informada',
                  )}
                </Text>
              </S.ItemListaDadosBancarios>
            </S.GridItem>
            <S.GridItem xs={1} md={2 / 5}>
              <S.ItemListaDadosBancarios>
                <Text variant="body02-sm">
                  <strong>DV:</strong>
                </Text>
                <Text variant="body02-sm">
                  {tryGetValueOrDefault(
                    [dadosAtualizado?.digitoVerificador],
                    'Não informado',
                  )}
                </Text>
              </S.ItemListaDadosBancarios>
            </S.GridItem>
          </S.ContainerDadosBancarios>
        </RenderConditional>
      </RenderConditional>
      <RenderConditional condition={isInput}>
        <S.ContainerDadosBancarios>
          <S.GridItem xs={1} marginFormDadosBancarios>
            <InputSelect
              placeholder="Selecione um banco"
              label="Banco"
              validationRules={[required()]}
              link={numeroBancoLink}
            >
              {listaInstituicoesBancarias?.map(item => (
                <Select.Item
                  key={item.codigoBanco}
                  value={item.codigoBanco?.toString()}
                  text={`${item.codigoBanco} - ${item.nomeAbreviado}`}
                  selected={
                    numeroBancoLink.get().value === item.codigoBanco.toString()
                  }
                />
              ))}
            </InputSelect>
          </S.GridItem>
          <S.GridItem xs={1} md={3 / 5} marginFormDadosBancarios>
            <S.TextField
              label="Agência"
              maxLength="4"
              value={agenciaLink.get().value}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) =>
                agenciaLink.set({
                  value,
                  isValid: true,
                  errorMsg: '',
                })
              }
            />
          </S.GridItem>
          <S.GridItem xs={1} md={2 / 5} marginFormDadosBancarios>
            <S.TextField
              label="Dígito"
              maxLength="1"
              value={digitoAgenciaLink.get().value}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) =>
                digitoAgenciaLink.set({
                  value,
                  isValid: true,
                  errorMsg: '',
                })
              }
            />
          </S.GridItem>
          <S.GridItem xs={1} marginFormDadosBancarios>
            <S.TextField
              label="Operação"
              maxLength="4"
              value={operacaoLink.get().value}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) =>
                operacaoLink.set({
                  value,
                  isValid: true,
                  errorMsg: '',
                })
              }
            />
          </S.GridItem>
          <S.GridItem xs={1} md={3 / 5} marginFormDadosBancarios>
            <S.TextField
              label="Conta"
              maxLength="20"
              value={contaLink.get().value}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) =>
                contaLink.set({
                  value,
                  isValid: true,
                  errorMsg: '',
                })
              }
            />
          </S.GridItem>
          <S.GridItem xs={1} md={2 / 5} marginFormDadosBancarios>
            <S.TextField
              label="DV"
              maxLength="1"
              value={digitoContaLink.get().value}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) =>
                digitoContaLink.set({
                  value,
                  isValid: true,
                  errorMsg: '',
                })
              }
            />
          </S.GridItem>
        </S.ContainerDadosBancarios>
      </RenderConditional>
      <Text
        variant="caption-02"
        color="primary"
        style={{ marginTop: '10px' }}
        align="justify"
      >
        *A restituição somente pode ser creditada em conta de titularidade do
        participante titular.
      </Text>
      <Text
        variant="caption-02"
        color="primary"
        style={{ marginTop: '10px', marginBotton: '10px' }}
        align="justify"
      >
        *A restituição não pode ser encaminhada via SIVAT, conforme art. 25 das
        Circulares SUSEP 563 e 564 de 2017.
      </Text>
      <Display justify="center" style={{ marginTop: '10px' }}>
        <Button
          variant="secondary"
          onClick={handleAtualizarDadosBancarios}
          loading={hookAtualizar.loading}
          data-testid="cancelar"
        >
          Atualizar
        </Button>
        <Button variant="primary" onClick={onContinue} data-testid="submit">
          Continuar
        </Button>
      </Display>
    </Modal>
  );
};

export default ModalAlertaDadosBancarios;
