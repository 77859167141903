import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import {
  ResponseEntidadeModificarBeneficiarios,
  ModificarBeneficiario,
  IRequestEntidadeModificarBeneficiario,
} from 'previdencia/features/AlteracaoBeneficiarios/types/ModificarBeneficiario';
import { Assinante } from '../types/Beneficiarios';

const useModificarBeneficiario = (
  beneficiarios?: ModificarBeneficiario,
  permissaoAssinatura?: boolean,
  assinantes?: Assinante[],
) => {
  const { loading, response, fetchData } = usePeco<
    IRequestEntidadeModificarBeneficiario,
    ResponseEntidadeModificarBeneficiarios
  >({
    api: { operationPath: PECOS.ModificarBeneficiarios },
    autoFetch: false,
    payload: {
      NumeroCertificado: beneficiarios?.NumeroCertificado,
      Cpf: beneficiarios?.Cpf,
      IdPlano: beneficiarios?.IdPlano,
      IdBeneficio: beneficiarios?.IdBeneficio,
      AlterarBeneficiarios: beneficiarios?.AlterarBeneficiarios,
      AdicionarBeneficiarios: beneficiarios?.AdicionarBeneficiarios,
    },
  });

  return { loading, response, fetchData };
};

export default useModificarBeneficiario;
