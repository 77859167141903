import { useState, useContext } from 'react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import * as CalcularBeneficioApi from 'previdencia/features/AlterarValorContribuicao/services/calcularBeneficio.api';
import { DefaultValue } from 'previdencia/features/AlterarValorContribuicao/types/enum';
import {
  ObterCalculoBeneficioParams,
  ObterCalculoBeneficioResponse,
} from 'previdencia/features/AlterarValorContribuicao/types/CalcularBeneficio';

export const useCalcularBeneficio = () => {
  const { cliente } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { toastError } = useToast();
  const [response, setResponse] = useState<
    ObterCalculoBeneficioResponse | undefined
  >();

  const obterCalculoBeneficio = async (params: ObterCalculoBeneficioParams) => {
    try {
      setLoading(true);
      const result = await CalcularBeneficioApi.obterCalculoBeneficio({
        ...params,
        codigoCertificado: cliente?.numCertificado,
        codigoEmpresa: DefaultValue.CODIGO_EMPRESA,
        salario: DefaultValue.SALARIO,
        periodicidade: DefaultValue.PERIODICIDADE_MES,
        criterioCustomizacao: DefaultValue.CRITERIO_CUSTOMIZACAO_TIPO_C,
      });

      setResponse(result);

      return result;
    } catch (requestError: any) {
      toastError(requestError.message);
    } finally {
      setLoading(false);
    }

    return undefined;
  };

  return {
    loading,
    response,
    obterCalculoBeneficio,
  };
};
