export const aplicarMascaraTelefone = (telefone?: string): string => {
  if (!telefone) {
    return '';
  }

  const numero = telefone?.replace(/\D/g, '');

  if (numero.length === 0) {
    return '';
  }
  if (numero.length >= 11) {
    return `(${numero.substr(0, 2)}) ${numero.substr(2, 5)}-${numero.substr(
      7,
      4,
    )}`;
  }

  if (numero.length === 10) {
    return `(${numero.substr(0, 2)}) ${numero.substr(2, 4)}-${numero.substr(
      6,
      4,
    )}`;
  }

  if (numero.length > 2 && numero.length < 10) {
    return `(${numero.substr(0, 2)}) ${numero.substr(2, 4)}${numero.substr(
      6,
      4,
    )}`;
  }

  if (numero.length <= 2) {
    return `${numero.substr(0, 2)}`;
  }

  return `(${numero.substr(0, 2)}) ${numero.substr(2, 4)}-${numero.substr(
    6,
    4,
  )}`;
};
