import React from 'react';
import { Display, Button, Text } from '@cvp/design-system/react';
import { EModalWorkflowStep } from 'contratosPrestamista/features/cancelamento/types/IModalWorkflow';
import { IFormConfirmReversaoProps } from 'contratosPrestamista/features/cancelamento/types/IFormConfirmReversaoProps';

const FormConfirmReversao: React.FC<IFormConfirmReversaoProps> = ({
  handlerCancel,
  handleWorkflow,
}) => {
  return (
    <div data-testid="modalConfirmacaoReversao">
      <Text variant="headline-06" margin>
        Confirmar ação
      </Text>
      <Text variant="body-medium1" margin>
        Tem certeza que deseja reter esse contrato de Prestamista?
      </Text>

      <Display type="display-block">
        <Display justify="center" style={{ marginTop: '2rem ' }}>
          <Button
            type="click"
            variant="primary"
            onClick={() => handleWorkflow({ step: EModalWorkflowStep.SEND })}
            data-testid="confirmarReversao"
          >
            Sim
          </Button>
          <Button
            variant="secondary"
            onClick={handlerCancel}
            data-testid="cancelar"
          >
            Não
          </Button>
        </Display>
      </Display>
    </div>
  );
};

export default FormConfirmReversao;
