/* eslint-disable react/jsx-no-constructed-context-values */

import React, { PropsWithChildren, createContext, useContext } from 'react';
import { useFeatureData } from 'main/hooks/useFeatureData';

interface IContratosPrestamistaContext<T = unknown> {
  featureData?: T | null;
  setFeatureData: (data: T | null) => void;
  clearFeatureData: () => void;
}

export function createContratosPrestamistaContext<T = unknown>() {
  return createContext<IContratosPrestamistaContext<T> | null>(null);
}

const ContratosPrestamistaContext =
  createContratosPrestamistaContext<unknown>();

ContratosPrestamistaContext.displayName =
  'ContratosPrestamistaContextFeatureData';

export function useContratosPrestamistaContext<T>() {
  const context = useContext(ContratosPrestamistaContext);
  if (!context) {
    throw new Error(
      'useContratosPrestamistaContext deve ser usado com o SegurosContext',
    );
  }
  return context as IContratosPrestamistaContext<T>;
}

const ContratosPrestamistaProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { clearFeatureData, setFeatureData, featureData } = useFeatureData();

  return (
    <ContratosPrestamistaContext.Provider
      value={{
        featureData,
        setFeatureData,
        clearFeatureData,
      }}
    >
      {children}
    </ContratosPrestamistaContext.Provider>
  );
};

export default ContratosPrestamistaProvider;
