import styled from 'styled-components/macro';
import InputSelect from 'main/components/form/InputSelect';
import { Select } from '@cvp/design-system/react';

export const ResgateSelect = styled(InputSelect)(({ theme: { color } }) => ({
  minWidth: '130px',
  ':disabled': {
    cursor: 'not-allowed',
    background: color.neutral['06'],
    border: `1px solid ${color.neutral['02']}`,
  },
}));

export const SelectItemHidden = styled(Select.Item)(() => ({
  display: 'none',
}));
