import React, { useState } from 'react';
import { Text, Button, Display, Radio, Select } from '@cvp/design-system/react';
import guid from 'main/utils/guid';
import { DynamicFormSelectOption } from 'main/features/prospeccao/types/DesignSystemSelectProps';
import RenderConditional from 'main/components/RenderConditional';
import masks from 'main/utils/masks';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import LinkedValue from 'main/features/Validation/types/LinkedValue';
import useFieldLink from 'main/hooks/useFieldLink';
import * as S from '../styles';
import { Container } from './styles';
import { TEXTO_BENEFICIO, TEXTO_CONTRIBUICAO } from '../../constants';
import {
  obterCodigoPeriodicidade,
  obterValorCustomizado,
  obterValorMaximo,
  obterValorMinimo,
  validaFaixaRenda,
} from '../../utils/beneficioDeProtecao';
import CardCobertura from '../CardCobertura';
import useCalcularBeneficioProtecao from '../../hooks/useCalcularBeneficioProtecao';
import {
  EnumCobertura,
  EnumPeriodicidade,
  EnumSelectPeriodicidade,
  EnumTipoCalculo,
} from '../../types/enum';
import { IResponseCalcularBeneficioProtecao } from '../../types/CalcularBaneficioProtecaoTypes';

type PersonalizarProps = {
  response: IResponseCalcularBeneficioProtecao | undefined;
  cobertura: number | undefined;
  prazo: number | undefined;
  valorSelecionadoLink: LinkedValue<string>;
  setPersonalizar: (flag: boolean) => void;
  setPlano: (
    plano: string,
    contribuicao: number | undefined,
    beneficio: number | undefined,
  ) => void;
  proximaEtapa: () => void;
};
const Personalizar: React.FC<PersonalizarProps> = ({
  response,
  cobertura,
  prazo,
  valorSelecionadoLink,
  setPersonalizar,
  setPlano,
  proximaEtapa,
}) => {
  const [tipoCalculo, setTipoCalculo] = useState('');
  const [exibirCard, setExibirCard] = useState(false);
  const [valorPersonalizado] = useFieldLink('');
  const [periodicidade, setPeriodicidade] = useState('');

  const valorPersonalizadoNumber = Number(valorPersonalizado.get().value) / 100;
  const valorCustomizacaoPeculio =
    cobertura === EnumCobertura.peculio ? valorPersonalizadoNumber : undefined;
  const valorCustomizacaoPensao =
    cobertura === EnumCobertura.pensao ? valorPersonalizadoNumber : undefined;
  const payloadCalcularBeneficio = {
    salario: Number(valorSelecionadoLink.get().value) / 100,
    prazo,
    periodicidade: String(obterCodigoPeriodicidade(periodicidade)),
    criterioCustomizacao: tipoCalculo,
    valorCustomizacaoPeculio,
    valorCustomizacaoPensao,
  };

  const {
    response: responseCalcular,
    loading: loadingSimulacao,
    fetchData: obterCalcularBeneficio,
  } = useCalcularBeneficioProtecao(payloadCalcularBeneficio);

  const responseSimulacao = responseCalcular?.entidade;
  const calcularPlanoPersonalizado = () => {
    obterCalcularBeneficio();
    setExibirCard(true);
  };
  const limparDados = () => {
    setPeriodicidade('');
    setExibirCard(false);
    setTipoCalculo('');
  };
  const alterarPlano = (novoPlano: string) => {
    limparDados();
    setPeriodicidade(novoPlano);
    valorPersonalizado.set({ value: '', isValid: true, errorMsg: undefined });
  };

  const goToAdquirir = (plano: string, contribuicao: number | undefined) => {
    const beneficioPersonalizado =
      tipoCalculo === EnumTipoCalculo.beneficio
        ? Number(valorPersonalizado.get().value) / 100
        : obterValorCustomizado(periodicidade, cobertura, responseSimulacao);
    setPlano(plano.toLocaleLowerCase(), contribuicao, beneficioPersonalizado);
    proximaEtapa();
    setPersonalizar(false);
  };
  const voltar = () => {
    limparDados();
    setPersonalizar(false);
  };

  return (
    <>
      <Text variant="body01-lg" color="text" margin>
        Personalizar Cobertura
      </Text>
      <Text variant="body02-sm" color="text">
        Escolha como deseja contribuir:
      </Text>
      <Display>
        <Container>
          <Display justify="space-between">
            <Display alignItems="center">
              <Radio
                name="periodicidade"
                checked={periodicidade === EnumPeriodicidade.mensal}
                onChange={() => alterarPlano(EnumPeriodicidade.mensal)}
              />
              Mensal
            </Display>
            <Display alignItems="center">
              <Radio
                name="periodicidade"
                checked={periodicidade === EnumPeriodicidade.anual}
                onChange={() => alterarPlano(EnumPeriodicidade.anual)}
              />
              Anual
            </Display>
          </Display>
          <RenderConditional condition={periodicidade !== ''}>
            <Text variant="body02-sm" color="text" margin>
              Escolha uma das opções:
            </Text>
            <S.SelectContainer>
              <Select
                placeholder="Escolha uma opção"
                onChange={({
                  target: { value },
                }: React.ChangeEvent<DynamicFormSelectOption>) => {
                  setExibirCard(false);
                  setTipoCalculo(value);
                }}
              >
                <Select.Item
                  key={guid()}
                  value="C"
                  text="Definir o quanto contribuir"
                  selected={tipoCalculo === EnumTipoCalculo.contribuicao}
                />
                <Select.Item
                  key={guid()}
                  value="B"
                  text="Definir o valor da renda mensal"
                  selected={tipoCalculo === EnumTipoCalculo.beneficio}
                />
              </Select>
            </S.SelectContainer>
          </RenderConditional>
          <RenderConditional condition={tipoCalculo !== ''}>
            <S.StyledInput
              link={valorPersonalizado}
              placeholder="0,00"
              inputMask={masks.currency}
              label={
                tipoCalculo === EnumTipoCalculo.beneficio
                  ? TEXTO_BENEFICIO
                  : TEXTO_CONTRIBUICAO
              }
            />
            <Text variant="body01-sm" color="text-light" margin>
              Valor entre{' '}
              {formatarValorPadraoBrasileiro(
                obterValorMinimo(
                  periodicidade,
                  tipoCalculo,
                  cobertura,
                  response,
                ),
              )}{' '}
              e{' '}
              {formatarValorPadraoBrasileiro(
                obterValorMaximo(
                  periodicidade,
                  tipoCalculo,
                  cobertura,
                  response,
                ),
              )}
            </Text>
            <Button
              variant="primary"
              disabled={validaFaixaRenda(
                Number(valorPersonalizado.get().value),
                obterValorMinimo(
                  periodicidade,
                  tipoCalculo,
                  cobertura,
                  response,
                ),
                obterValorMaximo(
                  periodicidade,
                  tipoCalculo,
                  cobertura,
                  response,
                ),
              )}
              onClick={() => calcularPlanoPersonalizado()}
            >
              Calcular
            </Button>
          </RenderConditional>
        </Container>
        <RenderConditional condition={exibirCard === true}>
          <CardCobertura
            loading={loadingSimulacao}
            title={periodicidade}
            cobertura={cobertura}
            valorContribuicao={
              tipoCalculo === EnumTipoCalculo.contribuicao
                ? Number(valorPersonalizado.get().value) / 100
                : obterValorCustomizado(
                    periodicidade,
                    cobertura,
                    responseSimulacao,
                  )
            }
            formaContribuicao={
              periodicidade === EnumPeriodicidade.mensal
                ? EnumSelectPeriodicidade.mensal
                : EnumSelectPeriodicidade.anual
            }
            valorBeneficio={
              tipoCalculo === EnumTipoCalculo.beneficio
                ? Number(valorPersonalizado.get().value) / 100
                : obterValorCustomizado(
                    periodicidade,
                    cobertura,
                    responseSimulacao,
                  )
            }
            onClick={goToAdquirir}
          />
        </RenderConditional>
      </Display>
      <Button variant="outlined" onClick={() => voltar()}>
        Voltar
      </Button>
    </>
  );
};
export default Personalizar;
