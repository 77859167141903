import { useState, useContext } from 'react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import * as DadosBancariosApi from 'previdencia/features/CancelamentoPrevidencia/services/dadosBancariosCancelamentoApi';
import {
  IAtualizacaoDadosBancariosConta,
  ResponseListaDadosBancariosListaDeContas,
} from 'previdencia/features/CancelamentoPrevidencia/types/IResponseAtualizacaoDadosBancarios';
import { DefaultParamRequest } from 'previdencia/types/enum';

export const useAtualizarDadosBancarios = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { toastError } = useToast();
  const [response, setResponse] = useState<string | undefined>();
  const [dadosBancarioAtualizado, setdadosBancarioAtualizado] =
    useState<IAtualizacaoDadosBancariosConta>();

  const {
    cliente: { numCertificado },
  } = useContext(AppContext);

  const atualizarDadosBancarios = async (
    params: IAtualizacaoDadosBancariosConta,
  ) => {
    try {
      setLoading(true);
      setdadosBancarioAtualizado(params);
      const result = await DadosBancariosApi.atualizarDadosBancarios({
        accountId: numCertificado,
        codEmpresa: DefaultParamRequest.CODIGO_EMPRESA,
        conta: params,
      });
      setResponse(result);
      return result;
    } catch (requestError: any) {
      toastError(requestError.message);
    } finally {
      setLoading(false);
    }
    return undefined;
  };

  return {
    loading,
    response,
    atualizarDadosBancarios,
    dadosBancarioAtualizado,
  };
};

export const useListarDadosBancarios = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { toastError } = useToast();
  const [response, setResponse] = useState<
    ResponseListaDadosBancariosListaDeContas | undefined
  >();

  const {
    cliente: { numCertificado },
  } = useContext(AppContext);

  const listarDadosBancarios = async () => {
    try {
      setLoading(true);

      const result = await DadosBancariosApi.listarDadosBancarios({
        certificado: numCertificado,
      });

      setResponse(result?.listaDeContas[0]);
    } catch (requestError: any) {
      toastError(requestError.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    response,
    listarDadosBancarios,
  };
};
