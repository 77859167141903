import React from 'react';
import { Text } from '@cvp/design-system/react';

const TextoReceberRenda: React.FC = () => {
  return (
    <>
      <Text variant="body02-sm" color="text">
        Caso você decida receber a sua renda, entre em contato com a Central de
        Serviços e Relacionamento pelo número: <strong>0800 702 4000</strong>
      </Text>
      <Text variant="body02-sm" color="text" margin>
        Não deixe de conferir as condições expostas no regulamento da sua
        previdência para saber mais.
      </Text>
    </>
  );
};

export default TextoReceberRenda;
