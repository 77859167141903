import styled from 'styled-components/macro';
import {
  Checkbox as CheckboxDS,
  Text as TextDS,
} from '@cvp/design-system/react';

export const Checkbox = styled(CheckboxDS)`
  margin-right: 10px;
`;

export const Text = styled(TextDS)`
  text-align: left;
`;
