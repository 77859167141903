import { Grid, Accordion, Card } from '@cvp/design-system/react';
import getAuthData from 'main/features/Auth/utils/auth';
import { formatarCpf } from 'main/utils/cpf_cnpj';
import { TextLabel } from 'main/styles/GlobalStyle';
import * as OCORRENCIA_STYLES from 'registroOcorrenciaASC/styles/styles';
import * as S from 'registroOcorrenciaASC/features/registrarOcorrencia/components/CardDadosInterlocutor/styles';

const CardDadosInterlocutor = (): React.ReactElement => {
  const {
    user: { email, cpf, nomeUsuario, nomeAcesso },
  } = getAuthData();

  return (
    <OCORRENCIA_STYLES.CardContainer>
      <Card.Content>
        <Accordion open data-testid="accordionDadosInterlocutor">
          <Accordion.Item title="Dados do interlocutor">
            <Grid>
              <Grid.Item xs={1} md={3 / 5}>
                <div>
                  <TextLabel
                    variant="body02-sm"
                    data-testid="dadosInterlocutorLabelNome"
                  >
                    Nome
                  </TextLabel>
                  <S.Text
                    variant="body02-sm"
                    data-testid="dadosInterlocutorValorNome"
                  >
                    {nomeUsuario}
                  </S.Text>
                </div>
              </Grid.Item>
              <Grid.Item xs={1} md={2 / 5}>
                <div>
                  <TextLabel
                    variant="body02-sm"
                    data-testid="dadosInterlocutorLabelCpf"
                  >
                    CPF
                  </TextLabel>
                  <S.Text
                    variant="body02-sm"
                    data-testid="dadosInterlocutorValorCpf"
                  >
                    {formatarCpf(cpf)}
                  </S.Text>
                </div>
              </Grid.Item>
              <Grid.Item xs={1} md={3 / 5}>
                <div>
                  <TextLabel
                    variant="body02-sm"
                    data-testid="dadosInterlocutorLabelEmail"
                  >
                    E-mail
                  </TextLabel>
                  <S.Text
                    variant="body02-sm"
                    data-testid="dadosInterlocutorValorEmail"
                  >
                    {email}
                  </S.Text>
                </div>
              </Grid.Item>
              <Grid.Item xs={1} md={2 / 5}>
                <div>
                  <TextLabel
                    variant="body02-sm"
                    data-testid="dadosInterlocutorLabelMatricula"
                  >
                    Matrícula
                  </TextLabel>
                  <S.Text
                    variant="body02-sm"
                    data-testid="dadosInterlocutorValorMatricula"
                  >
                    {nomeAcesso}
                  </S.Text>
                </div>
              </Grid.Item>
            </Grid>
          </Accordion.Item>
        </Accordion>
      </Card.Content>
    </OCORRENCIA_STYLES.CardContainer>
  );
};

export default CardDadosInterlocutor;
