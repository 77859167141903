import React from 'react';
import { Text } from '@cvp/design-system/react';
import { chatAtendimentoSalesForce } from 'previdencia/utils/chatAtendimentoSalesForce';
import { MENSAGEM_NAO_SE_APLICA } from '../../constants';
import { LinkChat } from './styles';

type mensagemErroProps = {
  mensagem: string | undefined;
};

const MensagemErro: React.FC<mensagemErroProps> = ({ mensagem }) => {
  const handleAbreChat = () => {
    return chatAtendimentoSalesForce();
  };
  return (
    <>
      <Text variant="body01-lg" color="primary" margin>
        Atenção!
      </Text>
      <Text variant="body02-sm" color="text" margin>
        {mensagem === MENSAGEM_NAO_SE_APLICA ? (
          <>
            {mensagem}{' '}
            <LinkChat
              onMouseDown={() => window.open(handleAbreChat(), '_blank')}
            >
              link
            </LinkChat>
          </>
        ) : (
          mensagem
        )}
      </Text>
    </>
  );
};

export default MensagemErro;
