import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';

import { ISolicitarTransferenciaFop223 } from 'extranet/types/ISolicitarTransferenciaFop223';
import { IResponseSolicitarTransferencia } from 'extranet/types/IResponseSolicitarTransferencia';

const useSolicitarTransferenciaFop223 = () => {
  const { loading, response, fetchData } = usePeco<
    ISolicitarTransferenciaFop223,
    IResponseSolicitarTransferencia
  >({
    api: { operationPath: PECOS.SolicitarTransferenciaReducaoTaxaFop223 },
  });

  return { loading, response, fetchData };
};

export default useSolicitarTransferenciaFop223;
