import { Button, Card, Display, Grid, Text } from '@cvp/design-system/react';

import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import TabelaResumoSimulacao from 'previdencia/features/SolicitacaoResgate/components/TabelaResumoSimulacao';
import useEfetuarResgate from 'previdencia/features/SolicitacaoResgate/hooks/useEfetuarResgate';
import useHandleEfetuarSolicitacaoResgate from 'previdencia/features/SolicitacaoResgate/hooks/useHandleEfetuarSolicitacaoResgate';
import DisclaimerMsgErroResgate from 'previdencia/features/SolicitacaoResgate/components/DisclaimerMsgErroResgate';
import CardMotivoResgate from 'previdencia/features/SolicitacaoResgate/components/CardMotivoResgate';
import FormNovaContaBancaria from 'previdencia/features/SolicitacaoResgate/components/FormNovaContaBancaria';
import InformacoesBancarias from 'previdencia/features/SolicitacaoResgate/components/InformacoesBancarias';
import { ContainerNav } from './styles';

const EfetuarResgate = () => {
  const { navigateTo } = usePrevNavigation();

  const {
    contasBancariasValidasParaResgate,
    colunas,
    formik,
    listaTiposConta,
    dadosRecuperarBancos,
    quantidadeCharInput,
    novaConta,
    listaMotivosResgate,
    colunasMotivoResgate,
    isFormularioPreenchido,
  } = useEfetuarResgate();

  const {
    efetuarSolicitacaoResgate,
    isLoadingEfetuarResgate,
    mensagemTemporaria,
  } = useHandleEfetuarSolicitacaoResgate({
    novaConta,
    formik,
  });

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Grid>
            <Grid.Item xs={1}>
              <Text variant="headline-05" color="primary" margin>
                Simular Resgate
              </Text>
            </Grid.Item>
          </Grid>
          <Grid>
            <Grid.Item xs={1}>
              <Card>
                <Card.Content padding={[4, 4, 4]}>
                  <Text variant="body01-md" color="primary" margin>
                    Dados da simulação
                  </Text>
                  <div>
                    <TabelaResumoSimulacao />
                  </div>
                </Card.Content>
              </Card>
            </Grid.Item>

            <Grid.Item xs={1}>
              <Card>
                <Card.Content padding={[4, 4, 4]}>
                  <InformacoesBancarias
                    contasBancariasValidasParaResgate={
                      contasBancariasValidasParaResgate
                    }
                    colunas={colunas}
                  />

                  <FormNovaContaBancaria
                    formik={formik}
                    listaTiposConta={listaTiposConta}
                    dadosRecuperarBancos={dadosRecuperarBancos}
                    quantidadeCharInput={quantidadeCharInput}
                    novaConta={novaConta}
                  />
                </Card.Content>
              </Card>
            </Grid.Item>

            <CardMotivoResgate
              listaMotivosResgate={listaMotivosResgate}
              colunasMotivoResgate={colunasMotivoResgate}
            />

            <DisclaimerMsgErroResgate mensagem={mensagemTemporaria} />

            <Grid.Item xs={1}>
              <ContainerNav>
                <Button
                  variant="outlined"
                  onClick={() => navigateTo('solicitacao-resgate')}
                >
                  Cancelar
                </Button>
                <Button
                  variant="primary"
                  onClick={efetuarSolicitacaoResgate}
                  loading={isLoadingEfetuarResgate}
                  disabled={checkIfSomeItemsAreTrue([
                    !isFormularioPreenchido,
                    isLoadingEfetuarResgate,
                  ])}
                >
                  Confirmar
                </Button>
              </ContainerNav>
            </Grid.Item>
          </Grid>
        </Card.Content>
      </Card>
    </Display>
  );
};

export default EfetuarResgate;
