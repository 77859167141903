import { tryGetValueOrDefault } from 'main/utils/conditional';
import styled from 'styled-components';
import { IModalContentProps } from 'main/components/ModalBlur/types/ModalBlur.types';

export const ModalOverlay = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div<IModalContentProps>`
  position: relative;
  width: ${props => tryGetValueOrDefault([props.widthContent], '100%')};
  @media only screen and (min-width: 768px) {
    width: ${props => tryGetValueOrDefault([props.widthContent], '100%')};
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
