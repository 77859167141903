import { ISelectSearchItem } from 'main/types/ISelectSearchItem';
import React, { useState, KeyboardEvent } from 'react';
import { SearchField } from '@cvp/design-system/react';
import { required } from 'main/features/Validation/validations';
import * as S from './styles';

type SelectSearchProps = {
  placeholder?: string;
  isRequired?: boolean;
  options: ISelectSearchItem[];
  selectedItem: ISelectSearchItem | undefined;
  setSelectedItem: (item: ISelectSearchItem) => void;
  [x: string]: unknown;
};

const SelectSearch: React.FC<SelectSearchProps> = ({
  placeholder,
  isRequired,
  options,
  selectedItem,
  setSelectedItem,
  ...props
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] =
    useState<ISelectSearchItem[]>(options);
  const [value, setValue] = useState('');

  const getSelectedOption = (valueToFilter: string) => {
    return options?.filter(
      item =>
        item?.label?.toLowerCase().includes(valueToFilter) ||
        item?.value?.toString().toLowerCase().includes(valueToFilter),
    );
  };
  const updateState = (valueToFilter: string) => {
    setValue(valueToFilter);
    setFilteredOptions(getSelectedOption(valueToFilter));
    setSelectedItem({ label: valueToFilter, value: valueToFilter });
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueToFilter = e.target.value;
    updateState(valueToFilter);
  };

  const handleSelect = (item: ISelectSearchItem) => {
    setSelectedItem(item);
    setValue(item.label);
  };

  const handleOnBlur = () => {
    setFilteredOptions(options);
    setShowOptions(false);
    if (!selectedItem) {
      setValue('');
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const valueFromInput = (e.target as HTMLInputElement).value;
      updateState(valueFromInput);
    }
  };

  return (
    <S.Container {...props}>
      <SearchField
        onFocus={() => setShowOptions(true)}
        onBlur={handleOnBlur}
        placeholder={placeholder || 'O que você procura?'}
        value={value}
        onChange={handleSearch}
        validationRules={isRequired && [required()]}
        onKeyPress={handleKeyPress}
      />
      <S.ContainerItens>
        <S.StyledUl show={showOptions}>
          {filteredOptions.map(item => (
            <S.StyledLi key={item.value} onMouseDown={() => handleSelect(item)}>
              {item.label}
            </S.StyledLi>
          ))}
        </S.StyledUl>
      </S.ContainerItens>
    </S.Container>
  );
};

export default SelectSearch;
