import styled from 'styled-components';

export const WrapperWarning = styled.div(({ theme }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.color.line.secondary,
  padding: 20,
  borderRadius: 8,
  marginTop: 20,
  '& *': {
    color: theme.color.line.secondary,
  },
}));
