import { useContext } from 'react';

import { getTimer60Minutos } from 'main/utils';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { PECOS } from 'previdencia/config/endpoints';
import * as RESPONSE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgateResponse';
import * as REQUEST_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgateRequest';

export const usePecoConsultarResumoAliquota = () => {
  const { cliente } = useContext(AppContext);

  const {
    response: dadosConsultarResumoAliquota,
    loading: loadingDadosConsultarResumoAliquota,
    fetchData: consultarResumoAliquota,
  } = usePeco<
    Partial<REQUEST_TYPES.IConsultarResumoAliquotaPayload>,
    RESPONSE_TYPES.IConsultarResumoAliquotaResponse
  >({
    handleResponse: { throwToastErrorBFF: false },
    api: { operationPath: PECOS.ConsultarResumoAliquota },
    payload: {
      codigoCertificado: tryGetValueOrDefault([cliente.numCertificado], ''),
    },
  });

  return {
    dadosConsultarResumoAliquota: dadosConsultarResumoAliquota?.entidade,
    loadingDadosConsultarResumoAliquota,
    consultarResumoAliquota,
  };
};

export const usePecoConsultarDetalheCalculo = () => {
  const { cliente } = useContext(AppContext);

  const {
    response: dadosConsultarDetalheCalculo,
    loading: loadingDadosConsultarDetalheCalculo,
    fetchData: consultarDetalheCalculo,
  } = usePeco<
    Partial<REQUEST_TYPES.IConsultarDetalheCalculoPayload>,
    RESPONSE_TYPES.IConsultarDetalheCalculoResponse
  >({
    handleResponse: { throwToastErrorBFF: false },
    api: { operationPath: PECOS.ConsultarDetalheCalculo },
    payload: {
      codigoCertificado: tryGetValueOrDefault([cliente.numCertificado], ''),
    },
  });

  return {
    dadosConsultarDetalheCalculo: dadosConsultarDetalheCalculo?.entidade,
    loadingDadosConsultarDetalheCalculo,
    consultarDetalheCalculo,
  };
};

export const usePecoListarMotivosResgate = () => {
  const {
    response: dadosListarMotivosResgate,
    loading: loadingDadosListarMotivosResgate,
    fetchData: listarMotivosResgate,
  } = usePeco<unknown, RESPONSE_TYPES.IListarMotivosResgateResponse[]>({
    api: { operationPath: PECOS.ListarMotivosResgate },
    cache: true,
    cacheTime: getTimer60Minutos(),
  });

  return {
    dadosListarMotivosResgate: dadosListarMotivosResgate?.entidade,
    loadingDadosListarMotivosResgate,
    listarMotivosResgate,
  };
};

export const usePecoDefinirContribuicaoRegular = () => {
  const { cliente } = useContext(AppContext);

  const {
    response: dadosDefinirContribuicaoRegular,
    loading: loadingDadosDefinirContribuicaoRegular,
    fetchData: definirContribuicaoRegular,
  } = usePeco<
    Partial<REQUEST_TYPES.IDefinirContribuicaoRegularPayload>,
    RESPONSE_TYPES.IDefinirContribuicaoRegularResponse
  >({
    handleResponse: { throwToastErrorBFF: false },
    api: { operationPath: PECOS.DefinirContribuicaoRegular },
    payload: {
      codigoCertificado: tryGetValueOrDefault([cliente.numCertificado], ''),
    },
  });

  return {
    dadosDefinirContribuicaoRegular: dadosDefinirContribuicaoRegular?.entidade,
    loadingDadosDefinirContribuicaoRegular,
    definirContribuicaoRegular,
  };
};

export const usePecoConfirmarResgate = () => {
  const { cliente } = useContext(AppContext);

  const {
    response: dadosConfirmarResgate,
    loading: loadingDadosConfirmarResgate,
    fetchData: confirmarResgate,
  } = usePeco<
    Partial<REQUEST_TYPES.IConfirmarResgatePayload>,
    RESPONSE_TYPES.IConfirmarResgateResponse
  >({
    handleResponse: { throwToastErrorBFF: false },
    api: { operationPath: PECOS.ConfirmarResgate },
    payload: {
      codigoCertificado: tryGetValueOrDefault([cliente.numCertificado], ''),
    },
  });

  return {
    dadosConfirmarResgate,
    loadingDadosConfirmarResgate,
    confirmarResgate,
  };
};

export const usePecoRecuperarBancos = () => {
  const { cliente } = useContext(AppContext);

  const {
    response: dadosRecuperarBancos,
    loading: loadingDadosRecuperarBancos,
    fetchData: recuperarBancos,
  } = usePeco<
    REQUEST_TYPES.IRecuperarBancosPayload,
    RESPONSE_TYPES.IRecuperarBancosResponse[]
  >({
    api: { operationPath: PECOS.RecuperarBancos },
    payload: {
      cpfCnpj: tryGetValueOrDefault([cliente.cpfCnpj], ''),
    },
    cache: true,
    cacheTime: getTimer60Minutos(),
  });

  return {
    dadosRecuperarBancos: dadosRecuperarBancos?.entidade,
    loadingDadosRecuperarBancos,
    recuperarBancos,
  };
};

export const usePecoConsultarContribuicaoRegular = () => {
  const { cliente } = useContext(AppContext);

  const {
    response: dadosConsultaContribuicaoRegular,
    loading: loadingDadosConsultaContribuicaoRegular,
    fetchData: consultarContribuicaoRegular,
  } = usePeco<
    Partial<REQUEST_TYPES.IConsultarContribuicaoRegularPayload>,
    RESPONSE_TYPES.IConsultarContribuicaoRegularResponse
  >({
    api: { operationPath: PECOS.ConsultarContribuicaoRegularResgate },
    payload: {
      codigoCertificado: tryGetValueOrDefault([cliente.numCertificado], ''),
    },
  });

  return {
    dadosConsultaContribuicaoRegular:
      dadosConsultaContribuicaoRegular?.entidade,
    loadingDadosConsultaContribuicaoRegular,
    consultarContribuicaoRegular,
  };
};

export const usePecoSolicitarAssinaturaEletronica = () => {
  const { cliente } = useContext(AppContext);

  const {
    response: dadosSolicitacaoAssinaturaEletronica,
    loading: loadingDadosSolicitacaoAssinaturaEletronica,
    fetchData: solicitarAssinatura,
  } = usePeco<
    Partial<REQUEST_TYPES.ISolicitarAssinaturaEletronicaPayload>,
    RESPONSE_TYPES.ISolicitarAssinaturaEletronicaResponse
  >({
    api: { operationPath: PECOS.SolicitarAssinaturaResgatePrevidencia },
    payload: {
      cpf: cliente.cpfCnpj,
    },
  });

  return {
    dadosSolicitacaoAssinaturaEletronica:
      dadosSolicitacaoAssinaturaEletronica?.entidade,
    loadingDadosSolicitacaoAssinaturaEletronica,
    solicitarAssinatura,
  };
};

export const usePecoConsultarTiposPagamento = () => {
  const {
    response: dadosConsultaTiposPagamento,
    loading: loadingDadosConsultaTiposPagamento,
    fetchData: consultarTiposPagamento,
  } = usePeco<
    Partial<REQUEST_TYPES.IConsultarTiposPagamentoPayload>,
    RESPONSE_TYPES.IConsultarTiposPagamentoResponse
  >({
    api: { operationPath: PECOS.ConsultarTiposPagamento },
  });

  return {
    dadosConsultaTiposPagamento: dadosConsultaTiposPagamento?.entidade,
    loadingDadosConsultaTiposPagamento,
    consultarTiposPagamento,
  };
};

export const usePecoSalvarDadosPagamento = () => {
  const {
    response: dadosSalvarDadosPagamento,
    loading: loadingDadosSalvarDadosPagamento,
    fetchData: salvarDadosPagamento,
  } = usePeco<
    Partial<REQUEST_TYPES.ISalvarDadosPagamentoPayload>,
    RESPONSE_TYPES.ISalvarDadosPagamentoResponse
  >({
    handleResponse: { throwToastErrorBFF: false },
    api: { operationPath: PECOS.SalvarDadosPagamento },
  });

  return {
    dadosSalvarDadosPagamento: dadosSalvarDadosPagamento?.entidade,
    loadingDadosSalvarDadosPagamento,
    salvarDadosPagamento,
  };
};

export const usePecoCriarMotivoResgate = () => {
  const {
    response: dadosCriarMotivoResgate,
    loading: loadingDadosCriarMotivoResgate,
    fetchData: criarMotivoResgate,
  } = usePeco<
    Partial<REQUEST_TYPES.ICriarMotivoResgatePayload>,
    RESPONSE_TYPES.ICriarMotivoResgateResponse
  >({
    handleResponse: { throwToastErrorBFF: false },
    api: { operationPath: PECOS.CriarMotivoResgate },
  });

  return {
    dadosCriarMotivoResgate: dadosCriarMotivoResgate?.entidade,
    loadingDadosCriarMotivoResgate,
    criarMotivoResgate,
  };
};
