import { Card, Grid, Text } from '@cvp/design-system/react';

import Table from 'main/components/Table';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const CardMotivoResgate = ({
  listaMotivosResgate,
  colunasMotivoResgate,
}: SOLICITACAO_RESGATE_TYPES.ICardMotivoResgateProps) => {
  return (
    <Grid.Item xs={1}>
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Text variant="body01-md" color="primary" margin>
            Motivo do resgate
          </Text>
          <Text variant="body01-md" margin>
            Selecione o motivo do resgate:
          </Text>

          <Table
            noHeader
            highlightOnHover
            striped
            responsive
            data={listaMotivosResgate}
            columns={colunasMotivoResgate}
            noDataComponent={CONSTS.NO_DATA_TABLE}
          />
        </Card.Content>
      </Card>
    </Grid.Item>
  );
};

export default CardMotivoResgate;
