import { Grid, Text } from '@cvp/design-system/react';

import TabelasAliquotasAgrupadasResgate from 'previdencia/features/SolicitacaoResgate/components/TabelasAliquotasAgrupadasResgate';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';
import { ModalValoresDetalhadosAliquota as Modal } from 'previdencia/features/SolicitacaoResgate/pages/styles';

const ModalDetalhesAliquota = ({
  open,
  handleClose,
  isLoadingDetalhesAliquota,
  dadosTabelaFundosAliquota,
  exibirAliquotas,
}: SOLICITACAO_RESGATE_TYPES.IModalDetalhesAliquotaProps) => {
  return (
    <Modal show={open} onClose={handleClose}>
      <Grid>
        <Grid.Item xs={1}>
          <Text variant="headline-05" color="primary">
            {CONSTS.TEXTOS_DETALHES_ALIQUOTA.TITULO}
          </Text>
        </Grid.Item>

        <TabelasAliquotasAgrupadasResgate
          loading={isLoadingDetalhesAliquota}
          dadosTabelaFundosAliquota={dadosTabelaFundosAliquota}
          exibirAliquotas={exibirAliquotas}
        />
      </Grid>
    </Modal>
  );
};

export default ModalDetalhesAliquota;
