export enum EnumCobertura {
  pensao = 14,
  peculio = 13,
}

export enum EnumPeriodicidade {
  anual = 'anual',
  mensal = 'mensal',
}

export enum EnumPeriodicidadeAbreviada {
  anual = 'A',
  mensal = 'M',
}

export enum EnumIncluirReativar {
  sim = 'S',
  nao = 'N',
}

export enum EnumSelectPeriodicidade {
  anual = 'por ano',
  mensal = 'todo mês',
}

export enum EnumTipoCalculo {
  contribuicao = 'C',
  beneficio = 'B',
}

export enum EnumTipoContribuicaoPeculio {
  anual = 'PCMO9',
  mensal = 'PCMO1',
}

export enum EnumTipoContribuicaoPensao {
  anual = 'PEPZ9',
  mensal = 'PEPZ1',
}

export enum EnumSituacaoBeneficio {
  ativo = 'ativo',
}
