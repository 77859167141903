import React, { useEffect, useState } from 'react';
import {
  formatarValorPadraoBrasileiro,
  removerMascaraValorMonetario,
  tryGetMonetaryValueOrDefault,
} from 'main/utils/money';
import { TextField } from '@cvp/design-system/react';
import masks from 'main/utils/masks';
import { Legend } from 'main/components/form/Input/styles';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import * as S from 'previdencia/components/TabelaMultifundosPJ/styles';
import {
  InputState,
  InputValorProps,
} from 'previdencia/components/TabelaMultifundosPJ/types/TabelaMultifundos.types';
import { TipoTransferencia } from 'previdencia/types/MultifundosPJ';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ITransferenciaMultifundosPjFeatureData } from './types/ITransferenciaMultifundosPjFeatureData';
import { EnumEtapas } from 'previdencia/types/Fundo.type';

const obterValorInput = (
  etapa: string | undefined,
  tipoTransferencia: string | undefined,
  valorTransferencia: number | undefined,
): string | undefined => {
  if (etapa === EnumEtapas.selecionarFundosDestino) {
    return formatarValorPadraoBrasileiro(valorTransferencia);
  }

  return tipoTransferencia === TipoTransferencia.TOTAL
    ? formatarValorPadraoBrasileiro(valorTransferencia)
    : masks.currencyInput.mask(0);
};

export const InputValor: React.FunctionComponent<InputValorProps> = ({
  show,
  valorTransferencia,
  tipoTransferencia,
  valorMaximo,
  valorMinimo,
  onBlur,
}) => {
  const { featureData } =
    usePrevidenciaContext<ITransferenciaMultifundosPjFeatureData>();

  const [input, setInput] = useState<InputState>();
  const valorMinimoDefault = tryGetValueOrDefault([valorMinimo], 200.0);
  const validarRange = (value: string) => {
    return checkIfAllItemsAreTrue([
      removerMascaraValorMonetario(value) >= valorMinimoDefault,
      removerMascaraValorMonetario(value) <= valorMaximo,
    ]);
  };

  const handleChange = (value: string) => {
    setInput({
      valor: masks.currencyInput.mask(value),
      error: !validarRange(masks.currencyInput.mask(value)),
    });
  };

  useEffect(() => {
    setInput({
      valor: obterValorInput(
        featureData?.etapa,
        tipoTransferencia,
        valorTransferencia,
      ),
      error:
        checkIfAllItemsAreTrue([
          tipoTransferencia === TipoTransferencia.PARCIAL,
          !validarRange(masks.currencyInput.mask(0)),
        ]) || false,
    });
  }, [tipoTransferencia, show]);

  return (
    <S.InputContainer show={!!show}>
      <TextField
        value={input?.valor}
        maxLength="17"
        disabled={!show}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(e.target.value)
        }
        onBlur={() =>
          onBlur(
            removerMascaraValorMonetario(
              tryGetValueOrDefault([input?.valor], '0'),
            ),
          )
        }
        error={input?.error}
        placeholder={
          show
            ? 'Insira um valor'
            : tryGetMonetaryValueOrDefault(Number(valorTransferencia))
        }
      />
      <Legend isValid={!input?.error}>
        <RenderConditional condition={!!show}>
          {`entre ${formatarValorPadraoBrasileiro(
            valorMinimoDefault,
          )} e ${tryGetMonetaryValueOrDefault(valorMaximo)}`}
        </RenderConditional>
      </Legend>
    </S.InputContainer>
  );
};
