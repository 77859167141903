import React, { useContext } from 'react';
import { Button, Display, Grid, Text } from '@cvp/design-system/react';
import { AppContext } from 'main/contexts/AppContext';
import getAuthData from 'main/features/Auth/utils/auth';
import masks from 'main/utils/masks';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { showErrorInput } from 'main/utils/formik';
import * as CONSTS from 'seguros/constants/AlteracaoValorCapital';
import { AlterarValorCapitalFormProps } from 'seguros/types/AlteracaoValorCapital';
import useVidaNavigation from 'seguros/hooks/useVidaNavigation';
import * as S from 'seguros/components/AlteracaoValorCapital/styles';

const AlterarValorCapitalForm = ({ formik }: AlterarValorCapitalFormProps) => {
  const { goDadosSeguradoVida } = useVidaNavigation();

  const { clienteVida } = useContext(AppContext);

  const { user } = getAuthData();

  return (
    <>
      <S.WrapperSection>
        <Text variant="headline-08" color="primary" margin>
          {formik.values.isReducao
            ? CONSTS.TEXTOS_ALTERACAO_VALOR_CAPITAL.TITULO_OPCAO.REDUCAO
            : CONSTS.TEXTOS_ALTERACAO_VALOR_CAPITAL.TITULO_OPCAO.AUMENTO}
        </Text>
        <Grid>
          <Grid.Item xs={1} xl={1 / 2}>
            <S.TextField
              {...formik.getFieldProps(
                formik.values.valorCapitalSeguradoCoberturaMorte,
              )}
              label="Informar o Capital Segurado desejado para Cobertura de Morte R$:"
              data-testid="valorCapitalSeguradoCoberturaMorte"
              name="valorCapitalSeguradoCoberturaMorte"
              value={masks.currencyInput.mask(
                formik.values.valorCapitalSeguradoCoberturaMorte,
              )}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue(
                  'valorCapitalSeguradoCoberturaMorte',
                  masks.currencyInput.unmask(value),
                );
              }}
              {...showErrorInput(formik, 'valorCapitalSeguradoCoberturaMorte')}
            />
          </Grid.Item>

          <Grid.Item xs={1} xl={1 / 2}>
            <S.TextField
              {...formik.getFieldProps(formik.values.emailCliente)}
              label="E-mail do Cliente:"
              data-testid="emailCliente"
              name="emailCliente"
              value={formik.values.emailCliente}
              {...showErrorInput(formik, 'emailCliente')}
            />
          </Grid.Item>
        </Grid>

        <Text variant="caption-02">
          <strong>
            {CONSTS.TEXTOS_ALTERACAO_VALOR_CAPITAL.CALCULO_CAPITAL}
          </strong>
        </Text>
      </S.WrapperSection>

      <S.WrapperSection>
        <Text variant="body02-sm" margin>
          <strong>
            {CONSTS.TEXTOS_ALTERACAO_VALOR_CAPITAL.INFORMACOES.SUBTITULO}
          </strong>
        </Text>

        <Text variant="body02-sm">
          {CONSTS.TEXTOS_ALTERACAO_VALOR_CAPITAL.INFORMACOES.PARAGRAFO_01}
        </Text>
        <Text variant="body02-sm">
          {CONSTS.TEXTOS_ALTERACAO_VALOR_CAPITAL.INFORMACOES.PARAGRAFO_02}
        </Text>
      </S.WrapperSection>

      <S.WrapperSection>
        <Text variant="body02-md" color="primary" margin>
          Solicitação Via Internet
        </Text>

        <Grid>
          <Grid.Item xs={1}>
            <S.TextField
              {...formik.getFieldProps(formik.values.localData)}
              label="Local e data:"
              data-testid="localData"
              name="localData"
              value={formik.values.localData}
              {...showErrorInput(formik, 'localData')}
            />
          </Grid.Item>
        </Grid>

        <S.WrapperInfo>
          <Text variant="body02-sm">
            <strong>Cliente:</strong>{' '}
            {tryGetValueOrDefault([clienteVida?.nomeSegurado], '-')}
          </Text>
          <Text variant="body02-sm">
            <strong>Apólice Nº:</strong>{' '}
            {tryGetValueOrDefault([clienteVida?.numApolice?.toString()], '-')}
          </Text>
          <Text variant="body02-sm">
            <strong>Certificado:</strong>{' '}
            {tryGetValueOrDefault(
              [clienteVida?.numCertificado?.toString()],
              '-',
            )}
          </Text>
        </S.WrapperInfo>
      </S.WrapperSection>

      <S.WrapperSection>
        <Text variant="body02-sm" margin>
          <strong>Dados do Representante Legal:</strong>
        </Text>

        <Grid>
          <Grid.Item xs={1} lg={2 / 3} xl={4 / 10}>
            <S.TextField
              {...formik.getFieldProps(
                formik.values.nomeCompletoRepresentanteLegal,
              )}
              label="Nome Completo:"
              data-testid="nomeCompletoRepresentanteLegal"
              name="nomeCompletoRepresentanteLegal"
              value={formik.values.nomeCompletoRepresentanteLegal}
              {...showErrorInput(formik, 'nomeCompletoRepresentanteLegal')}
            />
          </Grid.Item>
          <Grid.Item xs={1} lg={1 / 3} xl={2 / 10}>
            <S.TextField
              {...formik.getFieldProps(formik.values.cpfRepresentanteLegal)}
              label="CPF:"
              data-testid="cpfRepresentanteLegal"
              name="cpfRepresentanteLegal"
              value={masks.cpf.mask(formik.values.cpfRepresentanteLegal)}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue(
                  'cpfRepresentanteLegal',
                  masks.cpf.unmask(value),
                );
              }}
              {...showErrorInput(formik, 'cpfRepresentanteLegal')}
            />
          </Grid.Item>
          <Grid.Item xs={1} xl={4 / 10}>
            <S.TextField
              {...formik.getFieldProps(formik.values.emailRepresentanteLegal)}
              label="E-mail:"
              data-testid="emailRepresentanteLegal"
              name="emailRepresentanteLegal"
              value={formik.values.emailRepresentanteLegal}
              {...showErrorInput(formik, 'emailRepresentanteLegal')}
            />
          </Grid.Item>
        </Grid>
      </S.WrapperSection>

      <S.WrapperSection>
        <Text variant="body02-sm" margin>
          <strong>
            Dados do Economiário Responsável por esta solicitação:
          </strong>
        </Text>

        <S.WrapperInfo>
          <Text variant="body02-sm">
            <strong>Nome Completo:</strong>{' '}
            {tryGetValueOrDefault([user?.nomeUsuario], '-')}
          </Text>
          <Text variant="body02-sm">
            <strong>Matrícula:</strong>{' '}
            {tryGetValueOrDefault([user?.nomeAcesso], '-')}
          </Text>
          <Text variant="body02-sm">
            <strong>Agência:</strong>{' '}
            {tryGetValueOrDefault([user?.agenciaVinculada], '-')}
          </Text>
          <Text variant="body02-sm">
            <strong>E-mail:</strong> {tryGetValueOrDefault([user?.email], '-')}
          </Text>
        </S.WrapperInfo>
      </S.WrapperSection>

      <Text variant="body02-sm" color="error" margin>
        {CONSTS.TEXTOS_ALTERACAO_VALOR_CAPITAL.CAMPOS_OBRIGATORIOS}
      </Text>

      <Display>
        <Button variant="secondary" onClick={goDadosSeguradoVida}>
          Atualizar Dados
        </Button>

        <Button
          variant="primary"
          onClick={formik.submitForm}
          disabled={!!Object.keys(formik.errors).length}
        >
          Enviar Solicitação
        </Button>
      </Display>
    </>
  );
};

export default AlterarValorCapitalForm;
