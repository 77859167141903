export const createProspeccaoByIdCpfCnpj = (
  prospeccaoId: number,
  cpfCnpj: string,
) => ({
  id: prospeccaoId,
  cpfCnpj,
  valorProspeccao: 0,
  numeroAgencia: 0,
  valorProspeccaoFormatado: '',
  dataHoraCadastro: '',
  dataHoraCadastroFormatada: '',
  situacao: {
    dataHoraOcorrencia: '',
    descricao: '',
    usuarioResponsavel: '',
  },
});
