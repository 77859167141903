/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
import { format } from 'date-fns';
import masks from 'main/utils/masks';
import { BeneficiorioData } from 'previdencia/features/AlteracaoBeneficiarios/types/Beneficiarios';
import { ResponseCoberturas } from 'previdencia/types/Beneficiarios';
import { ResponseEntidadeModificarBeneficiarios } from 'previdencia/features/AlteracaoBeneficiarios/types/ModificarBeneficiario';

export const retornarBeneficiariosAdicionados = (
  beneficiario: BeneficiorioData[],
) => {
  const beneficiariosAtivos = beneficiario.filter(item => {
    return (
      item.situacao === 'A' &&
      !item.isRemove &&
      !item.pessoaFisica.pessoaFisicaId
    );
  });

  return beneficiariosAtivos.map(item => {
    return {
      IdParentesco: item.grauParentescoId,
      Porcentagem: item.percentualDistribuicao,
      NomeBeneficiario: item.pessoaFisica.nome,
      NumCpf: item.pessoaFisica.cpfCnpj,
      DataNascimento: format(item.pessoaFisica.dataNascimento, 'yyyy-MM-dd'),
      Sexo: item.pessoaFisica.genero,
    };
  });
};

export const retornarBeneficiariosAlterados = (
  beneficiario: BeneficiorioData[],
) => {
  const updateBeneficiariosArray = beneficiario.filter(
    item => item.pessoaFisica.pessoaFisicaId,
  );

  const removeBeneficiarioArray = updateBeneficiariosArray.filter(
    item => item.isRemove,
  );
  if (removeBeneficiarioArray.length > 0) {
    return updateBeneficiariosArray
      .filter(item => !item.isRemove)
      .map(item => {
        return {
          IdBeneficiario: item.pessoaFisica.pessoaFisicaId || '',
          IdParentesco: item.grauParentescoId,
          Porcentagem: item.percentualDistribuicao.split('.')[0],
        };
      });
  }

  return updateBeneficiariosArray.map(item => {
    return {
      IdBeneficiario: item.pessoaFisica.pessoaFisicaId ?? '',
      IdParentesco: item.grauParentescoId,
      Porcentagem: item.percentualDistribuicao.split('.')[0],
    };
  });
};

export const somaPercentualDistribuicao = (
  beneficiarios: BeneficiorioData[],
) => {
  let somaPercentual = 0;
  let somaIsRemove = 0;

  const beneficiariosAtivos = beneficiarios.filter(item => {
    return !item.isRemove;
  });

  beneficiariosAtivos.forEach(item => {
    if (item.percentualDistribuicao === '') {
      somaPercentual = -1;
    }
    if (item.isRemove) {
      somaIsRemove += 1;
    }
    somaPercentual += Number(item.percentualDistribuicao);
  });

  if (beneficiariosAtivos.length === somaIsRemove) {
    somaPercentual = 100;
  }

  return somaPercentual;
};

export const verificarBeneficiarios = (
  response: ResponseCoberturas | undefined,
): boolean => {
  let sum = 0;

  response?.retorno?.beneficios?.forEach(e => {
    if (e.beneficiarios && e.beneficiarios.length > 0) {
      sum += 1;
    }
  });

  if (sum > 0 || !response) {
    return true;
  }

  return false;
};

export const verificarBeneficiarioAtual = (
  identificador: string | undefined,
  cpfCorrente: string | undefined,
  identificadorComparativo: string,
  cpfComparativo: string,
): boolean => {
  if (identificador) {
    if (identificador === cpfCorrente && cpfCorrente) {
      return true;
    }
  } else if (identificadorComparativo === cpfComparativo && cpfComparativo) {
    return true;
  }

  return false;
};

export const definirNomeIcone = (descricaoCobertura: string) => {
  const nomes = ['reserva', 'peculio', 'pensaoconjuge', 'pensaoprazocerto'];
  const nomeIcon = masks.removeSpaces.mask(descricaoCobertura.toLowerCase());

  return nomes.includes(nomeIcon) ? nomeIcon : 'peculio';
};

export const verificarLoading = (
  isLoading: boolean,
  isFetching: boolean,
  loadingConsultaPermissao: boolean,
  loadingObterContatos: boolean,
  loadingAssinatura: boolean,
  idRequisicao?: string,
) => {
  return (
    isLoading ||
    isFetching ||
    loadingConsultaPermissao ||
    loadingObterContatos ||
    (loadingAssinatura && idRequisicao)
  );
};

export const isSucessModificarBeneficiarios = (
  loadingModificacaoBeneficiarios: boolean,
  retornoModificacaoBeneficiarios:
    | ResponseEntidadeModificarBeneficiarios
    | undefined,
) => {
  return !loadingModificacaoBeneficiarios && !!retornoModificacaoBeneficiarios;
};

export const validarAssinaturaPendente = (
  idRequisicao?: string,
  emailDefault?: string,
  numeroDDD?: string,
  numeroTelefoneSemDDD?: string,
) => {
  return !!(idRequisicao && emailDefault && numeroDDD && numeroTelefoneSemDDD);
};
