import React from 'react';
import { Card, Display, Grid, Text } from '@cvp/design-system/react';

import { tryGetValueOrDefault } from 'main/utils/conditional';
import InformeReserva from 'previdencia/features/AlteracaoBeneficiarios/components/InformeReserva';
import {
  Beneficios,
  ResponseCoberturas,
} from 'previdencia/types/Beneficiarios';
import TextoAlteracaoBeneficiario from '../TextoAlteracaoBeneficiario';
import TabelaAlteracaoBeneficiario from '../TabelaAlteracaoBeneficiario';
import TabelaReserva from '../TabelaReserva';
import { TipoParentesco } from '../../types/TipoParentesco';

type TabelaImpressaoType = {
  dataBeneficiarios: Beneficios[] | undefined;
  benefitsAndBeneficiaries: ResponseCoberturas | undefined;
};
const TabelaImpressao: React.FC<TabelaImpressaoType> = ({
  dataBeneficiarios,
  benefitsAndBeneficiaries,
}) => {
  return (
    <Display className="show-print">
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Grid>
            <Grid.Item xs={1}>
              <Text variant="headline-08" color="primary">
                Alteração de Beneficiários
              </Text>

              <Text variant="body01-md" margin>
                Veja como está a distribuição dos beneficiários entre as
                coberturas encontradas:
              </Text>
              <TabelaAlteracaoBeneficiario
                print
                data={benefitsAndBeneficiaries}
              />
            </Grid.Item>
            <Grid.Item xs={1}>
              <TextoAlteracaoBeneficiario />
            </Grid.Item>
            <Grid.Item xs={1}>
              {dataBeneficiarios?.map(item => (
                <>
                  <InformeReserva
                    data={item}
                    saldoTotal={benefitsAndBeneficiaries?.retorno.saldoTotal}
                    count={tryGetValueOrDefault(
                      [item.beneficiarios?.length],
                      0,
                    )}
                  />
                  <TabelaReserva
                    print
                    beneficiarios={tryGetValueOrDefault(
                      [item?.beneficiarios],
                      [],
                    )}
                    TipoParentesco={TipoParentesco || []}
                  />
                </>
              ))}
            </Grid.Item>
          </Grid>
        </Card.Content>
      </Card>
    </Display>
  );
};

export default TabelaImpressao;
