import React from 'react';
import { Button } from '@cvp/design-system/react';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';
import { Display } from 'main/features/Auth/components';

const ButtonsEtapasAporte: React.FC<APORTE_TYPES.IButtonsEtapasAporteProps> = ({
  disabled,
  proximaEtapa,
  buttonLabel,
  loading,
}) => {
  const { featureData } =
    usePrevidenciaContext<APORTE_TYPES.IAporteContextData>();

  return (
    <Display>
      <Button variant="outlined" onClick={featureData?.etapaAnterior}>
        Cancelar
      </Button>
      <Button
        variant="primary"
        onClick={proximaEtapa}
        disabled={disabled}
        loading={loading}
      >
        {buttonLabel}
      </Button>
    </Display>
  );
};

export default ButtonsEtapasAporte;
