import { IDadosBasicosCertificadoPrevidencia } from 'consultaCliente/features/listaCardProduto/interfaces/IDadosBasicosCertificadoPrevidencia';
import { removerMascaraValorMonetario } from 'main/utils/money';
import { NumeroContaECodigoOperacao } from 'previdencia/types/DadosContaBancaria';
import { ResponseDadosCertificadosPorCpf } from '../types/DadosCertificado';

export const obterProdutoPeloCertificado = (
  data: IDadosBasicosCertificadoPrevidencia[] | undefined,
  numCertificado: string,
): string | null => {
  const result = data?.find(
    element => element.certificadoNumero === numCertificado,
  );

  return result?.produto.descricaoProduto || null;
};

export const obterCodigoOperacaoENumeroConta = (
  numeroConta: string,
): NumeroContaECodigoOperacao => {
  if (numeroConta.length < 12) {
    return {
      numeroConta: numeroConta.substr(3),
      operacao: numeroConta.substr(0, 3),
    };
  }

  return {
    numeroConta: numeroConta.substr(4),
    operacao: numeroConta.substr(0, 4),
  };
};

export const filtrarDadosPorCertificado = (
  dados: ResponseDadosCertificadosPorCpf[] | undefined,
  numCertificado: string,
): ResponseDadosCertificadosPorCpf | undefined => {
  return dados?.find(e => e.codigoConta === numCertificado);
};

export const ordenarDataGenerica = (dataA: any, dataB: any) => {
  if (dataA === '-') {
    return -1;
  }
  if (dataB === '-') {
    return 1;
  }
  const datePartsA = dataA.split('/');
  const datePartsB = dataB.split('/');

  const dateObjectA = new Date(
    +datePartsA[2],
    datePartsA[1] - 1,
    +datePartsA[0],
  );

  const dateObjectB = new Date(
    +datePartsB[2],
    datePartsB[1] - 1,
    +datePartsB[0],
  );

  if (dateObjectA > dateObjectB) {
    return 1;
  }

  if (dateObjectB > dateObjectA) {
    return -1;
  }

  return 0;
};

export const ordenaDataVencimentoSemConversao = (rowA: any, rowB: any) => {
  if (rowA.dataVencimento < rowB.dataVencimento) {
    return 1;
  }

  if (rowB.dataVencimento < rowA.dataVencimento) {
    return -1;
  }

  return 0;
};

export const ordenaValor = (rowA: any, rowB: any) => {
  const a =
    rowA.valor !== '' ? removerMascaraValorMonetario(rowA.valor) : rowA.valor;
  const b =
    rowB.valor !== '' ? removerMascaraValorMonetario(rowB.valor) : rowB.valor;

  if (a === '') {
    return -1;
  }
  if (b === '') {
    return 1;
  }
  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const ordenaDataRealizado = (rowA: any, rowB: any) => {
  return ordenarDataGenerica(rowA.realizado, rowB.realizado);
};
