/* eslint-disable no-param-reassign */
import { getUserName } from 'main/features/Auth/utils/auth';
import { AxiosInstance } from 'axios';
import { obterChaveGateway } from 'main/features/Auth/services/authApi';

export const registerGatewayInterceptor = (api: AxiosInstance) => {
  const excludeUrls = ['PECO_ValidarAcessoSSO'];
  api.interceptors.request.use(async config => {
    if (
      config.url?.includes('/PortalEconomiario') &&
      !excludeUrls.find(x => config.url?.includes(x))
    ) {
      const [funcao] = config.url?.match(/\PECO_\S+/g) ??
        config.url?.match(/MF_\S+/g) ?? [''];
      const username = getUserName();
      const key = await obterChaveGateway(funcao, username);
      if (!(config.data instanceof FormData)) {
        config.data = {
          UserName: username,
          SHArsaKey: key,
          ...config.data,
        };
      }
      if (config.data instanceof FormData) {
        config.data.append('UserName', username);
        config.data.append('SHArsaKey', key);
      }
    }
    return config;
  });
};
