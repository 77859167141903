import { formatarData } from 'main/utils';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import {
  IResponseHistoricoPagamentos,
  IResponseHistoricoPagamentosTableProps,
} from 'vida/features/Pagamentos/types/HistoricoPagamentosTypes';

export function converterParaHistoricoPagamentosTableProps(
  dados?: IResponseHistoricoPagamentos[],
): IResponseHistoricoPagamentosTableProps[] {
  if (!dados) return [];

  const retorno: IResponseHistoricoPagamentosTableProps[] = [];

  dados.forEach(element => {
    retorno.push({
      numeroParcela: element.numeroParcela,
      opcaoPagamento: element.opcaoPagamento,
      situacao: element.situacao,
      valor: tryGetMonetaryValueOrDefault(element.valor),
      vencimento: formatarData(element.vencimento),
    });
  });

  return retorno;
}
