import { IEntradaUploadDocumento } from 'contratosPrestamista/types/IEntradaUploadDocumento';

export const defaultFormStateDadosContrato = (
  cpfCnpj?: string,
): IEntradaUploadDocumento => ({
  codContrato: '',
  cpfCnpj: cpfCnpj ?? '',
  dtaVenda: null,
  desSistemaOrigem: '',
  numOperacao: '',
  valorCredito: '',
  capitalSegurado: '',
  valorAcumulo: 0,
});
