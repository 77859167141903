import {
  FilterTypes,
  IFilterOption,
} from 'main/components/Table/TableFilter/IFilterOption';
import masks from 'main/utils/masks';
import { IRelatorioResgateResponse } from 'previdencia/features/Resgate/types/RelatorioResgateResponse';
import * as UTILS from 'previdencia/features/Resgate/utils/resgateUtils';
import { useMemo } from 'react';

const useRelatorioResgateFilterOptions = (
  response: IRelatorioResgateResponse[] | undefined,
): IFilterOption[] =>
  useMemo(
    () => [
      {
        key: 'codigoCertificado',
        value: 'Código do Certificado',
        type: FilterTypes.TEXT,
      },
      {
        key: 'cpfCnpjCliente',
        value: 'Nº CPF/CNPJ',
        type: FilterTypes.TEXT,
        unmask: masks.cpf.unmask,
      },
      {
        key: 'tipoResgate',
        value: 'Tipo de Resgate',
        type: FilterTypes.TAG,
        checkboxConfig: {
          options: (response || [])
            .filter(UTILS.removerTiposDeResgatesDuplicados)
            .map(UTILS.mapIRelatorioResgateResponseParaITagFilterOption),
        },
      },
      {
        key: 'valorResgate',
        value: 'Valor do Resgate',
        type: FilterTypes.TEXT,
        unmask: masks.currencyInput.unmask,
      },
    ],
    [response],
  );

export default useRelatorioResgateFilterOptions;
