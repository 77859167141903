import React, { ChangeEvent, useContext, useEffect } from 'react';
import { Text, Card, Button, Display, Grid } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { AppContext } from 'main/contexts/AppContext';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import { formatarData } from 'main/utils';
import masks from 'main/utils/masks';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import CardErrorDataAposentadoria from 'previdencia/features/AlteracaoPrazoDiferimento/components/CardErrorDataAposentadoria';
import useValidarIdadeSaidaPlano from 'previdencia/features/AlteracaoPrazoDiferimento/hooks/useValidarIdadeSaidaPlano';
import { calcularAnoAposentadoria } from 'previdencia/features/AlteracaoPrazoDiferimento/utils/calcularAnoAposentadoria';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import * as S from 'previdencia/features/AlteracaoPrazoDiferimento/pages/styles';

const AlteracaoPrazoDiferimento: React.FC = () => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const { goDadosPlano } = usePrevNavigation();

  const {
    fundo,
    messageError,
    messageSuccess,
    isLoading,
    loadingatuAlizarDataAposentadoria,
    vigenciaSaida,
    diaMesVigenciaSaida,
    idadeSaida,
    handleConfirmarAlteracaoAno,
    handleConfirmarAlteracaoVigencia,
    handleSetVigenciaSaida,
    handleSetIdadeSaida,
    validateIdadeSaidaPlano,
    atualizarValores,
  } = useValidarIdadeSaidaPlano(cpfCnpj, numCertificado);

  const disabledButton = !checkIfAllItemsAreTrue([
    !!idadeSaida,
    !!vigenciaSaida,
  ]);

  useEffect(() => {
    if (checkIfSomeItemsAreTrue([!!messageSuccess, !!messageError])) {
      atualizarValores();
    }
  }, [messageError, messageSuccess]);

  if (checkIfAllItemsAreTrue([isLoading, !fundo])) {
    return <SkeletonLoading blocks={2} />;
  }

  if (checkIfAllItemsAreTrue([!fundo?.certificadoNumero, !isLoading])) {
    return <CardErrorDataAposentadoria />;
  }

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredRoles={[...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO]}
        requiredPermissions={[PREV_PERMISSIONS.PRAZO_DE_TERMINO_DO_PLANO]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-05" color="primary">
                  Alteração do Prazo de Diferimento
                </Text>
              </Grid.Item>

              <Grid.Item xs={1} md={1 / 4}>
                <Text variant="body02-sm" color="text">
                  Idade de saída do plano (atual):
                </Text>
                <Display>
                  <S.InputDisabled
                    value={calcularAnoAposentadoria(
                      fundo?.pessoa.pessoaFisica.nascimento,
                      fundo?.aposentadoria,
                    )}
                    disabled
                  />
                </Display>
              </Grid.Item>

              <Grid.Item xs={1} md={1 / 4}>
                <Text variant="body02-sm" color="text">
                  Fim da vigência do plano (atual):
                </Text>
                <Display>
                  <S.InputDisabled
                    value={formatarData(fundo?.aposentadoria)}
                    disabled
                  />
                </Display>
              </Grid.Item>

              <Grid.Item xs={1} md={1 / 4}>
                <Text variant="body02-sm" color="text">
                  Idade de saída do plano (novo):
                </Text>
                <Display>
                  <S.Input
                    value={idadeSaida}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleSetIdadeSaida(
                        masks.numberOnly.mask(e.target.value),
                      );
                    }}
                    onBlur={handleConfirmarAlteracaoVigencia}
                    maxLength={2}
                  />
                </Display>
                <RenderConditional
                  condition={!!messageError}
                  component={
                    <Text variant="body03-md" color="error">
                      {messageError}
                    </Text>
                  }
                />
              </Grid.Item>

              <Grid.Item xs={1} md={1 / 4}>
                <Text variant="body02-sm" color="text">
                  Fim da vigência do plano (novo):
                </Text>
                <Display alignItems="center">
                  <Text variant="body02-sm" color="text">
                    {diaMesVigenciaSaida}
                  </Text>

                  <S.Input
                    largura="150px"
                    value={vigenciaSaida}
                    maxLength={4}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleSetVigenciaSaida(
                        masks.numberOnly.mask(e.target.value),
                      );
                    }}
                    onBlur={handleConfirmarAlteracaoAno}
                  />
                </Display>
                <RenderConditional condition={!!messageSuccess}>
                  <Text variant="body03-md" color="success" align="center">
                    <Icon name="successCheck" /> {messageSuccess}
                  </Text>
                </RenderConditional>
              </Grid.Item>

              <Grid.Item xs={1}>
                <Display type="center">
                  <Button variant="outlined" onClick={goDadosPlano}>
                    Voltar
                  </Button>
                  <Button
                    loading={loadingatuAlizarDataAposentadoria}
                    disabled={disabledButton}
                    onClick={validateIdadeSaidaPlano}
                  >
                    Confirmar
                  </Button>
                </Display>
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
    </Display>
  );
};

export default AlteracaoPrazoDiferimento;
