import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { PECOS } from 'previdencia/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import { DefaultParamRequest } from 'previdencia/types/enum';
import { IResponseFaixaRendaBeneficioProtecao } from '../types/FaixaRendaTypes';
import { IPayloadResgatarBeneficioProtecao } from '../types/BeneficioProtecaoTypes';

const useFaixaRendaBeneficioProtecao = () => {
  const {
    cliente: { numCertificado },
  } = useContext(AppContext);
  const { response, loading, fetchData } = usePeco<
    IPayloadResgatarBeneficioProtecao,
    IResponseFaixaRendaBeneficioProtecao
  >({
    api: {
      operationPath: PECOS.FaixaRenda,
    },
    autoFetch: true,
    payload: {
      codigoEmpresa: DefaultParamRequest.CODIGO_EMPRESA,
      codigoCertificado: numCertificado,
    },
  });

  return { response, loading, fetchData };
};

export default useFaixaRendaBeneficioProtecao;
