import { useEffect } from 'react';
import { FormikProps, useFormik } from 'formik';
import { isCpf } from 'main/utils/cpf_cnpj';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import { useRegistrarOcorrenciaContext } from 'registroOcorrenciaASC/features/registrarOcorrencia/contexts/RegistrarOcorrenciaContext';
import { usePecoBuscarEnderecoCep } from 'registroOcorrenciaASC/features/registrarOcorrencia/hooks/usePecoRegistrarOcorrencia';
import * as FACTORY from 'registroOcorrenciaASC/features/registrarOcorrencia/factories/RegistrarOcorrenciaFactory';
import * as UTILS from 'registroOcorrenciaASC/features/registrarOcorrencia/utils/RegistrarOcorrenciasUtils';
import * as CONSTS from 'registroOcorrenciaASC/features/registrarOcorrencia/constants/constants';
import * as REGISTRO_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrencias';
import * as ENUM_TYPES from 'registroOcorrenciaASC/types/enum';

export const useRenderizarFormCliente = () => {
  const { cpfCnpjCliente, formDadosCliente, salvarDadosCliente } =
    useRegistrarOcorrenciaContext();

  const {
    dadosEnderecoPeloCep,
    loadingDadosEnderecoPeloCep,
    obterEnderecoPeloCep,
  } = usePecoBuscarEnderecoCep();

  const initialStateFormik =
    FACTORY.initialFormikStateDadosClienteFactory(formDadosCliente);

  const formik: FormikProps<REGISTRO_OCORRENCIA_TYPES.IFormikValuesDadosCliente> =
    useFormik<REGISTRO_OCORRENCIA_TYPES.IFormikValuesDadosCliente>({
      enableReinitialize: false,
      initialValues: initialStateFormik,
      validationSchema: FACTORY.formikDadosClienteSchemaFactory(
        cpfCnpjCliente.valor,
      ),
      initialErrors: {
        inputRazaoSocialOuNome:
          CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO,
        inputTelefonePrincipal:
          CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO,
        inputCpfRepresentante:
          CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO,
        inputNomeRepresentante:
          CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO,
        inputEmailRepresentante:
          CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO,
        inputTelefoneRepresentante:
          CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO,
      },
      initialTouched: {
        inputRazaoSocialOuNome: true,
        inputTelefonePrincipal: true,
        inputCpfRepresentante: true,
        inputNomeRepresentante: true,
        inputEmailRepresentante: true,
        inputTelefoneRepresentante: true,
      },
      validateOnMount: true,
      onSubmit: () => {
        return undefined;
      },
    });

  const cpfOuCnpjValido: boolean = checkIfSomeItemsAreTrue([
    cpfCnpjCliente.cpf,
    cpfCnpjCliente.cnpj,
  ]);

  const validarCamposObrigatoriosPF = (): boolean => {
    return checkIfAllItemsAreTrue([
      cpfCnpjCliente.cpf,
      !!formik.values.inputRazaoSocialOuNome.length,
      formik.values.inputTelefonePrincipal.length >=
        ENUM_TYPES.DefaultValue.VALOR_MINIMO_TELEFONE,
    ]);
  };

  const validarCamposObrigatoriosPJ = (): boolean => {
    return checkIfAllItemsAreTrue([
      cpfCnpjCliente.cnpj,
      !!formik.values.inputRazaoSocialOuNome.length,
      formik.values.inputTelefonePrincipal.length >=
        ENUM_TYPES.DefaultValue.VALOR_MINIMO_TELEFONE,
      isCpf(formik.values.inputCpfRepresentante),
      !!formik.values.inputNomeRepresentante.length,
      UTILS.isEmail(formik.values.inputEmailRepresentante),
      formik.values.inputTelefoneRepresentante.length >=
        ENUM_TYPES.DefaultValue.VALOR_MINIMO_TELEFONE,
    ]);
  };

  const atualizarCamposEnderecoPosConsulta = (): void => {
    if (dadosEnderecoPeloCep?.entidade) {
      formik.setValues({
        ...formik.values,
        inputEndereco: dadosEnderecoPeloCep.entidade.logradouro,
        inputBairro: dadosEnderecoPeloCep.entidade.bairro,
        inputCidade: dadosEnderecoPeloCep.entidade.cidade,
        inputUf: dadosEnderecoPeloCep.entidade.estado,
      });
    }
  };

  const salvarDadosContextoCliente = (): void => {
    if (cpfOuCnpjValido) {
      salvarDadosCliente({
        ...formDadosCliente,
        razaoSocialOuNome: formik.values.inputRazaoSocialOuNome,
        emailCorporativoOuEmail: formik.values.inputEmailCorporativoOuEmail,
        cep: formik.values.inputCep,
        endereco: formik.values.inputEndereco,
        bairro: formik.values.inputBairro,
        cidade: formik.values.inputCidade,
        uf: formik.values.inputUf,
        telefonePrincipal: formik.values.inputTelefonePrincipal,
        telefoneSecundario: formik.values.inputTelefoneSecundario,
        cpfRepresentante: formik.values.inputCpfRepresentante,
        nomeRepresentante: formik.values.inputNomeRepresentante,
        emailRepresentante: formik.values.inputEmailRepresentante,
        telefoneRepresentante: formik.values.inputTelefoneRepresentante,
        camposObrigatorios: {
          pessoaFisica: validarCamposObrigatoriosPF(),
          pessoaJuridica: validarCamposObrigatoriosPJ(),
        },
      });
    }
  };

  useEffect(salvarDadosContextoCliente, [
    formik.values.inputRazaoSocialOuNome,
    formik.values.inputEmailCorporativoOuEmail,
    formik.values.inputCep,
    formik.values.inputEndereco,
    formik.values.inputTelefonePrincipal,
    formik.values.inputTelefoneSecundario,
    formik.values.inputCpfRepresentante,
    formik.values.inputNomeRepresentante,
    formik.values.inputEmailRepresentante,
    formik.values.inputTelefoneRepresentante,
  ]);

  useEffect(atualizarCamposEnderecoPosConsulta, [
    dadosEnderecoPeloCep?.entidade,
  ]);

  return { formik, loadingDadosEnderecoPeloCep, obterEnderecoPeloCep };
};
