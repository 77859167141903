import React from 'react';
import { Grid, Text, Card, Accordion } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import masks from 'main/utils/masks';
import { formatarData } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { TextLabel } from 'main/styles/GlobalStyle';
import * as CONSTS from 'registroOcorrenciaASC/features/consultarOcorrencia/constants/constants';
import * as CONSULTA_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/consultarOcorrencia/types/ConsultarOcorrencia';
import * as OCORRENCIA_STYLES from 'registroOcorrenciaASC/styles/styles';

const CardDetalhesOcorrencia: React.FC<
  CONSULTA_OCORRENCIA_TYPES.CardDetalhesOcorrenciaProps
> = ({ loadingDadosDetalhesOcorrencia, dadosDetalhesOcorrencia }) => {
  return (
    <OCORRENCIA_STYLES.CardContainer>
      <Card.Content padding={[6, 8, 6, 8]}>
        <Accordion open data-testid="accordionDetalhesOcorrencia">
          <Accordion.Item title="Detalhes da Ocorrência">
            <RenderConditional condition={loadingDadosDetalhesOcorrencia}>
              <SkeletonLoading lines={8} />
            </RenderConditional>

            <RenderConditional condition={!loadingDadosDetalhesOcorrencia}>
              <Grid>
                <Grid.Item xs={1} md={1 / 2}>
                  <div>
                    <TextLabel
                      variant="body02-sm"
                      data-testid="numeroProtocoloDetalhesOcorrenciaLabel"
                    >
                      {CONSTS.TEXTOS_DETALHES_OCORRENCIA.NUMERO_PROTOCOLO}
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      data-testid="numeroProtocoloDetalhesOcorrenciaValor"
                    >
                      {tryGetValueOrDefault(
                        [dadosDetalhesOcorrencia?.entidade?.numeroProtocolo],
                        '-',
                      )}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} md={1 / 2}>
                  <div>
                    <TextLabel
                      variant="body02-sm"
                      data-testid="numeroSolicitacaoDetalhesOcorrenciaLabel"
                    >
                      {CONSTS.TEXTOS_DETALHES_OCORRENCIA.NUMERO_SOLICITACAO}
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      data-testid="numeroSolicitacaoDetalhesOcorrenciaValor"
                    >
                      {tryGetValueOrDefault(
                        [dadosDetalhesOcorrencia?.entidade?.numeroSolicitacao],
                        '-',
                      )}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} md={1 / 2}>
                  <div>
                    <TextLabel
                      variant="body02-sm"
                      data-testid="assuntoDetalhesOcorrenciaLabel"
                    >
                      {CONSTS.TEXTOS_DETALHES_OCORRENCIA.ASSUNTO}
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      data-testid="assuntoDetalhesOcorrenciaValor"
                    >
                      {tryGetValueOrDefault(
                        [dadosDetalhesOcorrencia?.entidade?.motivo],
                        '-',
                      )}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} md={1 / 2}>
                  <div>
                    <TextLabel
                      variant="body02-sm"
                      data-testid="cpfCnpjClienteDetalhesOcorrenciaLabel"
                    >
                      {CONSTS.TEXTOS_DETALHES_OCORRENCIA.CPF_CNPJ}
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      data-testid="cpfCnpjClienteDetalhesOcorrenciaValor"
                    >
                      {masks.cpfCnpj.mask(
                        tryGetValueOrDefault(
                          [dadosDetalhesOcorrencia?.entidade?.numeroDocumento],
                          '-',
                        ),
                      )}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} md={1 / 2}>
                  <div>
                    <TextLabel
                      variant="body02-sm"
                      data-testid="interlocutorDetalhesOcorrenciaLabel"
                    >
                      {CONSTS.TEXTOS_DETALHES_OCORRENCIA.INTERLOCUTOR}
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      data-testid="interlocutorDetalhesOcorrenciaValor"
                    >
                      {tryGetValueOrDefault(
                        [dadosDetalhesOcorrencia?.entidade?.interlocutor],
                        '-',
                      )}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} md={1 / 2}>
                  <div>
                    <TextLabel
                      variant="body02-sm"
                      data-testid="dataRegistroDetalhesOcorrenciaLabel"
                    >
                      {CONSTS.TEXTOS_DETALHES_OCORRENCIA.DATA_REGISTRO}
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      data-testid="dataRegistroDetalhesOcorrenciaValor"
                    >
                      {tryGetValueOrDefault(
                        [
                          formatarData(
                            dadosDetalhesOcorrencia?.entidade?.dataCriacao,
                          ),
                        ],
                        '-',
                      )}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} md={1 / 2}>
                  <div>
                    <TextLabel
                      variant="body02-sm"
                      data-testid="situacaoClienteDetalhesOcorrenciaLabel"
                    >
                      {CONSTS.TEXTOS_DETALHES_OCORRENCIA.SITUACAO}
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      data-testid="situacaoClienteDetalhesOcorrenciaValor"
                    >
                      {tryGetValueOrDefault(
                        [dadosDetalhesOcorrencia?.entidade?.statusSolicitacao],
                        '-',
                      )}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} md={1 / 2}>
                  <div>
                    <TextLabel
                      variant="body02-sm"
                      data-testid="canalAberturaClienteDetalhesOcorrenciaLabel"
                    >
                      {CONSTS.TEXTOS_DETALHES_OCORRENCIA.CANAL_ABERTURA}
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      data-testid="canalAberturaClienteDetalhesOcorrenciaValor"
                    >
                      {tryGetValueOrDefault(
                        [dadosDetalhesOcorrencia?.entidade?.canal],
                        '-',
                      )}
                    </Text>
                  </div>
                </Grid.Item>
                <Grid.Item xs={1} md={1 / 2}>
                  <div>
                    <TextLabel
                      variant="body02-sm"
                      data-testid="mensagemClienteDetalhesOcorrenciaLabel"
                    >
                      {CONSTS.TEXTOS_DETALHES_OCORRENCIA.MENSAGEM}
                    </TextLabel>
                    <Text
                      variant="body02-sm"
                      data-testid="mensagemClienteDetalhesOcorrenciaValor"
                    >
                      {tryGetValueOrDefault(
                        [dadosDetalhesOcorrencia?.entidade?.descricao],
                        '-',
                      )}
                    </Text>
                  </div>
                </Grid.Item>
              </Grid>
            </RenderConditional>
          </Accordion.Item>
        </Accordion>
      </Card.Content>
    </OCORRENCIA_STYLES.CardContainer>
  );
};

export default CardDetalhesOcorrencia;
