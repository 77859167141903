import React from 'react';
import { Button } from '@cvp/design-system/react';
import { IListColumnActionProps } from 'contratosPrestamista/features/cancelamento/types/IListColumnActionProps';

const ListColumnActionReversao: React.FC<IListColumnActionProps> = ({
  dados,
  callToAction,
}) => {
  const { reversaoPermitida, numeroPropostaEstipulante } = dados;
  return (
    <>
      {reversaoPermitida ? (
        <Button
          data-testid={`reversaoButton-${numeroPropostaEstipulante}`}
          onClick={callToAction}
          small
          style={{ minWidth: '110px' }}
        >
          Reter
        </Button>
      ) : (
        '-'
      )}
    </>
  );
};

export default ListColumnActionReversao;
