import { PECOS } from 'previdencia/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import { IPayloadGerarExtrato } from '../types/IPayloadGerarExtrato';
import { IResponseGerarExtrato } from '../types/IResponseGerarExtrato';

const usePecoGerarExtrato = (payload: IPayloadGerarExtrato) => {
  const { response, loading, fetchData } = usePeco<
    IPayloadGerarExtrato,
    IResponseGerarExtrato
  >({
    api: {
      operationPath: PECOS.GerarExtratoCotas,
    },
    autoFetch: false,
    payload: {
      numeroCertificado: payload.numeroCertificado,
      cpfCnpj: payload.cpfCnpj,
    },
  });

  return { response: response?.entidade, loading, fetchData };
};

export default usePecoGerarExtrato;
