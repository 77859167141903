import { tryGetValueOrDefault } from 'main/utils/conditional';
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import * as RESPONSE_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblResponse';
import * as VGBL_MASKS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/utils/TransferenciaVgblMasks';
import * as CONSTS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/constants/constants';
import * as FUNC_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblFuncParams';

/**
 * Retorna 0 se o valor estiver em notação científica, caso contrário, retorna o próprio valor.
 * @param {number} value - O valor numérico a ser verificado.
 * @returns {number} - Retorna 0 se o valor estiver em notação científica, caso contrário, retorna o próprio valor.
 */

export const preventCientificValue = (value: number): number => {
  const scientificNotationRegex = /^[+-]?\d+(\.\d+)?[eE][+-]?\d+$/;

  if (scientificNotationRegex.test(value.toString())) {
    return 0;
  }

  return value;
};

export const obterPercentualAplicado = (
  tabelaFundos:
    | RESPONSE_TYPES.IObtencaoListaOfertasVgblConjugadoProdutosFundos[]
    | undefined,
): number => {
  const somaPercentualAplicado: number | undefined = tabelaFundos?.reduce(
    (valorAplicacaoAnterior, valorAplicacaoAtual) =>
      Number(valorAplicacaoAnterior) +
      Number(valorAplicacaoAtual.percentualDistribuicao),
    0,
  );

  return tryGetValueOrDefault([somaPercentualAplicado], 0);
};

export const obterValorReserva = (
  tabelaFundos:
    | RESPONSE_TYPES.IObtencaoListaOfertasVgblConjugadoProdutosFundos[]
    | undefined,
): number => {
  const somaReserva = tabelaFundos?.reduce(
    (valorReservaAnterior, valorReservaAtual) =>
      Number(valorReservaAnterior) + Number(valorReservaAtual.saldo),
    0,
  );

  return tryGetValueOrDefault([somaReserva], 0);
};

export const somarSaldoFundosAtuais = (
  dadosOrigem:
    | IHandleReponseResult<RESPONSE_TYPES.IDadosOrigemResponse>
    | undefined,
): number => {
  const resultadoSomaSaldoFundos = dadosOrigem?.entidade?.fundosVgbl.reduce(
    (valorSaldoFundoAnterior, atual) =>
      valorSaldoFundoAnterior + atual.valorSaldoFundo,
    0,
  );

  return tryGetValueOrDefault([resultadoSomaSaldoFundos], 0);
};

export const calcularValorSaldoReserva = ({
  percentualDistribuicao,
  valorTotalReserva,
  sobrouResto,
  restoCalculoSaldoReservaRestante,
}: FUNC_TYPES.CalcularValorSaldoReservaParams): string => {
  const percentual = Number(percentualDistribuicao);

  const resultado: string = ((percentual * valorTotalReserva) / 100).toFixed(2);

  const saldoComResto: string = (
    Number(resultado) + Number(restoCalculoSaldoReservaRestante)
  ).toFixed(2);

  return sobrouResto ? saldoComResto : resultado;
};

export const calcularRendaMensalOuIndenizacao = (
  fatorCobertura: number,
  valorContribuicao: number,
): number => {
  return fatorCobertura * valorContribuicao;
};

export const calcularResumoFundosInvestimentoDestino = ({
  tabelaFundos,
  saldoTotalFundosAtuais,
}: FUNC_TYPES.CalcularResumoFundosInvestimentoDestinoParams) => {
  const percentualTotalAplicado: string = VGBL_MASKS.percentageDecimal.mask(
    obterPercentualAplicado(tabelaFundos),
  );

  const percentualRestanteAplicado: string = VGBL_MASKS.percentageDecimal.mask(
    CONSTS.VALOR_PORCENTAGEM.MAXIMO - obterPercentualAplicado(tabelaFundos),
  );

  const saldoReservaAplicada: string = tryGetMonetaryValueOrDefault(
    obterValorReserva(tabelaFundos),
    saldoTotalFundosAtuais,
  );

  const restoCalculoSaldoReservaRestante: number =
    saldoTotalFundosAtuais - obterValorReserva(tabelaFundos);

  const saldoReservaRestante: string = tryGetMonetaryValueOrDefault(
    preventCientificValue(restoCalculoSaldoReservaRestante),
  );

  return {
    percentualTotalAplicado,
    percentualRestanteAplicado,
    saldoReservaAplicada,
    saldoReservaRestante,
    restoCalculoSaldoReservaRestante,
  };
};
