import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';
import { IMenuItem } from 'main/components/Menu/Sidebar/types';

export const menuDPSEletronica: IMenuItem = {
  label: 'DPS',
  alt: 'DPS Eletrônica',
  path: '/painelDPS',
  icon: 'handPlus',
  roles: [USER_PROFILES.ANALISTA_TI],
  subItems: [
    {
      label: 'Consultar status DPS',
      path: '/dps/consultar-status',
      roles: [USER_PROFILES.ANALISTA_TI],
    },
    {
      label: 'Solicitar DPS',
      path: '/dps/solicitar',
      roles: [USER_PROFILES.ANALISTA_TI],
    },
    {
      label: 'DPS pendentes',
      path: '/dps/pendentes',
      roles: [USER_PROFILES.ANALISTA_TI],
    },
  ],
};
