import { Button, Display } from '@cvp/design-system/react';
import ModalTokenDocuSign from 'main/components/AssinaturaDocuSign/ModalTokenDocuSign';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { AppContext } from 'main/contexts/AppContext';
import { useMessageEvent } from 'main/hooks/useMessageEvent';
import { IMessageEvent } from 'main/types/IMessageEvent';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import { ITransferenciaMultifundosPjFeatureData } from 'previdencia/components/TabelaMultifundosPJ/types/ITransferenciaMultifundosPjFeatureData';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ISolicitarAssinaturaRequest } from 'previdencia/features/TransferenciaFundoInvestimento/types/ISolicitarAssinaturaRequest';
import { montarPayloadAssinaturaEletronica } from 'previdencia/features/TransferenciaMultifundos/factories/fundosFactory';
import useSolicitarAssinatura from 'previdencia/features/TransferenciaMultifundos/hooks/useSolicitarAssinatura';
import { useObterDadosParticipante } from 'previdencia/hooks/useObterDadosParticipante';
import { EnumEtapas } from 'previdencia/types/Fundo.type';
import React, { useContext, useEffect, useState } from 'react';

const AssinaturaTransferenciaFundos: React.FC = () => {
  const { subscribe } = useMessageEvent();
  const { cliente } = useContext(AppContext);
  const { featureData, setFeatureData } =
    usePrevidenciaContext<ITransferenciaMultifundosPjFeatureData>();

  const [openModalDocuSign, setOpenModalDocuSign] = useState(false);
  const { loading, response, obterDadosParticipante } =
    useObterDadosParticipante();

  const {
    solicitarAssinatura,
    loadingSolicitarAssinatura,
    resultadoAssinatura,
  } = useSolicitarAssinatura();

  useEffect(() => {
    obterDadosParticipante();
  }, []);

  useEffect(() => {
    if (response) {
      const payload: ISolicitarAssinaturaRequest =
        montarPayloadAssinaturaEletronica(
          featureData?.idRequisicao,
          cliente.numCertificado,
          response,
        );

      solicitarAssinatura(payload);
    }
  }, [cliente.numCertificado, featureData?.idRequisicao, response]);

  useEffect(() => {
    if (resultadoAssinatura?.urlAssinatura) {
      setOpenModalDocuSign(true);
    }
  }, [resultadoAssinatura]);

  useEffect(() => {
    subscribe(async item => {
      const {
        data: { eventName, eventSuccess },
      } = item as MessageEvent<IMessageEvent>;

      if (
        checkIfAllItemsAreTrue([
          eventName === 'retornoAcaoConcluirAssinatura',
          eventSuccess,
        ])
      ) {
        setFeatureData({
          ...featureData,
          etapa: EnumEtapas.concluirComprovante,
        });
      }
    });
  }, []);

  return (
    <>
      <RenderConditional
        condition={checkIfSomeItemsAreTrue([
          loading,
          loadingSolicitarAssinatura,
        ])}
      >
        <SkeletonLoading lines={3} data-testid="skeletonLoading" />
      </RenderConditional>

      <ModalTokenDocuSign
        open={openModalDocuSign}
        urlTokenPage={resultadoAssinatura?.urlAssinatura}
        handleClose={() => setOpenModalDocuSign(false)}
        exibirBotaoFecharModal={false}
      />
      <Display justify="space-between">
        <Button
          variant="outlined"
          onClick={() =>
            setFeatureData({
              ...featureData,
              etapa: EnumEtapas.selecionarFundosDestino,
            })
          }
        >
          Voltar
        </Button>
      </Display>
    </>
  );
};

export default AssinaturaTransferenciaFundos;
