import { PECOS } from 'previdencia/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import {
  IPayloadConfirmarAssinatura,
  IResponseConfirmarAssinatura,
} from '../types/IContribuicaoRegular';

const useConfirmarTransferencia = (numeroSolicitacao?: string) => {
  const { response, loading, fetchData } = usePeco<
    IPayloadConfirmarAssinatura,
    IResponseConfirmarAssinatura
  >({
    api: {
      operationPath: PECOS.ConfirmarTransferenciaMultiFundoPj,
    },
    autoFetch: false,
    payload: {
      numeroSolicitacao,
    },
  });

  return { response: response?.entidade, loading, fetchData };
};

export default useConfirmarTransferencia;
