/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
import { basePath } from 'config/httpRequest';

export const obterEndpointCertificadosPrevidencia = (): string =>
  '/PortalEconomiario/PECO_ListarCertificadosPrevidencia';

export const obterEndpointDadosPlano = (): string =>
  '/PortalEconomiario/PECO_ObterPlano';

export const obterEndpointObterExtrato = (): string =>
  '/PortalEconomiario/PECO_ObterExtratoPdf';

export const obterEndpointExtratoRentabilidadeCotas = (): string =>
  '/PortalEconomiario/PECO_ConsultarDadosExtratoCotas';

export const obterEndpointContribuicoes = (): string =>
  '/PortalEconomiario/PECO_ObterPagamento';

export const obterEndpointEmissaoCertificado = (): string =>
  '/PortalEconomiario/PECO_ObterCertificado';

export const obterEndpointInformeIR = (): string =>
  '/PortalEconomiario/PECO_ObterInforme';

export const obterEndpointCoberturas = (): string =>
  '/PortalEconomiario/PECO_Coberturas';

export const obterEndpointTipoParentesco = (): string =>
  '/PortalEconomiario/PECO_KinshipType';

export const obterEndpointModificarBeneficiarios = (): string =>
  '/PortalEconomiario/PECO_ModificarBeneficiarios';

export const obterEndpointAlterarValorContribuicao = (): string =>
  '/PortalEconomiario/PECO_ListaFundosAlteracaoValorContribuicao';

export const obterEndpointValoresContribuicao = (): string =>
  '/PortalEconomiario/PECO_ListarValores';

export const obterEndpointDadosBeneficioProtecao = (): string =>
  '/PortalEconomiario/PECO_BeneficioProtecao';

export const obterEndpointMesesCarenciaBeneficioProtecao = (): string =>
  '/PortalEconomiario/PECO_RecuperarParametrosCarenciaBeneficioProtecao';

export const obterEndpointAlterarValorContribuicaoBeneficioProtecao =
  (): string => '/PortalEconomiario/PECO_AlterarBeneficioProtecao';

export const obterEndpointSolicitarAssinaturaBeneficioProtecao = (): string =>
  '/PortalEconomiario/PECO_SolicitarBeneficioProtecao';

export const obterEndpointAtualizarValorContribuicao = (): string =>
  `/PortalEconomiario/PECO_AtualizarValores`;

export const obterEndpointValorMinimoMaximo = (): string =>
  '/PortalEconomiario/PECO_ObterValorMinimoMaximo';

export const obterEndpointListaFundosReserva = (): string =>
  '/PortalEconomiario/PECO_ObterListaFundosReserva';

export const obterEndpointListaFundosParaResgate = (): string =>
  '/PortalEconomiario/PECO_ObterListaFundosParaResgate';

export const obterEndpointConsultarResgatesProgramados = (): string =>
  '/PortalEconomiario/PECO_ConsultarResgatesProgramados';

export const obterEndpointSimularRetirada = (): string =>
  '/PortalEconomiario/PECO_SimularRetirada';

export const obterEndpointRecuperarListaBancos = (): string =>
  '/PortalEconomiario/PECO_RecuperarBancos';

export const obterEndpointContasBancarias = (): string =>
  '/PortalEconomiario/PECO_ObterContasBancarias';

export const obterEndpointSolicitarResgate = (): string =>
  '/PortalEconomiario/PECO_SolicitarResgate';

export const obterEndpointCriarCanalPagamento = (): string =>
  '/PortalEconomiario/PECO_CriarCanalPagamento';

export const obterEndpointPermitirAlterarValorContribuicaoRegular =
  (): string => '/PortalEconomiario/PECO_PermiteAlterarContribuicaoRegular';

export const obterEndpointDefinirContribuicaoRegular = (): string =>
  '/PortalEconomiario/PECO_DefinirRegularMultiFundoPj';

export const obterEndpointConfirmarTransferenciaMultifundo = (): string =>
  '/PortalEconomiario/PECO_ConfirmarTransferenciaMultiFundoPj';

export const obterEndpointConfirmarResgate = (): string =>
  '/PortalEconomiario/PECO_ConfirmarResgate';

export const obterEndpointObterComprovanteResgate = (): string =>
  '/PortalEconomiario/PECO_ObterComprovanteResgate';

export const obterEndpointConsultarResumoAliquota = (): string =>
  '/PortalEconomiario/PECO_ConsultarResumoAliquota';

export const obterEndpointConsultarDetalheCalculo = (): string =>
  '/PortalEconomiario/PECO_ConsultarDetalheCalculo';

export const obterEndpointValidarConta = (): string =>
  '/PortalEconomiario/PECO_ValidarConta';

export const obterEndpointExtratoUnificado = (): string =>
  '/PortalEconomiario/PECO_ObterExtratoUnificado';

export const obterEndpointLaminaRentabilidade = (): string =>
  '/PortalEconomiario/PECO_ObterLaminaRentabilidade';

export const obterEndpointRentabilidade = (): string =>
  '/PortalEconomiario/PECO_ObterRentabilidade';

export const obterEndpointFundosPorCertificado = (): string =>
  `/PortalEconomiario/PECO_ObterInformacoesCertificado`;

export const obterEndpointTransferirEntreFundos = (): string =>
  `/PortalEconomiario/PECO_TransferirEntreFundos`;

export const obterEndpointDefinirReservaDestino = (): string =>
  `/PortalEconomiario/PECO_DefinirReservaDestino`;

export const obterEndpointRevalidarTransferencia = (): string =>
  `/PortalEconomiario/PECO_RevalidarTransferencia`;

export const obterEndpointDefinirFundoContribuicaoRegular = (): string =>
  `/PortalEconomiario/PECO_DefinirFundoContribuicaoRegular`;

export const obterEndpointConfirmarTransferencia = (): string =>
  `/PortalEconomiario/PECO_ConfirmarTransferencia`;

export const obterEndpointValidarAssinaturaTransferencia = (): string =>
  `/PortalEconomiario/PECO_ValidarAssinaturaTransferenciaPrevidencia`;

export const obterEndpointSegundaViaBoleto = (): string =>
  '/PortalEconomiario/PECO_ObterSegundaVia';

export const obterEndpointHistoricoAnosDisponiviesDetalhes = (): string =>
  '/PortalEconomiario/PECO_ObterHistorico';

export const obterEndpointAnosHistorico = (): string =>
  '/PortalEconomiario/PECO_ObterAnoHistorico';

export const obterEndpointStatusCoberturasContratadas = (): string =>
  '/PortalEconomiario/PECO_ObterBeneficiosPorCertificado';

export const obterEndpointAtivarContribuicao = (): string =>
  '/PortalEconomiario/PECO_AtivarContribuicao';

export const obterEndpointSuspenderContribuicao = (): string =>
  '/PortalEconomiario/PECO_SuspenderContribuicao';

export const obterEndpointMultiFundoPrevidencia = (): string =>
  '/PortalEconomiario/PECO_MultiFundoPrevidencia';

export const obterEndpointAtualizarDataAposentadoria = (): string =>
  '/PortalEconomiario/PECO_AtualizarDataAposentadoria';

export const obterEndpointConsultarDadosBasico = (): string =>
  '/PortalEconomiario/PECO_DadosBasico';

export const obterEndpointTiposRendas = (): string =>
  '/PortalEconomiario/PECO_TiposPagamento';

export const obterEndpointSimularBeneficio = (): string =>
  '/PortalEconomiario/PECO_SimularRenda';

export const obterEndpointConsultarHistoricoSimulacaoRenda = (): string =>
  '/PortalEconomiario/PECO_ConsultarHistorico';

export const obterEndpointHistoricoSimulacaoRendaPrevidencia = (): string =>
  '/PortalEconomiario/PECO_HistoricoSimulacaoRendaPrevidencia';

export const obterEndpointDadosParticipante = (): string =>
  '/PortalEconomiario/PECO_ObterDadosParticipante';

export const obterEndpointEnderecoCEP = (): string =>
  '/PortalEconomiario/PECO_ObterEnderecoCep';

export const obterEndpointAtualizarEndereco = (): string =>
  '/PortalEconomiario/PECO_AtualizarEndereco';

export const obterEndpointAtualizarEmail = (): string =>
  '/PortalEconomiario/PECO_AtualizarEmail';

export const obterEndpointAtualizarTelefone = (): string =>
  '/PortalEconomiario/PECO_AtualizarTelefone';

export const obterEndpointConsultarConsentimento = (): string =>
  '/PortalEconomiario/PECO_ConsultarConsentimento';

export const obterEndpointAtualizarConsentimento = (): string =>
  '/PortalEconomiario/PECO_AtualizarConsentimento';

export const obterEndpointAtualizarCadastro = (): string =>
  '/PortalEconomiario/PECO_AtualizarCadastro ';

export const obterEndpointConsultarHistoricoSolicitacoes = (): string =>
  '/PortalEconomiario/PECO_ObterHistoricoSolicitacoes';

export const obterEndpointComprovanteResgate = (): string =>
  '/PortalEconomiario/PECO_ObterComprovanteResgate';

export const obterEndpointTiposSolicitacoes = (): string =>
  '/PortalEconomiario/PECO_ObterTiposSolicitacoesHistorico';

export const obterEndpointCertificadosPorCpf = (): string =>
  '/PortalEconomiario/PECO_ObterCertificadosPorCpf';

export const obterEndpointAtualizarDataVencimento = (): string =>
  '/PortalEconomiario/PECO_AtualizarDataVencimento';

export const obterLinkAtendimentoOnline = (id: string) => {
  return `https://caixavidaeprevidencia.my.salesforce-sites.com/LiveAgentChatRouting?button_id=${id}`;
};

export const obterEndpointConsultarBeneficiarios = (): string =>
  '/PortalEconomiario/PECO_ObterBeneficiarios';

export const obterEndpointRecuperarInformacoesCertificado = (): string =>
  '/PortalEconomiario/PECO_RecuperarContribuicoesCertificado';

export const obterEndpointRecuperarContribuicoesCertificado = (): string =>
  '/PortalEconomiario/PECO_RecuperarContribuicoesCertificado';

export const obterEndpointListarContasBancarias = (): string =>
  '/PortalEconomiario/PECO_ListarContasBancarias';

export const obterEndpointAtualizarFormaPagamento = (): string =>
  '/PortalEconomiario/PECO_AtualizarFormaPagamento';

export const obterEndpointMatrizDeAcesso = (): string =>
  '/PortalEconomiario/PECO_ConsultarMatrizAcessoPrevidencia';

export const obterEndpointEnviarEmail = (): string =>
  '/PortalEconomiario/PECO_EnviarEmailPrevidencia';

export const obterEndPointAporteFundos = (): string =>
  '/PortalEconomiario/PECO_ObterFundos';

export const obterEndPointAportePermiteBoleto = (): string =>
  '/PortalEconomiario/PECO_PermiteBoleto';

export const obterEndPointAporteDatasPagamento = (): string =>
  '/PortalEconomiario/PECO_ObterDatas';

export const obterEndPointAporteDadosBancarios = (): string =>
  '/PortalEconomiario/PECO_ObterDadosBancarios';

export const obterEndPointEfetuarAporte = (): string =>
  '/PortalEconomiario/PECO_EfetuarAporte';

export const obterEndPointFinalizarAporte = (): string =>
  '/PortalEconomiario/PECO_FinalizarAporte';

export const obterEndPointAporteFormaPagamento = (): string =>
  '/PortalEconomiario/PECO_ObterFormaPagamentoAporte';

export const obterEndPointOperacaoBancaria = (): string =>
  '/PortalEconomiario/PECO_ObterOperacaoBancaria';

export const obterEndPointDadosPep = (): string =>
  '/PortalEconomiario/PECO_ConsultarDadosPep';

export const obterEndpointResponsavelFinanceiromaPagamento = (): string =>
  '/PortalEconomiario/PECO_ResponsavelFinanceiro';

export const obterLinkAtendimentoSalesForceOnline = (id: string) => {
  return `/LiveAgentChatRouting/?button_id=${id}`;
};

export const obterEndPointAssinaturaTransferenciaFundos = (): string =>
  '/PortalEconomiario/PECO_AssinaturaTransferenciaFundos';

export const obterEndpointSolicitarCancelamentoPrevidencia = () =>
  '/PortalEconomiario/PECO_SolicitarCancelamentoPrevidencia';

export const obterEndpointConsultarPermissaoAssinaturaBeneficiario =
  (): string =>
    '/PortalEconomiario/PECO_ConsultarPermissaoAssinaturaBeneficiario';

export const obterEndpointAtualizarDadosBancarios = (): string =>
  '/PortalEconomiario/PECO_CadastrarContaBancaria';

export const obterEndpointListarDadosBancarios = (): string =>
  '/PortalEconomiario/PECO_ListarContasBancariasCancelamento';
export const obterEndpointValidarNecessidadeAssinaturaEletronica = () =>
  '/PortalEconomiario/PECO_ValidarNecessidadeAssinaturaEletronicaResgatePrevidencia';

export const obterEndpointSolicitarAssinaturaResgatePrevidencia = () =>
  '/PortalEconomiario/PECO_SolicitarAssinaturaResgatePrevidencia';
export const obterEndpointGraficoRentabilidadeCarteira = (): string =>
  '/PortalEconomiario/PECO_ObterGraficoRentabilidadeCarteira';
export const obterEndpointImprimirPosicaoConsolidada = (): string =>
  '/PortalEconomiario/PECO_ImprimirPosicaoConsolidada';
export const obterEndpointBeneficioProtecao = (): string =>
  '/PortalEconomiario/PECO_BeneficioProtecao';

export const obterEndpointPrazosBeneficioProtecao = (): string =>
  '/PortalEconomiario/PECO_PrazosBeneficioProtecao';

export const obterEndpointFaixaRendaBeneficioProtecao = (): string =>
  '/PortalEconomiario/PECO_FaixaRendaBeneficioProtecao';

export const obterEndpointCalcularBeneficioProtecao = (): string =>
  '/PortalEconomiario/PECO_CalcularBeneficioProtecao';

export const obterEndpointIncluirBeneficioProtecao = (): string =>
  '/PortalEconomiario/PECO_IncluirBeneficioProtecao';

export const obterEndpointReativarBeneficioProtecao = (): string =>
  '/PortalEconomiario/PECO_ReativarBeneficioProtecao';

export const obterEndpointListarFundos = (): string =>
  '/PortalEconomiario/PECO_ListarFundosMultiFundoPj';

export const obterEndpointConsultaContribuicaoRegular = (): string =>
  '/PortalEconomiario/PECO_ConsultarFundoContribuicaoRegularMultiFundoPj';

export const obterEndpointValidarTransferencia = (): string =>
  '/PortalEconomiario/PECO_ValidarMultiFundoPj';

export const obterEndpointDefinirSaidaMultifundos = (): string =>
  '/PortalEconomiario/PECO_DefinirSaidaMultiFundoPj';

export const obterEndpointDefinirDestinoMultifundos = (): string =>
  '/PortalEconomiario/PECO_DefinirDestinoMultiFundoPj';

export const obterEndpointDefinirRegularMultifundoPj = (): string =>
  '/PortalEconomiario/PECO_DefinirRegularMultiFundoPj';

export const obterEndpointIndicarInstituidoMultiFundoPj = (): string =>
  '/PortalEconomiario/PECO_IndicarInstituidoMultiFundoPj';

export const obterEndpointImprimirDadosExtratoCotasPrevidencia = (): string =>
  '/PortalEconomiario/PECO_imprimirDadosExtratoCotasPrevdencia';

export const obterEndpointCertificadoPrevidenciaDetalhar = (): string =>
  '/PortalEconomiario/PECO_CertificadoPrevidenciaDetalhar';

export const PECOS = {
  ObterDadosOrigemTransferenciaVgbl: 'PECO_ObterDadosOrigemTransferenciaVgbl',
  ObterValorMinimoContribuicao: 'PECO_ObterValorMinimoContribuicao',
  ValidarValoresMinimosTransferenciaVgbl:
    'PECO_ValidarValoresMinimosTransferenciaVgbl',
  ListarOfertasVgblConjugado: 'PECO_ListarOfertasVgblConjugado',
  ListarDadosBancariosVgbl: 'PECO_ListarDadosBancariosVgbl',
  ListarTiposOperacoesBancariasVgbl: 'PECO_ListarTiposOperacoesBancariasVgbl',
  ListarDiasUteisVgbl: 'PECO_ListarDiasUteisVgbl',
  InserirDadosBancariosTransfVgbl: 'PECO_InserirDadosBancariosTransfVgbl',
  TransferirProdutoVgbl: 'PECO_TransferirProdutoVgbl',
  SolicitarAssinaturaTransfVgbl: 'PECO_SolicitarAssinaturaTransfVgbl',
  CancelarTransferenciaVgblConjugado: 'PECO_CancelarTransferenciaVgblConjugado',
  ValidarCancelamentoPreviencia: 'PECO_ValidarCancelamentoPrevidencia',
  obterEndpointExerciciosDisponivesIR: 'PECO_ObterExercicios',
  RelatorioResgate: 'PECO_RelatorioResgate',
  BeneficioProtecao: 'PECO_BeneficioProtecao',
  FaixaRenda: 'PECO_FaixaRendaBeneficioProtecao',
  PrazosBeneficioProtecao: 'PECO_PrazosBeneficioProtecao',
  CalcularBeneficioProtecao: 'PECO_CalcularBeneficioProtecao',
  ReativarBeneficioProtecao: 'PECO_ReativarBeneficioProtecao',
  IncluirBeneficioProtecao: 'PECO_IncluirBeneficioProtecao',
  ExerciciosDisponivesIR: 'PECO_ObterExercicios',
  ObterBeneficiarios: 'PECO_ObterBeneficiarios',
  SolicitarAlteracaoPerfilTributario: 'PECO_SolicitarAlteracaoPerfilTributario',
  AtualizarDataVencimento: 'PECO_AtualizarDataVencimento',
  RecuperarContribuicoesCertificado: 'PECO_RecuperarContribuicoesCertificado',
  AtivarContribuicao: 'PECO_AtivarContribuicao',
  SuspenderContribuicao: 'PECO_SuspenderContribuicao',
  ObterComprovanteResgate: 'PECO_ObterComprovanteResgate',
  ObterExtratoPdf: 'PECO_ObterExtratoPdf',
  ListarFundos: 'PECO_ListarFundosMultiFundoPj',
  ConsultarContribuicaoRegular:
    'PECO_ConsultarFundoContribuicaoRegularMultiFundoPj',
  DefinirSaidaMultifundos: 'PECO_DefinirSaidaMultiFundoPj',
  DefinirDestinoMultifundos: 'PECO_DefinirDestinoMultiFundoPj',
  ValidarMultiFundoPj: 'PECO_ValidarMultiFundoPj',
  DefinirRegularMultiFundoPj: 'PECO_DefinirRegularMultiFundoPj',
  IndicarInstituidoMultiFundoPj: 'PECO_IndicarInstituidoMultiFundoPj',
  ConfirmarTransferenciaMultiFundoPj: 'PECO_ConfirmarTransferenciaMultiFundoPj',
  FinalizarAporte: 'PECO_FinalizarAporte',
  EfetuarAporte: 'PECO_EfetuarAporte',
  ObterDatas: 'PECO_ObterDatas',
  ObterDadosBancarios: 'PECO_ObterDadosBancarios',
  ObterFundos: 'PECO_ObterFundos',
  ValidarConta: 'PECO_ValidarConta',
  PermiteBoleto: 'PECO_PermiteBoleto',
  ObterOperacaoBancaria: 'PECO_ObterOperacaoBancaria',
  ObterFormaPagamentoAporte: 'PECO_ObterFormaPagamentoAporte',
  CancelarResgate: 'PECO_CancelarResgate',
  ObterHistoricoResgate: 'PECO_ObterHistoricoResgate',
  ObterCoberturas: 'PECO_Coberturas',
  SolicitarAssinaturaBeneficiariosPrevidencia:
    'PECO_SolicitarAssinaturaBeneficiariosPrevidencia',
  ModificarBeneficiarios: 'PECO_ModificarBeneficiarios',
  ConsultarPermissaoAssinaturaBeneficiario:
    'PECO_ConsultarPermissaoAssinaturaBeneficiario',
  ConsultarDadosExtratoCotas: 'PECO_ConsultarDadosExtratoCotas',
  GerarQuitacaoAnual: 'PECO_GerarQuitacaoAnual',
  ObterExtratoUnificado: 'PECO_ObterExtratoUnificado',
  ObterLaminaRentabilidade: 'PECO_ObterLaminaRentabilidade',
  SolicitarTransferenciaReducaoTaxaFop223: 'PECO_SolicitarTransferenciaFop223',
  ConsultarPainelUnificado: 'PECO_BuscarHierarquiaResgate',
  ConsultarPainelDetalhado: 'PECO_ConsultarPainelGestaoResgateDetalhado',
  CancelarResgateAssinatura: 'PECO_CancelarResgateComAssinatura',
  ValidarCancelamentoResgate: 'PECO_ConsultarPermissaoAssinaturaResgate',

  ConsultarPainelDetalhadoCertificado:
    'PECO_ConsultarPainelGestaoResgateDetalhadoPorCertificado',
  SolicitarCriacaoProtocoloRegistroOcorrenciaFop223:
    'PECO_SolicitarCriacaoProtocoloRegistroOcorrenciaFop223',
  ObterAnoHistorico: 'PECO_ObterAnoHistorico',
  ObterHistorico: 'PECO_ObterHistorico',
  ObterCertificado: 'PECO_ObterCertificado',
  ObterPagamento: 'PECO_ObterPagamento',
  ObterSegundaVia: 'PECO_ObterSegundaVia',
  GerarExtratoCotas: 'PECO_GerarExtratoCotas',
  ConsultarMotivosResgate: 'PECO_ConsultarMotivosResgate',
  SolicitarResgate: 'PECO_SolicitarResgate',
  ImprimirPosicaoConsolidada: 'PECO_ImprimirPosicaoConsolidada',
  ConsultarDocumentoAssinadoFop223: 'PECO_ConsultarDocumentoAssinadoFop223',
  ConsultarEvolucaoPatrimonial: 'PECO_ConsultarEvolucaoPatrimonial',
  ListarContasBancarias: 'PECO_ListarContasBancarias',
  ResponsavelFinanceiro: 'PECO_ResponsavelFinanceiro',
  CriarCanalPagamento: 'PECO_CriarCanalPagamento',
  AtualizarFormaPagamento: 'PECO_AtualizarFormaPagamento',
  ImprimirDadosExtratoCotasPrevdencia:
    'PECO_imprimirDadosExtratoCotasPrevdencia',
  ListarFundosParaResgate: 'PECO_ListarFundosParaResgate',
  CalcularResgate: 'PECO_CalcularResgate',
  ConsultarResumoAliquota: 'PECO_ConsultarResumoAliquota',
  ConsultarDetalheCalculo: 'PECO_ConsultarDetalheCalculo',
  ListarCanaisPagamento: 'PECO_ListarCanaisPagamento',
  ListarMotivosResgate: 'PECO_ListarMotivosResgate',
  DefinirContribuicaoRegular: 'PECO_DefinirContribuicaoRegular',
  ConfirmarResgate: 'PECO_ConfirmarResgate',
  ConsultarResgatesProgramados: 'PECO_ConsultarResgatesProgramados',
  RecuperarBancos: 'PECO_RecuperarBancos',
  ConsultarContribuicaoRegularResgate: 'PECO_ConsultarContribuicaoRegular',
  SolicitarAssinaturaResgatePrevidencia:
    'PECO_SolicitarAssinaturaResgatePrevidencia',
  ConsultarTiposPagamento: 'PECO_ConsultarTiposPagamento',
  SalvarDadosPagamento: 'PECO_SalvarDadosPagamento',
  CriarMotivoResgate: 'PECO_CriarMotivoResgate',
  ListarAlicotasAgrupadas: 'PECO_ListarAlicotasAgrupadas',
  ConsultarParametrosRegimeTributario:
    'PECO_consultarParametrosRegimeTributario',
  SimularRenda: 'PECO_SimularRenda',
};

const URLS_PECOS_MATRIZ = Object.values(PECOS).map(PECO => basePath + PECO);

export const URLS_MATRIZ_INTERCEPTOR = [
  ...URLS_PECOS_MATRIZ,
  obterEndpointDadosPlano(),
  obterEndpointObterExtrato(),
  obterEndpointExtratoRentabilidadeCotas(),
  obterEndpointContribuicoes(),
  obterEndpointEmissaoCertificado(),
  obterEndpointInformeIR(),
  obterEndpointCoberturas(),
  obterEndpointTipoParentesco(),
  obterEndpointModificarBeneficiarios(),
  obterEndpointAlterarValorContribuicao(),
  obterEndpointValoresContribuicao(),
  obterEndpointAtualizarValorContribuicao(),
  obterEndpointSolicitarAssinaturaBeneficioProtecao(),
  obterEndpointValorMinimoMaximo(),
  obterEndpointListaFundosReserva(),
  obterEndpointConsultarResgatesProgramados(),
  obterEndpointSimularRetirada(),
  obterEndpointContasBancarias(),
  obterEndpointSolicitarResgate(),
  obterEndpointCriarCanalPagamento(),
  obterEndpointPermitirAlterarValorContribuicaoRegular(),
  obterEndpointDefinirContribuicaoRegular(),
  obterEndpointConfirmarTransferenciaMultifundo(),
  obterEndpointConfirmarResgate(),
  obterEndpointObterComprovanteResgate(),
  obterEndpointConsultarResumoAliquota(),
  obterEndpointConsultarDetalheCalculo(),
  obterEndpointExtratoUnificado(),
  obterEndpointLaminaRentabilidade(),
  obterEndpointRentabilidade(),
  obterEndpointFundosPorCertificado(),
  obterEndpointTransferirEntreFundos(),
  obterEndpointDefinirReservaDestino(),
  obterEndpointRevalidarTransferencia(),
  obterEndpointDefinirFundoContribuicaoRegular(),
  obterEndpointConfirmarTransferencia(),
  obterEndpointValidarAssinaturaTransferencia(),
  obterEndpointSegundaViaBoleto(),
  obterEndpointHistoricoAnosDisponiviesDetalhes(),
  obterEndpointAnosHistorico(),
  obterEndpointStatusCoberturasContratadas(),
  obterEndpointAtivarContribuicao(),
  obterEndpointSuspenderContribuicao(),
  obterEndpointMultiFundoPrevidencia(),
  obterEndpointAtualizarDataAposentadoria(),
  obterEndpointConsultarDadosBasico(),
  obterEndpointTiposRendas(),
  obterEndpointSimularBeneficio(),
  obterEndpointConsultarHistoricoSimulacaoRenda(),
  obterEndpointHistoricoSimulacaoRendaPrevidencia(),
  obterEndpointDadosParticipante(),
  obterEndpointEnderecoCEP(),
  obterEndpointAtualizarEndereco(),
  obterEndpointAtualizarEmail(),
  obterEndpointAtualizarTelefone(),
  obterEndpointConsultarConsentimento(),
  obterEndpointAtualizarConsentimento(),
  obterEndpointAtualizarCadastro(),
  obterEndpointConsultarHistoricoSolicitacoes(),
  obterEndpointComprovanteResgate(),
  obterEndpointTiposSolicitacoes(),
  obterEndpointCertificadosPorCpf(),
  obterEndpointAtualizarDataVencimento(),
  obterLinkAtendimentoOnline(''),
  obterEndpointConsultarBeneficiarios(),
  obterEndpointRecuperarInformacoesCertificado(),
  obterEndpointRecuperarContribuicoesCertificado(),
  obterEndpointListarContasBancarias(),
  obterEndpointAtualizarFormaPagamento(),
  obterEndpointRecuperarListaBancos(),
  obterEndpointEnviarEmail(),
  obterEndPointAporteFundos(),
  obterEndPointAportePermiteBoleto(),
  obterEndPointAporteDatasPagamento(),
  obterEndPointAporteDadosBancarios(),
  obterEndPointEfetuarAporte(),
  obterEndPointFinalizarAporte(),
  obterEndPointAporteFormaPagamento(),
  obterEndPointOperacaoBancaria(),
  obterEndPointDadosPep(),
  obterEndpointSolicitarCancelamentoPrevidencia(),
  obterEndpointBeneficioProtecao(),
  obterEndpointPrazosBeneficioProtecao(),
  obterEndpointFaixaRendaBeneficioProtecao(),
  obterEndpointCalcularBeneficioProtecao(),
  obterEndpointIncluirBeneficioProtecao(),
  obterEndpointReativarBeneficioProtecao(),
  obterEndpointResponsavelFinanceiromaPagamento(),
  obterLinkAtendimentoSalesForceOnline(''),
  obterEndPointAssinaturaTransferenciaFundos(),
  obterEndpointConsultarPermissaoAssinaturaBeneficiario(),
  obterEndpointAtualizarDadosBancarios(),
  obterEndpointListarDadosBancarios(),
  obterEndpointValidarNecessidadeAssinaturaEletronica(),
  obterEndpointSolicitarAssinaturaResgatePrevidencia(),
  obterEndpointGraficoRentabilidadeCarteira(),
  obterEndpointConsultarPermissaoAssinaturaBeneficiario(),
  obterEndpointCertificadoPrevidenciaDetalhar(),
];
