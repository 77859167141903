import React from 'react';
import { Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import ComprovanteAporteViaBoleto from 'previdencia/features/Aporte/components/ComprovanteAporte/ComprovanteAporteViaBoleto';
import ComprovanteAporteViaDebito from 'previdencia/features/Aporte/components/ComprovanteAporte/ComprovanteAporteViaDebito';
import * as UTILS from 'previdencia/features/Aporte/utils/aporteUtils';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';
import * as S from 'previdencia/features/Aporte/styles';

const ComprovanteAporteDadosSolicitacao: React.FC<
  APORTE_TYPES.IComprovanteAporteDadosSolicitacaoProps
> = ({ aporteFormaPagamento, aporteDadosBancarios }) => {
  const { featureData } =
    usePrevidenciaContext<APORTE_TYPES.IAporteContextData>();

  const responseEfetuarAporte = featureData?.dadosAporteAdicional?.entidade;
  return (
    <>
      <S.ContainerListaAporte>
        <Text variant="body02-lg">Dados da Solicitação</Text>
        <S.ContainerCinzaAporte>
          <Text variant="body02-sm">Número da Solicitação:</Text>
          <Text variant="body02-sm">
            {tryGetValueOrDefault(
              [responseEfetuarAporte?.numeroSolicitacao],
              '-',
            )}
          </Text>
        </S.ContainerCinzaAporte>
        <S.ContainerBrancoAporte>
          <Text variant="body02-sm">Forma de Pagamento:</Text>
          <Text variant="body02-sm">
            {UTILS.tipoPagamento(
              aporteFormaPagamento.get().value.formaPagamento,
            )}
          </Text>
        </S.ContainerBrancoAporte>
        <S.ContainerCinzaAporte>
          <Text variant="body02-sm">
            Valor da Contribuição Adicional / Aporte:
          </Text>
          <Text variant="body02-sm">
            {tryGetMonetaryValueOrDefault(
              Number(aporteFormaPagamento.get().value.valorContribuicao) / 100,
            )}
          </Text>
        </S.ContainerCinzaAporte>
        <RenderConditional
          condition={
            aporteFormaPagamento.get().value.formaPagamento ===
            APORTE_TYPES.EnumFormaPagamento.DEBITO
          }
        >
          <ComprovanteAporteViaDebito
            agenciaBancaria={aporteDadosBancarios.get().value.agencia}
            contaBancaria={aporteDadosBancarios.get().value.conta}
            dataSelecionada={aporteFormaPagamento.get().value.dataDebito}
            operacaoBancaria={aporteDadosBancarios.get().value.operacao}
          />
        </RenderConditional>
        <RenderConditional
          condition={
            aporteFormaPagamento.get().value.formaPagamento ===
            APORTE_TYPES.EnumFormaPagamento.BOLETO
          }
        >
          <ComprovanteAporteViaBoleto />
        </RenderConditional>
      </S.ContainerListaAporte>
    </>
  );
};
export default ComprovanteAporteDadosSolicitacao;
