import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Display, Grid, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import TagStatusRenda from 'previdencia/features/SimulacaoRenda/components/TagStatusRenda';
import * as S from 'previdencia/features/SimulacaoRenda/components/TiposRenda/styles';
import useObterTiposRendas from 'previdencia/features/SimulacaoRenda/hooks/useObterTiposRendas';
import { TiposRendaProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';
import {
  EnumTipoRendaCodigo,
  EtapasFluxoSimulacaoRenda,
} from 'previdencia/features/SimulacaoRenda/types/enum';
import { retornarTipoRenda } from 'previdencia/features/SimulacaoRenda/utils/simulacaoRenda';
import { Beneficios } from 'previdencia/types/Beneficiarios';
import {
  TIPO_RENDA_CODIGO,
  TIPOS_RENDA_SIMULACAO_RENDA,
} from 'previdencia/features/SimulacaoRenda/constants/constants';
import { useObterCoberturas } from 'previdencia/features/SimulacaoRenda/hooks/useObterDadosCoberturas';
import { useSimulacaoRendaContextData } from 'previdencia/features/SimulacaoRenda/hooks/useSimulacaoRendaContextData';

const TiposRenda: React.FC<TiposRendaProps> = ({ planoContratado }) => {
  const { data: tiposRendas, isLoading: loadingTiposPagamentos } =
    useObterTiposRendas();

  const { response: responseCoberturas, ObterCoberturas } =
    useObterCoberturas();

  const {
    setEtapaAtual,
    featureData: { podeAlterarRegimeTributario },
  } = useSimulacaoRendaContextData();

  const coberturasPorTipoRenda: Beneficios[] | undefined = useMemo(
    () =>
      responseCoberturas?.retorno?.beneficios?.filter(cobertura => {
        return TIPO_RENDA_CODIGO.includes(cobertura.codTipoPagamentoOriginal);
      }),
    [responseCoberturas],
  );

  const receberTipoRenda = useCallback(
    (codigoTipoRenda: string): void => {
      const coberturaContratada = coberturasPorTipoRenda?.find(
        cobertura => cobertura.codTipoPagamentoOriginal === codigoTipoRenda,
      );

      const calculoAutomaticoSimulacao = checkIfAllItemsAreTrue([
        !!coberturaContratada,
        codigoTipoRenda !==
          EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario,
      ]);

      if (calculoAutomaticoSimulacao) {
        setEtapaAtual(EtapasFluxoSimulacaoRenda.RESULTADO_SIMULACAO, {
          tipoRenda: codigoTipoRenda,
          qtdPrazoAnos: tryGetValueOrDefault(
            [coberturaContratada?.termoDesejado],
            '',
          ),
        });
      } else {
        setEtapaAtual(EtapasFluxoSimulacaoRenda.CALCULO_PRE_SIMULACAO, {
          tipoRenda: codigoTipoRenda,
        });
      }
    },
    [coberturasPorTipoRenda, podeAlterarRegimeTributario],
  );

  useEffect(() => {
    ObterCoberturas();
  }, []);

  if (loadingTiposPagamentos) {
    return <SkeletonLoading blocks={2} />;
  }

  return (
    <>
      <Text variant="body02-md" color="primary" margin>
        Tipos de Renda
      </Text>

      <S.ContainerTiposRenda>
        {tiposRendas?.map(tipoRenda => {
          const renda = retornarTipoRenda(
            TIPOS_RENDA_SIMULACAO_RENDA,
            tipoRenda?.codTipoPagamento,
          );

          return (
            <S.ContainerTiposRendaItem key={tipoRenda.codTipoPagamento}>
              <Grid.Item xs={1} md={2 / 10}>
                <Display alignItems="center">
                  <Text variant="lead" margin>
                    <strong>{renda?.tipo}</strong>
                  </Text>
                  <RenderConditional
                    condition={tipoRenda.codTipoPagamento === planoContratado}
                    component={<TagStatusRenda status="1" />}
                  />
                </Display>
              </Grid.Item>

              <Grid.Item xs={1} lg={6 / 10}>
                <Display alignItems="center">
                  <Text variant="lead">{renda?.descricao}</Text>
                </Display>
              </Grid.Item>

              <Grid.Item xs={1} lg={2 / 10}>
                <Display alignItems="center">
                  <Button
                    variant="secondary"
                    onClick={() => receberTipoRenda(tipoRenda.codTipoPagamento)}
                  >
                    Simular
                  </Button>
                </Display>
              </Grid.Item>
            </S.ContainerTiposRendaItem>
          );
        })}
      </S.ContainerTiposRenda>
    </>
  );
};

export default TiposRenda;
