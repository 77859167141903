export const COLUNAS_TABELA_COMPROVANTE_CONFIRMACAO_VALOR_CONTRIBUICAO = [
  {
    id: '1',
    name: 'Código do Fundo',
    selector: 'codFundo',
    maxWidth: '145px',
  },
  {
    id: '2',
    name: 'Descrição do Fundo',
    selector: 'descFundo',
    minWidth: '340px',
  },
  {
    id: '3',
    name: 'Valor Contribuição',
    selector: 'valorContribuicao',
    maxWidth: '180px',
  },
];

export const TEXTO_CONFIRMACAO_CLIENTE_CIENTE = (
  mesesCarenciaBeneficioProtecaoValue: number | undefined,
) => `Cliente ciente de que a cobertura solicitada poderá ocasionar
aumento da parcela e possui carência de${' '}
${mesesCarenciaBeneficioProtecaoValue} meses para eventos naturais.${' '}
O Valor da nova cobertura será reajustado anualmente, de acordo${' '}
com o indexador do plano, conforme regulamento do produto. Além${' '}
disso, o valor pago paro o Benefício de Proteção, não compõe a${' '}
reserva acumulada.`;

export const MSG_ERRO_AMIGAVEL_SOLICITACAO_CONTRIBUICAO =
  'Falha ao executar a operação! O certificado possui solicitação de manutenção em andamento ou sistema indisponível.';

export const QUANTIDADE_MAXIMA_FUNDOS = 100;
