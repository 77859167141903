import { isCnpj } from 'main/utils/cpf_cnpj';
import { TIPO_PESSOA_DPS } from '../constants/MapeamentoStatus';
import {
  IResponseListarStatusPropostaDps,
  IStatusPropostaDpsAgrupado,
} from '../types/IResponseListarStatusPropostaDps';

export const agruparPorCnpj = (
  propostas: IResponseListarStatusPropostaDps[],
): IStatusPropostaDpsAgrupado[] => {
  const agrupado: { [key: number | string]: IStatusPropostaDpsAgrupado } = {};

  propostas.forEach((item, idx) => {
    const novoItem = item;
    const cnpj = novoItem.numCpfCnpjFormatado;
    const newCnpj = isCnpj(item.numCpfCnpjFormatado)
      ? cnpj
      : `${cnpj}_${idx + 1}`;

    if (!agrupado[newCnpj]) {
      agrupado[newCnpj] = {
        ...novoItem,
        socios: [],
      };
    }

    const socio: IResponseListarStatusPropostaDps = { ...novoItem };
    if (item.tipoPessoa === TIPO_PESSOA_DPS.FISICA && !socio.numCpfSocio) {
      socio.numCpfSocio = socio.numCpfCnpj;
      socio.numCpfSocioFormatado = socio.numCpfCnpjFormatado;
    }
    socio.dpsVerificada = true;
    delete socio.socios;
    if (socio.numCpfSocio) {
      agrupado[newCnpj].socios.push(socio);
    }
  });
  return Object.values(agrupado);
};
