import { removerMascaraValorMonetario } from 'main/utils/money';
import { ordenarDataGenerica } from 'previdencia/utils/previdencia';
import { CODIGO_MEIO_PAGAMENTO } from '../constants/constants';

export const obterMeioPagamento = (codMeioPagamento: string) => {
  return codMeioPagamento === CODIGO_MEIO_PAGAMENTO.BOLETO
    ? 'Boleto'
    : 'Débito em conta';
};

export const ordenaValorContribuicao = (rowA: any, rowB: any) => {
  const a = removerMascaraValorMonetario(rowA.valorContribuicao);
  const b = removerMascaraValorMonetario(rowB.valorContribuicao);
  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const ordenaDataVencimento = (rowA: any, rowB: any) => {
  return ordenarDataGenerica(rowA.dataVencimento, rowB.dataVencimento);
};

export const ordenaDataPagamento = (rowA: any, rowB: any) => {
  return ordenarDataGenerica(rowA.dataPagamento, rowB.dataPagamento);
};

export const obterProximoMes = () => {
  const dataAtual = new Date();
  const proximoMes = new Date(dataAtual.setMonth(new Date().getMonth() + 1));
  return proximoMes.toISOString();
};
