import styled from 'styled-components/macro';
import { Accordion } from '@cvp/design-system/react';

export const Sidebar = styled.aside`
  background: ${props => props.theme.color.neutral['07']};
  border-right: solid 1px ${props => props.theme.color.neutral['06']};
  position: relative;
  flex: 0 0 110px;
  @media print {
    display: none;
  }
`;

export const ListItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  .menu-target {
    background: white;
    outline: 2px solid rgba(0, 0, 0, 0.3);
    color: black;
    cursor: pointer;
    ul {
      display: block;
    }
    :after {
      content: '';
      position: absolute;
      background: rgba(0, 0, 0, 0.6);
      top: 0;
      left: 100%;
      right: 0;
      bottom: 0;
      width: 100vw;
      z-index: 199;
      pointer-events: none;
    }
  }

  .accordion-target {
    background: white;
    outline: 2px solid rgba(0, 0, 0, 0.3);
    color: black;
    cursor: pointer;

    .accordion-items {
      height: auto;
    }
    .accordion-fechado {
      height: 1110px !important;
      color: red !important;
    }
  }

  &.with-subitems:after {
    content: '';
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 100%;
    right: 0;
    bottom: 0;
    width: 100vw;
    z-index: 199;
  }
`;

export const Item = styled.li<{ fixedColor?: string }>`
  > span,
  > a {
    border-bottom: 1px solid ${props => props.theme.color.neutral['06']};
    width: 100%;
    display: block;
    text-align: center;
    font-size: 14px;
    color: ${props => props.fixedColor ?? props.theme.color.neutral['04']};
    padding: 12px 2px;
    text-decoration: none;

    &.active,
    &:hover,
    &:focus {
      background: white;
      color: ${props => props.fixedColor ?? props.theme.color.neutral['04']};
      cursor: pointer;
    }

    i {
      font-size: 28px;
      display: block;
      line-height: 1;
      color: #ff7200;
    }
    .image-container {
      display: block;
      justify-content: center;
    }
    img,
    svg {
      width: 24px;
      height: 24px;
      line-height: 1;
    }
  }
  &:hover,
  &:focus {
    > span,
    > a {
      background: white;
      color: ${props => props.fixedColor ?? props.theme.color.line.primary};
    }
    ul {
      display: block;
    }
    img {
      filter: none;
    }
  }

  &.with-subitems:hover:after {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 100%;
    right: 0;
    bottom: 0;
    width: 100vw;
    z-index: 199;
    pointer-events: none;
  }

  &:focus {
    background: white;
    outline: 2px solid 2px solid rgba(0, 0, 0, 0.3);
    color: ${props => props.fixedColor ?? props.theme.color.neutral['04']};
    cursor: pointer;
    ul {
      display: block;
    }
  }

  &.with-subitems:focus:after {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 100%;
    right: 0;
    bottom: 0;
    width: 100vw;
    z-index: 199;
    pointer-events: none;
  }
`;

export const ListSubItems = styled.ul`
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 100%;
  top: 0;
  width: 242px;
  border-left: 1px solid ${props => props.theme.color.neutral['06']};
  background: ${props => props.theme.color.neutral['08']};
  height: 100%;
  z-index: 2000;
`;

export const Accordiont = styled(Accordion)(({ theme }) => ({
  'div:first-child': {
    borderBottomColor: theme.color.neutral['06'],
  },
  ':focus:after': {
    content: '',
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.6)',
    top: '0',
    left: '100%',
    right: '0',
    bottom: '0',
    width: '100vw',
    zIndex: '199',
    pointerEvents: 'none',
  },
}));

export const AccordionItem = styled(Accordion.Item)(({ theme, open }) => ({
  '> div:nth-child(1)': {
    padding: '16px 10px 16px 21px',
    '> p': {
      fontSize: 14,
      color: theme.color.neutral['04'],
    },
    ...(open && {
      backgroundColor: theme.color.neutral['07'],
      color: theme.color.line.primary,
    }),
    boxShadow: 'none',
    '-webkit-box-shadow': 'none',
    '-moz-box-shadow': 'none',
    'box-shadow': 'none',
  },
  'div:nth-child(2)': {
    borderLeft: `4px solid ${theme.color.neutral['06']}`,
  },

  div: {
    a: {
      '&:last-child,  :hover, :focus': {
        border: 'none',
      },
    },
  },
  'div:last-child': {
    ':after': {
      content: 'none',
    },
  },
  ':focus': {
    background: 'white',
    outline: '2px solid rgba(0, 0, 0, 0.3)',
    color: 'black',
    cursor: 'pointer',
  },
  ':focus:after': {
    content: '',
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.6)',
    top: '0',
    left: '100%',
    right: '0',
    bottom: '0',
    width: '100vw',
    zIndex: '199',
    pointerEvents: 'none',
  },
}));

export const SubItem = styled.ul`
  font-size: 14px;
  box-shadow: 0px -21px 0 -20px ${props => props.theme.color.neutral['06']};
  &:first-child {
    box-shadow: none;
  }

  a {
    text-decoration: none;
    padding: 21px;
    display: block;
    color: ${props => props.theme.color.neutral['04']};
    border-bottom: solid 1px ${props => props.theme.color.neutral['06']};

    &.active,
    &:hover,
    &:focus {
      background-color: ${props => props.theme.color.neutral['07']};
      color: ${props => props.theme.color.line.primary};
      border-bottom: solid 1px ${props => props.theme.color.neutral['06']};
    }
  }
  &:focus {
    background-color: ${props => props.theme.color.neutral['07']};
    border-bottom: solid 1px ${props => props.theme.color.neutral['07']};
    a {
      color: ${props => props.theme.color.line.primary};
    }
  }
  &:focus {
    background: white;
    outline: 2px solid rgba(0, 0, 0, 0.3);
    color: black;
    cursor: pointer;
    ul {
      display: block;
    }
  }

  &:focus:after {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 100%;
    right: 0;
    bottom: 0;
    width: 100vw;
    z-index: 199;
    pointer-events: none;
  }

  .accordionBorder {
    background: white;
    outline: 2px solid rgba(0, 0, 0, 0.3);
    color: black;
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

export const Middle = styled.div`
  display: flex;
  flex: auto;
`;

export const Main = styled.main(({ theme }) => ({
  flex: 'auto',
  overflow: 'hidden',
  marginTop: '0',
  paddingBottom: '75px',

  hr: {
    margin: '12px 0',
  },

  [theme.breakpoint.md()]: {
    paddingBottom: '25px',
  },
}));
