import {
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import { IMenuItem } from 'main/components/Menu/Sidebar/types';

export const menuConsulta: IMenuItem = {
  label: 'Consultas',
  alt: 'consultas',
  path: '/cliente',
  icon: 'search',
  roles: [
    ...PRINCIPAL_USERS,
    USER_PROFILES.ANALISTA_MANUTENCAO,
    USER_PROFILES.ANALISTA_SAIDA,
    USER_PROFILES.ANALISTA_ENTRADA,
    USER_PROFILES.ANALISTA_CONSULTA,
  ],
  subItems: [
    {
      label: 'Cliente',
      path: '/cliente',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_MANUTENCAO,
        USER_PROFILES.ANALISTA_SAIDA,
        USER_PROFILES.ANALISTA_ENTRADA,
        USER_PROFILES.ANALISTA_CONSULTA,
      ],
    },
    {
      label: 'Relatórios',
      path: '/relatorios/prestamista',
      roles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
      subItems: [
        {
          label: 'Dashboard Upload Prestamista',
          path: '/relatorios/prestamista/upload',
          roles: PRINCIPAL_USERS,
        },
        {
          label: 'Dashboard Retenção de Prestamista',
          path: '/relatorios/prestamista/cancelamento',
          roles: [...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA],
        },
      ],
    },
    {
      label: 'Status de Sinistro',
      path: '/sinistro/status',
      roles: [
        ...PRINCIPAL_USERS,
        USER_PROFILES.ANALISTA_CONSULTA,
        USER_PROFILES.ANALISTA_SAIDA,
        USER_PROFILES.ANALISTA_ENTRADA,
      ],
    },
    {
      label: 'Status de Vendas',
      path: '/statusVendas',
      roles: PRINCIPAL_USERS,
    },
    {
      label: 'Valor da IS',
      path: '/ferramentas/consultar-valor-is',
      roles: [USER_PROFILES.ANALISTA_TI, USER_PROFILES.ANALISTA_MANUTENCAO],
    },
  ],
};
