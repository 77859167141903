import { usePeco } from 'main/hooks/usePeco';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { PECOS } from 'previdencia/config/endpoints';
import {
  IRequestSimularBeneficioApi,
  ResponseSimularBeneficios,
} from 'previdencia/features/SimulacaoRenda/types/simularBeneficios';

export const useSimularBeneficio = (request: IRequestSimularBeneficioApi) => {
  return usePeco<IRequestSimularBeneficioApi, ResponseSimularBeneficios>({
    api: { operationPath: PECOS.SimularRenda },
    autoFetch: false,
    payload: {
      cpf: tryGetValueOrDefault([request?.cpf], ''),
      numeroCertificado: tryGetValueOrDefault([request?.numeroCertificado], ''),
      tipoRenda: request?.tipoRenda,
      qtdPrazoAnos: request?.qtdPrazoAnos,
      pctReversao: request?.pctReversao,
      dthNascimentoConjuge: request?.dthNascimentoConjuge,
      staGeneroConjuge: request?.staGeneroConjuge,
      cpfBeneficiario: request?.cpfBeneficiario,
      tipoTributacao: request?.tipoTributacao,
    },
  });
};

export default useSimularBeneficio;
