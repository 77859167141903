import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  Display,
  Divider,
  Grid,
  Text,
  Button,
  Select,
} from '@cvp/design-system/react';
import EmailSenderModal from 'main/components/EmailSenderModal';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import SkeletonLoading from 'main/components/SkeletonLoading';
import Table from 'main/components/Table';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import useFieldLink from 'main/hooks/useFieldLink';
import guid from 'main/utils/guid';
import useObterAnoHistorico from 'previdencia/features/ReajusteAnualPlano/hooks/useObterAnoHistorico';
import useObterAnosDetalhes from 'previdencia/features/ReajusteAnualPlano/hooks/useObterAnosDetalhes';
import { useLocation } from 'react-router';
import { AppContext } from 'main/contexts/AppContext';
import RenderConditional from 'main/components/RenderConditional';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import usePrint from 'previdencia/hooks/usePrint';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import * as S from 'previdencia/features/ReajusteAnualPlano/pages/styles';
import {
  columnsCuidadoExtra,
  columnsReserva,
  INFORMACOES_REAJUSTE,
} from 'previdencia/features/ReajusteAnualPlano/constants/constants';
import ReajusteAnualPlanoHeader from 'previdencia/features/ReajusteAnualPlano/components/ReajusteAnualPlanoHeader';

const InformacaoReajusteAnualPlano: React.FC = () => {
  const { cliente } = useContext(AppContext);
  const [anoSelecionadoLink] = useFieldLink('');
  const location = useLocation<{ anoSelecionado: string }>();
  const { anoSelecionado } = location.state;
  const [anoSelected, setAnoSelected] = useState<{
    text: string;
    value: string;
  }>({ text: anoSelecionado, value: anoSelecionado });
  const { navigateTo } = usePrevNavigation();

  const { dadosTabelaCuidadoExtra, dadosTabelaReserva, isFetching, refetch } =
    useObterAnoHistorico(
      cliente.cpfCnpj,
      cliente.numCertificado,
      anoSelected.value,
    );
  const { print } = usePrint();
  const { data, isLoading: isLoadingAnosDetalhes } = useObterAnosDetalhes(
    cliente.cpfCnpj,
    cliente.numCertificado,
  );

  function isSelected(value: string) {
    return anoSelected?.value === value;
  }

  const montarObjetoEmail = (
    cpfCnpj: string,
    numeroCertificado: string,
    anoConsulta: string,
  ) => {
    return {
      tipoEmail: tipoEmailConstants.REAJUSTE_ANUAL_PLANO_PREVIDENCIA,
      parametrosEnvio: {
        NumeroCertificado: numeroCertificado,
        CpfCnpj: cpfCnpj,
        Ano: anoConsulta,
      },
    };
  };

  useEffect(() => {
    anoSelecionadoLink.set({
      value: anoSelected.value,
      isValid: true,
      errorMsg: '',
    });

    if (!anoSelected.value) {
      return;
    }
    refetch();
  }, [anoSelected]);

  if (isFetching || isLoadingAnosDetalhes) {
    return <SkeletonLoading blocks={2} />;
  }

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredRoles={[
          ...PRINCIPAL_USERS,
          USER_PROFILES.ANALISTA_MANUTENCAO,
          USER_PROFILES.ANALISTA_CONSULTA,
        ]}
        requiredPermissions={[PREV_PERMISSIONS.REAJUSTE_E_REENQUADRAMENTO]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <ReajusteAnualPlanoHeader />

              <Grid.Item xs={1}>
                <Text variant="body01-lg" color="primary" margin>
                  Histórico de Reajuste e Reenquadramento
                </Text>
                <Text variant="body02-md" color="text-light" margin>
                  Consulte as atualizações feitas a partir de 2019
                </Text>
              </Grid.Item>

              <Grid.Item xs={1}>
                <Display>
                  <Text variant="body02-md" color="text-light">
                    Período exibido:
                  </Text>
                  <div style={{ width: '20%', position: 'relative' }}>
                    <Select
                      placeholder="Escolha uma opção"
                      onChange={({
                        target: value,
                      }: React.ChangeEvent<{
                        text: string;
                        value: string;
                      }>) => {
                        setAnoSelected(value);
                      }}
                    >
                      {data?.dados.map(item => (
                        <Select.Item
                          key={guid()}
                          value={item.numAno}
                          text={item.numAno}
                          selected={isSelected(item.numAno)}
                        />
                      ))}
                    </Select>
                  </div>
                </Display>
              </Grid.Item>

              <Grid.Item xs={1}>
                <Text variant="body01-md" color="primary" margin>
                  Reserva
                </Text>
                <Table
                  data={dadosTabelaReserva ?? []}
                  columns={columnsReserva}
                  noHeader
                  responsive
                  noDataComponent="Não há dados para exibir."
                />
              </Grid.Item>
              <RenderConditional
                condition={dadosTabelaCuidadoExtra?.length !== 0}
              >
                <Grid.Item xs={1}>
                  <Divider />
                  <Text variant="body01-md" color="primary" margin>
                    Cuidado Extra
                  </Text>
                  <Table
                    data={dadosTabelaCuidadoExtra ?? []}
                    columns={columnsCuidadoExtra}
                    noHeader
                    responsive
                    noDataComponent="Não há dados para exibir."
                  />
                </Grid.Item>
              </RenderConditional>
            </Grid>

            <RenderConditional
              condition={dadosTabelaCuidadoExtra?.length !== 0}
            >
              <Grid.Item xs={1} className="hide-print">
                <S.WrapperWarning>
                  <Text variant="body01-lg" color="warning" margin>
                    IMPORTANTE
                  </Text>
                  <Text variant="body02-md" color="text-light" margin>
                    {INFORMACOES_REAJUSTE.CUIDADO_EXTRA}
                  </Text>
                  <Text variant="body05-md" color="text-light" margin>
                    {INFORMACOES_REAJUSTE.CUIDADO_EXTRA_EXEMPLO}
                  </Text>
                </S.WrapperWarning>
              </Grid.Item>
            </RenderConditional>
            <Grid.Item xs={1} className="hide-print">
              <Text variant="body03-md" color="text-light" margin>
                {INFORMACOES_REAJUSTE.VARIACAO_INDICE}
              </Text>
              <S.Separador />
              <Text variant="body03-md" color="text-light" margin>
                {INFORMACOES_REAJUSTE.UTILIZA_IGPM}
              </Text>
            </Grid.Item>
            <Grid.Item xs={1} className="hide-print">
              <Divider />
              <Text variant="body02-md" color="text-light" margin>
                Dúvidas? Entre em contato com a Central de Serviços e
                Relacionamento:
              </Text>

              <Text variant="body03-md" color="text-light" margin>
                - 0800 702 4000
              </Text>
            </Grid.Item>
            <Divider className="hide-print" />
            <div className="hide-print">
              <S.VerticalButtons>
                <Button
                  variant="outlined"
                  onClick={() => navigateTo('reajuste-anual-do-plano')}
                >
                  Voltar
                </Button>

                <S.ButtonSeparador>
                  <Button
                    data-testid="button-print"
                    variant="secondary"
                    onClick={() => print()}
                  >
                    Imprimir
                  </Button>
                </S.ButtonSeparador>
                <S.ButtonSeparador>
                  <EmailSenderModal
                    objetoEmail={montarObjetoEmail(
                      cliente.cpfCnpj,
                      cliente.numCertificado,
                      anoSelected.value,
                    )}
                  />
                </S.ButtonSeparador>
              </S.VerticalButtons>
            </div>
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
    </Display>
  );
};

export default InformacaoReajusteAnualPlano;
