import styled from 'styled-components/macro';

export const ContainerSelecaoNovaConta = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  margin: '20px 0 40px 0',

  '& label': {
    marginRight: '10px',
  },
}));
