import React from 'react';
import { Text, Grid, Display, Card } from '@cvp/design-system/react';

import RenderConditional from 'main/components/RenderConditional';
import VoltarDadosPlano from 'main/components/Button/voltarDadosPlano/VoltarDadosPlano';
import Icon from 'main/components/Icon';
import SkeletonLoading from 'main/components/SkeletonLoading';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import useAlteracaoFormaDadosPagamento from 'previdencia/features/AlteracaoFormaDadosPagamento/hooks/useAlteracaoFormaDadosPagamento';
import * as CONSTS from 'previdencia/features/AlteracaoFormaDadosPagamento/constants/constants';
import * as S from './styles';

const AlteracaoFormaDadosPagamento: React.FC = () => {
  const { skeletonLoading, dadosResponsavel, handleAbreChat, etapaAtualFluxo } =
    useAlteracaoFormaDadosPagamento();

  if (skeletonLoading) {
    return <SkeletonLoading blocks={1} />;
  }

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredRoles={[...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_MANUTENCAO]}
        requiredPermissions={[PREV_PERMISSIONS.ALTERAR_DADOS_DE_PAGAMENTO]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-05" color="primary">
                  Alterar Forma e Dados de Pagamento
                </Text>
              </Grid.Item>

              <RenderConditional condition={!dadosResponsavel.cpf}>
                <Grid.Item xs={1}>
                  {CONSTS.ESTA_ALTERACAO_APLICA}
                  <S.IconButton
                    large
                    variant="secondary-white"
                    onClick={() => window.open(handleAbreChat(), '_blank')}
                  >
                    <Icon name="chatIcon" />
                  </S.IconButton>
                </Grid.Item>
                <Grid.Item xs={1}>
                  <Display>
                    <VoltarDadosPlano />
                  </Display>
                </Grid.Item>
              </RenderConditional>

              <RenderConditional condition={!!dadosResponsavel.cpf}>
                {etapaAtualFluxo}
              </RenderConditional>
            </Grid>
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
    </Display>
  );
};

export default AlteracaoFormaDadosPagamento;
