import { Display } from '@cvp/design-system/react';
import * as S from 'main/components/AssinaturaDocuSign/styles';
import {
  DADOS_DO_PARTICIPANTE,
  MENSAGEM_CONTATOS_AUSENTES,
} from 'main/components/AssinaturaDocuSign/constants/contatosInvalidos';

export function MensagemContatosInvalidos() {
  return (
    <Display justify="center">
      <S.Text variant="caption-02" color="error">
        {MENSAGEM_CONTATOS_AUSENTES}
      </S.Text>
      <S.Text variant="caption-02" color="error">
        {DADOS_DO_PARTICIPANTE}
      </S.Text>
    </Display>
  );
}
