import React from 'react';
import { Text } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import * as S from 'main/styles/GlobalStyle';
import { CoberturaContratada } from '../../types/CoberturasContratadas.types';

type ListaInformacoesPlanoReservaProps = {
  dados: CoberturaContratada | undefined;
};
const ListaInformacoesPlanoReserva: React.FC<
  ListaInformacoesPlanoReservaProps
> = ({ dados }) => {
  return (
    <S.ContainerLista>
      <S.ContainerCinza>
        <Text variant="body02-sm">Produto :</Text>
        <Text variant="body02-sm">{dados?.descricaoProduto}</Text>
      </S.ContainerCinza>
      <S.ContainerBranco>
        <Text variant="body02-sm">Certificado :</Text>
        <Text variant="body02-sm">{dados?.certificado}</Text>
      </S.ContainerBranco>
      <S.ContainerCinza>
        <Text variant="body02-sm">Reserva acumulada :</Text>
        <Text variant="body02-sm">
          {formatarValorPadraoBrasileiro(dados?.valorAcumulado)}
        </Text>
      </S.ContainerCinza>
      <S.ContainerBranco>
        <Text variant="body02-sm">Valor da Contribuição :</Text>
        <Text variant="body02-sm">
          {formatarValorPadraoBrasileiro(dados?.valor)}
        </Text>
      </S.ContainerBranco>
      <S.ContainerCinza>
        <Text variant="body02-sm">Dia de Vencimento :</Text>
        <Text variant="body02-sm">{dados?.diaVenciamento}</Text>
      </S.ContainerCinza>
    </S.ContainerLista>
  );
};

export default ListaInformacoesPlanoReserva;
