import { IApiResponse, api } from 'main/services';
import { obterEndpointTimelinePrestamista } from 'sinistro/config/endpoints';
import { IFormTimelinePrestamistaParams } from 'sinistro/features/statusSinistro/types/FormularioSinistroParams';
import { IStatusSinistroTimelinePrestamista } from 'sinistro/features/statusSinistro/types/IStatusSinistro';

export const obterTimelinePrestamista = async (
  params: IFormTimelinePrestamistaParams,
): Promise<IStatusSinistroTimelinePrestamista | undefined> => {
  const resultado = await api.post<
    IApiResponse<IStatusSinistroTimelinePrestamista>
  >(obterEndpointTimelinePrestamista(), params);

  const mensagemErro: string | undefined =
    resultado?.data?.dados?.mensagens?.[0].descricao;

  if (resultado?.data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return resultado?.data?.dados?.entidade;
};
