import React from 'react';
import { Grid, Text, Divider } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { dadosBancariosFormaPagamento } from 'seguros/utils/validarDadosConta';
import { ApoliceDadosBancariosProps } from 'seguros/types/DadosApolice';
import { Display } from 'seguros/components/ApoliceDadosBancarios/styles';
import * as S from 'seguros/components/TabelasApoliceImpressao/components/styles';
import { GridItem } from 'seguros/components/CardResumoProduto/InfoGridItemImpressao/styled';

export const ApoliceDadosBancariosImpressao: React.FC<
  ApoliceDadosBancariosProps
> = ({ data }) => {
  const isPagamentoDebito = checkIfAllItemsAreTrue([
    !!data?.dadosDebito?.agenciaOperacao,
    !!data?.dadosDebito?.numeroConta,
  ]);

  return (
    <S.WrapperTableContainer>
      <RenderConditional condition={Boolean(data?.dadosDebito)}>
        <Text variant="body02-md" color="primary" margin>
          Dados Bancários
        </Text>

        <Grid>
          <RenderConditional condition={isPagamentoDebito}>
            <GridItem xs={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Agência
                  </Text>
                  <Text variant="caption-02" color="text">
                    {data?.dadosDebito?.agenciaCodigo}
                  </Text>
                </div>
              </Display>
            </GridItem>
            <GridItem xs={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Operação
                  </Text>
                  <Text variant="caption-02" color="text">
                    {data?.dadosDebito?.agenciaOperacao}
                  </Text>
                </div>
              </Display>
            </GridItem>
            <GridItem xs={1 / 3}>
              <Display>
                <div>
                  <Text variant="caption-02" color="text-light">
                    Conta de Débito
                  </Text>
                  <Text variant="caption-02" color="text">
                    {data?.dadosDebito?.numeroConta} -{' '}
                    {data?.dadosDebito?.digitoConta}
                  </Text>
                </div>
              </Display>
            </GridItem>
          </RenderConditional>

          <GridItem xs={1 / 3}>
            <Display>
              <div>
                <Text variant="caption-02" color="text-light">
                  Periodicidade de Pagamento
                </Text>
                <Text variant="caption-02" color="text">
                  {tryGetValueOrDefault([data?.periodoPagamento], '-')}
                </Text>
              </div>
            </Display>
          </GridItem>
          <GridItem xs={1 / 3}>
            <Display>
              <div>
                <Text variant="caption-02" color="text-light">
                  Dia de Vencimento
                </Text>
                <Text variant="caption-02" color="text">
                  {data?.dadosDebito?.dia}
                </Text>
              </div>
            </Display>
          </GridItem>
          <GridItem xs={1 / 3}>
            <Display>
              <div>
                <Text variant="caption-02" color="text-light">
                  Forma de Pagamento
                </Text>
                <Text variant="caption-02" color="text">
                  {dadosBancariosFormaPagamento(isPagamentoDebito)}
                </Text>
              </div>
            </Display>
          </GridItem>
        </Grid>
        <Divider />
      </RenderConditional>
    </S.WrapperTableContainer>
  );
};
