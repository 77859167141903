import * as Yup from 'yup';
import { isCnpj, isCpf } from 'main/utils/cpf_cnpj';
import * as UTILS from 'registroOcorrenciaASC/features/registrarOcorrencia/utils/RegistrarOcorrenciasUtils';
import * as CONSTS from 'registroOcorrenciaASC/features/registrarOcorrencia/constants/constants';
import * as ENUM_TYPES from 'registroOcorrenciaASC/types/enum';
import * as REGISTRO_OCORRENCIA_TYPES from 'registroOcorrenciaASC/features/registrarOcorrencia/types/RegistrarOcorrencias';
import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';

export const initialFormikStateDadosClienteFactory = (
  formDadosCliente: REGISTRO_OCORRENCIA_TYPES.FormDadosCliente,
) => ({
  inputRazaoSocialOuNome: formDadosCliente.razaoSocialOuNome,
  inputEmailCorporativoOuEmail: formDadosCliente.emailCorporativoOuEmail,
  inputCep: formDadosCliente.cep,
  inputEndereco: formDadosCliente.endereco,
  inputBairro: formDadosCliente.bairro,
  inputCidade: formDadosCliente.cidade,
  inputUf: formDadosCliente.uf,
  inputTelefonePrincipal: formDadosCliente.telefonePrincipal,
  inputTelefoneSecundario: formDadosCliente.telefoneSecundario,
  inputCpfRepresentante: '',
  inputNomeRepresentante: '',
  inputEmailRepresentante: '',
  inputTelefoneRepresentante: '',
});

export const initialFormikStateCamposDinamicosFactory = (
  listaCamposDinamicos: REGISTRO_OCORRENCIA_TYPES.IListaCamposDinamicos[],
): REGISTRO_OCORRENCIA_TYPES.IDynamicFields => {
  return UTILS.camposDinamicosOrdenados(listaCamposDinamicos).reduce(
    (acc, objCampoDinamico) => {
      acc[objCampoDinamico.nomeCampo] = {
        nomeCampo: objCampoDinamico.nomeCampo,
        obrigatorio: objCampoDinamico.obrigatorio,
        idCampoDinamico: objCampoDinamico.idCampoDinamico,
        valorCampo: '',
      };

      return acc;
    },
    {} as REGISTRO_OCORRENCIA_TYPES.IDynamicFields,
  );
};

export const formikDadosClienteSchemaFactory = (cpfCnpj: string) => {
  return Yup.object({
    inputRazaoSocialOuNome: Yup.string().required(
      CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO,
    ),
    inputEmailCorporativoOuEmail: Yup.string().email(
      CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.EMAIL_INVALIDO,
    ),
    inputTelefonePrincipal: Yup.string()
      .min(10, CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.TELEFONE_INVALIDO)
      .required(CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO),
    inputCpfRepresentante: isCnpj(cpfCnpj)
      ? Yup.string()
          .required(CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO)
          .test('validar CPF', 'CPF Inválido', value => isCpf(value))
      : Yup.string(),
    inputNomeRepresentante: isCnpj(cpfCnpj)
      ? Yup.string().required(
          CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO,
        )
      : Yup.string(),
    inputEmailRepresentante: isCnpj(cpfCnpj)
      ? Yup.string()
          .required(CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO)
          .email(CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.EMAIL_INVALIDO)
      : Yup.string().email(
          CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.EMAIL_INVALIDO,
        ),
    inputTelefoneRepresentante: isCnpj(cpfCnpj)
      ? Yup.string()
          .min(10, CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.TELEFONE_INVALIDO)
          .required(CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.CAMPO_OBRIGATORIO)
      : Yup.string().min(
          10,
          CONSTS.VALIDATION_DEFAULT_MESSAGE_SCHEMA.TELEFONE_INVALIDO,
        ),
  });
};

export const initialFormikStateDadosOcorrenciaFactory = (
  initialState: REGISTRO_OCORRENCIA_TYPES.IFormikValuesDadosOcorrencia,
  formFop: REGISTRO_OCORRENCIA_TYPES.FormFop,
) => {
  return {
    ...initialState,
    toggleModalComunicadoSinistroPrestamista:
      formFop.comunicadoSinistroPrestamista,
  };
};

export const payloadAberturaOcorrencia = ({
  formDadosOcorrencia,
  formDadosCliente,
  formFop,
  cpfCnpjCliente,
  user,
}: REGISTRO_OCORRENCIA_TYPES.PayloadAberturaOcorrenciaParams) => {
  return {
    unidadeNegocio: formDadosOcorrencia.produto,
    comentario: formDadosOcorrencia.mensagem,
    canal: ENUM_TYPES.PayloadFixo.CANAL,
    cliente: {
      endereco: {
        numero: '',
        cep: formDadosCliente.cep,
        cidade: formDadosCliente.cidade,
        complemento: formDadosCliente.endereco,
        bairro: formDadosCliente.bairro,
        estado: formDadosCliente.uf,
        rua: '',
      },
      numeroCelular: formDadosCliente.telefonePrincipal,
      numeroComercial: formDadosCliente.telefoneSecundario,
      email: formDadosCliente.emailCorporativoOuEmail,
      primeiroNome: UTILS.manterPrimeiroNome(
        formDadosCliente.razaoSocialOuNome,
      ),
      ultimoNome: UTILS.removerPrimeiroNome(formDadosCliente.razaoSocialOuNome),
      numero: '',
    },
    origemContato: ENUM_TYPES.PayloadFixo.ORIGEM_CONTATO,
    numeroContrato: formDadosOcorrencia.contratoSelecionado.contrato,
    numeroDocumento: cpfCnpjCliente.valor,
    apoio: {
      agencia: user.agenciaVinculada.toString(),
      numeroDocumento: user.cpf,
      email: user.email,
      primeiroNome: UTILS.manterPrimeiroNome(user.nomeUsuario),
      ultimoNome: UTILS.removerPrimeiroNome(user.nomeUsuario),
      telefone: '',
      matricula: user.nomeAcesso,
    },
    codigoAdmAssunto: formDadosOcorrencia.assunto,
    numeroContratoIndicativo: formDadosOcorrencia.contratoSelecionado.id,
    segundoNivel: ENUM_TYPES.PayloadFixo.ATENDIMENTO_SEGUNDO_NIVEL,
    canalOrigem:
      user.perfil === USER_PROFILES.SAC_CAIXA_TERCEIROS
        ? ENUM_TYPES.PayloadFixo.CANAL_ORIGEM_SAC
        : ENUM_TYPES.PayloadFixo.CANAL_ORIGEM,
    representanteLegal: {
      telefone: formDadosCliente.telefoneRepresentante,
      cpfCnpj: formDadosCliente.cpfRepresentante,
      nomeContato: formDadosCliente.nomeRepresentante,
      descricaoEmail: formDadosCliente.emailRepresentante,
    },
    camposArquivoRtf: {
      dadosArquivoRtf: formFop.camposComunicadoSinistoPrestamista,
    },
  };
};

export const payloadAtualizacaoCliente = ({
  formDadosCliente,
  cpfCnpjCliente,
}: Pick<
  REGISTRO_OCORRENCIA_TYPES.PayloadAberturaOcorrenciaParams,
  'formDadosCliente' | 'cpfCnpjCliente'
>) => {
  return {
    indClientePj: cpfCnpjCliente.cnpj,
    telefoneResidencial: '',
    telefoneEmpresa: '',
    telefone: formDadosCliente.telefoneSecundario,
    cpfCnpj: cpfCnpjCliente.valor,
    cep: formDadosCliente.cep,
    celular: formDadosCliente.telefonePrincipal,
    uf: formDadosCliente.uf,
    endereco: formDadosCliente.endereco,
    email: formDadosCliente.emailCorporativoOuEmail,
    nomTipo: formDadosCliente.tipoCliente,
    cliente: formDadosCliente.razaoSocialOuNome,
    cidade: formDadosCliente.cidade,
    bairro: formDadosCliente.bairro,
    primeiroNomecliente: UTILS.manterPrimeiroNome(
      formDadosCliente.razaoSocialOuNome,
    ),
    sobreNomecliente: UTILS.removerPrimeiroNome(
      formDadosCliente.razaoSocialOuNome,
    ),
  };
};

export const payloadCriacaoCliente = ({
  formDadosCliente,
  cpfCnpjCliente,
}: Pick<
  REGISTRO_OCORRENCIA_TYPES.PayloadAberturaOcorrenciaParams,
  'formDadosCliente' | 'cpfCnpjCliente'
>) => {
  return {
    sobreNomecliente: UTILS.removerPrimeiroNome(
      formDadosCliente.razaoSocialOuNome,
    ),
    primeiroNomecliente: UTILS.manterPrimeiroNome(
      formDadosCliente.razaoSocialOuNome,
    ),
    telefoneResidencial: '',
    telefone: formDadosCliente.telefoneSecundario,
    cpfCnpj: cpfCnpjCliente.valor,
    cep: formDadosCliente.cep,
    celular: formDadosCliente.telefonePrincipal,
    uf: formDadosCliente.uf,
    endereco: formDadosCliente.endereco,
    email: formDadosCliente.emailCorporativoOuEmail,
    complemento: '',
    cidade: formDadosCliente.cidade,
    bairro: formDadosCliente.bairro,
  };
};

export const payloadCriacaoCamposDinamicos = ({
  formDadosOcorrencia,
  protocoloCriado,
}: REGISTRO_OCORRENCIA_TYPES.PayloadCriacaoCamposDinamicosParams) => {
  return {
    idSolicitacao: protocoloCriado.codigoSolicitacao,
    campos: formDadosOcorrencia.camposDinamicosPreenchidos.map(
      ({ idCampoDinamico, valorCampo }) => ({
        idCampoDinamico,
        valorCampo,
      }),
    ),
  };
};
