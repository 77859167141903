import React, { useContext } from 'react';
import { Display } from '@cvp/design-system/react';
import DetalhesPreAnalise from 'main/features/prospeccao/components/DetalhesPreAnalise';
import { ProspeccaoContext } from 'main/features/prospeccao/contexts/ProspeccaoContext';
import { USER_PROFILES } from 'main/features/Auth/config/userProfiles';
import DetalhesDoParecer from '../components/DetalhesDoParecer';

const DetalhesDefinirParecerTimeOperacao: React.FC = () => {
  const { propeccao } = useContext(ProspeccaoContext);
  return (
    <Display type="display-block">
      <DetalhesPreAnalise>
        <DetalhesDoParecer
          title="Parecer Médico"
          resultadoAvaliacao={propeccao?.respostasFormulario?.avaliacoes?.find(
            x => x.PerfilAvaliador === USER_PROFILES.MEDICO,
          )}
        />
        <DetalhesDoParecer
          title="Parecer Técnico"
          resultadoAvaliacao={propeccao?.respostasFormulario?.avaliacoes?.find(
            x => x.PerfilAvaliador === USER_PROFILES.ANALISTA_PRODUTO,
          )}
        />
      </DetalhesPreAnalise>
    </Display>
  );
};
export default DetalhesDefinirParecerTimeOperacao;
