import { useContext, useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import { AppContext } from 'main/contexts/AppContext';
import * as DefinirFundosContribuicaoRegularApi from '../services/definirFundoContribuicaoRegular.api';
import { RequestDefinirFundoContribuicaoRegular } from '../types/ContribuicaoRegular.types';

const useDefinirFundoContribuicaoRegular = () => {
  const { toastError } = useToast();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<boolean>();
  const { cliente } = useContext(AppContext);
  const definirFundoContribuicaoRegular = async (
    numTransferencia: string | undefined,
    request: RequestDefinirFundoContribuicaoRegular | undefined,
  ) => {
    try {
      setLoading(true);
      const result =
        await DefinirFundosContribuicaoRegularApi.definirFundoContribuicaoRegular(
          cliente?.cpfCnpj,
          numTransferencia,
          request,
        );
      setData(result);
      return result;
    } catch (requestError: any) {
      toastError(requestError.message);
    } finally {
      setLoading(false);
    }
    return null;
  };
  return {
    isLoading,
    data,
    definirFundoContribuicaoRegular,
  };
};

export default useDefinirFundoContribuicaoRegular;
