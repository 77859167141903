import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { useAuth } from 'main/features/Auth/hooks';
import { getSessionItem } from 'main/utils/storage';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import {
  obterChaveArmazenamentoCliente,
  obterChaveArmazenamentoTokenCliente,
} from 'config/storage_keys';
import { PECOS } from 'previdencia/config/endpoints';
import { IMatrizAcessoCliente } from 'previdencia/types/MatrizDeAcesso';
import {
  IClientePrevidencia,
  IDadosClientePrev,
} from 'previdencia/types/IClientePrevidencia';
import { useTransferenciaInternaContext } from 'previdencia/features/TransferenciaInternaEmissaoConjugado/contexts/TransferenciaInternaContext';
import * as REQUEST_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblRequest';
import * as RESPONSE_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblResponse';
import * as PECO_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblUsePeco';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';

const useAquisicao = ({
  numeroDDD,
  numeroTelefoneSemDDD,
  emailDefault,
}: PECO_TYPES.UseAquisicaoParams): PECO_TYPES.UseAquisicao => {
  const tokenCliente = obterChaveArmazenamentoTokenCliente();
  const dadosCliente = obterChaveArmazenamentoCliente();
  const resultTokenCliente = getSessionItem<IMatrizAcessoCliente>(tokenCliente);
  const resultClienteData =
    getSessionItem<IClientePrevidencia<IDadosClientePrev>>(dadosCliente);

  const { cliente } = useContext(AppContext);

  const { user } = useAuth();

  const {
    coberturasCuidadoGarantido,
    dadosOfertasVgblConjugado,
    dadosOrigem,
    contribuicaoRendaMensal,
    contaCadastradaSelecionada,
    numeroSolicitacao: numeroSolicitacaoContext,
  } = useTransferenciaInternaContext();

  const tabelaFundosSelecionados =
    dadosOfertasVgblConjugado?.produtos?.[0]?.fundos?.filter(
      fundoSelecionado => fundoSelecionado.selecionado,
    );

  const codigoProdutoTAFPayload =
    dadosOfertasVgblConjugado?.produtos?.[0]?.codigoProduto;

  const listaFundosTAFPayload = tabelaFundosSelecionados?.map(fundo => ({
    codigoFundo: fundo.codigoFundo,
    codigoReserva: fundo.codigoReserva,
    percentualDistribuicao: Number(fundo.percentualDistribuicao),
    indicadorContribuicaoFundo: !!fundo.indicadorContribuicaoFundo,
  }));

  const {
    response: responseTransferirProdutoVgbl,
    loading: loadingTransferirProdutoVgbl,
    fetchData: transferirProdutoVgbl,
  } = usePeco<
    REQUEST_TYPES.ITransferirProdutoVgblPayload,
    RESPONSE_TYPES.ITransferirProdutoVgblResponse
  >({
    api: { operationPath: PECOS.TransferirProdutoVgbl },
    payload: {
      certificado: cliente?.numCertificado,
      codigoEmpresa: ENUM_TYPES.DefaultValue.CODIGO_EMPRESA,
      codigoAgenteVenda: user.nomeAcesso,
      codigoEnte: ENUM_TYPES.DefaultValue.CODIGO_BANCO,
      codigoOficina: contaCadastradaSelecionada.agencia,
      codigoOrigem: ENUM_TYPES.DefaultValue.CODIGO_ORIGEM,
      fundos: tryGetValueOrDefault([listaFundosTAFPayload], []),
      prazoPensao: tryGetValueOrDefault(
        [coberturasCuidadoGarantido.pensao?.prazo],
        0,
      ),
      produto: {
        codigoProduto: tryGetValueOrDefault([codigoProdutoTAFPayload], ''),
      },
      valorContribuicaoPeculio: tryGetValueOrDefault(
        [coberturasCuidadoGarantido.peculio?.valorContribuicao],
        0,
      ),
      valorContribuicaoPensao: tryGetValueOrDefault(
        [coberturasCuidadoGarantido.pensao?.valorContribuicao],
        0,
      ),
      valorContribuicaoRenda: Number(contribuicaoRendaMensal),
    },
  });

  const numeroSolicitacao =
    responseTransferirProdutoVgbl?.entidade?.dados.numeroSolicitacao;

  const {
    response: responseSolicitarAssinaturaTransfVgbl,
    loading: loadingSolicitarAssinaturaTransfVgbl,
    fetchData: solicitarAssinaturaTransfVgbl,
  } = usePeco<
    REQUEST_TYPES.ISolicitarAssinaturaTransfVgblPayload,
    RESPONSE_TYPES.ISolicitarAssinaturaTransfVgblResponse
  >({
    api: { operationPath: PECOS.SolicitarAssinaturaTransfVgbl },
    payload: {
      codigoCertificado: cliente?.numCertificado,
      codigoEmpresa: ENUM_TYPES.DefaultValue.CODIGO_EMPRESA,
      numeroDocumento: tryGetValueOrDefault(
        [numeroSolicitacaoContext, numeroSolicitacao?.toString()],
        '',
      ),
      nome: tryGetValueOrDefault(
        [dadosOrigem?.nomeClienteVgbl],
        resultTokenCliente.matrizAcessoCliente.nome,
      ),
      codigoTelefone: tryGetValueOrDefault([numeroDDD], ''),
      telefone: tryGetValueOrDefault([numeroTelefoneSemDDD], ''),
      email: tryGetValueOrDefault([emailDefault], ''),
      tipoEnvio: ENUM_TYPES.DefaultValue.TIPO_ENVIO,
      cpf: tryGetValueOrDefault(
        [dadosOrigem?.cpfClienteVgbl],
        resultClienteData.cpfCnpj,
      ),
      tipoAssinatura: ENUM_TYPES.DefaultValue.TIPO_ASSINATURA,
      matriculaEconomiario: user.nomeAcesso,
      nomeEconomiario: user.nomeUsuario,
    },
  });

  const urlAssinatura =
    responseSolicitarAssinaturaTransfVgbl?.entidade?.urlAssinatura;

  return {
    responseTransferirProdutoVgbl,
    loadingTransferirProdutoVgbl,
    transferirProdutoVgbl,
    loadingSolicitarAssinaturaTransfVgbl,
    solicitarAssinaturaTransfVgbl,
    urlAssinatura,
  };
};

export default useAquisicao;
