import { useHistory } from 'react-router';
import {
  Grid,
  Accordion,
  Card,
  Text,
  Button,
  Display,
} from '@cvp/design-system/react';
import { formatarData } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { useRegistrarOcorrenciaContext } from 'registroOcorrenciaASC/features/registrarOcorrencia/contexts/RegistrarOcorrenciaContext';
import * as OCORRENCIA_STYLES from 'registroOcorrenciaASC/styles/styles';

const CardDadosProtocolo = (): React.ReactElement => {
  const history = useHistory();

  const { aberturaOcorrencia, limparOcorrenciaAberta } =
    useRegistrarOcorrenciaContext();

  return (
    <OCORRENCIA_STYLES.CardContainer>
      <Card.Content>
        <Accordion open data-testid="accordionDadosInterlocutor">
          <Accordion.Item title="Detalhes da Ocorrência">
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-08" margin>
                  Protocolo registrado com sucesso
                </Text>
                <Text
                  variant="body02-sm"
                  data-testid="dadosAberturaOcorrenciaProtocolo"
                >
                  <strong>Protocolo:</strong>{' '}
                  {tryGetValueOrDefault(
                    [aberturaOcorrencia.numeroProtocolo],
                    '-',
                  )}
                </Text>
                <Text
                  variant="body02-sm"
                  data-testid="dadosAberturaOcorrenciaDataAtendimento"
                >
                  <strong>Data prevista para atendimento:</strong>{' '}
                  {tryGetValueOrDefault(
                    [
                      formatarData(
                        aberturaOcorrencia.dataVencimento?.toString(),
                      ),
                    ],
                    '-',
                  )}
                </Text>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Display>
                  <Button
                    variant="primary"
                    onClick={limparOcorrenciaAberta}
                    data-testid="botaoNovaOcorrencia"
                  >
                    Nova Ocorrência
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() =>
                      history.push(`/registro-ocorrencias/consultar-ocorrencia`)
                    }
                    data-testid="botaoConsultarOcorrencias"
                  >
                    Consultar Ocorrências
                  </Button>
                </Display>
              </Grid.Item>
            </Grid>
          </Accordion.Item>
        </Accordion>
      </Card.Content>
    </OCORRENCIA_STYLES.CardContainer>
  );
};

export default CardDadosProtocolo;
