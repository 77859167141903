import styled from 'styled-components/macro';

export const TabContainer = styled.div`
  pointer-events: auto;

  &.visible {
    pointer-events: none;
  }
  &.visible:after {
    transition: 0.3s 0s;
    opacity: 0.5;
  }
  &:after {
    transition: 0.3s 40ms opacity;
    content: '';
    position: absolute;
    background: ${props => props.theme.color.neutral['01']};
    opacity: 0;
    left: 0;
    right: 0;
    bottom: calc(100% + 1px);
    pointer-events: none;
    height: calc(100vh - 100%);
  }
`;

export const TabButton = styled.button`
  background: transparent;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  height: 100%;
  width: 100%;
  border: 0;
  padding: 0 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.color.support['01']};
  font-size: 10px;

  .icon {
    font-size: 23px;
    line-height: 0;
  }
`;

export const TabContent = styled.div`
  position: absolute;
  bottom: 100%;
  height: auto;
  cursor: default;
  transition: 0.3s 50ms transform;
  width: 70%;
  z-index: 2;

  &.slide-from-left {
    right: calc(100% + 1px);
  }
  &.slide-from-right {
    left: calc(100% + 1px);
  }
  &.show {
    transition: 0.3s 0s transform;
    pointer-events: auto;
  }
  &.show.slide-from-left {
    transform: translateX(100%);
  }
  &.show.slide-from-right {
    transform: translateX(-100%);
  }
`;
