import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Warnner } from 'assets/img/icon_info_m.svg';
import RenderConditional from 'main/components/RenderConditional';
import { Card } from '@cvp/design-system/react';
import { checkIfAllItemsAreTrue } from 'main/utils/conditional';
import { CoberturaContratada } from '../../types/CoberturasContratadas.types';
import * as S from './styles';

type CardStatusCanceladoProps = {
  dados: CoberturaContratada;
};

const CardStatusCancelado: React.FC<CardStatusCanceladoProps> = ({ dados }) => {
  return (
    <Card.Content>
      <S.ContainerIcon>
        <Warnner />
      </S.ContainerIcon>
      <S.TextDescricaoItemContribuicao variant="body01-lg">
        <strong>{dados.nome}</strong>
      </S.TextDescricaoItemContribuicao>
      <S.TextDescricaoItemContribuicao variant="body01-md" margin>
        Esse cuidado extra foi cancelado.
      </S.TextDescricaoItemContribuicao>
      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          dados.nome !== 'PENSAO CONJUGE',
          dados.nome !== 'INVALIDEZ',
        ])}
      >
        <S.TextDescricaoItemContribuicao variant="body01-sm" margin>
          Para contratar novamente, selecione a opção Incluir benefício de
          Proteção no menu ou{' '}
          <Link to="/cliente/produtos/previdencia/incluir-beneficio-de-protecao">
            clique aqui.
          </Link>
        </S.TextDescricaoItemContribuicao>
      </RenderConditional>
    </Card.Content>
  );
};

export default CardStatusCancelado;
