import { Accordion, Grid } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import React from 'react';
import { colunasLinhaTempoSinistroFactory } from 'sinistro/features/statusSinistro/factories/colunasLinhaTempoSinistroFactory';
import {
  AccordionItemSinistro,
  TableSinistro,
} from 'sinistro/features/statusSinistro/pages/styles';
import { LinhaTempoSinistroProps } from 'sinistro/features/statusSinistro/types/StatusSinistroComponentProps';

const LinhaTempoSinistro: React.FC<LinhaTempoSinistroProps> = ({
  timeline,
  certificadoSelecionado,
}) => {
  return (
    <RenderConditional condition={!!certificadoSelecionado}>
      <Grid>
        <Grid.Item xs={1}>
          <Accordion data-testid="acordionLinhaTempoSinistroPrevidencia" open>
            <AccordionItemSinistro title="Linha do Tempo">
              <TableSinistro
                columns={colunasLinhaTempoSinistroFactory()}
                noHeader
                striped
                responsive
                pagination
                paginationPerPage={10}
                paginationComponentOptions={{
                  rowsPerPageText: 'Items por página',
                  rangeSeparatorText: 'de',
                }}
                title="Histórico da Situação"
                data={timeline}
                noDataComponent="Não há dados para serem exibidos."
              />
            </AccordionItemSinistro>
          </Accordion>
        </Grid.Item>
      </Grid>
    </RenderConditional>
  );
};

export default LinhaTempoSinistro;
