import styled from 'styled-components/macro';
import { Display } from '@cvp/design-system/react';

export const ContainerConsultarCliente = styled(Display)`
  & div {
    align-self: flex-end;
  }

  & div:first-child {
    flex: 1;
  }

  & div input {
    height: 45px;
  }

  & button {
    margin: 0;
    height: 45px;
  }
`;
