import { useContext } from 'react';

import { usePeco } from 'main/hooks/usePeco';
import { AppContext } from 'main/contexts/AppContext';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { PECOS } from 'previdencia/config/endpoints';
import {
  IListarFundosParaResgatePayload,
  IListarFundosParaResgateResponse,
} from 'previdencia/types/ConsultaListaFundosParaResgate';

export const usePecoConsultarListaFundosParaResgate = () => {
  const { cliente } = useContext(AppContext);

  const codigoCertificado = tryGetValueOrDefault([cliente.numCertificado], '');

  const {
    response: dadosListarFundosParaResgate,
    loading: loadingDadosListarFundosParaResgate,
    fetchData: listarFundosParaResgate,
  } = usePeco<
    IListarFundosParaResgatePayload,
    IListarFundosParaResgateResponse
  >({
    api: { operationPath: PECOS.ListarFundosParaResgate },
    payload: { codigoCertificado },
  });

  return {
    dadosListarFundosParaResgate: dadosListarFundosParaResgate?.entidade,
    loadingDadosListarFundosParaResgate,
    listarFundosParaResgate,
  };
};
