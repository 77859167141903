import { FormikProps } from 'formik';

import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { usePecoCalcularResgate } from 'previdencia/hooks/usePecoCalcularResgate';
import { IListarFundosParaResgateResponse } from 'previdencia/types/ConsultaListaFundosParaResgate';
import {
  usePecoConsultarDetalheCalculo,
  usePecoConsultarResumoAliquota,
} from 'previdencia/features/SolicitacaoResgate/hooks/usePecoSolicitacaoResgate';
import * as FACTORY from 'previdencia/features/SolicitacaoResgate/factories/solicitacaoResgateFactory';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const useHandleConfirmarSimulacao = (
  formik: FormikProps<SOLICITACAO_RESGATE_TYPES.IFormikValuesSimulacaoResgate>,
) => {
  const { navigateTo } = usePrevNavigation();

  const { featureData, setFeatureData } =
    usePrevidenciaContext<SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData>();

  const { aliquota } = tryGetValueOrDefault(
    [featureData?.dadosListarFundosParaResgate],
    {} as IListarFundosParaResgateResponse,
  );

  const { loadingDadosCalcularResgate, calcularResgate } =
    usePecoCalcularResgate();

  const { loadingDadosConsultarResumoAliquota, consultarResumoAliquota } =
    usePecoConsultarResumoAliquota();

  const { loadingDadosConsultarDetalheCalculo, consultarDetalheCalculo } =
    usePecoConsultarDetalheCalculo();

  const loadingConfirmacaoSimulacao = checkIfSomeItemsAreTrue([
    loadingDadosCalcularResgate,
    loadingDadosConsultarResumoAliquota,
    loadingDadosConsultarDetalheCalculo,
  ]);

  const confirmarSimulacao = async (): Promise<void> => {
    const defaultFactoryParams: SOLICITACAO_RESGATE_TYPES.IDefaultFactoryConfirmarSimulacaoParams =
      {
        formik,
        calcularResgate,
        consultarResumoAliquota,
        consultarDetalheCalculo,
      };

    const dadosEtapaSelecaoAliquota =
      await FACTORY.criarContextoSelecaoAliquotaFactory(
        aliquota,
        defaultFactoryParams,
      );

    const isCalculoAliquota = checkIfSomeItemsAreTrue([
      !!dadosEtapaSelecaoAliquota.calculoAliquotaProgressiva,
      !!dadosEtapaSelecaoAliquota.calculoAliquotaRegressiva,
    ]);

    if (isCalculoAliquota) {
      setFeatureData({
        ...featureData,
        dadosEtapaSelecaoAliquota,
      });

      navigateTo('confirmar-valores-simulacao');
    }
  };

  return { confirmarSimulacao, loadingConfirmacaoSimulacao };
};

export default useHandleConfirmarSimulacao;
