import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  Display,
  Text,
  Button,
  Checkbox,
} from '@cvp/design-system/react';
import Icon from 'main/components/Icon';
import RenderConditional from 'main/components/RenderConditional';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import { useTransferenciaInternaContext } from 'previdencia/features/TransferenciaInternaEmissaoConjugado/contexts/TransferenciaInternaContext';
import CardCoberturaIdeal from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/CardCoberturaIdeal';
import ModalInformeCuidadoGarantido from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/Modal/ModalInformeCuidadoGarantido';
import * as RESPONSE_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgblResponse';
import * as FACTORY from 'previdencia/features/TransferenciaInternaEmissaoConjugado/factories/initialStateFactory';
import * as VALIDATION_UTILS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/utils/TransferenciaVgblValidacoes';
import * as VGBL_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/TransferenciaVgbl';
import * as ENUM_TYPES from 'previdencia/features/TransferenciaInternaEmissaoConjugado/types/enum';
import * as CONSTS from 'previdencia/features/TransferenciaInternaEmissaoConjugado/constants/constants';
import * as S from 'previdencia/features/TransferenciaInternaEmissaoConjugado/components/CuidadoGarantido/styles';

const CuidadoGarantido: React.FC = () => {
  const {
    selecionarFormaPagamento,
    escolherFundoInvestimentoDestino,
    salvarCuidadoGarantido,
    dadosOfertasVgblConjugado,
    dadosOrigem,
  } = useTransferenciaInternaContext();

  const [coberturasCuidadoGarantido, setCoberturasCuidadoGarantido] =
    useState<VGBL_TYPES.CoberturasCuidadoGarantido>(
      FACTORY.initialStateCoberturasCuidadoGarantido,
    );

  const [checkboxCoberturaIdeal, setCheckboxCoberturaIdeal] =
    useState<VGBL_TYPES.CheckboxCoberturaIdeal>(FACTORY.initialStateCheckbox);

  const [
    openModalInformeCuidadoGarantido,
    setOpenModalInformeCuidadoGarantido,
  ] = useState<boolean>(true);

  const desabilitaInclusaoPensao =
    dadosOrigem?.incluirPensao ===
    ENUM_TYPES.DefaultValue.NAO_PERMITIR_INCLUSAO_NOVA_COBERTURA;

  const desabilitaInclusaoPeculio =
    dadosOrigem?.incluirPeculio ===
    ENUM_TYPES.DefaultValue.NAO_PERMITIR_INCLUSAO_NOVA_COBERTURA;

  const handleModalInformeCuidadoGarantido = (): void => {
    setOpenModalInformeCuidadoGarantido(!openModalInformeCuidadoGarantido);
  };

  const validacaoCoberturas =
    VALIDATION_UTILS.validarCoberturasCuidadoGarantido({
      coberturasCuidadoGarantido,
      checkboxCoberturaIdeal,
      dadosOfertasVgblConjugado,
      dadosOrigem,
    });

  const exibicaoCard = VALIDATION_UTILS.validarExibicaoCardsCobertura({
    dadosOrigem,
    checkboxCoberturaIdeal,
  });

  const salvarCuidadoGarantidoNoContexto = (): void => {
    const pensaoNaoChecado = !checkIfSomeItemsAreTrue([
      exibicaoCard.permiteInclusaoPensao,
      checkboxCoberturaIdeal.pensao,
    ]);

    const peculioNaoChecado = !checkIfSomeItemsAreTrue([
      exibicaoCard.permiteInclusaoPeculio,
      checkboxCoberturaIdeal.peculio,
    ]);

    if (validacaoCoberturas) {
      salvarCuidadoGarantido(coberturasCuidadoGarantido);

      if (pensaoNaoChecado) {
        salvarCuidadoGarantido({
          ...coberturasCuidadoGarantido,
          pensao: FACTORY.initialStateCoberturasCuidadoGarantido.pensao,
        });
      }

      if (peculioNaoChecado) {
        salvarCuidadoGarantido({
          ...coberturasCuidadoGarantido,
          peculio: FACTORY.initialStateCoberturasCuidadoGarantido.peculio,
        });
      }
    }
  };

  const cuidadosExtrasExistentes = (
    tipoCobertura: string,
  ): RESPONSE_TYPES.DadosOrigemResponseCuidadosExtras | undefined => {
    return dadosOrigem?.cuidadosExtras?.find(cuidado =>
      cuidado.descricaoRisco.toLowerCase().includes(tipoCobertura),
    );
  };

  useEffect(salvarCuidadoGarantidoNoContexto, [coberturasCuidadoGarantido]);

  return (
    <>
      <Display type="display-block">
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-05" color="primary" margin>
                  {CONSTS.TEXTOS_COBERTURAS.TITULO}
                </Text>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Text variant="body02-md">
                  {CONSTS.TEXTOS_COBERTURAS.SUBTITULO}
                </Text>
              </Grid.Item>
              <Grid.Item xs={1}>
                <S.ContainerSelecionarCuidado>
                  <S.ContainerSelecionar>
                    <Checkbox
                      onClick={(): void =>
                        setCheckboxCoberturaIdeal(prev => ({
                          ...prev,
                          pensao: !prev.pensao,
                        }))
                      }
                      disabled={exibicaoCard.permiteInclusaoPensao}
                      checked={checkIfSomeItemsAreTrue([
                        exibicaoCard.permiteInclusaoPensao,
                        checkboxCoberturaIdeal.pensao,
                      ])}
                    />
                    <Text variant="body02-md" color="primary">
                      {CONSTS.TEXTOS_COBERTURAS.TITULO_COBERTURA_PENSAO}
                    </Text>
                  </S.ContainerSelecionar>
                  <Text variant="body02-md" margin>
                    {CONSTS.TEXTOS_COBERTURAS.DESCRICAO_COBERTURA_PENSAO}
                  </Text>
                </S.ContainerSelecionarCuidado>
              </Grid.Item>
              <RenderConditional
                condition={exibicaoCard.exibirCardIndividualPensao}
              >
                <Grid.Item xs={1}>
                  <CardCoberturaIdeal
                    prazo
                    subTitulo={
                      ENUM_TYPES.DefaultValue.SUBTITULO_COBERTURA_PENSAO
                    }
                    tipoCobertura={
                      ENUM_TYPES.DefaultValue.TIPO_COBERTURA_PENSAO
                    }
                    valorMinimoContribuicao={
                      dadosOfertasVgblConjugado?.valorMinimoPensao
                    }
                    valorMaximoContribuicao={
                      dadosOfertasVgblConjugado?.valorMaximoPensao
                    }
                    fatorCobertura={dadosOfertasVgblConjugado?.fatorPensao}
                    setCoberturasCuidadoGarantido={
                      setCoberturasCuidadoGarantido
                    }
                    desabilitaInclusaoCobertura={desabilitaInclusaoPensao}
                    cuidadosExtrasExistentes={cuidadosExtrasExistentes}
                  />
                </Grid.Item>
              </RenderConditional>
              <Grid.Item xs={1}>
                <S.ContainerSelecionarCuidado>
                  <S.ContainerSelecionar>
                    <Checkbox
                      onClick={(): void =>
                        setCheckboxCoberturaIdeal(prev => ({
                          ...prev,
                          peculio: !prev.peculio,
                        }))
                      }
                      disabled={exibicaoCard.permiteInclusaoPeculio}
                      checked={checkIfSomeItemsAreTrue([
                        exibicaoCard.permiteInclusaoPeculio,
                        checkboxCoberturaIdeal.peculio,
                      ])}
                    />
                    <Text variant="body02-md" color="primary">
                      {CONSTS.TEXTOS_COBERTURAS.TITULO_COBERTURA_PECULIO}
                    </Text>
                  </S.ContainerSelecionar>
                  <Text variant="body02-md" margin>
                    {CONSTS.TEXTOS_COBERTURAS.DESCRICAO_COBERTURA_PECULIO}
                  </Text>
                </S.ContainerSelecionarCuidado>
              </Grid.Item>
              <RenderConditional
                condition={exibicaoCard.exibirCardIndividualPeculio}
              >
                <Grid.Item xs={1}>
                  <CardCoberturaIdeal
                    subTitulo={
                      ENUM_TYPES.DefaultValue.SUBTITULO_COBERTURA_PECULIO
                    }
                    tipoCobertura={
                      ENUM_TYPES.DefaultValue.TIPO_COBERTURA_PECULIO
                    }
                    valorMinimoContribuicao={
                      dadosOfertasVgblConjugado?.valorMinimoPeculio
                    }
                    valorMaximoContribuicao={
                      dadosOfertasVgblConjugado?.valorMaximoPeculio
                    }
                    fatorCobertura={dadosOfertasVgblConjugado?.fatorPeculio}
                    setCoberturasCuidadoGarantido={
                      setCoberturasCuidadoGarantido
                    }
                    desabilitaInclusaoCobertura={desabilitaInclusaoPeculio}
                    cuidadosExtrasExistentes={cuidadosExtrasExistentes}
                  />
                </Grid.Item>
              </RenderConditional>
              <RenderConditional
                condition={exibicaoCard.exibirTodosCardsCoberturas}
              >
                <>
                  <Grid.Item xs={1} md={5 / 10}>
                    <CardCoberturaIdeal
                      subTitulo={
                        ENUM_TYPES.DefaultValue.SUBTITULO_COBERTURA_PENSAO
                      }
                      tipoCobertura={
                        ENUM_TYPES.DefaultValue.TIPO_COBERTURA_PENSAO
                      }
                      prazo
                      combinacao
                      valorMinimoContribuicao={
                        dadosOfertasVgblConjugado?.valorMinimoPensao
                      }
                      valorMaximoContribuicao={
                        dadosOfertasVgblConjugado?.valorMaximoPensao
                      }
                      fatorCobertura={dadosOfertasVgblConjugado?.fatorPensao}
                      setCoberturasCuidadoGarantido={
                        setCoberturasCuidadoGarantido
                      }
                      desabilitaInclusaoCobertura={desabilitaInclusaoPensao}
                      cuidadosExtrasExistentes={cuidadosExtrasExistentes}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1} md={1 / 10}>
                    <S.ContainerCombinacaoCobertura>
                      <S.CombinacaoCobertura>
                        <Icon name="plus" />
                      </S.CombinacaoCobertura>
                    </S.ContainerCombinacaoCobertura>
                  </Grid.Item>
                  <Grid.Item xs={1} md={4 / 10}>
                    <CardCoberturaIdeal
                      subTitulo={
                        ENUM_TYPES.DefaultValue.SUBTITULO_COBERTURA_PECULIO
                      }
                      tipoCobertura={
                        ENUM_TYPES.DefaultValue.TIPO_COBERTURA_PECULIO
                      }
                      combinacao
                      valorMinimoContribuicao={
                        dadosOfertasVgblConjugado?.valorMinimoPeculio
                      }
                      valorMaximoContribuicao={
                        dadosOfertasVgblConjugado?.valorMaximoPeculio
                      }
                      fatorCobertura={dadosOfertasVgblConjugado?.fatorPeculio}
                      setCoberturasCuidadoGarantido={
                        setCoberturasCuidadoGarantido
                      }
                      desabilitaInclusaoCobertura={desabilitaInclusaoPeculio}
                      cuidadosExtrasExistentes={cuidadosExtrasExistentes}
                    />
                  </Grid.Item>
                </>
              </RenderConditional>
              <Grid.Item xs={1}>
                <Text variant="caption-01" color="text-light" margin>
                  {CONSTS.TEXTOS_COBERTURAS.AVISO_COBERTURA_OBRIGATORIA}
                </Text>
                <Display>
                  <Button
                    variant="outlined"
                    onClick={escolherFundoInvestimentoDestino}
                  >
                    Voltar
                  </Button>
                  <Button
                    onClick={selecionarFormaPagamento}
                    disabled={!validacaoCoberturas}
                  >
                    Continuar
                  </Button>
                </Display>
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </Display>

      <ModalInformeCuidadoGarantido
        toggleModal={openModalInformeCuidadoGarantido}
        handleModal={handleModalInformeCuidadoGarantido}
      />
    </>
  );
};

export default CuidadoGarantido;
