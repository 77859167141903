import { useEffect, useState } from 'react';
import useFieldLink from 'main/hooks/useFieldLink';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { useObterDadosClientePep } from 'previdencia/hooks/useObterDadosClientePep';
import ComprovanteAporte from 'previdencia/features/Aporte/components/ComprovanteAporte';
import DadosBancarios from 'previdencia/features/Aporte/components/DadosBancarios';
import DeclaracaoOrigemRecursos from 'previdencia/features/Aporte/components/DeclaracaoOrigemRecursos';
import FormaPagamentoAporte from 'previdencia/features/Aporte/components/FormaPagamentoAporte';
import FundosDistribuicaoAporte from 'previdencia/features/Aporte/components/FundosDistribuicaoAporte';
import GerarAporte from 'previdencia/features/Aporte/components/GerarAporte';
import * as CONSTS from 'previdencia/features/Aporte/constants';
import * as RESPONSE_TYPES from 'previdencia/features/Aporte/types/AporteResponse';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';

const EtapasAporte = () => {
  const { setFeatureData, featureData, clearFeatureData } =
    usePrevidenciaContext<APORTE_TYPES.IAporteContextData>();

  const { dataPep, buscaDadosClientePep } = useObterDadosClientePep();

  const [etapaAtual, setEtapaAtual] = useState(0);

  const [aporteFormaPagamento] =
    useFieldLink<APORTE_TYPES.IAporteFormaPagamento>(
      {} as APORTE_TYPES.IAporteFormaPagamento,
    );

  const [aporteOrigemRecursos] =
    useFieldLink<APORTE_TYPES.IAporteOrigemRecursos>(
      {} as APORTE_TYPES.IAporteOrigemRecursos,
    );

  const [aporteDadosBancarios] =
    useFieldLink<RESPONSE_TYPES.IDadosBancariosAporte>(
      {} as RESPONSE_TYPES.IDadosBancariosAporte,
    );

  const [fundosDistribuicao] = useFieldLink<
    RESPONSE_TYPES.IDadosFundosAporte[]
  >([]);

  useEffect(() => {
    buscaDadosClientePep();
  }, []);

  const proximaEtapa = (): void => {
    setEtapaAtual(etapaAtual + 1);
  };

  const etapaAnterior = (): void => {
    setEtapaAtual(etapaAtual - 1);
  };

  const iniciarProcessoAporte = (): void => {
    setEtapaAtual(0);
  };

  useEffect(() => {
    setFeatureData({
      ...featureData,
      etapaAtual,
      pepPositivo: dataPep?.pep,
      proximaEtapa,
      etapaAnterior,
      iniciarProcessoAporte,
    });

    return clearFeatureData;
  }, [etapaAtual, dataPep?.pep]);

  const telaEtapaAporte = [
    <FormaPagamentoAporte aporteFormaPagamento={aporteFormaPagamento} />,
    <DeclaracaoOrigemRecursos aporteOrigemRecursos={aporteOrigemRecursos} />,
    <DadosBancarios aporteDadosBancarios={aporteDadosBancarios} />,
    <FundosDistribuicaoAporte
      fundosDistribuicao={fundosDistribuicao}
      aporteFormaPagamento={aporteFormaPagamento}
      aporteDadosBancarios={aporteDadosBancarios}
    />,
    <GerarAporte
      fundosDistribuicao={fundosDistribuicao}
      aporteFormaPagamento={aporteFormaPagamento}
      aporteDadosBancarios={aporteDadosBancarios}
      aporteOrigemRecursos={aporteOrigemRecursos}
    />,
    <ComprovanteAporte
      aporteFormaPagamento={aporteFormaPagamento}
      aporteOrigemRecursos={aporteOrigemRecursos}
      fundosDistribuicao={fundosDistribuicao}
      aporteDadosBancarios={aporteDadosBancarios}
    />,
  ];

  const ehOrigemPep = checkIfSomeItemsAreTrue([
    typeof dataPep?.dadosPep === 'undefined',
    dataPep?.dadosPep.length === 0,
  ]);

  const indicarOrigemRecursosPep = checkIfSomeItemsAreTrue([
    Number(aporteFormaPagamento.get().value.valorContribuicao) / 100 >=
      CONSTS.VALOR_MINIMO_DECLARACAO_ORIGEM_APORTE,
    !ehOrigemPep,
  ]);

  if (indicarOrigemRecursosPep) {
    if (
      aporteFormaPagamento.get().value.formaPagamento ===
      APORTE_TYPES.EnumFormaPagamento.BOLETO
    )
      telaEtapaAporte.splice(2, 1);
  } else {
    if (
      aporteFormaPagamento.get().value.formaPagamento ===
      APORTE_TYPES.EnumFormaPagamento.DEBITO
    )
      telaEtapaAporte.splice(1, 1);
    if (
      aporteFormaPagamento.get().value.formaPagamento ===
      APORTE_TYPES.EnumFormaPagamento.BOLETO
    )
      telaEtapaAporte.splice(1, 2);
  }

  return <>{telaEtapaAporte[etapaAtual]}</>;
};

export default EtapasAporte;
