import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { useToast } from 'main/hooks/useToast';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { useContext, useEffect, useMemo, useState } from 'react';
import { PECOS_VIDA } from 'seguros/config/endpoints';
import { useCertificadoSeguroResumo } from 'seguros/hooks/useCertificadoSeguroResumo';
import { obterArquivoQuitacaoAnualDebitos } from 'seguros/services/quitacaoAnualDebitos.api';
import {
  IEmitirDeclaracaoQuitacaoAnualVidaPayload,
  IEmitirDeclaracaoQuitacaoAnualVidaResponse,
  IQuitacaoAnualDebitosDados,
  IUseQuitacaoAnualDebitos,
} from 'seguros/types/EmitirDeclaracaoQuitacaoAnualTypes';
import {
  anoValido,
  quitacaoAnualDate,
} from 'seguros/utils/quitacaoAnualDebitos';

export const useQuitacaoAnualDebitos = (): IUseQuitacaoAnualDebitos => {
  const { toastError } = useToast();
  const [anoConsulta, setAnoConsulta] = useState<string | undefined>(undefined);
  const [loadingFile, setLoadingFile] = useState(false);
  const listaAnos = useMemo(quitacaoAnualDate, []);
  const { clienteVida } = useContext(AppContext);

  const { certificadoSeguros: certificadoResult } = useCertificadoSeguroResumo({
    numeroContrato: tryGetValueOrDefault([clienteVida?.numCertificado], '0'),
    codigoProduto: tryGetValueOrDefault([clienteVida?.codigoProduto], '0'),
  });

  const declaracaoResult = usePeco<
    IEmitirDeclaracaoQuitacaoAnualVidaPayload,
    IEmitirDeclaracaoQuitacaoAnualVidaResponse
  >({
    api: { operationPath: PECOS_VIDA.EmitirDeclaracaoQuitacaoAnualVida },
    handleResponse: { throwToastErrorBFF: false },
    payload: {
      numeroCertificadoBilhete: clienteVida?.numCertificado.toString(),
      tipoCertificado: clienteVida?.tipoCertificado?.toString(),
    },
  });
  useEffect(() => {
    if (anoValido(anoConsulta))
      declaracaoResult.fetchData({ ano: anoConsulta });
  }, [anoConsulta]);

  useEffect(() => {
    certificadoResult.fetchData();
  }, []);

  const entidade = declaracaoResult.response?.entidade as
    | IQuitacaoAnualDebitosDados
    | undefined;
  if (entidade) {
    entidade.numeroApolice =
      certificadoResult.response?.entidade?.dadosGeraisCertificado.numeroApolice;

    entidade.nomeProduto =
      certificadoResult.response?.entidade?.dadosGeraisCertificado.nomeProduto;
  }

  const loading = checkIfSomeItemsAreTrue([
    declaracaoResult.loading,
    certificadoResult.loading,
    loadingFile,
  ]);

  const exibirLoading = checkIfAllItemsAreTrue([
    loading,
    anoValido(anoConsulta),
  ]);

  const error = checkIfSomeItemsAreTrue([
    declaracaoResult.response?.sucessoGI === false,
    declaracaoResult.response?.sucessoBFF === false,
  ]);

  const exibirErro = checkIfAllItemsAreTrue([
    error,
    !loading,
    anoValido(anoConsulta),
  ]);

  const sucesso = checkIfAllItemsAreTrue([!!entidade, !exibirLoading]);

  const emitir = async () => {
    try {
      setLoadingFile(true);

      const result = await obterArquivoQuitacaoAnualDebitos({
        numeroCertificadoBilhete: clienteVida?.numCertificado.toString(),
        tipoCertificado: clienteVida?.tipoCertificado?.toString(),
        ano: anoConsulta,
      });

      const blob = new Blob([result], { type: 'application/pdf' });
      const downloadUrl = window.URL.createObjectURL(blob);
      window.open(downloadUrl, '_blank');
    } catch (requestError) {
      const erro = requestError as Error;
      toastError(erro?.message);
    } finally {
      setLoadingFile(false);
    }
  };

  return {
    anoConsulta,
    setAnoConsulta,
    listaAnos,
    entidade,
    exibirLoading,
    exibirErro,
    sucesso,
    emitir,
  };
};
