import { useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import { usePeco } from 'main/hooks/usePeco';

import { obterArquivoCorp } from 'extranet/services/obterEndpointArquivo';
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import useFieldLink from 'main/hooks/useFieldLink';

import {
  requiredFile,
  filesFormatsAllowed,
  maxFileSizeAllowed,
} from 'main/features/Validation/validations';
import { usePecoObterListaFopsAtivos } from 'main/features/Administracao/hooks/useObterListaFopsAtivos';
import { RequestListarFops } from 'main/features/Administracao/types/IFops';

export interface IArquivoDownload {
  sucesso: true;
  mensagem: string;
  stackTrace: string;
  dados: string;
}

export const useFormFops = () => {
  const { loading: loadingFops, response: responseFops } =
    usePecoObterListaFopsAtivos();

  const { loading: loadingDownload, fetchData } = usePeco<
    RequestListarFops,
    IArquivoDownload
  >({
    api: {
      operationPath: 'PECO_ObterArquivoCorp',
    },
    autoFetch: false,
  });
  const VALOR_FIXO_CONTRIBUICAO = 'valorFixoContribuicao';
  const VALOR_BASE_CONTRIBUICAO = 'valorBaseContribuicao';
  const OUTRA_FORMA_CONTRIBUICAO = 'outraFormaContribuicao';
  const { toastError, toastSuccess } = useToast();
  const [loadingDownloadArquivo, setLoadingDownloadArquivo] =
    useState<boolean>(false);

  const obterArquivoFOP = async (
    codigoIdentificadorUnico: string,
    nomeArquivo: string,
  ) => {
    const result = await fetchData({
      codigoIdentificadorUnico,
      nomeArquivo,
    });
    if (!result) toastError('Ocorreu um erro ao buscar o arquivo.');
    const { entidade } =
      result ?? ({} as unknown as IHandleReponseResult<IArquivoDownload>);
    return entidade;
  };

  const baixarArquivoFop = async (
    codigoIdentificadorUnico?: string,
    nomeArquivo?: string,
  ) => {
    if (codigoIdentificadorUnico && nomeArquivo) {
      const arquivo = await obterArquivoCorp(
        codigoIdentificadorUnico,
        nomeArquivo,
      );
      const downloadUrl = window.URL.createObjectURL(new Blob([arquivo]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', nomeArquivo); // Define o nome do arquivo a ser baixado
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoadingDownloadArquivo(false);
    } else {
      return toastError('Ocorreu um erro ao baixar o arquivo.');
    }
    return '';
  };
  const regraFiles = [
    requiredFile(),
    filesFormatsAllowed(),
    maxFileSizeAllowed(() => 'Tamanho máximo do arquivo excedido.', 3),
  ];
  const [openModalFormulario, setOpenModalFormulario] =
    useState<boolean>(false);
  const [arquivoAnexoFop] = useFieldLink<FileList>({} as FileList);
  const [arquivoAnexoFop63] = useFieldLink<FileList>({} as FileList);

  const [textOutraFormaPagamento, setTextOutraFormaPagamento] =
    useState<string>('');

  const [textOutraFormaPgEmpresa, setTextOutraFormaPgEmpresa] =
    useState<string>('');

  const [textOutraFormaPagamentoEmpresa, setTextOutraFormaPagamentoEmpresa] =
    useState<string>('');
  const [
    textOutraFormaPagamentoEmpresaCuidadoExtra,
    setTextOutraFormaPagamentoEmpresaCuidadoExtra,
  ] = useState<string>('');
  const [
    textOutraFormaPagamentoFuncionario,
    setTextOutraFormaPagamentoFuncionario,
  ] = useState<string>('');
  const [
    textOutraFormaPagamentoFuncionarioCuidadoExtra,
    setTextOutraFormaPagamentoFuncionarioCuidadoExtra,
  ] = useState<string>('');
  const [
    textOutraFormaPagamentoCuidadoExtra,
    setTextOutraFormaPagamentoCuidadoExtra,
  ] = useState<string>('');
  const [
    textOutraRegraLiberacaoDaReserva,
    setTextOutraRegraLiberacaoDaReserva,
  ] = useState<string>('');
  const [textInformacoesComplementares, setTextInformacoesComplementares] =
    useState<string>('');

  const [openSelectTipoBenficio, setOpenSelectTipoBenficio] =
    useState<boolean>(false);

  const [openSelectReversivel, setOpenSelectReversivel] =
    useState<boolean>(false);
  const [
    openSelectValoresParticipantesCuidado,
    setOpenSelectValoresParticipantesCuidado,
  ] = useState<boolean>(false);
  const [openSelectValorContribuicao, setOpenSelectValorContribuicao] =
    useState<boolean>(false);
  const [
    openSelectValorContribuicaoCuidadoExtra,
    setOpenSelectValorContribuicaoCuidadoExtra,
  ] = useState<boolean>(false);
  const [openSelectValorFixo, setOpenSelectValorFixo] =
    useState<boolean>(false);
  const [openSelectValorFixoCuidadoExtra, setOpenSelectValorFixoCuidadoExtra] =
    useState<boolean>(false);
  const [openSelectValorFixoEmpresa, setOpenSelectValorFixoEmpresa] =
    useState<boolean>(false);
  const [
    openSelectValorFixoEmpresaCuidadoExtra,
    setOpenSelectValorFixoEmpresaCuidadoExtra,
  ] = useState<boolean>(false);
  const [openSelectValorFixoFuncionario, setOpenSelectValorFixoFuncionario] =
    useState<boolean>(false);
  const [
    openSelectValorFixoFuncionarioCuidadoExtra,
    setOpenSelectValorFixoFuncionarioCuidadoExtra,
  ] = useState<boolean>(false);
  const [openSelectValorPercentual, setOpenSelectValorPercentual] =
    useState<boolean>(false);
  const [
    openSelectValorPercentualCuidadoExtra,
    setOpenSelectValorPercentualCuidadoExtra,
  ] = useState<boolean>(false);
  const [
    openSelectValorPercentualEmpresa,
    setOpenSelectValorPercentualEmpresa,
  ] = useState<boolean>(false);
  const [
    openSelectValorPercentualEmpresaCuidadoExtra,
    setOpenSelectValorPercentualEmpresaCuidadoExtra,
  ] = useState<boolean>(false);
  const [
    openSelectValorPercentualFuncionario,
    setOpenSelectValorPercentualFuncionario,
  ] = useState<boolean>(false);
  const [
    openSelectValorPercentualFuncionarioCuidadoExtra,
    setOpenSelectValorPercentualFuncionarioCuidadoExtra,
  ] = useState<boolean>(false);

  const [openSelectPlanoInstituido, setOpenSelectPlanoInstituido] =
    useState<boolean>(false);

  const [openSelectOutraFormaPagamento, setOpenSelectOutraFormaPagamento] =
    useState<boolean>(false);
  const [
    openSelectPlanoInstituidoCuidadoExtra,
    setOpenSelectPlanoInstituidoCuidadoExtra,
  ] = useState<boolean>(false);

  const [
    openSelectOutraFormaPagamentoCuidadoExtra,
    setOpenSelectOutraFormaPagamentoCuidadoExtra,
  ] = useState<boolean>(false);

  const [openSelectPrazoBenficio, setOpenSelectPrazoBenficio] =
    useState<boolean>(false);

  const [openSelectValoresParticipantes, setOpenSelectValoresParticipantes] =
    useState<boolean>(false);

  const [openSelectPeculio, setOpenSelectPeculio] = useState<boolean>(false);
  const [openSelectPensao, setOpenSelectPensao] = useState<boolean>(false);
  const [openSelectFormaPagamentoCuidado, setOpenSelectFormaPagamentoCuidado] =
    useState<boolean>(false);

  const [
    openSelectOutraFormaPagamentoEmpresa,
    setOpenSelectOutraFormaPagamentoEmpresa,
  ] = useState<boolean>(false);

  const [
    openSelectOutraFormaPagamentoEmpresaCuidadoExtra,
    setOpenSelectOutraFormaPagamentoEmpresaCuidadoExtra,
  ] = useState<boolean>(false);

  const [
    openSelectOutraFormaPagamentoFuncionario,
    setOpenSelectOutraFormaPagamentoFuncionario,
  ] = useState<boolean>(false);

  const [
    openSelectOutraFormaPagamentoFuncionarioCuidadoExtra,
    setOpenSelectOutraFormaPagamentoFuncionarioCuidadoExtra,
  ] = useState<boolean>(false);

  const [openSelectTipoModalidade, setOpenSelectTipoModalidade] =
    useState<boolean>(false);

  const [openSelectLiberacaoReserva, setOpenSelectLiberacaoReserva] =
    useState<boolean>(false);

  const [openSelectSugestaoLiberacao, setOpenSelectSugestaoLiberacao] =
    useState<boolean>(false);

  const [openSelectOutraFormaLiberacao, setOpenSelectOutraFormaLiberacao] =
    useState<boolean>(false);

  const [openSelectTipoModalidadePGBL, setOpenSelectTipoModalidadePGBL] =
    useState<boolean>(false);

  const [openSelectTipoModalidadeVGBL, setOpenSelectTipoModalidadeVGBL] =
    useState<boolean>(false);

  const [openSelectTipoAporte, setOpenSelectTipoAporte] =
    useState<boolean>(false);

  const [openSelectPortabilidade, setOpenSelectPortabilidade] =
    useState<boolean>(false);

  const [openSelectComAporte, setOpenSelectComAporte] =
    useState<boolean>(false);

  const [openSelectIdadeAposentadoria, setOpenSelectIdadeAposentadoria] =
    useState<boolean>(false);

  const [openSelectPrazoContribuicao, setOpenSelectPrazoContribuicao] =
    useState<boolean>(false);

  const [openSelectAporteUnico, setOpenSelectAporteUnico] =
    useState<boolean>(false);

  const [openSelectDiaVencimento, setOpenSelectDiaVencimento] =
    useState<boolean>(false);

  const [openSelectDebitoEmConta, setOpenSelectDebitoEmConta] =
    useState<boolean>(false);

  const selectRegraCalculo = (valor: string) => {
    if (valor === `aposentadoria`) {
      setOpenSelectTipoBenficio(true);
    }
    if (valor === 'contribuicaoMensal') {
      setOpenSelectTipoBenficio(false);
      setOpenSelectReversivel(false);
    }
    if (valor === 'contribuicaoAnual') {
      setOpenSelectTipoBenficio(false);
      setOpenSelectReversivel(false);
    }
  };

  const selectFormaPagamento = (formaPagamento: string) => {
    if (formaPagamento === `averbado`) {
      setOpenSelectValorContribuicao(true);
      setOpenSelectPlanoInstituido(false);
    }
    if (formaPagamento === 'instituido') {
      setOpenSelectValorContribuicao(true);
      setOpenSelectPlanoInstituido(false);
    }
    if (formaPagamento === 'planoInstituido') {
      setOpenSelectValorContribuicao(false);
      setOpenSelectOutraFormaPagamento(false);
      setOpenSelectValorFixo(false);
      setOpenSelectPlanoInstituido(true);
    }
  };

  const selectFormaPagamentoCuidado = (pagamentoCuidado: string) => {
    if (pagamentoCuidado === `averbado`) {
      setOpenSelectValorContribuicaoCuidadoExtra(true);
      setOpenSelectPlanoInstituidoCuidadoExtra(false);
    }
    if (pagamentoCuidado === 'instituido') {
      setOpenSelectValorContribuicaoCuidadoExtra(true);
      setOpenSelectPlanoInstituidoCuidadoExtra(false);
    }
    if (pagamentoCuidado === 'planoInstituido') {
      setOpenSelectValorContribuicaoCuidadoExtra(false);
      setOpenSelectOutraFormaPagamentoCuidadoExtra(false);
      setOpenSelectPlanoInstituidoCuidadoExtra(true);
    }
  };

  const selectBeneficio = (beneficio: string) => {
    if (beneficio === `rendaTemporaria`) {
      setOpenSelectPrazoBenficio(true);
      setOpenSelectReversivel(false);
    }
    if (beneficio === 'prazoMinimoGarantido') {
      setOpenSelectPrazoBenficio(true);
      setOpenSelectReversivel(false);
    }
    if (beneficio === 'rendaPorPrazoCerto') {
      setOpenSelectPrazoBenficio(true);
      setOpenSelectReversivel(false);
    }
    if (beneficio === 'vitalicia') {
      setOpenSelectPrazoBenficio(false);
      setOpenSelectReversivel(false);
    }
    if (beneficio === 'vitaliciareversivel') {
      setOpenSelectPrazoBenficio(false);
      setOpenSelectReversivel(true);
    }
  };

  const selectValoresParticipantes = (valoresParticipantes: string) => {
    if (valoresParticipantes === 'sim') {
      setOpenSelectValoresParticipantes(false);
    }
    if (valoresParticipantes === 'nao') {
      setOpenSelectValoresParticipantes(true);
      setOpenSelectValorContribuicao(false);
      setOpenSelectPlanoInstituido(false);
    }
  };

  const selectValoresParticipantesCuidado = (
    valoresParticipantesCuidado: string,
  ) => {
    if (valoresParticipantesCuidado === 'sim') {
      setOpenSelectValoresParticipantesCuidado(false);
    }
    if (valoresParticipantesCuidado === 'nao') {
      setOpenSelectValorContribuicaoCuidadoExtra(false);
      setOpenSelectPlanoInstituidoCuidadoExtra(false);
      setOpenSelectValoresParticipantesCuidado(true);
    }
  };

  const selectCuidadoExtra = (valorCuidadoExtra: string) => {
    if (valorCuidadoExtra === 'peculio') {
      setOpenSelectPensao(false);
      setOpenSelectPeculio(true);
      setOpenSelectFormaPagamentoCuidado(true);
    }
    if (valorCuidadoExtra === 'pensao') {
      setOpenSelectPeculio(false);
      setOpenSelectPensao(true);
      setOpenSelectFormaPagamentoCuidado(true);
    }
    if (valorCuidadoExtra === 'semCuidadoExtra') {
      setOpenSelectPensao(false);
      setOpenSelectPeculio(false);
      setOpenSelectFormaPagamentoCuidado(false);
      setOpenSelectValorFixoCuidadoExtra(false);
      setOpenSelectValorPercentualCuidadoExtra(false);
      setOpenSelectOutraFormaPagamentoCuidadoExtra(false);
    }
  };

  const selectValorContribuicao = (valorContribuicao: string) => {
    if (valorContribuicao === VALOR_FIXO_CONTRIBUICAO) {
      setOpenSelectValorPercentual(false);
      setOpenSelectOutraFormaPagamento(false);
      setOpenSelectValorFixo(true);
    }
    if (valorContribuicao === VALOR_BASE_CONTRIBUICAO) {
      setOpenSelectValorFixo(false);
      setOpenSelectOutraFormaPagamento(false);
      setOpenSelectValorPercentual(true);
    }
    if (valorContribuicao === OUTRA_FORMA_CONTRIBUICAO) {
      setOpenSelectValorFixo(false);
      setOpenSelectValorPercentual(false);
      setOpenSelectOutraFormaPagamento(true);
    }
  };

  const selectValorContribuicaoCuidadoExtra = (
    valorContribuicaoCuidadoExtra: string,
  ) => {
    if (valorContribuicaoCuidadoExtra === VALOR_FIXO_CONTRIBUICAO) {
      setOpenSelectValorPercentualCuidadoExtra(false);
      setOpenSelectOutraFormaPagamentoCuidadoExtra(false);
      setOpenSelectValorFixoCuidadoExtra(true);
    }
    if (valorContribuicaoCuidadoExtra === VALOR_BASE_CONTRIBUICAO) {
      setOpenSelectValorFixoCuidadoExtra(false);
      setOpenSelectOutraFormaPagamentoCuidadoExtra(false);
      setOpenSelectValorPercentualCuidadoExtra(true);
    }
    if (valorContribuicaoCuidadoExtra === OUTRA_FORMA_CONTRIBUICAO) {
      setOpenSelectValorFixoCuidadoExtra(false);
      setOpenSelectValorPercentualCuidadoExtra(false);
      setOpenSelectOutraFormaPagamentoCuidadoExtra(true);
    }
  };

  const selectValorContribuicaoEmpresa = (valorContribuicaoEmpresa: string) => {
    if (valorContribuicaoEmpresa === VALOR_FIXO_CONTRIBUICAO) {
      setOpenSelectValorPercentualEmpresa(false);
      setOpenSelectOutraFormaPagamentoEmpresa(false);
      setOpenSelectValorFixoEmpresa(true);
    }
    if (valorContribuicaoEmpresa === VALOR_BASE_CONTRIBUICAO) {
      setOpenSelectValorFixoEmpresa(false);
      setOpenSelectOutraFormaPagamentoEmpresa(false);
      setOpenSelectValorPercentualEmpresa(true);
    }
    if (valorContribuicaoEmpresa === OUTRA_FORMA_CONTRIBUICAO) {
      setOpenSelectOutraFormaPagamentoEmpresa(true);
      setOpenSelectValorFixoEmpresa(false);
      setOpenSelectValorPercentualEmpresa(false);
    }
  };

  const selectValorContribuicaoEmpresaCuidadoExtra = (
    valorContribuicaoEmpresaCuidadoExtra: string,
  ) => {
    if (valorContribuicaoEmpresaCuidadoExtra === 'valorFixoContribuicao') {
      setOpenSelectValorPercentualEmpresaCuidadoExtra(false);
      setOpenSelectOutraFormaPagamentoEmpresaCuidadoExtra(false);
      setOpenSelectValorFixoEmpresaCuidadoExtra(true);
    }
    if (valorContribuicaoEmpresaCuidadoExtra === 'valorBaseContribuicao') {
      setOpenSelectValorFixoEmpresaCuidadoExtra(false);
      setOpenSelectOutraFormaPagamentoEmpresaCuidadoExtra(false);
      setOpenSelectValorPercentualEmpresaCuidadoExtra(true);
    }
    if (valorContribuicaoEmpresaCuidadoExtra === 'outraFormaContribuicao') {
      setOpenSelectOutraFormaPagamentoEmpresaCuidadoExtra(true);
      setOpenSelectValorFixoEmpresaCuidadoExtra(false);
      setOpenSelectValorPercentualEmpresaCuidadoExtra(false);
    }
  };

  const selectValorContribuicaoFuncionario = (
    valorContribuicaoFuncionario: string,
  ) => {
    if (valorContribuicaoFuncionario === 'valorFixoContribuicao') {
      setOpenSelectValorPercentualFuncionario(false);
      setOpenSelectOutraFormaPagamentoFuncionario(false);
      setOpenSelectValorFixoFuncionario(true);
    }
    if (valorContribuicaoFuncionario === 'valorBaseContribuicao') {
      setOpenSelectOutraFormaPagamentoFuncionario(false);
      setOpenSelectValorFixoFuncionario(false);
      setOpenSelectValorPercentualFuncionario(true);
    }
    if (valorContribuicaoFuncionario === 'outraFormaContribuicao') {
      setOpenSelectOutraFormaPagamentoFuncionario(true);
      setOpenSelectValorFixoFuncionario(false);
      setOpenSelectValorPercentualFuncionario(false);
    }
  };

  const selectValorContribuicaoFuncionarioCuidadoExtra = (
    valorContribuicaoFuncionarioCuidadoExtra: string,
  ) => {
    if (valorContribuicaoFuncionarioCuidadoExtra === 'valorFixoContribuicao') {
      setOpenSelectValorPercentualFuncionarioCuidadoExtra(false);
      setOpenSelectOutraFormaPagamentoFuncionarioCuidadoExtra(false);
      setOpenSelectValorFixoFuncionarioCuidadoExtra(true);
    }
    if (valorContribuicaoFuncionarioCuidadoExtra === 'valorBaseContribuicao') {
      setOpenSelectOutraFormaPagamentoFuncionarioCuidadoExtra(false);
      setOpenSelectValorFixoFuncionarioCuidadoExtra(false);
      setOpenSelectValorPercentualFuncionarioCuidadoExtra(true);
    }
    if (valorContribuicaoFuncionarioCuidadoExtra === 'outraFormaContribuicao') {
      setOpenSelectOutraFormaPagamentoFuncionarioCuidadoExtra(true);
      setOpenSelectValorFixoFuncionarioCuidadoExtra(false);
      setOpenSelectValorPercentualFuncionarioCuidadoExtra(false);
    }
  };

  const selectFormaPagamentoFop63 = (formaPagamentoFop63: string) => {
    if (formaPagamentoFop63 === `averbado`) {
      setOpenSelectValorContribuicao(true);
      setOpenSelectTipoModalidade(true);
      setOpenSelectTipoModalidadePGBL(false);
      setOpenSelectTipoModalidadeVGBL(false);
      setOpenSelectLiberacaoReserva(false);
      setOpenSelectPlanoInstituido(false);
    }
    if (formaPagamentoFop63 === 'instituido') {
      setOpenSelectValorContribuicao(true);
      setOpenSelectLiberacaoReserva(true);
      setOpenSelectTipoModalidadePGBL(true);
      setOpenSelectTipoModalidadeVGBL(false);
      setOpenSelectTipoModalidade(false);
      setOpenSelectPlanoInstituido(false);
    }
    if (formaPagamentoFop63 === 'planoInstituido') {
      setOpenSelectTipoModalidadeVGBL(true);
      setOpenSelectLiberacaoReserva(true);
      setOpenSelectPlanoInstituido(true);
      setOpenSelectValorContribuicao(false);
      setOpenSelectOutraFormaPagamento(false);
      setOpenSelectTipoModalidadePGBL(false);
      setOpenSelectTipoModalidade(false);
      setOpenSelectValorFixo(false);
    }
  };

  const selecLiberacaoReserva = (liberacaoReserva: string) => {
    if (liberacaoReserva === `sugestao`) {
      setOpenSelectSugestaoLiberacao(true);
      setOpenSelectOutraFormaLiberacao(false);
    }
    if (liberacaoReserva === `outraRegra`) {
      setOpenSelectSugestaoLiberacao(false);
      setOpenSelectOutraFormaLiberacao(true);
    }
    if (liberacaoReserva === `liberacaoTotal`) {
      setOpenSelectSugestaoLiberacao(false);
      setOpenSelectOutraFormaLiberacao(false);
    }
    if (liberacaoReserva === `semLiberacao`) {
      setOpenSelectSugestaoLiberacao(false);
      setOpenSelectOutraFormaLiberacao(false);
    }
  };

  const selectRegraAporte = (valorRegraAporte: string) => {
    if (valorRegraAporte === `semAporte`) {
      setOpenSelectTipoAporte(false);
    }
    if (valorRegraAporte === 'comAporte') {
      setOpenSelectTipoAporte(true);
      setOpenSelectComAporte(true);
      setOpenSelectPortabilidade(false);
    }
    if (valorRegraAporte === 'portabilidade') {
      setOpenSelectTipoAporte(true);
      setOpenSelectPortabilidade(true);
      setOpenSelectComAporte(false);
    }
  };

  const selectTipoConcessao = (tipoConcessao: string) => {
    if (tipoConcessao === 'idadeAposentadoria') {
      setOpenSelectIdadeAposentadoria(true);
      setOpenSelectPrazoContribuicao(false);
    }
    if (tipoConcessao === 'prazoContribuicao') {
      setOpenSelectPrazoContribuicao(true);
      setOpenSelectIdadeAposentadoria(false);
    }
  };

  const selecTipoPagamentoFatura = (tipoPagamentoFatura: string) => {
    if (tipoPagamentoFatura === 'aporteUnico') {
      setOpenSelectAporteUnico(true);
      setOpenSelectDiaVencimento(false);
    }
    if (tipoPagamentoFatura === 'mensal') {
      setOpenSelectDiaVencimento(true);
      setOpenSelectAporteUnico(false);
    }
  };

  const selectDadosCobranca = (dadosCobranca: string) => {
    if (dadosCobranca === 'boleto') {
      setOpenSelectDebitoEmConta(false);
    }
    if (dadosCobranca === 'debito') {
      setOpenSelectDebitoEmConta(true);
    }
  };
  const openModal = (): void => {
    setOpenModalFormulario(true);
  };
  const fecharModal = (): void => {
    setOpenModalFormulario(false);
  };

  return {
    openSelectTipoBenficio,
    openSelectReversivel,
    openSelectPlanoInstituido,
    openSelectValoresParticipantesCuidado,
    openSelectValorContribuicao,
    openSelectValorContribuicaoCuidadoExtra,
    openSelectValorFixo,
    openSelectValorFixoCuidadoExtra,
    openSelectValorFixoEmpresa,
    openSelectValorFixoEmpresaCuidadoExtra,
    openSelectValorFixoFuncionario,
    openSelectValorPercentualCuidadoExtra,
    openSelectValorFixoFuncionarioCuidadoExtra,
    openSelectValorPercentual,
    openSelectValorPercentualEmpresa,
    openSelectValorPercentualEmpresaCuidadoExtra,
    openSelectValorPercentualFuncionario,
    openSelectValorPercentualFuncionarioCuidadoExtra,
    openSelectOutraFormaPagamento,
    openSelectPlanoInstituidoCuidadoExtra,
    openSelectPrazoBenficio,
    openSelectOutraFormaPagamentoCuidadoExtra,
    openSelectValoresParticipantes,
    openSelectPeculio,
    openSelectPensao,
    openSelectFormaPagamentoCuidado,
    openSelectOutraFormaPagamentoEmpresa,
    openSelectOutraFormaPagamentoEmpresaCuidadoExtra,
    openSelectOutraFormaPagamentoFuncionario,
    openSelectOutraFormaPagamentoFuncionarioCuidadoExtra,
    openSelectTipoModalidade,
    openSelectTipoModalidadePGBL,
    openSelectTipoModalidadeVGBL,
    openSelectLiberacaoReserva,
    openSelectSugestaoLiberacao,
    openSelectOutraFormaLiberacao,
    openSelectTipoAporte,
    openSelectPortabilidade,
    openSelectComAporte,
    openSelectIdadeAposentadoria,
    openSelectPrazoContribuicao,
    openSelectAporteUnico,
    openSelectDiaVencimento,
    openSelectDebitoEmConta,
    loadingDownload,
    loadingDownloadArquivo,
    setLoadingDownloadArquivo,
    loadingFops,
    responseFops,
    textOutraFormaPagamento,
    textOutraFormaPagamentoEmpresa,
    textOutraFormaPgEmpresa,
    textOutraFormaPagamentoEmpresaCuidadoExtra,
    textOutraFormaPagamentoFuncionario,
    textOutraRegraLiberacaoDaReserva,
    textOutraFormaPagamentoFuncionarioCuidadoExtra,
    textOutraFormaPagamentoCuidadoExtra,
    textInformacoesComplementares,
    openModalFormulario,
    arquivoAnexoFop,
    arquivoAnexoFop63,
    regraFiles,
    baixarArquivoFop,
    fecharModal,
    setTextInformacoesComplementares,
    setTextOutraFormaPagamentoEmpresa,
    setTextOutraFormaPgEmpresa,
    setTextOutraFormaPagamentoCuidadoExtra,
    setTextOutraRegraLiberacaoDaReserva,
    setTextOutraFormaPagamentoFuncionarioCuidadoExtra,
    setTextOutraFormaPagamentoFuncionario,
    setTextOutraFormaPagamentoEmpresaCuidadoExtra,
    setTextOutraFormaPagamento,
    toastSuccess,
    selectRegraCalculo,
    selectFormaPagamento,
    selectFormaPagamentoCuidado,
    selectBeneficio,
    selectValoresParticipantes,
    selectValoresParticipantesCuidado,
    selectValorContribuicao,
    selectValorContribuicaoCuidadoExtra,
    selectCuidadoExtra,
    selectValorContribuicaoEmpresa,
    selectValorContribuicaoEmpresaCuidadoExtra,
    selectValorContribuicaoFuncionario,
    selectValorContribuicaoFuncionarioCuidadoExtra,
    selectFormaPagamentoFop63,
    selecLiberacaoReserva,
    selectRegraAporte,
    selectTipoConcessao,
    selecTipoPagamentoFatura,
    selectDadosCobranca,
    obterArquivoFOP,
    openModal,
  };
};
