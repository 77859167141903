import { subDays } from 'date-fns';
import RangeDate from 'previdencia/types/RangeDate';

export const calcularPeriodo = (periodo: string): RangeDate => {
  const now = new Date();

  return {
    dataInicial: subDays(now, Number(periodo)),
    dataFinal: now,
  } as RangeDate;
};

export const getOptions = () => {
  return [
    {
      value: '7',
      text: 'Últimos 7 dias',
    },
    {
      value: '15',
      text: 'Últimos 15 dias',
    },
    {
      value: '30',
      text: 'Últimos 30 dias',
    },
    {
      value: '60',
      text: 'Últimos 60 dias',
    },
    {
      value: '90',
      text: 'Últimos 90 dias',
    },
    {
      value: 'custom',
      text: 'Consulta personalizada',
    },
  ];
};
