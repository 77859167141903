import { Display, Grid, Text } from '@cvp/design-system/react';
import Icon from 'main/components/Icon';

type InfoGridItemProps = {
  icon: string;
  label: string;
  value: string | React.ReactElement | null;
};

export const InfoGridItemInline: React.FC<InfoGridItemProps> = ({
  icon,
  label,
  value,
}: InfoGridItemProps) => (
  <Grid.Item
    xs={1}
    md={1}
    lg={1 / 2}
    xl={1 / 3}
    style={{ width: '100%', display: 'contents' }}
  >
    <Display>
      <Icon name={icon} />
      <div>
        <Text variant="caption-02" color="text-light">
          {label}
        </Text>
        <Text
          variant="caption-02"
          color="text"
          title={value}
          data-testid={`info-grid-${label}`}
        >
          {value}
        </Text>
      </div>
    </Display>
  </Grid.Item>
);
