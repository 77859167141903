import { useContext, useState } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { IParametrosEmissaoImpostoRenda } from 'previdencia/features/ServicosIR/types/IParametrosEmissaoImpostoRenda';
import { IEmitirImpostoRenda } from 'previdencia/features/ServicosIR/types/IEmitirImpostoRenda';

export const useEnviarIREmail = (): IEmitirImpostoRenda => {
  const { cliente } = useContext(AppContext);

  const [_, atualizarParametrosIR] = useState<IParametrosEmissaoImpostoRenda>({
    cpfCnpj: cliente?.cpfCnpj,
    anoBase: '',
    tipoPessoa: 'PF',
  });

  const montarObjetoEmail = (anoSelecionado: string) => {
    return {
      tipoEmail: tipoEmailConstants.CONSULTA_IR,
      parametrosEnvio: {
        cpfCnpj: cliente.cpfCnpj,
        numeroCertificado: cliente.numCertificado,
        anoBase: anoSelecionado,
        tipoPessoa: 'PF',
      },
    };
  };
  return {
    atualizarParametrosIR,
    montarObjetoEmail,
  };
};
