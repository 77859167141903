import React from 'react';
import { Select } from '@cvp/design-system/react';

import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import { showErrorInput } from 'main/utils/formik';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const ColunaTipoResgate = ({
  row,
  formik,
  handleTipoResgate,
  isTipoResgateTotal,
}: SOLICITACAO_RESGATE_TYPES.IColunaTipoResgateProps) => {
  return (
    <Select
      placeholder="Selecionar..."
      disabled={checkIfSomeItemsAreTrue([!row.selecionado, isTipoResgateTotal])}
      onChange={({
        target: { value },
      }: React.ChangeEvent<HTMLInputElement>) => {
        handleTipoResgate(row.codigoFundo, value);
      }}
      {...showErrorInput(formik, 'tipoResgate')}
    >
      {CONSTS.TIPO_RESGATE_FUNDO.map(item => (
        <Select.Item
          key={item.id}
          value={item.id}
          text={item.value}
          selected={row.tipoResgate === item.id}
        />
      ))}
    </Select>
  );
};

export default ColunaTipoResgate;
