import { Text } from '@cvp/design-system/react';

import For from 'main/components/For';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { getTernaryResult, tryGetValueOrDefault } from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';
import * as S from './styles';

const TabelaResumoSimulacao = ({
  dadosResumo,
}: SOLICITACAO_RESGATE_TYPES.ITabelaResumoSimulacaoProps) => {
  const { featureData } =
    usePrevidenciaContext<SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData>();

  const resumo = tryGetValueOrDefault(
    [dadosResumo, featureData?.resumoAliquota?.resumo],
    [],
  );

  return (
    <For each={resumo}>
      {item => (
        <S.ContainerTableAliquota key={item.label}>
          <Text variant="body01-sm" color="text">
            {item.label}
          </Text>
          <Text variant="body01-sm" color="text">
            {getTernaryResult(
              !!item.mask,
              tryGetMonetaryValueOrDefault([item.value?.replace(',', '.')]),
              item.value,
            )}
          </Text>
        </S.ContainerTableAliquota>
      )}
    </For>
  );
};

export default TabelaResumoSimulacao;
