import { AppContext } from 'main/contexts/AppContext';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { useContext, useEffect, useMemo } from 'react';
import * as APOLICE_TYPES from 'seguros/types/ApoliseCobertura';
import * as PECO_TYPES from 'seguros/types/ApoliseCoberturaUsePeco';
import {
  ICoberturaBilheteEmpresarial,
  ICoberturaCetrificadoIndividual,
} from 'seguros/types/IResponseCertificadosResumo';
import { useCertificadoSeguroResumo } from './useCertificadoSeguroResumo';

export const useApoliceDadosCoberturaVida =
  (): PECO_TYPES.IUsePecoTabelaApolice<APOLICE_TYPES.ITabelaApoliceCoberturasItens> => {
    const { clienteVida } = useContext(AppContext);
    const {
      certificadoSeguros: { fetchData, response, loading },
    } = useCertificadoSeguroResumo({
      numeroContrato: tryGetValueOrDefault([clienteVida?.numCertificado], '0'),
      codigoProduto: tryGetValueOrDefault([clienteVida?.codigoProduto], '0'),
    });
    useEffect(() => {
      fetchData();
    }, []);

    const entidade = response?.entidade;

    const coberturasBilhete = tryGetValueOrDefault(
      [entidade?.dadosComunsBilheteEmpresarial?.coberturas],
      [],
    );

    const coberturasIndividual = tryGetValueOrDefault(
      [entidade?.individual?.coberturas],
      [],
    );

    const avisosCoberturas = tryGetValueOrDefault(
      [entidade?.individual?.avisos],
      [],
    );

    const dados = useMemo<APOLICE_TYPES.ITabelaApoliceCoberturasItens[]>(() => {
      const individual = coberturasIndividual.map(
        (cobertura: ICoberturaCetrificadoIndividual) => ({
          descricao: cobertura.nome,
          valor: cobertura.importanciaSegurada,
          descricaoCobertura: tryGetValueOrDefault(
            [
              avisosCoberturas.find(
                aviso =>
                  Number(aviso.numeroCobertura) === cobertura.numeroAcessorio,
              )?.descricaoCobertura,
            ],
            '',
          ),
        }),
      );

      const bilhete = coberturasBilhete.map(
        (cobertura: ICoberturaBilheteEmpresarial) => ({
          descricao: cobertura.descricao,
          valor: cobertura.capitalSegurado,
          descricaoCobertura: '',
        }),
      );

      return [...individual, ...bilhete];
    }, [coberturasIndividual, coberturasBilhete]);

    return {
      dados,
      loading,
    };
  };
