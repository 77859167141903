import { useContext } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { PECOS } from 'previdencia/config/endpoints';
import { usePeco } from 'main/hooks/usePeco';
import {
  IPayloadConsultarBeneficiarios,
  IResponseConsultarBeneficiarios,
} from '../types/InformesBeneficiariosTypes';

const useObterBeneficiariosPrevidencia = (ano: string) => {
  const {
    cliente: { cpfCnpj },
  } = useContext(AppContext);
  const { response, loading, fetchData } = usePeco<
    IPayloadConsultarBeneficiarios,
    IResponseConsultarBeneficiarios[]
  >({
    api: {
      operationPath: PECOS.ObterBeneficiarios,
    },
    autoFetch: false,
    payload: {
      cpf: cpfCnpj,
      ano,
    },
  });

  return { response, loading, fetchData };
};

export default useObterBeneficiariosPrevidencia;
