/**
 * validarGenero
 *
 * Função que valida o gênero passado se for F = feminino se for M = masculino.
 *
 * @param {string} siglaGenero - Valor M/F
 * @returns {string}
 * @author Ramon Gomes
 * @version 1.0.0
 */
export const validarGenero = (siglaGenero = ''): string => {
  if (siglaGenero === 'M') {
    return 'Masculino';
  }

  if (siglaGenero === 'F') {
    return 'Feminino';
  }

  return 'Gênero inválido';
};
