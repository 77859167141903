import { Button, Card, Display, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { Skeleton, Table } from 'main/components/Table';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import * as S from 'seguros/components/ConsultaSinistro/styles';
import * as CONSTS from 'seguros/constants/ConsultaSinistro';
import useConsultaSinistro from 'seguros/hooks/useConsultaSinistro';
import useVidaNavigation from 'seguros/hooks/useVidaNavigation';

const ConsultaSinistro = () => {
  const { goDadosSeguradoVida } = useVidaNavigation();

  const { dadosSinistro, loadingDadosSinistro } = useConsultaSinistro();

  const listaSinistro = dadosSinistro?.entidade;

  const isDisableButton = listaSinistro;

  return (
    <Display type="display-block">
      <Card>
        <Card.Content padding={[6, 8, 6, 8]}>
          <Text
            variant="headline-05"
            color="primary"
            margin
            data-testid="tituloConsultaSinistro"
          >
            {CONSTS.TEXTOS_CONSULTA_SINISTRO.TITULO}
          </Text>

          <RenderConditional
            condition={loadingDadosSinistro}
            data-testid="condicionalExibirSkeletonConsultaSinistro"
          >
            <Skeleton
              colunas={CONSTS.COLUNAS_TABELA_CONSULTA_SINISTRO}
              data-testid="skeletonExibirTabelaConsultaSinistro"
            />
          </RenderConditional>

          <RenderConditional
            condition={!loadingDadosSinistro}
            data-testid="condicionalExibirTabelaConsultaSinistro"
          >
            <div className="hide-print">
              <Table
                columns={CONSTS.COLUNAS_TABELA_CONSULTA_SINISTRO}
                noHeader
                striped
                responsive
                pagination
                highlightOnHover
                paginationPerPage={10}
                paginationComponentOptions={{
                  rowsPerPageText: 'Items por página',
                  rangeSeparatorText: 'de',
                }}
                title="Resultado Consulta Sinistro"
                data={tryGetValueOrDefault([listaSinistro], [])}
                noDataComponent={
                  CONSTS.TEXTOS_CONSULTA_SINISTRO.TABELA_SEM_DADOS
                }
                data-testid="tabelaConsultaSinistro"
              />
            </div>
            <div className="show-print">
              <Table
                columns={CONSTS.COLUNAS_TABELA_CONSULTA_SINISTRO}
                noHeader
                striped
                responsive
                highlightOnHover
                title="Resultado Consulta Sinistro"
                data={tryGetValueOrDefault([listaSinistro], [])}
                noDataComponent={
                  CONSTS.TEXTOS_CONSULTA_SINISTRO.TABELA_SEM_DADOS
                }
                data-testid="tabelaConsultaSinistroPrint"
              />
            </div>
          </RenderConditional>
          <RenderConditional condition={!!isDisableButton}>
            <S.Text
              variant="caption-01"
              color="text-light"
              data-testid="observacaoConsultaSinistro"
            >
              {CONSTS.TEXTOS_CONSULTA_SINISTRO.OBSERVACAO}
            </S.Text>
          </RenderConditional>
          <Display type="display-block">
            <Button
              className="hide-print"
              variant="outlined"
              onClick={goDadosSeguradoVida}
              data-testid="voltarConsultarSinistro"
            >
              Voltar
            </Button>
            <RenderConditional condition={!!isDisableButton}>
              <Button
                className="hide-print"
                variant="primary"
                onClick={window.print}
                data-testid="imprimirConsultarSinistro"
              >
                Imprimir
              </Button>
            </RenderConditional>
          </Display>
        </Card.Content>
      </Card>
    </Display>
  );
};

export default ConsultaSinistro;
