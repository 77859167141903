import styled from 'styled-components/macro';
import { Text } from '@cvp/design-system/react';

export const InputLabel = styled.span({
  marginBottom: 8,
  display: 'flex',
  lineHeight: '150%',
  fontSize: 16,
});

export const ErrorMessage = styled(Text)({
  marginTop: '5px',
  svg: {
    width: '16px',
    height: '16px',
    color: '#900000',
    marginRight: '5px',
  },
});

export const InputValidation = styled.div`
  input.error {
    border: #900000 solid 1px;
  }
`;
