import React from 'react';
import { Grid, Text, Display } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { formatarData } from 'main/utils';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import * as UTILS from 'previdencia/features/Aporte/utils/aporteUtils';
import * as APORTE_TYPES from 'previdencia/features/Aporte/types/Aporte';

const FundosDistribuicaoAporteHeader: React.FC<
  APORTE_TYPES.IFundosDistribuicaoAporteHeaderProps
> = ({ aporteFormaPagamento, aporteDadosBancarios }) => {
  return (
    <Display type="display-block">
      <Grid>
        <Grid.Item xs={1} md={1 / 2} lg={1 / 3} xl={1 / 4}>
          <Text variant="caption-01">Forma de Pagameto</Text>
          <Text variant="body-medium3">
            {UTILS.tipoPagamento(
              aporteFormaPagamento.get().value.formaPagamento,
            )}
          </Text>
        </Grid.Item>

        <RenderConditional
          condition={
            aporteFormaPagamento.get().value.formaPagamento ===
            APORTE_TYPES.EnumFormaPagamento.DEBITO
          }
        >
          <Grid.Item xs={1} md={1 / 2} lg={1 / 3} xl={1 / 4}>
            <Text variant="caption-01">Banco</Text>
            <Text variant="body-medium3">104 - Caixa Econômica Federal</Text>
          </Grid.Item>

          <Grid.Item xs={1} md={1 / 2} lg={1 / 3} xl={1 / 4}>
            <Text variant="caption-03">Operação</Text>
            <Text variant="body-medium3">
              {aporteDadosBancarios.get().value.operacao}
            </Text>
          </Grid.Item>

          <Grid.Item xs={1} md={1 / 2} lg={1 / 3} xl={1 / 4}>
            <Text variant="caption-01">Agência</Text>
            <Text variant="body-medium3">
              {aporteDadosBancarios.get().value.agencia}
            </Text>
          </Grid.Item>

          <Grid.Item xs={1} md={1 / 2} lg={1 / 3} xl={1 / 4}>
            <Text variant="caption-01">Conta</Text>
            <Text variant="body-medium3">
              {aporteDadosBancarios.get().value.conta}
            </Text>
          </Grid.Item>

          <Grid.Item xs={1} md={1 / 2} lg={1 / 3} xl={1 / 4}>
            <Text variant="caption-01">Data do Pagamento</Text>
            <Text variant="body-medium3">
              {formatarData(aporteFormaPagamento.get().value.dataDebito)}
            </Text>
          </Grid.Item>
        </RenderConditional>

        <Grid.Item xs={1} md={1 / 2} lg={1 / 3} xl={1 / 4}>
          <Text variant="caption-01">Valor da Contribuição</Text>
          <Text variant="body-medium3">
            {tryGetMonetaryValueOrDefault(
              Number(aporteFormaPagamento.get().value.valorContribuicao) / 100,
            )}
          </Text>
        </Grid.Item>
      </Grid>
    </Display>
  );
};

export default FundosDistribuicaoAporteHeader;
