import { cpf, cnpj } from 'cpf-cnpj-validator';
import masks from './masks';
/* eslint-disable no-param-reassign */

export function formatarCnpj(value?: string): string {
  if (!value) return '';

  return cnpj.format(value);
}

export function formatarCpf(value?: string): string {
  if (!value) return '';

  return cpf.format(value);
}

export function isCpf(value?: string) {
  if (!value) return false;
  return cpf.isValid(value);
}

export function isCnpj(value?: string) {
  if (!value) return false;
  return cnpj.isValid(value);
}

export function numeroCpfEhValido(numeroCpf?: string) {
  const strCpf = numeroCpf
    ?.replace(/[^\d]+/g, '')
    .replace('-', '')
    .replace('/', '');
  if (strCpf === '') return false;
  if (
    strCpf?.length !== 11 ||
    strCpf === '00000000000' ||
    strCpf === '11111111111' ||
    strCpf === '22222222222' ||
    strCpf === '33333333333' ||
    strCpf === '44444444444' ||
    strCpf === '55555555555' ||
    strCpf === '66666666666' ||
    strCpf === '77777777777' ||
    strCpf === '88888888888' ||
    strCpf === '99999999999'
  )
    return false;

  let add = 0;
  for (let i = 0; i < 9; i += 1) {
    add += parseInt(strCpf.charAt(i), 11) * (10 - i);
  }

  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(strCpf.charAt(9), 11)) return false;

  add = 0;
  for (let i = 0; i < 10; i += 1) {
    add += parseInt(strCpf.charAt(i), 10) * (11 - i);
  }

  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(strCpf.charAt(10), 11)) return false;

  return true;
}

export function validarCpf(value: string): boolean {
  return cpf.isValid(value);
}

export const validaCpfCnpj = (value: string) => {
  if (value.length === 11) {
    return isCpf(value.substring(0, 11)) ? undefined : 'CPF inválido!';
  }

  if (value.length === 14) {
    return isCnpj(value.substring(0, 14)) ? undefined : 'CNPJ inválido!';
  }

  if (value.length < 11 || value.length > 14) {
    return 'Campo inválido';
  }
  return undefined;
};

/**
 * Retorna um cpf com 11 caracteres colocando 0 a esquerda
 * @param numeroCpf
 * @returns CPF com 11 dígitos
 * @example 39698649 => 00039698649
 */
export const completarDigitosCpf = (numeroCpf: string) =>
  masks.numberOnly.mask(numeroCpf).padStart(11, '0');
