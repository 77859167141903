import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Display, Button, Text, Tooltip } from '@cvp/design-system/react';
import { useContratosPrestamistaContext } from 'contratosPrestamista/contexts/ContratosPrestamistaContext';
import Table from 'main/components/Table';
import Icon from 'main/components/Icon';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { maskCpfCnpj } from 'contratosPrestamista/utils/maskCpfCnpj';
import SkeletonLoading from 'main/components/SkeletonLoading';
import StepBarWrapper from '../components/StepBarWrapper';
import { useAuth } from 'main/features/Auth/hooks';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  getTernaryResult,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import ModalGerenciarSocios from '../../../components/ModalGerenciarSocios';
import UploadProposta from '../components/UploadProposta';
import {
  MENSAGEM_TABELA_DADOS,
  PARAMETROS_DPS,
} from '../../../constants/parametrosDps';
import useFieldLink from 'main/hooks/useFieldLink';
import { validate } from 'main/features/Validation/utils/validateRules';
import * as Helpers from '../utils/helpers';
import * as Factories from '../factories';
import * as TypesDPS from '../types';
import { usePecoFluxoPF } from '../hooks/usePecoFluxoPF';
import { ISubscricaoPessoaFisica } from 'contratosPrestamista/types';
import { formatarDataAdicionandoSeparador } from 'main/utils';
import { removeEmptySpace } from 'main/utils/string';
import { useVerificarNecessidadeDps } from 'contratosPrestamista/hooks/useVerificarNecessidadeDps';
import masks from 'main/utils/masks';
import { useAdicionarSocio } from 'contratosPrestamista/hooks/useAdicionarSocio';
import { IDadosBasicoProposta } from 'contratosPrestamista/types/IDadosBasicoProposta';
import { IEntradaUploadDocumentoFluxoDps } from 'contratosPrestamista/types/IEntradaUploadDocumentoFluxoDps';
import { TipoPessoaEnum } from 'contratosPrestamista/features/dpsEletronicaPostecipado/constants/EnumTipoPessoa';

const FluxoPF = () => {
  const history = useHistory();
  const [arquivoProposta, validateArquivoProposta] = useFieldLink<FileList>(
    {} as FileList,
  );
  const { user } = useAuth();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalSocios, setOpenModalSocios] = useState<boolean>(false);
  const { featureData, setFeatureData } =
    useContratosPrestamistaContext<TypesDPS.IDpsEletronicaFeatureDataFluxoPF>();
  const [clienteValidado, setClienteValidado] = useState(false);
  const [dadosPessoa, setDadosPessoa] = useState<ISubscricaoPessoaFisica>(
    {} as ISubscricaoPessoaFisica,
  );
  const [dpsEnviada, setDpsEnviada] = useState<boolean>(false);
  const [listaEnvioDps, setListaEnvioDps] = useState<TypesDPS.IDadosEnvioDps[]>(
    [],
  );
  const serviceAdicionarSocio = useAdicionarSocio();

  const service = usePecoFluxoPF();
  const serviceVerificarNecessidadeDps = useVerificarNecessidadeDps();
  const necessitaDps =
    !!serviceVerificarNecessidadeDps?.responseVerificarNecessidadeDps?.entidade
      ?.necessitaDps;
  const possuiDpsExistentes =
    tryGetValueOrDefault(
      [
        serviceVerificarNecessidadeDps?.responseVerificarNecessidadeDps
          ?.entidade?.listaDpsExistentes,
      ],
      [],
    ).length > 0;

  const enviarDps = async () => {
    const payloadDadosBasicoProposta: IDadosBasicoProposta = {
      capitalSegurado: featureData?.dadosContrato?.capitalSegurado,
      cpf: featureData?.dadosContrato?.cpfCnpj,
      numeroProposta: featureData?.dadosContrato?.codContrato,
      valorAcumulo: featureData?.dadosContrato?.valorAcumulo,
      tipoPessoa: TipoPessoaEnum.fisica,
      cnpj: featureData?.dadosContrato?.cpfCnpj,
    };
    serviceAdicionarSocio.incluirSocioSmart(
      payloadDadosBasicoProposta,
      necessitaDps,
      '0',
    );
    const resultadoSolicitacaoDps = await service.fecthSolicitarDps(
      Factories.construirPayloadSolicitarDpsFluxoPF({
        ...featureData,
        codigoUsuario: user.nomeAcesso,
      }),
    );

    setDpsEnviada(!!resultadoSolicitacaoDps?.sucessoBFF);

    setListaEnvioDps(prevState => [
      ...prevState,
      {
        cpf: tryGetValueOrDefault([featureData?.dadosContrato?.cpfCnpj], '-'),
        isDPSReqSuccessfully: !!resultadoSolicitacaoDps?.entidade,
      },
    ]);

    return resultadoSolicitacaoDps;
  };

  const handleSubmit = async () => {
    if (checkIfAllItemsAreTrue([necessitaDps, clienteValidado]))
      await enviarDps();
  };

  const setDadosCadastraisFeatureData = (data: ISubscricaoPessoaFisica) => {
    if (data) {
      setFeatureData({
        ...featureData,
        dadosCadastrais: {
          nome: data.nome,
          dataNascimento: formatarDataAdicionandoSeparador(data.dataNascimento),
          contato: {
            email: removeEmptySpace(data.email),
            telefone: removeEmptySpace(data.telefone),
          },
          sexo: data.sexo,
          endereco: {
            logradouro: removeEmptySpace(data.endereco),
            bairro: removeEmptySpace(data.bairro),
            complemento: '',
            cidade: removeEmptySpace(data.cidade),
            uf: removeEmptySpace(data.uf),
            cep: removeEmptySpace(data.cep),
          },
        },
      });
    }
  };

  const handleValidarDadosDps = async () => {
    const result = await service.fetchDataConsultarSubscricao({
      cpfCnpj: featureData?.dadosContrato?.cpfCnpj,
      idProduto: PARAMETROS_DPS.ID_PRODUTO,
    });

    const dadosFormatados = Factories.obterObjetoSubscricaoPessoaFisica(result);
    setDadosPessoa({
      ...dadosFormatados,
      cpf: tryGetValueOrDefault([featureData?.dadosContrato?.cpfCnpj], ''),
    });
    setDadosCadastraisFeatureData(dadosFormatados);
  };

  const handleCloseModalValidacaoDados = (pessoa: unknown) => {
    const dadosPessoaCast = pessoa as ISubscricaoPessoaFisica;
    setDadosCadastraisFeatureData(dadosPessoaCast);
    setOpenModalSocios(!openModalSocios);
    setDadosPessoa(dadosPessoaCast);
    setClienteValidado(true);
  };

  const runServices = async () => {
    await serviceVerificarNecessidadeDps.verificarExigenciaDps({
      capitalSegurado: featureData?.dadosContrato?.capitalSegurado,
      cnpj: featureData?.dadosContrato?.cpfCnpj,
      numeroProposta: masks.numberOnly.mask(
        featureData?.dadosContrato?.codContrato,
      ),
      tipoPessoa: TipoPessoaEnum.fisica,
      valorAcumulo: featureData?.dadosContrato?.valorAcumulo,
      cpf: featureData?.dadosContrato?.cpfCnpj,
      numeroAgencia: user.agenciaVinculada,
    });
    await handleValidarDadosDps();
  };
  useEffect(() => {
    runServices();
  }, []);

  if (
    checkIfSomeItemsAreTrue([
      serviceVerificarNecessidadeDps.loadingVerificarNecessidadeDps,
      service.loadingConsultarSubscricao,
    ])
  )
    return <SkeletonLoading />;

  return (
    <Display type="display-block">
      <StepBarWrapper>
        <Text variant="headline-06" margin>
          Upload de Proposta/ Envio de DPS
        </Text>

        <Table
          responsive
          className="disableReactDataTableOverflow"
          columns={[
            {
              name: 'CPF',
              selector: 'cpfCnpjCliente',
              cell: (row: IEntradaUploadDocumentoFluxoDps) => (
                <Tooltip
                  text={Helpers.obterMensagemNecessidadeDps(necessitaDps)}
                  position="top"
                  variant="secondary"
                >
                  <Display alignItems="center">
                    <Icon name="warning" /> {maskCpfCnpj(row.cpfCnpj)}
                  </Display>
                </Tooltip>
              ),
            },
            {
              name: 'Capital Segurado Individual',
              selector: 'capitalSegurado',
              cell: (row: IEntradaUploadDocumentoFluxoDps) => (
                <span>
                  {formatarValorPadraoBrasileiro(row.capitalSegurado)}
                </span>
              ),
            },
            {
              name: 'Total de acúmulo de risco',
              selector: 'valorAcumulo',
              cell: (row: IEntradaUploadDocumentoFluxoDps) => (
                <span>{formatarValorPadraoBrasileiro(row.valorAcumulo)}</span>
              ),
            },
            {
              name: 'Ação',
              selector: 'socios',
              maxWidth: '250px',
              cell: (row: IEntradaUploadDocumentoFluxoDps) => {
                if (
                  checkIfAllItemsAreTrue([!necessitaDps, possuiDpsExistentes])
                )
                  return (
                    <Text variant="caption-02">
                      Não necessita DPS - DPS válida dentro de 90 dias
                    </Text>
                  );
                if (!necessitaDps)
                  return (
                    <Text variant="caption-02">
                      Envio da DPS não necessário
                    </Text>
                  );

                return (
                  <Button
                    title={row.nomeSegurado}
                    variant={Helpers.obterVariacaoBotaoValidaDados(
                      clienteValidado,
                    )}
                    onClick={() => {
                      setOpenModalSocios(!openModalSocios);
                    }}
                  >
                    {Helpers.obterTextoBotaoValidarDados(clienteValidado)}
                  </Button>
                );
              },
            },
          ]}
          data={[{ ...featureData?.dadosContrato }]}
          pagination
          paginationPerPage={10}
          paginationComponentOptions={{
            rowsPerPageText: 'Items por página',
            rangeSeparatorText: 'de',
          }}
          noDataComponent={MENSAGEM_TABELA_DADOS}
        />
        <br />
        <UploadProposta
          openModal={openModal}
          dadosContrato={tryGetValueOrDefault(
            [featureData?.dadosContrato],
            {} as IEntradaUploadDocumentoFluxoDps,
          )}
          handleCloseModal={() => setOpenModal(false)}
          dpsEnviada={dpsEnviada}
          listaEnvioDps={listaEnvioDps}
          executeAction={handleSubmit}
          loadingSolicitarDps={service.loadingSolicitarDps}
          arquivoProposta={arquivoProposta}
        />
        <Display style={{ marginTop: '1.5rem' }}>
          <br />
          <Button
            variant="outlined"
            onClick={() => {
              setFeatureData({
                ...featureData,
                etapas: ['Identificação', 'Upload/DPS'],
                etapaAtual: 1,
                showStepBar: true,
              });

              history.push('/prestamista/dps/identificacao');
            }}
          >
            Voltar
          </Button>
          <Button
            disabled={checkIfAllItemsAreTrue([
              !arquivoProposta.get().value.item?.name,
              !clienteValidado,
            ])}
            onClick={() => {
              if (validate([validateArquivoProposta])) {
                setOpenModal(true);
              }
            }}
          >
            {getTernaryResult(!!necessitaDps, 'Enviar link DPS', 'Concluir')}
          </Button>
        </Display>
        <ModalGerenciarSocios
          dadosPessoa={dadosPessoa}
          openModal={openModalSocios}
          handleClose={() => setOpenModalSocios(!openModalSocios)}
          loading={service.loadingConsultarSubscricao}
          disableDelete
          handleSubmitForm={handleCloseModalValidacaoDados}
          tipo="VALIDAR"
        />
      </StepBarWrapper>
    </Display>
  );
};

export default FluxoPF;
