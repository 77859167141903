import styled from 'styled-components/macro';
import { Text } from '@cvp/design-system/react';

export const ContainerIcon = styled.div({
  display: 'block',
  position: 'absolute',
  width: '35px',
  right: '15px',
  top: '10px',
});

export const TextDescricaoItemContribuicao = styled(Text)({
  textAlign: 'center',
});
