import { useEffect, useState } from 'react';
import { FormikProps, useFormik } from 'formik';

import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  getTernaryResult,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import {
  IListarFundosParaResgateLimitesCertificado,
  IListarFundosParaResgateResponse,
} from 'previdencia/types/ConsultaListaFundosParaResgate';
import * as FACTORY from 'previdencia/features/SolicitacaoResgate/factories/solicitacaoResgateFactory';
import * as UTILS from 'previdencia/features/SolicitacaoResgate/utils/SolicitacaoResgate';
import * as CONSTS from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as SOLICITACAO_RESGATE_TYPES from 'previdencia/features/SolicitacaoResgate/types/SolicitacaoResgate';

const useSimularResgate = () => {
  const { featureData, setFeatureData } =
    usePrevidenciaContext<SOLICITACAO_RESGATE_TYPES.ISolicitacaoResgateContextData>();

  const [selectedTableInputFocus, setSelectedTableInputFocus] =
    useState<string>('');

  const [confirmaSolicitacaoResgate, setConfirmaSolicitacaoResgate] =
    useState<SOLICITACAO_RESGATE_TYPES.IConfirmaSolicitacaoResgate>(
      CONSTS.INITIAL_STATE_CONFIRMACAO_SOLICITACAO_RESGATE,
    );

  const {
    limitesCertificado,
    fundosDisponiveis,
    saldo,
    indicadorPermiteResgate,
  } = tryGetValueOrDefault(
    [featureData?.dadosListarFundosParaResgate],
    {} as IListarFundosParaResgateResponse,
  );

  const { valorMinimoPermanencia, valorMinimoResgate, valorMaximoResgate } =
    tryGetValueOrDefault(
      [limitesCertificado],
      {} as IListarFundosParaResgateLimitesCertificado,
    );

  const saldoTotalFundosParaResgate = tryGetValueOrDefault(
    [saldo?.saldoTotal.toString()],
    '',
  );

  const initialStateFormik = FACTORY.formikSimularResgateFactory([
    ...tryGetValueOrDefault([fundosDisponiveis], []),
  ]);

  const validationSchemaSimulacaoResgate =
    FACTORY.formikSimulacaoResgateSchemaFactory(
      valorMinimoResgate,
      valorMaximoResgate,
      valorMinimoPermanencia,
    );

  const formik: FormikProps<SOLICITACAO_RESGATE_TYPES.IFormikValuesSimulacaoResgate> =
    useFormik<SOLICITACAO_RESGATE_TYPES.IFormikValuesSimulacaoResgate>({
      enableReinitialize: true,
      initialValues: initialStateFormik,
      validateOnMount: true,
      validationSchema: validationSchemaSimulacaoResgate,
      onSubmit: () => undefined,
    });

  const isTipoResgateTotal = formik.values.tipoResgate === CONSTS.ID_TOTAL;

  const isTipoResgateParcial = formik.values.tipoResgate === CONSTS.ID_PARCIAL;

  const resetarInputFocus = (): void => {
    setSelectedTableInputFocus('');
  };

  const handleFundoSelecionado = (codigoFundo: string): void => {
    formik.setValues({
      ...formik.values,
      listaFundosParaResgate: formik.values.listaFundosParaResgate.map(fundo =>
        FACTORY.modificarFundoSelecionadoFactory({
          fundo,
          codigoFundo,
        }),
      ),
    });

    setSelectedTableInputFocus(codigoFundo);
  };

  const alterarTipoResgateParcialEmTodosOsFundos = (): void => {
    if (isTipoResgateParcial) {
      formik.setValues({
        ...formik.values,
        listaFundosParaResgate: formik.values.listaFundosParaResgate.map(
          fundo => ({
            ...fundo,
            selecionado: false,
            tipoResgate: CONSTS.ID_SIGLA_PARCIAL,
            valorRetirar: '',
          }),
        ),
      });
    }
  };

  const handleTipoResgate = (
    codigoFundo: string,
    tipoResgate: string,
  ): void => {
    formik.setValues({
      ...formik.values,
      listaFundosParaResgate: formik.values.listaFundosParaResgate.map(fundo =>
        FACTORY.selecionarTipoResgateFactory({
          fundo,
          codigoFundo,
          tipoResgate,
        }),
      ),
    });
  };

  const handleValorRetirar = (
    codigoFundo: string,
    valorRetirar: string,
  ): void => {
    formik.setValues({
      ...formik.values,
      listaFundosParaResgate: formik.values.listaFundosParaResgate.map(fundo =>
        FACTORY.distribuirValorAplicacaoFactory({
          fundo,
          codigoFundo,
          valorRetirar,
        }),
      ),
    });
  };

  const selecionarTodosOsFundosParaResgateTotal = (): void => {
    if (isTipoResgateTotal) {
      formik.setValues({
        ...formik.values,
        listaFundosParaResgate: formik.values.listaFundosParaResgate.map(
          fundo => ({
            ...fundo,
            selecionado: true,
            tipoResgate: CONSTS.ID_SIGLA_TOTAL,
            valorRetirar: fundo.saldoTotal.toString(),
          }),
        ),
      });
    }
  };

  const alterarValorSolicitadoTotal = (tipoResgate: string): void => {
    formik.setValues({
      ...formik.values,
      tipoResgate,
      valorSolicitado: getTernaryResult(
        tipoResgate === CONSTS.ID_TOTAL,
        saldoTotalFundosParaResgate,
        '',
      ),
    });
  };

  const colunas = CONSTS.COLUNAS_FUNDOS_RESGATE({
    formik,
    handleFundoSelecionado,
    handleTipoResgate,
    handleValorRetirar,
    setSelectedTableInputFocus,
    selectedTableInputFocus,
    isTipoResgateTotal,
    isTipoResgateParcial,
    valorMinimoResgate,
  });

  const reiniciarSimulacaoResgate = (): void => {
    setConfirmaSolicitacaoResgate(
      CONSTS.INITIAL_STATE_CONFIRMACAO_SOLICITACAO_RESGATE,
    );
    setSelectedTableInputFocus('');
    formik.resetForm();
  };

  const habilitarSolicitacaoResgate = (): void => {
    const isFiltroSimulacaoResgatePreenchido: boolean = checkIfAllItemsAreTrue([
      !!formik.values.tipoResgate,
      !!formik.values.valorSolicitado,
    ]);

    if (isFiltroSimulacaoResgatePreenchido) {
      setConfirmaSolicitacaoResgate(prevState => ({
        ...prevState,
        isSimulacaoPreenchida: false,
      }));
    }

    const isFiltroSimulacaoResgateValorSolicitadoNaoPreenchido: boolean =
      checkIfAllItemsAreTrue([
        !!formik.values.tipoResgate,
        !formik.values.valorSolicitado,
      ]);

    if (isFiltroSimulacaoResgateValorSolicitadoNaoPreenchido) {
      setConfirmaSolicitacaoResgate(
        CONSTS.INITIAL_STATE_CONFIRMACAO_SOLICITACAO_RESGATE,
      );
    }
  };

  const resetarValorSolicitadoAoAlterarTipoResgate = (): void => {
    if (isTipoResgateParcial) {
      setConfirmaSolicitacaoResgate(
        CONSTS.INITIAL_STATE_CONFIRMACAO_SOLICITACAO_RESGATE,
      );
      formik.setValues({
        ...initialStateFormik,
        tipoResgate: CONSTS.ID_PARCIAL,
        valorSolicitado: '',
      });
    }

    if (isTipoResgateTotal) {
      formik.setValues({
        ...initialStateFormik,
        tipoResgate: CONSTS.ID_TOTAL,
        valorSolicitado: saldoTotalFundosParaResgate,
      });
    }
  };

  const confirmarFiltroDesejadoParaSimulacao = (): void => {
    setConfirmaSolicitacaoResgate(prevState => ({
      ...prevState,
      isSimulacaoPreenchida: true,
      isBtnConfirmacaoSimulacaoClicada: true,
    }));
  };

  const listaTiposResgateFiltro = FACTORY.listarTiposResgateFiltroFactory(
    saldo?.saldoTotalBloqueado,
  );

  const continuarFluxo = !UTILS.continuarSimulacaoResgate(formik);

  const isDisabledConfirmarSimulacao: boolean = checkIfSomeItemsAreTrue([
    confirmaSolicitacaoResgate.isSimulacaoPreenchida,
    !!formik.errors.tipoResgate,
    !!formik.errors.valorSolicitado,
    !indicadorPermiteResgate,
  ]);

  const isExibindoDetalhesSimulacao: boolean = checkIfAllItemsAreTrue([
    confirmaSolicitacaoResgate.isSimulacaoPreenchida,
    confirmaSolicitacaoResgate.isBtnConfirmacaoSimulacaoClicada,
  ]);

  const isInputValorSolicitadoDesabilitado = checkIfSomeItemsAreTrue([
    isTipoResgateTotal,
    checkIfAllItemsAreTrue([
      confirmaSolicitacaoResgate.isBtnConfirmacaoSimulacaoClicada,
      !!formik.values.valorSolicitado,
    ]),
  ]);

  useEffect(habilitarSolicitacaoResgate, [
    formik.values.tipoResgate,
    formik.values.valorSolicitado,
  ]);

  useEffect(() => {
    resetarValorSolicitadoAoAlterarTipoResgate();
    selecionarTodosOsFundosParaResgateTotal();
    alterarTipoResgateParcialEmTodosOsFundos();
  }, [formik.values.tipoResgate]);

  useEffect(() => {
    setFeatureData({
      ...featureData,
      simulacaoResgateFundos: formik.values,
    });
  }, [formik.values]);

  return {
    formik,
    valorMinimoResgate,
    valorMaximoResgate,
    valorMinimoPermanencia,
    confirmaSolicitacaoResgate,
    colunas,
    continuarFluxo,
    listaTiposResgateFiltro,
    isInputValorSolicitadoDesabilitado,
    isTipoResgateParcial,
    isDisabledConfirmarSimulacao,
    isExibindoDetalhesSimulacao,
    alterarValorSolicitadoTotal,
    setConfirmaSolicitacaoResgate,
    resetarInputFocus,
    reiniciarSimulacaoResgate,
    confirmarFiltroDesejadoParaSimulacao,
  };
};

export default useSimularResgate;
