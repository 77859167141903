import {
  DadosSimulacao,
  ResponseSimularBeneficios,
} from 'previdencia/features/SimulacaoRenda/types/simularBeneficios';

export const colunasTabelaDadosSimulacaoFactory = (
  simulacaoProgressivo: ResponseSimularBeneficios | undefined,
  simulacaoRegressivo: ResponseSimularBeneficios | undefined,
) => {
  const existeRegimeProgressivo = Boolean(simulacaoProgressivo?.nomCliente);
  const existeRegimeRegressivo = Boolean(simulacaoRegressivo?.nomCliente);

  const colunasSimulacao = [];

  colunasSimulacao.push({
    name: 'Comparação',
    selector: 'comparacao',
    wrap: true,
    minWidth: '140px',
  });

  if (existeRegimeProgressivo)
    colunasSimulacao.push({
      name: 'Simulação Alíquota Progressiva',
      selector: (row: DadosSimulacao) => (
        <strong>{row.simulacaoProgressivo}</strong>
      ),
      minWidth: '140px',
    });

  if (existeRegimeRegressivo)
    colunasSimulacao.push({
      name: 'Simulação Alíquota Regressiva',
      selector: (row: DadosSimulacao) => (
        <strong>{row.simulacaoRegressivo}</strong>
      ),
      minWidth: '140px',
    });

  colunasSimulacao.push({
    name: 'Estimativa de Renda que você Planejou ao Contratar a Previdência',
    selector: (row: DadosSimulacao) => <strong>{row.estimativaRenda}</strong>,
    minWidth: '140px',
  });

  return colunasSimulacao;
};
