import React, { useContext } from 'react';
import InputFile, {
  DescriptionFormatsFilesAllowed,
} from 'main/components/form/InputFile';
import {
  requiredFile,
  filesFormatsAllowed,
  maxFileSizeAllowed,
} from 'main/features/Validation/validations';
import { ProspeccaoContext } from 'main/features/prospeccao/contexts/ProspeccaoContext';

export const DynamicFormUpload: React.FC = () => {
  const { arquivoDps } = useContext(ProspeccaoContext);

  return (
    <>
      <DescriptionFormatsFilesAllowed fileSize="3.5" />
      <InputFile
        link={arquivoDps}
        validationRules={[
          requiredFile(),
          filesFormatsAllowed(),
          maxFileSizeAllowed(),
        ]}
      />
    </>
  );
};
