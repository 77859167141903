import React, { useEffect } from 'react';
import { IconButton } from 'ferramentas/components/ferramentasStyles';
import Icon from 'main/components/Icon';
import { Card } from '@cvp/design-system/react';

import { ModalOverlay, ModalContent, CloseButton } from './styles';
import { ModalProps } from './types/ModalBlur.types';
import { fecharModal, focoModal } from 'main/utils/modal';

const ModalBlur: React.FC<ModalProps> = ({ onClose, children, open }) => {
  useEffect(() => {
    if (open) {
      focoModal(open);
      fecharModal(onClose);
    }
  }, [open, onClose]);

  return (
    <ModalOverlay aria-label="Modal alerta" id="modal_alerta">
      <ModalContent widthContent="50">
        <CloseButton onClick={onClose}>
          <IconButton onClick={onClose} small variant="primary">
            <Icon name="close" />
          </IconButton>
        </CloseButton>
        <Card>
          <Card.Content>{children}</Card.Content>
        </Card>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ModalBlur;
