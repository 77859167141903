import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Grid, Pagination } from '@cvp/design-system/react';
import { ICardProdutoVida } from 'consultaCliente/features/listaCardProduto/interfaces/ICardProdutoVida';
import { AppContext } from 'main/contexts/AppContext';
import { useCertificadoSeguroResumo } from 'seguros/hooks/useCertificadoSeguroResumo';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { CardVida } from './CardVida';
import TextoInformativoSemProdutos from '../../TextoInformativoSemProdutos/TextoInformativoSemProdutos';
import GridCardSkeleton from '../../GridCardSkeleton';

type ListaSegurosProps = {
  seguros?: ICardProdutoVida[];
  cpfCnpj: string;
  itensPorPagina: number;
};

const ListaSeguros: React.FunctionComponent<ListaSegurosProps> = ({
  seguros,
  cpfCnpj,
  itensPorPagina,
}) => {
  const history = useHistory();
  const [item, setItem] = useState<ICardProdutoVida>();
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const { setClienteVida } = useContext(AppContext);
  const [segurosPage, setSegurosPage] = useState<
    ICardProdutoVida[] | undefined
  >();
  const handleNumeroApolice = () => {
    if (Number(item?.numeroApolice) > 0) return item?.numeroApolice;

    return item?.numeroBilhete;
  };

  const {
    certificadoSeguros: { loading, response, fetchData },
  } = useCertificadoSeguroResumo({
    numeroContrato: tryGetValueOrDefault([item?.numeroBilhete], '0'),
    codigoProduto: tryGetValueOrDefault([item?.codigoProduto], '0'),
  });

  useEffect(() => {
    if (response) {
      setClienteVida({
        cpfCnpj,
        codigoCliente: String(item?.codigoCliente),
        numApolice: handleNumeroApolice(),
        numCertificado: tryGetValueOrDefault([item?.numeroBilhete], '0'),
        nomeSegurado: tryGetValueOrDefault([item?.nomeSegurado], '0'),
        codigoProduto: tryGetValueOrDefault([item?.codigoProduto], '0'),
        tipoCertificado: response?.entidade?.tipoContrato,
      });
      history.push(`/cliente/produtos/vida`);
    }
  }, [response]);

  useEffect(() => {
    if (item) {
      fetchData();
    }
  }, [item]);

  useEffect(() => {
    const tamanho = (seguros || [])?.length;
    const paginas = tamanho / itensPorPagina;
    setTotalPages(tamanho % itensPorPagina === 0 ? paginas : paginas + 1);
    setSegurosPage(seguros?.slice(0, itensPorPagina));
  }, [seguros]);

  useEffect(() => {
    setSegurosPage(
      seguros?.slice((page - 1) * itensPorPagina, page * itensPorPagina),
    );
  }, [page]);

  if (loading) {
    return <GridCardSkeleton quantidadeColunas={4} />;
  }

  if ((seguros || [])?.length === 0) {
    return <TextoInformativoSemProdutos />;
  }

  return (
    <>
      <Grid>
        {segurosPage?.map((itemHandle: ICardProdutoVida) => (
          <Grid.Item xs={1} lg={1 / 4}>
            <CardVida onClick={() => setItem(itemHandle)} data={itemHandle} />
          </Grid.Item>
        ))}
      </Grid>
      <br />
      {(seguros || [])?.length > itensPorPagina && (
        <Pagination page={page} total={totalPages} onChange={setPage} />
      )}
    </>
  );
};

export default ListaSeguros;
